import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: 0,
    resize: 'none',
    overflow: 'visible',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
    },
  },
  drawerOpen: {
    width: theme.drawerOpenedWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '& .MuiSvgIcon-root': {
      marginRight: 8,
    },
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.drawerClosedWidth,
    '& .MuiSvgIcon-root': {
      marginRight: 24,
    },
  },
  list: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  drawerPaper: {
    background: theme.palette.black,
    color: theme.palette.white,
    boxShadow: '0 9px 18px 0',
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    overflow: 'hidden',
    '& .MuiList-root': {
      paddingTop: 0,
      paddingBottom: 0,
      '& .nav-link-active': {
        height: '100%',
        '& .MuiButtonBase-root': {
          background: theme.palette.richBlack,
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            background: theme.palette.dtekYellow,
            top: 0,
            bottom: 0,
            left: 0,
            width: 4,
          },
        },
        '& .MuiSvgIcon-root': {
          color: theme.palette.dtekYellow,
        },
        '& .MuiListItemText-root': {
          color: theme.palette.dtekYellow,
          '& .MuiTypography-root': {
            fontWeight: 'bold',
          },
        },
      },
    },
  },
  menuButtonWrapper: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 2,
    left: 0,
    top: 0,
    width: 72,
    height: 64,
    background: theme.palette.black,
  },
  menuButton: {
    margin: '10px 14px',
    color: theme.palette.white,
    width: 44,
    height: 44,
    background: theme.palette.black,
  },
  linkItem: {
    height: 72,
    width: theme.drawerOpenedWidth,
    paddingLeft: 24,
    color: theme.palette.white,
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
      transition: theme.transitions.create('margin-right', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  toolbar: theme.mixins.toolbar,
}));

export default useStyles;
