import Agent from 'ApiAgents/Agent';
import getPaginationQueryObject from 'Src/utils/getPaginationQueryObject';


export default class BrigadeAgent extends Agent {
  fetchAllByOrgUnit = (
    orgUnitId = '',
    shouldPaginate,
    pageNumber,
    pageSize
  ) => {
    const pageQuery = getPaginationQueryObject(shouldPaginate, pageNumber, pageSize);

    return this.request({
      url: '/brigades/view/all',
      method: 'GET',
      params: { orgUnitId, ...pageQuery }
    }, 'user');
  };

  create = body => (
    this.request({
      url: '/brigades/manage/create',
      method: 'POST',
      data: body
    }, 'user')
  );

  update = body => (
    this.request({
      url: '/brigades/manage/update',
      method: 'PUT',
      data: body
    }, 'user')
  );

  filterById = id => this.request({
    url: `/brigades/view/byId?brigadeId=${id}`,
    method: 'GET',
  }, 'user');

  delete = id => this.request({
    url: `/brigades/manage/delete?brigadeId=${id}`,
    method: 'DELETE',
  }, 'user');

  checkIfAssignedTo = id => this.request({
    url: `/task/assignments/check/byAssigneeId?assigneeId=${id}`,
    method: 'GET',
  }, 'tasks');
}
