import React from 'react';
import PropTypes from 'prop-types';
import { HIGH_PRIORITY, LOW_PRIORITY } from 'Shared/constants/priority';
import {
  SvgIcon,
  Tooltip,
} from '@material-ui/core/';
import { Error } from '@material-ui/icons';
import { ReactComponent as CustomTasksIcon } from 'Src/assets/tasks_custom.svg';
import { ReactComponent as StandardTasksIcon } from 'Src/assets/tasks_standard.svg';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const TaskTypeIcon = ({ priority, isStandard }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Tooltip title={(
      <div>
        <div>{isStandard ? t('STANDARD_TASK_TYPE') : t('NOT_STANDARD_TASK_TYPE')}</div>
        {priority === HIGH_PRIORITY && (<div>{t('HIGH_PRIORITY')}</div>)}
      </div>
    )}
    >
      <div className={classes.iconWrapper}>
        <SvgIcon
          component={isStandard ? StandardTasksIcon : CustomTasksIcon}
        />
        {priority === HIGH_PRIORITY && (
          <>
            <div className={classes.substrate} />
            <SvgIcon
              viewBox="0 0 24 24"
              className={classes.warningIcon}
              component={Error}
            />
          </>
        )}
      </div>
    </Tooltip>
  );
};

TaskTypeIcon.defaultProps = {
  priority: LOW_PRIORITY,
  isStandard: true,
};

TaskTypeIcon.propTypes = {
  priority: PropTypes.number,
  isStandard: PropTypes.bool,
};

export default TaskTypeIcon;
