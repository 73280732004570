import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  endAdornmentBtn: {
    paddingTop: 0,
    paddingBottom: 0,
    width: 24,
    height: 24,
  },
  endAdornmentIcon: {
    width: 20,
    height: 20,
    cursor: 'pointer',
  },
  textField: {
    height: 40,
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    '& .MuiInputBase-root': {
      paddingRight: 8,
      height: 40,
      '& .MuiInputBase-input': {
        height: 40,
      },
    },
  },
}));

export default useStyles;
