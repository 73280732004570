import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: 72,
    display: 'flex',
    flex: 1,
    width: '100%',
    '&:hover': {
      '& $nameLabel': {
        textDecoration: 'underline',
      },
    },
    overflow: 'hidden',
  },
  isVisibleSwitcherWrapper: {
    position: 'relative',
    width: 134,
    maxWidth: 134,
    margin: 'auto',
    '& .MuiFormControl-root': {
      margin: 'auto',
    },
    '& .MuiFormControlLabel-root': {
      margin: 'auto',
    },
  },
  switcherWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  nameLabel: {
    fontSize: 14,
    color: theme.palette.primaryLight,
    margin: 'auto',
    marginLeft: 0,
    marginRight: 0,
    maxWidth: 'calc((100% - 134px - 204px + 50px) / 2)',
    width: 'calc((100% - 134px - 204px  + 50px) / 2)',
    paddingRight: 16,
  },
  codeLabel: {
    fontSize: 14,
    color: theme.palette.midBlack,
    margin: 'auto',
    maxWidth: 'calc((100% - 134px - 204px - 50px) / 2)',
    width: 'calc((100% - 134px - 204px - 50px) / 2)',
  },
  isPhotoRequiredSwitcherWrapper: {
    position: 'relative',
    margin: 'auto',
    '& .MuiFormControl-root': {
      margin: 'auto',
    },
    '& .MuiFormControlLabel-root': {
      margin: 'auto',
    },
    width: 204,
    maxWidth: 204,
  },
  isPhotoRequiredProgress: {
    position: 'absolute',
    top: 0,
    left: 82,
  },
  isPhotoRequiredSwitcher: {
    opacity: ({ isPhotoRequiredUpdating }) => (isPhotoRequiredUpdating ? 0.5 : 1),
  },
  rowButton: {
    width: 'calc(100% - 36px)',
    maxWidth: 'calc(100% - 36px)',
    textAlign: 'left',
  },
}));

export default useStyles;
