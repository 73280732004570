import { makeStatusDto } from 'Services/dto/makeTaskStatusDto';
import taskStatusSchema from 'Services/validation/schemas/TaskStatusSchema';
import { HEAD_MECHANIC, MECHANIC } from 'Shared/constants/roles';
import { add } from 'date-fns';
import { formatToISOString } from 'Src/utils/datetime';

const dtoMaker = makeStatusDto(taskStatusSchema);

const maxPeriodInMonths = 1;
const specificDate = formatToISOString(add(new Date(), { months: -maxPeriodInMonths }));

export const inspectionStatusConfig = t => (
  {
    NEW: dtoMaker({
      title: t('NEW'),
      name: 'new',
      query: ['ASSIGNED'],
      access: [HEAD_MECHANIC, MECHANIC],
    }),
    IN_PROGRESS: dtoMaker({
      title: t('IN_PROGRESS'),
      name: 'in_progress',
      query: ['IN_PROGRESS', 'PAUSE'],
      access: [HEAD_MECHANIC, MECHANIC],
    }),
    COMPLETED: dtoMaker({
      dateLimits: {
        [HEAD_MECHANIC]: {
          factFinishDateMoreThan: specificDate,
        },
        [MECHANIC]: {
          factFinishDateMoreThan: specificDate,
        },
      },
      title: t('COMPLETED'),
      name: 'completed',
      query: ['COMPLETED', 'CANCELED'],
      access: [HEAD_MECHANIC, MECHANIC],
    }),
    ALL: dtoMaker({
      dateLimits: {
        [HEAD_MECHANIC]: {
          factFinishDateMoreThan: specificDate,
        },
        [MECHANIC]: {
          factFinishDateMoreThan: specificDate,
        },
      },
      title: t('ALL'),
      name: 'all',
      access: [HEAD_MECHANIC, MECHANIC],
      query: ['ASSIGNED', 'IN_PROGRESS', 'PAUSE', 'COMPLETED', 'CANCELED']
    })
  }
);

export default inspectionStatusConfig;
