import React from 'react';

import withStyles from 'Src/utils/table/withColumnStyles';
import DefectSwitcher from '../components/DefectSwither/DefectSwither';

const tableColumns = t => (
  {
    status: {
      field: 'isUnconstrained',
      title: t('RE_REGISTRATION_SHORT'),
      // eslint-disable-next-line
      render: ({ defectId, isUnconstrained, isUpdating }) => {
        return (
          <DefectSwitcher
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            id={defectId}
            isUnconstrained={isUnconstrained}
            isUpdating={isUpdating}
          />
        );
      },
    },
    title: {
      field: 'shortText',
      title: t('DEFECT_CODE_NAME'),
      mainLink: true,
      withEllipsis: true,
      render: ({ shortText, code }) => (code && shortText ? `${code} - ${shortText}` : code || ''),
    },
    userText: {
      field: 'userText',
      title: t('USER_TEXT'),
      withEllipsis: true,
    },
  }
);

export const tableConfig = (t) => {
  const translatedTableColumns = tableColumns(t);
  return (
    {
      columns: [
        withStyles(translatedTableColumns.status, { width: '12%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.title, { width: '44%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.userText, { width: '44%', whiteSpace: 'normal' }),
      ],
      actions: []
    }
  );
};

export default tableColumns;
