import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { PlayCircleFilledOutlined, PauseCircleFilledOutlined, CheckCircle } from '@material-ui/icons';

import useStyles from './styles';

const PlannerTaskStatus = ({ status }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  switch (status) {
    case 'Ready':
      return (
        <div className={clsx(classes.wrapper, classes.ready)}>
          <PlayCircleFilledOutlined />
          <span>{t('IN_PROGRESS')}</span>
        </div>
      );

    case 'OnPause':
      return (
        <div className={clsx(classes.wrapper, classes.pause)}>
          <PauseCircleFilledOutlined />
          <span>{t('PAUSE')}</span>
        </div>
      );

    case 'Done':
      return (
        <div className={clsx(classes.wrapper, classes.done)}>
          <CheckCircle />
          <span>{t('COMPLETED_PARTICIPLE')}</span>
        </div>
      );

    default:
      return null;
  }
};

PlannerTaskStatus.propTypes = {
  status: PropTypes.oneOf(['ready', 'pause', 'done']).isRequired,
};

export default PlannerTaskStatus;
