import Agent from 'ApiAgents/Agent';
import getPaginationQueryObject from 'Src/utils/getPaginationQueryObject';

export default class MeasurementReportAgent extends Agent {
  fetchReports = (
    shouldPaginate,
    pageNumber,
    pageSize
  ) => {
    const pageQuery = getPaginationQueryObject(shouldPaginate, pageNumber, pageSize);

    return this.request({
      url: '/tpsInspectionReport/reports',
      method: 'POST',
      data: { ...pageQuery }
    },
    'tasks');
  };

  getReportShifts = () => this.request({
    url: '/tpsInspectionReport/reports-shift',
    method: 'GET',
  }, 'tasks');
}
