import OperationKeysAgent from 'ApiAgents/Settings/OperationKeysAgent';
import {
  action, observable, runInAction, makeObservable
} from 'mobx';
import {
  findIndex, get, omit, find
} from 'lodash';


const operationKeysAgent = new OperationKeysAgent();
const initialOperationKeyData = { isActive: true };

export class OperationKeysSettingsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable operationKeyData = initialOperationKeyData;

  @observable list = [];

  @observable isUpdatingOperationKey = false;

  @observable isAddingOperationKey = false;

  @observable isListLoading = false;

  @observable isListLoaded = false;

  @action getOperationById = async (id) => {
    const operationKeyData = find(this.list, ({ id: operationKeyId = '' } = {}) => id === operationKeyId.toString());
    if (operationKeyData) {
      this.operationKeyData = operationKeyData;
    } else {
      this.operationKeyData = {};
      throw new Error();
    }
  };

  @action updateOperationKey = async () => {
    this.isUpdatingOperationKey = true;
    try {
      const requestData = omit(this.operationKeyData, ['key']);
      await operationKeysAgent.updateOperationKey(requestData);

      runInAction(() => {
        this.isUpdatingOperationKey = false;
      });

      return { error: false };
    } catch (error) {
      console.log('ERROR UPDATING OPERATION KEY', error);
      runInAction(() => {
        this.isUpdatingOperationKey = false;
      });
      return { error };
    }
  };

  @action addOperationKey = async () => {
    this.isAddingOperationKey = true;
    try {
      await operationKeysAgent.addOperationKey(this.operationKeyData);

      runInAction(() => {
        this.isAddingOperationKey = false;
      });

      return { error: false };
    } catch (error) {
      console.log('ERROR UPDATING OPERATION KEY', error);
      runInAction(() => {
        this.isAddingOperationKey = false;
      });
      return { error };
    }
  }

  @action updateField = (field, value) => {
    this.operationKeyData = { ...this.operationKeyData, [field]: value };
  };

  @action onChangeIsActive = async (id, isActive) => {
    const operationKeyIndex = findIndex(this.list, ({ id: operationKeyId }) => operationKeyId === id);
    if (operationKeyIndex < 0) return;
    const newList = [...this.list];
    try {
      newList[operationKeyIndex] = { ...this.list[operationKeyIndex], isUpdating: true };
      runInAction(() => {
        this.list = newList;
      });
      const name = get(this.list[operationKeyIndex], 'name');
      const updatedDefect = await operationKeysAgent.updateOperationKey({ id, isActive, name }) || {};
      const updatedIsActive = get(updatedDefect, 'isActive', false);
      newList[operationKeyIndex] = { ...this.list[operationKeyIndex], isUpdating: false, isActive: updatedIsActive };
      runInAction(() => {
        this.list = newList;
      });
    } catch (error) {
      newList[operationKeyIndex] = { ...this.list[operationKeyIndex], isUpdating: false, isActive: true };
      runInAction(() => {
        this.list = newList;
      });
      throw new Error(error);
    }
  };

  @action discardList = () => {
    this.isListLoaded = false;
    this.isListLoading = false;
    this.list = [];
  }

  @action discardEditorState = () => {
    this.operationKeyData = initialOperationKeyData;
    this.isListLoaded = false;
    this.isListLoading = false;
    this.list = [];
  }

  @action loadList = async () => {
    this.isListLoading = true;
    this.isListLoaded = false;
    try {
      const items = await operationKeysAgent.getOperationKeys() || [];
      runInAction(() => {
        this.list = items;
        this.isListLoading = false;
        this.isListLoaded = true;
      });
    } catch (error) {
      console.log('ERROR LOAD OPERATION KEYS: ', error);
      runInAction(() => {
        this.list = [];
        this.isListLoading = false;
        this.isListLoaded = false;
      });
      throw new Error(error);
    }
  }
}

export default OperationKeysSettingsStore;
