const historyTabHeaderConfig = t => [
  {
    id: 'datetime', numeric: false, disablePadding: true, label: t('DATE_AND_TIME')
  },
  {
    id: 'statuses', numeric: false, disablePadding: false, label: t('STATUS')
  },
  {
    id: 'assignee', numeric: false, disablePadding: false, label: t('EMPLOYEE')
  },
  {
    id: 'reason',
    numeric: false,
    disablePadding: false,
    width: 170,
    label: `${t('REASON')}/${t('COMMENT')}`,
  },
  {
    id: 'author', numeric: false, disablePadding: false, label: t('AUTHOR')
  },
];

export default historyTabHeaderConfig;
