import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { PhotoCameraOutlined } from '@material-ui/icons';
import useStores from 'Store/useStores';
import ActionButton from 'Common/components/Forms/Button/components/Action/Action';
import actionButtonTypes from 'Common/components/Forms/Button/configs/actionButtonTypes';
import useManageEntityModal from 'Common/components/Modal/ManageEntityModal/useManageEntityModal';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import HeaderLayout from 'Common/widgets/Layout/Header/Header';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import OptionRenderer from 'Common/components/Forms/OptionRenderer';
import CommentsBlock from 'Common/widgets/CommentsBlock';
import { useTranslation } from 'react-i18next';
import { formatDate, formatTime } from 'Src/utils/datetime';
import { VIEW_TASK, DEFECTS } from 'Shared/constants/routes';
import { taskStatuses } from 'Shared/constants/taskStatuses';
import { NEW_ENUM } from 'Shared/constants/defectStatuses';
import StyledLink from 'Common/components/StyledLink';

import useStyles from './styles';

const Defect = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const { id: defectId } = params;
  const {
    setManageEntityModalVisibility,
    setOnManageEntityModalCloseCallback,
    setManageEntityModalState,
    renderManageEntityModal,
  } = useManageEntityModal();
  const translatedTaskStatuses = taskStatuses(t);

  const {
    coalDefectStore: {
      rejectDefect,
      confirmDefect,
      defect = {},
      isLoading,
      isRejecting,
      isConfirming,
      getDefectById,
      discardState,
    },
    notificationStore: { enqueueSnackbar },
  } = useStores();

  const goBack = () => {
    if (history.action !== 'POP') {
      history.goBack();
    } else {
      history.push({
        pathname: DEFECTS,
      });
    }
  };
  useEffect(() => () => discardState(), []);

  useEffect(() => {
    if (!parseInt(defectId, 10)) {
      goBack();
      return;
    }
    getDefectById(defectId);
  }, [defectId]);

  const {
    title,
    id,
    parentTask = {},
    childTask = {},
    status,
    group,
    createdDate,
    department: defectDepartment,
    code,
    equipment,
    reporterName,
    editorName,
    defectComment,
    defectsPhotoFolderLink
  } = defect;

  const parentTaskId = get(parentTask, 'id');
  const parentTaskTitle = get(parentTask, 'title');
  const parentTaskStatus = get(parentTask, 'taskStatus');

  const childTaskId = get(childTask, 'id');
  const childTaskTitle = get(childTask, 'title');
  const childTaskStatus = get(childTask, 'taskStatus');
  const rejectionReason = get(childTask, 'rejectionReason');

  const showExecutionButtons = status === NEW_ENUM;

  const reject = async () => {
    const { error } = await rejectDefect(id) || {};
    if (error) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('REQUEST_DEFAULT_ERROR')
            }]
          }],
        },
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('DEFECT_REJECTED_SUCCESSFULLY')
          }]
        }],
      },
      variant: 'success',
    });
    goBack();
  };

  const confirm = async () => {
    const { error } = await confirmDefect(id) || {};
    if (error) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('REQUEST_DEFAULT_ERROR')
            }]
          }],
        },
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('DEFECT_WAS_SUCCESSFULLY_SENT_TO_WORK')
          }]
        }],
      },
      variant: 'success',
    });
    goBack();
  };

  const showDefectRejectionPrompt = () => {
    setManageEntityModalVisibility(true);
    setManageEntityModalState({
      title: t('REJECTING_DEFECT'),
      entityName: title,
      message: t('DEFECT_WILL_BE_SENT_TO_THE_REJECTED_TAB'),
      type: 'action',
      actionButtonTitle: t('CONFIRM'),
    });
    setOnManageEntityModalCloseCallback(() => reject);
  };

  const showConfirmDefectPrompt = () => {
    setManageEntityModalVisibility(true);
    setManageEntityModalState({
      title: t('SENDING_TO_WORK'),
      entityName: title,
      message: t('AN_URGENT_TASK_WILL_BE_CREATED_BASED_ON_THE_DEFECT'),
      secondaryMessage: t('THE_DEFECT_WILL_BE_SENT_TO_THE_IN_PROGRESS_TAB'),
      type: 'action',
      actionButtonTitle: t('CONFIRM'),
    });
    setOnManageEntityModalCloseCallback(() => confirm);
  };

  const renderActionButtons = () => (
    <>
      {(isLoading || isRejecting || isConfirming) && <ProgressBar />}
      <div className={classes.actionButtonsBlock}>
        <ActionButton
          data-test="rejectButton"
          className={classes.actionButton}
          type={actionButtonTypes.CANCEL}
          text={t('REJECT')}
          handleClick={showDefectRejectionPrompt}
        />
        <ActionButton
          data-test="confirmButton"
          className={classes.actionButton}
          type={actionButtonTypes.CONFIRM}
          text={t('SEND_TO_WORK')}
          handleClick={showConfirmDefectPrompt}
        />
      </div>
      {renderManageEntityModal()}
    </>
  );

  if (isEmpty(defect)) return null;

  return (
    <PaperLayout autoHeight rootStyles={classes.root}>
      <HeaderLayout title={title} handleGoBack={goBack} isActiveGoBack titleGoBack={t('GO_BACK')} />
      <div>
        <div className={classes.block}>
          <div className={classes.blockHeader}>
            <div className={classes.blockHeaderLabel}>{t('EXECUTION_RESULT')}</div>
          </div>
          <div className={classes.twoColumns}>
            <div className={classes.column}>
              <OptionRenderer
                name="defectStatus"
                value={t(`${status}_DEFECT_STATUS`)}
                title={t('DEFECT_STATUS')}
                wrapperClass={classes.optionRenderer}
                data-test="defectStatus"
              />
              {childTaskId && (
                <OptionRenderer
                  name="childTaskTitle"
                  value={childTaskTitle}
                  valueRenderer={value => (
                    <StyledLink
                      to={`${VIEW_TASK}/${childTaskId}`}
                      data-test="childTaskLink"
                    >
                      {value}
                    </StyledLink>
                  )}
                  title={t('CREATED_TASK')}
                  wrapperClass={classes.optionRenderer}
                  data-test="childTaskLink"
                />
              )}
            </div>
            <div className={classes.column}>
              {editorName && (
                <OptionRenderer
                  name="editorName"
                  value={editorName}
                  title={t('PROCESSED')}
                  wrapperClass={classes.optionRenderer}
                  data-test="editorName"
                />
              )}
              {childTaskStatus && (
                <OptionRenderer
                  name="childTaskStatus"
                  value={translatedTaskStatuses[childTaskStatus] ? translatedTaskStatuses[childTaskStatus] : translatedTaskStatuses.UNKNOWN}
                  title={t('CREATED_TASK_STATUS')}
                  wrapperClass={classes.optionRenderer}
                  data-test="childTaskStatus"
                />
              )}
              {rejectionReason && (
                <OptionRenderer
                  name="rejectionReason"
                  value={rejectionReason}
                  title={t('PROCESS_REJECTION_REASON')}
                  wrapperClass={classes.optionRenderer}
                  data-test="rejectionReason"
                />
              )}
            </div>
          </div>
        </div>
        <div className={classes.block}>
          <div className={classes.blockHeader}>
            <div className={classes.blockHeaderLabel}>{t('DEFECT_ATRIBUTES')}</div>
          </div>
          <div className={classes.twoColumns}>
            <div className={classes.column}>
              {parentTaskId && parentTaskTitle && (
                <OptionRenderer
                  name="parentTaskTitle"
                  value={parentTaskTitle}
                  valueRenderer={value => (
                    <StyledLink
                      to={`${VIEW_TASK}/${parentTaskId}`}
                      data-test="parentTaskLink"
                    >
                      {value}
                    </StyledLink>
                  )}
                  title={t('DEFECT_SOURCE')}
                  wrapperClass={classes.optionRenderer}
                  data-test="parentTaskTitle"
                />
              )}
              <OptionRenderer
                name="defectName"
                value={title}
                title={t('DEFECT_NAME')}
                wrapperClass={classes.optionRenderer}
                data-test="defectName"
              />
              <OptionRenderer
                name="defectCode"
                value={code}
                title={t('DEFECT_CODE')}
                wrapperClass={classes.optionRenderer}
                data-test="defectCode"
              />
              <OptionRenderer
                name="defectGroup"
                value={group}
                title={t('DEFECTS_GROUP')}
                wrapperClass={classes.optionRenderer}
                data-test="defectGroup"
              />
              <OptionRenderer
                name="defectCreatedDate"
                value={`${formatDate(createdDate)}  ${formatTime(createdDate)}`}
                title={t('DEFECT_DATE')}
                wrapperClass={classes.optionRenderer}
                data-test="defectCreatedDate"
              />
            </div>
            <div className={classes.column}>
              {parentTaskStatus && (
                <OptionRenderer
                  name="parentTaskStatus"
                  value={parentTaskStatus}
                  title={t('DEFECT_SOURCE_STATUS')}
                  wrapperClass={classes.optionRenderer}
                  data-test="parentTaskStatus"
                />
              )}
              <OptionRenderer
                name="defectDepartment"
                value={defectDepartment}
                title={t('DEPARTMENT')}
                // wrapperClass={classes.optionRenderer}
                data-test="defectDepartment"
              />
              <OptionRenderer
                name="equipment"
                value={equipment}
                title={t('EQUIPMENT')}
                wrapperClass={classes.optionRenderer}
                data-test="equipment"
              />
              <OptionRenderer
                name="reporterName"
                value={reporterName}
                title={t('IDENTIFIED')}
                wrapperClass={classes.optionRenderer}
                data-test="reporterName"
              />
            </div>
          </div>
        </div>
        {Boolean(defectsPhotoFolderLink) && (
          <div className={classes.block}>
            <div className={classes.photoBlockHeader}>
              <div className={classes.blockHeaderLabel}>{t('PHOTOS')}</div>
              <a className={classes.photoLink} href={defectsPhotoFolderLink} target="_blank" rel="noreferrer" data-test="photo-link">
                <PhotoCameraOutlined className={classes.photoLinkIcon} />
                {t('VIEW_A_PHOTO')}
              </a>
            </div>
          </div>
        )}
        {Boolean(defectComment) && (
          <div className={classes.block}>
            <div className={classes.blockHeader}>
              <div className={classes.blockHeaderLabel}>{t('COMMENTS')}</div>
              <CommentsBlock comments={[defectComment]} />
            </div>
          </div>
        )}
        {showExecutionButtons && renderActionButtons()}
      </div>
    </PaperLayout>
  );
};

export default observer(Defect);
