import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
  isEmpty, find, get, includes
} from 'lodash';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import useStores from 'Store/useStores';
import * as routes from 'Shared/constants/routes';
import { Tooltip, ButtonBase } from '@material-ui/core';
import { ReactComponent as OrderRequiredIcon } from 'Assets/order_required_icon.svg';
import Panel, { PanelTitle, PanelBox } from 'Common/widgets/Layout/Panel/Panel';
import Input from 'Common/components/Forms/Input/Input';
import DateInput from 'Common/components/Forms/Input/Date';
import FormSwitch from 'Common/components/Forms/Input/FormSwitch';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import { useTranslation } from 'react-i18next';
import Assignments from 'Business/coal/components/Assignments';
import DottedLoader from 'Common/components/Progress/components/Dotted';
import RouteValidationBlock from 'Business/coal/components/RouteValidationBlock';
import InspectionExecutionBlock from 'Business/coal/components/InspectionExecutionBlock';
import RouteTechObject from 'Business/coal/components/RouteTechObject';
import useManageEntityModal from 'Common/components/Modal/ManageEntityModal/useManageEntityModal';

import { ReactComponent as IconFolder } from 'Assets/folder.svg';
import { ReactComponent as IconOpenFolder } from 'Assets/folder_open.svg';
import OptionRenderer from 'Common/components/Forms/OptionRenderer';
import InspectionTypeIcon from 'Business/coal/components/InspectionTypeIcon';
import { MECHANIC } from 'Shared/constants/roles';
import {
  LOW, HIGH, HIGH_PRIORITY, LOW_PRIORITY
} from 'Shared/constants/priority';
import { ASSIGNED_ENUM } from 'Shared/constants/inspectionStatuses';

import useStyles from './styles';


const TaskRouteDetails = observer(({ id: viewerId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const fromPath = get(location, 'state.fromPath');
  const { id, action: mode = 'view' } = viewerId
    ? {
      id: viewerId,
      action: 'view',
    }
    : useParams();

  const {
    coalInspectionStore: {
      isLoading,
      isLoaded,
      isDeleting,
      isUpdating,
      discardState,
      updateDataField,
      saveInspection,
      deleteInspection,
      loadShiftDictionary,
      operationRejectReasonDictionary,
      operationPauseReasonDictionary,
      scanFailReasonDictionary,
      loadReasonDictionaries,
      loadDepartments,
      loadRoutesDictionary,
      shiftDictionary,
      departmentsDictionary,
      routesDictionary,
      isDepartmentsReadonly,
      isRoutesLoading,
      inspection,
      getInspectionById,
      taskRejectionReasonsDictionary,
      isReasonDictionariesLoaded,
      isShiftsDictionaryLoaded,
      isDepartmentsLoaded
    },
    userStore: { role, auth },
    notificationStore: { enqueueSnackbar },
    validationStore: {
      validateRequiredFields, isValidFields, hasErrors, hasNotValidFields
    },
  } = useStores();

  const isMechanic = role === MECHANIC;

  const {
    id: inspectionId,
    title,
    expectedStartDate,
    shift,
    taskPriority,
    currentAssignee,
    assignments = [],
    departmentId,
    routeId,
    isOrderRequired,
    authorName,
    taskStatus,
    canBeDeleted
  } = inspection;

  const classes = useStyles();

  const currentUserDepartmentId = get(auth, 'department.id');

  let successSaveMessage;
  let shouldLoadInspectionById;
  let isReadonlyByMode;
  let technicalObjects = [];
  let actions = [];
  let shouldLoadReasonDictionaries = false;
  let isDictionariesLoaded = false;
  let shouldLoadDepartments = false;
  let shouldSetDepartmentByDefault = false;
  let showInspectionExecutionBlock = false;

  const route = find(routesDictionary, { id: routeId });
  switch (mode) {
    case 'create':
      isReadonlyByMode = false;
      shouldLoadInspectionById = false;
      successSaveMessage = t('INSPECTION_CREATED');
      technicalObjects = get(route, 'technicalObjects', []);
      isDictionariesLoaded = isShiftsDictionaryLoaded && isDepartmentsLoaded;
      shouldLoadDepartments = true;
      shouldSetDepartmentByDefault = isMechanic && currentUserDepartmentId;
      break;
    case 'view':
      isReadonlyByMode = true;
      shouldLoadInspectionById = true;
      technicalObjects = get(inspection, 'technicalObjects', []);
      shouldLoadReasonDictionaries = true;
      showInspectionExecutionBlock = true;
      isDictionariesLoaded = isReasonDictionariesLoaded && isShiftsDictionaryLoaded;
      if (taskStatus === ASSIGNED_ENUM && canBeDeleted) actions = ['delete'];
      break;
    case 'edit':
      isReadonlyByMode = false;
      shouldLoadInspectionById = true;
      successSaveMessage = t('INSPECTION_UPDATED');
      break;
    case 'control':
      isReadonlyByMode = true;
      break;
    default:
      isReadonlyByMode = false;
  }

  const showTechnicalObjects = technicalObjects.length > 0;

  const {
    setManageEntityModalVisibility,
    setOnManageEntityModalCloseCallback,
    setManageEntityModalState,
    renderManageEntityModal,
  } = useManageEntityModal();

  const goToInspectionsList = () => {
    history.push({
      pathname: fromPath || routes.INSPECTIONS,
    });
  };

  const goBack = () => {
    goToInspectionsList();
  };

  const saveItem = async () => {
    validateRequiredFields();
    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    const { res } = await saveInspection(inspection);
    if (res) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: successSaveMessage,
                },
              ],
            },
          ],
        },
        variant: 'success',
      });
      goBack();
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('REQUEST_DEFAULT_ERROR'),
              },
            ],
          },
        ],
      },
      variant: 'error',
    });
  };

  const viewItem = () => {
    history.push({
      pathname: routes.VIEW_TASK_ROUTE.replace(':id', id).replace(':action(view)', 'view'),
    });
  };

  const removeItem = async () => {
    try {
      await deleteInspection(id);
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('INSPECTION_DELETED'),
                },
              ],
            },
          ],
        },
        variant: 'success',
      });
      goBack();
      return;
    } catch (error) {
      const rows = [
        {
          rowContent: [
            {
              type: 'text',
              text: t('CANT_DELETE_INSPECTION'),
            },
          ],
        },
      ];
      const errors = get(error, 'data.errors');
      if (errors) {
        errors.forEach((err) => {
          switch (err) {
            case 'StatusIsNotAssigned':
              rows.push({
                rowContent: [
                  {
                    type: 'text',
                    text: t('CANT_DELETE_INSPECTION_STATUS_CHANGED'),
                  },
                ],
              },);
              break;

            case 'ExecutionDateHasArrived':
              rows.push({
                rowContent: [
                  {
                    type: 'text',
                    text: t('CANT_DELETE_INSPECTION_INVALID_DATE'),
                  },
                ],
              },);
              break;

            default:
              break;
          }
        });
      }
      enqueueSnackbar({
        messageTemplate: {
          rows
        },
        variant: 'error',
      });
    }
  };

  const showDeleteInspectionPrompt = () => {
    setManageEntityModalVisibility(true);
    setManageEntityModalState({
      title: t('INSPECTION_DELETING'),
      entityName: title,
      message: t('INSPECTION_DELETING_MESSAGE'),
      type: 'action',
      actionButtonTitle: t('DELETE'),
    });
    setOnManageEntityModalCloseCallback(() => removeItem);
  };

  useEffect(() => {
    if (isEmpty(shiftDictionary)) loadShiftDictionary();
    if (shouldLoadReasonDictionaries) {
      loadReasonDictionaries();
    }
    if (shouldLoadDepartments) {
      loadDepartments();
    }
    if (shouldSetDepartmentByDefault) {
      updateDataField('departmentId', currentUserDepartmentId);
    }
    return () => discardState();
  }, []);

  useEffect(() => {
    loadRoutesDictionary(departmentId);
  }, [departmentId]);

  useEffect(() => {
    // if id is not valid
    if (shouldLoadInspectionById && !parseInt(id, 10)) {
      goBack();
    }
    if (shouldLoadInspectionById && parseInt(id, 10)) {
      getInspectionById(id);
    }
  }, [id]);

  useEffect(() => {
    if (isLoaded && !inspectionId) {
      history.push(routes.NOT_FOUND);
    }
  }, [isLoaded]);

  const handleDeleteInspection = () => {
    showDeleteInspectionPrompt();
  };

  const [unCollapsedTechnicalObjects, setUnCollapsedTechnicalObjects] = useState([]);

  if (isLoading && !isDictionariesLoaded) {
    return <ProgressBar />;
  }

  const allTechnicalObjectsAreUnCollapsed = unCollapsedTechnicalObjects.length === technicalObjects.length;

  const onClickCollapseButton = (technicalObjectId) => {
    if (includes(unCollapsedTechnicalObjects, technicalObjectId)) {
      setUnCollapsedTechnicalObjects(
        unCollapsedTechnicalObjects.filter(itemId => itemId !== technicalObjectId),
      );
    } else {
      setUnCollapsedTechnicalObjects([...unCollapsedTechnicalObjects, technicalObjectId]);
    }
  };

  const onClickCollapseAllButton = () => {
    if (allTechnicalObjectsAreUnCollapsed) {
      setUnCollapsedTechnicalObjects([]);
    } else {
      setUnCollapsedTechnicalObjects(
        technicalObjects.map(({ id: technicalObjectId }) => technicalObjectId),
      );
    }
  };

  const renderHighPriority = () => {
    if (taskPriority === LOW) return null;
    return (
      <div className={classes.priorityReadOnly}>
        <InspectionTypeIcon taskPriority={HIGH} />
        <div>{t('HIGH_PRIORITY')}</div>
      </div>
    );
  };

  const renderIsOrderRequiredReadOnly = () => {
    if (!isOrderRequired) return null;
    return (
      <div className={classes.orderRequiredReadOnly}>
        <Tooltip title={t('ORDER_REQUIRED_IS_ON')}>
          <div className={classes.orderRequiredIconWrapper}>
            <OrderRequiredIcon className={classes.orderRequiredIcon} />
          </div>
        </Tooltip>
        <div>{t('ORDER_REQUIRED_SHORT')}</div>
      </div>
    );
  };

  return (
    !isLoading && (
      <Panel
        mode={viewerId ? 'onlyView' : mode}
        viewItem={viewItem}
        saveItem={saveItem}
        goBack={goBack}
        deleteItem={handleDeleteInspection}
        labelGoToView={t('GO_BACK')}
        labelGoToList={t('GO_BACK')}
        labelView={title}
        labelCreate={t('INSPECTION_CREATION')}
        actions={actions}
      >
        {(isDeleting || isUpdating) && <ProgressBar />}
        {showInspectionExecutionBlock && (
          <InspectionExecutionBlock
            data={inspection}
            taskRejectionReasonsDictionary={taskRejectionReasonsDictionary}
          />
        )}

        <PanelTitle className={classes.title}>{t('INSPECTION_ATTRIBUTES')}</PanelTitle>

        <div className={classes.twoColumns}>
          <div className={classes.column}>
            <div className={classes.unitedBlock}>
              <DateInput
                className={classes.expectedStartDate}
                required
                isReadonly={isReadonlyByMode}
                disablePast
                label={t('START_DATE')}
                name="expectedStartDate"
                value={expectedStartDate}
                onChange={value => updateDataField('expectedStartDate', value)}
                data-test="expectedStartDate"
                OptionRendererProps={{ wrapperClass: classes.expectedStartDateOptionRenderer }}
              />
              {isReadonlyByMode ? (
                renderHighPriority()
              ) : (
                <FormSwitch
                  label={t('HIGH_PRIORITY')}
                  name="taskPriority"
                  checked={taskPriority === 1}
                  onChange={(e) => {
                    const checked = get(e, 'target.checked');
                    updateDataField('taskPriority', checked ? HIGH_PRIORITY : LOW_PRIORITY);
                  }}
                  data-test="switch-taskPriority"
                />
              )}
            </div>
            {isReadonlyByMode && (
            <OptionRenderer
              value={authorName}
              title={t('AUTHOR')}
              data-test="authorName"
            />
            )}
          </div>
          <div className={classes.column}>
            <Input
              required
              fullOutlined
              label={t('SHIFT')}
              placeholder={t('SELECT_VALUE')}
              value={shift}
              name="shift"
              onChange={(value) => {
                const newValue = get(value, 'enumValue');
                updateDataField('shift', newValue);
              }}
              disabled={isEmpty(shiftDictionary)}
              type="selectAlpha"
              isReadonly={isReadonlyByMode}
              options={shiftDictionary}
              optionValueKey="enumValue"
              optionTitleKey="value"
            />
            <Assignments
              name="assignments"
              value={!isEmpty([currentAssignee, ...assignments].filter(it => it))}
              type="custom"
              required
              isReadonlyByMode={isReadonlyByMode}
            />
          </div>
        </div>
        <div className={classes.routeBlock}>
          <div className={classes.routeBlockHeader}>
            <div className={classes.routeBlockHeaderCenterPart}>
              <div className={classes.routeBlockLabel}>{t('INSPECTION_ROUTE')}</div>
              {isReadonlyByMode ? (
                renderIsOrderRequiredReadOnly()
              ) : (
                <Tooltip
                  title={t(isOrderRequired ? 'ORDER_REQUIRED_IS_ON' : 'ORDER_REQUIRED_IS_OFF')}
                >
                  <div>
                    <FormSwitch
                      label={t('ORDER_REQUIRED_SHORT')}
                      name="isOrderRequired"
                      checked={isOrderRequired}
                      onChange={() => updateDataField('isOrderRequired', !isOrderRequired)}
                      data-test="switch-isOrderRequired"
                    />
                  </div>
                </Tooltip>
              )}
            </div>
            {showTechnicalObjects && (
              <div className={classes.collapseAllWrapper}>
                <ButtonBase
                  disableRipple
                  className={classes.collapseButton}
                  onClick={onClickCollapseAllButton}
                >
                  {allTechnicalObjectsAreUnCollapsed ? (
                    <IconFolder className={classes.folderIcon} />
                  ) : (
                    <IconOpenFolder className={classes.folderIcon} />
                  )}
                  <span>
                    {allTechnicalObjectsAreUnCollapsed ? t('UNCOLLAPSE_ALL') : t('COLLAPSE_ALL')}
                  </span>
                </ButtonBase>
              </div>
            )}
          </div>
          <PanelBox>
            {!isReadonlyByMode && (
              <>
                <Input
                  required={!(isDepartmentsReadonly || isMechanic)}
                  fullOutlined
                  label={t('DEPARTMENT')}
                  placeholder={t('SELECT_VALUE')}
                  value={departmentId}
                  name="departmentId"
                  disabled={isEmpty(departmentsDictionary)}
                  onChange={value => updateDataField('departmentId', value ? value.id : null)}
                  type="selectAlpha"
                  isReadonly={isDepartmentsReadonly || isMechanic}
                  options={departmentsDictionary}
                  optionValueKey="id"
                  optionTitleKey="name"
                />
                <DottedLoader isLoading={isRoutesLoading}>
                  <Input
                    required
                    fullOutlined
                    label={t('ROUTE')}
                    placeholder={t('SELECT_VALUE')}
                    value={routeId}
                    name="routeId"
                    disabled={isEmpty(routesDictionary)}
                    onChange={value => updateDataField('routeId', value ? value.id : null)}
                    type="selectAlpha"
                    isReadonly={isReadonlyByMode}
                    options={routesDictionary}
                    optionValueKey="id"
                    optionTitleKey="name"
                  />
                </DottedLoader>
                {!showTechnicalObjects && (
                  <RouteValidationBlock
                    departmentId={departmentId}
                    routeId={routeId}
                    hasErrors={hasNotValidFields}
                  />
                )}
              </>
            )}
            {showTechnicalObjects
              && technicalObjects.map((value) => {
                const technicalObjectId = get(value, 'id');
                const technicalObjectKey = `technicalObject${technicalObjectId}`;
                return (
                  <div key={technicalObjectKey} className={classes.routeTechObject}>
                    <RouteTechObject
                      key={technicalObjectKey}
                      mode="view"
                      data={value}
                      onClickCollapseButton={() => onClickCollapseButton(technicalObjectId)}
                      isExpandedMeasurementPoints={includes(
                        unCollapsedTechnicalObjects,
                        technicalObjectId,
                      )}
                      isHoverBlocked
                      operationRejectReasonDictionary={operationRejectReasonDictionary}
                      operationPauseReasonDictionary={operationPauseReasonDictionary}
                      scanFailReasonDictionary={scanFailReasonDictionary}
                    />
                  </div>
                );
              })}
          </PanelBox>
        </div>
        {renderManageEntityModal()}
      </Panel>
    )
  );
});

TaskRouteDetails.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
};

TaskRouteDetails.defaultProps = {
  id: 0,
};

export default TaskRouteDetails;
