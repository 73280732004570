import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  filterButton: {
    width: 32,
    height: 32,
    padding: '6px 4px 2px 4px',
  },
  filterButtonIcon: {
    position: 'relative',
    color: theme.palette.primaryLight2,
    height: 24,
    width: 24,
  },
  hasActiveFiltersBadge: {
    position: 'absolute',
    top: 10,
    left: '65%',
    width: 10,
    height: 10,
    color: theme.palette.activeFilter
  },
  opened: {
    '&.MuiButtonBase-root': {
      backgroundColor: theme.palette.lightSelection
    },
  },
}));

export default useStyles;
