import BrigadeAgent from 'ApiAgents/Brigades/BrigadeAgent';
import {
  action, observable, runInAction, makeObservable
} from 'mobx';
import { addBrigadier } from 'Src/utils/brigade';
import { getByRoleQuery } from 'Src/utils/roles';
import { PERFORMER_RU } from 'Shared/constants/roles';

const BRIGADE_EMPLOYEES_ROLES = [PERFORMER_RU];
const brigadeAgent = new BrigadeAgent();


export class BrigadeStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable brigades = [];

  @observable managedBrigade = null;

  @observable brigadesList = [];

  @observable employeesList = [];

  @observable updatingBrigade = null;

  @observable isLoading = false;

  @observable isLoadingByPage = false;

  @observable isDeleting = false;

  @observable isBrigadeListLoaded = false;

  @observable isUpdatingBrigadeLoaded = false;

  @observable isManagedBrigadeLoaded = false;

  @observable isEmployeesLoaded = false;

  @observable totalPages = null;

  @observable hasNextPage = null;

  @observable pageNumber = null;

  @observable pageSize = null;

  checkIfAssignedToPromise = id => brigadeAgent.checkIfAssignedTo(id);

  checkIfAssignedTo = async (id) => {
    if (!id) {
      throw new Error('Error in Assign Check: no id provided');
    }
    this.isLoading = true;
    try {
      const isAssigned = await this.checkIfAssignedToPromise(id);
      runInAction(() => {
        this.isLoading = false;
      });
      return isAssigned;
    } catch (error) {
      console.log('ERROR in Assign Check: ', error);
      runInAction(() => {
        this.isLoading = false;
      });
      throw new Error(error);
    }
  };

  @action delete = async (id) => {
    if (!id) {
      throw new Error('Error in delete brigade: no id provided');
    }
    this.isDeleting = true;
    try {
      const isAssigned = await this.checkIfAssignedToPromise(id);
      if (isAssigned) {
        runInAction(() => {
          this.isDeleting = false;
        });
        return false;
      }
      await brigadeAgent.delete(id);
      runInAction(() => {
        this.isDeleting = false;
      });
      return true;
    } catch (error) {
      console.log('ERROR in DELETE BRIGADE: ', error);
      runInAction(() => {
        this.isDeleting = false;
      });
      throw new Error(error);
    }
  };

  transformManagedBrigade = ({
    brigadier: { id: brigadierId }, selected, title, id
  }) => (
    {
      id,
      name: title,
      brigadierId,
      orgUnitId: this.rootStore.userStore.orgUnitRootId,
      users: [...selected.map(({ id: userId }) => userId), brigadierId]
    }
  );

  @action filterBrigadeToUpdate = (id) => {
    this.updatingBrigade = this.brigadesList.find(brigade => (
      brigade.id === id
    ));
  };

  @action clearEditingState = () => {
    this.updatingBrigade = null;
    this.isUpdatingBrigadeLoaded = false;
  };

  addBrigadiers = items => items.map(brigade => addBrigadier(brigade));

  @action loadAll = async (pageNumber, pageSize) => {
    const isPaginating = !!pageNumber;
    this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', true);
    let pagingOptions = { items: [], totalPages: null, hasNextPage: false };

    const {
      userStore: {
        orgUnitRootId,
      }
    } = this.rootStore;

    this.pageNumber = pageNumber;
    this.pageSize = pageSize;

    try {
      const brigadesList = await brigadeAgent.fetchAllByOrgUnit(orgUnitRootId, isPaginating, pageNumber, pageSize);

      if (isPaginating) {
        pagingOptions = brigadesList;
      }

      const { items, totalPages, hasNextPage } = pagingOptions;
      runInAction(() => {
        this.brigadesList = this.addBrigadiers(isPaginating ? items : brigadesList);
        this.isBrigadeListLoaded = true;
        this.totalPages = totalPages;
        this.hasNextPage = hasNextPage;
      });
      this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', false);
    } catch (error) {
      runInAction(() => {
        this.brigadesList = [];
        this.isBrigadeListLoaded = true;
        this.totalPages = 0;
        this.hasNextPage = false;
      });
      console.log('ERROR in FETCHING BRIGADE LIST: ', error);
      this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', false);
      throw new Error(error);
    }
  };

  @action loadPotentialAssignees = async () => {
    this.isLoading = true;

    try {
      const employees = await this.rootStore.userStore.loadUsersByRole({ roles: getByRoleQuery(BRIGADE_EMPLOYEES_ROLES) });

      runInAction(() => {
        this.employeesList = employees;
        this.isEmployeesLoaded = true;
      });
    } catch (error) {
      console.log('ERROR in FETCHING BRIGADE BY ID: ', error);
    }
    runInAction(() => {
      this.isLoading = false;
    });
  };

  @action loadUpdatingBrigade = async (id) => {
    this.isLoading = true;
    try {
      const brigade = await brigadeAgent.filterById(id);
      runInAction(() => {
        this.updatingBrigade = addBrigadier(brigade);
        this.isUpdatingBrigadeLoaded = true;
      });
    } catch (error) {
      console.log('ERROR in FETCHING BRIGADE BY ID: ', error);
    }
    runInAction(() => {
      this.isLoading = false;
    });
  };

  @action loadManagedBrigade = async (id) => {
    this.isLoading = true;
    try {
      const brigade = await brigadeAgent.filterById(id);
      runInAction(() => {
        this.managedBrigade = addBrigadier(brigade);
        this.isManagedBrigadeLoaded = true;
      });
    } catch (error) {
      console.log('ERROR in FETCHING BRIGADE BY ID: ', error);
    }
    runInAction(() => {
      this.isLoading = false;
    });
  };

  // eslint-disable-next-line consistent-return
  @action manage = async (brigade, isUpdating) => {
    this.isLoading = true;
    const brigadeAction = isUpdating ? brigadeAgent.update : brigadeAgent.create;
    try {
      const managedBrigade = await brigadeAction(brigade);
      return managedBrigade.id;
    } catch (error) {
      console.log('ERROR in BRIGADE CREATION: ', error);
    }
    runInAction(() => {
      this.isLoading = false;
    });
  };
}

export default BrigadeStore;
