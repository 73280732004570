import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  isArray,
  isNull,
  assign,
  isEmpty,
  toSafeInteger,
  get,
  find,
} from 'lodash';
import {
  Box,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  FormControl,
  InputLabel,
  FormLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  Checkbox,
  Chip,
  Switch,
  TextField,
  InputAdornment,
  Grid,
} from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { parseISO, format } from 'date-fns';
import { isValid } from 'Src/utils/datetime';
import clsx from 'clsx';
import {
  CheckBoxOutlineBlank,
  HighlightOff,
  Search,
  CheckBox,
  Add,
} from '@material-ui/icons/';
import Datepicker from 'Common/components/Forms/DateTimePicker/components/Datepicker';
import withValidation from 'Src/HOC/withValidation';

import useStyles from './styles';

const Input = ({
  type,
  min,
  max,
  value,
  name,
  label,
  placeholder,
  onChange,
  required,
  isReadonly,
  options,
  isOptionValue,
  render,
  optionValueKey,
  optionTitleKey,
  getOptionTitle,
  isBordered,
  iconRight,
  customRender,
  disabled,
  fullOutlined,
  optionWithCheckbox,
  errorMessage,
  inputProps,
  withSearch,
  listBoxMaxHeight,
  multiLine,
  fullWidth,
  InputEndAdornment,
  marginBottom,
  marginTop,
  inputRef,
  error,
  helperText,
  requiredMessage,
  ...props
}) => {
  const showIsRequiredLabel = required && !isReadonly;
  const { t } = useTranslation();
  const classes = useStyles({
    value,
    isBordered,
    listBoxMaxHeight,
    fullWidth,
    marginBottom,
    marginTop,
    error,
    disabled,
    helperText,
    requiredMessage,
    t
  });

  const icons = {
    search: <Search />
  };

  const attr = {};

  if (iconRight || InputEndAdornment) {
    attr.endAdornment = (
      <InputAdornment>
        {iconRight && icons[iconRight]}
        {InputEndAdornment}
      </InputAdornment>
    );
  }

  if (type === 'custom') {
    return (
      <FormControl
        variant="outlined"
        className={clsx(
          classes.formControl,
          isReadonly && classes.readOnly,
        )}
        margin="dense"
        fullWidth
        error={error}
        {...props}
      >
        <InputLabel shrink={Boolean(value)} id={name}>
          {label}
        </InputLabel>
        {render}
      </FormControl>
    );
  }

  if (type === 'select') {
    let menuProps;
    if (fullOutlined) {
      menuProps = {
        classes: { paper: classes.selectPaper },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        getContentAnchorEl: null
      };
    }
    return (
      <FormControl
        variant="outlined"
        className={clsx(
          classes.formControl,
          isReadonly && classes.readOnly,
          showIsRequiredLabel && classes.required,
        )}
        margin="dense"
        {...props}
      >
        <InputLabel id={name}>
          {label}
        </InputLabel>
        <Select
          error={Boolean(error || errorMessage)}
          id={name}
          data-test={name}
          name={name}
          value={!isEmpty(options) ? value : ''}
          onChange={event => onChange(event.target.value, event.target.name)}
          label={label}
          readOnly={isReadonly}
          disabled={disabled}
          className={clsx(classes.select, customRender ? classes.withoutVerticalPadding : '')}
          MenuProps={menuProps}
        >
          {options.map(option => (
            <MenuItem
              className={classes.selectItem}
              key={option[optionValueKey]}
              value={isOptionValue ? option : option[optionValueKey]}
            >
              {customRender ? customRender(option) : option[optionTitleKey]}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{errorMessage}</FormHelperText>
      </FormControl>
    );
  }

  if (type === 'search') {
    const selectedValue = options.reduce((acc, option) => (option[optionValueKey] === value ? option : acc), null);

    return (
      <Autocomplete
        clearText=""
        closeText=""
        openText=""
        value={selectedValue}
        id={name}
        onChange={(event, val) => onChange(val && val[optionValueKey], name)}
        options={options}
        disabled={Boolean(isReadonly)}
        getOptionLabel={({
          [optionValueKey]: optionValue,
          [optionTitleKey]: optionTitle = ''
        }) => (!isNull(optionTitle) ? optionTitle || '' : optionValue || '').toString()}
        renderInput={params => (
          <TextField
            className={clsx(
              classes.formControl,
              classes.textField,
              isReadonly ? classes.readOnly : '',
              showIsRequiredLabel && classes.required,
            )}
            {...params}
            variant="outlined"
            margin="dense"
            label={label}
            error={error}
          />
        )}
      />
    );
  }

  if (type === 'selectAlpha') {
    const autocompleteClasses = { clearIndicator: clsx(!value && classes.hidden) };
    if (fullOutlined) {
      assign(autocompleteClasses, { popper: classes.popper });
    }
    const inputValue = find(options, { [optionValueKey]: value }) || null;

    const [openedTips, seOpenedTips] = useState(false);
    return (
      <div style={{ position: 'relative' }}>
        <Autocomplete
          onOpen={() => seOpenedTips(true)}
          onClose={() => seOpenedTips(false)}
          noOptionsText={t('NOTHING_FOUND')}
          clearText=""
          closeText=""
          openText=""
          openOnFocus
          disablePortal
          value={inputValue}
          id={name}
          classes={autocompleteClasses}
          onChange={(event, val) => {
            onChange(val, name);
          }}
          options={options}
          disabled={Boolean(isReadonly) || disabled}
          getOptionLabel={(option) => {
            if (getOptionTitle) return getOptionTitle(option);
            const {
              [optionValueKey]: optionValue,
              [optionTitleKey]: optionTitle = ''
            } = option;
            return (!isNull(optionTitle) ? optionTitle || '' : optionValue || '').toString();
          }}
          renderOption={(option, { selected }) => (
            <>
              {customRender
                ? customRender(option)
                : (
                  <div
                    className={classes.options}
                  >
                    {optionWithCheckbox && (
                      <Checkbox
                        icon={<CheckBoxOutlineBlank />}
                        checkedIcon={<CheckBox color="primary" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                    )}
                    {getOptionTitle ? getOptionTitle(option) : option[optionTitleKey]}
                  </div>
                )}
            </>
          )}
          renderInput={({
            inputProps: inputDefinedProps = {}, InputProps: InputDefinedProps, ...rest
          } = {}) => {
            const dataTestAttribute = get(inputDefinedProps, 'data-test');
            const textFieldValue = get(inputDefinedProps, 'value', '');
            const showSearchIcon = withSearch && openedTips && (!value || textFieldValue !== value.toString());
            const newParams = {
              inputProps: {
                ...inputDefinedProps,
                // autoComplete: 'none',
                readOnly: !withSearch,
                ...dataTestAttribute && { 'data-test': `${dataTestAttribute}-input` },
              },
              ...rest,
              InputProps: {
                ...InputDefinedProps,
                ...showSearchIcon && openedTips && {
                  startAdornment: (
                    <InputAdornment style={{ color: '#a1a1a1' }}>
                      {icons.search}
                    </InputAdornment>
                  )
                },
              }
            };
            return (
              <TextField
                autoComplete="off"
                data-test={dataTestAttribute}
                multiline={multiLine}
                type="text"
                onKeyDown={(event) => {
                  if (withSearch) return;
                  event.stopPropagation();
                  event.preventDefault();
                }}
                onPaste={(event) => {
                  if (withSearch) return;
                  event.stopPropagation();
                  event.preventDefault();
                }}
                {...{ ...openedTips && { placeholder } }}
                className={clsx(
                  classes.autocompleteInput,
                  !withSearch && classes.styledLikeButton,
                  classes.textField,
                  isReadonly && classes.readOnly,
                  showIsRequiredLabel && classes.required,
                )}
                {...newParams}
                variant="outlined"
                margin="dense"
                label={label}
                error={error}
              />
            );
          }}
        />
      </div>
    );
  }

  if (type === 'tags') {
    let currentValue = [];
    if (value && isArray(value)) {
      currentValue = value;
    } else if (value) {
      currentValue = [value];
    }
    const selectedValues = options.filter(({ [optionValueKey]: key }) => currentValue.includes(key));
    const autocompleteClasses = {
      popupIndicator: classes.popupIndicator,
    };
    if (fullOutlined) {
      assign(autocompleteClasses, { popper: classes.autocompletePopper });
    }
    return (
      <Autocomplete
        clearText=""
        closeText=""
        openText=""
        disableCloseOnSelect
        multiple
        value={selectedValues}
        id={name}
        classes={autocompleteClasses}
        popupIcon={<Add />}
        onChange={(event, vals) => onChange(vals, name)}
        options={options}
        disabled={Boolean(isReadonly) || disabled}
        getOptionLabel={({
          [optionValueKey]: optionValue,
          [optionTitleKey]: optionTitle = ''
        }) => (!isNull(optionTitle) ? optionTitle || '' : optionValue || '').toString()}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={<CheckBoxOutlineBlank />}
              checkedIcon={<CheckBox color="primary" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option[optionTitleKey]}
          </React.Fragment>
        )}
        renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
          <Chip
            variant="outlined"
            deleteIcon={<HighlightOff />}
            label={option[optionTitleKey]}
            {...getTagProps({ index })}
            className={classes.autocompleteChip}
          />
        ))}
        renderInput={params => (
          <TextField
            type="button"
            className={clsx(
              classes.formControl,
              classes.textField,
              isReadonly ? classes.readOnly : ''
            )}
            {...params}
            variant="outlined"
            margin="dense"
            label={label}
          />
        )}
      />
    );
  }

  if (type === 'date') {
    const valueDate = value ? new Date(parseISO(value)) : null;
    // const restInputProps = { ...props };
    // if (helperText) assign(restInputProps, { helperText });
    return (
      <Box className={clsx(
        classes.formControlDate,
        isReadonly ? classes.readOnly : '',
        showIsRequiredLabel ? classes.formControlDateIsRequired : '',
      )}
      >
        <Datepicker
          fullWidth
          required={showIsRequiredLabel}
          label={label}
          value={valueDate}
          disableToolbar
          variant="inline"
          onChange={(val) => {
            if (isValid(val, true)) {
              onChange(format((val), 'yyyy-MM-dd'));
            } else {
              onChange(val);
            }
          }}
          readOnly={isReadonly}
          inputProps={{
            readOnly: Boolean(isReadonly),
            disabled: Boolean(isReadonly),
          }}
          error={error}
          tooltip="test"
        />
      </Box>

    );
  }

  if (['text', 'number', 'time'].includes(type)) {
    const restInputProps = { ...props };
    if (helperText) assign(restInputProps, { helperText });
    const onlyPositiveNumbers = (type === 'number' && min && toSafeInteger(min) >= 0);
    return (
      <TextField
        type={type}
        className={clsx(
          classes.formControl,
          classes.textField,
          isReadonly && classes.readOnly,
          showIsRequiredLabel && classes.required,
        )}
        value={value || ''}
        id={name}
        name={name}
        label={label}
        variant="outlined"
        margin="dense"
        InputProps={{
          inputRef,
          onKeyDown: (event) => {
            if (!onlyPositiveNumbers) return;
            const { keyCode } = event;
            const minusKeyCodes = [109, 189, 173];
            if (minusKeyCodes.includes(keyCode)) {
              event.preventDefault();
              event.stopPropagation();
            }
          },
          inputProps: {
            min, max, ...inputProps,
          },
          readOnly: Boolean(isReadonly),
          disabled: Boolean(isReadonly),
          ...attr,
        }}
        onChange={(event) => {
          let val = get(event, 'target.value', '');
          const inputName = get(event, 'target.name', '');
          if (onlyPositiveNumbers) val = val.replace(/[^0-9]/g, '');
          onChange(val, inputName);
        }}
        fullWidth
        error={error}
        {...restInputProps}
      />
    );
  }

  if (type === 'textarea') {
    throw new Error('textarea in input.js');
    return ( // eslint-disable-line
      <FormControl
        variant="outlined"
        className={clsx(
          classes.formControl,
          isReadonly ? classes.readOnly : ''
        )}
        margin="dense"
        fullWidth
        error={error}
        {...props}
      >
        <InputLabel shrink={Boolean(value)} id={name}>
          {label}
        </InputLabel>
        <TextareaAutosize
          value={value || ''}
          variant="outlined"
          readOnly={isReadonly}
          className={classes.textArea}
          rowsMin={1}
        />
      </FormControl>
    );
  }

  if (type === 'switch') {
    const { onClick } = props;
    return (
      <FormControl
        className={clsx(
          classes.formControlRadio,
        )}
        margin="normal"
        fullWidth
        error={error}
        {...props}
      >
        <FormControlLabel
          control={(
            <Switch
              onClick={onClick}
              checked={value}
              onChange={(event, val) => onChange(val, name)}
              color="primary"
              name={name}
              inputProps={{
                'aria-label': 'primary checkbox',
                'data-test': `switch-${name}`
              }}
            />
          )}
          disabled={disabled || isReadonly}
          label={label}
        />
      </FormControl>
    );
  }

  if (type === 'checkbox') {
    return (
      <FormControl
        className={clsx(
          classes.formControlRadio,
        )}
        margin="dense"
        fullWidth
        {...props}
      >
        <FormControlLabel
          control={(
            <Checkbox
              checked={value}
              onChange={(event, val) => onChange(val, name)}
              color="primary"
              name={name}
              inputProps={{
                'aria-label': 'primary checkbox',
                'data-test': `checkbox-${name}`
              }}
            />
          )}
          disabled={disabled || isReadonly}
          label={label}
        />
      </FormControl>
    );
  }

  if (type === 'radio') {
    return (
      <FormControl
        className={classes.formControlRadio}
        margin="dense"
        fullWidth
        {...props}
      >
        <FormLabel className={classes.formLabelRadio}>{label}</FormLabel>
        <RadioGroup
          aria-label={name}
          name={name}
          value={value}
          onChange={event => onChange(event.target.value)}
          style={{
            flexDirection: 'row'
          }}
        >
          {options.map(option => (
            <FormControlLabel
              key={option.value}
              data-test={option.enumValue || option.value}
              data-active={option.value === value}
              value={option.value}
              label={option.title}
              className={classes.labelRadio}
              control={(<Radio color="primary" className={classes.radio} />)}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }

  return (
    <Grid classes={classes.viewer}>
      <Typography className={classes.viewerLabel}>{label}</Typography>
      <div className={classes.viewerValue}>
        {value}
      </div>
    </Grid>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.array,
    PropTypes.any,
  ]),
  required: PropTypes.bool,
  isReadonly: PropTypes.bool,
  multiLine: PropTypes.bool,
  fullWidth: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  isOptionValue: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  render: PropTypes.node,
  optionValueKey: PropTypes.string,
  optionTitleKey: PropTypes.string,
  getOptionTitle: PropTypes.func,
  isBordered: PropTypes.bool,
  iconRight: PropTypes.string,
  customRender: PropTypes.func,
  disabled: PropTypes.bool,
  fullOutlined: PropTypes.bool,
  optionWithCheckbox: PropTypes.bool,
  withSearch: PropTypes.bool,
  inputProps: PropTypes.any, // eslint-disable-line
  listBoxMaxHeight: PropTypes.number,
  InputEndAdornment: PropTypes.node,
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  helperText: PropTypes.string,
  requiredMessage: PropTypes.string,
};

Input.defaultProps = {
  type: 'text',
  label: '',
  placeholder: '',
  value: '',
  required: false,
  isReadonly: false,
  multiLine: false,
  fullWidth: false,
  options: [],
  isOptionValue: false,
  onChange: () => {},
  onClick: () => {},
  render: null,
  optionValueKey: '',
  optionTitleKey: '',
  getOptionTitle: null,
  isBordered: false,
  min: '',
  max: '',
  iconRight: '',
  errorMessage: '',
  error: false,
  customRender: null,
  disabled: false,
  fullOutlined: false,
  optionWithCheckbox: false,
  withSearch: false,
  listBoxMaxHeight: undefined,
  InputEndAdornment: null,
  marginBottom: undefined,
  marginTop: undefined,
  inputRef: {},
  helperText: '',
  requiredMessage: '',
};

export default withValidation(Input);
