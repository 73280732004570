import { columnsType } from 'Business/plant/constants';

import ParameterTypeColumn from './columns/ParameterTypeColumn';
import DataTypeColumn from './columns/DataTypeColumn';
import UnitsColumn from './columns/UnitsColumn';
import PositionsInSelectColumn from './columns/PositionsInSelectColumn';
import OperationKeysColumn from './columns/OperationKeysColumn';

export const columnsData = {
  [columnsType.PARAMETER_TYPE]: ParameterTypeColumn,
  [columnsType.DATA_TYPE]: DataTypeColumn,
  [columnsType.UNIT]: UnitsColumn,
  [columnsType.OPERATION_KEYS]: OperationKeysColumn,
  [columnsType.DIRECTORIES]: PositionsInSelectColumn,
};

export const getDefaultValueForColumn = (columnType) => {
  switch (columnType) {
    case columnsType.OPERATION_KEYS:
      return [];
    case columnsType.DIRECTORIES:
      return [];
    default:
      return null;
  }
};

export const defaultColumn = {
  reconciliationKey: columnsType.PARAMETER_TYPE,
  type: columnsType.PARAMETER_TYPE,
  value: null,
  Component: ParameterTypeColumn,
};

export const getModalColumnsData = (data) => {
  const res = [];

  if (data[columnsType.PARAMETER_TYPE]) {
    res.push({
      reconciliationKey: `${data.id}_${columnsType.PARAMETER_TYPE}`,
      type: columnsType.PARAMETER_TYPE,
      value: data[columnsType.PARAMETER_TYPE],
      Component: ParameterTypeColumn,
    });
  }

  if (data[columnsType.DATA_TYPE]) {
    res.push({
      reconciliationKey: `${data.id}_${columnsType.DATA_TYPE}`,
      type: columnsType.DATA_TYPE,
      value: data[columnsType.DATA_TYPE],
      Component: DataTypeColumn,
    });
  }

  if (data[columnsType.UNIT]) {
    res.push({
      reconciliationKey: `${data.id}_${columnsType.UNIT}`,
      type: columnsType.UNIT,
      value: data[columnsType.UNIT],
      Component: UnitsColumn,
    });
  }

  if (data[columnsType.DIRECTORIES].length) {
    res.push({
      reconciliationKey: `${data.id}_${columnsType.DIRECTORIES}`,
      type: columnsType.DIRECTORIES,
      value: data[columnsType.DIRECTORIES],
      Component: PositionsInSelectColumn,
    });
  }

  if (data[columnsType.OPERATION_KEYS]) {
    res.push({
      reconciliationKey: `${data.id}_${columnsType.OPERATION_KEYS}`,
      type: columnsType.OPERATION_KEYS,
      value: data[columnsType.OPERATION_KEYS],
      Component: OperationKeysColumn,
    });
  }

  return res;
};

export default module;
