import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  overlay: {
    minWidth: 1024,
    '& *': {
      outline: 'none',
    },
  },
  leftInput: {
    width: 320,
    marginRight: theme.spacing(2),
    '& input[type=text]': {
      height: 19,
    },
  },
  headerContainer: {
    padding: theme.spacing(1, 2),
  },
  dottedLoader: {
    display: 'flex',
    flexGrow: 1,
  },
}));

export default useStyles;
