import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SectionListItem from 'Common/widgets/Section/SectionListItem';
import Section from 'Common/widgets/Section/Section';
import { ParameterName, columnsType } from 'Business/plant/constants';

import { ReactComponent as SelectArrow } from './assets/arrow-with-scribble.svg';
import useStyles from './styles';

const DataTypeColumn = ({
  activateNextColumn, index, defValue, gridWidth, setColumnValue,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const handleParameterClick = (type) => {
    setColumnValue(type, columnsType.DATA_TYPE);
    if (type === ParameterName.TEXT) {
      activateNextColumn(type, columnsType.OPERATION_KEYS, index);
    }
    if (type === ParameterName.NUMERIC) {
      activateNextColumn(type, columnsType.UNIT, index);
    }
  };

  return (
    <Section title={t('DATA_TYPE')} gridWidth={gridWidth} customClass={classes.sectionRoot}>
      <SectionListItem
        onItemClick={() => handleParameterClick(ParameterName.TEXT)}
        title={t('TEXT_INPUT')}
        isCurrentlySelected={defValue === ParameterName.TEXT}
        noAction
      />
      <SectionListItem
        onItemClick={() => handleParameterClick(ParameterName.NUMERIC)}
        title={t('NUMERIC_INPUT')}
        isCurrentlySelected={defValue === ParameterName.NUMERIC}
        noAction
      />
      <div className={classes.selectArrow}>
        <SelectArrow />
        <div className={classes.arrowText}>{t('SPECIFY_DATA_TYPE')}</div>
      </div>
    </Section>
  );
};

DataTypeColumn.propTypes = {
  activateNextColumn: PropTypes.func.isRequired,
  setColumnValue: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  defValue: PropTypes.string,
  gridWidth: PropTypes.number.isRequired,
};

DataTypeColumn.defaultProps = {
  defValue: '',
};

export default DataTypeColumn;
