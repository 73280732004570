import Agent from 'ApiAgents/Agent';

export default class SharePointSettingsAgent extends Agent {
  loadList = () => this.request({
    url: '/sharepointSettings/items',
    method: 'GET',
  }, 'tasks');

  getSettingsByOsrId = osrId => this.request({
    url: '/sharepointSettings/item',
    method: 'GET',
    params: { id: osrId },
  }, 'tasks');

  update = data => this.request({
    url: '/sharepointSettings',
    method: 'PUT',
    data: { ...data },
  }, 'tasks');
}
