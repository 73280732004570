import PauseReasonsAgent from 'ApiAgents/Dictionary/PauseReasonsAgent';
import {
  action, observable, runInAction, makeObservable,
} from 'mobx';
import {
  findIndex, cloneDeep, get, find
} from 'lodash';


const pauseReasonsAgent = new PauseReasonsAgent();

const initialPauseReason = { isVisible: true };

export class PauseReasonsDictionaryStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable list = [];

  @observable pauseReason = initialPauseReason;

  @observable isUpdating = false;

  @observable isUpdatingPauseReason = false;

  @observable addingPauseReason = false;

  @observable updatingIsVisibleArray = [];

  @observable updatingIsPhotoRequiredArray = [];

  @observable isUpdatingOrder = false;

  @observable isLoading = false;

  @observable isListLoading = false;

  @observable isLoaded = false;

  @action getPauseReasonById = (id) => {
    const pauseReason = find(this.list, ({ id: pauseReasonId }) => pauseReasonId === id);
    if (pauseReason) {
      this.pauseReason = pauseReason;
      return;
    }
    this.pauseReason = {};
    return { error: 'cant find pause reason' };
  };

  @action updateDefectData = async () => {
    this.isUpdating = true;
    try {
      await pauseReasonsAgent.updateDefectData(this.defectData);

      runInAction(() => {
        this.isUpdating = false;
      });

      return { error: false };
    } catch (error) {
      console.log('ERROR UPDATING DEFECT', error);
      runInAction(() => {
        this.isUpdating = false;
      });
      return { error };
    }
  };

  @action discardState = () => {
    this.list = [];
    this.isUpdatingPauseReason = false;
    this.addingPauseReason = false;
    this.updatingIsVisibleArray = [];
    this.updatingIsPhotoRequiredArray = [];
    this.isUpdatingOrder = false;
    this.isLoading = [];
    this.isListLoading = false;
    this.isLoaded = false;
  }

  @action loadList = async () => {
    this.isListLoading = true;
    this.isLoaded = false;
    try {
      const { items = [] } = await pauseReasonsAgent.getPauseReasons() || {};
      runInAction(() => {
        this.list = items;
        this.isListLoading = false;
        this.isLoaded = true;
      });
    } catch (error) {
      console.log('ERROR LOAD PAUSE REASONS: ', error);
      runInAction(() => {
        this.list = [];
        this.isListLoading = false;
        this.isLoaded = true;
      });
      throw new Error(error);
    }
  }

  @action updateListOrder = async (orderedList) => {
    this.isUpdatingOrder = true;
    try {
      const requestData = orderedList.map(({ id } = {}, index) => ({ id, order: index }));
      await pauseReasonsAgent.updatePauseReasonsOrder(requestData);
      runInAction(() => {
        this.list = orderedList;
        this.isUpdatingOrder = false;
      });
    } catch (error) {
      console.log('ERROR UPDATE PAUSE REASONS ORDER: ', error);
      runInAction(() => {
        this.isUpdatingOrder = false;
      });
      throw new Error(error);
    }
  }

  @action onChangeIsVisible = async (reasonId, isVisible) => {
    const tempOldList = cloneDeep(this.list);
    const list = cloneDeep(this.list);
    this.updatingIsVisibleArray = [...this.updatingIsVisibleArray, reasonId];
    try {
      const reasonIndex = findIndex(list, ({ id } = {}) => reasonId === id);
      list[reasonIndex] = { ...list[reasonIndex], isVisible };
      this.list = list;
      await pauseReasonsAgent.updatePauseReasons(list[reasonIndex]);
      runInAction(() => {
        this.list = list;
        this.updatingIsVisibleArray = this.updatingIsVisibleArray.filter(item => item !== reasonId);
      });
    } catch (error) {
      console.log('ERROR UPDATE PAUSE REASONS ORDER: ', error);
      runInAction(() => {
        this.list = tempOldList;
        this.updatingIsVisibleArray = this.updatingIsVisibleArray.filter(item => item !== reasonId);
      });
      throw new Error(error);
    }
  }

  @action onChangeIsPhotoRequired = async (reasonId, isPhotoRequired) => {
    const list = cloneDeep(this.list);
    const tempOldList = cloneDeep(this.list);
    const reasonIndex = findIndex(list, ({ id } = {}) => reasonId === id);
    list[reasonIndex] = { ...list[reasonIndex], attributes: { ...list[reasonIndex].attributes, isPhotoRequired } };
    this.list = list;

    this.updatingIsPhotoRequiredArray = [...this.updatingIsPhotoRequiredArray, reasonId];
    try {
      await pauseReasonsAgent.updatePauseReasons(list[reasonIndex]);
      runInAction(() => {
        this.list = list;
        this.updatingIsPhotoRequiredArray = this.updatingIsPhotoRequiredArray.filter(item => item !== reasonId);
      });
    } catch (error) {
      console.log('ERROR UPDATE PAUSE REASONS ORDER: ', error);
      runInAction(() => {
        this.list = tempOldList;
        this.updatingIsPhotoRequiredArray = this.updatingIsPhotoRequiredArray.filter(item => item !== reasonId);
      });
      throw new Error(error);
    }
  }

  @action discardEditorState = () => {
    this.pauseReason = initialPauseReason;
  }

  @action updateIsVisibleFromEditor = (isVisible) => {
    this.pauseReason = { ...this.pauseReason, isVisible };
  }

  @action updateTitleFromEditor = (title) => {
    this.pauseReason = { ...this.pauseReason, title };
  }

  @action updateCodeFromEditor = (externalCode) => {
    this.pauseReason = { ...this.pauseReason, externalCode };
  }

  @action updateIsPhotoRequiredFromEditor = (isPhotoRequired) => {
    this.pauseReason = { ...this.pauseReason, attributes: { ...this.pauseReason.attributes, isPhotoRequired } };
  }

  @action updatePauseReason = async () => {
    this.isUpdatingPauseReason = true;
    try {
      await pauseReasonsAgent.updatePauseReasons(this.pauseReason);
      const reasonId = get(this.pauseReason, 'id');
      if (!reasonId) return { error: true };
      const list = cloneDeep(this.list);
      const reasonIndex = findIndex(list, ({ id } = {}) => reasonId === id);
      list[reasonIndex] = this.pauseReason;
      runInAction(() => {
        this.list = list;
        this.isUpdatingPauseReason = false;
      });
      return { error: false };
    } catch (error) {
      console.log('ERROR UPDATE PAUSE REASONS: ', error);
      runInAction(() => {
        this.isUpdatingPauseReason = false;
      });
      return { error };
    }
  }

  @action addPauseReason = async () => {
    this.addingPauseReason = true;
    try {
      const isActive = get(this.pauseReason, 'isActive', false);
      const isPhotoRequired = get(this.pauseReason, 'attributes.isPhotoRequired', false);
      const { error = false } = await pauseReasonsAgent.addPauseReasons(
        {
          ...this.pauseReason,
          key: 'SubOperationStopReason',
          isActive,
          attributes: { isPhotoRequired },
        }
      );
      runInAction(() => {
        this.addingPauseReason = false;
      });
      return { error };
    } catch (error) {
      console.log('ERROR UPDATE PAUSE REASONS: ', error);
      runInAction(() => {
        this.addingPauseReason = false;
      });
      return { error };
    }
  }
}

export default PauseReasonsDictionaryStore;
