import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useStores from 'Store/useStores';
import * as routes from 'Shared/constants/routes';
import { assign, get } from 'lodash';
import { isValid as isValidDate } from 'Src/utils/datetime';
import {
  Grid,
  Tooltip,
} from '@material-ui/core/';
import DottedLoader from 'Common/components/Progress/components/Dotted';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import Panel, {
  PanelBox,
  PanelTitle,
  PanelSubTitle
} from 'Common/widgets/Layout/Panel/Panel';
import Input from 'Common/components/Forms/Input/Input';
import FormSwitch from 'Common/components/Forms/Input/FormSwitch';
import TextInput from 'Common/components/Forms/Input/Text';
import TimeInput from 'Common/components/Forms/Input/Time';
import NumberInput from 'Common/components/Forms/Input/Number';
import DateInput from 'Common/components/Forms/Input/Date';

import useStyles from './styles';

const AutomatedTaskDetails = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { id, action: mode = 'view' } = useParams();

  const {
    tasksStore: {
      dictionaries: {
        TpsInspectionRoles
      },
      getDictionaryByKeys,
    },
    inspectionTaskStore: {
      setMode,
      getInspectionTaskById,
      inspectionTaskData,
      taskRouteList,
      taskRouteListIsLoading,
      getTaskRouteList,
      isLoading,
      isUpdating,
      updateDataField,
      hoursIntervalList,
      helperTextDaysInterval,
      helperTextStartDelay,
      helperTextPlanDuration,
      helperTextStartDate,
      helperTextFinishDate,
      helperTextStartTime,
      helperTextFinishTime,
      save,
      reset,
      loadTpsId,
    },
    notificationStore: {
      enqueueSnackbar,
    },
    validationStore: {
      validateRequiredFields, isValidFields, hasErrors,
    },
    userStore: {
      rootOrgUnit: {
        name: tpsName,
        code
      }
    }
  } = useStores();

  const goBack = () => {
    const { state } = location;
    const { fromPath, ...rest } = state || {};
    history.push({
      pathname: fromPath || routes.INSPECTIONTASKS,
      state: rest,
    });
  };

  const viewItem = () => {
    history.push({
      pathname: routes.VIEW_INSPECTIONTASK.replace(':id', id)
    });
  };

  const removeItem = async () => {
    // const res = await deleteItem(id);
    // if (res) {
    //   goBack();
    // }
  };

  const editItem = () => {
    // history.push({
    //   pathname: routes.EDIT_INSPECTIONTASK
    //     .replace(':id', id)
    //     .replace(':action(edit)', 'edit')
    // });
  };

  const saveItem = async () => {
    validateRequiredFields();
    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('CANT_CREATE')
            }]
          }, {
            rowContent: [{
              type: 'text',
              text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS')
            }]
          }],
        },
        variant: 'error',
      });
      return;
    }
    const data = await save();
    if (data && data.automatedTaskId) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('AUTOMATED_TASK_SUCCESSFULLY_CREATED')
            }]
          }],
        },
        variant: 'success',
      });
      goBack();
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('REQUEST_DEFAULT_ERROR')
          }]
        }],
      },
      variant: 'error',
    });
  };

  const updateData = (key, value) => {
    updateDataField(key, value);
  };

  useEffect(() => {
    reset();
    loadTpsId(code);

    // if id is not valid
    if (['edit', 'view'].includes(mode) && !parseInt(id, 10)) {
      goBack();
    } else if (id) {
      // getItem(id);
    }
    // getMainTOTree(code, 3);
  }, [id]);

  useEffect(() => {
    getDictionaryByKeys(['TpsInspectionRoles']);
    getTaskRouteList();
  }, []);

  useEffect(() => {
    if (id > 0) getInspectionTaskById(id);
  }, [id]);

  useEffect(() => {
    setMode(mode);
  }, [mode]);

  const {
    title,
    routeId,
    roles,
    startDate,
    finishDate,
    startTime,
    finishTime,
    daysInterval,
    daily,
    hoursInterval,
    startDelay,
    planDuration,
  } = inspectionTaskData;

  const startDateAttrs = {};
  const finishDateAttrs = {};

  if (mode !== 'view') {
    assign(startDateAttrs, { minDate: new Date() });
    if (isValidDate(finishDate)) {
      assign(startDateAttrs, { maxDate: new Date(finishDate) });
    }
    assign(finishDateAttrs, { minDate: isValidDate(startDate) ? new Date(startDate) : new Date() });
  }

  if (isLoading) {
    return <ProgressBar />;
  }

  const startDateTooltip = t('DEVICE_RECEIVED_DATE');
  const finishDateTooltip = t('DEVICE_TASK_END_DATE');

  return !isLoading && (
    <Panel
      mode={mode}
      viewItem={viewItem}
      editItem={editItem}
      saveItem={saveItem}
      goBack={goBack}
      deleteItem={removeItem}
      labelGoToView={t('GO_BACK')}
      labelGoToList={t('GO_BACK')}
      labelView={t('TASK_VIEW')}
      labelCreate={t('TASK_CREATION')}
      labelEdit={t('EDITING_A_TASK')}
    >
      {(isUpdating) && <ProgressBar />}
      <PanelBox style={{ paddingBottom: 50, marginBottom: 24 }}>
        <PanelTitle>{t('GENERAL_PARAMS')}</PanelTitle>

        <TextInput
          label={t('NOMINATION')}
          value={title}
          name="title"
          onChange={value => updateData('title', value)}
          autoFocus
          isReadonly={mode === 'view'}
          required
          data-test="title"
        />

        <TextInput
          label={t('THERMAL_POWER_PLANT')}
          value={tpsName}
          name="tpsName"
          isReadonly
          data-test="tpsName"
        />

        <DottedLoader
          isLoading={taskRouteListIsLoading}
        >
          <Input
            label={t('ROUTE')}
            value={routeId}
            name="routeId"
            onChange={value => updateData('routeId', value)}
            type="search"
            isReadonly={mode === 'view'}
            options={taskRouteList}
            optionValueKey="id"
            optionTitleKey="name"
            required
          />
        </DottedLoader>

        <Input
          label={t('EMPLOYEE')}
          value={roles.length > 0 ? roles[0] : ''}
          name="roles"
          onChange={value => updateData('roles', value ? [value] : [])}
          type="search"
          isReadonly={mode === 'view'}
          options={TpsInspectionRoles ? Object.keys(TpsInspectionRoles).map(role => ({
            value: TpsInspectionRoles[role],
            title: TpsInspectionRoles[role]
          })) : []}
          optionValueKey="value"
          optionTitleKey="title"
          required
        />

        <PanelTitle style={{ marginTop: 55 }}>{t('AUTOMATIC_TASK_CREATION_CONFIGURATIONS')}</PanelTitle>

        <PanelSubTitle>{t('TASK_EXECUTION_PERIOD')}</PanelSubTitle>

        <Grid container spacing={3}>
          <Grid item xs={6} style={{ padding: '8px 12px' }}>
            {mode !== 'view'
              ? (
                <Tooltip
                  classes={{ popper: classes.popper, tooltip: classes.tooltip }}
                  data-test={startDateTooltip}
                  title={startDateTooltip}
                  aria-label={startDateTooltip}
                  placement="bottom"
                >
                  <div>
                    <DateInput
                      label={t('START_DATE')}
                      name="startDate"
                      value={startDate}
                      onChange={value => updateData('startDate', value)}
                      {...mode !== 'view' && helperTextStartDate && { helperText: t(helperTextStartDate) }}
                      {...startDateAttrs}
                      required
                      data-test="startDate"
                    />
                  </div>
                </Tooltip>
              )
              : (
                <>
                  <DateInput
                    label={t('START_DATE')}
                    name="startDate"
                    value={startDate}
                    onChange={value => updateData('startDate', value)}
                    {...mode !== 'view' && helperTextStartDate && { helperText: t(helperTextStartDate) }}
                    {...startDateAttrs}
                    required
                    isReadonly
                    data-test="startDate"
                  />
                </>
              )
            }
            {mode !== 'view'
              ? (
                <Tooltip
                  classes={{ popper: classes.popper, tooltip: classes.tooltip }}
                  data-test={finishDateTooltip}
                  title={finishDateTooltip}
                  aria-label={finishDateTooltip}
                  placement="bottom"
                >
                  <div>
                    <DateInput
                      label={t('END_DATE')}
                      name="finishDate"
                      value={finishDate}
                      onChange={value => updateData('finishDate', value)}
                      isReadonly={mode === 'view'}
                      {...mode !== 'view' && helperTextFinishDate && { helperText: t(helperTextFinishDate) }}
                      {...finishDateAttrs}
                      required
                      data-test="finishDate"
                    />
                  </div>
                </Tooltip>
              )
              : (
                <DateInput
                  label={t('END_DATE')}
                  name="finishDate"
                  value={finishDate}
                  onChange={value => updateData('finishDate', value)}
                  isReadonly={mode === 'view'}
                  {...mode !== 'view' && helperTextFinishDate && { helperText: t(helperTextFinishDate) }}
                  {...finishDateAttrs}
                  required
                  data-test="finishDate"
                />
              )
            }
          </Grid>
          <Grid item xs={6} style={{ padding: '8px 12px' }}>
            <TimeInput
              label={t('START_TIME')}
              name="startTime"
              value={startTime}
              onChange={value => updateData('startTime', value)}
              isReadonly={mode === 'view'}
              tooltip={t('DEVICE_RECEIVED_TIME')}
              {...helperTextStartTime && { helperText: t(helperTextStartTime) }}
              required
              data-test="startTime"
            />
            <TimeInput
              label={t('FINISH_TIME')}
              name="finishTime"
              value={finishTime}
              onChange={value => updateData('finishTime', value)}
              isReadonly={mode === 'view'}
              tooltip={t('DEVICE_TASK_END_TIME')}
              {...helperTextFinishTime && { helperText: t(helperTextFinishTime) }}
              required
              data-test="finishTime"
            />
          </Grid>
        </Grid>

        <PanelSubTitle>{t('TASK_CREATION_INTERVAL')}</PanelSubTitle>

        <Grid container spacing={3}>
          <Grid item xs={6} style={{ padding: '8px 12px' }}>

            {daily ? (
              <Input
                label={t('INTERVAL_DAYS')}
                name="daysInterval"
                value={t('DAILY')}
                isReadonly
                style={{ marginBottom: 10 }}
                isBordered={mode !== 'view'}
                tooltip={t('THE_INTERVAL_OF_DAYS_BETWEEN_RECEIVING_A_TASK_ON_A_MOBILE_DEVICE')}
              />
            ) : (
              <NumberInput
                label={t('INTERVAL_DAYS')}
                name="daysInterval"
                value={daysInterval}
                onChange={value => updateData('daysInterval', value)}
                min="0"
                isReadonly={mode === 'view'}
                style={{ marginBottom: 10 }}
                {...helperTextDaysInterval && { helperText: t(helperTextDaysInterval) }}
                tooltip={t('THE_INTERVAL_OF_DAYS_BETWEEN_RECEIVING_A_TASK_ON_A_MOBILE_DEVICE')}
                required={!daily}
              />
            )}

            {mode !== 'view' && (
              <FormSwitch
                label={t('DAILY')}
                name={t('DAILY')}
                checked={daily}
                isReadonly={mode === 'view'}
                onChange={(e) => {
                  const checked = get(e, 'target.checked');
                  updateData('daily', checked);
                }}
                data-test="switch-daily"
              />
            )}

          </Grid>
          <Grid item xs={6} style={{ padding: '8px 12px' }}>
            <Input
              label={t('INTERVAL_HOURS')}
              name="hoursInterval"
              value={hoursInterval}
              onChange={value => updateData('hoursInterval', value)}
              type="select"
              options={hoursIntervalList.map(item => ({
                value: item,
                title: `${item} ${t('H')}`,
              }))}
              isReadonly={mode === 'view'}
              optionValueKey="value"
              optionTitleKey="title"
              tooltip={t('THE_INTERVAL_OF_DAYS_BETWEEN_RECEIVING_A_TASK_ON_A_MOBILE_DEVICE')}
              required
            />
          </Grid>
        </Grid>

        <PanelSubTitle>{t('START_TIME_AND_PLANNED_TASK_EXECUTION_TIME')}</PanelSubTitle>

        <Grid container spacing={3}>
          <Grid item xs={6} style={{ padding: '8px 12px' }}>
            <NumberInput
              label={t('START_IN_MIN')}
              name="startDelay"
              value={startDelay}
              onChange={value => updateData('startDelay', value)}
              min="0"
              isReadonly={mode === 'view'}
              {...helperTextStartDelay && { helperText: t(helperTextStartDelay) }}
              tooltip={t('THE_TIME_AT_THE_END_OF_WHICH_THE_TASK_STARTS_ON_THE_DEVICE')}
              required
            />
          </Grid>
          <Grid item xs={6} style={{ padding: '8px 12px' }}>
            <NumberInput
              label={t('EXECUTION_TIME_MIN')}
              name="planDuration"
              value={planDuration}
              onChange={value => updateData('planDuration', value)}
              min="0"
              isReadonly={mode === 'view'}
              {...helperTextPlanDuration && { helperText: t(helperTextPlanDuration) }}
              tooltip={t('THE_RECOMMENDED_TIME_FOR_COMPLETING_THE_TASK_ON_THE_DEVICE')}
              required
            />
          </Grid>
        </Grid>

      </PanelBox>
    </Panel>
  );
};

export default observer(AutomatedTaskDetails);
