import TaskAgent from 'ApiAgents/Tasks/TaskAgent';
import UnitsAgent from 'ApiAgents/Units/UnitsAgent';
import RepairsAgent from 'ApiAgents/Repairs/Repairs';
import EquipmentAgent from 'ApiAgents/Equipment/EquipmentAgent';
import DefectsAgent from 'ApiAgents/Defects/DefectsAgent';
import DictionaryAgent from 'ApiAgents/Dictionary/DictionaryAgent';
import {
  isEqual,
  get,
  find,
  partition,
  omitBy,
  isNil,
  isEmpty,
  toSafeInteger,
  isArray,
  isObject,
  assign,
  startsWith,
} from 'lodash';
import {
  MECHANIC_RU, PERFORMER_RU, MECHANIC, COAL_MASTER, HEAD_MECHANIC,
} from 'Shared/constants/roles';
import { HIGH_PRIORITY, LOW_PRIORITY } from 'Shared/constants/priority';

import {
  action,
  observable,
  runInAction,
  makeObservable,
} from 'mobx';
import {
  formatDate,
  formatToISOString,
} from 'Src/utils/datetime';
import { getByRoleQuery } from 'Src/utils/roles';
import { CODE_DATE_FORMAT } from 'Shared/constants/datetime';
import {
  assignmentsMap, currentAssigneeMap, operationsMap, repairOperationsMap, operationsMapforSaving
} from 'Business/coal/utils/mappers';

const equipmentAgent = new EquipmentAgent();
const taskAgent = new TaskAgent();
const unitsAgent = new UnitsAgent();
const repairsAgent = new RepairsAgent();
const defectsAgents = new DefectsAgent();
const dictionaryAgent = new DictionaryAgent();

const initItemData = {
  title: '',
  isStandard: true,
  isHighPriority: false,
  expectedStartDate: null,
  currentAssignee: null,
  assignments: [],
  equipmentSapCode: null,
  repairTypeSapCode: null,
  description: '',
  isRequiredQrCode: false,
  departmentId: null,
  operations: [],
  additionalOperations: [],
};

export class TaskStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  initialOperationsDictionary = [];

  initialEquipmentDictionary = [];

  initialRepairTypesDictionary = [];

  @action getInitialTaskData = () => initItemData;

  @observable items = [];

  @observable isEmptyLoadedTaskData = false;

  @observable mode = '';

  @observable task = {};

  @observable taskData = this.getInitialTaskData();

  @observable isLoading = false;

  @observable isLoaded = false;

  @observable isUpdating = false;

  @observable isLoadingByPage = false;

  @observable isDeleting = false;

  @observable totalPages = null;

  @observable hasNextPage = null;

  @observable isEmployeesLoaded = false;

  @observable employeesList = [];

  @observable filterEmployeesList = [];

  @observable equipmentDictionary = [];

  @observable equipmentDictionaryIsLoading = false;

  @observable repairTypesDictionary = [];

  @observable operationsDictionary = [];

  @observable departmentsDictionary = [];

  @observable taskCompletionReasonsDictionary = [];

  @observable scanFailReasonDictionary = [];

  @observable usersSelectEditorData = {
    employees: {
      checked: [],
      selected: [],
      brigadier: null,
    },
  };

  @observable taskDefects = [];

  @action loadDepartmentAndDictionaries = async () => {
    this.rootStore.setLoadingFlag(false, this, 'isLoadingByPage', 'isLoading', true);
    const { auth: { department }, role } = this.rootStore.userStore;
    let departmentId = null;
    let flattenDictionary = [];
    if (role !== HEAD_MECHANIC && this.mode === 'create') {
      this.equipmentDictionaryIsLoading = true;
      const departmentCode = get(department, 'code');
      departmentId = get(department, 'id');
      const dictionary = await equipmentAgent.getTechObjectsByDepartment(departmentCode);
      flattenDictionary = this.flatCollection(dictionary);
    }
    try {
      const rootId = get(this.rootStore, 'userStore.auth.rootOrgUnit.id');
      const departments = await unitsAgent.getDepartmentsByOrgUnit(rootId);
      const scanFailReasonDictionary = await dictionaryAgent.loadScanFailReasonDictionary();

      const taskCompletionReasonsDictionary = await taskAgent.getDictionaryByKey({ key: 'taskCompletionReason' }) || [];

      runInAction(() => {
        this.departmentsDictionary = departments;
        this.taskData.departmentId = departmentId;
        this.equipmentDictionary = flattenDictionary;
        this.equipmentDictionaryIsLoading = false;
        this.taskCompletionReasonsDictionary = taskCompletionReasonsDictionary.filter(item => startsWith(item.enumValue, 'COAL'));
        this.scanFailReasonDictionary = scanFailReasonDictionary;
      });
    } catch (error) {
      console.log('ERROR iN FETCHING DEPARTMENTS: ', error);
    }

    this.rootStore.setLoadingFlag(false, this, 'isLoadingByPage', 'isLoading', false);
  };


  flatCollection(array, level = 0, parents = []) {
    let result = [];
    array.forEach((item) => {
      const parentsIds = [...parents];
      const parentId = get(item, 'parentId');
      if (parentId) parentsIds.push(parentId);
      const newItem = { ...item, level, parentsIds };
      result.push(newItem);
      if (isArray(item.children)) {
        result = result.concat(this.flatCollection(item.children, level + 1, parentsIds));
      }
    });
    return result;
  }

  @action onChangeDepartment = async (departmentObj) => {
    const departmentId = get(departmentObj, 'id', null);
    const departmentCode = get(departmentObj, 'code', null);
    const orgUnitSapCode = get(departmentObj, 'orgUnitSapCode');
    if (isEqual(this.taskData.departmentCode, departmentId)) return;
    this.equipmentDictionary = [];
    this.repairTypesDictionary = [];
    this.operationsDictionary = [];
    this.taskData = {
      ...this.taskData,
      equipmentSapCode: null,
      technicalObjectKind: null,
      repairTypeSapCode: null,
      operations: [],
      departmentId,
      orgUnitSapCode,
      currentAssignee: null,
      assignments: [],
    };
    if (!departmentId) return;

    try {
      this.equipmentDictionaryIsLoading = true;
      const dictionary = await equipmentAgent.getTechObjectsByDepartment(departmentCode);
      const flattenDictionary = this.flatCollection(dictionary);
      runInAction(() => {
        this.equipmentDictionary = flattenDictionary;
        this.equipmentDictionaryIsLoading = false;
      });
    } catch (error) {
      console.log('ERROR in FETCHING equipmentDictionary: ', error);
    }
  };

  @action onChangeEquipment = async (equipmentObj) => {
    const technicalObjectId = get(equipmentObj, 'technicalObjectId', null);
    const technicalObjectKind = get(equipmentObj, 'technicalObjectKind', null);
    if (isEqual(this.taskData.equipmentSapCode, technicalObjectId)) return;
    const sapCode = get(equipmentObj, 'sapCode', null);
    this.taskData.repairTypeSapCode = null;
    this.taskData.technicalObjectKind = technicalObjectKind;
    this.repairTypesDictionary = [];
    this.operationsDictionary = [];
    this.taskData.operations = [];

    try {
      const currentEquipment = find(this.equipmentDictionary, { technicalObjectId });
      const { repairType = [] } = currentEquipment || {};
      runInAction(() => {
        this.taskData.equipmentSapCode = sapCode;
        this.repairTypesDictionary = repairType;
      });
    } catch (error) {
      console.log('ERROR in FETCHING repairTypesDictionary: ', error);
    }
  };

  @action onChangeRepairType = async (repairTypeObj) => {
    const repairTypeId = get(repairTypeObj, 'id', null);
    if (isEqual(this.taskData.repairTypeSapCode, repairTypeId)) return;
    this.taskData.repairTypeSapCode = repairTypeId;
    this.taskData.operations = [];
    this.operationsDictionary = [];
    if (!repairTypeId) return;

    try {
      const repairType = await repairsAgent.getRepairTypeById(repairTypeId);
      const { repairOperations } = repairType || {};
      const mappedRepairOperations = repairOperationsMap(repairOperations);

      runInAction(() => {
        this.operationsDictionary = mappedRepairOperations;
        this.taskData.operations = mappedRepairOperations;
      });
    } catch (error) {
      console.log('ERROR in FETCHING operationsDictionary:', error);
    }
  };

  @action onChangeTaskType = (taskType) => {
    this.taskData.isStandard = taskType;
    if (!taskType) {
      this.taskData.title = '';
    } else {
      this.rootStore.validationStore.removeField('title');
    }
    this.taskData.operations = this.operationsDictionary;
    this.taskData.additionalOperations = [];
    if (isEmpty(this.repairTypesDictionary)) this.taskData.equipmentSapCode = null;
  };

  @action setEmployees = (employees) => {
    this.usersSelectEditorData.employees = employees;
  };

  @action loadPotentialAssignees = async () => {
    try {
      let params;
      const { role } = this.rootStore.userStore;
      const { departmentId } = this.taskData;
      if (role === HEAD_MECHANIC && departmentId) {
        params = { roles: getByRoleQuery([MECHANIC_RU]), orgUnitId: this.taskData.departmentId };
      }
      if (role === COAL_MASTER || role === MECHANIC) {
        params = { roles: getByRoleQuery([PERFORMER_RU]) };
      }
      if (params) {
        const employees = await this.rootStore.userStore.loadUsersByRole(params);
        runInAction(() => {
          this.employeesList = employees;
          this.isEmployeesLoaded = true;
        });
      }
    } catch (error) {
      console.log('ERROR in FETCHING BRIGADE BY ID: ', error);
    }
  };

  @action loadFiltersAssignees = async () => {
    const EMPLOYEES_ROLES = [MECHANIC_RU];

    try {
      const employees = await this.rootStore.userStore.fetchUsers({ roles: getByRoleQuery(EMPLOYEES_ROLES) });
      runInAction(() => {
        this.filterEmployeesList = employees;
        this.isEmployeesLoaded = true;
      });
    } catch (error) {
      console.log('ERROR in FETCHING BRIGADE BY ID: ', error);
    }
  };

  @action confirmSelectedUsers = ({ selected, currentAssignee }) => {
    runInAction(() => {
      this.taskData.currentAssignee = currentAssignee;
      this.taskData.assignments = selected;
    });
  };

  @action prepareAssignments = () => {
    const {
      taskData,
    } = this;

    const {
      currentAssignee,
      assignments,
    } = taskData;

    this.usersSelectEditorData.employees = {
      checked: [...assignments, { ...currentAssignee }],
      selected: [...assignments],
      brigadier: { ...currentAssignee },
    };
  };

  @action reset = () => {
    this.task = initItemData;
    this.taskData = initItemData;
    this.isEmptyLoadedTaskData = false;
  };

  @action setMode = (mode) => {
    this.mode = mode;
  };

  @action discardEditorState = () => {
    this.taskData = { ...this.task };
    this.operationsDictionary = this.initialOperationsDictionary;
    this.equipmentDictionary = this.initialEquipmentDictionary;
    this.repairTypesDictionary = this.initialRepairTypesDictionary;
  };

  @action updateDataField = (name, value) => {
    runInAction(() => {
      this.taskData[name] = value;
    });
  };

  getTaskTitle = (equipmentSapCode, repairTypeSapCode) => {
    const equipment = this.equipmentDictionary.find(item => item.sapCode === equipmentSapCode);
    const repairType = this.repairTypesDictionary.find(item => item.id === repairTypeSapCode);

    if (equipment && repairType) {
      const equipmentTitle = get(equipment, 'title');
      const repairTypeTitle = get(repairType, 'title');

      return `${repairTypeTitle} ${equipmentTitle}`;
    }
    return '';
  };

  @action save = async () => {
    const { taskData } = this;
    const {
      taskId,
      title,
      description,
      departmentId,
      isRequiredQrCode,
      isStandard,
      equipmentSapCode,
      expectedStartDate,
      isHighPriority,
      assignments,
      operations,
      currentAssignee: { id: currentAssigneeId } = {},
      repairTypeSapCode,
      additionalOperations,
      technicalObjectKind,
    } = taskData;

    const mappedOperations = operationsMapforSaving(operations);
    additionalOperations.forEach(operation => mappedOperations.push(operation));
    this.isUpdating = true;

    const equipmentCode = {};
    if (equipmentSapCode) {
      if (technicalObjectKind === 0) {
        assign(equipmentCode, { technicalPlaceSapCode: equipmentSapCode });
      } else {
        assign(equipmentCode, { equipmentSapCode });
      }
    }

    try {
      let requestData = {
        taskId,
        title: isStandard ? this.getTaskTitle(equipmentSapCode, repairTypeSapCode) : title,
        description,
        departmentId,
        isRequiredQrCode,
        isStandard,
        ...equipmentCode,
        expectedStartDate: formatToISOString(expectedStartDate),
        taskPriority: isHighPriority ? HIGH_PRIORITY : LOW_PRIORITY,
        currentAssignee: currentAssigneeId,
        assignments: assignments.map(item => item.id),
        orgUnitSapCode: get(this.rootStore, 'userStore.auth.rootOrgUnit.id'),
        taskType: 'COAL_REPAIR',
        operations: mappedOperations,
        repairTypeId: repairTypeSapCode
      };

      requestData = omitBy(requestData, isNil);
      const res = await taskAgent.saveTask(requestData);

      runInAction(() => {
        this.isUpdating = false;
      });

      return { res };
    } catch (error) {
      console.log('ERROR in SAVE TASK: ', error);
      runInAction(() => {
        this.isUpdating = false;
      });
      return { error };
    }
  };

  getAssignments = (current, all) => {
    let currentAssignee = {};
    let assignments = [];
    let currentAssigneeId;
    if (isObject(current)) {
      currentAssignee = currentAssigneeMap(current);
      currentAssigneeId = get(current, 'identityId');
    }
    if (isArray(all)) {
      assignments = assignmentsMap(all).filter(
        ({ id: assignmentId, identityType }) => assignmentId !== currentAssigneeId && identityType !== 'CONTROLLER',
      );
    }
    return { currentAssignee, assignments };
  };

  @action getTaskById = async (id) => {
    this.isLoading = true;
    this.isLoaded = false;
    try {
      const tasks = await taskAgent.filterById({ id });
      if (tasks.length > 0) {
        const {
          department,
          description,
          repairType,
          expectedStartDate,
          taskPriority,
          operations = [],
          rootTechnicalObjectIds = [],
          currentAssignee,
          assignments,
        } = tasks[0];

        const departmentId = get(department, 'id');
        const departmentCode = get(department, 'code');
        const repairTypeId = get(repairType, 'id');
        const controller = assignments.find(user => user.identityType === 'CONTROLLER');

        const [technicalObjectId] = rootTechnicalObjectIds;
        let equipmentDictionary = await equipmentAgent.getTechObjectsByDepartment(departmentCode);
        equipmentDictionary = this.flatCollection(equipmentDictionary);
        let mappedOperations = [];
        let repairTypesDictionary = [];
        let repairOperationsDictionary = [];
        let equipmentSapCode = null;
        let technicalObjectKind = null;
        let operationsFromTK = [];
        let additionalOperations = [];

        if (technicalObjectId) {
          const techObject = await equipmentAgent.getTechObjectByIds(technicalObjectId);
          equipmentSapCode = get(techObject, 'sapCode');
          technicalObjectKind = get(techObject, 'technicalObjectKind', null);
          const currentEquipment = find(equipmentDictionary, { technicalObjectId });
          repairTypesDictionary = get(currentEquipment, 'repairType', []);
        }

        if (repairTypeId) {
          const repairTypeObj = await repairsAgent.getRepairTypeById(repairTypeId);
          const { repairOperations = [] } = repairTypeObj || {};
          repairOperationsDictionary = repairOperationsMap(repairOperations);
        }

        mappedOperations = operationsMap(operations);
        const dividedOperations = partition(mappedOperations, ({ operationSapCode }) => operationSapCode);
        const [fromTK = [], additional = []] = dividedOperations;
        operationsFromTK = fromTK;
        additionalOperations = additional;

        const task = {
          ...tasks[0],
          departmentId,
          equipmentSapCode,
          technicalObjectKind,
          description: description || '',
          ...this.getAssignments(currentAssignee, assignments),
          controller,
          operations: operationsFromTK,
          additionalOperations,
          repairTypeSapCode: repairTypeId,
          isHighPriority: taskPriority === HIGH_PRIORITY,
          expectedStartDate: formatDate(expectedStartDate, CODE_DATE_FORMAT),
        };

        runInAction(() => {
          this.task = task;
          this.taskData = task;
          this.isLoaded = true;
          this.operationsDictionary = repairOperationsDictionary;
          this.equipmentDictionary = equipmentDictionary;
          this.repairTypesDictionary = repairTypesDictionary;
          this.initialOperationsDictionary = repairOperationsDictionary;
          this.initialEquipmentDictionary = equipmentDictionary;
          this.initialRepairTypesDictionary = repairTypesDictionary;
          this.isLoading = false;
        });
      }
      if (tasks.length === 0) {
        runInAction(() => {
          this.isEmptyLoadedTaskData = true;
          this.isLoading = false;
          this.isLoaded = true;
        });
      }
    } catch (error) {
      console.log('ERROR IN TASK FETCHING: ', error);
    }
  };

  @action getDefectsByTaskId = async (taskId) => {
    try {
      const data = await defectsAgents.getDefectsByTaskId(toSafeInteger(taskId));
      runInAction(() => {
        this.taskDefects = data;
      });
    } catch (error) {
      console.log('ERROR IN FETCHING TASK DEFECTS: ', error);
    }
  };

  @action deleteTask = async (taskId) => {
    this.isDeleting = true;
    try {
      await taskAgent.disableTasksByIds([toSafeInteger(taskId)]);
      this.isDeleting = false;
    } catch (error) {
      this.isDeleting = false;
      const errorMessage = 'ERROR IN TASK DELETING';
      console.log(`${errorMessage}: `, error);
      throw new Error(errorMessage);
    }
  };

  @action confirmTask = async ({ taskId, taskCompletionReason, completionReasonComment }) => {
    this.isDeleting = true;
    try {
      await taskAgent.confirmTask({ taskId, taskCompletionReason, completionReasonComment });
      this.isDeleting = false;
    } catch (error) {
      console.log('ERROR IN TASK CONFIRMING: ', error);
      this.isDeleting = false;
      throw new Error(error);
    }
  };

  @action rescheduleTask = async (taskId) => {
    this.isDeleting = true;
    try {
      const res = await taskAgent.rescheduleTask({ taskId });
      this.isDeleting = false;
      return { res };
    } catch (error) {
      console.log('ERROR IN TASK RESCHEDULING: ', error);
      this.isDeleting = false;
      return { error };
    }
  };
}

export default TaskStore;
