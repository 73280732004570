import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  root: {
    padding: '6px 6px 6px 16px',
    minWidth: 300
  },
  title: {
    color: theme.palette.black30,
    fontSize: 12
  },
  value: {
    color: theme.palette.black,
    fontSize: 16
  }
}));

const OverlayBreadcrumb = ({ title, value, 'data-test': dataTest }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} {...dataTest && { 'data-test': dataTest }}>
      <div className={classes.title}>{title}</div>
      <div className={classes.value}>{value}</div>
    </div>
  );
};

OverlayBreadcrumb.defaultProps = {
  'data-test': undefined
};

OverlayBreadcrumb.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  'data-test': PropTypes.string,
};

export default OverlayBreadcrumb;
