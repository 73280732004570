import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core/';
import useStores from 'Store/useStores';
import {
  ExitToApp,
  PersonAddOutlined,
  PeopleAltOutlined,
  ErrorOutlineOutlined,
  CheckCircleOutline
} from '@material-ui/icons/';
import { POST_DATE_FORMAT } from 'Shared/constants/datetime';
import { formatDate, formatTime } from 'Src/utils/datetime';
import RejectionReasonButton from 'Src/business/tpsRepairs/containers/RejectionReasonButton/RejectionReasonButton';
import historyTabHeaderConfig from 'Src/business/tpsRepairs/components/HistoryTab/historyTabHeaderConfig';
import { taskStatuses } from 'Shared/constants/taskStatuses';
import useStyles from './styles';

const icons = {
  NEW: ExitToApp,
  ASSIGNED: PersonAddOutlined,
  IN_PROGRESS: PeopleAltOutlined,
  CANCELED: ErrorOutlineOutlined,
  UNKNOWN: ErrorOutlineOutlined,
  COMPLETED: CheckCircleOutline,
};

const HistoryTab = ({ taskId }) => {
  const {
    tasksStore: {
      history,
      getHistory,
      dictionaries
    }
  } = useStores();

  const { t } = useTranslation();
  const classes = useStyles();
  const translatedTaskStatuses = taskStatuses(t);

  useEffect(() => {
    getHistory(taskId);
  }, []);

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <TableHead>
            <TableRow>
              <TableCell />
              {historyTabHeaderConfig(t).map(({
                id,
                numeric,
                disablePadding,
                label,
                ...rest
              }) => (
                <TableCell
                  key={id}
                  align={numeric ? 'right' : 'left'}
                  padding={disablePadding ? 'none' : 'default'}
                  {...rest}
                >
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {history.map((row, index) => {
              const IconNewStatus = icons[row.status] ? icons[row.status] : 'span';
              const previousStatus = index > 0 ? history[index - 1].status : false;

              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row.dateTime}
                  style={{
                    fontSize: 16,
                  }}
                >
                  <TableCell style={{
                    position: 'relative',
                    width: 48,
                    minWidth: 48,
                    border: 'none'
                  }}
                  >
                    <span className={`table-circle ${index === 0 ? 'table-circle--first' : ''}`} />
                  </TableCell>
                  <TableCell padding="none">
                    <Typography>
                      {formatDate(row.dateTime, POST_DATE_FORMAT)}
                    </Typography>
                    <Typography style={{ color: '#a1a1a1' }}>
                      {formatTime(row.dateTime)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box style={{ display: 'flex' }}>
                      <Typography style={{ color: '#4499ee' }}>
                        <IconNewStatus />
                      </Typography>
                      <Box style={{ marginLeft: 10 }}>
                        <Typography>
                          {translatedTaskStatuses[row.status] ? translatedTaskStatuses[row.status] : translatedTaskStatuses.UNKNOWN}
                        </Typography>
                        {previousStatus && previousStatus !== row.status && (
                        <Typography style={{ color: '#a1a1a1' }}>
                          {previousStatus ? `${t('WAS')}: ${dictionaries.TaskStatus[previousStatus]}` : ''}
                        </Typography>
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell className={classes.assigneeCell}>
                    <Typography>
                      {row.assigneeName}
                    </Typography>
                    <Typography style={{
                      color: '#a1a1a1'
                    }}
                    >
                      {
                        row.assigneeIdentityType === 'BRIGADE' && row.brigadeName
                          ? `${t('PRODUCER')} (${row.brigadeName})`
                          : (row.assigneePosition || '')
                      }
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <RejectionReasonButton
                      reason={row.rejectionReason}
                      comment={row.rejectReasonComment}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {row.authorName}
                    </Typography>
                    {row.authorPersonnelNumber && (
                    <Typography style={{
                      color: '#a1a1a1'
                    }}
                    >
                      {row.authorPosition || ''}
                    </Typography>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

HistoryTab.propTypes = {
  taskId: PropTypes.number.isRequired
};

export default observer(HistoryTab);
