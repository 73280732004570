import {
  action, runInAction, makeObservable
} from 'mobx';
import { get, find } from 'lodash';
import CheckpointAgent from 'ApiAgents/Checkpoints/CheckpointAgentNEW';
import { INITIAL_PAGE_NUMBER, INITIAL_PAGE_SIZE } from 'Shared/constants/paging';

const checkpointAgent = new CheckpointAgent();

const initialState = {
  isLoaded: false,
  isLoading: false,
  isListLoaded: false,
  isListLoading: false,
  isCreating: false,
  isUpdating: false,
  isLoadingByPage: false,
  checkpointName: '',
  powerUnitId: '',
  powerUnits: [],
  titleSearchString: '',
  checkpointsList: [],
  totalPages: 0,
  hasNextPage: false,
  storedPageSize: INITIAL_PAGE_SIZE,
  pageIndex: undefined,
  isDeleting: false,
  powerUnitTitle: '',
  checkpointId: '',
  updatedBy: '',
  updatedAt: '',
  emptyData: false
};

const TECH_OBJECTS_LEVELS_TO_LOAD = 2;

export class CheckpointStoreNEW {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
    this.rootStore.prepareState(initialState, this);
  }

  isLoading;

  isListLoaded;

  isListLoading;

  isCreating;

  isUpdating;

  isLoaded;

  isLoadingByPage;

  checkpointName;

  powerUnitId;

  powerUnits;

  titleSearchString;

  checkpointsList;

  totalPages;

  hasNextPage;

  storedPageSize;

  pageIndex;

  isDeleting;

  powerUnitTitle;

  checkpointId;

  updatedBy;

  updatedAt;

  emptyData;

  checkpointNumber;

  @action discardState = () => {
    this.isLoaded = false;
    this.isLoading = false;
    this.isLoadingByPage = false;
    this.checkpointName = '';
    this.powerUnitId = '';
    this.powerUnits = [];
    this.titleSearchString = '';
    this.checkpointsList = [];
    this.totalPages = 0;
    this.hasNextPage = false;
    this.storedPageSize = INITIAL_PAGE_SIZE;
    this.pageIndex = undefined;
    this.isDeleting = false;
    this.powerUnitTitle = '';
    this.checkpointId = '';
    this.checkpointNumber = '';
    this.updatedBy = '';
    this.updatedAt = '';
    this.emptyData = false;
  };

  @action onChangeCheckpointName = (checkpointName) => {
    this.checkpointName = checkpointName;
  }

  @action onChangePowerUnitId = (powerUnit) => {
    const powerUnitId = get(powerUnit, 'id', '');
    this.powerUnitId = powerUnitId;
    this.loadCheckpointsList(INITIAL_PAGE_NUMBER, this.storedPageSize);
  }

  @action createCheckpoint = async () => {
    this.isCreating = true;
    try {
      const { error = false } = await checkpointAgent.createCheckpoint({
        title: this.checkpointName,
        technicalObjectId: this.powerUnitId,
        technicalObjectTitle: get(find(this.powerUnits, { id: this.powerUnitId }), 'title'),
      });
      runInAction(() => {
        this.isCreating = false;
      });
      return { error };
    } catch (error) {
      console.log('ERROR IN CHECKPOINT CREATION: ', error);
      runInAction(() => {
        this.isCreating = false;
      });
    }
  }

  @action updateCheckpoint = async (id) => {
    this.isUpdating = true;
    try {
      const { error = false } = await checkpointAgent.updateCheckpoint({ id, title: this.checkpointName });
      runInAction(() => {
        this.isUpdating = false;
      });
      return { error };
    } catch (error) {
      console.log('ERROR IN CHECKPOINT UPDATE: ', error);
      runInAction(() => {
        this.isUpdating = false;
      });
      return { error };
    }
  }

  @action onChangeTitleSearchString = (titleSearchString) => {
    this.titleSearchString = titleSearchString;
  }

  @action loadPowerUnits = async () => {
    this.isLoading = true;
    const { code: rootOrgUnitCode } = this.rootStore.userStore.rootOrgUnit;

    try {
      const [{ children: powerUnits } = {}] = await this.rootStore.equipmentStore.loadTechObjectsTrees(rootOrgUnitCode, TECH_OBJECTS_LEVELS_TO_LOAD);

      runInAction(() => {
        this.powerUnits = powerUnits.map(({ technicalObjectId, ...rest }) => ({ id: technicalObjectId, ...rest }));
        this.isLoading = false;
        this.isLoaded = true;
      });
    } catch (error) {
      console.log('ERROR IN FETCHING POWER UNITS: ', error);
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  @action loadCheckpointById = async (checkpointId) => {
    try {
      this.isLoading = true;
      const checkpointData = await checkpointAgent.getCheckpointById(checkpointId);

      if (!checkpointData) {
        runInAction(() => {
          this.emptyData = true;
          this.isLoading = false;
        });
        return;
      }
      const {
        title,
        technicalObjectTitle,
        id,
        updatedBy,
        updatedAt,
      } = checkpointData;
      runInAction(() => {
        this.checkpointName = title;
        this.powerUnitTitle = technicalObjectTitle;
        this.checkpointId = id;
        this.updatedBy = updatedBy;
        this.updatedAt = updatedAt;
        this.checkpointNumber = id;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.checkpointName = '';
        this.powerUnitTitle = '';
        this.checkpointId = '';
        this.checkpointNumber = '';
        this.updatedBy = '';
        this.updatedAt = '';
        this.isLoading = false;
      });
      console.log('ERROR IN FETCH CHECKPOINT BY ID: ', error);
      throw new Error(error);
    }
  }

  @action loadCheckpointsList = async (pageNumber, pageSize) => {
    this.isListLoaded = false;
    this.isListLoading = true;
    const isPaginating = !!pageNumber;
    this.pageIndex = pageNumber;
    if (pageSize) this.storedPageSize = pageSize;
    this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', true);

    try {
      const params = {
        ...this.titleSearchString && { titleSearchString: this.titleSearchString },
        ...this.powerUnitId && { technicalObjectId: this.powerUnitId },
      };
      const { items, totalPages, hasNextPage } = await checkpointAgent.getCheckpoints(params, true, pageNumber, pageSize);
      runInAction(() => {
        this.checkpointsList = items;
        this.isListLoaded = true;
        this.isListLoading = false;
        this.totalPages = totalPages;
        this.hasNextPage = hasNextPage;
        this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', false);
      });
    } catch (error) {
      runInAction(() => {
        this.checkpointsList = [];
        this.isListLoaded = true;
        this.isListLoading = false;
        this.totalPages = 0;
        this.hasNextPage = false;
        this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', false);
      });
      console.log('ERROR IN FETCH CHECKPOINTS: ', error);
      throw new Error(error);
    }
  };

  @action deleteCheckpoint = async (id) => {
    if (!id) return { error: 'No checkpoint id provided' };

    this.isDeleting = true;

    try {
      const result = await checkpointAgent.deleteCheckpoint(id);
      runInAction(() => {
        this.isDeleting = false;
      });
      return { result };
    } catch (error) {
      runInAction(() => {
        this.isDeleting = false;
      });
      return { error };
    }
  };
}

export default CheckpointStoreNEW;
