import { makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LinearProgress from 'Common/components/Progress/components/Linear';


const useStyles = makeStyles(() => ({
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16,
    fontSize: 16
  },
  progressContainer: {
    marginBottom: 16
  }
}));

const TaskProgress = ({
  factDurationPercentage, planDurationPercentage, planDuration = 0, factDuration = 0
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <div className={classes.progressContainer}>
        <div className={classes.titleWrapper}>
          <span>{t('PLANNED_LEAD_TIME')}</span>
          <span>
            {`${planDuration} ${t('MIN')}`}
          </span>
        </div>

        <LinearProgress
          progressColor={theme.palette.positive}
          value={planDurationPercentage}
        />
      </div>

      <div className={classes.progressContainer}>
        <div className={classes.titleWrapper}>
          <span>{t('ACTUAL_LEAD_TIME')}</span>
          <span>
            {`${factDuration} ${t('MIN')}`}
          </span>
        </div>

        <LinearProgress
          progressColor={
          factDurationPercentage > planDurationPercentage
            ? theme.palette.negative
            : theme.palette.positive
        }
          value={factDurationPercentage}
        />
      </div>
    </>
  );
};

TaskProgress.propTypes = {
  factDurationPercentage: PropTypes.number.isRequired,
  planDurationPercentage: PropTypes.number.isRequired,
  planDuration: PropTypes.number.isRequired,
  factDuration: PropTypes.number
};

TaskProgress.defaultProps = {
  factDuration: null
};

export default TaskProgress;
