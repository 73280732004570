import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  content: {
    height: `calc(100vh - ${theme.headerHeight}px - ${theme.pageHeaderHeight}px - ${theme.mainContentPaddingTop}px - ${theme.mainContentPaddingBottom}px)`,
    maxHeight: `calc(100vh - ${theme.headerHeight}px - ${theme.pageHeaderHeight}px - ${theme.mainContentPaddingTop}px - ${theme.mainContentPaddingBottom}px)`,
  },
  scrollContent: {
    maxHeight: ({ mode }) => (`calc(100vh - ${theme.headerHeight}px - ${theme.pageHeaderHeight}px - ${theme.mainContentPaddingTop}px - ${theme.mainContentPaddingBottom}px - ${mode === 'view' ? theme.actionPanelHeight : 0}px)`),
    overflowY: 'auto',
  },
  nameInput: {
    marginRight: 100,
  },
  layout: {
    marginBottom: theme.spacing(2),
  },
  boxWrapper: {
    borderBottom: '1px solid #f3f3f3'
  },
  box: {
    maxWidth: 500,
    padding: '14px 5px',
    margin: '0 auto',
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    margin: '35px 25px 25px',
    color: '#212121',
  },
  shiftTypeInput: {
    marginRight: 24,
  },
  shiftInput: {
    marginRight: 24,
  },
  unitLabel: {
    fontSize: 12, marginLeft: 8, color: theme.palette.black30
  },
  executionType: {
    maxWidth: 350,
    width: 350,
    marginRight: 16
  },
  dateInput: {
    width: 185,
    minWidth: 185,
    maxWidth: 185,
    marginRight: 16
  },
  tasksCount: {
    width: 280,
    minWidth: 280,
    maxWidth: 280,
    marginRight: 16,
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
  },
  tasksCountReadonly: {
    marginLeft: 8,
    marginRight: 16,
    width: 160,
    minWidth: 160,
    maxWidth: 160,
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
  },
  priorityReadOnly: {
    display: 'flex',
    alignItems: 'center',
    height: 46,
    fontSize: 16,
    marginTop: 4,
    marginLeft: 16,
  },
  shiftAcceptanceReadOnly: {
    display: 'flex',
    alignItems: 'center',
    height: 46,
    fontSize: 16,
    marginTop: 4,
    marginLeft: 16,
  },
  priorityReadOnlyIcon: {
    color: theme.palette.primaryLight,
    marginRight: 8,
  },
  shiftAcceptanceReadOnlyIcon: {
    color: theme.palette.primaryLight,
    marginRight: 8,
  },
  isShiftAcceptance: {
    width: 170,
    minWidth: 170,
    maxWidth: 170,
  },
  isHightPriority: {
    width: 200,
    minWidth: 200,
    maxWidth: 200,
  },
  tasksCountButton: {
    border: 'none',
    background: 'transparent',
    outline: 'none',
    color: theme.palette.black70,
    width: 20,
    height: 20,
    padding: 0,
    marginTop: 4,
    marginLeft: 18,
    cursor: 'pointer',
  },
  shiftType: {
    maxWidth: 350,
    width: 350,
    marginRight: 16
  },
  shiftDuration: {
    width: 185,
    minWidth: 185,
    maxWidth: 185,
    marginRight: 16
  },
  plannedDuration: {
    width: 175,
    minWidth: 175,
    maxWidth: 175,
    marginRight: 16
  },
  lastExecutionResult: {
    flex: 1,
    '& h3': {
      fontSize: 16,
      marginTop: 0
    }
  },
  status: {
    flex: 1,
    '& h3': {
      fontSize: 16,
      marginTop: 0
    }
  },
  optionRenderer: {
    flex: 1
  },
  tagsBlockHeader: {
    marginTop: 12,
    marginBottom: 22,
    height: 56,
    display: 'flex',
    alignItems: 'center',
  },
  tagsBlockTitle: {
    flex: 1,
    fontSize: 20,
    fontWeight: 700,
    color: theme.palette.black,
  },
  folderIcon: {
    margin: 8,
  },
  collapseButton: {
    fontSize: 16,
    lineHeight: 1.5,
    color: theme.palette.primaryLight,
    padding: '8px 24px',
  },
  orderRequired: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    '& .MuiFormControl-root': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  orderRequiredIconWrapper: {
    height: 24,
    width: 24,
    marginRight: 6,
  },
  orderRequiredIcon: {
    color: theme.palette.black30,
  },
  actionButtonsBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 24,
    backgroundColor: theme.palette.white,
    boxShadow: `0px 1px 0px ${theme.palette.midGray} inset`,
    zIndex: 3,
  },
  actionButton: {
    marginRight: 24,
    '&:last-child': {
      marginRight: 0,
    },
  },
}));

export default useStyles;
