import { makeStatusDto } from 'Services/dto/makeTaskStatusDto';
import taskStatusSchema from 'Services/validation/schemas/TaskStatusSchema';
import { HEAD_MECHANIC, MECHANIC, COAL_MASTER } from 'Shared/constants/roles';
import { subDays } from 'date-fns';

const dtoMaker = makeStatusDto(taskStatusSchema);

const specificDate = subDays(new Date(), 30);

export const taskStatusesIds = {
  UNKNOWN: 0,
  NEW: 1,
  ASSIGNED: 2,
  IN_PROGRESS: 3,
  PAUSE: 4,
  COMPLETED: 5,
  CANCELED: 6,
  ON_CONTROL: 7,
};

export const taskStatusesConfig = t => (
  {
    ACTUAL: dtoMaker({
      title: t('ACTUAL'),
      name: 'actual',
      query: [taskStatusesIds.NEW, taskStatusesIds.ASSIGNED],
      access: [HEAD_MECHANIC, MECHANIC, COAL_MASTER],
    }),
    IN_PROGRESS: dtoMaker({
      title: t('IN_PROGRESS'),
      name: 'in_progress',
      query: [taskStatusesIds.IN_PROGRESS],
      access: [HEAD_MECHANIC, MECHANIC, COAL_MASTER],
    }),
    CONTROL: dtoMaker({
      dateLimits: {
        [HEAD_MECHANIC]: {
          expectedStartDateMoreThan: specificDate,
        },
        [MECHANIC]: {
          expectedStartDateMoreThan: specificDate,
        },
      },
      title: t('ON_CONTROL'),
      name: 'control',
      query: [taskStatusesIds.ON_CONTROL],
      access: [HEAD_MECHANIC, MECHANIC],
    }),
    COMPLETED: dtoMaker({
      dateLimits: {
        [HEAD_MECHANIC]: {
          expectedStartDateMoreThan: specificDate,
        },
        [MECHANIC]: {
          expectedStartDateMoreThan: specificDate,
        },
        [COAL_MASTER]: {
          expectedStartDateMoreThan: specificDate,
        },
      },
      title: t('COMPLETED'),
      name: 'completed',
      query: [taskStatusesIds.COMPLETED],
      access: [HEAD_MECHANIC, MECHANIC, COAL_MASTER],
    }),
    ALL: dtoMaker({
      title: t('ALL'),
      name: 'all',
      query: [],
      queryByRole: {
        [HEAD_MECHANIC]: [
          taskStatusesIds.NEW,
          taskStatusesIds.ASSIGNED,
          taskStatusesIds.IN_PROGRESS,
          taskStatusesIds.COMPLETED,
          taskStatusesIds.CANCELED,
          taskStatusesIds.ON_CONTROL,
        ],
        [MECHANIC]: [
          taskStatusesIds.NEW,
          taskStatusesIds.ASSIGNED,
          taskStatusesIds.IN_PROGRESS,
          taskStatusesIds.COMPLETED,
          taskStatusesIds.CANCELED,
          taskStatusesIds.ON_CONTROL,
        ],
        [COAL_MASTER]: [
          taskStatusesIds.NEW,
          taskStatusesIds.ASSIGNED,
          taskStatusesIds.IN_PROGRESS,
          taskStatusesIds.COMPLETED,
          taskStatusesIds.CANCELED,
        ],
      },
      access: [HEAD_MECHANIC, MECHANIC, COAL_MASTER],
    }),
    ARCHIVE: dtoMaker({
      dateLimits: {
        [HEAD_MECHANIC]: {
          expectedStartDateLessThan: specificDate,
        },
        [MECHANIC]: {
          expectedStartDateLessThan: specificDate,
        },
        [COAL_MASTER]: {
          expectedStartDateLessThan: specificDate,
        },
      },
      title: t('ARCHIVE'),
      name: 'archive',
      queryByRole: {
        [HEAD_MECHANIC]: [
          taskStatusesIds.COMPLETED,
          taskStatusesIds.CANCELED,
          taskStatusesIds.ON_CONTROL,
        ],
        [MECHANIC]: [taskStatusesIds.COMPLETED, taskStatusesIds.CANCELED, taskStatusesIds.ON_CONTROL],
        [COAL_MASTER]: [taskStatusesIds.COMPLETED, taskStatusesIds.CANCELED],
      },
      access: [HEAD_MECHANIC, MECHANIC, COAL_MASTER],
      query: [taskStatusesIds.COMPLETED, taskStatusesIds.CANCELED, taskStatusesIds.ON_CONTROL],
    }),
  }
);

export default taskStatusesConfig;
