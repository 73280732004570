import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  filterApplyButtonWrapper: {
    height: 48,
    width: '100%',
    backgroundColor: theme.palette.white,
  },
  filterApplyButton: {
    height: 48,
    width: '100%',
    '&.MuiButtonBase-root': {
      backgroundColor: theme.palette.primaryLight,
    },
    '& .MuiButton-label': {
      color: '#fff',
      textTransform: 'none',
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.5,
      letterSpacing: 'normal',
      textAlign: 'center',
    },
    '&:disabled': {
      '&.MuiButtonBase-root': {
        backgroundColor: theme.palette.disabled,
      },
    },
  },
}));

export default useStyles;
