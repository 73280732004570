import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isUndefined } from 'lodash';
import useStores from 'Store/useStores';
import clsx from 'clsx';
import OperationView from 'Common/widgets/OperationView/OperationView';
import { IconButton, Tooltip } from '@material-ui/core';
import { Edit, CheckCircleOutlineSharp, HighlightOffRounded } from '@material-ui/icons';
import MaterialValueInput from 'Business/tpsRepairs/components/ConsumptionsControl/MaterialValueInput';

import useStyles from './styles';

const EditableOperationView = ({
  operation,
  materialValue = {},
  factQuantity,
  showNotValidMaterialValueQuantity,
  isEditable,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    quantity, updatedQuantity, sapCode: materialValueSapCode, description, unit,
  } = materialValue;

  const { operationId } = operation;

  const [value, setValue] = useState(!isUndefined(updatedQuantity) ? updatedQuantity : factQuantity);
  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    if (!isUndefined(updatedQuantity)) setValue(updatedQuantity);
  }, [updatedQuantity]);
  const {
    materialValuesControlStore: {
      updateMaterialValueQuantity
    },
  } = useStores();

  const handleApply = () => {
    if (quantity < value) {
      showNotValidMaterialValueQuantity();
      return;
    }
    setIsEditing(false);
    if (!value || value === '') {
      setValue('0');
      updateMaterialValueQuantity(0, operationId, materialValueSapCode);
      return;
    }
    updateMaterialValueQuantity(parseFloat(value), operationId, materialValueSapCode);
  };

  const shouldDisplayAsEdited = !isUndefined(updatedQuantity) && +updatedQuantity !== +factQuantity;

  const isNotEqualToQuantity = () => value < quantity || value > quantity;

  const renderHeaderButtons = () => {
    if (!isEditable) return null;
    if (isEditing) {
      return (
        <div className={classes.headerActionButtons}>
          <Tooltip title={t('CANCEL')}>
            <IconButton
              className={classes.actionButton}
              size="small"
              color="primary"
              aria-label="cancel"
              onClick={() => {
                setIsEditing(false);
                if (isUndefined(updatedQuantity)) {
                  setValue(factQuantity);
                  return;
                }
                if (!updatedQuantity) {
                  setValue('0');
                  return;
                }
                setValue(updatedQuantity);
              }}
              data-test="cancel"
            >
              <HighlightOffRounded />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('APPLY')}>
            <IconButton
              className={classes.actionButton}
              size="small"
              color="primary"
              aria-label="apply"
              onClick={handleApply}
              data-test="apply"
            >
              <CheckCircleOutlineSharp />
            </IconButton>
          </Tooltip>
        </div>
      );
    }
    return (
      <Tooltip title={t('EDIT')}>
        <IconButton
          size="small"
          color="primary"
          aria-label="edit"
          onClick={() => { setIsEditing(true); }}
        >
          <Edit />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <OperationView
      hideHeaderButtonOnBlur={!isEditing}
      boxShadowOnHover={isEditable}
      key={materialValueSapCode}
      title={description}
      subTitle={(
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {materialValueSapCode}
          {shouldDisplayAsEdited && (
            <div>
              {`${t('FACT_QUANTITY_SHORT_UPDATED')}: ${factQuantity} ${unit}`}
            </div>
          )}
        </div>
      )}
      counters={{
        top: {
          left: `${t('PLANNED_QUANTITY')}: ${quantity} ${unit}`,
          right: isEditing
            ? (
              <MaterialValueInput
                negativeColored={isNotEqualToQuantity()}
                value={value}
                unit={unit}
                label={t('FACT_QUANTITY_SHORT')}
                onChange={(val) => {
                  setValue(val);
                }}
              />
            )
            : (
              <div className={clsx(isNotEqualToQuantity() && classes.redColor)}>
                {shouldDisplayAsEdited
                  ? `${t('FACT_QUANTITY_SHORT')}: ${updatedQuantity} ${unit}`
                  : `${t('FACT_QUANTITY_SHORT')}: ${factQuantity} ${unit}`}
              </div>
            ),
        }
      }}
      headerActionButton={renderHeaderButtons()}
    />
  );
};

EditableOperationView.defaultProps = {
  factQuantity: 0,
  operation: {},
  materialValue: {
    quantity: 0,
    updatedQuantity: 0,
    previousQuantity: 0,
    sapCode: '',
    description: '',
    unit: '',
  },
  isEditable: true,
};

EditableOperationView.propTypes = {
  factQuantity: PropTypes.number,
  operation: PropTypes.shape({
    operationId: PropTypes.number,
  }),
  materialValue: PropTypes.shape({
    quantity: PropTypes.number,
    updatedQuantity: PropTypes.number,
    previousQuantity: PropTypes.number,
    sapCode: PropTypes.string,
    description: PropTypes.string,
    unit: PropTypes.string,
  }),
  isEditable: PropTypes.bool,
  showNotValidMaterialValueQuantity: PropTypes.func.isRequired,
};

export default EditableOperationView;
