export const EQUIPMENT = 'EQUIPMENT';
export const NODE = 'NODE';
export const ACTION = 'ACTION';
export const DEPARTMENT = 'DEPARTMENT';
export const MEASUREMENT_POINTS = 'MEASUREMENT_POINTS';

const sectionTypes = t => (
  {
    [EQUIPMENT]: t('EQUIPMENT'),
    [NODE]: t('NODE'),
    [ACTION]: t('MEASUREMENTS'),
    [DEPARTMENT]: t('DEPARTMENT'),
    [MEASUREMENT_POINTS]: t('MEASUREMENT_POINTS'),
  }
);

export const sectionTypesAccusative = t => (
  {
    [EQUIPMENT]: t('EQUIPMENT_ACCUSATIVE'),
    [NODE]: t('NODE_ACCUSATIVE'),
    [ACTION]: t('MEASUREMENTS_ACCUSATIVE'),
    [DEPARTMENT]: t('DEPARTMENT_ACCUSATIVE'),
    [MEASUREMENT_POINTS]: t('MEASUREMENT_POINTS_ACCUSATIVE'),
  }
);

export default sectionTypes;
