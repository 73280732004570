import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import useStores from 'Store/useStores';
import BusinessSelector from 'Common/widgets/BusinessSelector/BusinessSelector';

const ContentWrapper = ({ render }) => {
  const {
    userStore: {
      businessType,
      availableBusinessTypes,
      setBusinessType,
      status,
    }
  } = useStores();

  if (businessType) {
    return (
      <div className="content-wrapper">
        { render({ businessType, status }) }
      </div>
    );
  }
  return (
    <BusinessSelector
      setBusinessType={setBusinessType}
      availableBusinessTypes={availableBusinessTypes}
    />
  );
};

ContentWrapper.propTypes = {
  render: PropTypes.func.isRequired
};

export default observer(ContentWrapper);
