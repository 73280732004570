import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  filterGroupLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 1.5,
    marginTop: 46,
    marginBottom: 16,
  },
  taskType: {
    '& .MuiFormLabel-root': {
      display: 'none',
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'column !important',
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
  },
  exportButton: {
    marginRight: 40,
  },
  importButton: {
    marginRight: 30,
  },
  headerButtonIcon: {
    transform: 'rotate(270deg)',
    width: 22,
    height: 22,
    color: theme.palette.primaryLight,
  },
  usersContainer: {
    transition: 'margin 0.4s',
  },
  usersContainerOpened: {
    marginRight: theme.filterPanelWidth,
  },
  usersContainerClosed: {
    marginRight: 0,
  },
  pageSearchBlock: {
    position: 'relative',
    height: theme.pageSearchBlockHeight,
    resize: 'none',
    paddingTop: 21,
    paddingBottom: 21,
    paddingLeft: 120,
    paddingRight: 130,
    '&::before': {
      zIndex: 4,
      position: 'absolute',
      content: '""',
      display: 'block',
      top: '100%',
      left: 0,
      right: 0,
      height: theme.spacing(1),
      background:
        'linear-gradient(to top, rgba(255, 255, 255,0)0%,rgba(255,255,255,0.25)25%,rgba(255, 255, 255, 0.5) 50%,rgba(255, 255, 255, 0.75) 75%,rgba(255, 255, 255, 1) 100%)',
    },
  },
  searchIcon: {
    color: theme.palette.black30,
  },
  searchInput: {
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 4,
    },
    '& $inputClearButton': {
      display: 'none',
    },
    '&:hover': {
      '& $inputClearButton': {
        display: 'block',
      },
    },
    '& .MuiInputLabel-shrink': {
      display: 'none',
    },
    '& fieldset legend span': {
      display: 'none',
    },
    '& input': {
      '&::placeholder': {
        color: theme.palette.black30,
        opacity: 1
      },
      '&::-webkit-input-placeholder': {
        color: theme.palette.black30,
        opacity: 1
      },
      '&::-moz-placeholder': {
        color: theme.palette.black30,
        opacity: 1
      },
      '&:-moz-placeholder': {
        color: theme.palette.black30,
        opacity: 1
      },
      '&:-ms-input-placeholder': {
        color: theme.palette.black30,
        opacity: 1
      },
    },
    '& .Mui-focused': {
      '& $inputClearButton': {
        display: 'block',
      },
    },
  },
  inputClearButton: {
    color: theme.palette.primaryLight,
    borderRadius: '50%',
    padding: 4,
    width: 28,
    height: 28,
    '&:hover': {
      backgroundColor: theme.palette.lightSelection
    },
    cursor: 'pointer',
  },
}));

export default useStyles;
