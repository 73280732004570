import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { observer } from 'mobx-react';
import { useTheme } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import useStores from 'Store/useStores';
import UserCard from 'Common/pages/User/components/UserCard';
import UserListItem from 'Common/pages/User/components/UserListItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CREATE_USER } from 'Shared/constants/routes';
import NoResultsBlock from 'Common/widgets/NoResultsBlock';
import SearchHelperBlock from 'Common/widgets/SearchHelperBlock';

import useStyles from './styles';


const UsersContainer = ({ mode, maxHeight }) => {
  const { t } = useTranslation();
  const { userCardWidth } = useTheme();
  const history = useHistory();
  const classes = useStyles({ mode, maxHeight });

  const {
    usersListStore: {
      usersList = [],
      hasNextPage,
      loadUsersList,
      currentPage,
      isLoading,
    },
    usersListFilterStore: {
      isFiltersSet,
      searchWord,
    },
    notificationStore: { enqueueSnackbar },
  } = useStores();

  const showNoResultsBlock = isEmpty(usersList) && !isLoading && (isFiltersSet || searchWord);
  const showInitialBlock = !(isFiltersSet || searchWord);

  const addUser = (id) => {
    const userLink = `${CREATE_USER}/${id}`;
    history.push({
      pathname: userLink,
    });
  };

  const fetchMoreData = () => {
    loadUsersList(currentPage + 1).catch(() => {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('REQUEST_LOAD_LIST_ERROR'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
    });
  };

  return (
    <div id="scrollableDiv" className={classes.root}>
      {showInitialBlock && <SearchHelperBlock />}
      {showNoResultsBlock && <NoResultsBlock />}
      {!isEmpty(usersList) && (
        <InfiniteScroll
          dataLength={usersList.length}
          next={fetchMoreData}
          hasMore={hasNextPage}
          loader={(
            <div className={classes.circularProgressWrapper}>
              <CircularProgress />
            </div>
          )}
          style={{
            width: '100%',
            resize: () => (maxHeight ? 'none' : 'inherit'),
            display: 'grid',
            gridTemplateColumns: mode === 'list' ? 'none' : `repeat(auto-fill, minmax(${userCardWidth}px, 1fr))`,
            gap: 16,
          }}
          scrollableTarget="scrollableDiv"
        >
          {usersList.map((user = {}) => {
            const { id: userId } = user;
            if (mode === 'list') return <UserListItem key={userId} user={user} addUser={addUser} />;
            return <UserCard key={userId} user={user} addUser={addUser} />;
          })}
        </InfiniteScroll>
      )}
    </div>
  );
};

UsersContainer.defaultProps = {
  maxHeight: 'auto',
};

UsersContainer.propTypes = {
  mode: PropTypes.string.isRequired,
  maxHeight: PropTypes.string,
};

export default observer(UsersContainer);
