import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import SectionTitle from 'Common/components/Forms/Title/components/SectionTitle';
import EmployeeListItem from 'Src/business/tpsRepairs/components/Employee/EmployeeListItem';
import NoContent from 'Common/components/Content/components/NoContent/NoContent';
import HeaderLayout from 'Common/widgets/Layout/Header/Header';
import Layout from 'Common/widgets/Layout/Paper/Layout';
import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import BrigadesStaff from 'Business/tpsRepairs/components/BrigadesStaff';
import { Search } from '@material-ui/icons/';
import TextInput from 'Common/components/Forms/Input/Text';


const useStyles = makeStyles((theme) => {
  const {
    headerHeight,
    pageHeaderHeight,
    mainContentPaddingTop,
    mainContentPaddingBottom,
  } = theme;
  const searchInputHeight = 66;
  const pageSectionTitleHeight = 27;
  const computedStaffSectionHeight = `calc(100vh - ${headerHeight}px - ${pageHeaderHeight}px - ${mainContentPaddingTop}px - ${mainContentPaddingBottom}px - ${theme.spacing(2)}px - ${searchInputHeight}px - ${pageSectionTitleHeight}px - ${theme.spacing(3)}px)`;

  return {
    backButton: {
      paddingTop: 0,
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
    margin: {
      marginBottom: theme.spacing(2),
    },
    noContentColored: {
      backgroundColor: '#fffce9',
      color: '#ab9800'
    },
    staffSection: {
      height: computedStaffSectionHeight,
      overflowY: 'auto',
    },
    wrapperContent: {
      padding: 20,
    },
    title: {
      color: theme.palette.black
    }
  };
});

const BrigadeManageWidget = observer(({
  title,
  handleGoBack,
  filteredStaff,
  handleToggle,
  employeesState,
  brigadeTitle,
  handleTitleChange,
  toggleBrigadier,
  handleRemoveFromBrigade,
  searchQuery,
  handleSearchQuery,
  handleSave
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const isBrigadeStaffValid = () => employeesState.brigadier.id && employeesState.selected.length >= 2;

  return (
    <Layout fixedHeight>
      <HeaderLayout
        title={title || t('BRIGADE_CREATION')}
        titleGoBack={t('GO_BACK')}
      >
        <HeaderButton type="close" handleClick={handleGoBack} />
        <HeaderButton type="save" handleClick={handleSave} />
      </HeaderLayout>
      <Grid className={classes.wrapperContent}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <SectionTitle text={t('STAFF_LIST')} />

            <TextInput
              value={searchQuery}
              marginTop={16}
              marginBottom={10}
              label={t('SEARCH_STAFF')}
              name="searchQuery"
              InputEndAdornment={(<Search />)}
              onChange={handleSearchQuery}
              data-test="searchQuery-input"
            />

            {filteredStaff.length ? (
              <List className={classes.staffSection}>
                {filteredStaff.map(employee => (
                  <EmployeeListItem
                    employee={employee}
                    handleToggle={handleToggle}
                    checked={employeesState.checked}
                    key={employee.id}
                  />
                ))}
              </List>
            ) : (
              <NoContent text={t('EMPTY_BRIGADE_STAFF_LIST')} colored />
            )}
          </Grid>
          <Grid item xs={6}>
            <SectionTitle text={t('CREATED_BRIGADE')} />
            <TextInput
              marginTop={16}
              label={brigadeTitle ? t('BRIGADE_TITLE') : t('SHOULD_ENTER_BRIGADE_TITLE')}
              value={brigadeTitle}
              onChange={handleTitleChange}
              name="brigadeName"
              placeholder={t('SELECT_VALUE')}
              required
              data-test="brigadeName"
            />

            <SectionTitle text={t('BRIGADE_STAFF')} isSubtitle />

            <BrigadesStaff
              name="brigadesStaff"
              value={isBrigadeStaffValid()}
              type="custom"
              required
              handleToggle={handleToggle}
              employeesState={employeesState}
              toggleBrigadier={toggleBrigadier}
              handleRemoveFromBrigade={handleRemoveFromBrigade}
            />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
});

BrigadeManageWidget.propTypes = {
  handleGoBack: PropTypes.func.isRequired,
  sectorStaff: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line
  filteredStaff: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line
  handleToggle: PropTypes.func.isRequired,
  employeesState: PropTypes.shape({
    brigadier: PropTypes.object, // eslint-disable-line
    selected: PropTypes.array, // eslint-disable-line
    checked: PropTypes.array, // eslint-disable-line
  }).isRequired,
  brigadeTitle: PropTypes.string,
  handleTitleChange: PropTypes.func.isRequired,
  toggleBrigadier: PropTypes.func.isRequired,
  handleRemoveFromBrigade: PropTypes.func.isRequired,
  discardState: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

BrigadeManageWidget.defaultProps = {
  brigadeTitle: '',
  sectorStaff: [],
  filteredStaff: [],
};


export default BrigadeManageWidget;
