import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  Tooltip,
  IconButton,
  ButtonBase,
  Box,
  Typography
} from '@material-ui/core/';
import {
  DragIndicator,
  DeleteOutline,
} from '@material-ui/icons';
import {
  SortableContainer,
  SortableElement,
  sortableHandle
} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import useStyles from './styles';

const DragHandle = sortableHandle(() => {
  const classes = useStyles();
  return (
    <Box className={classes.boxDragHandler}>
      <DragIndicator />
    </Box>
  );
});

const SortableItem = SortableElement(({
  id,
  fullName,
  isChangeable,
  onView,
  onDelete,
  disabledDrag,
  techPlaceId,
  isHoverBlocked,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ isHoverBlocked });

  return (
    <Box className={clsx(classes.boxWrapper, isChangeable ? classes.changeableIndicator : classes.notChangeableIndicator)}>
      <Box className={clsx(classes.box, !isHoverBlocked && classes.hovered)}>
        {(isChangeable && !disabledDrag) && <DragHandle />}
        {isChangeable && !isHoverBlocked && (
          <Tooltip title={t('DELETE')}>
            <IconButton
              className={classes.boxButtonDelete}
              onClick={onDelete(id, techPlaceId)}
              size="small"
              aria-label="settings"
              data-test="deleteButton"
            >
              <DeleteOutline />
            </IconButton>
          </Tooltip>
        )}
        <ButtonBase
          onClick={() => onView(id)}
          className={`${classes.boxInner} ${isChangeable ? classes.boxInnerChangeable : ''}`}
          data-test={`checkpoint_${id}`}
        >
          <Typography className={classes.boxTitle} data-test="checkpointId">
            {`№${id}`}
          </Typography>
          <Typography className={classes.boxSubTitle} data-test="checkpointFullName">
            {fullName}
          </Typography>
        </ButtonBase>
      </Box>
    </Box>
  );
});

const SortableList = SortableContainer(({
  items, isChangeable, isHoverBlocked, ...props
}) => {
  const classes = useStyles();
  const [isClicked, isClickedSet] = useState();
  const disabled = items.length === 1;

  return (
    <Box
      className={`${classes.boxes} ${(isClicked && !disabled) ? classes.boxesClicked : ''}`}
      onMouseDown={() => isClickedSet(isChangeable)}
      onMouseUp={() => isClickedSet(false)}
    >
      {items.map(({ ...rest }, index) => (
        <SortableItem
          key={`item-${rest.id}`}
          index={index}
          isChangeable={isChangeable}
          disabled={disabled}
          disabledDrag={disabled}
          isHoverBlocked={isHoverBlocked}
          {...rest}
          {...props}
        />
      ))}
    </Box>
  );
});

const CheckpointBox = ({
  techPlaceId,
  withButtons,
  items,
  onView,
  onDelete,
  setSelected,
}) => {
  const classes = useStyles();

  const [isHoverBlocked, setIsHoverBlocked] = useState(false);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setIsHoverBlocked(false);
    setSelected(arrayMove(items, oldIndex, newIndex), techPlaceId);
  };

  return (
    <SortableList
      updateBeforeSortStart={() => setIsHoverBlocked(true)}
      isHoverBlocked={isHoverBlocked}
      lockToContainerEdges
      lockAxis="y"
      axis="y"
      useDragHandle
      items={items}
      onSortEnd={onSortEnd}
      helperClass={classes.boxWrapperDrag}
      isChangeable={withButtons}
      onView={onView}
      onDelete={onDelete}
      techPlaceId={techPlaceId}
    />
  );
};

CheckpointBox.propTypes = {
  techPlaceId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  withButtons: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      number: PropTypes.number,
      title: PropTypes.string,
    })
  ),
  onView: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
};

CheckpointBox.defaultProps = {
  withButtons: false,
  items: [],
};

export default CheckpointBox;
