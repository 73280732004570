import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  commentHeader: {
    paddingTop: 16,
    display: 'flex',
    alignItems: 'flex-end',
  },
  avatarWrapper: {
    padding: '0px 12px 6px 0px',
  },
  author: {
    flex: 1,
  },
  authorLabel: {
    fontSize: 16,
    lineHeight: 1.5,
    color: theme.palette.black,
  },
  dateTime: {
    textAlign: 'end',
    flex: 1,
    fontSize: 12,
    lineHeight: 1.33,
    color: theme.palette.black30,
  },
  authorPosition: {
    fontSize: 12,
    lineHeight: 1.33,
    color: theme.palette.black30,
  },
  comment: {
    padding: '16px 0px',
    fontSize: 16,
    lineHeight: 1.5,
    color: theme.palette.black,
  },
  divider: {
    marginRight: -24,
    marginLeft: -24,
    backgroundColor: theme.palette.midGray
  },
}));

export default useStyles;
