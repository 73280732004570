import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Modal from 'Common/components/Modal/Modal';
import CheckpointDetails from 'Business/tpsInspections/pages/CheckpointDetails';

const useStyles = makeStyles(() => ({
  checkpointViewer: {
    width: '100%',
  }
}));


const CheckpointDetailsOverlay = ({ checkpointId, handleClose }) => {
  const classes = useStyles();

  return (
    <Modal
      handleClose={handleClose}
      fullScreen
      isOpened={checkpointId > 0}
      contentStyle={{
        padding: 0
      }}
    >
      <Box className={classes.checkpointViewer}>
        <CheckpointDetails
          id={checkpointId}
          onClose={handleClose}
        />
      </Box>
    </Modal>
  );
};

CheckpointDetailsOverlay.propTypes = {
  checkpointId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  handleClose: PropTypes.func.isRequired
};

export default CheckpointDetailsOverlay;
