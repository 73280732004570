import Agent from 'ApiAgents/Agent';

export default class ParallelOperationsAgent extends Agent {
  loadList = () => this.request({
    url: '/settings/parallelExecution',
    method: 'GET',
  }, 'tasks');

  save = data => this.request({
    url: '/settings/parallelExecution',
    method: 'POST',
    data: { ...data },
  }, 'tasks');

  update = data => this.request({
    url: '/settings/parallelExecution',
    method: 'PUT',
    data: { ...data },
  }, 'tasks');
}
