import { makeStatusDto } from 'Services/dto/makeTaskStatusDto';
import taskStatusSchema from 'Services/validation/schemas/TaskStatusSchema';
import {
  ASSIGNED_ENUM, CANCELED_ENUM, IN_PROGRESS_ENUM, COMPLETED_ENUM, PAUSE_ENUM,
} from 'Shared/constants/taskStatuses';

const dtoMaker = makeStatusDto(taskStatusSchema);

export const taskStatusesConfig = t => (
  {
    NEW: dtoMaker({
      title: t('CANCELED'),
      name: 'new',
      query: [ASSIGNED_ENUM],
    }),
    IN_PROGRESS: dtoMaker({
      title: t('IN_PROGRESS'),
      name: 'in_progress',
      query: [IN_PROGRESS_ENUM],
    }),
    COMPLETED: dtoMaker({
      title: t('COMPLETED'),
      name: 'completed',
      query: [COMPLETED_ENUM],
    }),
    CANCELED: dtoMaker({
      title: t('CANCELED'),
      name: 'canceled',
      query: [CANCELED_ENUM],
    }),
    PAUSE: dtoMaker({
      title: t('PAUSE'),
      name: 'pause',
      query: [PAUSE_ENUM],
    }),
  }
);

export default taskStatusesConfig;
