import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  dragContainer: {
    zIndex: 9999,
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  dragLeftSide: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dragIndicator: {
    cursor: 'pointer',
    background: theme.palette.lightGray,
    height: 80,
    width: 37,
    borderRight: `1px solid ${theme.palette.gray}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
  },
  sortableElementWrapper: {
    position: 'relative',
    '&:last-child .sortableElementNum:before': {
      display: 'none',
    },
  },
  sortableElementNum: {
    position: 'absolute',
    left: -27,
    top: 'calc(50% - 8px)',
    width: 18,
    height: 18,
    borderRadius: '50%',
    background: theme.palette.primaryLight,
    outline: `8px solid ${theme.palette.white}`,
    color: theme.palette.white,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 'calc(50% - 1px)',
      top: 'calc(100% + 8px)',
      width: 2,
      height: 62,
      display: 'block',
      background: theme.palette.midGray,
    }
  },
}));

export default useStyles;
