import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import useStores from 'Store/useStores';
import RadioInput from 'Common/components/Forms/Input/Radio';
import {
  PanelSubTitle
} from 'Common/widgets/Layout/Panel/Panel';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const Priority = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { isReadonlyByMode } = props;

  const {
    coalTaskStore: {
      taskData,
      updateDataField,
    },
  } = useStores();

  const {
    isHighPriority,
  } = taskData;

  if (isReadonlyByMode) {
    return (
      <>
        <PanelSubTitle>{t('PRIORITY')}</PanelSubTitle>
        <div className={clsx(classes.readOnlyLabel, isHighPriority && classes.isHighPriority)}>
          {isHighPriority ? t('HIGH_PRIORITY') : t('STANDARD')}
        </div>
      </>
    );
  }
  return (
    <RadioInput
      label={t('PRIORITY')}
      value={isHighPriority}
      name="priority"
      onChange={(value) => {
        updateDataField('isHighPriority', value === 'true');
      }}
      options={[{ value: false, title: t('STANDARD'), enumValue: 'standard' }, { value: true, title: t('HIGH_PRIORITY'), enumValue: 'highPriority' }]}
      data-test="priority"
    />
  );
};

Priority.propTypes = {
  isReadonlyByMode: PropTypes.bool.isRequired,
};

export default observer(Priority);
