import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ActionButton from 'Common/components/Forms/Button/components/Action/Action';
import { ReactComponent as NoVolumesBanner } from 'Assets/banners/no-volumes.svg';

import useStyles from './styles';

const NoContentBlock = ({ setStateCreateValueModal }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.noContent}>
      <div className={classes.subtitle}>{t('VOLUME_TAB')}</div>
      <NoVolumesBanner />
      <div className={classes.text}>
        <p>{t('VOLUME_PARAMETER_IS_ABSENT')}</p>
        <p>{t('CREATE_VOLUME_PARAMETER')}</p>
      </div>
      <div className={classes.button}>
        <ActionButton
          text={t('CREATE')}
          type="confirm"
          handleClick={() => setStateCreateValueModal(true)}
        />
      </div>
    </div>
  );
};

NoContentBlock.propTypes = {
  setStateCreateValueModal: PropTypes.func.isRequired,
};

export default NoContentBlock;
