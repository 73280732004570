import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Switch,
  Route,
  useHistory,
  Redirect,
  withRouter,
  useLocation
} from 'react-router-dom';
import { observer } from 'mobx-react';
import Skeleton from '@material-ui/lab/Skeleton';

import FilterPanel from 'Common/widgets/FilterPanel';
import { VIEW_TASK, TASKS } from 'Shared/constants/routes';
import useStores from 'Store/useStores';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import useCurrentListStatus from 'Src/hooks/useCurrentListStatus';
import { taskStatusesConfig } from 'Business/tpsInspections/config/tasksTableConfig/taskStatusesConfig';
import tableConfig from 'Business/tpsInspections/config/tasksTableConfig/tableConfig';
import DataTable from 'Common/components/DataTable/DataTable';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import ListTabs from 'Common/widgets/Tabs/ListTabs';
import Header from 'Common/widgets/Layout/Header/Header';
import FilterButton from 'Common/widgets/FilterButton/';
import Input from 'Common/components/Forms/Input/Input';
import DateInput from 'Common/components/Forms/Input/Date';
import DottedLoader from 'Common/components/Progress/components/Dotted/DottedLoader';
import useTableBodyHeight from 'Src/utils/table/useTableBodyHeight';
import { INITIAL_PAGE_NUMBER } from 'Shared/constants/paging';

const TaskListPage = ({ match }) => {
  const { t } = useTranslation();
  const translatedTaskStatusesConfig = taskStatusesConfig(t);
  const {
    tpsInspectCreatedTaskListStore: {
      totalPages,
      isLoadingByPage,
      hasNextPage,
      hasPreviousPage,
      isTaskListLoaded,
      taskList,
      loadTasksList
    },
    tpsInspectTaskListFilterStore: {
      loadInitialFilters,
      handlePowerUnitChange,
      handleRouteChange,
      handleRolesChange,
      discardState,
      setState,
      powerUnits,
      roles,
      currentRoutes,
      isFiltersSet,
      discardSelectedFilters,
      currentAutoTasks,
      dependentFiltersLoadErrors: {
        routesLoadError,
        autotasksLoadError
      },
      initialFiltersLoadError,
      isRoutesLoading,
      isInitialFiltersLoading,
      isAutotasksLoading,
      selectedState: {
        assigneeRoles,
        techPlaceIds,
        routeId,
        expectedStartDate,
        expectedFinishDate,
        automatedTaskId,
      }
    },
    notificationStore: { enqueueSnackbar },
  } = useStores();
  const currentStatus = useCurrentListStatus('tasks');
  const [filterOpened, setFilterVisibility] = useState();
  const history = useHistory();
  const statusConfigArr = Object.values(translatedTaskStatusesConfig);
  const bodyHeight = useTableBodyHeight({ withPageTabs: true, withTableFooter: true });
  const location = useLocation();
  const { state, pathname } = location;
  const { paginationParams: { pageNumber, perPage } = {} } = state || {};

  const translatedTableConfig = tableConfig(t);

  const goToInitialTab = () => history.replace(`${TASKS}/${translatedTaskStatusesConfig.NEW.name}`);

  const handleFilterBtnClick = () => setFilterVisibility(prevState => !prevState);

  const onRowClickHandler = (event, { taskId }, { page, perPage: itemsOnPage } = {}) => {
    history.push({
      pathname: `${VIEW_TASK}/${taskId}`,
      state: { paginationParams: { pageNumber: page, perPage: itemsOnPage }, fromPath: pathname }
    });
  };

  const getStatusQuery = () => {
    if (!currentStatus) return [];
    const { query } = translatedTaskStatusesConfig[currentStatus.toUpperCase()];

    return query;
  };

  const loadPaginatedTasks = (page, itemsPerPage) => loadTasksList(getStatusQuery(), page, itemsPerPage).catch(() => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('REQUEST_LOAD_LIST_ERROR')
          }]
        }],
      },
      variant: 'error',
    });
  });


  const handleClearFilters = () => {
    discardSelectedFilters();
    loadPaginatedTasks(INITIAL_PAGE_NUMBER)
      .then(setFilterVisibility(false));
  };

  const handleFiltersApply = () => {
    loadPaginatedTasks(INITIAL_PAGE_NUMBER)
      .then(setFilterVisibility(false));
  };

  useEffect(() => {
    if (currentStatus === '') {
      goToInitialTab();
      return;
    }

    loadPaginatedTasks(pageNumber || INITIAL_PAGE_NUMBER, perPage);
    history.replace({
      state: {},
    });
    loadInitialFilters();
  }, [currentStatus]);

  useEffect(() => () => discardState(), []);

  const renderFilterPanel = () => (
    <FilterPanel
      filterOpened={filterOpened}
      filterApplyBtnLabel={t('APPLY')}
      headerLabel={t('FILTRATION')}
      headerClearBtnLabel={t('CLEAR')}
      hasDiff={isFiltersSet}
      hasActiveFilters={isFiltersSet}
      handleClearFilters={handleClearFilters}
      setFilters={handleFiltersApply}
    >
      {isInitialFiltersLoading
        && <Skeleton variant="rect" width="100%" height={250} />}

      {initialFiltersLoadError
        && <div style={{ color: 'red' }}>{t(initialFiltersLoadError)}</div>}

      {(!isInitialFiltersLoading && !initialFiltersLoadError)
        && (
        <>
          <Input
            fullOutlined
            type="tags"
            options={roles}
            onChange={handleRolesChange}
            name="roles"
            label={t('EMPLOYEE')}
            value={assigneeRoles}
            optionTitleKey="value"
            optionValueKey="value"
          />
          <DateInput
            label={t('START_DATE')}
            name="expectedStartDate"
            value={expectedStartDate}
            onChange={val => setState(val, 'expectedStartDate')}
            maxDate={expectedFinishDate}
            data-test="expectedStartDate"
          />
          <DateInput
            label={t('END_DATE')}
            name="expectedFinishDate"
            value={expectedFinishDate}
            onChange={val => setState(val, 'expectedFinishDate')}
            data-test="expectedFinishDate"
          />
          <Input
            type="tags"
            options={powerUnits}
            onChange={handlePowerUnitChange}
            name="powerUnits"
            label={t('POWER_UNIT')}
            value={techPlaceIds}
            optionTitleKey="title"
            optionValueKey="id"
          />
          <DottedLoader isLoading={isRoutesLoading}>
            <Input
              type="select"
              options={currentRoutes}
              onChange={handleRouteChange}
              name="routeId"
              label={t('ROUTE')}
              value={routeId}
              optionTitleKey="name"
              optionValueKey="id"
              disabled={!techPlaceIds.length || !currentRoutes.length}
              {...routesLoadError && { errorMessage: t(routesLoadError) }}
            />
          </DottedLoader>
          <DottedLoader isLoading={isAutotasksLoading}>
            <Input
              type="select"
              options={currentAutoTasks}
              onChange={val => setState(val, 'automatedTaskId')}
              name="automatedTaskId"
              label={t('AUTOTASK')}
              value={automatedTaskId}
              optionTitleKey="title"
              optionValueKey="automatedTaskId"
              disabled={!routeId || !currentAutoTasks.length}
              {...autotasksLoadError && { errorMessage: t(autotasksLoadError) }}
            />
          </DottedLoader>
        </>
        )}
    </FilterPanel>
  );

  if (!currentStatus) return null;

  return (
    <PaperLayout autoHeight>
      {isLoadingByPage && <ProgressBar />}
      <Header title={t('TASKS')}>
        <FilterButton
          onClickFilterButton={handleFilterBtnClick}
          filterOpened={filterOpened}
          hasActiveFilters={isFiltersSet}
        />
      </Header>
      <Switch>
        {statusConfigArr.map(({ name }) => (
          <Route
            key={name}
            path={`${match.path}/${name}`}
            render={props => (isTaskListLoaded ? (
              <div style={{ position: 'relative' }}>
                <ListTabs
                  matchUrl={match.url}
                  statusConfig={statusConfigArr}
                  value={currentStatus}
                />
                <DataTable
                  isDataLoaded={isTaskListLoaded}
                  {...props}
                  initialPage={pageNumber}
                  initialPerPage={perPage}
                  titleSingle={t('TASK_ACCUSATIVE')}
                  columns={translatedTableConfig}
                  data={taskList}
                  tableBodyHeight={bodyHeight}
                  onRowClick={onRowClickHandler}
                  count={totalPages}
                  hasNextPage={hasNextPage}
                  hasPreviousPage={hasPreviousPage}
                  onPageChange={loadPaginatedTasks}
                  onPerPageChange={loadPaginatedTasks}
                  actions={[]}
                  dataTestRowSuffixEntityName="taskId"
                />
                {renderFilterPanel()}
              </div>
            ) : <ProgressBar />)}
          />
        ))}
        <Redirect to={`/tasks/${translatedTaskStatusesConfig.NEW.name}`} />
      </Switch>
    </PaperLayout>
  );
};

TaskListPage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};


export default withRouter(observer(TaskListPage));
