import {
  action, observable, runInAction, makeObservable
} from 'mobx';
import {
  filter, startsWith, get, cloneDeep, find, assign, isUndefined
} from 'lodash';
import TaskAgent from 'ApiAgents/Tasks/TaskAgent';
import ControlAgent from 'ApiAgents/Control/ControlAgent';
import EquipmentAgent from 'ApiAgents/Equipment/EquipmentAgent';

const taskAgent = new TaskAgent();
const controlAgent = new ControlAgent();
const equipmentAgent = new EquipmentAgent('techobjects');

export class MaterialValuesControlStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable isTaskListLoading = false;

  @observable isTaskListLoaded = false;

  @observable isHistoryLoading = false;

  @observable isHistoryLoaded = false;

  @observable isOperationUpdating = false;

  @observable isLoadingByPage = false;

  @observable error = null;

  @observable isTaskLoading = false;

  @observable isTaskLoaded = false;

  @observable totalPages = null;

  @observable hasNextPage = null;

  @observable tasks = [];

  @observable statusList = [];

  @observable isStatusListLoaded = false;

  @observable reasonsList = {};

  @observable materials = {};

  @observable selectedTask = {};

  @observable history = [];

  @observable dictionaries = {
    taskStatus: {},
    TaskType: {},
  };

  @action loadTaskList = async (statuses = [''], pageNumber, pageSize) => {
    const isPaginating = !!pageNumber;
    this.isTaskListLoading = true;
    this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', true);

    try {
      const params = {
        statuses
      };

      const { items, totalPages, hasNextPage } = await controlAgent.loadControlList({ ...params }, true, pageNumber, pageSize);

      runInAction(() => {
        this.tasks = items;
        this.totalPages = totalPages;
        this.hasNextPage = hasNextPage;
        this.isTaskListLoading = false;
        this.isTaskListLoaded = true;
      });
    } catch (error) {
      console.log('ERROR in TASKS FETCHING: ', error);
      runInAction(() => {
        this.tasks = [];
        this.totalPages = 0;
        this.hasNextPage = false;
        this.isTaskListLoading = false;
        this.isTaskListLoaded = true;
      });
      throw new Error(error);
    }

    runInAction(() => {
      this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', false);
    });
  };

  @action getMaterialsByIds = async (ids) => {
    if (ids.length === 0) return false;
    try {
      const materials = await equipmentAgent.getTechObjectsByIds(ids);
      runInAction(() => {
        this.materials = materials.reduce((acc, material) => ({ ...acc, [material.technicalObjectId]: material }), {});
      });
    } catch (error) {
      console.log('ERROR in GET TECH OBJECTS BY IDS: ', error);
    }
  };

  @action getTaskById = async (taskId, operationStatus) => {
    this.istaskLoading = true;
    this.isTaskLoaded = false;
    this.selectedTask = {};
    this.materials = {};
    try {
      const { task, rejectionComments = [] } = await controlAgent.getTaskById({ id: taskId, status: operationStatus }) || {};

      const materialIds = [...task.rootTechnicalObjectIds];
      if (task.operations) {
        task.operations.forEach(({ equipmentId, technicalPlaceId }) => {
          if (equipmentId > 0) {
            materialIds.push(equipmentId);
          } else if (technicalPlaceId > 0) {
            materialIds.push(technicalPlaceId);
          }
        });
      }
      this.getMaterialsByIds([...(new Set(materialIds))]);

      runInAction(() => {
        this.selectedTask = {
          ...task,
          rejectionComments: rejectionComments.map(({
            createdAt, userPosition = '', userFullName, comment, operationId
          } = {}) => ({
            comment, operationId, authorName: userFullName, authorPosition: userPosition, dateTime: createdAt
          }))
        };
        this.istaskLoading = false;
        this.isTaskLoaded = true;
      });
    } catch (error) {
      console.log('ERROR in TASK FETCHING: ', error);
      runInAction(() => {
        this.selectedTask = {};
        this.istaskLoading = false;
        this.isTaskLoaded = true;
      });
      throw new Error(error);
    }
  };

  @action confirmMaterialValues = async (operationId) => {
    try {
      this.isOperationUpdating = true;
      const approves = [{ operationId }];
      const { error = false } = await controlAgent.confirmMaterialValues({ approves });
      runInAction(() => {
        this.isOperationUpdating = false;
      });
      return { error };
    } catch (error) {
      console.log('ERROR IN MATERIAL VALUES CONFIRMATION', error);
      runInAction(() => {
        this.isOperationUpdating = false;
      });
      return { error };
    }
  }

  @action rejectMaterialValues = async (operationId, comment) => {
    try {
      this.isOperationUpdating = true;
      const comments = [{ comment, operationId }];
      const { error = false } = await controlAgent.rejectMaterialValues({ comments });
      runInAction(() => {
        this.isOperationUpdating = false;
      });
      return { error };
    } catch (error) {
      console.log('ERROR IN MATERIAL VALUES REJECTION', error);
      runInAction(() => {
        this.isOperationUpdating = false;
      });
      return { error };
    }
  }

  @action removeOperation = (operationIdToRemove) => {
    this.selectedTask.operations = this.selectedTask.operations.filter(({ operationId } = {}) => operationId !== operationIdToRemove);
  }

  @action getHistory = async (taskId) => {
    this.history = [];
    this.isHistoryLoading = true;
    this.isHistoryLoaded = false;
    try {
      const { items = [] } = await controlAgent.getHistory({ taskId }) || {};
      runInAction(() => {
        this.history = items;
        this.isHistoryLoading = false;
        this.isHistoryLoaded = true;
      });
    } catch (error) {
      console.log('ERROR IN FETCHING HISTORY: ', error);
      runInAction(() => {
        this.isHistoryLoading = false;
        this.isHistoryLoaded = true;
      });
      throw new Error(error);
    }
  };

  @action getReasons = async () => {
    this.isLoading = true;
    try {
      const reasons = await taskAgent.getReasonList() || [];
      const filteredReasons = filter(reasons, ({ reason } = {}) => startsWith(reason, 'REASON') || startsWith(reason, 'OTHER'));
      runInAction(() => {
        this.reasonsList = filteredReasons.reduce((acc, { reason, value }) => ({ ...acc, [reason]: value }), {});
      });
    } catch (error) {
      console.log('ERROR in FETCHING REASONS LIST: ', error);
    }
    runInAction(() => {
      this.isLoading = false;
    });
  };

  @action updateOperations = (updatedOperations = []) => {
    const task = cloneDeep(this.selectedTask);
    const { operations } = task;
    updatedOperations.forEach(({ operationId: updatedOperationId, materialValues: updatedMaterialValues }) => {
      const operationToUpdate = find(operations, { operationId: +updatedOperationId });
      const { materialValues: materialValuesToUpate = [] } = operationToUpdate || {};
      updatedMaterialValues.forEach(({ sapCode: updatedMaterialValueSapCode, updatedQuantity }) => {
        const materialValueToUpate = find(materialValuesToUpate, { sapCode: updatedMaterialValueSapCode });
        assign(materialValueToUpate, { updatedQuantity });
      });
    });
    runInAction(() => {
      this.selectedTask = task;
    });
  };

  @action updateMaterialValueQuantity = (value, operationId, materialValueSapCode) => {
    const task = cloneDeep(this.selectedTask);
    const { operations } = task;
    const operation = find(operations, { operationId });
    const materialValues = get(operation, 'materialValues');
    const materialValue = find(materialValues, { sapCode: materialValueSapCode });
    assign(materialValue, { updatedQuantity: value });
    runInAction(() => {
      this.selectedTask = task;
    });
  };

  @action setMaterialValues = async (operationIdToSet) => {
    try {
      this.isOperationUpdating = true;
      const operation = find(this.selectedTask.operations, ({ operationId } = {}) => operationId === operationIdToSet);
      const { operationId, materialValues } = operation;
      const operations = [
        {
          operationId,
          materialValues: materialValues.map(
            ({ materialValueId, updatedQuantity } = {}) => ({
              materialValueId,
              quantity: !isUndefined(updatedQuantity)
                ? updatedQuantity
                : operation.materialValueConsumptions.reduce((acc, consumptionMaterial) => (
                  (consumptionMaterial.materialValueId === materialValueId) ? consumptionMaterial.quantity : acc), 0)
            })
          )
        }];
      const { error = false } = await controlAgent.setMaterialValues(operations);

      runInAction(() => {
        this.isOperationUpdating = false;
      });
      return { error };
    } catch (error) {
      console.log('ERROR in MATERIAL VALUES UPDATING: ', error);
      runInAction(() => {
        this.isOperationUpdating = false;
      });
      return { error };
    }
  };

  @action resetTaskListStorePart = () => {
    this.isTaskListLoading = false;
    this.isTaskListLoaded = false;
    this.totalPages = null;
    this.hasNextPage = false;
    this.tasks = [];
  };

  @action resetTaskStorePart = () => {
    this.isTaskLoading = false;
    this.isTaskLoaded = false;
    this.selectedTask = {};
    this.history = [];
    this.isOperationUpdating = false;
  };

  @action resetHistoryStorePart = () => {
    this.history = [];
    this.isHistoryLoaded = false;
    this.isHistoryLoading = false;
  };
}

export default MaterialValuesControlStore;
