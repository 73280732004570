import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  manual: {
    fontSize: 16,
    border: `1px solid ${theme.palette.black20}`,
    borderRadius: 4,
    padding: theme.spacing(1, 1, 1, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 328,
  },
  binary: {
    fontSize: 16,
  },
  manualRightSide: {
    minWidth: 30,
    color: theme.palette.black30,
  },
}));

export default useStyles;
