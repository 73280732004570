import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { get, isEmpty } from 'lodash';
import {
  Tooltip, IconButton, ButtonBase, Box, Collapse
} from '@material-ui/core';
import {
  DragIndicator, DeleteOutline, Edit, ExpandMore
} from '@material-ui/icons';
import { SortableElement, sortableHandle } from 'react-sortable-hoc';
import PlannerCustomAction from 'Business/tpsInspections/components/PlannerCustomAction';
import OverflowTipSimple from 'Common/widgets/OverflowTipSimple';

import useStyles from './styles';

const DragHandle = sortableHandle(() => {
  const classes = useStyles();
  return (
    <Box className={classes.boxDragHandler}>
      <DragIndicator />
    </Box>
  );
});

const PlannerCheckpointItem = ({
  id,
  title,
  label,
  technicalObjects,
  isChangeable,
  onView,
  onEditCheckpoint,
  onEditTechnicalObject,
  onEditOperations,
  disabledDrag,
  isHoverBlocked,
  isReadonlyByMode,
  onDeleteCheckpoint,
  onDeleteTechnicalObject,
  onDeleteOperations,
  onUpdateCustomAction,
  isExpanded,
  toggleCollapseCheckpoint,
  withPaddingLeft,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ isHoverBlocked, isReadonlyByMode });

  return (
    <Box
      className={clsx(
        classes.boxWrapper,
        withPaddingLeft && classes.withPaddingLeft,
        isChangeable ? classes.changeableIndicator : classes.notChangeableIndicator,
      )}
    >
      <Box className={clsx(classes.box, !isHoverBlocked && classes.hovered)}>
        {isChangeable && !disabledDrag && <DragHandle />}
        <ButtonBase
          onClick={() => onView(id)}
          className={`${classes.boxInner} ${isChangeable ? classes.boxInnerChangeable : ''}`}
          data-test={`checkpoint_${id}`}
        >
          <div className={classes.checkpointHeader}>
            <div>
              <OverflowTipSimple
                tooltipProps={{ placement: 'bottom' }}
                tooltipTitle={title}
                text={title}
                textProps={{ className: classes.boxTitle }}
                data-test="measuringPointsHeader"
              />
              <OverflowTipSimple
                tooltipProps={{ placement: 'bottom' }}
                tooltipTitle={label.id}
                text={label.id}
                textProps={{ className: classes.boxSubTitle }}
                data-test="checkpointId"
              />
            </div>
            <div className={classes.headerButtonsWrapper}>
              {isChangeable && !isHoverBlocked && (
                <div className={classes.headerButtons}>
                  <Tooltip title={t('DELETE_CHECKPOINT')}>
                    <IconButton
                      className={classes.deleteButton}
                      onClick={() => onDeleteCheckpoint(id, title)}
                      size="small"
                      aria-label="settings"
                      data-test="deleteButton"
                    >
                      <DeleteOutline />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('EDIT_CHECKPOINTS_TASKS')}>
                    <IconButton
                      className={classes.editButton}
                      onClick={onEditCheckpoint}
                      size="small"
                      aria-label="settings"
                      data-test="editButton"
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
              <Tooltip title={isExpanded ? t('HIDE_EQUIPMENT') : t('SHOW_EQUIPMENT')} classes={{ tooltip: classes.tooltip }}>
                <IconButton
                  aria-label="expand"
                  onClick={() => toggleCollapseCheckpoint(!isExpanded, id)}
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: isExpanded,
                  })}
                >
                  <ExpandMore />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </ButtonBase>
        <Collapse in={isExpanded} timeout={0} unmountOnExit>
          <Box className={classes.innerBox}>
            {technicalObjects.map((technicalObject = {}) => {
              const technicalObjectTitle = get(technicalObject, 'title', '');
              const technicalObjectId = get(technicalObject, 'id', '');
              const technicalObjectCustomAction = get(technicalObject, 'customAction', '');
              const technicalObjectDefects = get(technicalObject, 'defects', []);
              const technicalObjectMeasuringPoints = get(technicalObject, 'measuringPoints', []);
              return (
                <div className={classes.technicalObjectWrapper}>
                  <div className={classes.technicalObjectHeader}>
                    {/*operationTypeHeader*/}
                    <OverflowTipSimple
                      tooltipProps={{ placement: 'bottom' }}
                      tooltipTitle={technicalObjectTitle}
                      text={technicalObjectTitle}
                      textProps={{ className: classes.technicalObjectTitle }}
                      data-test="measuringPointsHeader"
                    />
                    {isChangeable && !isHoverBlocked && (
                      <div className={classes.headerButtons}>
                        <Tooltip title={t('DELETE_EQUIPMENT')}>
                          <IconButton
                            className={classes.deleteButton}
                            onClick={() => onDeleteTechnicalObject(technicalObjectId, technicalObjectTitle)}
                            size="small"
                            aria-label="settings"
                            data-test="deleteButton"
                          >
                            <DeleteOutline />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t('EDIT_EQUIPMENT_TASKS')}>
                          <IconButton
                            className={classes.editButton}
                            onClick={() => onEditTechnicalObject(technicalObjectId)}
                            size="small"
                            aria-label="settings"
                            data-test="editButton"
                          >
                            <Edit />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  <div className={classes.technicalObjectInfo}>
                    {technicalObjectCustomAction && (
                      <PlannerCustomAction
                        onDeleteOperations={onDeleteOperations}
                        onUpdateCustomAction={onUpdateCustomAction}
                        technicalObjectId={technicalObjectId}
                        technicalObjectCustomAction={technicalObjectCustomAction}
                        isChangeable={isChangeable}
                        isHoverBlocked={isHoverBlocked}
                      />
                    )}
                    {!isEmpty(technicalObjectDefects) && (
                      <div className={classes.operationType}>
                        <div className={classes.operationTypeHeader}>
                          <OverflowTipSimple
                            tooltipProps={{ placement: 'bottom' }}
                            tooltipTitle={t('INSPECTIONS')}
                            text={t('INSPECTIONS')}
                            textProps={{ className: classes.technicalObjectInfoTitle }}
                            data-test="measuringPointsHeader"
                          />
                          {isChangeable && !isHoverBlocked && (
                            <div className={classes.headerButtons}>
                              <Tooltip title={t('DELETE_EQUIPMENT_TASK')}>
                                <IconButton
                                  className={classes.deleteButton}
                                  onClick={() => onDeleteOperations('DEFECTS', technicalObjectId)}
                                  size="small"
                                  aria-label="settings"
                                  data-test="deleteButton"
                                >
                                  <DeleteOutline />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={t('EDIT_EQUIPMENT_TASK')}>
                                <IconButton
                                  className={classes.editButton}
                                  onClick={() => onEditOperations('DEFECTS', technicalObjectId)}
                                  size="small"
                                  aria-label="settings"
                                  data-test="editButton"
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            </div>
                          )}
                        </div>
                        {technicalObjectDefects.map(defect => (
                          <div className={classes.technicalObjectInfoText}>
                            {defect && defect.title}
                          </div>
                        ))}
                      </div>
                    )}
                    {!isEmpty(technicalObjectMeasuringPoints) && (
                      <div className={classes.operationType}>
                        <div className={classes.operationTypeHeader}>
                          <OverflowTipSimple
                            tooltipProps={{ placement: 'top' }}
                            tooltipTitle={t('MEASUREMENTS')}
                            text={t('MEASUREMENTS')}
                            textProps={{ className: classes.technicalObjectInfoTitle }}
                            data-test="measuringPointsHeader"
                          />
                          {isChangeable && !isHoverBlocked && (
                            <div className={classes.headerButtons}>
                              <Tooltip title={t('DELETE_EQUIPMENT_TASK')}>
                                <IconButton
                                  className={classes.deleteButton}
                                  onClick={() => onDeleteOperations('MEASURING_POINTS', technicalObjectId)}
                                  size="small"
                                  aria-label="settings"
                                  data-test="deleteButton"
                                >
                                  <DeleteOutline />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={t('EDIT_EQUIPMENT_TASK')}>
                                <IconButton
                                  className={classes.editButton}
                                  onClick={() => onEditOperations('MEASURING_POINTS', technicalObjectId)}
                                  size="small"
                                  aria-label="settings"
                                  data-test="editButton"
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            </div>
                          )}
                        </div>
                        {technicalObjectMeasuringPoints.map(measuring => (
                          <div className={classes.technicalObjectInfoText}>
                            {measuring && measuring.title}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

PlannerCheckpointItem.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  isChangeable: PropTypes.bool,
  onView: PropTypes.func,
  onDeleteCheckpoint: PropTypes.func,
  onDeleteTechnicalObject: PropTypes.func,
  onDeleteOperations: PropTypes.func,
  onUpdateCustomAction: PropTypes.func,
  onEditCheckpoint: PropTypes.func,
  onEditOperations: PropTypes.func,
  onEditTechnicalObject: PropTypes.func,
  disabledDrag: PropTypes.bool,
  isHoverBlocked: PropTypes.bool,
  technicalObjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  isReadonlyByMode: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool,
  toggleCollapseCheckpoint: PropTypes.func,
  withPaddingLeft: PropTypes.bool,
};

PlannerCheckpointItem.defaultProps = {
  label: {},
  technicalObjects: [],
  isChangeable: false,
  onView: () => {},
  onEditCheckpoint: () => {},
  onEditTechnicalObject: () => {},
  onEditOperations: () => {},
  disabledDrag: false,
  isHoverBlocked: false,
  onDeleteCheckpoint: () => {},
  onDeleteTechnicalObject: () => {},
  onDeleteOperations: () => {},
  onUpdateCustomAction: () => {},
  isExpanded: false,
  toggleCollapseCheckpoint: () => {},
  withPaddingLeft: false,
};

export default SortableElement(PlannerCheckpointItem);
