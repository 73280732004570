import Agent from 'ApiAgents/Agent';
import getPaginationQueryObject from 'Src/utils/getPaginationQueryObject';

export default class PlannerTaskAgent extends Agent {
  getPlannerTasks = (
    shouldPaginate = true,
    pageNumber,
    pageSize,
  ) => {
    const pageQuery = getPaginationQueryObject(shouldPaginate, pageNumber, pageSize);
    return (
      this.request({
        url: '/ScheduleTasks',
        method: 'GET',
        params: { ...pageQuery }
      },
      'tpsInspectionTask'));
  };

  getPlannerTaskById = id => this.request(
    {
      url: `/ScheduleTasks/${id}`,
      method: 'GET',
    },
    'tpsInspectionTask',
  );

  getShifts = () => this.request(
    {
      url: '/Shift',
      method: 'GET',
    },
    'tpsInspectionTask',
  );

  savePlannerTask = (body = {}) => this.request(
    {
      url: '/ScheduleTasks',
      method: 'POST',
      data: body,
    },
    'tpsInspectionTask',
  );

  editPlannerTask = (body = {}, plannerTaskId) => this.request(
    {
      url: `/ScheduleTasks/${plannerTaskId}`,
      method: 'PUT',
      data: body,
    },
    'tpsInspectionTask',
  );

  deletePlannerTask = plannerTaskId => this.request(
    {
      url: `/ScheduleTasks/${plannerTaskId}`,
      method: 'DELETE',
    },
    'tpsInspectionTask',
  );

  changeStatus = body => this.request(
    {
      url: '/ScheduleTasks/ChangeStatus',
      method: 'PUT',
      data: body,
    },
    'tpsInspectionTask',
  );

  getScheduleExecutionType = () => this.request(
    {
      url: '/v2/dictionaries?Keys=TpsInspectionScheduleExecutionType',
      method: 'GET',
    },
    'tasks',
  );

  getPauseDictionary = () => this.request(
    {
      url: '/ScheduleTasks/PauseReasons',
      method: 'GET',
    },
    'tpsInspectionTask',
  );

  getShiftDuration = () => this.request(
    {
      url: '/v2/dictionaries?Keys=TpsInspectionShiftDuration',
      method: 'GET',
    },
    'tasks',
  );
}
