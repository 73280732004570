import Agent from 'ApiAgents/Agent';
import qs from 'qs';
import getPaginationQueryObject from 'Src/utils/getPaginationQueryObject';

export default class WorkplacesAgent extends Agent {
  getWorkplaces = (
    shouldPaginate,
    pageNumber,
    pageSize
  ) => {
    const pageQuery = getPaginationQueryObject(shouldPaginate, pageNumber, pageSize);
    return (
      this.request({
        url: '/workplace',
        method: 'GET',
        params: pageQuery,
        paramsSerializer: params => qs.stringify(params)
      }, 'user')
    );
  };

  deleteWorkplace = id => (
    this.request({
      url: `/workplace/${id}`,
      method: 'DELETE',
    }, 'user')
  );

  getWorkplaceById = id => (
    this.request({
      url: `/workplace/${id}`,
      method: 'GET',
    }, 'user')
  );

  getWorkplaceTypes = () => (
    this.request({
      url: '/workplace/types',
      method: 'GET',
    }, 'user')
  );

  updateWorkplace = body => this.request({
    url: '/workplace',
    method: 'PUT',
    data: body,
  }, 'user');

  addWorkplace = body => this.request({
    url: '/workplace',
    method: 'POST',
    data: body,
  }, 'user');
}
