import React from 'react';
import * as routes from 'Shared/constants/routes';
import { Route, Redirect } from 'react-router-dom';
import PrivateRoute from 'Common/components/Authorization/PrivateRoute';
import {
  TPS_REPORT_CREATE,
  TPS_REPORT_EDIT,
  TPS_REPORT_REQUEST,
  TPS_REPORT_VIEW,
  INSPECTION_TASKS_VIEW,
  INSPECTION_TASK_VIEW,
  INSPECTION_TASK_CREATE,
  // INSPECTION_TASK_EDIT,
  MEASUREMENT_REPORT_LIST_VIEW,
  TASKS_VIEW,
  TASK_VIEW,
  TASK_ROUTES_VIEW,
  TASK_ROUTE_VIEW,
  TASK_ROUTE_CREATE,
  TASK_ROUTE_EDIT,
  CHECKPOINTS_VIEW,
  CHECKPOINT_VIEW,
  CHECKPOINT_CREATE,
  CHECKPOINT_EDIT,
  INSPECTION_REPORTS_VIEW,
  REPORT_INSPECTION_STATUSES_VIEW,
  REPORT_COMPLETED_INSPECTION_VIEW,
  REPORT_DETAILED_INSPECTION_VIEW,
  CHECKPOINTS_NEW_VIEW,
  CHECKPOINT_NEW_CREATE,
  CHECKPOINT_NEW_EDIT,
  PLANNER_TASK_CREATE,
  PLANNER_TASK_EDIT,
  PLANNER_TASK_VIEW,
  PLANNER_TASKS_LIST_VIEW,
} from 'Src/RBAC/businessPermissions';

import AutomatedTasksList from 'Business/tpsInspections/pages/AutomatedTasksList';
import AutomatedTaskDetails from 'Business/tpsInspections/pages/AutomatedTaskDetails';

import TaskRoutesList from 'Business/tpsInspections/pages/TaskRoutesList';
import TaskRoutesDetails from 'Business/tpsInspections/pages/TaskRouteDetails';

import CheckpointsList from 'Business/tpsInspections/pages/CheckpointsList';
import CheckpointsListNew from 'Business/tpsInspections/pages/CheckpointsListNew';
import CheckpointDetails from 'Business/tpsInspections/pages/CheckpointDetails';
import CheckpointDetailsNEW from 'Business/tpsInspections/pages/CheckpointDetailsNEW';
import RequestReport from 'Business/tpsInspections/pages/RequestReport';
import Report from 'Business/tpsInspections/pages/Report';
import SelectReport from 'Business/tpsInspections/pages/SelectReport';
import InspectionReport from 'Business/tpsInspections/pages/InspectionReport';
import PlannerTask from 'Business/tpsInspections/pages/PlannerTask';
import PlannerTasksList from 'Business/tpsInspections/pages/PlannerTasksList';

import TaskListPage from 'Business/tpsInspections/pages/TaskListPage';
import TaskPage from 'Business/tpsInspections/pages/TaskPage';
import MeasurementReportListPage from 'Business/tpsInspections/pages/MeasurementReportListPage';
import CommonRoutes from 'Src/routes/CommonRoutes';
import {
  COMPLETED_INSPECTION,
  DETAILED_INSPECTION_REPORT,
  INSPECTION_STATUSES
} from 'Shared/constants/inspectionReportTypes';

const TPSInspectionRoutes = () => (
  <CommonRoutes>
    <Route exact path={`${routes.HOME}`}>
      <Redirect to={routes.INSPECTIONTASKS} />
    </Route>

    {/* AutoTasks */}
    <PrivateRoute
      exact
      path={routes.CREATE_INSPECTIONTASK}
      component={AutomatedTaskDetails}
      permission={INSPECTION_TASK_CREATE}
    />
    {/*<PrivateRoute*/}
    {/*exact*/}
    {/*path={routes.EDIT_INSPECTIONTASK}*/}
    {/*component={AutomatedTaskDetails}*/}
    {/*permission={INSPECTION_TASK_EDIT}*/}
    {/*/>*/}
    <PrivateRoute
      exact
      path={routes.VIEW_INSPECTIONTASK}
      component={AutomatedTaskDetails}
      permission={INSPECTION_TASK_VIEW}
    />
    <PrivateRoute
      exact
      path={routes.INSPECTIONTASKS}
      component={AutomatedTasksList}
      permission={INSPECTION_TASKS_VIEW}
    />

    {/* Reports */}
    <PrivateRoute
      exact
      path={routes.MEASUREMENT_REPORTS}
      component={MeasurementReportListPage}
      permission={MEASUREMENT_REPORT_LIST_VIEW}
    />

    {/* Tasks created based on AutoTask */}
    <PrivateRoute
      exact
      path={`${routes.VIEW_TASK}/:id`}
      component={TaskPage}
      permission={TASK_VIEW}
    />
    <PrivateRoute path={routes.TASKS} component={TaskListPage} permission={TASKS_VIEW} />

    {/* TaskRoutes Маршруты */}
    <PrivateRoute
      exact
      path={routes.CREATE_TASK_ROUTE}
      component={TaskRoutesDetails}
      permission={TASK_ROUTE_CREATE}
    />
    <PrivateRoute
      exact
      path={routes.EDIT_TASK_ROUTE}
      component={TaskRoutesDetails}
      permission={TASK_ROUTE_EDIT}
    />
    <PrivateRoute
      exact
      path={routes.VIEW_TASK_ROUTE}
      component={TaskRoutesDetails}
      permission={TASK_ROUTE_VIEW}
    />
    <PrivateRoute
      exact
      path={routes.TASK_ROUTES}
      component={TaskRoutesList}
      permission={TASK_ROUTES_VIEW}
    />

    {/* Checkpoints Метки */}
    <PrivateRoute
      exact
      path={routes.CREATE_CHECKPOINT}
      component={CheckpointDetails}
      permission={CHECKPOINT_CREATE}
    />
    <PrivateRoute
      exact
      path={routes.EDIT_CHECKPOINT}
      component={CheckpointDetails}
      permission={CHECKPOINT_EDIT}
    />
    <PrivateRoute
      exact
      path={routes.VIEW_CHECKPOINT}
      component={CheckpointDetails}
      permission={CHECKPOINT_VIEW}
    />
    <PrivateRoute
      exact
      path={routes.CHECKPOINTS}
      component={CheckpointsList}
      permission={CHECKPOINTS_VIEW}
    />

    {/* Checkpoints NEW Метки */}
    <PrivateRoute
      exact
      component={props => <CheckpointDetailsNEW {...props} mode="create" />}
      path={routes.CREATE_CHECKPOINT_NEW}
      permission={CHECKPOINT_NEW_CREATE}
    />
    <PrivateRoute
      exact
      path={routes.EDIT_CHECKPOINT_NEW}
      component={props => <CheckpointDetailsNEW {...props} mode="edit" />}
      permission={CHECKPOINT_NEW_EDIT}
    />
    <PrivateRoute
      exact
      path={routes.CHECKPOINTS_NEW}
      component={CheckpointsListNew}
      permission={CHECKPOINTS_NEW_VIEW}
    />

    <PrivateRoute
      exact
      path={routes.CREATE_PLANNER_TASK}
      component={props => <PlannerTask {...props} mode="create" />}
      permission={PLANNER_TASK_CREATE}
    />
    <PrivateRoute
      exact
      path={`${routes.EDIT_PLANNER_TASK}/:id`}
      component={props => <PlannerTask {...props} mode="edit" />}
      permission={PLANNER_TASK_EDIT}
    />
    <PrivateRoute
      exact
      path={`${routes.VIEW_PLANNER_TASK}/:id`}
      component={props => <PlannerTask {...props} mode="view" />}
      permission={PLANNER_TASK_VIEW}
    />
    <PrivateRoute
      exact
      path={routes.PLANNER_TASKS}
      component={PlannerTasksList}
      permission={PLANNER_TASKS_LIST_VIEW}
    />

    {/* Checkpoints Рапортичка */}
    <PrivateRoute
      exact
      path={routes.REQUEST_REPORT}
      component={RequestReport}
      permission={TPS_REPORT_REQUEST}
    />
    <PrivateRoute
      path={`${routes.VIEW_REPORT}/:id`}
      component={props => <Report {...props} mode="view" />}
      permission={TPS_REPORT_VIEW}
    />
    <PrivateRoute
      path={`${routes.EDIT_REPORT}/:id`}
      component={props => <Report {...props} mode="edit" />}
      permission={TPS_REPORT_EDIT}
    />
    <PrivateRoute
      path={routes.CREATE_REPORT}
      component={props => <Report {...props} mode="create" />}
      permission={TPS_REPORT_CREATE}
    />

    {/* REPORTS */}
    <PrivateRoute
      exact
      path={routes.REPORT_INSPECTION_STATUSES}
      component={props => <InspectionReport {...props} reportType={INSPECTION_STATUSES} />}
      permission={REPORT_INSPECTION_STATUSES_VIEW}
    />

    <PrivateRoute
      exact
      path={routes.REPORT_COMPLETED_INSPECTION}
      component={props => <InspectionReport {...props} reportType={COMPLETED_INSPECTION} />}
      permission={REPORT_COMPLETED_INSPECTION_VIEW}
    />

    <PrivateRoute
      exact
      path={routes.REPORT_DETAILED_INSPECTION}
      component={props => <InspectionReport {...props} reportType={DETAILED_INSPECTION_REPORT} />}
      permission={REPORT_DETAILED_INSPECTION_VIEW}
    />

    <PrivateRoute
      exact
      path={routes.REPORTS}
      component={SelectReport}
      permission={INSPECTION_REPORTS_VIEW}
    />
  </CommonRoutes>
);

export default TPSInspectionRoutes;
