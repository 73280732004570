import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import Modal from 'Common/components/Modal/Modal';
import TextInput from 'Common/components/Forms/Input/Text';
import ActionBtn from 'Common/components/Forms/Button/components/Action/Action';

const INPUT_MAX_LENGTH = 100;
const PositionModal = ({
  isOpen,
  setPositionModalState,
  title,
  handleSavePosition,
  mode,
  position,
}) => {
  const { t } = useTranslation();
  const [positionName, setPositionName] = useState('');
  const [positionCode, setPositionCode] = useState('');

  const handleSave = () => {
    handleSavePosition({
      // the id should be unique and used for dnd
      id: (position && position.id) || positionCode,
      name: positionName,
      code: positionCode,
    });
    setPositionModalState(false);
  };

  const clearForm = () => {
    setPositionName('');
    setPositionCode('');
  };

  const isDisabledSave = positionName === '' || positionCode === '';

  const renderActions = () => (
    <Grid container spacing={3} justify="flex-end">
      <Grid item>
        <ActionBtn
          text={t('CANCEL')}
          type="cancel"
          handleClick={() => setPositionModalState(false)}
        />
      </Grid>
      <Grid item>
        <ActionBtn
          text={t('SAVE')}
          type="confirm"
          disabled={isDisabledSave}
          handleClick={handleSave}
        />
      </Grid>
    </Grid>
  );

  useEffect(() => {
    if (mode !== 'edit') return;

    setPositionName(position.name);
    setPositionCode(position.code);
  }, [position]);

  return (
    <Modal
      onClick={e => e.stopPropagation()}
      title={title}
      isOpened={isOpen}
      onExited={clearForm}
      actions={renderActions()}
    >
      <>
        <TextInput
          label={t('POSITION_NAME')}
          name="positionName"
          onChange={val => setPositionName(val)}
          value={positionName}
          inputProps={{ maxLength: INPUT_MAX_LENGTH }}
          data-test="positionName"
        />
        <TextInput
          label={t('POSITION_CODE')}
          name="positionCode"
          onChange={val => setPositionCode(val)}
          value={positionCode}
          inputProps={{ maxLength: INPUT_MAX_LENGTH }}
          data-test="positionCode"
        />
      </>
    </Modal>
  );
};

PositionModal.propTypes = {
  mode: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  position: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
  }),
  setPositionModalState: PropTypes.func.isRequired,
  handleSavePosition: PropTypes.func.isRequired,
};

PositionModal.defaultProps = {
  position: null,
};

export default PositionModal;
