import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  block: {
    padding: 24,
    marginBottom: 16,
  },
  blockHeader: {
    fontSize: 20,
    lineHeight: 2.4,
    fontWeight: 'bold',
    color: theme.palette.black,
    marginBottom: 16,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  item: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 16,
    width: 'calc((100% - 24px) / 2)',
    boxShadow: `0 2px 4px 0 ${theme.palette.black20}`,
    border: `1px solid ${theme.palette.midGray}`,
    borderRadius: 4,
    '&:hover': {
      boxShadow: `0 4px 8px 0 ${theme.palette.black20}`,
      '& $iconWrapper': {
        backgroundColor: theme.palette.dtekYellow,
      },
      '& $itemText': {
        textDecoration: 'underline',
      },
    }
  },
  itemText: {
    fontSize: 16,
    lineHeight: 3.0,
    fontWeight: 'bold',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 48,
    width: 48,
    minWidth: 48,
    borderRadius: '50%',
    backgroundColor: theme.palette.midGray,
    marginRight: 16,
  },
}));

export default useStyles;
