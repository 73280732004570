import {
  action,
  runInAction,
  makeObservable,
  computed,
  toJS, // eslint-disable-line
} from 'mobx';
import {
  isEmpty, assign, get, set, values, head, startsWith, find, last, cloneDeep, omit
} from 'lodash';
import { formatToISOString, formatDate } from 'Src/utils/datetime';
import { DEFECTS, MEASURING_POINTS, operationTypeTitles } from 'Business/tpsInspections/config/operationTypeMapping';
import PlannerTaskAgent from 'ApiAgents/Planner/PlannerTaskAgent';
import CheckpointAgent from 'ApiAgents/Checkpoints/CheckpointAgentNEW';
import EquipmentAgent from 'ApiAgents/Equipment/EquipmentAgent';
import DefectsAgent from 'ApiAgents/Defects/DefectsAgent';

import {
  POWER_UNIT, TAGS, TECHNICAL_PLACE, OPERATION, ACTION, CUSTOM_ACTION
} from 'Business/tpsInspections/config/sectionTypeMapping';
import { CODE_DATE_FORMAT } from 'Shared/constants/datetime';

const plannerTaskAgent = new PlannerTaskAgent();
const checkpointAgent = new CheckpointAgent();
const equipmentAgent = new EquipmentAgent();
const defectsAgent = new DefectsAgent();

const initialState = {
  plannerTask: {
    tasksCount: '',
    executionType: 'Indefinite',
    isShiftAcceptance: false,
    shiftDurationType: 'Eight',
    checkpoints: [],
    shiftIds: ['all'],
    plannedDurationMinutes: 30,
    isHightPriority: false,
  },
  isLoading: false,
  isSaving: false,
  isDeleting: false,
  shiftsDictionary: [],
  shiftDurationDictionary: [],
  scheduleExecutionTypeDictionary: [],
  isLoadingPowerUnits: false,
  isLoadedPowerUnits: false,
  powerUnits: [],
  structureDictionary: {},
  visibleSections: [],
  measurementPoints: [],
  defects: [],
  isLoadingOperations: false,
  isLoadedOperations: false,
  history: [],
  activeActions: [],
  firstSectionItems: [],
  isEditing: false,
  pauseDictionary: [],
  isUpdatingReadyStatus: false,
  isUpdatingOnPauseStatus: false,
  areTechObjectChildrenLoading: false,
};

export class PlannerTaskStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
    this.rootStore.prepareState(initialState, this);
  }

  isLoading;

  isSaving;

  isDeleting;

  plannerTask;

  shiftsDictionary;

  shiftDurationDictionary;

  scheduleExecutionTypeDictionary;

  isLoadingPowerUnits;

  isLoadedPowerUnits;

  powerUnits;

  structureDictionary;

  visibleSections;

  isLoadingOperations;

  isLoadedOperations;

  defects;

  measurementPoints;

  history;

  activeActions;

  firstSectionItems;

  isEditing;

  pauseDictionary;

  isUpdatingReadyStatus

  isUpdatingOnPauseStatus

  areTechObjectChildrenLoading

  @computed get isDictionaryLoaded() {
    return (
      !isEmpty(this.shiftsDictionary)
      && !isEmpty(this.shiftDurationDictionary)
      && !isEmpty(this.scheduleExecutionTypeDictionary)
    );
  }

  @computed get allCheckpointsAreExpanded() {
    const { checkpoints = [] } = this.plannerTask;
    return checkpoints.every(({ isExpanded } = {}) => isExpanded);
  }

  @computed get shiftOptions() {
    const { shiftDurationType } = this.plannerTask;
    return this.shiftsDictionary.filter(shift => shift.shiftDurationType === shiftDurationType).map(shift => ({ value: shift.id, title: shift.title }));
  }

  @action loadPlannerTask = async (id) => {
    this.isLoading = true;
    try {
      const {
        startDate,
        finishDate,
        shiftIds = [],
        ...restPlannerTaskOptions
      } = await plannerTaskAgent.getPlannerTaskById(id) || {};
      runInAction(() => {
        this.plannerTask = {
          ...restPlannerTaskOptions,
          startDate: formatDate(startDate, CODE_DATE_FORMAT),
          ...finishDate && { finishDate: formatDate(finishDate, CODE_DATE_FORMAT) },
          shiftIds: shiftIds.length === 1 ? shiftIds : ['all']
        };
        this.isLoading = false;
      });
    } catch (error) {
      console.log('ERROR IN FETCHING PLANNER TASK: ', error);
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action savePlannerTask = async (mode, plannerTaskId) => {
    this.isSaving = true;
    try {
      const {
        startDate,
        finishDate,
        checkpoints,
        role: { name: roleName } = {},
        shiftIds,
        isOrderRequired,
        executionType,
        isHightPriority,
        isShiftAcceptance,
        ...restTaskOptions
      } = this.plannerTask;

      const requestData = {
        ...restTaskOptions,
        ...startDate && { startDate: formatToISOString(startDate) },
        finishDate: finishDate ? formatToISOString(finishDate) : null,
        checkpoints: checkpoints.map(({
          label: { id: labelId } = {},
          powerUnit: { id: powerUnitId } = {},
          technicalObjects,
          ...restCheckpointOptions
        }) => ({
          labelId,
          powerUnitId,
          ...restCheckpointOptions,
          technicalObjects: technicalObjects.map(({
            id,
            customAction,
            defects = [],
            measuringPoints = []
          }) => ({
            id,
            customAction,
            defectIds: defects.map(({ id: defectId }) => defectId),
            measuringPointIds: measuringPoints.map(({ id: measuringPointId }) => measuringPointId)
          }))
        })),
        roleName,
        executionType,
        shiftIds: head(shiftIds) === 'all' ? this.shiftOptions.slice(1).map(({ value } = {}) => value) : shiftIds,
        isOrderRequired: checkpoints.length < 2 ? false : isOrderRequired,
        isHightPriority: executionType === 'OneTime' ? isHightPriority : false,
        isShiftAcceptance: executionType !== 'OneTime' ? isShiftAcceptance : false,
      };
      let saveFunction;
      if (mode === 'create') {
        saveFunction = plannerTaskAgent.savePlannerTask;
      } else if (mode === 'edit') saveFunction = plannerTaskAgent.editPlannerTask;
      const { error, id } = await saveFunction(requestData, plannerTaskId);
      runInAction(() => {
        this.isSaving = false;
      });
      if (error) return { error };
      return ({ result: { id } });
    } catch (error) {
      console.log('ERROR IN SAVING PLANNER TASK: ', error);
      runInAction(() => {
        this.isSaving = false;
      });
      return { error };
    }
  };

  @action loadShiftsDictionary = async () => {
    try {
      const result = await plannerTaskAgent.getShifts() || [];
      runInAction(() => {
        this.shiftsDictionary = result;
      });
    } catch (error) {
      console.log('ERROR IN FETCHING SHIFTS: ', error);
    }
  };

  @action loadPauseDictionary = async () => {
    try {
      const result = await plannerTaskAgent.getPauseDictionary() || [];
      runInAction(() => {
        this.pauseDictionary = result;
      });
    } catch (error) {
      console.log('ERROR IN FETCHING PAUSE DICTIONARY: ', error);
    }
  };

  @action loadShiftDurationDictionary = async () => {
    try {
      const { items } = (await plannerTaskAgent.getShiftDuration()) || {};
      runInAction(() => {
        this.shiftDurationDictionary = items;
      });
    } catch (error) {
      console.log('ERROR IN FETCHING SHIFT DURATION DICTIONARY: ', error);
    }
  };

  @action loadScheduleExecutionTypeDictionary = async () => {
    try {
      const { items } = (await plannerTaskAgent.getScheduleExecutionType()) || {};
      runInAction(() => {
        this.scheduleExecutionTypeDictionary = items;
      });
    } catch (error) {
      console.log('ERROR IN FETCHING Schedule Execution Types: ', error);
    }
  };

  @action updateField = (path, value) => {
    this.plannerTask = set(this.plannerTask, path, value);
  };

  @action discardState = () => {
    this.rootStore.setInitialState(initialState, this);
  };

  @action loadPowerUnits = async () => {
    this.isLoadingPowerUnits = true;
    this.areTechObjectChildrenLoading = true;
    const { code: rootOrgUnitCode } = this.rootStore.userStore.rootOrgUnit;

    try {
      const TECH_OBJECTS_LEVELS_TO_LOAD = 2;
      const [{ children: powerUnits } = {}] = await equipmentAgent.getTechObjectsTrees(
        rootOrgUnitCode,
        TECH_OBJECTS_LEVELS_TO_LOAD,
      );

      runInAction(() => {
        this.powerUnits = powerUnits.map(({ technicalObjectId, ...rest }) => ({
          id: technicalObjectId,
          ...rest,
        }));
        this.isLoadingPowerUnits = false;
        this.isLoadedPowerUnits = true;
        this.areTechObjectChildrenLoading = false;
      });
    } catch (error) {
      console.log('ERROR IN FETCHING POWER UNITS: ', error);
      runInAction(() => {
        this.isLoadingPowerUnits = false;
        this.areTechObjectChildrenLoading = false;
      });
    }
  };

  @action addPowerUnitsToStructureDictionary = () => {
    const structureDictionary = {};
    this.powerUnits.forEach((powerUnit = {}) => {
      const powerUnitId = get(powerUnit, 'id');
      if (powerUnitId) {
        assign(structureDictionary, {
          [powerUnitId.toString()]: {
            ...powerUnit,
            isRoot: true,
            branchIds: [powerUnitId.toString()],
            branchIdsString: powerUnitId.toString(),
            childrenObj: {},
            type: POWER_UNIT,
          },
        });
      }
    });
    this.structureDictionary = structureDictionary;
  };

  @action onPowerUnitClick = async (powerUnit) => {
    try {
      this.areTechObjectChildrenLoading = true;
      const powerUnitId = get(powerUnit, 'id');
      const powerUnitSapCode = get(powerUnit, 'sapCode');
      const { items: checkpoints } = await checkpointAgent.getCheckpoints({
        technicalObjectId: powerUnitId,
      });
      const TECH_OBJECTS_LEVELS_TO_LOAD = 2;
      const [{ children: powerUnitsChildren = [] } = {}] = await this.rootStore.equipmentStore.loadTechObjectsTrees(
        powerUnitSapCode,
        TECH_OBJECTS_LEVELS_TO_LOAD,
      );
      const getPowerUnitsChildrenObj = (checkpointBranchIds) => {
        const powerUnitsChildrenObj = {};
        powerUnitsChildren.forEach(
          ({ technicalObjectId, ...rest } = {}) => assign(
            powerUnitsChildrenObj,
            {
              [technicalObjectId]:
                {
                  technicalObjectId,
                  ...rest,
                  branchIds: [...checkpointBranchIds, technicalObjectId.toString()],
                  type: TECHNICAL_PLACE
                }
            }
          )
        );
        return powerUnitsChildrenObj;
      };

      const powerUnitsChildrenObjPath = `${powerUnitId}.childrenObj`;
      const powerUnitsPath = powerUnitId.toString();
      checkpoints.forEach((checkpoint) => {
        const checkpointId = get(checkpoint, 'id');
        const checkpointBranchIds = [powerUnitId.toString(), checkpointId.toString()];
        runInAction(() => {
          this.structureDictionary = set(
            this.structureDictionary,
            powerUnitsChildrenObjPath,
            {
              ...get(this.structureDictionary, powerUnitsChildrenObjPath, {}),
              [checkpointId]: {
                ...checkpoint,
                type: TAGS,
                isCheckpoint: true,
                childrenObj: getPowerUnitsChildrenObj(checkpointBranchIds),
                branchIds: checkpointBranchIds,
                childrenLoaded: true,
              },
            },
          );
          this.structureDictionary = set(
            this.structureDictionary,
            powerUnitsPath,
            {
              ...get(this.structureDictionary, powerUnitsPath, {}),
              childrenLoaded: true,
            },
          );
        });
      });
      runInAction(() => {
        this.areTechObjectChildrenLoading = false;
      });
    } catch (error) {
      console.log('ERROR IN FETCHING POWER UNIT TECH OBJECTS: ', error);
    }
  };

  @action lazyGetOperationsAndDefects = async (techObjectId) => {
    this.isLoadingOperations = true;
    try {
      let measuringPoints = [];
      let defects = [];

      if (this.measurementPoints.length === 0) {
        measuringPoints = await equipmentAgent.getMeasurementPoints();
      }

      if (this.defects.length === 0) {
        defects = await defectsAgent.getDefects();
      }

      runInAction(() => {
        this.selectedTechObjectId = techObjectId;

        if (!defects.length && !measuringPoints.length) return;
        if (this.measurementPoints.length === 0) {
          this.measurementPoints = measuringPoints.map(({ measurementPointId, title }) => ({ id: measurementPointId, title }));
        }
        if (this.defects.length === 0) {
          this.defects = defects.map(({ defectId, shortText }) => ({ id: defectId, title: shortText }));
        }
        this.isLoadingOperations = false;
        this.isLoadedOperations = true;
      });
    } catch (error) {
      console.log('ERROR in LOAD DEFECTS BY TECHOBJECT ID: ', error);
    }

    runInAction(() => {
      this.isLoadingOperations = false;
    });
  };

  @action onTechObjectClick = async (clickedItem) => {
    try {
      this.areTechObjectChildrenLoading = true;
      const sapCode = get(clickedItem, 'sapCode');
      const branchIds = get(clickedItem, 'branchIds', []);
      const TECH_OBJECTS_LEVELS_TO_LOAD = 2;
      const [{ children: tmChildren = [] } = {}] = await this.rootStore.equipmentStore.loadTechObjectsTrees(
        sapCode,
        TECH_OBJECTS_LEVELS_TO_LOAD,
      );
      const [firstTmChildren] = tmChildren;
      const technicalObjectType = get(firstTmChildren, 'technicalObjectType', '');

      const pathToTechObj = branchIds.join('.childrenObj.');
      this.structureDictionary = set(
        this.structureDictionary,
        pathToTechObj,
        {
          ...get(this.structureDictionary, pathToTechObj, {}),
          childrenLoaded: true,
        }
      );

      const pathToChildrenObj = `${branchIds.join('.childrenObj.')}.childrenObj`;
      tmChildren.forEach((technicalObject) => {
        const technicalObjectId = get(technicalObject, 'technicalObjectId', '');

        this.structureDictionary = set(
          this.structureDictionary,
          pathToChildrenObj,
          {
            ...get(this.structureDictionary, pathToChildrenObj, {}),
            [technicalObjectId]: {
              ...technicalObject,
              type: technicalObjectType,
              childrenObj: {},
              branchIds: [...branchIds, technicalObjectId.toString()],
              childrenLoaded: false,
            },
          },
        );
      });
      runInAction(() => {
        this.areTechObjectChildrenLoading = false;
      });
    } catch (error) {
      console.log('ERROR iN FETCHING TECH OBJECT CHILDREN: ', error);
    }
  };

  @action handleListItemClick = async (clickedItem = {}) => {
    this.updateHistory(clickedItem);
    const {
      id, branchIds = [], isRoot = false, isCheckpoint = false
    } = clickedItem;

    if (this.visibleSections.length > 0) {
      this.visibleSections = this.visibleSections.filter((item = {}) => {
        const { branchIds: itemBranchIds = [] } = item;
        return itemBranchIds.length <= branchIds.length - 1;
      });
    }
    const childrenLoaded = get(this.structureDictionary, `${branchIds.join('.childrenObj.')}.childrenLoaded`, false);

    if (isRoot) {
      if (!childrenLoaded) await this.onPowerUnitClick(clickedItem); // TODO
      const childrenObj = get(this.structureDictionary, `${branchIds.join('.childrenObj.')}.childrenObj`, {});
      this.addToVisibleSections(
        {
          branchIds: [id.toString()],
          title: '',
          type: TAGS,
          items: values(childrenObj),
        }
      );
    } else if (isCheckpoint) {
      const childrenObj = get(this.structureDictionary, `${branchIds.join('.childrenObj.')}.childrenObj`, {});
      this.addToVisibleSections(
        {
          branchIds,
          title: 'test',
          type: TECHNICAL_PLACE,
          items: values(childrenObj)
        }
      );
    } else {
      if (!childrenLoaded) {
        await this.onTechObjectClick(clickedItem);
      }
      const childrenObj = get(this.structureDictionary, `${branchIds.join('.childrenObj.')}.childrenObj`, {});

      const items = values(childrenObj);
      if (isEmpty(items)) {
        await this.lazyGetOperationsAndDefects(id);
        this.addToVisibleSections(
          {
            branchIds,
            title: '',
            type: OPERATION,
            items: [
              {
                title: operationTypeTitles[MEASURING_POINTS],
                operationType: MEASURING_POINTS,
                branchIds: [...branchIds, 'measuringPoints']
              },
              {
                title: operationTypeTitles[DEFECTS],
                operationType: DEFECTS,
                branchIds: [...branchIds, 'defects']
              }
            ],
          }
        );
        return;
      }
      const technicalObjectType = get(head(items), 'technicalObjectType');
      this.addToVisibleSections({
        branchIds,
        title: '',
        type: technicalObjectType,
        items,
      });
    }
  };

  @action setSelectedCheckpoints = (checkpoints) => {
    if (!Array.isArray(checkpoints)) {
      throw new TypeError("Argument provided to 'setSelectedCheckpoints()' is not array");
    }
    this.plannerTask.checkpoints = checkpoints;
  };

  @action onOperationTypeClick = ({ operationType, branchIds }) => {
    const isOperationTypeItemsPresented = this.visibleSections.some(({ operationType: visibleSectionOperationType }) => visibleSectionOperationType === operationType);
    if (isOperationTypeItemsPresented) return;
    this.visibleSections = this.visibleSections.filter(({ operationType: visibleSectionOperationType }) => !visibleSectionOperationType || visibleSectionOperationType === operationType);
    if (operationType === MEASURING_POINTS) {
      this.addToVisibleSections({
        type: ACTION,
        branchIds,
        items: this.measurementPoints.map(({ id, ...rest }) => ({
          id,
          ...rest,
          branchIds: [...branchIds, id.toString()]
        })),
        operationType
      });
    }
    if (operationType === DEFECTS) {
      this.addToVisibleSections({
        type: ACTION,
        branchIds,
        items: this.defects.map(({ id, ...rest }) => ({
          id,
          ...rest,
          branchIds: [...branchIds, id.toString()]
        })),
        operationType
      });
    }
  };

  @action addToVisibleSections = (section) => {
    if (this.visibleSections.length === 2) {
      this.visibleSections = this.visibleSections.slice(1);
    }
    const itemType = get(section, 'type');
    const indexByType = this.history.filter(({ type } = {}) => type === itemType).length + 1;
    this.visibleSections = [...this.visibleSections, { ...section, indexByType }];
  };

  @action updateHistory = (clickedItem) => {
    const branchIds = get(clickedItem, 'branchIds', []);
    const treeDepth = branchIds.length;
    this.history = this.history.slice(0, treeDepth - 1);
    const itemType = get(clickedItem, 'type');
    const indexByType = this.history.filter(({ type } = {}) => type === itemType).length + 1;
    this.history.push({ ...clickedItem, indexByType });
  };

  @action handleToggleAction = ({ branchIds }) => {
    const branchIdsString = branchIds.join('.');
    const isInActiveActions = this.activeActions.includes(branchIdsString);
    if (isInActiveActions) {
      this.activeActions = this.activeActions.filter(activeAction => activeAction !== branchIdsString);
    } else {
      this.activeActions.push(branchIdsString);
    }
  };

  @action removeActiveActions = (branchIds) => {
    this.activeActions = this.activeActions.filter(activeAction => !startsWith(activeAction, branchIds.join('.')));
  };

  @action handleHistoryClick = async (historyItem) => {
    const { branchIds: historyItemBranchIds, isRoot, isCheckpoint } = historyItem;
    if (isRoot) {
      this.history = [];
      await this.handleListItemClick(historyItem);
    } else if (isCheckpoint) {
      const [blockHistoryItem] = this.history;
      this.history = [];
      this.visibleSections = [];
      await this.handleListItemClick(blockHistoryItem);
      await this.handleListItemClick(historyItem);
    } else {
      this.history = this.history.filter(({ branchIds }) => branchIds.length < historyItemBranchIds.length);
      this.visibleSections = [];
      await this.handleListItemClick(historyItem);
    }
  };

  @action setConfirmedOperations = async () => {
    const checkpoints = [];

    for await (const activeAction of this.activeActions) { // eslint-disable-line
      const actionBranchIds = activeAction.split('.');
      const [actionPowerUnitId, actionCheckpointId, ...rest] = actionBranchIds;
      const [technicalObjectId, actionOperationType, actionId] = rest.slice(-3);

      const pathToTechObject = actionBranchIds.slice(0, actionBranchIds.length - 2).join('.childrenObj.');
      let technicalObject = get(this.structureDictionary, pathToTechObject);
      if (!technicalObject) {
        const checkpointFromPlannerTask = this.plannerTask.checkpoints.find(({ label: { id = '' } = {} } = {}) => id.toString() === actionCheckpointId.toString());
        technicalObject = checkpointFromPlannerTask.technicalObjects.find(({ id = '' } = {}) => id.toString() === technicalObjectId.toString());
      }

      const technicalObjectTitle = get(technicalObject, 'title');
      const technicalObjectSapCode = get(technicalObject, 'sapCode');
      const technicalObjectIsDeleted = get(technicalObject, 'isDeleted');

      let operationAction;
      await this.lazyGetOperationsAndDefects();
      if (actionOperationType === 'defects') {
        operationAction = find(this.defects, ({ id }) => id.toString() === actionId);
      } else if (actionOperationType === 'measuringPoints') {
        operationAction = find(this.measurementPoints, ({ id }) => id.toString() === actionId);
      }
      const operationActionId = get(operationAction, 'id');
      const operationActionTitle = get(operationAction, 'title');
      const operationActionIsDeleted = get(operationAction, 'isDeleted');

      const operation = {
        id: operationActionId,
        title: operationActionTitle,
        isDeleted: operationActionIsDeleted,
      };

      const object = {
        id: technicalObjectId,
        title: technicalObjectTitle,
        sapCode: technicalObjectSapCode,
        isDeleted: technicalObjectIsDeleted,
        ...(actionOperationType === 'defects' || actionOperationType === 'measuringPoints') && { [actionOperationType]: [operation] },
        ...actionOperationType === 'customAction' && { customAction: actionId }
      };

      const storedInCheckpoints = find(checkpoints, ({ label: { id = '' } = {} } = {}) => id.toString() === actionCheckpointId);
      if (!storedInCheckpoints) {
        const powerUnit = this.structureDictionary[actionPowerUnitId];
        const powerUnitId = get(powerUnit, 'id');
        const powerUnitTitle = get(powerUnit, 'title');
        const powerUnitIsDeleted = get(powerUnit, 'isDeleted');

        const checkpoint = this.structureDictionary[actionPowerUnitId].childrenObj[actionCheckpointId] || this.plannerTask.checkpoints.find(({ label: { id } = {} } = {}) => id.toString() === actionCheckpointId);
        const checkpointTitle = get(checkpoint, 'title');
        const checkpointId = get(checkpoint, 'id') || get(checkpoint, 'label.id');
        const checkpointIsDeleted = get(checkpoint, 'isDeleted');

        checkpoints.push({
          title: checkpointTitle,
          order: 1,
          label: {
            id: checkpointId,
            isDeleted: checkpointIsDeleted,
          },
          powerUnit: {
            id: powerUnitId,
            title: powerUnitTitle,
            isDeleted: powerUnitIsDeleted,
          },
          technicalObjects: [object]
        });
      } else {
        const storedInTechnicalObjects = find(storedInCheckpoints.technicalObjects, ({ id } = {}) => id.toString() === technicalObjectId);
        if (!storedInTechnicalObjects) {
          storedInCheckpoints.technicalObjects = [
            ...storedInCheckpoints.technicalObjects,
            object
          ];
        } else {
          switch (actionOperationType) {
            case 'customAction': {
              storedInTechnicalObjects.customAction = actionId;
              break;
            }
            default: {
              const hasOperationTypeKey = get(storedInTechnicalObjects, actionOperationType);
              if (!hasOperationTypeKey) {
                storedInTechnicalObjects[actionOperationType] = [operation];
              } else {
                storedInTechnicalObjects[actionOperationType] = [...storedInTechnicalObjects[actionOperationType], operation];
              }
            }
          }
        }
      }
    }
    const sortedCheckpoints = [...new Array(this.plannerTask.checkpoints.length)];
    const unsortedCheckpoints = [];
    checkpoints.forEach((checkpoint) => {
      const { label: { id } = {} } = checkpoint;
      const indexInPlannerTaskCheckpoints = this.plannerTask.checkpoints.findIndex(({ label: { id: checkpointId } = {} } = {}) => checkpointId === id);
      if (indexInPlannerTaskCheckpoints < 0) {
        unsortedCheckpoints.push(checkpoint);
      } else {
        sortedCheckpoints[indexInPlannerTaskCheckpoints] = checkpoint;
      }
    });
    this.plannerTask = { ...this.plannerTask, checkpoints: [...sortedCheckpoints.filter(it => it), ...unsortedCheckpoints] };
    if (this.plannerTask.isOrderRequired && this.plannerTask.checkpoints.length < 2) {
      this.plannerTask.isOrderRequired = false;
    }
  };

  @action collectActiveActions = async () => {
    this.activeActions = [];
    const checkpoints = get(this.plannerTask, 'checkpoints', []);
    for (const checkpoint of checkpoints) { // eslint-disable-line
      const technicalObjects = get(checkpoint, 'technicalObjects', []);
      const checkpointId = get(checkpoint, 'label.id');
      for await (const technicalObject of technicalObjects) { // eslint-disable-line
        const technicalObjectsId = get(technicalObject, 'id');
        const defects = get(technicalObject, 'defects', []);
        const measuringPoints = get(technicalObject, 'measuringPoints', []);
        const customAction = get(technicalObject, 'customAction', '');
        const technicalObjectTree = await equipmentAgent.getTechObjectParents(technicalObjectsId) || [];
        const [powerUnitObj, ...rest] = technicalObjectTree.slice(1);
        const technicalObjectBranchIds = [powerUnitObj, { id: checkpointId }, ...rest].map(({ id } = {}) => id.toString());
        defects.forEach(({ id }) => {
          this.activeActions = [...this.activeActions, [...technicalObjectBranchIds, 'defects', id.toString()].join('.')];
        });
        measuringPoints.forEach(({ id }) => {
          this.activeActions = [...this.activeActions, [...technicalObjectBranchIds, 'measuringPoints', id.toString()].join('.')];
        });
        if (customAction) this.activeActions = [...this.activeActions, [...technicalObjectBranchIds, 'customAction', customAction].join('.')];
      }
    }
  };

  @action prepareForAdding = async () => {
    this.isEditing = false;
    this.visibleSections = [];
    this.history = [];
    this.firstSectionItems = values(this.structureDictionary);
    await this.collectActiveActions();
  };

  @action onCheckpointEdit = async (checkpoint) => {
    this.firstSectionItems = [];
    this.visibleSections = [];
    await this.collectActiveActions();
    const checkpointId = get(checkpoint, 'label.id');
    const powerUnitId = get(checkpoint, 'powerUnit.id');
    const powerUnit = get(this.structureDictionary, powerUnitId);
    await this.onPowerUnitClick(powerUnit);
    const checkpointFromStructureDictionary = get(this.structureDictionary, `${powerUnitId}.childrenObj.${checkpointId}`);
    this.firstSectionItems = [checkpointFromStructureDictionary];
  };

  @action onTechnicalObjectEdit = async ({ technicalObjectId }) => {
    this.isEditing = true;
    this.history = [];
    this.firstSectionItems = [];
    this.visibleSections = [];
    await this.collectActiveActions();
    const currentActiveAction = this.activeActions.find((activeAction) => {
      const treeArray = activeAction.split('.');
      return last(treeArray.slice(0, treeArray.length - 2)).toString() === technicalObjectId.toString();
    });
    const currentActiveActionTreeArray = currentActiveAction.split('.');
    const technicalObjectTreeArray = currentActiveActionTreeArray.slice(0, currentActiveActionTreeArray.length - 2);
    const temp = [];
    for await (const treeItem of technicalObjectTreeArray) { // eslint-disable-line
      temp.push(treeItem);
      const path = temp.join('.childrenObj.');
      const structureDictionaryItem = get(this.structureDictionary, path);
      await this.handleListItemClick(structureDictionaryItem);
    }
  };

  @action onEditOperations = (type) => {
    this.isEditing = true;
    const operationsSection = this.visibleSections.find(({ type: sectionType }) => sectionType === 'OPERATION');
    const operationsSectionIndex = this.visibleSections.findIndex(({ type: sectionType }) => sectionType === 'OPERATION');
    const clonedVisibleSections = cloneDeep(this.visibleSections);
    clonedVisibleSections[operationsSectionIndex] = { ...operationsSection, items: operationsSection.items.filter(({ operationType }) => operationType === type) };
    this.visibleSections = clonedVisibleSections;
    const operations = get(operationsSection, 'items', []);
    const operation = operations.find(({ operationType }) => operationType === type);
    const operationBranchIds = get(operation, 'branchIds');
    this.onOperationTypeClick({ operationType: type, branchIds: operationBranchIds });
  };

  @action onDeleteCheckpoint = (checkpointId) => {
    this.plannerTask = { ...this.plannerTask, checkpoints: this.plannerTask.checkpoints.filter(({ label: { id } = {} } = {}) => id !== checkpointId) };
    if (this.plannerTask.isOrderRequired && this.plannerTask.checkpoints.length < 2) {
      this.plannerTask.isOrderRequired = false;
    }
  };

  @action onDeleteTechnicalObject = async ({ checkpoint: { label: { id: checkpointId } }, technicalObjectId }) => { // eslint-disable-line
    const currentCheckpoint = this.plannerTask.checkpoints.find(({ label: { id } }) => id === checkpointId);
    const currentCheckpointIndex = this.plannerTask.checkpoints.findIndex(({ label: { id } }) => id === checkpointId);
    currentCheckpoint.technicalObjects = currentCheckpoint.technicalObjects.filter(({ id }) => id !== technicalObjectId);
    if (isEmpty(currentCheckpoint.technicalObjects)) {
      this.onDeleteCheckpoint(checkpointId);
      return;
    }
    const clonedCurrentCheckpoints = cloneDeep(this.plannerTask.checkpoints);
    clonedCurrentCheckpoints[currentCheckpointIndex] = currentCheckpoint;
    this.plannerTask = { ...this.plannerTask, checkpoints: clonedCurrentCheckpoints };
  };

  @action onDeleteOperations = ({ technicalObjectId, operationType, checkpoint }) => {
    const { label: { id: checkpointId } } = checkpoint;
    const currentCheckpoint = this.plannerTask.checkpoints.find(({ label: { id } }) => id === checkpointId);
    const currentCheckpointIndex = this.plannerTask.checkpoints.findIndex(({ label: { id } }) => id === checkpointId);
    const currentCheckpointTechnicalObject = currentCheckpoint.technicalObjects.find(({ id }) => id === technicalObjectId);
    const currentCheckpointTechnicalObjectIndex = currentCheckpoint.technicalObjects.findIndex(({ id }) => id === technicalObjectId);
    if (operationType === 'DEFECTS') {
      currentCheckpointTechnicalObject.defects = [];
    } else if (operationType === 'MEASURING_POINTS') {
      currentCheckpointTechnicalObject.measuringPoints = [];
    } else if (operationType === 'CUSTOM_ACTION') {
      currentCheckpointTechnicalObject.customAction = '';
    }
    const measuringPoints = get(currentCheckpointTechnicalObject, 'currentCheckpointTechnicalObject', []);
    const defects = get(currentCheckpointTechnicalObject, 'defects', []);
    const customAction = get(currentCheckpointTechnicalObject, 'customAction') || '';
    if (isEmpty(measuringPoints) && isEmpty(defects) && customAction.length === 0) {
      this.onDeleteTechnicalObject({ checkpoint, technicalObjectId });
      return;
    }
    const clonedCurrentCheckpoints = cloneDeep(this.plannerTask.checkpoints);
    clonedCurrentCheckpoints[currentCheckpointIndex].technicalObjects[currentCheckpointTechnicalObjectIndex] = currentCheckpointTechnicalObject;
    this.plannerTask = { ...this.plannerTask, checkpoints: clonedCurrentCheckpoints };
  };

  @action addCustomActionSection = (item, section) => {
    this.visibleSections = [section, {
      type: CUSTOM_ACTION,
      sectionTitle: item.title,
      technicalObjectId: item.technicalObjectId,
      branchIds: [...item.branchIds, 'customAction'],
    }];
  };

  @action onChangeCustomAction = (value, technicalObjectId, sectionBranchIds) => {
    const pathToValue = sectionBranchIds.join('.');
    if (!value) {
      this.activeActions = this.activeActions.filter(activeAction => !startsWith(activeAction, pathToValue));
      return;
    }
    const clonedActiveActions = cloneDeep(this.activeActions);
    const currentActiveAction = clonedActiveActions.find(activeAction => startsWith(activeAction, pathToValue));
    const currentActiveActionIndex = clonedActiveActions.findIndex(activeAction => startsWith(activeAction, pathToValue));
    if (currentActiveAction) {
      clonedActiveActions[currentActiveActionIndex] = `${pathToValue}.${value}`;
      this.activeActions = clonedActiveActions;
    } else {
      this.activeActions = [...this.activeActions, `${pathToValue}.${value}`];
    }
  };

  @action onUpdateCustomAction = ({ customActionValue, technicalObjectId, checkpoint }) => {
    const { label: { id: checkpointId } } = checkpoint;
    const currentCheckpoint = this.plannerTask.checkpoints.find(({ label: { id } }) => id === checkpointId);
    const currentCheckpointIndex = this.plannerTask.checkpoints.findIndex(({ label: { id } }) => id === checkpointId);
    let currentCheckpointTechnicalObject = currentCheckpoint.technicalObjects.find(({ id }) => id === technicalObjectId);
    const currentCheckpointTechnicalObjectIndex = currentCheckpoint.technicalObjects.findIndex(({ id }) => id === technicalObjectId);
    if (customActionValue) {
      currentCheckpointTechnicalObject.customAction = customActionValue;
    } else {
      currentCheckpointTechnicalObject = omit(currentCheckpointTechnicalObject, ['customAction']);
      const measuringPoints = get(currentCheckpointTechnicalObject, 'currentCheckpointTechnicalObject', []);
      const defects = get(currentCheckpointTechnicalObject, 'defects', []);
      if (isEmpty(measuringPoints) && isEmpty(defects)) {
        this.onDeleteTechnicalObject({ checkpoint, technicalObjectId });
        return;
      }
    }
    const clonedCurrentCheckpoints = cloneDeep(this.plannerTask.checkpoints);
    clonedCurrentCheckpoints[currentCheckpointIndex].technicalObjects[currentCheckpointTechnicalObjectIndex] = currentCheckpointTechnicalObject;
    this.plannerTask = { ...this.plannerTask, checkpoints: clonedCurrentCheckpoints };
  };

  @action toggleCollapseCheckpoint = (isExpanded, checkpointId) => {
    const { checkpoints } = this.plannerTask;
    const checkpoint = checkpoints.find(({ label: { id } = {} } = {}) => id === checkpointId);
    set(checkpoint, 'isExpanded', isExpanded);
    this.plannerTask = { ...this.plannerTask, checkpoints };
  };

  @action toggleCollapseAll = () => {
    const { checkpoints } = this.plannerTask;
    this.plannerTask = { ...this.plannerTask, checkpoints: checkpoints.map(checkpoint => ({ ...checkpoint, isExpanded: !this.allCheckpointsAreExpanded })) };
  };

  @action deletePlannerTask = async (plannerTaskId) => {
    try {
      this.isDeleting = true;
      const { error = false } = await plannerTaskAgent.deletePlannerTask(plannerTaskId) || {};
      runInAction(() => {
        this.isDeleting = false;
      });
      return { error };
    } catch (error) {
      console.log('ERROR DELETING PLANNER TASK: ', error);
      runInAction(() => {
        this.isDeleting = false;
      });
      return { error };
    }
  };

  @action changeStatus = async ({
    id, status, pauseReasonId, pauseReasonComment
  }) => {
    try {
      this.isUpdatingReadyStatus = status === 'OnPause';
      this.isUpdatingOnPauseStatus = status === 'Ready';
      const data = {
        id,
        status,
        ...pauseReasonId && { pauseReasonId },
        ...pauseReasonComment && { pauseReasonComment },
      };
      const { error } = await plannerTaskAgent.changeStatus(data) || {};
      if (error) return { error };
      runInAction(() => {
        this.isUpdatingReadyStatus = false;
        this.isUpdatingOnPauseStatus = false;
      });
    } catch (error) {
      console.log('ERROR CHANGING STATUS: ', error);
      runInAction(() => {
        this.isUpdatingReadyStatus = false;
        this.isUpdatingOnPauseStatus = false;
      });
      return { error };
    }
  };
}

export default PlannerTaskStore;
