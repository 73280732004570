import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  layout: {
    marginBottom: theme.spacing(2),
  },
  boxWrapper: {
    borderBottom: '1px solid #f3f3f3'
  },
  box: {
    maxWidth: 500,
    padding: '14px 5px',
    margin: '0 auto',
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    margin: '35px 25px 25px',
    color: '#212121',
  },
  addEquipmentButton: {
    boxShadow: 'none',
    minHeight: 80,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderStyle: 'dashed !important',
    fontSize: 16,
    fontWeight: 'normal'
  },
  addEquipmentIcon: {
    marginRight: theme.spacing(1)
  },
  sortableItem: {
    display: 'flex',
    width: '100%',
    '&:hover': {
      boxShadow: `0 2px 4px 0 ${theme.palette.black20}`,
    },
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  dragIndicator: {
    display: 'flex',
    width: 34,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.lightGray,
    cursor: 'grab',
  },
  grabbing: {
    cursor: 'grabbing',
  },
  progressBar: {
    display: 'flex',
    minWidth: 40,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  progressIndicator: {
    flex: 1,
    position: 'relative',
    marginBottom: 8,
    '&::after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: 8,
      height: 8,
      borderRadius: '50%',
      top: 'calc(50% - 4px)',
      left: 'calc(50% - 4px)',
      background: theme.palette.primaryLight,
      zIndex: 1,
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: 2,
      height: 'calc(100% + 8px)',
      top: '50%',
      left: 'calc(50% - 1px)',
      background: theme.palette.gray,
    },
    '&:last-child': {
      marginBottom: 0,
      '&::before': {
        height: 0,
      }
    },
  },
  helperClass: {
    zIndex: theme.sortableItemZIndex,
    maxWidth: '100%',
  },
  technicalObjectWrapper: {
    display: 'flex',
    marginBottom: 24,
    maxWidth: '100%',
  },
  technicalObject: {
    flex: 1,
    maxWidth: ({ isSortable }) => (isSortable ? 'calc(100% - 40px)' : '100%'),
  },
  emptyDataBlockWrapper: {
    paddingTop: 64,
  },
}));

export default useStyles;
