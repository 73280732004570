import {
  action,
  computed,
  runInAction,
  makeObservable,
} from 'mobx';
import {
  isNull, isNaN, cloneDeep,
} from 'lodash';
import TaskReportAgent from 'ApiAgents/Reports/TaskReportAgent';
import EquipmentAgent from 'ApiAgents/Equipment/EquipmentAgent';

const initialState = {
  isLoading: false,
  isLoadingMeasuringPoints: false,
  isSaving: false,
  reportData: {},
  reportInitialData: {},
  measuringPoints: [],
};

const taskReportAgent = new TaskReportAgent();
const equipmentAgent = new EquipmentAgent();

export class ReportStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
    this.rootStore.prepareState(initialState, this);
  }

  isLoading;

  isLoadingMeasuringPoints;

  isSaving;

  reportData;

  reportInitialData;

  measuringPoints;

  @action getMeasuringPoints = async () => {
    try {
      this.isLoadingMeasuringPoints = true;
      const measuringPoints = await equipmentAgent.getMeasurementPointsByReport();
      runInAction(() => {
        this.measuringPoints = measuringPoints;
        this.isLoadingMeasuringPoints = false;
      });
    } catch (error) {
      runInAction(() => {
        this.isLoadingMeasuringPoints = false;
      });
    }
  };

  @action getReportById = async (reportId) => {
    try {
      this.isLoading = true;
      const report = await taskReportAgent.getTpsInspectionReport(reportId);
      runInAction(() => {
        this.reportData = report;
        this.reportInitialData = report;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action saveReport = async () => {
    try {
      this.isSaving = true;
      const result = await taskReportAgent.saveTpsInspectionReport(this.reportData);
      runInAction(() => {
        this.isSaving = false;
      });
      return { result };
    } catch (error) {
      runInAction(() => {
        this.isSaving = false;
      });
      return { error };
    }
  };

  @action updateReport = async () => {
    try {
      this.isSaving = true;
      const result = await taskReportAgent.updateTpsInspectionReport(this.reportData);
      runInAction(() => {
        this.isSaving = false;
      });
      return { result };
    } catch (error) {
      runInAction(() => {
        this.isSaving = false;
      });
      return { error };
    }
  };

  @action setValidatedReportData = (validatedReportData) => {
    runInAction(() => {
      this.reportData = validatedReportData;
    });
  };

  @action onMeasurementChange = ({
    value, rowIndex, columnIndex, groupIndex, measurementIndex
  }) => {
    const newReportState = cloneDeep(this.reportData);
    const modifiedMeasurement = newReportState.rows[rowIndex].columns[columnIndex].groups[groupIndex].measurements[measurementIndex];
    const { hasError } = modifiedMeasurement;
    modifiedMeasurement.value = value;
    if (value && hasError) delete modifiedMeasurement.hasError;
    runInAction(() => {
      this.reportData = newReportState;
    });
  };

  @action onCommentChange = ({
    value, rowIndex, columnIndex, groupIndex, commentIndex
  }) => {
    const newReportState = cloneDeep(this.reportData);
    const modifiedComment = newReportState.rows[rowIndex].columns[columnIndex].groups[groupIndex].comments[commentIndex];
    const { hasError } = modifiedComment;
    modifiedComment.value = value;
    if (value && hasError) delete modifiedComment.hasError;
    runInAction(() => {
      this.reportData = newReportState;
    });
  };

  @action discardEditedData = () => {
    runInAction(() => {
      this.reportData = this.reportInitialData;
    });
  };

  @computed get canMakeRequest() {
    return !isNull(this.tpsId)
      && !isNull(this.powerUnitId)
      && !isNull(this.shiftId)
      && !isNull(this.state)
      && !isNaN(+this.state);
  }

  @action createTpsInspectionReport = async () => {
    this.isSaving = true;
    try {
      const result = await taskReportAgent.createTpsInspectionReport({
        tpsId: this.tpsId,
        powerUnitId: this.powerUnitId,
        shift: this.shiftId,
        state: +this.state,
      });
      runInAction(() => {
        this.isSaving = false;
      });
      return { result };
    } catch (error) {
      console.log('ERROR IN CREATING REPORT: ', error);
    }
    runInAction(() => {
      this.isCreating = false;
    });
  };
}

export default ReportStore;
