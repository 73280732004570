import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import useStores from 'Store/useStores';
import { EXISTENT_TASKS } from 'Src/shared/constants/responseErrors/errors';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import HeaderLayout from 'Common/widgets/Layout/Header/Header';
import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import DataTable from 'Common/components/DataTable/DataTable';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import * as routes from 'Shared/constants/routes';
import useManageEntityModal from 'Common/components/Modal/ManageEntityModal/useManageEntityModal';
import tableConfig from 'Business/tpsInspections/config/checkpiontsTableConfig';


const CheckpointList = observer(() => {
  const {
    checkpointStore: {
      isLoading,
      isLoaded,
      isDeleting,
      deleteCheckpoint,
      checkpointsList,
      loadCheckpointsList,
      removeCheckpointFromList,
      totalPages,
      isLoadingByPage,
      hasNextPage,
    },
    notificationStore: { enqueueSnackbar },
  } = useStores();
  const {
    setManageEntityModalVisibility, setOnManageEntityModalCloseCallback, setManageEntityModalState, renderManageEntityModal
  } = useManageEntityModal();
  const { t } = useTranslation();
  const history = useHistory();

  const translatedTableConfig = tableConfig(t);

  const createHandler = () => {
    history.push({
      pathname: routes.CREATE_CHECKPOINT
        .replace(':action(create)', 'create')
    });
  };

  const editHandler = (event, row) => {
    history.push({
      pathname: routes.EDIT_CHECKPOINT
        .replace(':id', row.id)
        .replace(':action(edit)', 'edit')
    });
  };

  const onRowClickHandler = (event, rowData = {}) => {
    if (rowData.id) {
      history.push({
        pathname: `${routes.CHECKPOINTS}/${rowData.id}`
      });
    }
  };

  const handleDeleteCheckpoint = id => async () => {
    const { error } = await deleteCheckpoint(id);
    if (error) {
      const errorMessage = get(error, 'data.message', '');
      const checkpointWithExistentTasksError = errorMessage.indexOf(EXISTENT_TASKS) > -1;
      const messageTemplateRows = [
        {
          rowContent: [{
            type: 'text',
            text: checkpointWithExistentTasksError
              ? t('NOT_EDITABLE_MESSAGE')
              : t('CANT_DELETE_CHECKPOINT')
          }]
        }];

      if (checkpointWithExistentTasksError) {
        messageTemplateRows.push({
          rowContent: [{
            type: 'text',
            text: `${t('DELETING')} ${t('RESTRICTED')}`
          }]
        });
      }

      enqueueSnackbar({
        messageTemplate: {
          rows: messageTemplateRows,
        },
        variant: 'error',
      });
      return;
    }
    removeCheckpointFromList(id);
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('CHECKPOINT_SUCCESSFULLY_REMOVED')
          }]
        }],
      },
      variant: 'success',
    });
  };

  const removeHandler = (event, { id, name }) => {
    setManageEntityModalState(() => ({
      title: `${t('DELETING')} ${t('TAG_GENITIVE')}`,
      entityName: name,
      message: `${t('CONFIRM_DELETING_MESSAGE')} ${t('TAG_ACCUSATIVE')}?`,
      type: 'action'
    }));
    setOnManageEntityModalCloseCallback(() => handleDeleteCheckpoint(id, name));
    setManageEntityModalVisibility(true);
  };

  const showLoadListError = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('REQUEST_LOAD_LIST_ERROR')
          }]
        }],
      },
      variant: 'error',
    });
  };

  useEffect(() => {
    loadCheckpointsList().catch(() => showLoadListError());
  }, []);

  return (isLoaded && !isLoading) ? (
    <>
      {isDeleting && <ProgressBar />}
      {isLoadingByPage && <ProgressBar />}
      <PaperLayout autoHeight>
        {isLoadingByPage && <ProgressBar />}
        <HeaderLayout title={t('TAGS')} sticky>
          <HeaderButton
            title={t('CREATE_TAG')}
            type="add"
            handleClick={createHandler}
          />
        </HeaderLayout>
        <DataTable
          isDataLoaded={isLoaded}
          titleSingle={t('TAG_ACCUSATIVE')}
          columns={translatedTableConfig}
          data={checkpointsList}
          onRowClick={onRowClickHandler}
          onEdit={editHandler}
          onRemove={removeHandler}
          count={totalPages}
          hasNextPage={hasNextPage}
          onPageChange={(page, size) => loadCheckpointsList(page, size).catch(() => showLoadListError())}
          onPerPageChange={(page, size) => loadCheckpointsList(page, size).catch(() => showLoadListError())}
          actions={['remove']}
          dataTestRowSuffixEntityName="id"
        />
      </PaperLayout>

      {renderManageEntityModal()}
    </>
  ) : <ProgressBar />;
});

CheckpointList.propTypes = {};

export default CheckpointList;
