import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoOperations } from 'Src/assets/banners/no-operations.svg';

import useStyles from './styles';

const NoOperationsToConfirm = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <NoOperations className={classes.image} />
      <div className={classes.title}>{t('NO_OPERATIONS_TO_CONFIRM')}</div>
    </div>
  );
};

export default NoOperationsToConfirm;
