import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  popper: {
    marginTop: -2,
    zIndex: 10
  },
  tooltip: {
    maxWidth: 240,
    margin: '5px 0',
    textAlign: 'center',
  }
}));

export default useStyles;
