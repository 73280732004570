import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  filterHeader: {
    padding: '0px 16px',
    height: theme.filterHeaderHeight,
  },
  filtersLabel: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: theme.palette.black,
  },
  clearFiltersButton: {
    textTransform: 'none',
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: theme.palette.primaryLight,
    '&:disabled': {
      color: theme.palette.black30,
    }
  },
}));

export default useStyles;
