import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useStores from 'Store/useStores';
import { get, find } from 'lodash';
import { observer } from 'mobx-react';

const WorkplaceTypeCell = ({ type }) => {
  const { t } = useTranslation();
  const {
    workplacesItemStore: {
      workplaceTypes,
    },
  } = useStores();
  const typeObj = find(workplaceTypes, { value: type });
  const title = get(typeObj, 'title', '');
  return t(title);
};

WorkplaceTypeCell.defaultProps = {
  type: undefined,
};

WorkplaceTypeCell.propTypes = {
  type: PropTypes.number,
};

export default observer(WorkplaceTypeCell);
