export const TASKS_VIEW = 'tasks:view';
export const TASK_CREATE = 'task:create';
export const TASK_VIEW = 'task:view';
export const TASK_EDIT = 'task:edit';
export const TASK_CONTROL = 'task:control';

export const BRIGADES_VIEW = 'brigades:view';
export const BRIGADE_CREATE = 'brigade:create';
export const BRIGADE_EDIT = 'brigade:edit';
export const BRIGADE_VIEW = 'brigade:view';

export const STATISTICS_VIEW = 'statistics:view';
export const TASK_PERFORMANCE_STATISTICS_VIEW = 'task_performance_statistics:view';
export const CONTROL_VIEW = 'control:view';

export const VOLUMES_VIEW = 'volumes:view';
export const USERS_VIEW = 'users:view';
export const USER_CREATE = 'user:create';
export const USER_VIEW = 'user:view';
export const USER_EDIT = 'user:edit';

export const DICTIONARIES_VIEW = 'dictionaries:view';
export const DEFECTS_DICTIONARY_ITEM_EDIT = 'defects_dictionary_item:edit';
export const DEFECTS_DICTIONARY_LIST_VIEW = 'defects_dictionary_list:view';
export const PAUSE_REASONS_DICTIONARY_LIST_VIEW = 'pause_reasons_dictionary_list:view';
export const PAUSE_REASON_DICTIONARY_CREATE = 'pause_reason_dictionary:create';
export const PAUSE_REASON_DICTIONARY_EDIT = 'pause_reason_dictionary:edit';
export const OPERATION_KEYS_LIST_VIEW = 'operation_keys_list:view';
export const OPERATION_KEY_EDIT = 'operation_key:edit';
export const OPERATION_KEY_CREATE = 'operation_key:create';
export const WORKPLACES_LIST_VIEW = 'workplaces_list:view';
export const WORKPLACE_CREATE = 'workplace:create';
export const WORKPLACE_EDIT = 'workplace:edit';

export const SETTINGS_VIEW = 'settings:view';
export const ADMIN_GENERAL_SETTINGS_VIEW = 'admin_general_settings:view';
export const PARALLEL_OPERATIONS_VIEW = 'parallel_operations:view';
export const PRESENCE_CONTROL_LIST_VIEW = 'presence_control_list:view';
export const PRESENCE_CONTROL_EDIT = 'presence_control:edit';
export const PRESENCE_CONTROL_VIEW = 'presence_control:view';

export const INSPECTION_TASKS_VIEW = 'inspection_tasks:view';
export const INSPECTION_TASK_VIEW = 'inspection_task:view';
export const INSPECTION_TASK_CREATE = 'inspection_tasks:create';
export const INSPECTION_TASK_EDIT = 'inspection_tasks:edit';

export const INSPECTIONS_VIEW = 'inspection:view';
export const INSPECTION_VIEW = 'inspection:view';
export const INSPECTION_CREATE = 'inspection:create';
export const INSPECTION_EDIT = 'inspection:edit';

export const MEASUREMENT_REPORT_VIEW = 'measurement_report:view';
export const MEASUREMENT_REPORT_LIST_VIEW = 'measurement_report_list:view';

export const TASK_ROUTES_VIEW = 'task_routes:view';
export const TASK_ROUTE_VIEW = 'task_route:view';
export const TASK_ROUTE_CREATE = 'task_route:create';
export const TASK_ROUTE_EDIT = 'task_route:edit';

export const CHECKPOINTS_VIEW = 'checkpoints:view';
export const CHECKPOINT_VIEW = 'checkpoint:view';
export const CHECKPOINT_CREATE = 'checkpoint:create';
export const CHECKPOINT_EDIT = 'checkpoint:edit';

export const CHECKPOINTS_NEW_VIEW = 'checkpoints-new:view';
export const CHECKPOINT_NEW_CREATE = 'checkpoint-new:create';
export const CHECKPOINT_NEW_EDIT = 'checkpoint-new:edit';

export const TPS_REPORT_REQUEST = 'tps_report:request';
export const TPS_REPORT_CREATE = 'tps_report:create';
export const TPS_REPORT_VIEW = 'tps_report:view';
export const TPS_REPORT_EDIT = 'tps_report:edit';

export const DEFECT_VIEW = 'defect:view';
export const DEFECTS_VIEW = 'defects:view';

// not assigned to current routes
export const STATUSES_VIEW = 'statuses:view';
export const PROBLEMS_VIEW = 'problems:view';
export const RESOURCES_VIEW = 'resources:view';
export const MAP_VIEW = 'map:view';
export const REFERENCE_VIEW = 'reference:view';
export const MATERIALS_VIEW = 'materials:view';
export const TRANSPORTS_VIEW = 'transports:view';
export const OCCUPATIONAL_SAFETY_VIEW = 'occupational_safety:view';
export const PERFORMANCE_STATISTICS_VIEW = 'performance_statistics:view';

export const INSPECTION_REPORTS_VIEW = 'repair-reports:view';
export const REPORT_INSPECTION_STATUSES_VIEW = 'inspection-statuses:view';
export const REPORT_COMPLETED_INSPECTION_VIEW = 'completed-inspection:view';
export const REPORT_DETAILED_INSPECTION_VIEW = 'detailed-inspection:view';

export const SHAREPOINT_SETTINGS_LIST_VIEW = 'sharepoint-settings-list:view';
export const SHAREPOINT_SETTING_VIEW = 'sharepoint-setting:view';
export const SHAREPOINT_SETTING_EDIT = 'sharepoint-setting:edit';

export const ADMIN_USER_ENTERPRISE_EDIT = 'admin-user-enterprise:edit';
export const ADMIN_USER_ENTERPRISE_FILTER = 'admin-user-enterprise:filter';
export const ADMIN_USER_AUTOFILL_ORGUNIT = 'admin-user-autofill-orgunit';

export const PLANNER_TASK_CREATE = 'planner-task:create';
export const PLANNER_TASK_EDIT = 'planner-task:edit';
export const PLANNER_TASK_VIEW = 'planner-task:view';
export const PLANNER_TASKS_LIST_VIEW = 'planner-tasks-list:view';
