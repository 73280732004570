import React, { useEffect, useState } from 'react';
import MsalAuth from 'Services/auth/MsalAuth';
import { client } from 'Services/api/request';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import Agent from 'ApiAgents/Agent';
import useStores from 'Store/useStores';
import urlJoin from 'url-join';

const agent = new Agent();

const WithAuth = (WrappedComponent) => {
  const WithStateComponent = () => {
    const {
      userStore: {
        setAccessToken,
        setLogoutHandler,
        loadAuth,
        auth,
      },
    } = useStores();
    // eslint-disable-next-line no-undef
    const localConfigUrl = LOCAL_BACKEND ? '/config_local_back.json' : '/config.json';
    let authService = null;
    const [loggedIn, setLoggedIn] = useState(false);

    const isAuthenticated = () => authService.getAccount();

    const getConfig = url => agent.getByUrl(url);

    const getMsalAuthInstance = config => (
      new MsalAuth(
        config,
        setAccessToken
      )
    );

    const prepareAppBaseUrl = url => urlJoin(url, 'api');

    useEffect(() => {
      if (loggedIn) return;

      (async () => {
        try {
          /* eslint-disable object-curly-newline*/
          const {
            ENVIRONMENT,
            TECHNICALOBJECT_BASE_URL,
            ACCOUNT_BASE_URL,
            INSPECTION_BASE_URL,
            TASK_BASE_URL,
            TPS_INSPECTION_TASK_BASE_URL,
            MS_GRAPH_API_URL,
            GTM_TOKEN,
            GA_TRACK_ID,
            ...restConfigOptions } = await getConfig(localConfigUrl);
          window.localStorage.setItem('gtmToken', GTM_TOKEN);
          window.localStorage.setItem('gaTrackId', GA_TRACK_ID);

          authService = await getMsalAuthInstance(restConfigOptions);
          window.authService = authService;
          client.setMsGraphApiUrl(MS_GRAPH_API_URL);

          if (!isAuthenticated()) {
            authService.login();
          } else {
            await authService.acquireToken();
            setLogoutHandler(authService.logout);
            client
              .setAccountUrl(prepareAppBaseUrl(ACCOUNT_BASE_URL))
              .setTechObjectUrl(prepareAppBaseUrl(TECHNICALOBJECT_BASE_URL))
              .setTaskUrl(prepareAppBaseUrl(INSPECTION_BASE_URL))
              .setTpsInspectionTaskUrl(prepareAppBaseUrl(TPS_INSPECTION_TASK_BASE_URL));
            await loadAuth(ENVIRONMENT);
            // setEnvironment(ENVIRONMENT);
            setLoggedIn(true);
          }
        } catch (e) {
          console.log('debug_error in WithAuth: ', e);
        }
      })();
    }, []);

    return (
      (loggedIn && auth) ? <WrappedComponent /> : <ProgressBar />
    );
  };

  return WithStateComponent;
};

export default WithAuth;
