import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useHistory,
  useLocation,
  withRouter,
} from 'react-router-dom';
import { withTheme } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import useStores from 'Store/useStores';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import DataTable from 'Common/components/DataTable/DataTable';
import { tableConfig } from 'Business/plant/config/workplacesTableConfig';
import Header from 'Common/widgets/Layout/Header/Header';
import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import { WORKPLACES_SETTINGS, SETTINGS, CREATE_WORKPLACE } from 'Shared/constants/routes';
import useManageEntityModal from 'Common/components/Modal/ManageEntityModal/useManageEntityModal';
import { INITIAL_PAGE_NUMBER, INITIAL_PAGE_SIZE } from 'Shared/constants/paging';
import AddWorkplaceHelperBlock from 'Common/widgets/AddWorkplaceHelperBlock';

const WorkplacesList = ({ theme }) => {
  const { t } = useTranslation();
  const translatedTableConfig = tableConfig(t);
  const {
    setManageEntityModalVisibility, setOnManageEntityModalCloseCallback, setManageEntityModalState, renderManageEntityModal
  } = useManageEntityModal();

  const {
    workplacesListStore: {
      list,
      currentPageNumber,
      currentPageSize,
      loadWorkplacesList,
      discardList,
      isListLoading,
      isListLoaded,
      totalPages,
      hasNextPage,
      hasPreviousPage,
      deleteWorkplace,
      isWorkplaceDeleting,
    },
    notificationStore: { enqueueSnackbar },
    workplacesItemStore: {
      loadWorkplaceTypes,
    },
  } = useStores();

  const history = useHistory();
  const location = useLocation();

  const { state: locationState, pathname } = location;
  const { paginationParams: { pageNumber, perPage } = {} } = locationState || {};

  const onRowClickHandler = (event, row = {}, { page, perPage: itemsOnPage } = {}) => {
    const { id } = row;
    history.push({
      pathname: `${WORKPLACES_SETTINGS}/${id}`,
      state: { paginationParams: { pageNumber: page, perPage: itemsOnPage }, fromPath: pathname }
    });
  };

  const showDefaultErrorNotification = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('REQUEST_DEFAULT_ERROR'),
              },
            ],
          },
        ],
      },
      variant: 'error',
    });
  };

  const loadList = (page, size) => {
    loadWorkplacesList(page, size).catch(() => {
      showDefaultErrorNotification();
    });
  };

  useEffect(() => {
    loadWorkplaceTypes().catch(() => {
      showDefaultErrorNotification();
    });
    loadList(pageNumber || INITIAL_PAGE_NUMBER, perPage || INITIAL_PAGE_SIZE);
    if (pageNumber) history.replace(pathname);
    return () => {
      discardList();
    };
  }, []);

  const {
    headerHeight = 0,
    pageHeaderHeight = 0,
    mainContentPaddingTop = 0,
    mainContentPaddingBottom = 0,
    tableFooterHeight = 0,
  } = theme;

  const tableBodyHeight = `calc(100vh - ${headerHeight}px - ${pageHeaderHeight}px - ${mainContentPaddingTop}px - ${mainContentPaddingBottom}px - ${tableFooterHeight}px)`;

  const showWorkplaces = isListLoaded && !isEmpty(list);
  const showHelperBlock = isListLoaded && isEmpty(list);

  const goToSettings = () => history.push({ pathname: SETTINGS });

  const goToWorkplaceCreation = () => {
    history.push({
      pathname: CREATE_WORKPLACE,
    });
  };

  const removeItem = id => async () => {
    const { error } = await deleteWorkplace(id) || {};
    if (error) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('ERROR_DELETE_WORKPLACE'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('WORKPLACE_SUCCESSFULLY_DELETED'),
              },
            ],
          },
        ],
      },
      variant: 'success',
    });
    loadList(currentPageNumber, currentPageSize);
  };

  const removeHandler = async (event, row = {}) => {
    const { id, code } = row;
    setManageEntityModalState(() => ({
      title: t('WORKPLACE_DELETION'),
      entityName: code,
      message: t('DO_YOU_WANT_TO_DELETE_WORKPLACE'),
      type: 'action',
      actionButtonTitle: t('DELETE'),
    }));
    setOnManageEntityModalCloseCallback(() => removeItem(id));
    setManageEntityModalVisibility(true);
  };

  return (
    <PaperLayout>
      {(isListLoading || isWorkplaceDeleting) && <ProgressBar />}
      <Header
        isActiveGoBack
        title={t('WORKPLACES')}
        titleGoBack={t('GO_BACK')}
        handleGoBack={goToSettings}
        style={{ borderBottom: 'none' }}
      >
        <HeaderButton
          title={t('ADD_WORKPLACE')}
          type="add"
          handleClick={goToWorkplaceCreation}
        />
      </Header>
      <DataTable
        isDataLoaded={isListLoaded}
        isVisible={showWorkplaces}
        options={{ tableLayout: 'fixed' }}
        withBoxShadow={false}
        tableBodyHeight={tableBodyHeight}
        data={list}
        titleSingle={t('WORK_PLACE').toLowerCase()}
        columns={translatedTableConfig.columns}
        onRowClick={onRowClickHandler}
        actions={translatedTableConfig.actions}
        dataTestRowSuffixEntityName="id"
        onRemove={removeHandler}
        count={totalPages}
        pageIndex={currentPageNumber}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        onPageChange={loadList}
        onPerPageChange={loadList}
      />
      {showHelperBlock && (
        <AddWorkplaceHelperBlock />
      )}
      {renderManageEntityModal()}
    </PaperLayout>
  );
};

WorkplacesList.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.shape({
    headerHeight: PropTypes.number,
    pageHeaderHeight: PropTypes.number,
    pageTabsHeight: PropTypes.number,
    tableHeaderHeight: PropTypes.number,
    tableFooterHeight: PropTypes.number,
    mainContentPaddingTop: PropTypes.number,
    mainContentPaddingBottom: PropTypes.number,
    pageSearchBlockHeight: PropTypes.number,
  }).isRequired,
};

export default withRouter(withTheme(observer(WorkplacesList)));
