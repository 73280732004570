import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import useStyles from './styles';

const StyledLink = ({ to, children, 'data-test': dataTest }) => {
  const classes = useStyles();
  console.log('dataTest', dataTest);

  return (
    <Link
      className={classes.link}
      to={to}
      {...dataTest && { 'data-test': dataTest }}
    >
      {children}
    </Link>
  );
};

StyledLink.defaultProps = {
  'data-test': undefined,
};

StyledLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ to: PropTypes.string })]).isRequired,
  children: PropTypes.node.isRequired,
  'data-test': PropTypes.string,
};

export default StyledLink;
