import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 750,
    '& .MuiTableRow-root:last-child .MuiTableCell-body': {
      border: 'none'
    },
    '& .MuiTableCell-body': {
      verticalAlign: 'top',
      paddingTop: 12,
      color: '#212121',
      '& p': {
        lineHeight: '24px'
      },
      '& .table-circle': {
        display: 'block',
        position: 'absolute',
        background: '#4499ee',
        width: 9,
        height: 9,
        borderRadius: '50%',
        top: 20,
        left: 22,
        zIndex: 2,
        '&::before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          background: '#f3f3f3',
          width: 1,
          height: 62,
          bottom: 9,
          left: 4,
          zIndex: -1
        },
        '&--first::before': {
          display: 'none'
        },
      }
    }
  },
  assigneeCell: {
    '&.MuiTableCell-root': {
      whiteSpace: 'break-spaces',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default useStyles;
