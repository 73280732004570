import {
  TASKS_VIEW,
  TASK_CREATE,
  TASK_VIEW,
  TASK_EDIT,
  TASK_CONTROL,
  BRIGADES_VIEW,
  BRIGADE_VIEW,
  BRIGADE_CREATE,
  BRIGADE_EDIT,
  STATISTICS_VIEW,
  TASK_PERFORMANCE_STATISTICS_VIEW,
  VOLUMES_VIEW,
  TPS_REPORT_CREATE,
  TPS_REPORT_EDIT,
  TPS_REPORT_REQUEST,
  TPS_REPORT_VIEW,
  INSPECTION_TASKS_VIEW,
  INSPECTION_TASK_VIEW,
  INSPECTION_TASK_CREATE,
  INSPECTION_TASK_EDIT,
  MEASUREMENT_REPORT_LIST_VIEW,
  TASK_ROUTES_VIEW,
  TASK_ROUTE_VIEW,
  TASK_ROUTE_CREATE,
  TASK_ROUTE_EDIT,
  CHECKPOINTS_VIEW,
  CHECKPOINT_VIEW,
  CHECKPOINT_CREATE,
  CHECKPOINT_EDIT,
  CONTROL_VIEW,
  USERS_VIEW,
  USER_VIEW,
  USER_EDIT,
  USER_CREATE,
  DEFECTS_VIEW,
  DEFECT_VIEW,
  INSPECTIONS_VIEW,
  INSPECTION_CREATE,
  DICTIONARIES_VIEW,
  DEFECTS_DICTIONARY_ITEM_EDIT,
  DEFECTS_DICTIONARY_LIST_VIEW,
  SETTINGS_VIEW,
  PARALLEL_OPERATIONS_VIEW,
  PRESENCE_CONTROL_LIST_VIEW,
  PRESENCE_CONTROL_VIEW,
  PRESENCE_CONTROL_EDIT,
  PAUSE_REASONS_DICTIONARY_LIST_VIEW,
  PAUSE_REASON_DICTIONARY_CREATE,
  PAUSE_REASON_DICTIONARY_EDIT,
  OPERATION_KEYS_LIST_VIEW,
  OPERATION_KEY_CREATE,
  OPERATION_KEY_EDIT,
  INSPECTION_REPORTS_VIEW,
  REPORT_INSPECTION_STATUSES_VIEW,
  REPORT_COMPLETED_INSPECTION_VIEW,
  REPORT_DETAILED_INSPECTION_VIEW,
  WORKPLACES_LIST_VIEW,
  WORKPLACE_EDIT,
  WORKPLACE_CREATE,
  SHAREPOINT_SETTINGS_LIST_VIEW,
  SHAREPOINT_SETTING_VIEW,
  SHAREPOINT_SETTING_EDIT,
  ADMIN_GENERAL_SETTINGS_VIEW,
  CHECKPOINTS_NEW_VIEW,
  CHECKPOINT_NEW_CREATE,
  CHECKPOINT_NEW_EDIT,
  ADMIN_USER_ENTERPRISE_EDIT,
  ADMIN_USER_ENTERPRISE_FILTER,
  PLANNER_TASK_CREATE,
  PLANNER_TASK_EDIT,
  PLANNER_TASK_VIEW,
  PLANNER_TASKS_LIST_VIEW,
  ADMIN_USER_AUTOFILL_ORGUNIT
} from 'Src/RBAC/businessPermissions';

import {
  TPS_REPAIR, TPS_INSPECTION, PL, COAL
} from 'Shared/constants/business';

import {
  FOREMAN,
  HEAD_MASTER,
  MASTER,
  PERFORMER,
  ADMINISTRATOR_OK,
  ADMINISTRATOR_OCP,
  PLANNER,
  HEAD_MECHANIC,
  MECHANIC,
  COAL_MASTER,
  MANAGER,
  SHIFT_SUPERVISOR_KTC,
  SENIOR_POWER_UNIT_OPERATOR,
  POWER_UNIT_OPERATOR,
  BOILER_OPERATOR,
  TURBINE_OPERATOR,
  SPECIALIST_SDKR,
  ECONOMIST_IETS,
  SUPER_ADMIN,
} from 'Shared/constants/roles';

const rules = {
  [TPS_REPAIR]: {
    [FOREMAN]: [
      TASKS_VIEW,
      TASK_VIEW,
      TASK_CREATE,
      STATISTICS_VIEW,
      TASK_PERFORMANCE_STATISTICS_VIEW,
      USERS_VIEW,
      USER_VIEW,
      USER_EDIT,
      USER_CREATE,
      ADMIN_USER_AUTOFILL_ORGUNIT,
    ],
    [HEAD_MASTER]: [
      TASKS_VIEW,
      TASK_VIEW,
      TASK_CREATE,
      STATISTICS_VIEW,
      TASK_PERFORMANCE_STATISTICS_VIEW,
    ],
    [MASTER]: [
      TASKS_VIEW,
      TASK_VIEW,
      TASK_CREATE,
      BRIGADES_VIEW,
      BRIGADE_VIEW,
      BRIGADE_EDIT,
      BRIGADE_CREATE,
      STATISTICS_VIEW,
      TASK_PERFORMANCE_STATISTICS_VIEW,
      CONTROL_VIEW,
    ],
    [SPECIALIST_SDKR]: [CONTROL_VIEW],
    [ECONOMIST_IETS]: [CONTROL_VIEW],
    [PERFORMER]: [],
    [ADMINISTRATOR_OK]: [
      USERS_VIEW,
      USER_VIEW,
      USER_EDIT,
      USER_CREATE,
      ADMIN_USER_ENTERPRISE_EDIT,
      ADMIN_USER_ENTERPRISE_FILTER
    ],
    [ADMINISTRATOR_OCP]: [
      USERS_VIEW,
      USER_VIEW,
      USER_EDIT,
      USER_CREATE,
      ADMIN_USER_AUTOFILL_ORGUNIT,
    ],
  },
  [TPS_INSPECTION]: {
    [MANAGER]: [
      TPS_REPORT_CREATE,
      TPS_REPORT_EDIT,
      TPS_REPORT_REQUEST,
      TPS_REPORT_VIEW,
      INSPECTION_TASKS_VIEW,
      INSPECTION_TASK_VIEW,
      INSPECTION_TASK_CREATE,
      INSPECTION_TASK_EDIT,
      TASK_ROUTES_VIEW,
      TASK_ROUTE_VIEW,
      TASK_ROUTE_CREATE,
      TASK_ROUTE_EDIT,
      CHECKPOINTS_VIEW,
      CHECKPOINT_VIEW,
      CHECKPOINT_CREATE,
      CHECKPOINT_EDIT,
      TASKS_VIEW,
      TASK_VIEW,
      INSPECTION_REPORTS_VIEW,
      REPORT_INSPECTION_STATUSES_VIEW,
      REPORT_COMPLETED_INSPECTION_VIEW,
      REPORT_DETAILED_INSPECTION_VIEW,
      CHECKPOINTS_NEW_VIEW,
      CHECKPOINT_NEW_CREATE,
      CHECKPOINT_NEW_EDIT,
      USERS_VIEW,
      USER_VIEW,
      USER_EDIT,
      USER_CREATE,
      PLANNER_TASK_CREATE,
      PLANNER_TASK_EDIT,
      PLANNER_TASK_VIEW,
      PLANNER_TASKS_LIST_VIEW,
      ADMIN_USER_AUTOFILL_ORGUNIT,
    ],
    [SHIFT_SUPERVISOR_KTC]: [
      TPS_REPORT_EDIT,
      TPS_REPORT_REQUEST,
      TPS_REPORT_VIEW,
      MEASUREMENT_REPORT_LIST_VIEW,
      INSPECTION_REPORTS_VIEW,
      REPORT_INSPECTION_STATUSES_VIEW,
      REPORT_COMPLETED_INSPECTION_VIEW,
      REPORT_DETAILED_INSPECTION_VIEW
    ],
    [SENIOR_POWER_UNIT_OPERATOR]: [
      TPS_REPORT_REQUEST,
      TPS_REPORT_VIEW,
      MEASUREMENT_REPORT_LIST_VIEW,
    ],
    [POWER_UNIT_OPERATOR]: [
      TPS_REPORT_REQUEST,
      TPS_REPORT_VIEW,
      TPS_REPORT_CREATE,
      MEASUREMENT_REPORT_LIST_VIEW,
    ],
    [BOILER_OPERATOR]: [TPS_REPORT_REQUEST, MEASUREMENT_REPORT_LIST_VIEW],
    [TURBINE_OPERATOR]: [TPS_REPORT_REQUEST, MEASUREMENT_REPORT_LIST_VIEW],
    [ADMINISTRATOR_OK]: [
      USERS_VIEW,
      USER_VIEW,
      USER_EDIT,
      USER_CREATE,
      ADMIN_USER_ENTERPRISE_EDIT,
      ADMIN_USER_ENTERPRISE_FILTER
    ],
    [ADMINISTRATOR_OCP]: [
      USERS_VIEW,
      USER_VIEW,
      USER_EDIT,
      USER_CREATE,
      ADMIN_USER_AUTOFILL_ORGUNIT,
    ],
  },
  [PL]: {
    [SUPER_ADMIN]: [VOLUMES_VIEW],
    [ADMINISTRATOR_OK]: [
      VOLUMES_VIEW,
      USERS_VIEW,
      USER_VIEW,
      USER_EDIT,
      USER_CREATE,
      DICTIONARIES_VIEW,
      DEFECTS_DICTIONARY_ITEM_EDIT,
      DEFECTS_DICTIONARY_LIST_VIEW,
      SETTINGS_VIEW,
      PARALLEL_OPERATIONS_VIEW,
      PRESENCE_CONTROL_LIST_VIEW,
      PRESENCE_CONTROL_VIEW,
      PRESENCE_CONTROL_EDIT,
      PAUSE_REASONS_DICTIONARY_LIST_VIEW,
      PAUSE_REASON_DICTIONARY_CREATE,
      PAUSE_REASON_DICTIONARY_EDIT,
      OPERATION_KEYS_LIST_VIEW,
      OPERATION_KEY_CREATE,
      OPERATION_KEY_EDIT,
      WORKPLACES_LIST_VIEW,
      WORKPLACE_CREATE,
      WORKPLACE_EDIT,
      SHAREPOINT_SETTINGS_LIST_VIEW,
      SHAREPOINT_SETTING_VIEW,
      SHAREPOINT_SETTING_EDIT,
      ADMIN_GENERAL_SETTINGS_VIEW,
      ADMIN_USER_ENTERPRISE_EDIT,
      ADMIN_USER_ENTERPRISE_FILTER
    ],
    [ADMINISTRATOR_OCP]: [
      USERS_VIEW,
      USER_VIEW,
      USER_EDIT,
      USER_CREATE,
      SETTINGS_VIEW,
      PRESENCE_CONTROL_LIST_VIEW,
      PRESENCE_CONTROL_VIEW,
      PRESENCE_CONTROL_EDIT,
      ADMIN_USER_AUTOFILL_ORGUNIT,
      SHAREPOINT_SETTINGS_LIST_VIEW,
      SHAREPOINT_SETTING_VIEW,
      SHAREPOINT_SETTING_EDIT
    ],
    [PLANNER]: [],
    [PERFORMER]: [],
  },
  [COAL]: {
    [HEAD_MECHANIC]: [
      TASKS_VIEW,
      TASK_CREATE,
      TASK_VIEW,
      TASK_EDIT,
      TASK_CONTROL,
      DEFECTS_VIEW,
      DEFECT_VIEW,
      TASK_ROUTES_VIEW,
      TASK_ROUTE_VIEW,
      TASK_ROUTE_CREATE,
      TASK_ROUTE_EDIT,
      INSPECTIONS_VIEW,
      INSPECTION_CREATE,
      USERS_VIEW,
      USER_VIEW,
      USER_EDIT,
      USER_CREATE,
      ADMIN_USER_AUTOFILL_ORGUNIT,
    ],
    [MECHANIC]: [
      TASKS_VIEW,
      TASK_CREATE,
      TASK_VIEW,
      TASK_EDIT,
      TASK_CONTROL,
      DEFECTS_VIEW,
      DEFECT_VIEW,
      TASK_ROUTES_VIEW,
      TASK_ROUTE_VIEW,
      TASK_ROUTE_CREATE,
      TASK_ROUTE_EDIT,
      INSPECTIONS_VIEW,
      INSPECTION_CREATE,
    ],
    [COAL_MASTER]: [
      TASKS_VIEW,
      TASK_CREATE,
      TASK_VIEW,
      TASK_EDIT,
      DEFECTS_VIEW,
      DEFECT_VIEW,
    ],
    [PERFORMER]: [],
    [ADMINISTRATOR_OK]: [
      USERS_VIEW,
      USER_VIEW,
      USER_EDIT,
      USER_CREATE,
      ADMIN_USER_ENTERPRISE_EDIT,
      ADMIN_USER_ENTERPRISE_FILTER
    ],
    [ADMINISTRATOR_OCP]: [
      USERS_VIEW,
      USER_VIEW,
      USER_EDIT,
      USER_CREATE,
      ADMIN_USER_AUTOFILL_ORGUNIT,
    ],
  },
};

export default rules;
