import { matchPath, useLocation } from 'react-router-dom';


const useCurrentListStatus = (type) => {
  const location = useLocation();
  let currentStatus = '';
  const matched = matchPath(location.pathname, {
    path: `/${type}/:status`,
    exact: true,
    strict: false
  });

  if (matched && matched.params.status) {
    currentStatus = matched.params.status;
  }

  return currentStatus;
};

export default useCurrentListStatus;
