import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  const computedHeight = `calc(100vh - ${theme.headerHeight}px - ${theme.spacing(8)}px)`;

  return {
    default: {
      minHeight: computedHeight,
      overflow: 'visible',
      position: 'relative'
    },
    autoScroll: {
      minHeight: 'auto',
      height: computedHeight,
      maxHeight: computedHeight,
      overflow: 'auto'
    },
    fixedHeight: {
      overflow: 'hidden',
      minHeight: computedHeight,
      maxHeight: computedHeight,
      position: 'relative'
    },
    autoHeight: {
      minHeight: 'auto',
      overflow: 'visible',
      position: 'static'
    }
  };
});

export default useStyles;
