import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import useStores from 'Store/useStores';
import { isEmpty, get } from 'lodash';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import Panel, { PanelBox, PanelTitle } from 'Common/widgets/Layout/Panel/Panel';
import TextInput from 'Common/components/Forms/Input/Text';
import FormSwitch from 'Common/components/Forms/Input/FormSwitch';
import { PAUSE_REASONS_DICTIONARIES } from 'Shared/constants/routes';

import useStyles from './styles';

const PauseReasonsDictionaryItem = ({ mode }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { state } = useLocation();
  const params = useParams();
  const { id } = params;

  const {
    pauseReasonsDictionaryStore: {
      list,
      loadList,
      isListLoading,
      isUpdatingPauseReason,
      addingPauseReason,
      pauseReason = {},
      getPauseReasonById,
      discardEditorState,
      updateIsVisibleFromEditor,
      updateTitleFromEditor,
      updateCodeFromEditor,
      updateIsPhotoRequiredFromEditor,
      updatePauseReason,
      addPauseReason,
    },
    notificationStore: { enqueueSnackbar },
    validationStore: { validateRequiredFields, isValidFields, hasErrors },
  } = useStores();

  const isEditingMode = mode === 'edit';
  const shouldLoadList = mode === 'edit' || isEmpty(list);
  const isCreatingMode = mode === 'create';

  const showDefaultErrorNotification = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('REQUEST_DEFAULT_ERROR'),
              },
            ],
          },
        ],
      },
      variant: 'error',
    });
  };

  const {
    attributes: { isPhotoRequired = false } = {},
    externalCode = '',
    isVisible = true,
    title = '',
  } = pauseReason;

  const goToList = () => {
    history.push({
      pathname: PAUSE_REASONS_DICTIONARIES,
      state,
    });
  };

  const updateItem = async () => {
    validateRequiredFields();
    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('CANT_UPDATE'),
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    const response = await updatePauseReason() || {};
    const { error } = response;
    if (error) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('CANT_UPDATE'),
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('REQUEST_DEFAULT_ERROR'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('CHANGES_SUCCESSFULLY_APPLIED'),
              },
            ],
          },
        ],
      },
      variant: 'success',
    });
    goToList();
  };

  const saveItem = async () => {
    validateRequiredFields();
    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('CANT_SAVE'),
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    const response = await addPauseReason() || {};
    const error = get(response, 'error');
    if (error) {
      const exceptionType = get(error, 'data.exceptionType');
      const isDictionaryDuplicateKeyException = exceptionType === 'DictionaryDuplicateKeyException';
      const errorMessage = isDictionaryDuplicateKeyException ? t('REASON_WITH_SAME_CODE_EXISTS') : t('REQUEST_DEFAULT_ERROR');
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('CANT_SAVE'),
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: errorMessage,
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('REASON_SUCCESSFULLY_ADDED'),
              },
            ],
          },
        ],
      },
      variant: 'success',
    });
    goToList();
  };

  const prepare = async () => {
    if (shouldLoadList) await loadList();
    if (isEditingMode) {
      const { error } = await getPauseReasonById(id);
      if (error) showDefaultErrorNotification();
    }
  };

  useEffect(() => {
    prepare();
  }, [id]);

  useEffect(() => () => discardEditorState(), []);

  if (isListLoading) return <ProgressBar />;
  return (
    <>
      {(isUpdatingPauseReason || addingPauseReason) && <ProgressBar />}
      <Panel
        mode={mode}
        saveItem={mode === 'edit' ? updateItem : saveItem}
        goBack={goToList}
        viewItem={goToList}
        labelGoToView={t('GO_BACK')}
        labelGoToList={t('GO_BACK')}
        labelEdit={t('PAUSE_REASON_EDITING')}
        labelCreate={t('PAUSE_REASON_CREATION')}
        withActions
      >
        <PanelBox>
          <PanelTitle>{t('PAUSE_REASON_ATTRIBUTES')}</PanelTitle>
          <FormSwitch
            label={t('IS_VISIBLE_IN_MOBILE_APP')}
            name="isVisible"
            checked={isVisible}
            onChange={() => updateIsVisibleFromEditor(!isVisible)}
            data-test="switch-isVisible"
          />
          <TextInput
            multiline
            autoComplete="off"
            label={t('TITLE')}
            value={title}
            name="title"
            onChange={updateTitleFromEditor}
            required
            data-test="userText"
          />
          <TextInput
            multiline
            autoComplete="off"
            label={t('CODE')}
            value={externalCode}
            name="externalCode"
            onChange={updateCodeFromEditor}
            required
            isReadonly={!isCreatingMode}
            data-test="externalCode"
          />
          <FormSwitch
            label={t('PHOTO_IS_REQUIRED')}
            name="isPhotoRequired"
            checked={isPhotoRequired}
            onChange={() => updateIsPhotoRequiredFromEditor(!isPhotoRequired)}
            data-test="switch-isPhotoRequired"
          />
          {isCreatingMode && (
            <div className={classes.warningBlock}>
              <div>{t('ATTENTION_TITLE')}</div>
              <div>{t('MAKING_CHANGES_MAKE_SURE')}</div>
              <div>{t('ABOUT_THE_PRESENCE_OF_THIS_REASON_CODE_IN_SAP_ERP')}</div>
            </div>
          )}
        </PanelBox>
      </Panel>
    </>
  );
};

PauseReasonsDictionaryItem.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default observer(PauseReasonsDictionaryItem);
