import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { VIEW_REPORT, REQUEST_REPORT } from 'Shared/constants/routes';
import useStores from 'Store/useStores';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import tableConfig from 'Business/tpsInspections/config/measurementReportsTableConfig';
import DataTable from 'Common/components/DataTable/DataTable';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import Header from 'Common/widgets/Layout/Header/Header';
import { POWER_UNIT_OPERATOR } from 'Src/shared/constants/roles';
import { INITIAL_PAGE_NUMBER } from 'Shared/constants/paging';

const MeasurementReportListPage = () => {
  const {
    measurementReportListStore: {
      totalPages,
      isLoadingByPage,
      hasNextPage,
      isReportsListLoaded,
      reportsList,
      loadReportsList,
      getReportShifts,
      shifts,
      isShiftsLoaded,
    },
    userStore: {
      role,
    },
  } = useStores();
  const { t } = useTranslation();
  const history = useHistory();

  const onRowClickHandler = (event, { reportId }) => {
    history.push({
      pathname: `${VIEW_REPORT}/${reportId}`
    });
  };

  const loadPaginatedReports = (pageNumber, perPage) => (
    loadReportsList(pageNumber, perPage)
  );

  useEffect(() => {
    getReportShifts();
    loadPaginatedReports(INITIAL_PAGE_NUMBER);
  }, []);

  if (!isReportsListLoaded || !isShiftsLoaded) {
    return <ProgressBar />;
  }

  const goToReportCreation = () => {
    history.push(REQUEST_REPORT);
  };

  const showAddReportButton = role === POWER_UNIT_OPERATOR;

  return (
    <PaperLayout autoHeight>
      {isLoadingByPage && <ProgressBar />}
      <Header title={t('MEASUREMENT_REPORT_LIST')}>
        {showAddReportButton && (
          <HeaderButton
            title={t('CREATE_REPORT')}
            type="add"
            handleClick={goToReportCreation}
          />
        )}
      </Header>
      <DataTable
        isDataLoaded={isReportsListLoaded}
        titleSingle={t('TASK_ACCUSATIVE')}
        columns={tableConfig(shifts, t)}
        data={reportsList}
        onRowClick={onRowClickHandler}
        count={totalPages}
        hasNextPage={hasNextPage}
        onPageChange={loadReportsList}
        onPerPageChange={loadReportsList}
        actions={[]}
        dataTestRowSuffixEntityName="reportId"
      />
    </PaperLayout>
  );
};

export default observer(MeasurementReportListPage);
