import React, { useState, createRef } from 'react';
import PropType from 'prop-types';
import {
  IconButton, SvgIcon, Tooltip // eslint-disable-line
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import TextInput from 'Common/components/Forms/Input/Text';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const ENTER_KEY_CODE = 13;

function AdditionalOperationsInput(props) {
  const { t } = useTranslation();
  const {
    title,
    placeholder,
    onOptionAdded,
    error
  } = props;

  const inputRef = createRef(null);

  const [value, setValue] = useState('');
  const classes = useStyles();

  const addOption = () => {
    if (value) {
      onOptionAdded(value);
      setValue('');
    }
  };

  return (
    <TextInput
      name="additionalOperationInput"
      className={classes.textField}
      ref={inputRef}
      label={title}
      placeholder={placeholder}
      value={value}
      onChange={setValue}
      error={error}
      inputProps={{
        onKeyDown: (event) => {
          const { keyCode } = event;
          if (keyCode === ENTER_KEY_CODE) addOption();
        },
      }}
      InputEndAdornment={(
        <Tooltip title={t('ADD_OPERATION')}>
          <SvgIcon
            color={value ? 'primary' : 'disabled'}
            className={classes.endAdornmentIcon}
            component={Add}
          />
        </Tooltip>
      )}
      onClickEndAdornment={addOption}
      data-test="additionalOperationInput"
    />
  );
}

AdditionalOperationsInput.defaultProps = {
  placeholder: '',
  error: false
};

AdditionalOperationsInput.propTypes = {
  title: PropType.string.isRequired,
  placeholder: PropType.string,
  onOptionAdded: PropType.func.isRequired,
  error: PropType.bool,
};

export default AdditionalOperationsInput;
