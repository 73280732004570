import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  popper: {
    width: '100% !important',
    top: 'calc(100% - 3px)',
    zIndex: 2,
    '& .MuiAutocomplete-paper': {
      border: ({ error }) => (`2px solid ${error ? theme.palette.negative : theme.palette.primaryLight}`),
      marginTop: -3,
      width: '100%',
      borderRadius: '0 0 4px 4px',
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14)',
      '&.MuiPaper-root': {
        borderTop: '0px',
      },
    },
    '& .MuiAutocomplete-listbox': {
      maxHeight: ({ listBoxMaxHeight }) => (listBoxMaxHeight || 150),
      padding: '8px 0px 0px 0px',
    },
    '& .MuiAutocomplete-paper[x-placement="top"]': {
      display: 'none',
    },
    '& li': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  options: {
    fontSize: 16,
    paddingTop: 6,
    paddingBottom: 6
  },
  popupIndicator: {
    color: theme.palette.primaryLight,
  },
  formControl: {
    maxWidth: ({ fullWidth }) => (fullWidth ? '100%' : 500),
    display: 'block',
    '& .MuiInputBase-root': {
      overflow: 'hidden',
      color: theme.palette.black,
      '& fieldset legend': {
        fontSize: 11.4,
      },
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.primaryLight,
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: theme.palette.negative,
    },
  },
  textField: {
    marginTop: ({ marginTop }) => (marginTop || theme.spacing(1)),
    marginBottom: ({ marginBottom }) => (marginBottom || theme.spacing(4)),
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 14px) scale(1)'
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.85)',
      color: ({ error }) => (error ? theme.palette.negative : theme.palette.black30),
      zIndex: 0,
    },
    '& .MuiInputLabel-shrink.Mui-focused': {
      color: ({ error }) => (error ? theme.palette.negative : theme.palette.primaryLight),
    },
    '& .MuiOutlinedInput-root': {
      minHeight: 40,
    },
    '& .MuiInputBase-input': {
      fontSize: 16
    }
  },
  readOnly: {
    '& .MuiInputBase-root': {
      paddingLeft: '0px !important',
      '& .MuiInputBase-input': {
        paddingLeft: ({ isBordered }) => (
          isBordered ? '4px !important' : '0px !important'
        ),
      },
    },
    '& .MuiInputLabel-shrink': {
      transform: ({ isBordered }) => (
        isBordered ? 'translate(14px, -6px) scale(0.85)' : 'translate(0px, -6px) scale(0.85)'
      ),
    },
    marginBottom: theme.spacing(2),
    '& .MuiSelect-root': {
      cursor: 'default',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: ({ isBordered }) => (
        isBordered ? `1px solid ${theme.palette.black20}` : 'none'
      ),
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.black30,
    },
    '& .MuiFormLabel-root': {
      paddingLeft: '0px !important',
    },
    '& .MuiSvgIcon-root': {
      display: 'none'
    }
  },
  required: {
    '&::after': {
      display: 'block',
      content: ({ t }) => ({ requiredMessage }) => (`'${requiredMessage || t('REQUIRED')}'`),
      position: 'absolute',
      background: theme.palette.white,
      opacity: ({ disabled }) => (disabled ? 0.5 : 1),
      top: 'calc(100% + 2px)',
      left: 16,
      fontFamily: 'Roboto',
      fontSize: 10,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.33,
      letterSpacing: 'normal',
      color: ({ error }) => (error ? theme.palette.negative : theme.palette.black30),
    },
  },
  select: {
    lineHeight: '19px',
    display: 'block',
    '& .MuiSelect-select:focus': {
      background: 'none'
    },
    '& .MuiSelect-outlined': {
      fontSize: 16
    },
    '& .MuiInputBase-input': {
      width: 'inherit'
    },
  },
  withoutVerticalPadding: {
    '& .MuiSelect-root': {
      paddingTop: 0.5,
      paddingBottom: 0.5,
      height: 40,
    },
  },
  selectPaper: {
    transition: 'none !important',
    border: `2px solid ${theme.palette.primaryLight}`,
    borderTop: 0,
    marginTop: -3,
    borderRadius: '0 0 4px 4px',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14)'
  },
  selectItem: {
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 16,
    display: 'block',
  },
  formControlRadio: {
    marginTop: ({ marginTop }) => (marginTop || theme.spacing(1)),
    marginBottom: ({ marginBottom }) => (marginBottom || theme.spacing(3)),
  },
  formLabelRadio: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: '2px 0 20px 2px',
    color: theme.palette.black,
    '&.Mui-focused': {
      color: theme.palette.black,
    }
  },
  labelRadio: {
    padding: '2px 8px',
    borderRadius: 2,
    '&:hover': {
      background: theme.palette.warmWhite,
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 16,
    }
  },
  radio: {
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
    }
  },
  formControlDate: {
    maxWidth: 500,
    marginBottom: theme.spacing(4),
    '& .MuiFormControl-marginDense': {
      marginTop: 0,
      marginBottom: 0,
    },
    '& .MuiInputBase-root': {
      height: 40,
      fontSize: 16,
      '& fieldset legend': {
        maxWidth: 0,
      },
    },
    '& .MuiInputLabel-outlined': {
      fontSize: '13.7143px',
      transform: 'translate(14px, 14px) scale(1)'
    },
    '& .MuiInputLabel-shrink': {
      paddingLeft: 6,
      paddingRight: 6,
      transform: 'translate(14px, -6px) scale(0.75)'
    },
  },
  formControlDateIsRequired: {
    '& .MuiInputBase-root': {
      '&::after': {
        display: 'block',
        content: ({ t }) => (`'${t('REQUIRED')}'`),
        position: 'absolute',
        background: theme.palette.white,
        top: 'calc(100% + 2px)',
        left: 16,
        fontFamily: 'Roboto',
        fontSize: 12,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: 'normal',
        color: ({ error }) => (error ? theme.palette.negative : theme.palette.black30),
      },
    },
  },
  textArea: {
    display: 'block',
    width: '100%',
    resize: 'none',
    fontSize: 16,
    lineHeight: '24px',
    padding: '10px 14px',
    '&[readonly]': {
      border: 'none',
      outline: 'none'
    }
  },
  autocompleteChip: {
    maxWidth: '100%',
    '&.MuiChip-root': {
      height: 'auto',
      padding: '8px 0px',
    },
    borderRadius: 4,
    background: theme.palette.lightSelection,
    margin: theme.spacing(0.5),
    fontSize: 16,
    border: 'none',
    flexDirection: 'row-reverse',
    '& .MuiChip-label': {
      paddingLeft: 4,
      whiteSpace: 'normal',
      // overflowWrap: 'anywhere',
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.blue,
      marginRight: 0,
      marginLeft: 5,
    }
  },
  autocompleteInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    minWidth: 120,
    display: 'block',
    '& .MuiInputBase-root': {
      overflow: 'hidden',
      color: ({ error }) => (error ? theme.palette.negative : theme.palette.black),
      cursor: 'pointer',
      '& fieldset legend': {
        fontSize: 11.4,
      },
    },
    '& .MuiFormLabel-root': {
      zIndex: 0,
    },
    '& .Mui-disabled': {
      cursor: 'default',
      opacity: ({ disabled }) => (disabled ? 0.5 : 1),
      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: ({ error }) => (`${(error) ? theme.palette.negative : theme.palette.black30}`),
      }
    },
  },
  styledLikeButton: {
    caretColor: 'transparent',
    '& input': {
      '&::selection': { background: 'transparent' },
      userSelect: 'none',
      textAlign: 'left',
      cursor: 'pointer',
    },
  },
  autocompletePlaceholder: {
    padding: '6.5px 10px',
    position: 'absolute',
    top: 2,
    left: 2,
    bottom: 2,
    width: 'calc(100% - 4px)',
    backgroundColor: 'transparent',
    fontSize: 16,
    color: theme.palette.black30,
  },
  hidden: {
    display: 'none',
  },
  inputClearIcon: {
    color: theme.palette.primaryLight
  },
}));

export default useStyles;
