import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NotFoundBanner } from 'Src/assets/banners/404.svg';

import useStyles from './styles';

const NotFound = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.notFoundContent}>
      <NotFoundBanner />
      <div className={classes.subtitle}>{t('PAGE_NOT_FOUND')}</div>
      <div className={classes.text}>
        <p>{t('IT_MAY_HAVE_BEEN_DELETED_OR_AN_INCORRECT_ADDRESS_WAS_ENTERED')}</p>
      </div>
    </div>
  );
};

export default NotFound;
