const TRANSLATIONS_ROLE_NAME = {
  ABOUT_THE_PRESENCE_OF_THIS_REASON_CODE_IN_SAP_ERP: 'о наличии данного кода причины в SAP ERP',
  ACTIONS_BY_TAG: 'Действия по метке',
  ACTIONS: 'Действия',
  ACTIVE_KEY: 'Активный ключ',
  ACTIVE_PLURAL: 'Активные',
  ACTUAL_LEAD_TIME: 'Фактическое время выполнения',
  ACTUAL_TIME: 'Фактическое время',
  ACTUAL: 'Актуальные',
  ADD_ADDITIONAL_OPERATION: 'Введите дополнительную операцию',
  ADD_CURRENT_USER_HELPER_TEXT_ADDITIONAL: 'Нажмите “Добавить” и присвойте пользователю роль и атрибуты.',
  ADD_CURRENT_USER_HELPER_TEXT_MAIN: 'Этот пользователь отсутствует в системе.',
  ADD_CURRENT_USER: 'Добавьте этого пользователя',
  ADD_CUSTOM_TASK: 'Добавите нестандартное задание',
  ADD_EMPLOYEE: 'Добавить сотрудника',
  ADD_EQUIPMENT: 'Добавить оборудование',
  ADD_ITEM: 'Добавить запись',
  ADD_ONE_OR_MORE_POSITIONS: 'Добавьте одну или несколько позиций',
  ADD_OPERATION_KEY: 'Добавить ключ операций',
  ADD_OPERATION: 'Добавить операцию',
  ADD_OPERATIONS: 'Добавить операции',
  ADD_POSITION: 'Добавить позицию',
  ADD_REASON: 'Добавить причину',
  ADD_TAG: 'Добавить метку',
  ADD_USER: 'Добавить пользователя',
  ADD_WORKPLACE: 'Добавить рабочее место',
  ADD: 'Добавить',
  ADDING_AN_OPERATION: 'Добавление операции',
  ADDING_POSITIONS: 'Добавление позиции',
  ADDITIONAL_OPERATIONS: 'Дополнительные операции',
  ALL_EMPLOYEES_LIST: 'Список всех сотрудников',
  ALL_PARAMS: 'Все параметры',
  ALL_TASKS: 'Все задачи',
  ALL: 'Все',
  ALLOWED_VALUE: 'Допустимое значение',
  ALTERNATIVE_SCENARIO_OFF: 'Выключен альтернативный сценарий',
  ALTERNATIVE_SCENARIO_ON: 'Включен альтернативный сценарий',
  ALTERNATIVE_SCENARIO_SHORT: 'Альт. Сценарий',
  ALTERNATIVE_SCENARIO: 'Альтернативный сценарий',
  AMOUNT_OF_PARALLEL_OPERATIONS_SHORT: 'Кол-во операций для параллельного выполнения',
  AMOUNT_OF_PARALLEL_OPERATIONS: 'Количество операций для параллельного выполнения',
  AN_URGENT_TASK_WILL_BE_CREATED_BASED_ON_THE_DEFECT: 'По дефекту будет созданна Акутальная задача.',
  APPLY: 'Применить',
  APPOINTMENT_OF_THE_PERFORMER: 'Назначение исполнителя',
  APPROVE_A_TASK: 'Согласование задачи',
  APPROVE: 'Согласовать',
  APPROVERS_COMMENTS: 'Комментарии согласующих',
  ARCHIVE: 'Архив',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_TASK_ROUTE: 'Уверены что хотите удалить маршрут?',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_TASK: 'Уверены что хотите удалить задачу?',
  ASSIGN_PERFORMER: 'Назначить исполнителя',
  ASSIGN_PERFORMERS: 'Назначить исполнителей',
  ASSIGN_PRODUCER: 'Назначить производителем',
  ASSIGN_RESPONSIBLE: 'Назначить ответственным',
  ASSIGN: 'Назначить',
  ASSIGNED_ENTITY: 'Назначена',
  ASSIGNED: 'Назначенные',
  ASSIGNEES: 'Исполнители задачи',
  ATTENTION_TITLE: 'Внимание!',
  AUART_TYPE: 'Вид заказа (AUART)',
  AUTHOR: 'Автор',
  AUTO_TASKS: 'Задачи (автоматические)',
  AUTOMATED_TASK_NAME: 'Название автозадачи',
  AUTOMATED_TASK_SUCCESSFULLY_CREATED: 'Автозадача успешно создана',
  AUTOMATIC_TASK_CREATION_CONFIGURATIONS: 'Конфигурации автоматического создания задачи',
  AUTOTASK: 'Автозадача',
  AUTOTASKS: 'Автозадачи',
  BLOCKED_USER_SUBTITLE: 'Обратитесь к администратору.',
  BLOCKED_USER_TITLE: 'К сожалению у Вас нет доступа',
  BLOCK: 'Блок',
  BLOCKED_PLURAL: 'Заблокированые',
  BRIGADE_COMPOSITION: 'Состав бригады (минимум 2 человека)',
  BRIGADE_CREATION: 'Создание бригады',
  BRIGADE_HAS_ASSIGNED_TASK: 'На бригаду назначена задача',
  BRIGADE_STAFF: 'Состав бригады',
  BRIGADE_SUCCESSFULLY_CREATED: 'Бригада успешно создана!',
  BRIGADE_SUCCESSFULLY_REMOVED: 'Бригада успешно удалена!',
  BRIGADE_TITLE_ACCUSATIVE: 'бригаду',
  BRIGADE_TITLE_GENITIVE: 'бригады',
  BRIGADE_TITLE: 'Название бригады',
  BRIGADE: 'Бригада',
  BRIGADES: 'Бригады',
  BUILD_REPORT: 'Построить рапортичку',
  CANCEL: 'Отменить',
  CANCELED: 'Отклоненные',
  CANCELLATION: 'отмена',
  CANT_CHANGE_STATE: 'Изменение состояния невозможно.',
  CANT_CREATE_TASK_ROUTE: 'Создание маршрута невозможно.',
  CANT_CREATE: 'Создание невозможно.',
  CANT_DELETE_BRIGADE: 'Не удалось удалить бригаду',
  CANT_DELETE_CHECKPOINT: 'Не удалось удалить метку',
  CANT_DELETE_INSPECTION_INVALID_DATE: 'Наступила дата выполнения. Обход может находится в работе.',
  CANT_DELETE_INSPECTION_STATUS_CHANGED: 'Данный обход изменил статус. Обновите страницу.',
  CANT_DELETE_INSPECTION: 'Удаление обхода невозможно.',
  CANT_SAVE: 'Сохранение невозможно.',
  CANT_UPDATE_TASK_ROUTE: 'Редактирование маршрута невозможно.',
  CANT_UPDATE: 'Редактирование невозможно.',
  CANT_VIEW_ROUTE: 'Невозможно отобразить маршрут.',
  CHANGE_PERFORMER: 'Изменить исполнителя',
  CHANGE_THE_NAME_AND_SAVE_THE_TASK_AGAIN: 'Измените название и сохраните задачу повторно.',
  CHANGES_SUCCESSFULLY_APPLIED: 'Изменения успешно сохранены',
  CHANGING_THE_POWER_UNIT_WILL_DELETE_THE_STORED_TAGS: 'Смена энергоблока приведет к удалению сохраненных меток.',
  CHECK_THE_CORRECTNESS_OF_THE_ENTERED_DATA: 'Проверьте правильность введенных данных',
  CHECKPOINT_NAME_ALREADY_EXISTS: 'Метка с таким названием уже существует.',
  CHECKPOINT_ON_PAUSE: 'Метка на паузе',
  CHECKPOINT_REJECTED: 'Метка отклонена',
  CHECKPOINT_SUCCESSFULLY_CREATED: 'Метка успешно создана!',
  CHECKPOINT_SUCCESSFULLY_REMOVED: 'Метка успешно удалена!',
  CHOOSE_BRIGADE: 'Выберите бригаду',
  CHOOSE_BUSINESS_TYPE_FOR_FURTHER_WORK: 'Выберите тип бизнеса для дальнейшей работы',
  CHOOSE_CATEGORY_TO_VIEW: 'Выберите категорию для просмотра',
  CHOOSE_CHECKPOINT: 'Укажите метку',
  CHOOSE_DEPARTMENT_AND_ROUTE: 'Укажите участок и маршрут для обхода',
  CHOOSE_DEPARTMENT: 'Выберите участок',
  CHOOSE_EMPLOYEE_FROM_LIST: 'Выберите сотрудников из списка',
  CHOOSE_EMPLOYEE: 'Выберите исполнителя',
  CHOOSE_EQUIPMENT_FROM_LIST: 'Выберите оборудование из списка',
  CHOOSE_EQUIPMENT_UNIT_FROM_LIST: 'Выберите узел из списка',
  CHOOSE_PERFORMERS: 'Выберите исполнителей',
  CHOOSE_POWER_UNIT: 'Укажите энергоблок',
  CHOOSE_REASON: 'Выберите причину',
  CHOOSE_RESPONSIBLE: 'Выберите исполнителя!',
  CHOOSE_ROUTE: 'Выберите маршрут для обхода',
  CHOOSE_TECHNICAL_PLACE: 'Выберите техническое место',
  CHOOSE: 'Укажите',
  CHOSEN_EMPLOYEES_LIST: 'Список выбранных сотрудников',
  CHOSEN_EQUIPMENT: 'Выбранное оборудование',
  CLEAR_NESTING: 'Очистить вложенность',
  CLEAR: 'Очистить',
  CLOSE: 'Закрыть',
  CODE_IS_PRESENT_BUT_NOT_SCANNED: 'Код присутствует но не сканируется',
  CODE: 'Код',
  COLLAPSE_ALL: 'Раскрыть все',
  COLLAPSE: 'Свернуть',
  COMMENT: 'Комментарий',
  COMMENTS: 'Комментарии',
  COMMON: 'Общие',
  COMPLETED_ENTITY: 'Выполнена',
  COMPLETED_INSPECTION: 'Отчет по завершенным обходам за период',
  COMPLETED_PARTICIPLE: 'Завершено',
  COMPLETED: 'Завершенные',
  COMPLETION_DATE_SHORT: 'Дата завершения',
  COMPLETION_DATE: 'Дата окончания выполнения',
  CONFIRM_DELETING_AUTOTASK: 'После удаления задачи автоматическое создание будет прекращено.',
  CONFIRM_DELETING_MESSAGE: 'Вы уверены что хотите удалить',
  CONFIRM_DELETING_PARAMETER_MESSAGE: 'Желаете удалить данный параметр?',
  CONFIRM_INFINITIVE: 'Подтвердите',
  CONFIRM_MATERIAL_VALUES: 'Согласовать ТМЦ',
  CONFIRM: 'Подтвердить',
  CONFIRMATION_OF_PRESENCE_OR_ABSENCE: 'Подтвержение налич./отсутств.',
  CONFIRMED: 'Согласована',
  CONTROL_RESULT: 'Результат контроля',
  CONTROL: 'Контроль',
  CONTROLLER: 'Контролер',
  COPIED: 'Скопировано!',
  COPY_TAG_CODE: 'Скопировать код метки',
  CREATE_A_TASK: 'Создать задачу',
  CREATE_AUTOTASK: 'Создать автозадачу',
  CREATE_BRIGADE_ACTION: 'Создать бригаду',
  CREATE_BRIGADE_WITH_BUTTON: 'Создайте бригаду с помощью кнопки “Создать бригаду”',
  CREATE_INSPECTION: 'Создать обход',
  CREATE_PLANNER_TASK: 'Создать задание',
  CREATE_REPORT: 'Создать рапортичку',
  CREATE_ROUTE: 'Создать маршрут',
  CREATE_TAG: 'Создать метку',
  CREATE_VOLUME_PARAMETER: 'Создайте параметры объема!',
  CREATE: 'Создать',
  CREATED_BRIGADE: 'Созданная бригада',
  CREATED_TASK_STATUS: 'Статус созданной задачи',
  CREATED_TASK: 'Созданная задача',
  CREATING_ITEM: 'Добавление записи',
  CREATION_DATE_TIME: 'Дата/время создания',
  CUSTOM_TASK: 'Нестандартное задание',
  DAILY: 'Ежедневно',
  DATA_TYPE: 'Тип данных',
  DATE_AND_TIME: 'Дата и время',
  DATE_CANNOT_BE_IN_FUTURE_TENSE: 'Дата не может быть в будущем времени',
  DATE_CANNOT_BE_IN_PAST_TENSE: 'Дата не может быть в прошедшем времени',
  DATE_COMMA_TIME: 'Дата время',
  DATE_OF_COMPLETION: 'Дата выполнения',
  DATE_OF_CREATION: 'Дата создания',
  DATE: 'Дата',
  DEFECT_ATRIBUTES: 'Атрибуты дефекта',
  DEFECT_CODE_NAME: 'Код неисправности',
  DEFECT_CODE: 'Код дефекта',
  DEFECT_DATE: 'Дата и время обнаружения',
  DEFECT_EDITING: 'Редактирование дефекта',
  DEFECT_FIXATION: 'Фиксация дефектов',
  DEFECT_GROUP: 'Группа дефектов',
  DEFECT_NAME: 'Название дефекта',
  DEFECT_REJECTED_SUCCESSFULLY: 'Дефект успешно отклонен!',
  DEFECT_RESOLVED_BY: 'Дефект устранил',
  DEFECT_SOURCE_STATUS: 'Статус источника',
  DEFECT_SOURCE: 'Источник дефекта',
  DEFECT_STATUS: 'Статус дефекта',
  DEFECT_SUCCESSFULLY_UPDATED: 'Дефект успешно изменен',
  DEFECT_WAS_SUCCESSFULLY_SENT_TO_WORK: 'Дефект успешно отправлен в работу!',
  DEFECT_WILL_BE_SENT_TO_THE_REJECTED_TAB: 'Дефект будет отправлен во вкладку “Отклоненные”.',
  DEFECTS_FOR_RE_REGISTRATION: 'Дефекты для повторной регистрации',
  DEFECTS_GROUP: 'Группа дефектов ',
  DEFECTS: 'Дефекты',
  DELETE_CHECKPOINT: 'Удалить метку',
  DELETE_EQUIPMENT_TASK: 'Удалить задание',
  DELETE_EQUIPMENT: 'Удалить оборудование',
  DELETE_POSITION: 'Удалить позицию',
  DELETE: 'Удалить',
  DELETING_A_TASK_ROUTE: 'Удаление маршрута',
  DELETING_A_TASK: 'Удаление задачи',
  DELETING_CHECKPOINT_FROM_TASK: 'Удаление метки из задания',
  DELETING_EQUIPMENT_FROM_CHECKPOINT: 'Удаление оборудования из метки',
  DELETING_PLANNER_TASK: 'Удаление задания',
  DELETING_TASK_FROM_CHECKPOINT: 'Удаление задания из метки',
  DELETING: 'Удаление',
  DEPARTMENT: 'Участок',
  DEPARTMENT_ACCUSATIVE: 'Участок',
  DESCRIPTION: 'Описание',
  DETAILED_INSPECTION_REPORT: 'Детальный отчет по завершенным обходам за период',
  DEVICE_RECEIVED_DATE: 'Дата получения задачи на моб. устройстве',
  DEVICE_RECEIVED_TIME: 'Время получения задачи на моб. устройстве',
  DEVICE_TASK_END_DATE: 'Дата окончания задачи на моб. устройстве',
  DEVICE_TASK_END_TIME: 'Время окончания задачи на моб. устройстве',
  DICTIONARIES: 'Справочники',
  DISPLAY_ON_DEVICE_BY_KEY: 'Отображение на устройстве по ключу',
  DO_YOU_WANT_TO_DELETE_WORKPLACE: 'Желаете удалить рабочее место?',
  DOCUMENTATION: 'Документация',
  DOWNLOAD_REPORT: 'Загрузить отчет',
  DOWNLOAD: 'Загрузить',
  COMPANY_NAME: 'ДТЕК',
  DUE_DATE: 'Дата выполнения',
  DURATION: 'Продолжительность',
  EDIT_CHECKPOINTS_TASKS: 'Редактировать задания по метке',
  EDIT_CUSTOM_TASK: 'Редактировать нестандартное задание',
  EDIT_EQUIPMENT_TASK: 'Редактировать задание по оборудованию',
  EDIT_EQUIPMENT_TASKS: 'Редактировать задания по оборудованию',
  EDIT_POSITION: 'Редактировать позицию',
  EDIT: 'Редактировать',
  EDITING_A_TASK: 'Редактирование задачи',
  EDITING_ITEM: 'Редактирование записи',
  EDITING_POSITIONS: 'Редактирование позиции',
  EDITING_PROHIBITED: 'Редактирование запрещено.',
  EDITING: 'Редактирование',
  EMPLOYEE_ADDING: 'Добавить сотрудников',
  EMPLOYEE: 'Исполнитель',
  EMPTY_BRIGADE_STAFF_LIST: 'Список мастеров пуст!',
  EMPTY_BRIGADES_LIST: 'В вашем списке еще нет бригад',
  EMPTY_OPERATION_CONFIRMATION_HISTORY: 'История согласования ТМЦ пуста',
  EMPTY_PERFORMERS_LIST_SUBTITLE: 'К выбранному участку не закреплен ни один исполнитель.',
  EMPTY_PERFORMERS_LIST: 'Список исполнителей пуст',
  EMPTY_RESULTS_SUBTITLE: 'Вы можете добавить новую запись.',
  EMPTY_RESULTS_TITLE: 'Записи отсутствуют',
  END_DATE_CANNOT_BE_LESS_THAN_START_DATE: 'Дата окончания не может быть меньше даты начала',
  END_DATE_CANNOT_BE_PAST_TENSE: 'Дата окончания не может быть прошедшим временем',
  END_DATE: 'Дата окончания',
  ENTER_CODE_OR_DEFECT_NAME: 'Введите код или название дефекта',
  ENTER_REJECTION_REASON_BY_CONTROLLER: 'Укажите причину отклонения задачи контролером',
  ENTER_REJECTION_REASON: 'Укажите причину отклонения задачи',
  ENTER_REQUIRED_MEASUREMENT_UNIT_NAME: 'Введите название необходимой ЕИ',
  ENTER_THE_NAME_OR_EMAIL: 'Введите ФИО или почту сотрудника...',
  ENTER_THE_OPERATION_NAME: 'Введите дополнительную операцию',
  ENTERPRISE: 'Предприятие',
  EQUIPMENT_ADDING: 'Добавление оборудования',
  EQUIPMENT_AND_TYPE_OF_REPAIR: 'Оборудование и вид ремонта',
  EQUIPMENT_CHOISE: 'Выбор оборудования',
  EQUIPMENT_EDITING: 'Редактирование оборудования',
  EQUIPMENT_ENTITY_SHORT: 'ЕО',
  EQUIPMENT_LEVEL: 'ТМ Уровень',
  EQUIPMENT_UNITS: 'Узлы',
  EQUIPMENT: 'Оборудование',
  EQUIPMENT_ACCUSATIVE: 'Оборудование',
  ERROR_DELETE_WORKPLACE: 'Ошибка удаления рабочего места',
  ERROR: 'Ошибка',
  EXECUTION_APPROVAL_STATUS: 'Статус согласования выполнения',
  EXECUTION_PERIOD: 'Период выполнения',
  EXECUTION_RESULT: 'Результат выполнения',
  EXECUTION_TIME_MIN: 'Время выполн. (мин)',
  EXISTING_EQUIPMENT: 'Существующее оборудование',
  EXISTING_PARAMETER_NAME: 'Название параметра уже существует',
  EXIT: 'Выйти',
  EXPAND: 'Развернуть',
  EXPECTED_START_DATE_TOOLTIP: 'Дата получения задачи на моб. устройстве',
  EXPECTED_START_DATE: 'Дата выполнения',
  EXPORT_USERS_ERROR: 'Ошибка экспорта пользователей',
  EXPORT: 'Экспортировать',
  FACT_DURATION_H: 'Фактическая продолжительность ч',
  FACT_END_TIME_LONG: 'Фактическое время окончание',
  FACT_END_TIME: 'Факт. время окончания',
  FACT_FINISH_DATE: 'Дата фактического завершения',
  FACT_NUMBER_OF_PEOPLE: 'Факт. кол-во людей',
  FACT_QUANTITY_CANNOT_BE_MORE_THAN_PLANNED: 'Фактическое кол-во не может быть больше планового.',
  FACT_QUANTITY_SHORT_UPDATED: ' Факт. кол-во (измененное)',
  FACT_QUANTITY_SHORT: 'Факт. кол-во',
  FACT_QUANTITY: 'Фактическое кол-во',
  FACT_START_DATE: 'Дата фактического начала',
  FACT_START_TIME: 'Факт. время начала',
  FACT_TIME: 'Факт. время',
  FAIL_TO_LOAD_AUTOTASKS: 'Не удалось загрузить автозадачи',
  FAIL_TO_LOAD_FILTERS: 'Не удалось загрузить фильтры',
  FAIL_TO_LOAD_ROUTES: 'Не удалось загрузить маршруты',
  FAILED_CHECKPOINT_SCANNING: 'Неуспешное сканирование метки',
  FAILED_TO_CHECK: 'Не удалось осуществить проверку',
  FAILED_TO_DISPLAY_DEFECTS_FOR_THE_SELECTED_GROUP: 'Не удалось отобразить дефекты по выбранной группе.',
  FAQ: 'ЧАВО',
  FILL_ALL_REQUIRED_FIELDS: 'Заполните все обязательные поля.',
  FILL_DATA_TO_LOAD_REPORT: 'Укажите атрибуты для загрузки отчета',
  FILTER_BY_EQUIPMENT: 'Фильтр по оборудованию',
  FILTRATION: 'Фильтрация',
  FIND_MEASUREMENT_UNIT: 'Найти единицу измерения',
  FINISH_DATE_CANNOT_BE_GREATER_THAN_START_DATE: 'Дата окончания не может быть больше даты начала',
  FINISH_TIME: 'Время окончания',
  FIRST_NAME: 'Имя',
  FIST_PAGE: 'Первая страница',
  FORM_HAS_ERROR: 'Форма содержит ошибки',
  FROM_SHORT: 'с',
  FROM: 'от',
  FULL_NAME: 'ФИО',
  FUNCTIONAL_PLACEMENT: 'Функциональное размещение',
  GENERAL_COMMENTS: 'Общие коментарии',
  GENERAL_PARAMS: 'Общие параметры',
  GENERAL_SETTINGS: 'Общие настройки',
  GO_BACK: 'Назад',
  GO_TO_DEFECTS_DICTIONARY_LIST: 'К просмотру справочника дефектов',
  GO_TO_DETAILS: 'Перейти к деталям',
  GO_TO_MEASUREMENT_REPORT_LIST: 'К рапортичкам',
  GO_TO_PRESENCE_CONTROL: 'К контролю присутствия',
  GO_TO_SETTINGS: 'К настройкам',
  H: 'Ч',
  HELPER_TEXT_DAYS_INTERVAL: 'Не может быть больше чем разница между полями «Дата окончания» и «Дата Начала»',
  HELPER_TEXT_PLANDURATION_STARTDELAY_HOURSINTERVAL: 'С учетом отсрочки старта задачи «Время выполнения» не должно превышать «Интервал»',
  HELPER_TEXT_PLANDURATION: 'Не может быть больше чем «Интервал»',
  HELPER_TEXT_START_TIME_CURRENT_TIME: 'Должно быть позже текущего времени',
  HELPER_TEXT_START_TIME_IS_EXPIRED: 'Пожалуйста проверьте «Время начала» оно должно быть позже текущего времени',
  HELPER_TEXT_STARTDELAY: 'Не может быть больше чем «Интервал»',
  HIDE_COMMENTS: 'Скрыть комментарии',
  HIDE_EQUIPMENT_MEASUREMENTS: 'Спрятать измерения по оборудованию',
  HIDE_EQUIPMENT: 'Спрятать оборудование',
  HIERARCHY: 'Иерархия',
  HIGH_PRIORITY: 'Высокий приоритет',
  HIGH: 'Высокий',
  HISTORY: 'История',
  IDENTIFIED_DEFECTS: 'Выявленные дефекты',
  IDENTIFIED: 'Выявил',
  IMPORT_USERS_ERROR: 'Ошибка импорта пользователей',
  IMPORT: 'Импортировать',
  IN_PROGRESS: 'В работе',
  IN_RESERVE: 'В резерве/Пуск',
  IN_THE_TIME_RANGE: 'во временнном дипазаоне',
  INACTIVE_KEY: 'Неактивный ключ',
  INDEFINITE: 'Бессрочно',
  INFORMATION: 'Информация',
  INITIAL_FACT_QUANTITY: 'Исходное фактическое кол-во',
  INPUT_EMPTY_VALUE: '—',
  INSPECTION_ATTRIBUTES: 'Атрибуты обхода',
  INSPECTION_CREATED: 'Обход успешно создан!',
  INSPECTION_CREATION: 'Создание обхода',
  INSPECTION_DELETED: 'Обход успешно удален!',
  INSPECTION_DELETING_MESSAGE: 'Уверены что хотите удалить обход?',
  INSPECTION_DELETING: 'Удаление обхода',
  INSPECTION_ROUTE: 'Маршрут обхода',
  INSPECTION_STATUSES: 'Отчет по обходам за период',
  INSPECTION_TITLE_NOMINATIVE: 'обход',
  INSPECTION_UPDATED: 'Обход успешно обновлен!',
  INSPECTION_VIEW: 'Просмотр обхода',
  INSPECTIONS_LIST: 'Обходы',
  INSPECTIONS: 'Осмотры',
  INTERVAL_DAYS: 'Интервал (дней)',
  INTERVAL_HOURS: 'Интервал (ч)',
  INVALID_FORMAT: 'Неверный формат',
  INVALID_QUANTITY_SPECIFIED: 'Указано некорректное кол-во',
  IS_NOT_VISIBLE_IN_MOBILE_APP: 'Не отображается в Мобильном приложении',
  IS_REQUIRED_QR_CODE: 'Требуется сканирование QR кода',
  IS_TASK_ASSIGNED: 'На бригаду назначена задача',
  IS_VISIBLE_IN_MOBILE_APP_SHORT: 'Отображение в МП',
  IS_VISIBLE_IN_MOBILE_APP: 'Отображается в Мобильном приложении',
  IT_MAY_HAVE_BEEN_DELETED_OR_AN_INCORRECT_ADDRESS_WAS_ENTERED: 'Возможно она была удалена или введен неверный адрес.',
  ITEMS_COUNT_LABEL_SHORT: 'шт',
  JOB: 'Задание',
  KEY_ALREADY_EXISTS: 'Такой ключ уже существует.',
  KEY: 'Ключ',
  LAST_EDIT: 'Последнее редактирование',
  LAST_EDITING_TIME: 'Последнее редактирование',
  LAST_EXECUTION_RESULT: 'Результат посл. запуска',
  LAST_NAME: 'Фамилия',
  LAST_PAGE: 'Последняя страница',
  LEVEL: 'Уровень',
  LINK_TO_LIBRARY: 'Путь к библиотеке',
  LIST_DISPLAY: 'Отображение списком',
  LIST_IS_EMPTY: 'Список пуст',
  MachinesAndMechanisms: 'Машины и механизмы',
  MAKING_CHANGES_MAKE_SURE: 'При внесении изменений убедитесь',
  MANUAL_DATA_ENTRY: 'Ручной ввод данных',
  MAP_LINK: 'Ссылка на карты',
  MATERIAL_VALUE_SHORT: 'ТМЦ',
  MATERIAL_VALUES_BY_OPERATIONS: 'ТМЦ по выполненным операциям',
  MATERIAL_VALUES_EDITING: 'Редактирование ТМЦ',
  MATERIAL_VALUES_SUCCESSFULLY_CONFIRMED_SENT_TO_THE_ECONOMIST_FOR_CONFIRMATION: 'ТМЦ успешно согласованы и отправлены Экономисту ИЭС на дальнейшее согласование.',
  MATERIAL_VALUES_SUCCESSFULLY_CONFIRMED_SENT_TO_THE_SAP_SYSTEM: 'ТМЦ успешно согласованы и отправлены в систему SAP.',
  MATERIAL_VALUES_SUCCESSFULLY_CONFIRMED_SENT_TO_THE_SPECIALIST_FOR_CONFIRMATION: 'ТМЦ успешно согласованы и отправлены Специалисту СДКР на дальнейшее согласование.',
  MATERIAL_VALUES_WERE_SUCCESSFULLY_RETURNED_TO_THE_MASTER_FOR_REVISION: 'ТМЦ успешно возвращены Мастеру на доработку',
  MATERIALS: 'Материалы',
  MAX_AMOUNT: 'Макс. кол-во',
  MAX_DATE_VALIDATION_MESSAGE: 'Дата не должна быть больше максимальной даты',
  MAX_DIST_TO_OBJECT: 'Макс. расстояние до объекта',
  MAX_OPERATIONS_AMOUNT: 'Макс. кол-во операций',
  MAX_PHOTOS_COUNT: 'Макс. кол-во фотографий',
  MAYBE_ROUTE_WAS_DELETED: 'Возможно данный маршрут был удален.',
  MEASUREMENT_POINTS: 'Точки измерений',
  MEASUREMENT_POINTS_ACCUSATIVE: 'Точки измерений',
  MEASUREMENT_REPORT_LIST: 'Рапортички',
  MEASUREMENT_UNIT: 'Единица измерения',
  MEASUREMENTS_IMPOSSIBLE: 'Невозможно выполнить измерения',
  MEASUREMENTS_UNITS: 'Единицы измерения',
  MEASUREMENTS: 'Измерения',
  MEASUREMENTS_ACCUSATIVE: 'Измерения',
  METERS_UNIT_SHORT: 'м',
  MIN_ALLOWED_SIZE_SHORT: 'Мин. допустимый размер',
  MIN_ALLOWED_SIZE: 'Мин. допустимый размер (Пикс.)',
  MIN_AMOUNT: 'Мин. кол-во',
  MIN_DATE_VALIDATION_MESSAGE: 'Дата не должна быть раньше минимальной даты',
  MIN: 'мин',
  NAME: 'Название',
  NEW_ENTITY: 'Новая',
  NEW: 'Новые',
  NEXT_PAGE: 'Следующая страница',
  NO_ACCESS_MESSAGE: 'К сожалению доступ к данному разделу ограничен пользователю с Вашей ролью.',
  NO_ACCESS_TITLE: 'Доступ ограничен!',
  NO_DEFECTS_FOUND: 'Дефекты не обнаружены',
  NO_DEFECTS: 'Дефекты отсутствуют',
  NO_MEASUREMENTS_FOUND: 'Измерения не обнаружены',
  NO_MEASUREMENTS: 'Показатели отсутствуют',
  NO_OPERATION_KEYS_SETTINGS: 'Настройки ключей операций отсутствуют',
  NO_OPERATIONS_TO_CONFIRM: 'У вас нет операций ожидающих согласования.',
  NO_OPTIONS: 'Нет опций',
  NO_OVERLAY_TECH_OBJECTS_MESSAGE: 'У выбранного Технического места отсутствуют подчинённые технические объекты.',
  NO_OVERLAY_TECH_OBJECTS_TITLE: 'Действие недоступно',
  NO_READINGS: 'Показания отсутствуют',
  NO_REPAIR_TYPES_SELECT_ANOTHER_EQUIPMENT: 'Для выбранного оборудования нет вида ремонта необходимо выбрать другое оборудование',
  NO_RESULTS: 'К сожалению, совпадений не найдено…',
  NO_ROUTE: 'Без маршрута',
  NO_TITLE: 'Название отсутствует',
  NO_WORKPLACES: 'Рабочие места отсутствуют',
  NODE: 'Узел',
  NODE_ACCUSATIVE: 'Узел',
  NOMINATION: 'Наименование',
  NON_STANDARD_OPERATIONS: 'Нестандартные операции',
  NOT_COMPLETED: 'Не выполнена',
  NOT_EDITABLE_MESSAGE: 'Для данной метки есть невыполненные задачи.',
  NOT_POSIBLE_CHANGE_REPORT_MESSAGE: 'После отправки изменение рапортички невозможно.',
  NOT_POSIBLE_CHANGE_TWICE_REPORT_MESSAGE: 'После сохранения изменение рапортички невозможно.',
  NOT_STANDARD_MULTIPLE: 'Нестандартные',
  NOT_STANDARD_TASK_DESCRIPTION: 'Описанеие нестандартного задания',
  NOT_STANDARD_TASK_TYPE: 'Нестандартная задача',
  NOT_VALID_SHIFT_MESSAGE: 'В данный момент сформировать отчет возможно только для',
  NOTHING_FOUND: 'Ничего не найдено',
  NUMBER_OF_TAGS: 'Метки (кол-во)',
  NUMBER: 'Номер',
  NUMERIC_INPUT: 'Числовой ввод',
  OBTAINING_APPROVALS: 'Получение допусков',
  OCCUPATIONAL_SAFETY: 'Охрана труда',
  ON_APPROVAL: 'На утверждении',
  ON_CONTROL: 'На контроле',
  ON_RESCHEDULING: 'На перепланирование',
  ONLY_CYRILLIC_SYMBOLS: 'Допускаются только кириллические символы!',
  ONLY_EDITED: 'Только отредактированные',
  OPEN: 'Открыть',
  OPERATION_CONFIRMATION: 'Подтверждение операции',
  OPERATION_KEY_ADDING: 'Добавление ключа операций',
  OPERATION_KEY_ATTRIBUTES: 'Атрибуты ключа операции',
  OPERATION_KEY_EDITING: 'Редактирование ключа операций',
  OPERATION_KEY_IS_USED_IN_VOLUMES_PARAMS: 'Данный ключ используется в параметрах физ. объема.',
  OPERATION_KEY_SUCCESSFULLY_ADDED: 'Ключ операции успешно добавлен!',
  OPERATION_KEY: 'Ключ операции',
  OPERATION_KEYS: 'Ключи операций',
  OPERATION_ON_PAUSE: 'Операция на паузе',
  OPERATION_REJECTED: 'Операция отклонена',
  OPERATION_WILL_BE_CONFIRMED_AND_SENT_TO_THE_ECONOMIST_FOR_CONFIRMATION: 'Операция будет подтверждена и отправлена Экономисту ИЭС на подтверждение.',
  OPERATION_WILL_BE_CONFIRMED_AND_SENT_TO_THE_SAP_SYSTEM: 'Операция будет подтверждена и отправлена в систему SAP',
  OPERATION_WILL_BE_CONFIRMED_AND_SENT_TO_THE_SPECIALIST_FOR_CONFIRMATION: 'Операция будет подтверждена и отправлена Специалисту СДКР на подтверждение.',
  OPERATION: 'Операция',
  OPERATIONS_CHOISE: 'Выбор операций',
  OPERATIONS_FROM_TC: 'Операции из ТК',
  OPERATIONS_IN_DONE_STATUS: 'Выполненные операции',
  OPERATIONS: 'Операции',
  ORDER_NUMBER: 'Номер заказа ТОРО',
  ORDER_REQUIRED_IS_OFF: 'Обязательный порядок прохождения маршрута выключен',
  ORDER_REQUIRED_IS_ON: 'Включен обязательный порядок прохождения маршрута',
  ORDER_REQUIRED_SHORT: 'Обязательный порядок',
  ORDER_REQUIRED: 'Обязательный порядок прохождения',
  ORDER_TYPE: 'Вид заказа',
  ORG_UNIT_CODE_SHORT: 'Код ОЕ',
  ORG_UNIT_NOT_PROVIDED: 'Предприятие не указано',
  ORG_UNIT_SHORT: 'Орг. единица (ОЕ)',
  OSR_NAME: 'Название ОСР',
  OSR_SETTINGS: 'Настройки для ОСР',
  PAGE_NOT_FOUND: 'Страница не найдена',
  PAGES_COUNT_LABEL: 'страниц',
  PARALLEL_OPERATION_COUNT_RANGE: 'Значение должно быть от 1 до 100',
  PARAMETER_ADD: 'Добавить параметр',
  PARAMETER_CREATE: 'Создание параметра',
  PARAMETER_DELETE: 'Удалить параметр',
  PARAMETER_EDIT: 'Редактировать параметр',
  PARAMETER_EDITING: 'Редактирование параметра',
  PARAMETER_GENITIVE: 'параметра',
  PARAMETER_NAME: 'Название параметра',
  PARAMETER_TYPE: 'Тип параметра',
  PARAMETER: 'Параметр',
  PARTIALLY_DONE: 'Частично выполнена',
  PAUSE_REASON_ATTRIBUTES: 'Атрибуты причины постановки на паузу',
  PAUSE_REASON_CREATION: 'Создание причины постановки на паузу',
  PAUSE_REASON_EDITING: 'Редактирование причины постановки на паузу',
  PAUSE_REASON: 'Причина постановки на паузу',
  PAUSE_REASONS: 'Причины постановки на паузу',
  PAUSE: 'На паузе',
  PAUSING_A_TASK: 'Постановка задания на паузу',
  PENDING: 'В ожидании',
  PEOPLE_CUT: 'чел',
  People: 'Люди',
  PERCENT_SYMBOL: '%',
  PERFORMER: 'Исполнитель',
  PERFORMERS: 'Исполнители',
  PERIOD_FINISH: 'Конец периода',
  PERIOD_START: 'Начало периода',
  PERSONNEL_NUMBER: 'Табельный номер',
  PHOTO_IS_NOT_REQUIRED: 'Не требуется фотофиксация',
  PHOTO_IS_REQUIRED: 'Требуется фотофиксация',
  PHOTO_REQUIREMENT: 'Необходимость фотофиксации',
  PHOTOS_PROCESSING_AND_UPLOADING: 'Обработка и загрузка фото',
  PHOTOS_QUALITY_SHORT: 'Качество фотографий',
  PHOTOS_QUALITY: 'Качество фотографий (%)',
  PHOTOS: 'Фотографии',
  PIXELS: 'Пикселей',
  PL_DEPARTMENT: 'Департамент',
  PLAN_TIME: 'Плановое время',
  PLANNED_COMPLETION_DATE: 'Плановая дата завершения',
  PLANNED_DEFECTS_PRESENCE: 'Наличие плановых дефектов',
  PLANNED_DURATION: 'Плановая продолжительность',
  PLANNED_END_TIME: 'Плановое время окончания',
  PLANNED_LEAD_TIME: 'Плановое время выполнения',
  PLANNED_MEASUREMENTS_PRESENCE: 'Наличие плановых измерений',
  PLANNED_NUMBER_OF_PEOPLE: 'Планир. кол-во людей',
  PLANNED_QUANTITY: 'Плановое кол-во',
  PLANNED_START_DATE: 'Плановая дата начала',
  PLANNED_START_TIME: 'Плановое время начала',
  PLANNED_TIME: 'Планир. время',
  PLANNER_TASK_ATTRIBUTES: 'Атрибуты задания',
  PLANNER_TASK_CREATION: 'Создание задания',
  PLANNER_TASK_SUCCESSFULLY_CREATED: 'Задача планировщика успешно создана',
  PLANNER_TASKS: 'Задачи планировщика',
  PLANNER: 'Планировщик',
  PLANT_CODE: 'Код завода',
  PLANTS_STORAGES_CODES: 'Коды завода/склада',
  POSITION_CODE: 'Код позиции',
  POSITION_NAME: 'Название позиции',
  POSITION: 'Должность',
  POSITIONS_IN_THE_DICTIONARY: 'Позиции в справочнике',
  POWER_UNIT_STATE: 'Состояние энергоблока',
  POWER_UNIT: 'Энергоблок',
  PRESENCE_CONTROL_PARAMS: 'Параметры контроля присутствия',
  PRESENCE_CONTROL: 'Контроль присутствия',
  PRETEXT_FOR: 'по',
  PREVIOUS_ACTIONS: 'Предыдущие действия',
  PREVIOUS_PAGE: 'Предыдущая страница',
  PRIORITY: 'Приоритет',
  PROBLEMS: 'Проблемы',
  PROCEED: 'Продолжить',
  PROCESS_REJECTION_REASON: 'Причина отказа от выполнения',
  PROCESSED: 'Обработал',
  PRODUCER: 'Производитель',
  PROVIDE_OPERATION_RETURNING_REASON: 'Укажите причину возвращения операции',
  QR_CODE_SCANNING: 'Сканирование QR кода',
  QR_CODE: 'QR код',
  QR: 'QR',
  QUANTITY_UPDATED_BY_MASTER: 'Количество указанное Мастером',
  RE_REGISTRATION_SHORT: 'Повт. регистр.',
  REASON_COMMENT: 'Причина/комментарий',
  REASON_FOR_REFUSAL_TO_EXECUTE: 'Причина отказа от исполнения',
  REASON_SUCCESSFULLY_ADDED: 'Причина успешно добавлена!',
  REASON_WITH_SAME_CODE_EXISTS: 'Причина с таким кодом уже сущестует.',
  REASON: 'Причина',
  RECORD_IS_NOT_UNIQUE: 'Запись с такими параметрами уже существет.',
  RECORD_SUCCESSFULLY_ADDED: 'Запись успешно добавлена!',
  REJECT: 'Отклонить',
  REJECTED_ENTITY: 'Отклонена',
  REJECTED: 'Отклонённые',
  REJECTING_DEFECT: 'Отклонение дефекта',
  REJECTION_REASON: 'Причина отклонения',
  REMOVAL_IS_PROHIBITED: 'Удаление запрещено.',
  REMOVE_APPOINTMENT: 'Отменить назначение',
  REMOVE_FROM_LIST: 'Удалить из списка',
  REPAIR_TASK: 'Задача на ремонт',
  REPORT_ALREADY_EXISTS_MESSAGE: 'Рапортичка по таким атрибутам и периоду построения уже создана. Повторное создание невозможно.',
  REPORT_ATTRIBUTES: 'Атрибуты отчета',
  REPORT_BUILDING_ERR_MESSAGE: 'Ошибка валидации при формировании «рапортички»',
  REPORT_BUILDING: 'Построение рапортички',
  REPORT_CAN_BE_EDITET_ONCE: 'Изменить рапортичку можно только 1 раз.',
  REPORT_CREATION_IMPOSSIBLE: 'Создание рапортички невозможно.',
  REPORT_CREATION: 'Создание рапортички',
  REPORT_EDITING: 'Редактирование рапортички',
  REPORT_IS_NOT_EDITED: 'Не удалось отредактировать рапортичку',
  REPORT_IS_NOT_SAVED: 'Не удалось сохранить рапортичку',
  REPORT_IS_READY_TO_LOAD: 'Отчет готов к загрузке!',
  REPORT_LONG: 'Рапортичка',
  REPORT_NO_DATA_MESSAGE: 'По входным критериям в БД отсутствуют данные для построения рапортички',
  REPORT_PERIOD_SHORT: 'Период построения',
  REPORT_PERIOD: 'Период построения отчета',
  REPORT_SAVING: 'Сохранениие рапортички',
  REPORT_SUCCESSFULLY_CREATED: 'Рапортичка успешно создана!',
  REPORT_TYPE: 'Тип отчета',
  REPORT_VALIDATION_MESSAGE: 'Ошибка валидации при сохранении «рапортички».',
  REPORT_WILL_BE_SEND_MESSAGE: 'Рапортичка будет отправлена на Начальника смены КТЦ.',
  REPORT: 'Отчет',
  REPORTS: 'Отчеты',
  REQUEST_BRIGADES_LIST_ERROR: 'При загрузке списка бригад возникла ошибка',
  REQUEST_DEFAULT_ERROR: 'Возникла ошибка',
  REQUEST_LOAD_DEPARTMENTS_ERROR: 'При загрузке департаментов возникла ошибка',
  REQUEST_LOAD_LIST_ERROR: 'При загрузке списка возникла ошибка',
  REQUEST_LOAD_POWER_UNITS_ERROR: 'При загрузке энергоблоков возникла ошибка',
  REQUEST_LOAD_TECH_OBJECTS_ERROR: 'При загрузке технических объектов возникла ошибка',
  REQUEST_LOAD_TECHNICAL_PLACES_ERROR: 'При загрузке ТМ возникла ошибка',
  REQUEST_ORG_UNITS_DICTIONARY_ERROR: 'Ошибка загрузки справочника ОЕ',
  REQUEST_REASONS_DICTIONARY_ERROR: 'Ошибка загрузки справочника причин',
  REQUEST_USER_ROLES_DICTIONARY_ERROR: 'Ошибка загрузки справочника ролей пользователей',
  REQUIRED_FIELD_FOR_DEVICE: 'Обязательный параметр на устройстве',
  REQUIRED: 'Обязательное поле',
  REREGISTRATION_ALLOWED: 'Разрешено для повторной регистрации',
  REREGISTRATION_NOT_ALLOWED: 'Запрещено для повторной регистрации',
  RESCHEDULE: 'Перепланировать',
  RESCHEDULED: 'Перепланирована',
  RESERVE: 'Резерв',
  RESOURCES: 'Ресурсы',
  RESPONSIBLE: 'Ответственный',
  RESTRICTED: 'запрещено.',
  RETURN: 'Вернуть',
  RETURNED: 'Возвращенные',
  RETURNING_OPERATIONS_TO_THE_MASTER: 'Возвращение операции Мастеру',
  REVISION_FOR_MASTER: 'Мастеру на доработку',
  ROLE: 'Роль',
  ROLES: 'Роли',
  ROUTE_ACCUSATIVE: 'маршрута',
  ROUTE_AND_OPERATIONS: 'Маршрут и операции',
  ROUTE_ATTRIBUTES: 'Атрибуты маршрута',
  ROUTE_CREATION: 'Создание маршрута',
  ROUTE_EDITING: 'Редактирование маршрута',
  ROUTE_NAME: 'Название маршрута',
  ROUTE_TAGS: 'Метки в маршруте (№)',
  ROUTE_TECH_OBJECTS: 'Оборудование маршрута',
  ROUTE_VIEW: 'Просмотр маршрута',
  ROUTE: 'Маршрут',
  ROUTES: 'Маршруты',
  RUN: 'Запустить',
  RUNTIME: 'Продолжительность выполнения',
  SAP_CODE_PARAMETER: 'Код параметра (SAP)',
  SAP_CODE: 'SAP код',
  SAP_WORK_PLACE: 'Рабочее место из SAP',
  SAVE: 'Сохранить',
  SCHEDULED_INSPECTIONS: 'Плановые осмотры',
  SCHEDULED_MEASUREMENTS: 'Плановые измерения',
  SEARCH_BY_NAME: 'Поиск по названию',
  SEARCH_STAFF: 'Поиск сотрудника',
  SECTOR: 'Участок',
  SELECT_DATE: 'Выбрать дату выполнения',
  SELECT_DEFECTS_GROUP: 'Выберите группу дефектов для отображения',
  SELECT_EMPLOEES_FROM_LIST: 'Выберите из списка сотрудников:',
  SELECT_EQUIPMENT_AND_TYPE_OF_REPAIR_TO_DISPLAY_OPERATIONS: 'Выберите Оборудование и Вид ремонта в Атрибутах задачи для отображения операций',
  SELECT_PERFORMERS_AND_APPOINT_A_RESPONSIBLE: 'Выберите исполнителей и назначьте ответственного',
  SELECT_POWER_UNIT: 'Выберите энергоблок',
  SELECT_PRODUCER: 'Назначьте производителя',
  SELECT_VALUE: 'Выберите значение',
  SELECT: 'Выберите',
  SELECTION_FROM_THE_DIRECTORY: 'Выбор из справочника',
  SEND_TO_WORK: 'Отправить в работу',
  SENDING_TO_WORK: 'Отправка в работу',
  SERVER_IS_NOT_AVAILABLE: 'Сервер недоступен.',
  SET_ON_PAUSE: 'На паузу',
  SETTINGS_BY_PHOTO_TYPE: 'Параметры по типу фото',
  SETTINGS: 'Настройки',
  SHAREPOINT_GENERAL_SETTINGS: 'Общие параметры при отправке в SharePoint',
  SHIFT_ACCEPTANCE: 'Приемка смены',
  SHIFT_TYPE: 'Тип смены',
  SHIFT: 'Смена',
  SHOULD_CHOOSE_BRIGADE_STAFF: 'Выберите состав бригады из списка сотрудников и назначьте бригадира',
  SHOULD_CLEAN_STATE_MESSAGE: 'Смена значения в данном поле приведет к удалению сохраненных операций.',
  SHOULD_ENTER_BRIGADE_TITLE: 'Введите название бригады',
  SHOW_COMMENTS: 'Показать комментарии',
  SHOW_EQUIPMENT_MEASUREMENTS: 'Показать измерения по оборудованию',
  SHOW_EQUIPMENT: 'Показать оборудование',
  SHOW_MATERIAL_VALUES: 'Отобразить ТМЦ',
  SPECIFY_A_TASK_DUE_DATE: 'Укажите дату выполнения задачи',
  SPECIFY_DATA_TYPE: 'Укажите тип данных',
  SPECIFY_FIELD_TYPE_AND_NAME: 'Укажите тип поля и название',
  SPECIFY_MEASUREMENT_UNIT: 'Укажите единицу измерения',
  STAFF_LIST: 'Список сотрудников',
  STAFF: 'Состав',
  STANDARD_MULTIPLE: 'Стандартные',
  STANDARD_PRIORITY: 'Стандартный приоритет',
  STANDARD_TASK_TYPE: 'Стандартная задача',
  STANDARD: 'Стандартный',
  START_DATE_CANNOT_BE_GREATER_THAN_THE_END_DATE: 'Дата начала не может быть больше даты окончания',
  START_DATE_CANNOT_BE_PAST_TENSE: 'Дата начала не может быть прошедшим временем',
  START_DATE: 'Дата начала',
  START_IN_MIN: 'Старт через (мин)',
  START_TIME_AND_PLANNED_TASK_EXECUTION_TIME: 'Время старта и плановое время выполнения задачи',
  START_TIME_MUST_NOT_BE_LESS_THAN_THE_END_TIME: 'Время начала не должно быть меньше времени окончания',
  START_TIME: 'Время начала',
  STATE: 'Состояние',
  STATISTICS: 'Статистика',
  STATUS: 'Статус',
  STORAGE_CODE: 'Код склада ТМЦ',
  SUCCESSFUL_QR_CODE_SCANNING: 'Успешное сканирование QR кода',
  SUCCESSFULLY: 'Успешно',
  SUR_NAME: 'Отчество',
  SYMBOLS: 'символа',
  SYNC_IS_IN_PROGRESS: 'Идет синхронизация',
  SYSTEM_PAUSE_REASON: '(Системная)',
  TAG_ACCUSATIVE: 'метку',
  TAG_ADDING: 'Добавление метки',
  TAG_CHOOSING: 'Выбор метки',
  TAG_CODE: 'Код метки',
  TAG_CREATION: 'Создание метки',
  TAG_EDITING: 'Редактирование метки',
  TAG_GENITIVE: 'метки',
  TAG_NAME: 'Название метки',
  TAG_NFC_CODE: 'Код NFC метки',
  TAG_NUMBER: 'Номер метки',
  TAG_VIEW: 'Просмотр метки',
  TAG: 'Метка',
  TAGS: 'Метки',
  TASK_ACCUSATIVE: 'задачу',
  TASK_ATTRIBUTES: 'Атрибуты задачи',
  TASK_CREATION_INTERVAL: 'Интервал создания задачи',
  TASK_CREATION: 'Создание задачи',
  TASK_DESCRIPTION: 'Описание задачи',
  TASK_EXECUTION_PERIOD: 'Период выполнения задачи',
  TASK_HAS_ALREADY_BEEN_TAKEN_INTO_OPERATION: 'Данная задача уже взята в работу',
  TASK_NAME_ALREADY_EXISTS: 'Задача с таким названием уже существует.',
  TASK_REJECTION_REASON: 'Причина отклонения задачи',
  TASK_RESCHEDULING: 'Перепланирование задачи',
  TASK_ROUTE_SUCCESSFULLY_CREATED: 'Маршрут успешно создан!',
  TASK_ROUTE_SUCCESSFULLY_REMOVED: 'Маршрут успешно удален!',
  TASK_SCHEDULE: 'Расписание задания',
  TASK_SOURCE: 'Источник задачи',
  TASK_STATE: 'Состояние задания',
  TASK_STATUS: 'Статус задачи',
  TASK_SUCCESSFULLY_ASSIGNED: 'Задача успешно передана в работу!',
  TASK_SUCCESSFULLY_CONFIRMED_SENT_TO_THE_COMPLETED_TAB: 'Задача успешно подтверждена и отправлена во вкладку “Завершенные”',
  TASK_SUCCESSFULLY_CREATED: 'Задача успешно создана!',
  TASK_SUCCESSFULLY_DELETED: 'Задача успешно удалена',
  TASK_SUCCESSFULLY_REJECTED: 'Задача успешно отклонена и передана Инициатору',
  TASK_SUCCESSFULLY_REMOVED: 'Задача успешно удалена!',
  TASK_SUCCESSFULLY_RESCHEDULED: 'Задача успешно перепланирована',
  TASK_TITLE: 'Название задачи',
  TASK_TYPE: 'Тип задачи',
  TASK_VIEW: 'Просмотр задачи',
  TASK_WILL_BE_MOVED_TO_A_TAB: 'Задача будет перемещанна во вкладку',
  TASK_WITH_DEFECTS: 'Выявлены дефекты!',
  TASK_WITHOUT_DEFECTS: 'Дефекты не выявлены',
  TASKS_PER_SHIFT: 'Задач на смену',
  TASKS: 'Задачи',
  TECHNICAL_PLACE_SHORT: 'ТМ',
  TECHNICAL_PLACE: 'Техническое место',
  TEMP: 'Временно',
  TEXT_INPUT: 'Текстовый ввод',
  THE_DEFECT_WILL_BE_SENT_TO_THE_IN_PROGRESS_TAB: 'Дефект будет отправлен во вкладку “В работе”.',
  THE_END_TIME_MUST_NOT_BE_GREATER_THAN_THE_START_TIME: 'Время окончания не должно быть больше времени начала',
  THE_INTERVAL_OF_DAYS_BETWEEN_RECEIVING_A_TASK_ON_A_MOBILE_DEVICE: 'Интервал дней между получениями задачи на мобильнос устройстве',
  THE_RECOMMENDED_TIME_FOR_COMPLETING_THE_TASK_ON_THE_DEVICE: 'Рекоммендуемое время выполнения задачи на моб. устройстве',
  THE_TASK_WILL_BE_RESCHEDULED: 'Задача будет перепланирована.',
  THE_TIME_AT_THE_END_OF_WHICH_THE_TASK_STARTS_ON_THE_DEVICE: 'Время по исходу которого задача стартует на моб. устройстве',
  THERE_ARE_NO_MATERIAL_VALUES: 'ТМЦ отсутствуют',
  THERE_ARE_NO_PAUSE_REASONS: 'Причины постановки на паузу отсутствуют',
  THERE_ARE_OUTSTANDING_TASKS_ALONG_THIS_ROUTE: 'По данному маршруту есть невыполненные задачи.',
  THERMAL_POWER_PLANT: 'ТЭС',
  THIS_OPERATION_WILL_BE_DISPLAYED_IN_THE_ON_APPROVAL_TAB: 'Данная операция будет отображена во вкладке “На утверждении”',
  THIS_OPERATION_WILL_BE_DISPLAYED_IN_THE_RETURNED_TAB: 'Данная операция будет отображена во вкладке “Возвращенные”',
  TILE_DISPLAY: 'Отображение плиткой',
  TIME: 'Время',
  TITLE: 'Название',
  TO_REPORT_CATEGORY_SELECT: 'К выбору категории отчета',
  TO_ROUTE_VIEW: 'К просмотру маршрута',
  TO_ROUTES_LIST: 'К списку маршрутов',
  TO_TAG_VIEW: 'К просмотру метки',
  TO_TAGS_LIST: 'К списку меток',
  TO_TASK_LIST: 'К списку задач',
  TO_TASK_VIEW: 'К просмотру задачи',
  TO_USER_VIEW: 'К просмотру данных пользователя',
  TO_USERS_LIST: 'К списку пользователей',
  TO: 'до',
  TOU_CAN_ADD_REASON: 'Вы можете добавить причину.',
  TRANSPORT: 'Транспорт',
  TYPE_OF_REPAIR: 'Вид ремонта',
  TYPE: 'Тип',
  UNASSIGN_RESPONSIBLE: 'Снять признак ответственности',
  UNASSIGNED: 'Неназначенные',
  UNCOLLAPSE_ALL: 'Закрыть все',
  UNKNOWN: 'Неизвестно',
  UNSUCCESSFUL_QR_CODE_SCANNING: 'Неуспешное сканирование QR кода',
  UPDATE_DATE: 'Дата и время изменения',
  UPDATED_ON: 'Обновлено',
  UPLOADING_DOCUMENTS: 'Загрузка документов',
  URL_IS_NOT_VALID: 'Данная ссылка не валидна',
  USER_ATTRIBUTES: 'Атрибуты пользователя',
  USER_IS_ACTIVE: 'Пользователь активен',
  USER_IS_BLOCKED: 'Пользователь заблокирован',
  USER_ROLE: 'Роль пользователя в системе',
  USER_SEARCH_HELPER: 'Для поиска пользователей воспользуйтесь поисковой строкой или блоком фильтрации.',
  USER_SEARCH: 'Поиск пользователей',
  USER_SUCCESSFULLY_CREATED: 'Пользователь успешно добавлен',
  USER_TEXT: 'Пользовательское название',
  USERS_DICTIONARIES: 'Пользовательские справочники',
  USERS_IMPORT_ERROR_REPORT: 'При импорте возникли ошибки. Отчет загружен.',
  USERS_IMPORTED_SUCCESSFULLY: 'Пользователи успешно импортированы.',
  USERS: 'Пользователи',
  VIEW_A_PHOTO: 'Просмотреть фото',
  VIEW_A_TASK: 'Просмотреть задачу',
  VIEW_DEFECT: 'Просмотр дефекта',
  VIEW_PROFILE: 'Смотреть профиль',
  VOLUME_PAGE_TITLE: 'Настройка физ. объема',
  VOLUME_PARAMETER_IS_ABSENT: 'Параметры объема отсутствуют.',
  VOLUME_TAB: 'Вкладка “Объем”',
  VOLUMES_SHORT: 'Физ. объем',
  WAS: 'Было',
  WITHOUT_EQUIPMENT_UNIT: 'Без узла',
  WORK_CONFIRMATION: 'Подтверждение работ',
  WORK_PLACE_ADDING: 'Добавление рабочего места',
  WORK_PLACE_ATTRIBUTES: 'Атрибуты рабочего места',
  WORK_PLACE_EDITING: 'Редактирование рабочего места',
  WORK_PLACE: 'Рабочее место',
  WORK: 'Работа',
  WORKPLACE_DELETION: 'Удаление рабочего места',
  WORKPLACE_DUPLICATE_CODE: 'Рабочее место с таким кодом уже существует.',
  WORKPLACE_SUCCESSFULLY_ADDED: 'Рабочее место  успешно добавлено!',
  WORKPLACE_SUCCESSFULLY_DELETED: 'Рабочее место успешно удалено!',
  WORKPLACES: 'Рабочие места',
  YOU_CAN_ADD_OPERATION_KEY: 'Вы можете добавить ключ операции',
  YOU_CAN_ADD_WORKPLACE: 'Вы можете добавить рабочее место.',
  YOU_CAN_SWITCH_AT_ANY_TIME: 'Вы можете переключиться в любое время.',
  YOU_NEED_CHANGE_TMC_TO_CONFIRM: 'Для подтверждения требуется изменить ТМЦ',
  TPS_REPAIR_BUSINESS_NAME: 'ТЭС.Ремонты',
  TPS_INSPECTION_BUSINESS_NAME: 'ТЭС.Обходы',
  COAL_BUSINESS_NAME: 'Уголь',
  PL_BUSINESS_NAME: 'Сети',
  FOREMAN_ROLE_NAME: 'Начальник цеха',
  HEAD_MASTER_ROLE_NAME: 'Ст. мастер',
  MASTER_ROLE_NAME: 'Мастер',
  COAL_MASTER_ROLE_NAME: 'Горный мастер',
  PERFORMER_ROLE_NAME: 'Исполнитель',
  MECHANIC_ROLE_NAME: 'Механик',
  HEAD_MECHANIC_ROLE_NAME: 'Главный механик',
  MANAGER_ROLE_NAME: 'Менеджер',
  SUPER_ADMIN_ROLE_NAME: 'Суперадмин',
  BOILER_OPERATOR_ROLE_NAME: 'Машинист-обходчик по котельному оборудованию',
  BOILER_OPERATOR_GZU_ROLE_NAME: 'Машинист-обходчик по котельному оборудованию (ГЗУ)',
  BOILER_OPERATOR_SHBM_ROLE_NAME: 'Машинист-обходчик по котельному оборудованию (ШБМ)',
  BOILER_OPERATOR_RG_ROLE_NAME: 'Машинист-обходчик по котельному оборудованию (Ряд Г)',
  POWER_UNIT_OPERATOR_ROLE_NAME: 'Машинист энергоблока',
  SENIOR_POWER_UNIT_OPERATOR_ROLE_NAME: 'Старший машинист энергоблока',
  SHIFT_SUPERVISOR_ROLE_NAME: 'Начальник смены',
  SHIFT_SUPERVISOR_KTC_ROLE_NAME: 'Начальник смены КТЦ',
  TURBINE_OPERATOR_ROLE_NAME: 'Машинист-обходчик по турбинному оборудованию',
  ADMINISTRATOR_OK_ROLE_NAME: 'Администратор ОК',
  ADMINISTRATOR_OCP_ROLE_NAME: 'Администратор Предприятия',
  PLANNER_ROLE_NAME: 'Плановик',
  SPECIALIST_SDKR_ROLE_NAME: 'Специалист СДКР',
  ECONOMIST_IETS_ROLE_NAME: 'Экономист ИЭС',
  UNKNOWN_DEFECT_STATUS: 'Неизвестно',
  NEW_DEFECT_STATUS: 'Новый',
  IN_PROGRESS_DEFECT_STATUS: 'В работе',
  REJECTED_DEFECT_STATUS: 'Отклоненный',
  ARCHIVED_DEFECT_STATUS: 'В архиве',
  PENDING_DEFECT_STATUS: 'Ожидает выполнения',
  REJECTED_DEFECT_STATUS_SHORT: 'Отклонен',
  COMPLETED_DEFECT_STATUS: 'Выполнен',
  PERFORMS_DEFECT_STATUS: 'Выполняется',
  SCHEDULED: 'Запланировано',
  CUSTOM_ACTION: 'Нестандартное задание',
  FUll_NAME: 'П.І.Б.',
};
export default TRANSLATIONS_ROLE_NAME;
