import React from 'react';

import useStyles from './styles';

const CoalFAQ = () => {
  const classes = useStyles();

  return (
    <>
      <h1 className={classes.title}>Вопросы по бизнесу “Уголь”</h1>

      <div className={classes.question}>
        - Как создавать задачи для «Уголь»?
      </div>
      <div className={classes.answer}>
        <p>
          Для создания задачи нажмите кнопку “Создать задачу” на главном экране задач на
          вкладке “Актуальные”.
        </p>
      </div>

      <div className={classes.question}>
        - Не могу редактировать/удалить задачу в «Уголь»?
      </div>
      <div className={classes.answer}>
        <p>
          Задача, которую Вы хотите изменить/удалить уже взята в работу исполнителем.
          Изменить/удалить её нет возможности.
        </p>
      </div>

      <div className={classes.question}>
        - Что такое нестандартная задача?
      </div>
      <div className={classes.answer}>
        <p>
          Для задач по углю в системе существуют готовые наборы операций, в зависимости
          от выбранного вида ремонта. По этим операциям уже рассчитаны плановые работы,
          время  и  ТМЦ.  Если  необходимо  выполнить  какие-либо  задачи,  которые не
          предусмотрены  стандартными  ситуациями  (например, выкопать  траншею),  для
          таких случаем существует нестандартная задача. При её создании Вы сами вручную
          можете добавить и описать необходимые для выполнения операции.
        </p>
      </div>

      <div className={classes.question}>
        - При  создании/редактировании  задачи  в  «Уголь»  нет  оборудования/участка/вида
        ремонта/исполнителей или не мои?
      </div>
      <div className={classes.answer}>
        <p>
          Доступность выбора некоторых полей регулируется Вашими правами доступа в
          систему. Права выдаются заявками на доступ от Вашей организации. Обратитесь в
          соответствующий отдел для добавления/изменения необходимых прав доступа.
        </p>
      </div>

      <div className={classes.question}>
        - При создании/редактировании задачи в «Уголь» вижу только основное оборудование,
        но не вижу второстепенного?
      </div>
      <div className={classes.answer}>
        <p>
          Рядом с полем выбора оборудования находится переключатель “Иерархия”, нажав
          на который Вы увидите все доступные уровни оборудования.
        </p>
      </div>

      <div className={classes.question}>
        - Как  добавить/изменить  описание  стандартной  операции при  создании  задачи  в
        «Уголь»?
      </div>
      <div className={classes.answer}>
        <p>
          Описание  стандартных  операций  не  настраивается  вручную,  а  загружается  из
          системы  SAP. Редактировать  их  у  обычных  сотрудников  и  пользователей
          возможности нет.
        </p>
      </div>

      <div className={classes.question}>
        - Нашел ошибку в описании стандартной операции при создании задачи в «Уголь»?
      </div>
      <div className={classes.answer}>
        <p>
          Создайте заявку для специалистов SAP на устранение ошибки описания.
        </p>
      </div>

      <div className={classes.question}>
        - Как подтвердить выполненную задачу в “Уголь”?
      </div>
      <div className={classes.answer}>
        <p>
          В основном окне задач, на вкладке “На контроле” найти нужную задачу, зайти в её
          просмотр и нажать кнопку “Согласовать”.
        </p>
      </div>

      <div className={classes.question}>
        - Как отклонить выполненную задачу в “Уголь”?
      </div>
      <div className={classes.answer}>
        <p>
          В основном окне задач, на вкладке “На контроле” найти нужную задачу, зайти в её
          просмотр и нажать кнопку “Отклонить”.
        </p>
      </div>

      <div className={classes.question}>
        - Как изменить выполненную задачу в “Уголь”? Необходимо создать такую же задачу, но
        с некоторыми изменениями/дополнениями?
      </div>
      <div className={classes.answer}>
        <p>
          В основном окне задач, на вкладке “На контроле” найти нужную задачу, зайти в её
          просмотр  и  нажать  кнопку  “На  перепланирование”. Выбранная  задача  будет
          отмечена как отклоненная, система создаст копию задачи со статусом “Актуальная”.
        </p>
      </div>

    </>
  );
};

export default CoalFAQ;
