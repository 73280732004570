import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100%',
    paddingTop: 50,
  },
  wrapper: {
    maxWidth: 480,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 2.4,
    color: theme.palette.black,
  },
  subTitle: {
    fontSize: 16,
    lineHeight: 1.5,
    color: theme.palette.black30,
  },
  searchImage: {
    marginBottom: 37,
  },
  arrowImage: {
    alignSelf: 'flex-end',
  },
}));

export default useStyles;
