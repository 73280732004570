import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import ActionBtn from 'Common/components/Forms/Button/components/Action/Action';
import Modal from 'Common/components/Modal/Modal';


const ManageEntityModal = ({
  handleClose, message, secondaryMessage, isOpen, title, onClose, type, entityName, actionButtonTitle,
}) => {
  const { t } = useTranslation();
  const onOkButtonClick = () => {
    onClose();
    handleClose();
  };

  const renderActions = () => (
    <Grid container spacing={3} justify="flex-end">
      <Grid item>
        <ActionBtn
          text={t('CANCEL')}
          type="cancel"
          handleClick={handleClose}
          data-test="cancel-action"
        />
      </Grid>
      <Grid item>
        <ActionBtn
          text={actionButtonTitle || t('PROCEED')}
          type="confirm"
          handleClick={onOkButtonClick}
          data-test="proceed"
        />
      </Grid>
    </Grid>
  );

  const renderConfirmationButton = () => (
    <Grid container justify="center">
      <Grid item>
        <ActionBtn
          single
          text="Ok"
          type="confirm"
          handleClick={handleClose}
          data-test="confirm"
        />
      </Grid>
    </Grid>
  );

  const renderButtons = () => {
    if (type === 'action') return renderActions();
    if (type === 'info') return renderConfirmationButton();
  };

  return (
    <Modal
      title={title}
      isOpened={isOpen}
      handleClose={handleClose}
      actions={renderButtons()}
    >
      {entityName
        && (
          <Typography variant="h6" data-test="entityName">{entityName}</Typography>
        )}
      <Typography variant="subtitle1" style={{ marginTop: entityName ? 16 : 0 }}>{message}</Typography>
      <Typography variant="subtitle1">{secondaryMessage}</Typography>
    </Modal>
  );
};

ManageEntityModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  entityName: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  secondaryMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  actionButtonTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['action', 'info']).isRequired
};

ManageEntityModal.defaultProps = {
  secondaryMessage: '',
  entityName: '',
  actionButtonTitle: '',
};

export default ManageEntityModal;
