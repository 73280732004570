import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { ReactComponent as DragIndicator } from 'Src/assets/drag-indicator.svg';
import PauseReason from 'Business/plant/components/SortablePauseReasons/PauseReason';
import arrayMove from 'array-move';

import useStyles from './styles';

const DragHandle = SortableHandle(({ isSortable, classes, isHoverBlocked }) => (
  <div className={clsx(
    classes.dragIndicator,
    isSortable && !isHoverBlocked && classes.grab,
    isSortable && isHoverBlocked && classes.grabbing
  )}
  >
    {isSortable && (
    <DragIndicator />
    )}
  </div>
));

const SortableItem = SortableElement(({
  value,
  mode,
  classes,
  isSortable,
  isHoverBlocked,
  handleEdit,
  onChangeIsVisible,
  updatingIsVisibleArray,
  onChangeIsPhotoRequired,
  updatingIsPhotoRequiredArray,
  showSortableDragElement,
}) => (
  <div
    className={clsx(classes.sortableItem, !isHoverBlocked && classes.hovered)}
    data-test={`sortableItem_${value.id}`}
  >
    {showSortableDragElement && (
    <DragHandle
      classes={classes}
      isSortable={isSortable}
      isHoverBlocked={isHoverBlocked}
    />
    )}
    <PauseReason
      mode={mode}
      data={value}
      handleEdit={(idToEdit) => {
        handleEdit(idToEdit);
      }}
      onChangeIsVisible={onChangeIsVisible}
      isVisibleUpdating={updatingIsVisibleArray.includes(value.id)}
      onChangeIsPhotoRequired={onChangeIsPhotoRequired}
      isPhotoRequiredUpdating={updatingIsPhotoRequiredArray.includes(value.id)}
      isHoverBlocked={isHoverBlocked}
    />
  </div>
));

const SortableList = SortableContainer(({
  items,
  isSortable,
  classes,
  isHoverBlocked,
  handleEdit,
  onChangeIsVisible,
  updatingIsVisibleArray,
  onChangeIsPhotoRequired,
  updatingIsPhotoRequiredArray,
  showSortableDragElement,
}) => (
  <div className={clsx(isHoverBlocked && classes.grabbing)}>
    {items.map((value, index) => {
      const sortableItemKey = `sortableItem-${index}`;
      return (
        <SortableItem
          key={sortableItemKey}
          index={index}
          value={value}
          disabled={!isSortable}
          isSortable={isSortable}
          showSortableDragElement={showSortableDragElement}
          classes={classes}
          isHoverBlocked={isHoverBlocked}
          handleEdit={handleEdit}
          onChangeIsVisible={onChangeIsVisible}
          updatingIsVisibleArray={updatingIsVisibleArray}
          onChangeIsPhotoRequired={onChangeIsPhotoRequired}
          updatingIsPhotoRequiredArray={updatingIsPhotoRequiredArray}
        />
      );
    })}
  </div>
));

const SortablePauseReasons = ({
  items,
  onItemsSortEnd,
  handleEdit,
  onChangeIsVisible,
  updatingIsVisibleArray,
  onChangeIsPhotoRequired,
  updatingIsPhotoRequiredArray,
}) => {
  const [isHoverBlocked, setIsHoverBlocked] = useState(false);
  const classes = useStyles();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setIsHoverBlocked(false);
    onItemsSortEnd(arrayMove(items, oldIndex, newIndex));
  };

  return (
    <SortableList
      items={items}
      updateBeforeSortStart={() => setIsHoverBlocked(true)}
      onSortEnd={onSortEnd}
      distance={1}
      useDragHandle
      lockAxis="y"
      lockToContainerEdges
      isSortable
      classes={classes}
      isHoverBlocked={isHoverBlocked}
      showSortableDragElement
      handleEdit={handleEdit}
      onChangeIsVisible={onChangeIsVisible}
      updatingIsVisibleArray={updatingIsVisibleArray}
      onChangeIsPhotoRequired={onChangeIsPhotoRequired}
      updatingIsPhotoRequiredArray={updatingIsPhotoRequiredArray}
      helperClass={classes.helperClass}
    />
  );
};

SortablePauseReasons.defaultProps = {
  items: [],
  handleEdit: () => {},
  onChangeIsVisible: () => {},
  updatingIsVisibleArray: [],
  onItemsSortEnd: () => {},
  onChangeIsPhotoRequired: () => {},
  updatingIsPhotoRequiredArray: [],
};

SortablePauseReasons.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  onChangeIsVisible: PropTypes.func,
  updatingIsVisibleArray: PropTypes.arrayOf(PropTypes.string),
  onChangeIsPhotoRequired: PropTypes.func,
  updatingIsPhotoRequiredArray: PropTypes.arrayOf(PropTypes.string),
  onItemsSortEnd: PropTypes.func,
  handleEdit: PropTypes.func,
};

export default SortablePauseReasons;
