import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { toJS } from 'mobx'; // eslint-disable-line
import {
  isEmpty, startsWith, last, get, head, values,
} from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import HeaderLayout from 'Common/widgets/Layout/Header/Header';
import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import SectionList from 'Common/widgets/Section/SectionList';
import Section from 'Common/widgets/Section/Section';
import SectionListItem from 'Common/widgets/Section/SectionListItem';
import History from 'Business/tpsInspections/components/History';
import HistoryItem from 'Business/tpsInspections/components/HistoryItem';
import PromptSection from 'Business/tpsInspections/components/PromptSection';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import DottedLoader from 'Common/components/Progress/components/Dotted';
import useStores from 'Store/useStores';
import sectionTypes, {
  ACTION, NODE, EQUIPMENT, DEPARTMENT, sectionTypesAccusative
} from 'Business/coal/config/sectionTypeMapping';
import { MECHANIC } from 'Shared/constants/roles';

import useStyles from './styles';


const OperationSelectOverlay = observer(({ isOpen, handleClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    coalRouteStore: {
      isLoadingTechnicalObjectsDictionary,
      structureDictionary,
      areTechObjectChildrenLoading,
      handleListItemClick,
      visibleSections,
      isCurrentlySelected,
      history,
      handleHistoryClick,
      handleToggleAction,
      handleDeleteBranch,
      temporaryEditorActiveMP,
      saveEditorState,
      isEditing,
    },
    userStore: { role },
    notificationStore: { enqueueSnackbar },
  } = useStores();


  const gridWidth = role === MECHANIC ? 4 : 3;
  const visibleSectionsCount = role === MECHANIC ? 2 : 3;
  const powerUnitsSectionsCount = role === MECHANIC ? 0 : 1;
  const lastVisibleSection = last(visibleSections);
  const firstVisibleSection = head(visibleSections) || {};
  const firstVisibleSectionItems = get(firstVisibleSection, 'items') || [];
  const firstVisibleSectionBranchLength = get(head(firstVisibleSectionItems), 'branchIds', []).length;
  const lastVisibleSectionItems = get(lastVisibleSection, 'items', []);
  const lastVisibleSectionIsEmpty = isEmpty(lastVisibleSectionItems);
  const lastVisibleSectionType = get(lastVisibleSection, 'type');
  const showHistory = firstVisibleSectionBranchLength > visibleSectionsCount || isEditing;
  const showPromptSection = visibleSections.length < visibleSectionsCount && !(lastVisibleSectionIsEmpty && lastVisibleSectionType === ACTION);

  const [isValid, setIsValid] = useState(true);

  const getPromptText = () => {
    let type;
    if (isEmpty(history)) {
      type = DEPARTMENT;
    } else {
      type = lastVisibleSectionType;
    }
    return `${t('SELECT')} ${sectionTypesAccusative(t)[type] || ''}`;
  };

  const getFormValidationState = () => {
    if (isEditing) {
      const { items = [] } = last(visibleSections) || {};
      return items.some(({ branchIdsString } = {}) => temporaryEditorActiveMP.includes(branchIdsString));
    }
    return !isEmpty(temporaryEditorActiveMP);
  };

  const handleSave = () => {
    const isValidForm = getFormValidationState();
    if (!isValidForm) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('CANT_SAVE'),
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('FILL_ALL_REQUIRED_FIELDS'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      setIsValid(isValidForm);
      return;
    }
    saveEditorState();
    handleClose();
  };

  const renderHistory = () => (
    <Section title={t('PREVIOUS_ACTIONS')} gridWidth={gridWidth}>
      <History>
        {history.map((item = {}, historyItemIndex) => {
          const isTheLastItem = historyItemIndex + 1 === history.length;
          const {
            title, technicalObjectId,
          } = item;
          return (
            <HistoryItem
              disableClick={isEditing || isTheLastItem || areTechObjectChildrenLoading}
              key={technicalObjectId}
              id={technicalObjectId}
              title={title}
              onItemClick={handleHistoryClick(technicalObjectId)}
            />
          );
        })}
      </History>
    </Section>
  );

  return (
    <Dialog fullScreen open={isOpen}>
      <Grid container direction="column" wrap="nowrap" className={classes.root}>
        <HeaderLayout title={isEditing ? t('EQUIPMENT_EDITING') : t('EQUIPMENT_ADDING')}>
          <HeaderButton type="close" handleClick={handleClose} />
          <HeaderButton type="save" handleClick={handleSave} disabled={false} />
        </HeaderLayout>
        {isLoadingTechnicalObjectsDictionary ? (
          <ProgressBar />
        ) : (
          <SectionList>
            {showHistory && renderHistory()}
            {!showHistory && !isEmpty(structureDictionary) && (
              <Section
                title={get(sectionTypes(t), get(head(values(structureDictionary)) || {}, 'technicalObjectType', ''))}
                gridWidth={gridWidth}
              >
                {(values(structureDictionary)).map( // departments
                  ({
                    title, technicalObjectId, branchIdsString, childrenLoaded, ...rest
                  }, indexAtRow) => (
                    <SectionListItem
                      stopPropagationOnActionButton
                      key={branchIdsString}
                      id={technicalObjectId}
                      onItemClick={() => {
                        handleListItemClick({
                          title,
                          technicalObjectId,
                          branchIdsString,
                          ...rest,
                          indexAtRow,
                        });
                      }}
                      disabled={areTechObjectChildrenLoading}
                      title={title}
                      isCurrentlySelected={isCurrentlySelected(technicalObjectId)}
                      actionFunc={handleDeleteBranch(branchIdsString)}
                      isTreePassed={temporaryEditorActiveMP.some(item => startsWith(item, branchIdsString),)}
                    />
                  ),
                )}
              </Section>
            )}
            {visibleSections.map(({ items = [], type, parentsLength }, sectionIndex) => {
              const sectionKey = `section${sectionIndex}`;
              if (type === ACTION) {
                return (
                  <Section title={sectionTypes(t)[ACTION]} key={sectionKey} gridWidth={gridWidth}>
                    {items.map((item) => {
                      const {
                        title, measurementPointId, unitTitle, branchIdsString = [],
                      } = item;
                      return (
                        <SectionListItem
                          variant="check"
                          checked={temporaryEditorActiveMP.includes(branchIdsString)}
                          key={measurementPointId}
                          id={measurementPointId}
                          onItemClick={() => {
                            setIsValid(true);
                            handleToggleAction(branchIdsString);
                          }}
                          title={`${title} (${unitTitle})`}
                          noNextIcon
                          error={!isValid}
                        />
                      );
                    })}
                    {isEmpty(items) && (
                      <div className={classes.noMeasurementsHelper}>{t('NO_MEASUREMENTS')}</div>
                    )}
                  </Section>
                );
              }

              return (
                <Section
                  title={
                    type === NODE
                      ? `${sectionTypes(t)[type]}-${parentsLength - powerUnitsSectionsCount}`
                      : sectionTypes(t)[type]
                  }
                  gridWidth={gridWidth}
                  key={sectionKey}
                >
                  {items.map(({
                    title, sapCode, technicalObjectId, branchIdsString, childrenLoaded, ...rest
                  }, indexAtRow) => (
                    <SectionListItem
                      stopPropagationOnActionButton
                      key={branchIdsString}
                      id={technicalObjectId}
                      actionFunc={handleDeleteBranch(branchIdsString)}
                      onItemClick={() => {
                        handleListItemClick({
                          title,
                          technicalObjectId,
                          branchIdsString,
                          ...rest,
                          indexAtRow,
                          sectionIndex,
                        });
                      }}
                      disabled={areTechObjectChildrenLoading}
                      title={title}
                      subTitle={type === EQUIPMENT ? sapCode : ''}
                      isCurrentlySelected={isCurrentlySelected(technicalObjectId)}
                      isTreePassed={temporaryEditorActiveMP.some(item => startsWith(item, branchIdsString),)}
                    />
                  ))}
                </Section>
              );
            })}
            {areTechObjectChildrenLoading && (
              <Section
                title=""
                gridWidth={gridWidth}
                key="childrenLoaderHelper"
              >
                <DottedLoader isLoading>
                  <div className={classes.childrenLoaderHelper} />
                </DottedLoader>
              </Section>
            )}
            {showPromptSection && !areTechObjectChildrenLoading && <PromptSection title={getPromptText()} error={!isValid} />}
          </SectionList>
        )}
      </Grid>
    </Dialog>
  );
});

OperationSelectOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default OperationSelectOverlay;
