import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  redColor: {
    color: theme.palette.negative,
  },
  orangeColor: {
    color: theme.palette.orange,
  },
  rejectionIcon: {
    marginRight: 8,
  },
  rejectionText: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: theme.palette.black,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export default useStyles;
