import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import CategoryButton from 'Common/components/Category/CategoryButton';

const useStyles = makeStyles(() => ({
  wrapper: {
    textAlign: 'center',
    height: 'calc(100vh - 260px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridWrapper: {
    //maxWidth: 1000
  },
}));

const CategoryButtonList = ({ listTitle, list }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Typography variant="h5">{listTitle}</Typography>
      <Box marginTop={4}>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
        }}
        >
          <Grid
            container
            spacing={3}
            justify="center"
            alignItems="center"
            className={classes.gridWrapper}
          >
            {list.map(({ Icon, url, title }) => (
              <CategoryButton key={title} title={title} url={url} Icon={Icon} />
            ))}
          </Grid>
        </div>
      </Box>
    </div>
  );
};

CategoryButtonList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      Icon: PropTypes.elementType.isRequired,
    })
  ).isRequired,
  listTitle: PropTypes.string.isRequired,
};

export default CategoryButtonList;
