import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  position: {
    color: theme.palette.black30
  },
}));

const Brigadier = ({ brigadier }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { fullName = t('INPUT_EMPTY_VALUE'), positionName = t('INPUT_EMPTY_VALUE') } = brigadier;

  return (
    <>
      <div>{fullName}</div>
      <div className={classes.position}>{positionName}</div>
    </>
  );
};

Brigadier.propTypes = {
  // eslint-disable-next-line react/require-default-props
  brigadier: PropTypes.shape({
    fullName: PropTypes.string,
    positionName: PropTypes.string,
  })
};

export default Brigadier;
