import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import isEmpty from 'lodash/isEmpty';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useStores from 'Store/useStores';
import * as routes from 'Shared/constants/routes';
import TextInput from 'Common/components/Forms/Input/Text';
import CheckpointsBox from 'Business/tpsInspections/components/CheckpointsBox';
import CheckpointSelectOverlay from 'Business/tpsInspections/components/CheckpointSelectOverlay';
import CheckpointDetailsOverlay from 'Business/tpsInspections/components/CheckpointDetailsOverlay';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import Panel, { PanelBox, PanelTitle } from 'Common/widgets/Layout/Panel/Panel';
import useManageEntityModal from 'Common/components/Modal/ManageEntityModal/useManageEntityModal';
import AddCheckpointsButton from 'Business/tpsInspections/components/AddCheckpointsButton';

const TaskRouteDetails = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { id, action: mode = 'view' } = useParams();
  const {
    taskRouteStore: {
      isLoading,
      isDeleting,
      isTechPlacesLoading,
      isTechPlacesLoaded,
      isPowerUnitsLoaded,
      tpsName,
      isRouteSaved,
      isRouteSaving,
      deleteRoute,
    },
    taskRouteManageStore: {
      handleChange,
      removeCheckpointFromSelected,
      loadPowerUnits,
      setSelectedCheckpoints,
      selectedState: {
        isEditable,
        name,
        checkpoints
      },
      createRoute,
      updateRoute,
      discardState,
      loadRouteById,
    },
    notificationStore: { enqueueSnackbar },
    validationStore: {
      validateRequiredFields, isValidFields, onFieldUpdate, hasErrors
    },
  } = useStores();

  const isEmptyCheckpoints = isEmpty(checkpoints);

  const [isCheckpointOverlayOpen, setCheckpointOverlayVisibility] = useState(false);
  const [checkpointId, setCheckpointId] = useState(0);
  const {
    setManageEntityModalVisibility,
    setOnManageEntityModalCloseCallback,
    setManageEntityModalState,
    renderManageEntityModal,
  } = useManageEntityModal();
  const isVisibleAddCheckpointsButton = mode !== 'view';

  const handleCheckpointOverlayClose = () => {
    setCheckpointOverlayVisibility(false);
  };

  const handleCheckpointDetailsOverlayClose = () => {
    setCheckpointId(0);
  };

  const handleRouteCreate = async () => {
    const { error } = await createRoute() || {};
    if (error) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('REQUEST_DEFAULT_ERROR')
            }]
          }],
        },
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('TASK_ROUTE_SUCCESSFULLY_CREATED')
          }]
        }],
      },
      variant: 'success',
    });
    discardState();
    history.push({ pathname: routes.TASK_ROUTES });
  };

  const handleRouteUpdate = async () => {
    const { error } = await updateRoute(id) || {};
    if (error) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('REQUEST_DEFAULT_ERROR')
            }]
          }],
        },
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('CHANGES_SUCCESSFULLY_APPLIED')
          }]
        }],
      },
      variant: 'success',
    });
    discardState();
    history.push({ pathname: routes.TASK_ROUTES });
  };

  const saveItem = () => {
    validateRequiredFields();
    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: mode === 'create' ? t('CANT_CREATE') : t('CANT_UPDATE')
            }]
          }, {
            rowContent: [{
              type: 'text',
              text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS')
            }]
          }],
        },
        variant: 'error',
      });
      return;
    }
    if (mode === 'create') {
      handleRouteCreate();
      return;
    }
    handleRouteUpdate();
  };

  const handleAddCheckpointsButtonClick = () => {
    setCheckpointOverlayVisibility(true);
  };

  const goBack = () => {
    history.push({
      pathname: routes.TASK_ROUTES,
    });
  };

  const viewItem = () => {
    history.push({
      pathname: routes.VIEW_TASK_ROUTE.replace(':id', id),
    });
  };

  const editItem = () => {
    if (!isEditable) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: `${t('EDITING')} ${t('ROUTE_ACCUSATIVE')} ${t('RESTRICTED')} ${t(
                'THERE_ARE_OUTSTANDING_TASKS_ALONG_THIS_ROUTE'
              )}`
            }]
          }],
        },
        variant: 'error',
      });
    } else {
      history.push({
        pathname: routes.EDIT_TASK_ROUTE.replace(':id', id).replace(':action(edit)', 'edit'),
      });
    }
  };

  const handleDeleteRoute = routeId => async () => {
    const { error } = await deleteRoute(routeId) || {};
    if (error) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('REQUEST_DEFAULT_ERROR')
            }]
          }],
        },
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('TASK_ROUTE_SUCCESSFULLY_REMOVED')
          }]
        }],
      },
      variant: 'success',
    });
    history.push({ pathname: routes.TASK_ROUTES });
  };

  const deleteItem = () => {
    if (mode !== 'view') return;

    if (!isEditable) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: `${t('DELETING')} ${t('ROUTE_ACCUSATIVE')} ${t('RESTRICTED')} ${t(
                'THERE_ARE_OUTSTANDING_TASKS_ALONG_THIS_ROUTE',
              )}`
            }]
          }],
        },
        variant: 'error',
      });
    } else {
      setManageEntityModalState(() => ({
        title: `${t('DELETING')} ${t('ROUTE_ACCUSATIVE')}`,
        entityName: name,
        message: `${t('CONFIRM_DELETING_MESSAGE')} ${t('ROUTE').toLowerCase()}?`,
        type: 'action',
      }));
      setOnManageEntityModalCloseCallback(() => handleDeleteRoute(parseInt(id, 10)));
      setManageEntityModalVisibility(true);
    }
  };

  const viewCheckpointBy = (cpId) => {
    setCheckpointId(cpId);
  };

  useEffect(
    () => () => {
      discardState();
    },
    [location.key],
  );

  useEffect(() => {
    if (isVisibleAddCheckpointsButton) onFieldUpdate('add-checkpoint-button', !isEmptyCheckpoints);
  }, [isEmptyCheckpoints]);

  useEffect(() => {
    async function fetchData() {
      await loadPowerUnits().catch(() => {
        enqueueSnackbar({
          messageTemplate: {
            rows: [{
              rowContent: [{
                type: 'text',
                text: t('REQUEST_LOAD_POWER_UNITS_ERROR')
              }]
            }],
          },
          variant: 'error',
        });
      });
      if (id) {
        await loadRouteById(id).catch(() => {
          enqueueSnackbar({
            messageTemplate: {
              rows: [{
                rowContent: [{
                  type: 'text',
                  text: t('REQUEST_DEFAULT_ERROR')
                }]
              }],
            },
            variant: 'error',
          });
        });
      }
    }
    // if id is not valid
    if (['edit', 'view'].includes(mode) && !parseInt(id, 10)) {
      goBack();
    } else {
      fetchData();
    }
  }, [id, location.key]);

  if (isLoading || !isPowerUnitsLoaded) {
    return <ProgressBar />;
  }

  return (
    <Panel
      mode={mode}
      viewItem={viewItem}
      editItem={editItem}
      saveItem={saveItem}
      goBack={goBack}
      deleteItem={deleteItem}
      labelGoToView={t('GO_BACK')}
      labelGoToList={t('GO_BACK')}
      labelView={t('ROUTE_VIEW')}
      labelCreate={t('ROUTE_CREATION')}
      labelEdit={t('ROUTE_EDITING')}
      actions={['edit', 'delete']}
    >
      {isTechPlacesLoading && !isTechPlacesLoaded && <ProgressBar />}
      {isRouteSaving && !isRouteSaved && <ProgressBar />}
      {isDeleting && <ProgressBar />}
      <PanelBox>
        <PanelTitle>{t('GENERAL_PARAMS')}</PanelTitle>

        <TextInput
          label={t('THERMAL_POWER_PLANT')}
          value={tpsName}
          name="tpsName"
          isReadonly
          data-test="tpsName"
        />

        <TextInput
          label={t('ROUTE_NAME')}
          value={name}
          name="name"
          onChange={handleChange}
          autoFocus
          isReadonly={mode === 'view'}
          required
          data-test="name"
        />
      </PanelBox>

      <PanelBox style={{ marginBottom: 20 }}>
        <PanelTitle>{t('TAGS')}</PanelTitle>

        {!isEmptyCheckpoints && (
          <CheckpointsBox
            key="techPlaceId"
            techPlaceId="techPlaceId"
            withButtons={mode !== 'view'}
            title="techPlaceTitle"
            items={toJS(checkpoints)}
            onChange={() => {}}
            setSelected={setSelectedCheckpoints}
            onView={cpId => viewCheckpointBy(cpId)}
            onDelete={removeCheckpointFromSelected}
          />
        )}

        <AddCheckpointsButton
          visible={isVisibleAddCheckpointsButton}
          onClick={handleAddCheckpointsButtonClick}
          name="add-checkpoint-button"
          value={!isEmptyCheckpoints}
          required
        />
      </PanelBox>

      {checkpointId > 0 && (
        <CheckpointDetailsOverlay
          checkpointId={checkpointId}
          handleClose={handleCheckpointDetailsOverlayClose}
        />
      )}

      <CheckpointSelectOverlay
        isOpen={isCheckpointOverlayOpen}
        handleClose={handleCheckpointOverlayClose}
      />

      {renderManageEntityModal()}
    </Panel>
  );
});

export default TaskRouteDetails;
