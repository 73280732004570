const historyTabHeaderConfig = t => [
  {
    id: 'datetime', numeric: false, disablePadding: false, label: t('DATE_AND_TIME')
  },
  {
    id: 'operations', numeric: false, disablePadding: false, label: t('OPERATION')
  },
  {
    id: 'assignee', numeric: false, disablePadding: false, label: t('MATERIAL_VALUE_SHORT')
  },
  {
    id: 'quantity',
    numeric: false,
    disablePadding: false,
    label: t('FACT_QUANTITY_SHORT'),
  },
  {
    id: 'updatedQuantity',
    numeric: false,
    disablePadding: false,
    label: t('FACT_QUANTITY_SHORT_UPDATED'),
  },
  {
    id: 'author', numeric: false, disablePadding: false, label: t('AUTHOR')
  },
];

export default historyTabHeaderConfig;
