import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import useStores from 'Store/useStores';
import * as routes from 'Shared/constants/routes';
import Panel, { PanelBox, PanelTitle } from 'Common/widgets/Layout/Panel/Panel';
import InputSelect from 'Common/components/Forms/Input/Select';
import TextInput from 'Common/components/Forms/Input/Text';
import OptionRenderer from 'Common/components/Forms/OptionRenderer';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import { formatDate, formatTime, isValid } from 'Src/utils/datetime';

const CheckpointDetails = observer(({
  id: viewerId,
  mode,
  onClose
}) => {
  const history = useHistory();
  const { id } = useParams();

  const {
    checkpointStoreNEW: {
      isLoading,
      isCreating,
      isUpdating,
      isDeleting,
      powerUnits,
      loadPowerUnits,
      createCheckpoint,
      updateCheckpoint,
      checkpointName,
      onChangeCheckpointName,
      powerUnitId,
      onChangePowerUnitId,
      powerUnitTitle,
      checkpointNumber,
      updatedBy,
      updatedAt,
      discardState,
      loadCheckpointById,
      emptyData
    },
    validationStore: {
      validateRequiredFields, isValidFields, hasErrors
    },
    notificationStore: { enqueueSnackbar },
  } = useStores();
  const { t } = useTranslation();

  const shouldLoadCheckpointById = ['edit', 'view'].includes(mode);
  const isEditMode = mode === 'edit';
  const isCreateMode = mode === 'create';

  const goToCheckpointList = () => {
    history.push({
      pathname: routes.CHECKPOINTS_NEW
    });
  };

  const goBack = () => {
    if (viewerId) {
      onClose(viewerId);
    } else {
      goToCheckpointList();
    }
  };

  const editItem = () => {
    history.push({
      pathname: `${routes.CHECKPOINTS_NEW}/edit/${id}`
    });
  };

  const saveItem = async () => {
    validateRequiredFields();

    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: isCreateMode ? t('CANT_CREATE') : t('CANT_UPDATE')
            }]
          }, {
            rowContent: [{
              type: 'text',
              text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS')
            }]
          }],
        },
        variant: 'error',
      });
      return;
    }

    const saveFunc = isCreateMode ? createCheckpoint : updateCheckpoint;
    const { error } = await saveFunc(parseInt(id, 10)) || {};
    if (!error) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: isCreateMode ? t('CHECKPOINT_SUCCESSFULLY_CREATED') : t('CHANGES_SUCCESSFULLY_APPLIED')
            }]
          }],
        },
        variant: 'success',
      });
      goToCheckpointList();
      return;
    }

    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('REQUEST_DEFAULT_ERROR')
          }]
        }],
      },
      variant: 'error',
    });
  };

  useEffect(() => {
    loadPowerUnits();
    return () => discardState();
  }, []);

  useEffect(() => {
    // if id is not valid
    if (shouldLoadCheckpointById && !parseInt(id, 10)) {
      goBack();
    }
  }, [id]);

  useEffect(() => {
    if (parseInt(id, 10)) {
      loadCheckpointById(id).catch(() => {
        enqueueSnackbar({
          messageTemplate: {
            rows: [{
              rowContent: [{
                type: 'text',
                text: t('REQUEST_DEFAULT_ERROR')
              }]
            }],
          },
          variant: 'error',
        });
      });
    }
  }, [id, mode]);

  if (isLoading && !emptyData) {
    return <ProgressBar />;
  }

  if (emptyData) {
    history.push({
      pathname: routes.NOT_FOUND
    });
  }

  return (
    <Panel
      mode={viewerId ? 'onlyView' : mode}
      viewItem={goBack}
      editItem={editItem}
      saveItem={saveItem}
      goBack={goBack}
      deleteItem={() => {}}
      labelGoToView={t('GO_BACK')}
      labelGoToList={t('GO_BACK')}
      labelCreate={t('TAG_CREATION')}
      labelEdit={t('TAG_EDITING')}
      actions={['edit', 'delete']}
    >
      {(isCreating || isUpdating || isDeleting)
        && <ProgressBar />
      }
      <PanelBox>
        <PanelTitle>{t('EQUIPMENT_CHOISE')}</PanelTitle>

        <TextInput
          label={t('NOMINATION')}
          value={checkpointName}
          name="checkpointName"
          onChange={onChangeCheckpointName}
          required
          data-test="checkpointName"
        />

        {isCreateMode && (
          <InputSelect
            label={t('POWER_UNIT')}
            value={powerUnitId}
            placeholder={t('SELECT_POWER_UNIT')}
            name="powerUnitId"
            onChange={onChangePowerUnitId}
            options={powerUnits}
            optionValueKey="id"
            optionTitleKey="title"
            required
            data-test="powerUnitId"
          />
        )}

        {isEditMode && (
          <>
            <OptionRenderer
              value={powerUnitTitle}
              title={t('POWER_UNIT')}
              data-test="powerUnitId"
            />
            <OptionRenderer
              value={checkpointNumber}
              title={t('NUMBER')}
              data-test="checkpointNumber"
            />
            <OptionRenderer
              value={updatedBy}
              title={t('AUTHOR')}
              data-test="updatedBy"
            />
            <OptionRenderer
              value={isValid(updatedAt) ? `${formatDate(updatedAt)} ${formatTime(updatedAt)}` : t('INPUT_EMPTY_VALUE')}
              title={t('UPDATE_DATE')}
              data-test="updatedAt"
            />
          </>
        )}
      </PanelBox>
    </Panel>
  );
});

CheckpointDetails.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func
};

CheckpointDetails.defaultProps = {
  id: 0,
};

export default CheckpointDetails;
