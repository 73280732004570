import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  assigned: {
    color: theme.palette.black30,
  },
  canceled: {
    color: theme.palette.negative,
  },
  completed: {
    color: theme.palette.positive,
  },
  in_progress: {
    color: theme.palette.midBlack,
  },
  pause: {
    color: theme.palette.orange,
  },
}));

export default useStyles;
