import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core/';
import { SortableContainer } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import PlannerCheckpointItem from 'Business/tpsInspections/components/PlannerCheckpointItem';

import useStyles from './styles';

const SortableList = SortableContainer(({
  items,
  isChangeable,
  isHoverBlocked,
  isReadonlyByMode,
  onEditCheckpoint,
  onEditTechnicalObject,
  onEditOperations,
  onDeleteCheckpoint,
  onDeleteTechnicalObject,
  onDeleteOperations,
  onUpdateCustomAction,
  toggleCollapseCheckpoint,
  ...props
}) => {
  const classes = useStyles();
  const [isClicked, isClickedSet] = useState();
  const disabled = items.length === 1;

  return (
    <Box
      className={`${classes.boxes} ${isClicked && !disabled ? classes.boxesClicked : ''}`}
      onMouseDown={() => isClickedSet(isChangeable)}
      onMouseUp={() => isClickedSet(false)}
    >
      {items.map((item, index) => (
        <PlannerCheckpointItem
          withPaddingLeft={items.length > 1}
          key={`item-${item.id}`}
          id={item.label.id}
          index={index}
          isChangeable={isChangeable}
          disabled={disabled}
          disabledDrag={disabled}
          isHoverBlocked={isHoverBlocked}
          isReadonlyByMode={isReadonlyByMode}
          {...item}
          {...props}
          onEditCheckpoint={() => onEditCheckpoint(item)}
          onEditTechnicalObject={technicalObjectId => onEditTechnicalObject({ technicalObjectId, checkpoint: item })}
          onEditOperations={(operationType, technicalObjectId) => onEditOperations({ technicalObjectId, operationType, checkpoint: item })}
          onDeleteCheckpoint={onDeleteCheckpoint}
          onDeleteTechnicalObject={(technicalObjectId, technicalObjectTitle) => onDeleteTechnicalObject({ technicalObjectId, technicalObjectTitle, checkpoint: item })}
          onDeleteOperations={(operationType, technicalObjectId) => onDeleteOperations({ technicalObjectId, operationType, checkpoint: item })}
          onUpdateCustomAction={(customActionValue, technicalObjectId) => onUpdateCustomAction({ customActionValue, technicalObjectId, checkpoint: item })}
          toggleCollapseCheckpoint={toggleCollapseCheckpoint}
        />
      ))}
    </Box>
  );
});

const CheckpointBox = ({
  techPlaceId,
  withButtons,
  items,
  onView,
  setSelected,
  isReadonlyByMode,
  onEditCheckpoint,
  onEditTechnicalObject,
  onEditOperations,
  onDeleteCheckpoint,
  onDeleteTechnicalObject,
  onDeleteOperations,
  onUpdateCustomAction,
  toggleCollapseCheckpoint,
}) => {
  const classes = useStyles();

  const [isHoverBlocked, setIsHoverBlocked] = useState(false);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setIsHoverBlocked(false);
    const sortedItems = arrayMove(items, oldIndex, newIndex).map((i, idx) => ({ ...i, order: idx + 1 }));
    setSelected(sortedItems);
  };

  return (
    <SortableList
      updateBeforeSortStart={() => setIsHoverBlocked(true)}
      isHoverBlocked={isHoverBlocked}
      lockToContainerEdges
      lockAxis="y"
      axis="y"
      useDragHandle
      items={items}
      onSortEnd={onSortEnd}
      helperClass={classes.boxWrapperDrag}
      isChangeable={withButtons}
      onView={onView}
      techPlaceId={techPlaceId}
      isReadonlyByMode={isReadonlyByMode}
      onEditCheckpoint={onEditCheckpoint}
      onEditTechnicalObject={onEditTechnicalObject}
      onEditOperations={onEditOperations}
      onDeleteCheckpoint={onDeleteCheckpoint}
      onDeleteTechnicalObject={onDeleteTechnicalObject}
      onDeleteOperations={onDeleteOperations}
      onUpdateCustomAction={onUpdateCustomAction}
      toggleCollapseCheckpoint={toggleCollapseCheckpoint}
    />
  );
};

CheckpointBox.propTypes = {
  techPlaceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  withButtons: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      number: PropTypes.number,
      title: PropTypes.string,
    }),
  ),
  onView: PropTypes.func.isRequired,
  onEditCheckpoint: PropTypes.func.isRequired,
  onDeleteCheckpoint: PropTypes.func.isRequired,
  onDeleteTechnicalObject: PropTypes.func.isRequired,
  onDeleteOperations: PropTypes.func.isRequired,
  onEditTechnicalObject: PropTypes.func.isRequired,
  onEditOperations: PropTypes.func.isRequired,
  onUpdateCustomAction: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  isReadonlyByMode: PropTypes.bool.isRequired,
  toggleCollapseCheckpoint: PropTypes.func.isRequired,
};

CheckpointBox.defaultProps = {
  withButtons: false,
  items: [],
};

export default CheckpointBox;
