/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Panel, { PanelBox } from 'Common/widgets/Layout/Panel/Panel';
import {
  TPS_REPAIR, TPS_INSPECTION, PL, COAL
} from 'Shared/constants/business';

import CoalFAQ from './COAL_FAQ';
import InspectionFAQ from './TPS_INSPECTION_FAQ';
import RepairFAQ from './TPS_REPAIR_FAQ';
import PlFAQ from './PL_FAQ';

import useStyles from './styles';

const FAQ = ({ businessType }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const getBusinessFAQ = () => {
    switch (businessType) {
      case TPS_REPAIR:
        return <RepairFAQ />;
      case TPS_INSPECTION:
        return <InspectionFAQ />;
      case PL:
        return <PlFAQ />;
      case COAL:
        return <CoalFAQ />;
      default:
        return null;
    }
  };

  return (
    <Panel labelView={t('FAQ')}>
      <PanelBox className={classes.page}>
        <h1 className={classes.title}>Общие вопросы</h1>

        <div className={classes.question}>
          - Я прочитал все вопросы, но нужного мне ответа тут нет. Что мне делать?
        </div>
        <div className={classes.answer}>
          <p>
            Если интересующий Вас вопрос не описан в руководстве пользователя и его нет в этом
            списке часто задаваемых вопросов, Вам необходимо создать заявку на портале. Для этого:
          </p>
          <ol>
            <li>
              Войдите на портал
              &nbsp;
              <a className={classes.link} href="https://gwt.dtek.com/logon/LogonPoint/tmindex.html" target="_blank" rel="noreferrer">gwt.dtek.com</a>
              &nbsp;
              и перейдите в
              &nbsp;
              <a className={classes.link} href="https://itsm.dtek.com/maximo/ui/login" target="_blank" rel="noreferrer">центр запуска</a>
              &nbsp;
              центр запуска.

            </li>
            <li>
              В разделе “ИТ услуги” выберите “Создать заявку на услуги” и создайте заявку с
              интересующим Вас вопросом.
            </li>
          </ol>
        </div>

        <div className={classes.question}>
          - Как создать нового пользователя для веб приложения?
        </div>
        <div className={classes.answer}>
          <p>
            Новые пользователи создаются по заявкам на доступ от Вашей организации. Также
            согласно заявке ему сразу выдаются определенные права доступа. Обратитесь в
            соответствующий отдел для создания заявки для добавления нового пользователя.
          </p>
        </div>

        <div className={classes.question}>
          - Как изменить права существующего пользователя веб приложения?
        </div>
        <div className={classes.answer}>
          <p>
            Изменения прав существующего пользователя выполняются по заявкам на доступ от
            Вашей  организации.  Обратитесь  в  соответствующий  отдел  для
            добавления/изменения необходимых прав доступа.
          </p>
        </div>

        <div className={classes.question}>
          - Как удалить ненужного пользователя из веб приложения?
        </div>
        <div className={classes.answer}>
          <p>
            Ненужные пользователи удаляются по заявкам на доступ от Вашей организации.
            Обратитесь в соответствующий отдел для создания заявки для удаления ненужного
            пользователя.
          </p>
        </div>

        <div className={classes.question}>
          - Как войти в веб приложение?
        </div>
        <div className={classes.answer}>
          <p>
            Для входа в приложении перейдите в браузере по ссылке
            &nbsp;
            <a
              className={classes.link}
              href="https://sleipnir-frontend-prod.azurewebsites.net/"
              target="_blank"
              rel="noreferrer"
            >
              https://sleipnir-frontend-prod.azurewebsites.net/
            </a>
            &nbsp;
            и введите свои  логин/пароль. Логином выступает адрес
            корпоративной почты, например IvanovOM@dtek.com.
          </p>
        </div>

        <div className={classes.question}>
          - После ввода логина/пароля очень долго входит в приложение или не входит вообще?
        </div>
        <div className={classes.answer}>
          <ol>
            <li>
              Для  входа  в  приложение необходимо  использовать  только  Браузер  Google
              Chrome или Internet Explorer/Microsoft Edge.
            </li>
            <li>Проверьте наличие интернет соединения.</li>
            <li>Попробуйте перезагрузить страницу и войти заново.</li>
            <li>
              Если  всё  вышеперечисленное  не  помогло,  обратитесь  к техническому
              специалисту  по  сетям  Вашей  организации. Возможно  есть  проблемы  с
              настройками сети Интернет.
            </li>
          </ol>
        </div>

        <div className={classes.question}>
          - Как выйти из веб приложения?
        </div>
        <div className={classes.answer}>
          <p>
            Для выхода нажмите  на кнопку выхода рядом с отображаемым ФИО в верхней
            правой части окна приложения.
          </p>
        </div>

        <div className={classes.question}>
          - Как зайти под другим пользователем?
        </div>
        <div className={classes.answer}>
          <p>
            Для смены пользователя сначала выйдите из веб приложения с помощью кнопки
            выхода,  после  чего  выполните  вход  с  помощью  логина/пароля другого
            пользователя.
          </p>
        </div>

        <div className={classes.question}>
          - Почему в боковом меню разное количество разделов?
        </div>
        <div className={classes.answer}>
          <p>
            У каждого пользователя веб приложения разные права в системе. В зависимости от
            этих прав, пользователям доступны соответствующие разделы. Права выдаются
            заявками на доступ от Вашей организации.
          </p>
        </div>

        <div className={classes.question}>
          - Кто такие исполнители?
        </div>
        <div className={classes.answer}>
          <p>
            Сотрудники  ДТЕК,  пользователи мобильного  устройства  с  установленным
            приложением «Мобильный обходчик».
          </p>
        </div>

        <hr />

        {getBusinessFAQ()}
      </PanelBox>
    </Panel>
  );
};

FAQ.propTypes = {
  businessType: PropTypes.oneOf([TPS_REPAIR, TPS_INSPECTION, PL, COAL]).isRequired,
};

export default FAQ;
