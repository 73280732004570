import React from 'react';

import useStyles from './styles';

const RepairFAQ = () => {
  const classes = useStyles();

  return (
    <>
      <h1 className={classes.title}>Вопросы по бизнесу “ТЭС Ремонты”</h1>

      <div className={classes.question}>
        - Как создавать задачи для «ТЭС Ремонты»?
      </div>
      <div className={classes.answer}>
        <p>
          Задачи для «ТЭС Ремонты» не создаются вручную, а получаются непосредственно из SAP. В веб приложении в разделе «Задачи» осуществляется только управление задачами и назначение исполнителям.
        </p>
      </div>

      <div className={classes.question}>
        - Почему кнопка «Действия» в окне задач в «ТЭС Ремонты» серая или синяя?
      </div>
      <div className={classes.answer}>
        <p>
          Если кнопка «Действия» серая, то для такой задачи необходимо выбрать исполнителя и установить дату выполнения, чтобы назначить дальше в работу. Если кнопка «Действия» синяя – по задаче выбраны исполнитель и дата выполнения и её можно назначать в работу.
        </p>
      </div>

      <div className={classes.question}>
        - Почему кнопка «Назначить» при просмотре задачи в «ТЭС Ремонты» неактивна?
      </div>
      <div className={classes.answer}>
        <p>
          Необходимо выбрать исполнителя и установить дату выполнения, чтобы назначить дальше в работу.
        </p>
      </div>

      <div className={classes.question}>
        - При выборе исполнителя для задачи в «ТЭС Ремонты» нет исполнителя или не мои?
      </div>
      <div className={classes.answer}>
        <p>
          Доступность выбора исполнителей регулируется Вашими правами доступа в систему. Права выдаются заявками на доступ от Вашей организации. Обратитесь в соответствующий отдел для добавления/изменения необходимых прав доступа.
        </p>
      </div>

      <div className={classes.question}>
        - При создании бригады в «ТЭС Ремонты» нет исполнителя или не мои?
      </div>
      <div className={classes.answer}>
        <p>
          Доступность выбора исполнителей регулируется Вашими правами доступа в систему. Права выдаются заявками на доступ от Вашей организации. Обратитесь в соответствующий отдел для добавления/изменения необходимых прав доступа.
        </p>
      </div>

      <div className={classes.question}>
        - Не могу редактировать/удалить бригаду в «ТЭС Ремонты»?
      </div>
      <div className={classes.answer}>
        <p>
          Бригада, которую Вы хотите изменить/удалить используется в задаче, которая находится в работе у исполнителя. Изменить/удалить бригаду возможно только если она не используется в других задачах в работе.
        </p>
      </div>

      <div className={classes.question}>
        - При выборе типа отчета статистики в «ТЭС Ремонты» нет нужного типа или не мои?
      </div>
      <div className={classes.answer}>
        <p>
          Доступность выбора некоторых полей регулируется Вашими правами доступа в систему. Права выдаются заявками на доступ от Вашей организации. Обратитесь в соответствующий отдел для добавления/изменения необходимых прав доступа.
        </p>
      </div>

      <div className={classes.question}>
        - Где искать загруженный отчет в «ТЭС Ремонты»?
      </div>
      <div className={classes.answer}>
        <p>
          Созданный excel файл сохраняется на Ваш компьютер в зависимости от настроек браузера, установленных на Вашем предприятии. Обратитесь к техническому специалисту Вашего предприятии, если не сможете найти самостоятельно.
        </p>
      </div>
    </>
  );
};

export default RepairFAQ;
