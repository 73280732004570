import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, FormControlLabel, FormLabel, RadioGroup, Radio as MaterialRadio
} from '@material-ui/core';

import useStyles from './styles';

const Radio = ({
  value,
  name,
  label,
  onChange,
  options,
  className,
  style,
  fullWidth,
  'data-test': dataTest,
}) => {
  const classes = useStyles({
    value,
  });

  return (
    <FormControl
      classes={{ root: classes.formControlRadio }}
      margin="dense"
      fullWidth={fullWidth}
      {...style && { style }}
      {...className && { className }}
      {...dataTest && { 'data-test': dataTest }}
    >
      <FormLabel className={classes.formLabelRadio}>{label}</FormLabel>
      <RadioGroup
        row
        aria-label={name}
        name={name}
        value={value}
        onChange={event => onChange(event.target.value)}
      >
        {options.map(({ value: optionValue, enumValue, title: optionTitle } = {}) => (
          <FormControlLabel
            key={optionValue}
            data-test={enumValue || value}
            data-active={optionValue === value}
            value={optionValue}
            label={optionTitle}
            className={classes.labelRadio}
            control={(<MaterialRadio color="primary" className={classes.radio} />)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

Radio.defaultProps = {
  label: '',
  value: '',
  options: [],
  onChange: () => {},
  'data-test': undefined,
  className: undefined,
  style: undefined,
  fullWidth: false,
};

Radio.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    title: PropTypes.string,
  })),
  onChange: PropTypes.func,
  'data-test': PropTypes.string,
  style: PropTypes.shape({
    marginTop: PropTypes.number,
  }),
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default Radio;
