import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  ButtonBase, Tooltip,
} from '@material-ui/core';
import Switch from 'Common/components/Forms/Input/Switch';
import OverflowTip from 'Common/widgets/OverflowTip/OverflowTip';

import useStyles from './styles';

const PauseReason = ({
  onChangeIsPhotoRequired,
  isPhotoRequiredUpdating,
  onChangeIsVisible,
  handleEdit,
  data: {
    isVisible = true,
    attributes: { isPhotoRequired = false, systemReasonKey = false },
    id = '',
    title = '',
    externalCode = '',
  } = {},
  isVisibleUpdating,
} = {}) => {
  const { t } = useTranslation();
  const classes = useStyles({ isVisibleUpdating, isPhotoRequiredUpdating });

  const pauseReasonTitle = systemReasonKey ? `${title} ${t('SYSTEM_PAUSE_REASON')}` : title;

  return (
    <ButtonBase
      disableRipple
      onClick={() => {
        if (!isVisibleUpdating) handleEdit(id);
      }}
      className={classes.rowButton}
    >
      <div className={classes.root}>
        <div className={classes.isVisibleSwitcherWrapper}>
          <Tooltip title={isVisible ? t('IS_VISIBLE_IN_MOBILE_APP') : t('IS_NOT_VISIBLE_IN_MOBILE_APP')}>
            <div className={classes.switcherWrapper}>
              <Switch
                className={classes.switcher}
                name="visibility"
                checked={isVisible}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onChangeIsVisible(id, !isVisible);
                }}
                isUpdating={isVisibleUpdating}
                data-test="visibility"
              />
            </div>
          </Tooltip>
        </div>
        <div className={classes.nameLabel}>
          <OverflowTip
            maxLine={2}
            tooltipProps={{ placement: 'bottom' }}
            tooltipTitle={pauseReasonTitle}
            text={pauseReasonTitle}
            data-test="pauseReasonTitle"
          />
        </div>
        <div className={classes.codeLabel}>
          <OverflowTip
            maxLine={2}
            tooltipProps={{ placement: 'bottom' }}
            tooltipTitle={externalCode}
            text={externalCode}
            data-test="externalCode"
          />
        </div>
        <div className={classes.isPhotoRequiredSwitcherWrapper}>
          <Tooltip title={isPhotoRequired ? t('PHOTO_IS_REQUIRED') : t('PHOTO_IS_NOT_REQUIRED')}>
            <div className={classes.switcherWrapper}>
              <Switch
                name="photoRequirement"
                checked={isPhotoRequired}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (!isPhotoRequiredUpdating) onChangeIsPhotoRequired(id, !isPhotoRequired);
                }}
                isUpdating={isPhotoRequiredUpdating}
                data-test="visibility"
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </ButtonBase>
  );
};

PauseReason.defaultProps = {
  data: {
    id: '',
    isVisible: false,
  },
  onChangeIsVisible: () => {},
  isVisibleUpdating: false,
  onChangeIsPhotoRequired: () => {},
  isPhotoRequiredUpdating: false,
  handleEdit: () => {},
};

PauseReason.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    isVisible: PropTypes.bool,
  }),
  onChangeIsVisible: PropTypes.func,
  isVisibleUpdating: PropTypes.bool,
  onChangeIsPhotoRequired: PropTypes.func,
  isPhotoRequiredUpdating: PropTypes.bool,
  handleEdit: PropTypes.func,
};

export default PauseReason;
