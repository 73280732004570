import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutline, Edit, CancelOutlined
} from '@material-ui/icons';
import {
  Tooltip, IconButton
} from '@material-ui/core';
import TextInput from 'Common/components/Forms/Input/Text';
import ActionButton from 'Common/components/Forms/Button/components/Action/Action';

import useStyles from './styles';

const PlannerCustomAction = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    onUpdateCustomAction,
    technicalObjectId,
    technicalObjectCustomAction,
    onDeleteOperations,
    isChangeable,
    isHoverBlocked,
  } = props;
  const [isCustomActionEditMode, setIsCustomActionEditMode] = useState(false);
  const [customActionValue, setCustomActionValue] = useState(technicalObjectCustomAction || '');

  useEffect(() => {
    if (isCustomActionEditMode) setCustomActionValue(technicalObjectCustomAction || '');
  }, [isCustomActionEditMode]);
  return (
    <div className={classes.customOperationWrapper}>
      {!isCustomActionEditMode && (
        <>
          <div className={classes.operationTypeHeader}>
            <div className={classes.technicalObjectInfoTitle}>{t('CUSTOM_TASK')}</div>
            {isChangeable && !isHoverBlocked && (
              <div className={classes.headerButtons}>
                <Tooltip title={t('DELETE_EQUIPMENT_TASK')}>
                  <IconButton
                    className={classes.deleteButton}
                    onClick={() => onDeleteOperations('CUSTOM_ACTION', technicalObjectId)}
                    size="small"
                    aria-label="settings"
                    data-test="deleteButton"
                  >
                    <DeleteOutline />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('EDIT_EQUIPMENT_TASK')}>
                  <IconButton
                    className={classes.editButton}
                    onClick={() => setIsCustomActionEditMode(true)}
                    size="small"
                    aria-label="settings"
                    data-test="editButton"
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
          <div className={classes.customActionReadOnly}>{technicalObjectCustomAction}</div>
        </>
      )}
      {isCustomActionEditMode && (
        <>
          <TextInput
            name="customActionInput"
            label={t('NOT_STANDARD_TASK_DESCRIPTION')}
            value={customActionValue}
            onChange={setCustomActionValue}
            multiline
            rows={4}
            // required
            {...isCustomActionEditMode && {
              InputEndAdornment: (
                <IconButton
                  size="small"
                  className={classes.inputClearButton}
                  onClick={() => setCustomActionValue('')}
                  data-test="remove"
                >
                  <CancelOutlined className={classes.clearIcon} />
                </IconButton>
              )
            }}
            // InputEndAdornment={(
            //   <IconButton
            //     size="small"
            //     className={classes.inputClearButton}
            //     onClick={() => setCustomActionValue('')}
            //     data-test="remove"
            //   >
            //     <CancelOutlined className={classes.clearIcon} />
            //   </IconButton>
            // )}
          />
          <div className={classes.actionButtonsWrapper}>
            <ActionButton
              text={t('CANCEL')}
              type="minimised"
              handleClick={() => setIsCustomActionEditMode(false)}
              data-test="cancel"
              style={{ marginLeft: 'auto' }}
            />
            <ActionButton
              text={t('SAVE')}
              type="strong"
              handleClick={() => {
                onUpdateCustomAction(customActionValue, technicalObjectId);
                setIsCustomActionEditMode(false);
              }}
              data-test="confirm"
              style={{ marginLeft: 16 }}
            />
          </div>
        </>
      )}
    </div>
  );
};

PlannerCustomAction.propTypes = {
  onUpdateCustomAction: PropTypes.func,
  onDeleteOperations: PropTypes.func,
  technicalObjectId: PropTypes.number.isRequired,
  technicalObjectCustomAction: PropTypes.string,
  isChangeable: PropTypes.bool,
  isHoverBlocked: PropTypes.bool,
};

PlannerCustomAction.defaultProps = {
  onUpdateCustomAction: () => {},
  onDeleteOperations: () => {},
  technicalObjectCustomAction: '',
  isChangeable: true,
  isHoverBlocked: false,
};

export default PlannerCustomAction;
