import request from 'Services/api/request';


export default class Agent {
  constructor(entity = '', type = '') {
    this.entity = entity;
    this.type = type;
    this.request = request;
    this.NO_VALID_PATH = 'Provide valid path for "get" method!';
  }

  get = (query = '') => (
    this.request({
      url: `/${this.entity}/view`,
      method: 'GET',
      params: query
    })
  );

  getByUrl = (url, query = '') => {
    if (!url || typeof url !== 'string') {
      throw new Error(this.NO_VALID_PATH);
    }

    return this.request({
      url,
      method: 'GET',
      params: query
    });
  };

  create = body => (
    this.request({
      url: `/${this.entity}/manage/create`,
      method: 'POST',
      data: body
    })
  );

  update = body => (
    this.request({
      url: `/${this.entity}/manage/update`,
      method: 'PUT',
      data: body
    })
  )
}
