import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

import useStores from 'Store/useStores';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import HeaderLayout from 'Common/widgets/Layout/Header/Header';
import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import DataTable from 'Common/components/DataTable/DataTable';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import * as routes from 'Shared/constants/routes';
import useManageEntityModal from 'Common/components/Modal/ManageEntityModal/useManageEntityModal';
import useTableBodyHeight from 'Src/utils/table/useTableBodyHeight';
import tableConfig from 'Business/tpsInspections/config/plannerTasksTableConfig';
import PlannerTaskActionButtons from 'Business/tpsInspections/components/PlannerTaskActionButtons';
import PlannerTaskPauseModal from 'Src/business/tpsInspections/components/PlannerTaskPauseModal';

const PlannerTaskList = observer(() => {
  const {
    setManageEntityModalVisibility, setOnManageEntityModalCloseCallback, setManageEntityModalState, renderManageEntityModal
  } = useManageEntityModal();
  const {
    plannerTaskListStore: {
      isLoading,
      loadPlannerTasks,
      plannerTasks,
      totalPages,
      hasNextPage,
      hasPreviousPage,
      pageIndex,
    },
    plannerTaskStore: {
      isDeleting,
      deletePlannerTask,
      changeStatus,
      loadPauseDictionary,
      pauseDictionary,
      isUpdatingReadyStatus,
      isUpdatingOnPauseStatus,
    },
    notificationStore: { enqueueSnackbar },
  } = useStores();

  const [editableTask, setEditableTask] = useState({});
  const { t } = useTranslation();
  const history = useHistory();
  const bodyHeight = useTableBodyHeight();
  const location = useLocation();
  const { state } = location;
  const { paginationParams: { pageNumber, perPage } = {} } = state || {};
  const disableClick = isUpdatingReadyStatus || isUpdatingOnPauseStatus;

  const translatedTableConfig = tableConfig(t);

  const createHandler = () => {
    history.push({
      pathname: routes.CREATE_PLANNER_TASK
    });
  };

  const editHandler = (event, row) => {
    if (disableClick) return;
    history.push({
      pathname: `${routes.EDIT_PLANNER_TASK}/${row.id}`
    });
  };

  const onRowClickHandler = (event, rowData = {}, { page, perPage: itemsOnPage } = {}) => {
    if (disableClick) return;
    if (rowData.id) {
      history.push({
        pathname: `${routes.VIEW_PLANNER_TASK}/${rowData.id}`,
        state: { paginationParams: { pageNumber: page, perPage: itemsOnPage } }
      });
    }
  };

  const handleDeletePlannerTask = plannerTaskId => async () => {
    const { error } = await deletePlannerTask(plannerTaskId) || {};

    if (error) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('REQUEST_DEFAULT_ERROR')
            }]
          }],
        },
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('TASK_SUCCESSFULLY_REMOVED')
          }]
        }],
      },
      variant: 'success',
    });
    loadPlannerTasks(pageNumber, perPage);
  };

  const deleteItem = (id, title) => {
    if (disableClick) return;
    setManageEntityModalState(() => ({
      title: t('DELETING_PLANNER_TASK'),
      entityName: title,
      message: `${t('CONFIRM_DELETING_MESSAGE')} ${t('JOB').toLowerCase()}?`,
      type: 'action',
      actionButtonTitle: t('DELETE'),
    }));
    setOnManageEntityModalCloseCallback(() => handleDeletePlannerTask(id));
    setManageEntityModalVisibility(true);
  };

  useEffect(() => {
    loadPlannerTasks(pageNumber, perPage);
    loadPauseDictionary();
    history.replace({
      state: {},
    });
  }, []);

  return (
    <>
      {(isDeleting || isUpdatingReadyStatus || isUpdatingOnPauseStatus || isLoading) && <ProgressBar />}
      <PaperLayout autoHeight>
        <HeaderLayout title={t('PLANNER_TASKS')} sticky>
          <HeaderButton
            title={t('CREATE_PLANNER_TASK')}
            type="add"
            handleClick={createHandler}
          />
        </HeaderLayout>
        <DataTable
          initialPage={pageNumber}
          initialPerPage={perPage}
          pageIndex={pageIndex}
          titleSingle={t('TASK_ACCUSATIVE')}
          columns={[
            ...translatedTableConfig,
            {
              field: 'actions',
              title: '',
              width: '15%',
              cellStyle: {
                whiteSpace: 'normal',
                width: '15%',
              },
              headerStyle: {
                whiteSpace: 'normal',
                width: '15%'
              },
              // eslint-disable-next-line react/prop-types
              render: ({ status, id, title }) => (
                <PlannerTaskActionButtons
                  status={status}
                  id={id}
                  onDelete={() => deleteItem(id, title)}
                  onPause={() => setEditableTask({ id, title })}
                  onStart={async () => {
                    await changeStatus({ id, status: 'Ready' });
                    loadPlannerTasks(pageNumber, perPage);
                  }}
                  isUpdatingReadyStatus={isUpdatingReadyStatus}
                  isUpdatingOnPauseStatus={isUpdatingOnPauseStatus}
                />
              )
            },
          ]}
          data={plannerTasks}
          tableBodyHeight={bodyHeight}
          onRowClick={onRowClickHandler}
          onEdit={editHandler}
          count={totalPages}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          onPageChange={loadPlannerTasks}
          onPerPageChange={loadPlannerTasks}
          actions={[]}
          dataTestRowSuffixEntityName="automatedTaskId"
        />
      </PaperLayout>
      {renderManageEntityModal()}
      {!isEmpty(editableTask) && (
        <PlannerTaskPauseModal
          title={editableTask.title}
          reasons={pauseDictionary}
          isOpened
          onClose={() => {
            setEditableTask('');
          }}
          onSave={async (pauseReasonId, pauseReasonComment) => {
            setEditableTask('');
            await changeStatus({
              id: editableTask.id, status: 'OnPause', pauseReasonId, pauseReasonComment
            });
            loadPlannerTasks(pageNumber, perPage);
          }}
        />
      )}
    </>
  );
});

export default PlannerTaskList;
