import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Cancel, CheckCircle } from '@material-ui/icons';

import { ReactComponent as Pending } from 'Src/assets/pending.svg';

import useStyles from './styles';

const PlannerTaskExecutionResult = ({ executionResult }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  switch (executionResult) {
    case 'Successed':
      return (
        <div className={clsx(classes.wrapper, classes.success)}>
          <CheckCircle />
          <span>{t('SUCCESSFULLY')}</span>
        </div>
      );

    case 'Error':
      return (
        <div className={clsx(classes.wrapper, classes.error)}>
          <Cancel />
          <span>{t('ERROR')}</span>
        </div>
      );

    case 'Pending':
      return (
        <div className={clsx(classes.wrapper, classes.pending)}>
          <Pending />
          <span>{t('PENDING')}</span>
        </div>
      );

    default:
      return null;
  }
};

PlannerTaskExecutionResult.propTypes = {
  executionResult: PropTypes.oneOf(['success', 'error', 'pending']).isRequired,
};

export default PlannerTaskExecutionResult;
