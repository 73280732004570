import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

const PlantStorageInput = ({ inputRef, ...other }) => (
  <MaskedInput
    {...other}
    ref={(ref) => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask={[/[a-zA-Z0-9А-Яа-я]/, ' ', /[a-zA-Z0-9А-Яа-я]/, ' ', /[a-zA-Z0-9А-Яа-я]/, ' ', /[a-zA-Z0-9А-Яа-я]/]}
    placeholderChar="_"
    placeholder="_ _ _ _"
    guide
    keepCharPositions
  />
);

PlantStorageInput.propTypes = {
  inputRef: PropTypes.func.isRequired
};

export default PlantStorageInput;
