import React from 'react';
import PropTypes from 'prop-types';
import useStores from 'Store/useStores';
import TextInput from 'Common/components/Forms/Input/Text';
import {
  find,
} from 'lodash';

const MeasurementComment = ({
  comment, onCommentChange, readByMode, inputName
}) => {
  const {
    tpsReportStore: {
      measuringPoints,
    },
  } = useStores();

  const {
    value, sapCode, isRequired, hasError
  } = comment;

  const measurementInfo = find(measuringPoints, { sapCode }) || {};
  const { title = '' } = measurementInfo;

  return (
    <TextInput
      isReadonly={readByMode}
      required={isRequired}
      error={Boolean(hasError)}
      multiline
      rows={readByMode ? 1 : 4}
      rowsMax={4}
      label={title}
      value={value}
      name={inputName}
      onChange={onCommentChange}
      OptionRendererProps={{ withOverflowTip: false }}
      data-test={`measurementCommentSapCode_${sapCode}`}
    />
  );
};

MeasurementComment.defaultProps = {
  onCommentChange: () => {},
  readByMode: false,
};

MeasurementComment.propTypes = {
  inputName: PropTypes.string.isRequired,
  comment: PropTypes.shape({
    value: PropTypes.string,
    sapCode: PropTypes.string,
    isRequired: PropTypes.bool,
    hasError: PropTypes.bool,
  }).isRequired,
  onCommentChange: PropTypes.func,
  readByMode: PropTypes.bool,
};

export default MeasurementComment;
