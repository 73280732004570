import {
  PeopleAltOutlined,
  DeleteOutline,
  Edit,
  Comment
} from '@material-ui/icons';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';


const icons = {
  people: PeopleAltOutlined,
  delete: DeleteOutline,
  edit: Edit,
  comment: Comment,
};

const useStyles = makeStyles(theme => ({
  common: {
    fontSize: 24,
  },
  primary: {
    color: theme.palette.lightPrimaryHover,
  },
  default: {
    color: theme.palette.black70,
  }
}));

const Icon = ({ variant, color }) => {
  const classes = useStyles();
  const IconElement = icons[variant];

  return (
    <IconElement className={clsx(classes.common, classes[color])} />
  );
};

Icon.propTypes = {
  variant: PropTypes.string.isRequired,
  color: PropTypes.string,
};

Icon.defaultProps = {
  color: 'default'
};

export default Icon;
