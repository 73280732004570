import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  headerActionButtons: {
    width: 56,
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionButton: {
    color: theme.palette.black70,
    '&:hover': {
      color: theme.palette.primaryLight,
    }
  },
  redColor: {
    color: theme.palette.negative,
  },
}));

export default useStyles;
