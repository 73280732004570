import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { differenceInMinutes } from 'date-fns';
import {
  isEmpty, find, filter, isArray, toSafeInteger, head, get, isNaN,
} from 'lodash';
import { Grid } from '@material-ui/core';
import useStores from 'Store/useStores';
import FormSwitch from 'Common/components/Forms/Input/FormSwitch';
import {
  PanelBox,
} from 'Common/widgets/Layout/Panel/Panel';
import OperationView from 'Common/widgets/OperationView/OperationView';
import OperationRejection from 'Business/coal/components/OperationRejection';
import timeUnitsTranslator, { convertDurationFromHoursToMinutes } from 'Business/coal/utils/timeUnitsConverter';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const OperationsBlock = (props) => {
  const { t } = useTranslation();
  const {
    coalTaskStore: {
      taskData: { isStandard, operations, repairTypeSapCode } = {},
      updateDataField,
      operationsDictionary,
    },
  } = useStores();

  const { mode, isReadonlyByMode } = props;
  const [showMaterialValues, setShowMaterialValues] = useState(false);

  const onChangeOperations = (operation) => {
    const { operationSapCode } = operation;
    const isInOperations = find(operations, { operationSapCode });
    if (isInOperations) {
      updateDataField('operations', filter(operations, ({ operationSapCode: sapCode }) => sapCode !== operationSapCode));
    } else updateDataField('operations', [...operations, operation]);
  };

  const classes = useStyles({ t });
  let operationsToRender;

  switch (mode) {
    case 'create':
      operationsToRender = repairTypeSapCode ? operationsDictionary : [];
      break;
    case 'view':
      operationsToRender = operations;
      break;
    case 'edit':
      operationsToRender = repairTypeSapCode ? operationsDictionary : [];
      break;
    case 'control':
      operationsToRender = operations;
      break;
    default:
      operationsToRender = [];
  }

  useEffect(() => {
    if (isEmpty(operationsToRender) && showMaterialValues) setShowMaterialValues(false);
  }, [operationsToRender]);

  const getOperationViewConfig = ({
    planDuration, status, durationUnit, reason, startTime, finishTime,
  }) => {
    const {
      duration: planDurationInMinutes,
      durationUnit: convertedToMinutesDurationUnit
    } = convertDurationFromHoursToMinutes({ duration: planDuration, durationUnit });
    const duration = differenceInMinutes(new Date(finishTime), new Date(startTime));
    const convertedDurationUnit = timeUnitsTranslator(t, convertedToMinutesDurationUnit);
    let operationViewConfig;
    switch (status) {
      case 'DONE':
        operationViewConfig = {
          bottom: {
            left: `${t('PLAN_TIME')}: ${planDurationInMinutes} ${convertedDurationUnit}`,
            right: isNaN(duration) ? '' : `${t('ACTUAL_TIME')}: ${duration} ${t('MIN')}`,
          }
        };
        break;
      case 'REJECTED':
        operationViewConfig = {
          bottom: {
            left: (
              <>
                <div>
                  {`${t('PLAN_TIME')}: ${planDurationInMinutes} ${convertedDurationUnit}`}
                </div>
                {Boolean(reason) && (
                  <div>
                    <OperationRejection rejectionType="REJECTED" reason={reason} />
                  </div>
                )}
              </>
            ),
          }
        };
        break;
      case 'ON_HOLD':
        if (!planDuration) return;
        operationViewConfig = {
          bottom: {
            left: (
              <>
                <div>
                  {`${t('PLAN_TIME')}: ${planDurationInMinutes} ${convertedDurationUnit}`}
                </div>
                {Boolean(reason) && (
                  <div>
                    <OperationRejection rejectionType="ON_HOLD" reason={reason} />
                  </div>
                )}
              </>
            ),
          }
        };
        break;
      default:
        operationViewConfig = {
          bottom: {
            left: `${t('PLAN_TIME')}: ${planDurationInMinutes} ${convertedDurationUnit}`,
          }
        };
    }
    return operationViewConfig;
  };

  const getWorkLog = (subOperations) => {
    if (!isArray(subOperations)) return {};
    if (isEmpty(subOperations)) return {};
    const subOperation = head(subOperations);
    const workLogs = get(subOperation, 'worklogs', []);
    const workLog = head(workLogs);
    return workLog || {};
  };

  if ((mode === 'view' || mode === 'control') && isEmpty(operationsToRender)) return null;

  return (
    <PanelBox
      className={classes.panelBox}
      boxStyles={{ padding: 0, maxWidth: showMaterialValues ? 'inherit' : 500 }}
    >
      <div className={classes.blockHeader}>
        <Grid container>
          <Grid
            item
            xs={6}
            className={classes.blockHeaderLabel}
          >
            {t('OPERATIONS_FROM_TC')}
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.blockHeaderButton}
          >
            {!isEmpty(operationsToRender) && (
              <FormSwitch
                label={t('SHOW_MATERIAL_VALUES')}
                name="showMaterialValues"
                checked={showMaterialValues}
                onChange={(e) => {
                  const checked = get(e, 'target.checked');
                  setShowMaterialValues(checked);
                }}
                data-test="switch-showMaterialValues"
              />
            )}
          </Grid>
        </Grid>
      </div>
      <div>
        {isEmpty(operationsToRender) && (
          <div className={classes.operationsHelperText}>
            {t('SELECT_EQUIPMENT_AND_TYPE_OF_REPAIR_TO_DISPLAY_OPERATIONS')}
          </div>
        )}
        { operationsToRender.map((operation = {}) => {
          const {
            operationSapCode,
            title: operationTitle,
            description,
            materialValues,
            status,
            planDuration = 0,
            durationUnit,
            factTime = 0,
            subOperations,
          } = operation;

          const hasMaterialsValues = isArray(materialValues) && !isEmpty(materialValues);
          const {
            startTime, finishTime, pauseReason, stopReason
          } = getWorkLog(subOperations);
          const checked = Boolean(find(operations, { operationSapCode }));

          return (
            <Grid
              key={operationSapCode}
              className={showMaterialValues ? classes.operationRowWithMaterialValues : classes.operationRow}
              direction="row"
              container
            >
              <Grid item xs={showMaterialValues ? 6 : 12}>
                <div style={{
                  maxWidth: 500, margin: '0 auto', paddingLeft: 24, paddingRight: 36
                }}
                >
                  <OperationView
                    checked={checked}
                    onClick={!isReadonlyByMode && !isStandard ? () => onChangeOperations(operation) : null}
                    title={description || operationTitle}
                    subTitle={operationSapCode}
                    status={status}
                    counters={getOperationViewConfig({
                      planDuration, factTime, status, durationUnit, reason: pauseReason || stopReason, startTime, finishTime,
                    })}
                  />
                </div>
              </Grid>
              {showMaterialValues && (
                <Grid item xs={6}>
                  <div style={{ margin: '0 auto', paddingLeft: 36, paddingRight: 24 }}>
                    {hasMaterialsValues
                    && materialValues.map((materialValue = {}, index) => {
                      const {
                        materialValueSapCode, description: materialValueDescription, quantity, unit
                      } = materialValue;
                      const materialValueKey = `${operationSapCode}Material${index}`;
                      return (
                        <div
                          key={materialValueKey}
                          className={classes.materialValue}
                        >
                          <OperationView
                            title={materialValueDescription}
                            subTitle={toSafeInteger(materialValueSapCode)}
                            counters={{
                              bottom: {
                                left: `${t('PLANNED_QUANTITY')} : ${quantity} ${unit || ''}`,
                              }
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                  {!hasMaterialsValues && (
                    <div style={{
                      display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center'
                    }}
                    >
                      <div className={classes.noMaterialsValuesLabel}>{t('THERE_ARE_NO_MATERIAL_VALUES')}</div>
                    </div>
                  )}
                </Grid>
              )}
            </Grid>
          );
        })}
      </div>
    </PanelBox>
  );
};

OperationsBlock.propTypes = {
  mode: PropTypes.string.isRequired,
  isReadonlyByMode: PropTypes.bool.isRequired,
};

export default observer(OperationsBlock);
