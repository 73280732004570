import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import useStores from 'Store/useStores';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import HeaderLayout from 'Common/widgets/Layout/Header/Header';
import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import DataTable from 'Common/components/DataTable/DataTable';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import * as routes from 'Shared/constants/routes';
import useManageEntityModal from 'Common/components/Modal/ManageEntityModal/useManageEntityModal';
import useTableBodyHeight from 'Src/utils/table/useTableBodyHeight';
import tableConfig from 'Business/tpsInspections/config/automatedTasksTableConfig';

const AutomatedTasksList = observer(() => {
  const {
    setManageEntityModalVisibility, setOnManageEntityModalCloseCallback, setManageEntityModalState, renderManageEntityModal
  } = useManageEntityModal();
  const {
    inspectionTaskStore: {
      isLoading,
      isDeleting,
      isLoaded,
      fetchInspectionTasks,
      items,
      totalPages,
      hasNextPage,
      pageIndex,
      hasPreviousPage,
      isLoadingByPage,
      deleteTask,
      removeTaskFromList,
    },
    notificationStore: { enqueueSnackbar },
  } = useStores();
  const { t } = useTranslation();
  const history = useHistory();
  const bodyHeight = useTableBodyHeight();
  const location = useLocation();
  const { state } = location;
  const { paginationParams: { pageNumber, perPage } = {} } = state || {};

  const translatedTableConfig = tableConfig(t);

  const createHandler = () => {
    history.push({
      pathname: routes.CREATE_INSPECTIONTASK
        .replace(':action(create)', 'create')
    });
  };

  const editHandler = (event, row) => {
    history.push({
      pathname: routes.EDIT_INSPECTIONTASK
        .replace(':id', row.automatedTaskId)
        .replace(':action(edit)', 'edit')
    });
  };

  const onRowClickHandler = (event, rowData = {}, { page, perPage: itemsOnPage } = {}) => {
    if (rowData.automatedTaskId) {
      history.push({
        pathname: `${routes.INSPECTIONTASKS}/${rowData.automatedTaskId}`,
        state: { paginationParams: { pageNumber: page, perPage: itemsOnPage } }
      });
    }
  };

  const handleDeleteTask = id => () => {
    deleteTask(id).then(() => removeTaskFromList(id));
  };

  const removeHandler = async (event, row = {}) => {
    const { automatedTaskId, title } = row;
    setManageEntityModalState(() => ({
      title: t('ATTENTION_TITLE'),
      entityName: title,
      message: t('CONFIRM_DELETING_AUTOTASK'),
      type: 'action'
    }));
    setOnManageEntityModalCloseCallback(() => handleDeleteTask(automatedTaskId));
    setManageEntityModalVisibility(true);
  };

  const showLoadListError = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('REQUEST_LOAD_LIST_ERROR')
          }]
        }],
      },
      variant: 'error',
    });
  };

  useEffect(() => {
    fetchInspectionTasks(pageNumber, perPage).catch(() => showLoadListError());
    history.replace({
      state: {},
    });
  }, []);

  if (!isLoaded || isLoading) {
    return <ProgressBar />;
  }

  const transform = data => data.map(item => ({ // temporarily disabled
    ...item,
    actions: {
      remove: {
        disabled: true
      }
    }
  }));

  return isLoaded && !isLoading && (
    <>
      {isLoadingByPage && <ProgressBar />}
      {isDeleting && <ProgressBar />}
      <PaperLayout autoHeight>
        {isLoadingByPage && <ProgressBar />}
        <HeaderLayout title={t('AUTO_TASKS')} sticky>
          <HeaderButton
            title={t('CREATE_AUTOTASK')}
            type="add"
            handleClick={createHandler}
          />
        </HeaderLayout>
        <DataTable
          isDataLoaded={isLoaded}
          initialPage={pageNumber}
          initialPerPage={perPage}
          pageIndex={pageIndex}
          titleSingle={t('TASK_ACCUSATIVE')}
          columns={translatedTableConfig}
          data={transform(items)}
          tableBodyHeight={bodyHeight}
          onRowClick={onRowClickHandler}
          onEdit={editHandler}
          onRemove={removeHandler}
          count={totalPages}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          onPageChange={(page, size) => fetchInspectionTasks(page, size).catch(() => showLoadListError())}
          onPerPageChange={(page, size) => fetchInspectionTasks(page, size).catch(() => showLoadListError())}
          actions={['remove']}
          dataTestRowSuffixEntityName="automatedTaskId"
        />
      </PaperLayout>
      {renderManageEntityModal()}
    </>
  );
});

AutomatedTasksList.propTypes = {};

export default AutomatedTasksList;
