import Agent from 'ApiAgents/Agent';
import qs from 'qs';
import getPaginationQueryObject from 'Src/utils/getPaginationQueryObject';


export default class UserAgent extends Agent {
  fetchAllByOrgUnit = (orgUnitId = '') => this.request(
    {
      url: '/orgUnits/view/users',
      method: 'GET',
      params: { orgUnitId },
    },
    'user',
  );

  fetchAllRootOrgUnits = () => this.request(
    {
      url: '/admin/orgUnits/root',
      method: 'GET',
    },
    'user',
  );

  loadUsersByRole = params => this.request(
    {
      url: '/users/view/byRoles',
      method: 'GET',
      params,
    },
    'user',
  );

  loadUsersRoles = () => this.request(
    {
      url: '/admin/roles',
      method: 'GET',
    },
    'user',
  );

  loadUsersStatuses = params => this.request(
    {
      url: '/dictionary/getByKey',
      method: 'GET',
      params,
    },
    'tasks',
  );

  loadUser = userId => this.request(
    {
      url: '/admin/users/details',
      method: 'GET',
      params: { userId },
    },
    'user',
  );

  fetchAll = (query = '', shouldPaginate, pageNumber, pageSize) => {
    const pageQuery = getPaginationQueryObject(shouldPaginate, pageNumber, pageSize);
    return this.request(
      {
        url: 'admin/users',
        method: 'GET',
        params: { ...query, ...pageQuery },
        paramsSerializer: params => qs.stringify(params, { indices: false }),
      },
      'user',
    );
  };

  searchUsers = (searchWord = '', shouldPaginate, pageNumber, pageSize) => {
    const pageQuery = getPaginationQueryObject(shouldPaginate, pageNumber, pageSize);
    if (!searchWord || searchWord.length < 3) return;
    return this.request(
      {
        url: 'admin/users/search',
        method: 'GET',
        params: { searchWord, ...pageQuery },
        paramsSerializer: params => qs.stringify(params),
      },
      'user',
    );
  };

  filterById = (query = {}) => this.request(
    {
      url: 'users/view/byid',
      method: 'GET',
      params: query,
    },
    'user',
  );

  addUser = (data = {}) => this.request(
    {
      url: '/admin/users',
      method: 'POST',
      data,
    },
    'user',
  );

  updateUser = (data = {}) => this.request(
    {
      url: '/admin/users',
      method: 'PUT',
      data,
    },
    'user',
  );

  exportUsersList = params => this.request(
    {
      url: '/admin/users/download',
      method: 'GET',
      responseType: 'blob',
      headers: { 'Content-Disposition': 'attachment', Accept: 'application/vnd.ms-excel' },
      params,
    },
    'user',
    true,
  );

  importUsersList = (file) => {
    const data = new FormData();
    data.append('file', file);
    return this.request(
      {
        url: '/admin/users/upload',
        method: 'POST',
        responseType: 'blob',
        headers: { 'Content-Type': 'multipart/form-data' },
        data,
      },
      'user',
    );
  };
}
