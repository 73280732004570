import Agent from 'ApiAgents/Agent';

export default class VolumeAgent extends Agent {
  loadUnits = () => this.request({
    url: '/units/view',
    method: 'GET',
  }, 'techObject');

  loadOperationKeys = () => this.request({
    url: '/operationKeys',
    method: 'GET',
  }, 'tasks');

  loadAllVolumes = () => this.request({
    url: '/dictionary/getByKey',
    method: 'GET',
    params: { key: 'VolumeType' }
  }, 'tasks');

  loadVolumes = () => this.request({
    url: '/volumes/all',
    method: 'GET',
  }, 'tasks');

  createNewVolume = body => this.request({
    url: '/volumes',
    method: 'POST',
    data: body,
  }, 'tasks');

  editVolume = body => this.request({
    url: '/volumes',
    method: 'PUT',
    data: body,
  }, 'tasks');

  loadVolumeByName = parameterName => this.request({
    url: '/volumes',
    method: 'GET',
    params: { parameterName }
  }, 'tasks');

  deleteVolume = id => this.request({
    url: `/volumes?id=${id}`,
    method: 'DELETE',
  }, 'tasks');

  updateVolumeOrder = body => this.request({
    url: '/volumes/updateOrder',
    method: 'PUT',
    data: body,
  }, 'tasks');
}
