import React, { useEffect, useState } from 'react';
import SelectInput from 'Common/components/Forms/Input/Select';
import { useTranslation } from 'react-i18next';

const uatLang = 'ua-UA';
const ruLang = 'ru-RU';

const LangSelector = () => {
  const { i18n } = useTranslation();
  const storedLanguage = window.localStorage.getItem('i18nextLng');
  const [language, setLanguage] = useState(storedLanguage);

  useEffect(() => {
    if (storedLanguage !== uatLang && storedLanguage !== ruLang) {
      setLanguage(uatLang);
      i18n.changeLanguage(uatLang);
    }
  }, []);

  return (
    <SelectInput
      maxWidth={72}
      disableClearable
      marginTop={4}
      marginBottom={4}
      listBoxMaxHeight={300}
      value={language}
      name="lang"
      onChange={({ id }) => {
        setLanguage(id);
        i18n.changeLanguage(id);
      }}
      options={[{ id: uatLang, label: 'UA' }, { id: ruLang, label: 'RU' }]}
      optionValueKey="id"
      optionTitleKey="label"
      data-test="lang"
    />
  );
};

export default LangSelector;
