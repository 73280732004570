import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import arrayMove from 'array-move';

import useStores from 'Store/useStores';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import BrigadeManageWidget from 'Src/business/tpsRepairs/widgets/BrigadeManageWidget';
import { BRIGADE_DETAILS, BRIGADES } from 'Shared/constants/routes';
import { sortObjectByEntryIndex } from 'Src/utils/sort';
import { searchInArrayOfObjects } from 'Src/utils/search';

const BrigadesManageContainer = observer(({
  match, history, location
}) => {
  const {
    brigadeStore,
    notificationStore: { enqueueSnackbar },
    validationStore: {
      validateRequiredFields,
      isValidFields,
      hasErrors,
    },
  } = useStores();

  const { t } = useTranslation();

  const {
    isLoading,
    isUpdatingBrigadeLoaded,
    updatingBrigade,
    loadUpdatingBrigade,
    clearEditingState,
    loadPotentialAssignees,
    isEmployeesLoaded,
    employeesList: staff
  } = brigadeStore;
  const isEditing = !!match.params.id;
  const INIT_BRIGADIER = { id: null };

  const [brigadeTitle, setBrigadeTitle] = useState('');
  const [employees, setEmployees] = useState({
    checked: [],
    selected: [],
    brigadier: INIT_BRIGADIER
  });
  const [searchQuery, searchQuerySet] = useState('');
  const [filteredStaff, setFilteredStaff] = useState([]);
  const SORT_FIELD = 'fullName';
  const SEARCH_FIELDS = ['fullName'];

  const handleSearchQuery = (phrase) => {
    const searchString = phrase.trim().toLowerCase();
    searchQuerySet(searchString);

    if (phrase.length < 2) {
      setFilteredStaff(staff);
      return;
    }

    const searchedStaff = searchInArrayOfObjects(staff, SEARCH_FIELDS, searchString);
    setFilteredStaff(
      sortObjectByEntryIndex(searchedStaff, searchString, SORT_FIELD)
    );
  };

  const setEditingState = () => {
    const { users: brigadeStaff = [], name = '', brigadier } = brigadeStore.updatingBrigade;

    setEmployees(() => ({
      checked: [...brigadeStaff.map(staffItem => staffItem.id), brigadier.id],
      selected: [brigadier, ...brigadeStaff],
      brigadier
    }));
    setBrigadeTitle(name);
  };

  const fetchStuff = () => {
    if (isEmployeesLoaded) return;
    loadPotentialAssignees();
  };

  useEffect(() => {
    fetchStuff();

    if (!isEditing) return;

    loadUpdatingBrigade(match.params.id);
  }, [match.params.id]);

  useEffect(() => {
    if (updatingBrigade) {
      setEditingState();
    }
  }, [match.params.id, updatingBrigade]);

  useEffect(() => () => {
    clearEditingState();
  }, []);

  useEffect(() => {
    searchQuerySet('');
    setFilteredStaff(staff);
  }, [staff]);

  const handleTitleChange = (value) => {
    setBrigadeTitle(value);
  };

  const moveBrigadierToTop = (arr, brigadierId) => (
    arrayMove(arr, arr.findIndex(({ id }) => id === brigadierId), 0)
  );

  const toggleBrigadier = isBrigadier => (e) => {
    const { value: selectedEmployeeId } = e.currentTarget;
    const brigadier = staff.find(staffItem => (
      staffItem.id === selectedEmployeeId
    ));

    setEmployees(prev => ({
      ...prev,
      brigadier: isBrigadier ? INIT_BRIGADIER : brigadier,
      selected: isBrigadier
        ? prev.selected
        : moveBrigadierToTop(prev.selected, selectedEmployeeId)
    }));
  };

  const handleToggle = employeeId => () => {
    let shouldRemoveBrigadier = false;
    const currentIndex = employees.checked.indexOf(employeeId);
    const newChecked = [...employees.checked];
    let filtered = [];

    if (currentIndex === -1) {
      newChecked.push(employeeId);
    } else {
      newChecked.splice(currentIndex, 1);
      shouldRemoveBrigadier = employees.brigadier && (employees.brigadier.id === employeeId);
    }

    if (newChecked.length) {
      filtered = staff.filter(staffItem => (newChecked.includes(staffItem.id)));
    }

    setEmployees(prev => ({
      checked: newChecked,
      selected: employees.brigadier.id
        ? moveBrigadierToTop(filtered.reverse(), employees.brigadier.id)
        : filtered.reverse(),
      brigadier: shouldRemoveBrigadier ? INIT_BRIGADIER : prev.brigadier
    }));
  };

  const handleRemoveFromBrigade = id => () => {
    const checked = employees.checked.filter(checkedItem => (
      checkedItem !== id
    ));
    const { brigadier: { id: brigadierId } } = employees;

    const selected = staff.filter(staffItem => (
      staffItem.id !== id && checked.includes(staffItem.id)
    ));

    setEmployees(prev => ({
      checked,
      selected,
      brigadier: brigadierId === id ? INIT_BRIGADIER : prev.brigadier,
    }));
  };

  const discardState = () => {
    if (isEditing) {
      setEditingState();
    } else {
      setEmployees(() => ({
        brigadier: INIT_BRIGADIER,
        selected: [],
        checked: []
      }));
      setBrigadeTitle('');
    }
  };

  const handleGoBack = () => {
    if (typeof location.state !== 'undefined' && location.state.location) {
      history.goBack(1);
    }
    history.push({ pathname: { BRIGADES } });
  };

  const goToDetails = id => history.push({ pathname: `${BRIGADE_DETAILS}/${id}` });

  const prepareBrigade = () => {
    const { brigadier, selected } = employees;
    const rawBrigade = {
      brigadier,
      selected,
      title: brigadeTitle,
      id: isEditing ? updatingBrigade.id : null
    };

    return brigadeStore.transformManagedBrigade(rawBrigade);
  };

  const handleSave = () => {
    validateRequiredFields();
    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('CANT_CREATE'),
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    brigadeStore.manage(prepareBrigade(), isEditing)
      .then((id) => {
        enqueueSnackbar({
          messageTemplate: {
            rows: [{
              rowContent: [{
                type: 'text',
                text: isEditing
                  ? t('CHANGES_SUCCESSFULLY_APPLIED')
                  : t('BRIGADE_SUCCESSFULLY_CREATED')
              }]
            }],
          },
          variant: 'success',
        });
        goToDetails(id);
      })
      .catch((error) => {
        enqueueSnackbar({
          messageTemplate: {
            rows: [{
              rowContent: [{
                type: 'text',
                text: t('REQUEST_DEFAULT_ERROR')
              }]
            }],
          },
          variant: 'error',
        });
        console.log(error);
      });
  };

  const title = (isEditing && brigadeStore.updatingBrigade)
    ? brigadeStore.updatingBrigade.name : null;

  if (isLoading || !isEmployeesLoaded) {
    return <ProgressBar />;
  }

  if (isEditing && !isUpdatingBrigadeLoaded) {
    return <ProgressBar />;
  }

  return (
    <BrigadeManageWidget
      title={title}
      handleGoBack={handleGoBack}
      sectorStaff={staff}
      handleToggle={handleToggle}
      employeesState={employees}
      brigadeTitle={brigadeTitle}
      handleTitleChange={handleTitleChange}
      toggleBrigadier={toggleBrigadier}
      handleRemoveFromBrigade={handleRemoveFromBrigade}
      searchQuery={searchQuery}
      handleSearchQuery={handleSearchQuery}
      filteredStaff={filteredStaff}
      discardState={discardState}
      handleSave={handleSave}
    />
  );
});

BrigadesManageContainer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.shape({
      location: PropTypes.object, // eslint-disable-line
    })
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.object // eslint-disable-line
  }).isRequired,
};

export default withRouter(BrigadesManageContainer);
