import { isArray, isEmpty, uniq } from 'lodash';

export default t => [
  {
    field: 'name',
    title: t('NAME'),
    withEllipsis: true,
    width: '25%',
    cellStyle: {
      width: '25%',
      whiteSpace: 'normal',
      overflowWrap: 'anywhere',
    },
    headerStyle: {
      width: '25%',
      whiteSpace: 'normal',
    },
  },
  {
    field: 'tesId',
    title: t('THERMAL_POWER_PLANT'),
    withEllipsis: true,
    width: '25%',
    cellStyle: {
      width: '25%',
      whiteSpace: 'normal',
      overflowWrap: 'anywhere',
    },
    headerStyle: {
      width: '25%',
      whiteSpace: 'normal',
    },
    render: ({ tps: { title } }) => title,
  },
  {
    field: 'blockNumber',
    title: t('POWER_UNIT'),
    withEllipsis: true,
    width: '20%',
    cellStyle: {
      width: '20%',
      whiteSpace: 'normal',
      overflowWrap: 'anywhere',
    },
    headerStyle: {
      width: '20%',
      whiteSpace: 'normal',
    },
    render: ({ checkpoints }) => {
      if (!isArray(checkpoints) || isEmpty(checkpoints)) return null;
      return uniq(checkpoints.map(({ powerUnit: { title: powerUnitTitle = '' } = {} }) => powerUnitTitle.replace(`${t('BLOCK')} `, ''))).join('; ');
    },
  },
  {
    field: 'checkpoints',
    title: t('ROUTE_TAGS'),
    sorting: false,
    width: '30%',
    cellStyle: {
      width: '30%',
      whiteSpace: 'normal',
      overflowWrap: 'anywhere',
    },
    headerStyle: {
      width: '30%',
      whiteSpace: 'normal',
    },
    render: (rowData) => {
      const { checkpoints } = rowData;
      if (!isArray(checkpoints) || isEmpty(checkpoints)) return null;
      return checkpoints.map(({ id }) => id).join(', ');
    }
  },
];
