import React from 'react';
import { Tooltip } from '@material-ui/core';

import withStyles from 'Src/utils/table/withColumnStyles';
import IsAlternativeSwitcher from 'Business/plant/components/IsAlternativeSwitcher/IsAlternativeSwitcher';

const tableColumns = t => (
  {
    name: {
      field: 'name',
      title: t('OSR_NAME'),
      mainLink: true,
      withEllipsis: true,
    },
    distance: {
      field: 'distance',
      title: `${t('MAX_DIST_TO_OBJECT')} (${t('METERS_UNIT_SHORT')})`,
    },
    isAlternativeEnabled: {
      field: 'isAlternativeEnabled',
      title: t('ALTERNATIVE_SCENARIO_SHORT'),
      // eslint-disable-next-line
      render: ({ id, isAlternativeEnabled, isUpdating }) => {
        return (
          <Tooltip title={isAlternativeEnabled ? t('ALTERNATIVE_SCENARIO_ON') : t('ALTERNATIVE_SCENARIO_OFF')}>
            <div>
              <IsAlternativeSwitcher
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                id={id}
                isAlternativeEnabled={isAlternativeEnabled}
                isUpdating={isUpdating}
              />
            </div>
          </Tooltip>
        );
      },
    },
    mapLink: {
      field: 'mapLink',
      title: t('MAP_LINK'),
      withEllipsis: true,
    },
  }
);

export const tableConfig = (t) => {
  const translatedTableColumns = tableColumns(t);
  return (
    {
      columns: [
        withStyles(translatedTableColumns.name, { width: '25%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.distance, { width: '25%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.isAlternativeEnabled, { width: '15%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.mapLink, { width: '35%', whiteSpace: 'normal' }),
      ],
      actions: [],
    }
  );
};

export default tableColumns;
