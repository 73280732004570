import DefectsDictionaryAgent from 'ApiAgents/Dictionary/DefectsAgent';
import {
  action, observable, runInAction, makeObservable
} from 'mobx';
import { findIndex, cloneDeep, get } from 'lodash'; // eslint-disable-line


const defectsDictionaryAgent = new DefectsDictionaryAgent();

export class DefectsDictionaryStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable defectData = {};

  @observable list = [];

  @observable totalPages = 0;

  @observable hasNextPage = false;

  @observable hasPreviousPage = false;

  @observable isTaskListLoaded = true;

  @observable defectsGroups = [];

  @observable isLoadingDefectsGroups = false;

  @observable groupValue = '';

  @observable isUpdating = false;

  @observable isDefectLoading = false;

  @observable isDefectLoaded = false;

  @observable isListLoading = false;

  @observable isListLoaded = false;

  @action loadDefectsGroups = async () => {
    this.isLoadingDefectsGroups = true;
    try {
      const defectsGroups = await defectsDictionaryAgent.loadDefectsGroups() || {};
      runInAction(() => {
        this.defectsGroups = defectsGroups;
        this.isLoadingDefectsGroups = false;
      });
    } catch (error) {
      console.log('ERROR FETCHING DEFECTS GROUPS', error);
      runInAction(() => {
        this.defectsGroups = [];
        this.isLoadingDefectsGroups = false;
      });
      throw new Error(error);
    }
  }

  @action getDefectDataById = async (defectId) => {
    this.isDefectLoading = true;
    this.isDefectLoaded = false;
    try {
      const { items: [defectData] = [] } = await defectsDictionaryAgent.getDefectByDefectId(defectId) || {};

      runInAction(() => {
        this.defectData = defectData;
        this.isDefectLoading = false;
        this.isDefectLoaded = true;
      });
    } catch (error) {
      console.log('ERROR FETCHING DEFECT', error);
      runInAction(() => {
        this.defectData = {};
        this.isDefectLoading = false;
        this.isDefectLoaded = true;
      });
      throw new Error(error);
    }
  };

  @action updateDefectData = async () => {
    this.isUpdating = true;
    try {
      await defectsDictionaryAgent.updateDefectData(this.defectData);

      runInAction(() => {
        this.isUpdating = false;
      });

      return { error: false };
    } catch (error) {
      console.log('ERROR UPDATING DEFECT', error);
      runInAction(() => {
        this.isUpdating = false;
      });
      return { error };
    }
  };

  @action updateField = (field, value) => {
    this.defectData = { ...this.defectData, [field]: value };
  };

  @action onChangeDefectStatus = async (id, isUnconstrained) => {
    const defectIndex = findIndex(this.list, ({ defectId }) => defectId === id);
    if (defectIndex < 0) return;
    const newList = [...this.list];
    try {
      newList[defectIndex] = { ...this.list[defectIndex], isUpdating: true };
      runInAction(() => {
        this.list = newList;
      });
      const userText = get(this.list[defectIndex], 'userText');
      const updatedDefect = await defectsDictionaryAgent.updateDefectData({ defectId: id, isUnconstrained, userText }) || {};
      const updatedIsUnconstrained = get(updatedDefect, 'isUnconstrained', false);
      newList[defectIndex] = { ...this.list[defectIndex], isUpdating: false, isUnconstrained: updatedIsUnconstrained };
      runInAction(() => {
        this.list = newList;
      });
    } catch (error) {
      console.log('ERROR EDITING DEFECT ', error);
      newList[defectIndex] = { ...this.list[defectIndex], isUpdating: false };
      runInAction(() => {
        this.list = newList;
      });
      throw new Error(error);
    }
  };

  @action discardList = () => {
    this.list = [];
    this.isListLoading = false;
    this.isListLoaded = false;
  }

  @action setGroupValue = (groupValue) => {
    this.groupValue = groupValue;
  }

  @action loadListByGroup = async ({ groupId, pageNumber, perPage }) => {
    const shouldPaginate = !!pageNumber;
    this.isListLoading = true;
    this.isListLoaded = false;
    try {
      const {
        items, totalPages, hasNextPage, hasPreviousPage
      } = await defectsDictionaryAgent.loadDefectsByGroup(
        {
          groupId,
          shouldPaginate,
          pageNumber,
          perPage
        }
      );
      runInAction(() => {
        this.list = items;
        this.isListLoading = false;
        this.isListLoaded = true;
        this.totalPages = totalPages;
        this.hasNextPage = hasNextPage;
        this.hasPreviousPage = hasPreviousPage;
      });
    } catch (error) {
      console.log('ERROR LOAD DEFECTS: ', error);
      runInAction(() => {
        this.list = [];
        this.isListLoading = false;
        this.isListLoaded = true;
        this.totalPages = 0;
        this.hasNextPage = false;
        this.hasPreviousPage = false;
      });
      throw new Error(error);
    }
  }
}

export default DefectsDictionaryStore;
