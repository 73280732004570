import React from 'react';
import PropTypes from 'prop-types';

import DetailsBlockTitle from 'Common/components/Forms/DetailsBlock/DetailsBlockTitle';
import useStyles from './styles';

const DetailsBlock = ({ children, title }) => {
  const classes = useStyles();
  return (
    <div className={classes.blockWrapper}>
      {title
        && <DetailsBlockTitle title={title} />}
      {children}
    </div>
  );
};

DetailsBlock.defaultProps = {
  title: '',
};

DetailsBlock.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default DetailsBlock;
