import React from 'react';
import PropTypes from 'prop-types';
import { EmailOutlined, CallOutlined, SmartphoneOutlined } from '@material-ui/icons';
import UserImage from 'Common/pages/User/components/UserImage';
import useStyles from './styles';

const UserHeader = ({ user, showAddUserHelper }) => {
  // TODO: change showAddUserHelper prop name
  const classes = useStyles({ showAddUserHelper });
  const {
    fullName,
    photo,
    status,
    email,
    isUserInSystem,
    phones = [],
  } = user;

  const isUserActive = status === 'ACTIVE';

  const getWorkPhoneNumber = () => {
    const workPhoneNumber = phones.find(phone => phone.type === 'BUSINESS');
    if (workPhoneNumber) {
      return workPhoneNumber.number;
    }
  };

  const getPersonalPhoneNumber = () => {
    const personalPhoneNumber = phones.find(phone => phone.type === 'MOBILE');
    if (personalPhoneNumber) {
      return personalPhoneNumber.number;
    }
  };

  const workPhoneNumber = getWorkPhoneNumber();
  const personalPhoneNumber = getPersonalPhoneNumber();

  return (
    <div className={classes.root}>
      <UserImage
        disabled={!isUserActive}
        isUserInSystem={isUserInSystem}
        photo={photo}
        fullName={fullName}
      />
      <div className={classes.userInfo}>
        {email && (
          <div data-test="email" className={classes.userInfoString}>
            <EmailOutlined />
            {email}
          </div>
        )}
        {workPhoneNumber && (
          <div data-test="work-phone-number" className={classes.userInfoString}>
            <CallOutlined />
            {workPhoneNumber}
          </div>
        )}
        {personalPhoneNumber && (
          <div data-test="personal-phone-number" className={classes.userInfoString}>
            <SmartphoneOutlined />
            {personalPhoneNumber}
          </div>
        )}
      </div>
    </div>
  );
};

UserHeader.defaultProps = {
  showAddUserHelper: false,
};

UserHeader.propTypes = {
  user: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
    personalPhoneNumber: PropTypes.string,
    workPhoneNumber: PropTypes.string,
    photo: PropTypes.string,
    role: PropTypes.shape({
      name: PropTypes.string,
    }),
    status: PropTypes.string,
    isUserInSystem: PropTypes.bool,
    phones: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        number: PropTypes.string,
      }),
    ),
  }).isRequired,
  showAddUserHelper: PropTypes.bool,
};

export default UserHeader;
