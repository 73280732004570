import {
  action,
  runInAction,
  makeObservable,
  computed,
} from 'mobx';
import {
  isEqual, get, isArray, assign, isEmpty, filter,
} from 'lodash';
import UnitsAgent from 'ApiAgents/Units/UnitsAgent';
import EquipmentAgent from 'ApiAgents/Equipment/EquipmentAgent';
import {
  HEAD_MECHANIC, PERFORMER_RU, MECHANIC_RU, COAL_MASTER, MECHANIC,
} from 'Shared/constants/roles';
import { getByRoleQuery } from 'Src/utils/roles';
import { useTranslation } from 'react-i18next'; // eslint-disable-line

const initialSelectedState = {
  taskType: 'all',
  departmentId: '',
  technicalPlaceId: '',
  assignments: [],
};

const initialState = {
  isInitialFiltersLoading: false,
  equipmentDictionaryIsLoading: false,
  departmentsDictionary: [],
  equipmentDictionary: [],
  employeesDictionary: [],
  selectedState: { ...initialSelectedState },
  requestedFilters: { ...initialSelectedState },
};

const unitsAgent = new UnitsAgent();
const equipmentAgent = new EquipmentAgent();

export class TaskListFilterStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
    this.rootStore.prepareState(initialState, this);
  }

  isInitialFiltersLoading;

  isLoadedInitialFilters;

  equipmentDictionaryIsLoading;

  selectedState;

  requestedFilters;

  departmentsDictionary;

  equipmentDictionary;

  employeesDictionary;

  @computed get filterState() {
    const {
      departmentId,
      taskType,
      technicalPlaceId,
      assignments,
    } = this.requestedFilters;

    const state = {
      ...departmentId && { departmentId },
      ...technicalPlaceId && { technicalPlaceId },
      ...assignments.length && { assignments },
    };

    switch (taskType) {
      case 'standard':
        assign(state, { isStandard: true });
        break;
      case 'notStandard':
        assign(state, { isStandard: false });
        break;
      default:
    }

    return state;
  }

  @computed get isFiltersSet() {
    const {
      departmentId,
      taskType,
      technicalPlaceId,
      assignments,
    } = this.requestedFilters;
    const isFiltersSet = taskType !== initialSelectedState.taskType
      || departmentId
      || technicalPlaceId
      || !isEmpty(assignments);

    return Boolean(isFiltersSet);
  }

  @computed get hasDiff() {
    const hasDiff = !isEqual(this.requestedFilters, this.selectedState);
    return hasDiff;
  }

  @action saveRequestedFilters = () => {
    this.requestedFilters = { ...this.selectedState };
  };

  @action discardSelectedFilters = () => {
    this.selectedState = { ...initialSelectedState };
    this.equipmentDictionary = [];
    this.requestedFilters = { ...initialSelectedState };
  };

  flatCollection(array, level = 0) {
    let result = [];
    array.forEach((item) => {
      const newItem = { ...item, level };
      result.push(newItem);
      if (isArray(item.children)) {
        result = result.concat(this.flatCollection(item.children, level + 1, item.title));
      }
    });
    return result;
  }

  @action loadInitialFilters = async () => {
    try {
      let departmentId = '';
      let equipmentDictionary = [];
      const { auth: { department }, role, rootOrgUnit: { id: rootOrgUnitId } } = this.rootStore.userStore;
      this.isInitialFiltersLoading = true;
      let departments = [];
      let EMPLOYEES_ROLES = [];
      if (role === HEAD_MECHANIC) EMPLOYEES_ROLES = [MECHANIC_RU];
      if (role === COAL_MASTER || role === MECHANIC) EMPLOYEES_ROLES = [PERFORMER_RU];
      const employees = await this.rootStore.userStore.loadUsersByRole({ roles: getByRoleQuery(EMPLOYEES_ROLES), orgUnitId: rootOrgUnitId });
      if (role === HEAD_MECHANIC) {
        departments = await unitsAgent.getDepartmentsByOrgUnit(get(this.rootStore, 'userStore.auth.rootOrgUnit.id'));
      } else {
        departmentId = get(department, 'id');
        const departmentCode = get(department, 'code');
        equipmentDictionary = await equipmentAgent.getTechObjectsByDepartment(departmentCode);
      }
      runInAction(() => {
        this.isLoadedInitialFilters = true;
        this.departmentsDictionary = departments;
        this.equipmentDictionary = equipmentDictionary;
        this.selectedState.departmentId = departmentId;
        this.employeesDictionary = employees;
      });
    } catch (error) {
      throw new Error(error);
    }

    runInAction(() => {
      this.isInitialFiltersLoading = false;
    });
  };

  @action onChangeDepartment = async (department) => {
    try {
      const departmentId = get(department, 'id');
      if (departmentId === this.selectedState.departmentId) return;
      const departmentCode = get(department, 'code');
      let equipmentDictionary = [];
      if (departmentCode) {
        this.equipmentDictionaryIsLoading = true;
        equipmentDictionary = await equipmentAgent.getTechObjectsByDepartment(departmentCode);
      }
      runInAction(() => {
        this.selectedState.departmentId = departmentId;
        this.selectedState.technicalPlaceId = '';
        this.equipmentDictionary = filter(equipmentDictionary, ({ technicalObjectId }) => technicalObjectId);
        this.equipmentDictionaryIsLoading = false;
      });
    } catch (error) {
      console.log('ERROR in FETCHING equipmentDictionary: ', error);
    }
  };

  @action onChangeEquipment = (equipmentObj) => {
    const technicalObjectId = get(equipmentObj, 'technicalObjectId', '');
    if (technicalObjectId === this.selectedState.technicalPlaceId) return;
    this.selectedState.technicalPlaceId = technicalObjectId;
  };

  @action onChangeEmployees = (employees) => {
    this.selectedState.assignments = employees.map(({ id }) => id);
  };

  @action onChangeTaskType = (taskType) => {
    this.selectedState.taskType = taskType;
  };

  @action discardState = () => {
    this.rootStore.setInitialState(initialState, this);
  }
}

export default TaskListFilterStore;
