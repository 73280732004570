import { formatDate, formatTime } from 'Src/utils/datetime';

export default t => ([
  {
    field: 'title',
    title: t('NOMINATION'),
    withEllipsis: true,
    mainLink: true,
    cellStyle: {
      whiteSpace: 'normal',
      overflowWrap: 'anywhere',
      width: '25%'
    },
    headerStyle: {
      whiteSpace: 'normal',
      width: '25%'
    },
  },
  {
    field: 'tps',
    title: t('THERMAL_POWER_PLANT'),
    withEllipsis: true,
    cellStyle: {
      whiteSpace: 'normal',
      overflowWrap: 'anywhere',
      width: '10%'
    },
    headerStyle: {
      whiteSpace: 'normal',
      width: '10%'
    },
    render: ({ tps: { title = '' } = {} } = {}) => title,
  },
  {
    field: 'routeName',
    title: t('ROUTE'),
    withEllipsis: true,
    cellStyle: {
      whiteSpace: 'normal',
      overflowWrap: 'anywhere',
      width: '12%'
    },
    headerStyle: {
      whiteSpace: 'normal',
      width: '12%'
    },
  },
  {
    field: 'roles',
    title: t('PERFORMERS'),
    withEllipsis: true,
    width: '33%',
    cellStyle: {
      whiteSpace: 'normal',
      overflowWrap: 'anywhere',
      width: '33%',
    },
    headerStyle: {
      whiteSpace: 'normal',
      width: '33%'
    },
    render: ({ roles = [] } = {}) => roles.join(', '),
  },
  {
    field: 'period',
    title: t('EXECUTION_PERIOD'),
    cellStyle: {
      whiteSpace: 'normal',
      width: '10%'
    },
    headerStyle: {
      whiteSpace: 'normal',
      width: '10%'
    },
    render: ({ startDate, finishDate }) => `${formatDate(startDate)} - ${formatDate(finishDate)}`
  },
  {
    field: 'createAt',
    title: t('CREATION_DATE_TIME'),
    cellStyle: {
      whiteSpace: 'normal',
      width: '10%'
    },
    headerStyle: {
      whiteSpace: 'normal',
      width: '10%'
    },
    render: ({ startTime }) => `${formatDate(startTime)} ${formatTime(startTime)}`
  },
]);
