import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as NoDataImage } from 'Src/assets/banners/no-data.svg';

import useStyles from './styles';

const EmptyDataBlock = ({ title, subTitle }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <NoDataImage className={classes.image} />
      {title && (<div className={classes.title}>{title}</div>)}
      {subTitle && (<div className={classes.subTitle}>{subTitle}</div>)}
    </div>
  );
};

EmptyDataBlock.defaultProps = {
  title: undefined,
  subTitle: undefined,
};

EmptyDataBlock.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string
};

export default EmptyDataBlock;
