import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#fff',
  },
  body: {
    margin: 0,
    padding: '0 24px',
    overflowY: 'auto',
    height: 'calc(100vh - 250px)',
    '&.footer-on': {
      height: 'calc(100vh - 338px)',
    }
  },
  commonDetails: {
    marginRight: -24,
    marginLeft: -24,
  },
  wrapperContent: {
    padding: '20px 0 25px',
  },
  header: {
    fontWeight: 'bold',
    marginTop: 35
  },
  divider: {
    marginRight: -24,
    marginLeft: -24,
  },
  actions: {
    textAlign: 'right',
    height: 96,
    margin: '0',
    padding: '24px',
    borderTop: '1px solid #f3f3f3',
    '& .MuiButtonBase-root': {
      marginLeft: 24,
      minWidth: 240
    }
  },
  notificationContent: {
    fontSize: 16,
    maxWidth: 328,
    display: 'flex',
    alignItems: 'center',
  },
  successIcon: {
    marginRight: 8,
    color: '#44bb44',
  },
  notificationText: {
    color: theme.palette.black
  },
  actionButtonsWrapper: {
    paddingTop: 24,
    paddingRight: 24,
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiButtonBase-root': {
      '&:not(:last-child)': {
        marginRight: 16,
      },
    },
  },
  materialValuesLayoutHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  taskLink: {
    display: 'flex',
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: 1.5,
    alignItems: 'center',
    '& .MuiSvgIcon-root ': {
      marginRight: 4,
    },
  },
  operationSubTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
