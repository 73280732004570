import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  content: {
    padding: '20px 0 25px',
  },
  dateInput: {
    maxWidth: 400,
  },
}));

export default useStyles;
