import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import {
  isEmpty, isArray, get
} from 'lodash';
import { Grid } from '@material-ui/core';
import FormSwitch from 'Common/components/Forms/Input/FormSwitch';
import {
  PanelBox,
} from 'Common/widgets/Layout/Panel/Panel';

import useStyles from './styles';

const OperationsLayout = (props) => {
  const {
    blockTitle,
    operations,
    renderOperation,
    renderMaterialValues,
    withoutShowMaterialValuesToggle,
    showMaterialValuesDefaultValue,
    headerWithoutBottomBorder,
    renderActions,
    renderComments,
  } = props;
  const [showMaterialValues, setShowMaterialValues] = useState(showMaterialValuesDefaultValue);

  const { t } = useTranslation();
  const classes = useStyles({ headerWithoutBottomBorder });

  return (
    <PanelBox
      className={classes.panelBox}
      boxStyles={{ padding: 0, maxWidth: showMaterialValues ? 'inherit' : '50%' }}
    >
      <div className={classes.blockHeader}>
        <Grid container>
          <Grid
            item
            xs={6}
            className={classes.blockHeaderLabel}
          >
            {blockTitle}
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.blockHeaderButton}
          >
            {!isEmpty(operations) && !withoutShowMaterialValuesToggle && (
              <FormSwitch
                label={t('SHOW_MATERIAL_VALUES')}
                name="showMaterialValues"
                checked={showMaterialValues}
                onChange={(e) => {
                  const checked = get(e, 'target.checked');
                  setShowMaterialValues(checked);
                }}
                data-test="switch-showMaterialValues"
              />
            )}
          </Grid>
        </Grid>
      </div>
      <div>
        {operations.map((operation = {}, index) => {
          const {
            operationId,
            operationSapCode,
            materialValues,
          } = operation;

          const hasMaterialsValues = isArray(materialValues) && !isEmpty(materialValues);
          const operationKey = `${operationSapCode}_${index}`;

          return (
            <div
              key={operationKey}
              className={showMaterialValues
                ? classes.operationRowWithMaterialValues
                : classes.operationRow}
            >
              <Grid
                direction="row"
                container
              >
                <Grid item xs={showMaterialValues ? 6 : 12}>
                  <div
                    className={classes.operationWrapper}
                  >
                    {renderOperation(operation)}
                  </div>
                </Grid>
                {showMaterialValues && (
                  <Grid item xs={6}>
                    <div className={classes.materialValuesWrapper}>
                      {hasMaterialsValues
                      && materialValues.map((materialValue = {}, materialValueIndex) => {
                        const materialValueKey = `${operationSapCode}Material${materialValueIndex}`;
                        return (
                          <div
                            key={materialValueKey}
                            className={classes.materialValue}
                          >
                            {renderMaterialValues(operation, materialValue)}
                          </div>
                        );
                      })}
                    </div>
                    {!hasMaterialsValues && (
                      <div
                        className={classes.noMaterialsValuesWrapper}
                      >
                        <div className={classes.noMaterialsValuesLabel}>{t('THERE_ARE_NO_MATERIAL_VALUES')}</div>
                      </div>
                    )}
                    {renderActions && renderActions(operationId)}
                  </Grid>
                )}
              </Grid>
              {renderComments && renderComments(operationId)}
            </div>
          );
        })}
      </div>
    </PanelBox>
  );
};

OperationsLayout.defaultProps = {
  operations: [],
  blockTitle: '',
  withoutShowMaterialValuesToggle: false,
  showMaterialValuesDefaultValue: false,
  headerWithoutBottomBorder: false,
  renderActions: undefined,
  renderComments: undefined,
};

OperationsLayout.propTypes = {
  operations: PropTypes.arrayOf(PropTypes.shape({
    operationSapCode: PropTypes.string,
    materialValues: PropTypes.arrayOf(PropTypes.shape({
      planDuration: PropTypes.number,
      factDuration: PropTypes.number,
    })),
  })),
  blockTitle: PropTypes.string,
  renderOperation: PropTypes.func.isRequired,
  renderComments: PropTypes.func,
  renderMaterialValues: PropTypes.func.isRequired,
  withoutShowMaterialValuesToggle: PropTypes.bool,
  showMaterialValuesDefaultValue: PropTypes.bool,
  headerWithoutBottomBorder: PropTypes.bool,
  renderActions: PropTypes.func,
};

export default observer(OperationsLayout);
