import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
  find, get, filter, isEqual, isEmpty,
} from 'lodash';
import useStores from 'Store/useStores';
import { Dialog, Grid } from '@material-ui/core';
import HeaderLayout from 'Common/widgets/Layout/Header/Header';
import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import UsersSelectWidget from 'Business/coal/components/UsersSelectWidget';
import { sortObjectByEntryIndex } from 'Src/utils/sort';
import { searchInArrayOfObjects } from 'Src/utils/search';
import { useTranslation } from 'react-i18next';


const UsersSelectOverlay = observer(({ isOpen, handleClose }) => {
  const { t } = useTranslation();
  const { coalTaskStore } = useStores();

  const {
    usersSelectEditorData: {
      employees = {},
    } = {},
    setEmployees,
    confirmSelectedUsers,
    loadPotentialAssignees,
    employeesList: staff,
    taskData: { departmentId } = {},
  } = coalTaskStore;

  const { checked = [], selected = [], brigadier: currentAssignee = {} } = employees;

  const SORT_FIELD = 'fullName';
  const SEARCH_FIELDS = ['fullName', 'positionName'];

  const [searchQuery, searchQuerySet] = useState('');
  const [filteredStaff, setFilteredStaff] = useState([]);

  const isSaveButtonDisabled = () => !currentAssignee;

  const discardState = () => {
    setEmployees({
      brigadier: null,
      selected: [],
      checked: []
    });
  };

  const handleConfirm = () => {
    confirmSelectedUsers({ selected, currentAssignee });
    discardState();
    handleClose();
  };

  const handleOverlayClose = () => {
    discardState();
    handleClose();
  };

  useEffect(() => {
    loadPotentialAssignees();
  }, [departmentId]);

  useEffect(() => {
    searchQuerySet('');
    setFilteredStaff(staff);
  }, [staff]);

  const title = 'title';

  const handleToggle = (employee) => {
    if (isEmpty(checked)) {
      setEmployees({
        ...employees,
        checked: [employee],
        brigadier: employee,
      });
      return;
    }

    const { id: employeeId } = employee;
    const currentAssigneeId = get(currentAssignee, 'id');
    const isCurrentAssignee = isEqual(currentAssigneeId, employeeId);
    if (isCurrentAssignee) {
      setEmployees({
        ...employees,
        checked: filter(checked, ({ id } = {}) => id && id !== employeeId),
        brigadier: null,
      });
      return;
    }

    const existsInEmployees = find(checked, { id: employeeId });
    if (!existsInEmployees) {
      setEmployees(
        {
          ...employees,
          checked: [...checked, employee],
          selected: [...selected, employee],
        }
      );
      return;
    }
    const newChecked = filter(checked, ({ id: checkedEmployeeId } = {}) => checkedEmployeeId !== employeeId);
    const newSelected = filter(selected, ({ id: checkedEmployeeId } = {}) => checkedEmployeeId !== employeeId);
    setEmployees(
      {
        ...employees,
        checked: newChecked,
        selected: newSelected,
      }
    );
  };

  const toggleBrigadier = (e, isBrigadier) => {
    const { value: selectedEmployeeId } = e.currentTarget;
    if (isBrigadier) {
      setEmployees({
        ...employees,
        brigadier: null,
        selected: [...selected, currentAssignee],
      });
      return;
    }

    const newBrigadier = selected.find(item => (
      item.id === selectedEmployeeId
    ));
    const newSelected = filter([...selected], ({ id } = {}) => id !== selectedEmployeeId);
    if (currentAssignee) newSelected.push(currentAssignee);
    setEmployees(
      {
        ...employees,
        brigadier: newBrigadier,
        selected: newSelected,
      }
    );
  };

  const handleSearchQuery = (phrase) => {
    const searchString = phrase.trim().toLowerCase();
    searchQuerySet(searchString);

    if (phrase.length < 2) {
      setFilteredStaff(staff);
      return;
    }

    const searchedStaff = searchInArrayOfObjects(staff, SEARCH_FIELDS, searchString);
    setFilteredStaff(
      sortObjectByEntryIndex(searchedStaff, searchString, SORT_FIELD)
    );
  };

  const renderUsersSelectContainer = () => (
    <UsersSelectWidget
      title={title}
      sectorStaff={staff}
      handleToggle={handleToggle}
      employeesState={employees}
      toggleBrigadier={toggleBrigadier}
      searchQuery={searchQuery}
      handleSearchQuery={handleSearchQuery}
      filteredStaff={filteredStaff}
      discardState={discardState}
    />
  );

  return (
    <Dialog fullScreen open={isOpen} data-test="usersSelectDialog">
      <Grid
        container
        direction="column"
        wrap="nowrap"
        style={{ height: '100%' }}
      >
        <HeaderLayout title={t('APPOINTMENT_OF_THE_PERFORMER')}>
          <HeaderButton type="close" handleClick={handleOverlayClose} />
          <HeaderButton type="save" handleClick={handleConfirm} disabled={isSaveButtonDisabled()} />
        </HeaderLayout>
        {renderUsersSelectContainer()}
      </Grid>
    </Dialog>
  );
});

UsersSelectOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default UsersSelectOverlay;
