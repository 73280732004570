import UserAgent from 'ApiAgents/Users/UserAgent';
import {
  get, isEmpty, isArray, assign, find
} from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  action, observable, runInAction, makeObservable
} from 'mobx';
import { hasPermission } from 'Common/components/Authorization/Can';
import { ADMIN_USER_AUTOFILL_ORGUNIT } from 'Src/RBAC/businessPermissions';

const userAgent = new UserAgent();

const initUserData = {};

export class AdminUserStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @action getInitialUserData = () => initUserData;

  @observable isEmptyLoadedTaskData = false;

  @observable mode = '';

  @observable userData = this.getInitialUserData();

  @observable isLoading = false;

  @observable isLoaded = false;

  @observable isUpdating = false;

  @observable rolesDictionary = [];

  @observable rootOrgUnits = [];

  @observable taskCompletionReasonsDictionary = [];

  @action reset = () => {
    this.userData = initUserData;
  };

  @action setMode = (mode) => {
    this.mode = mode;
  };

  @action discardEditorState = () => {
    this.userData = {};
  };

  @action save = async () => {
    const businessName = get(this.rootStore, 'userStore.auth.businessName');
    const groupedPlants = [];
    this.userData.plants.forEach(({ code = '', storages } = {}) => {
      const unmaskedCode = code.replace(/[ _]/g, '');
      const isInGroupedPlants = find(groupedPlants, { code: unmaskedCode });
      const unmaskedStorages = isArray(storages) ? storages.map(({ code: storageCode } = {}) => storageCode.replace(/[ _]/g, '')) : null;
      if (isInGroupedPlants && isArray(unmaskedStorages)) {
        isInGroupedPlants.storages.push(...unmaskedStorages);
      } else if (isArray(unmaskedStorages)) {
        groupedPlants.push({ code: unmaskedCode, storages: unmaskedStorages });
      }
    });
    const formattedData = {
      ...this.userData,
      businessName,
      login: this.userData.email,
      department: this.userData.department.code,
      roles: [this.userData.role.name],
      status: this.userData.status || 'BLOCKED',
      plants: this.userData.plants
        ? groupedPlants
        : null,
    };

    try {
      if (this.userData.isUserInSystem) {
        await userAgent.updateUser(formattedData);
      } else {
        await userAgent.addUser(formattedData);
      }

      runInAction(() => {
        this.isUpdating = false;
      });

      return { res: true };
    } catch (error) {
      console.log('ERROR in SAVE TASK: ', error);
      runInAction(() => {
        this.isUpdating = false;
      });
      return { error };
    }
  };

  @action getUserById = async (userId) => {
    try {
      const { plants, ...rest } = (await userAgent.loadUser(userId)) || {};
      const flattenPlants = [];
      if (!isEmpty(plants)) {
        plants.forEach((plant) => {
          const storages = get(plant, 'storages', []);
          if (isArray(storages) && !isEmpty(storages)) {
            storages.forEach((storage) => {
              flattenPlants.push({ ...plant, storages: [storage] });
            });
          } else {
            flattenPlants.push(plant);
          }
        });
      }
      let updatedUserData = { ...rest, plants: flattenPlants.map(i => ({ ...i, uniqId: uuidv4() })) };
      if (this.mode === 'create') {
        updatedUserData = {
          ...updatedUserData,
          firstName: '',
          lastName: '',
          surName: '',
        };
        const { environment, businessType, role } = this.rootStore.userStore;
        const shouldAutofillOrgunit = hasPermission(environment, businessType, role, ADMIN_USER_AUTOFILL_ORGUNIT);

        if (shouldAutofillOrgunit) {
          const rootOrgUnitName = get(this.rootStore, 'userStore.auth.rootOrgUnit.name');
          const rootOrgUnitCode = get(this.rootStore, 'userStore.auth.rootOrgUnit.code');
          assign(updatedUserData, {
            rootOrgUnit: {
              name: rootOrgUnitName,
              code: rootOrgUnitCode,
            },
          });
        }
      }
      runInAction(() => {
        this.userData = updatedUserData;
        this.isLoaded = true;
        this.isLoading = false;
      });
    } catch (error) {
      console.log('error', error);
      runInAction(() => {
        this.userData = {};
        this.isLoaded = true;
        this.isLoading = false;
      });
      throw new Error(error);
    }
  };

  @action loadRolesDictionary = async () => {
    try {
      const rolesDictionary = await userAgent.loadUsersRoles();
      runInAction(() => {
        this.rolesDictionary = rolesDictionary;
      });
    } catch (error) {
      throw new Error(error);
    }

    runInAction(() => {
      this.isInitialFiltersLoading = false;
    });
  };

  @action loadAllRootOrgUnits = async () => {
    try {
      const rootOrgUnits = await userAgent.fetchAllRootOrgUnits();
      runInAction(() => {
        this.rootOrgUnits = rootOrgUnits;
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  @action onChangeRole = (role) => {
    runInAction(() => {
      this.userData = { ...this.userData, role };
    });
  };

  @action onAddPlant = () => {
    const newPlants = [...this.userData.plants, { code: '', storages: [], uniqId: uuidv4() }];
    this.userData = { ...this.userData, plants: newPlants };
  };

  @action onRemovePlantByIndex = (uniqId) => {
    this.userData = { ...this.userData, plants: this.userData.plants.filter(plant => plant.uniqId !== uniqId) };
  };

  @action onChangeStorageCode = (value, uniqId) => {
    const newPlants = [...this.userData.plants];
    const currentIndex = newPlants.findIndex(i => i.uniqId === uniqId);
    if (currentIndex === -1) return;
    newPlants[currentIndex] = {
      ...newPlants[currentIndex],
      storages: [{ code: value }],
    };
    runInAction(() => {
      this.userData = { ...this.userData, plants: newPlants };
    });
  };

  @action onChangePlantCode = (value, uniqId) => {
    const newPlants = [...this.userData.plants];
    const currentIndex = newPlants.findIndex(i => i.uniqId === uniqId);
    if (currentIndex === -1) return;
    newPlants[currentIndex] = { ...newPlants[currentIndex], code: value };
    runInAction(() => {
      this.userData = { ...this.userData, plants: newPlants };
    });
  };

  @action onChangeUserStatus = (isActive) => {
    this.userData = { ...this.userData, status: isActive };
  };

  @action onChangePositionName = (position) => {
    this.userData = { ...this.userData, position };
  };

  @action onChangeSapWorkPlace = (sapWorkPlace) => {
    this.userData = {
      ...this.userData,
      department: { ...this.userData.department, code: sapWorkPlace },
    };
  };

  @action onChangePersonnelNumber = (personnelNumber) => {
    this.userData = { ...this.userData, personnelNumber };
  };

  @action onChangeRootOrgUnit = (rootOrgUnit) => {
    this.userData = { ...this.userData, rootOrgUnit };
  };

  @action onChangeLastName = (lastName) => {
    this.userData = { ...this.userData, lastName };
  };

  @action onChangeFirstName = (firstName) => {
    this.userData = { ...this.userData, firstName };
  };

  @action onChangeSurName = (surName) => {
    this.userData = { ...this.userData, surName };
  };
}

export default AdminUserStore;
