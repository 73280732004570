import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { add, isValid, isBefore } from 'date-fns';
import { get } from 'lodash';
import { Grid, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Input from 'Common/components/Forms/Input/Input';
import DateInput from 'Common/components/Forms/Input/Date';
import { PanelBox, PanelTitle } from 'Common/widgets/Layout/Panel/Panel';
import { ReactComponent as FIleDownload } from 'Src/assets/file_download.svg';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import Header from 'Common/widgets/Layout/Header/Header';
import useStores from 'Store/useStores';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import { ReactComponent as FillData } from 'Assets/report-enter-data.svg';
import { ReactComponent as LoadReport } from 'Assets/report-ready.svg';
import OptionRenderer from 'Common/components/Forms/OptionRenderer';
import { MANAGER } from 'Shared/constants/roles';
import { REPORTS } from 'Shared/constants/routes';
import {
  COMPLETED_INSPECTION,
  DETAILED_INSPECTION_REPORT,
  INSPECTION_STATUSES
} from 'Shared/constants/inspectionReportTypes';

import useStyles from './styles';

const maxReportPeriodInMonths = 6;
const minDate = '2020-01-01';


const InspectionReport = ({ reportType }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    inspectionReportsStore: {
      getRootOrgUnits,
      isRootOrgUnitsLoading,
      startDate,
      finishDate,
      rootOrgUnits,
      rootOrgUnitCode,
      updateField,
      downloadReport,
      discardState,
      isReportLoading
    },
    notificationStore: { enqueueSnackbar },
    validationStore: {
      validateRequiredFields, isValidFields, hasErrors,
    },
    userStore: {
      role,
      auth: { rootOrgUnit: { name: rootOrgUnitName = '' } = {} } = {}
    },
  } = useStores();

  const history = useHistory();

  let pageTitle = '';

  switch (reportType) {
    case INSPECTION_STATUSES: {
      pageTitle = t('INSPECTION_STATUSES');
      break;
    }
    case COMPLETED_INSPECTION: {
      pageTitle = t('COMPLETED_INSPECTION');
      break;
    }
    case DETAILED_INSPECTION_REPORT: {
      pageTitle = t('DETAILED_INSPECTION_REPORT');
      break;
    }
    default: {
      return '';
    }
  }

  const [isStartDateValid, setIsStartDateValid] = useState(true);
  const [isFinishDateValid, setIsFinishDateValid] = useState(true);

  const canSetRootOrgUnit = role === MANAGER;

  useEffect(() => {
    if (role === MANAGER) getRootOrgUnits();
    return () => discardState();
  }, []);

  const getReportName = (type) => {
    switch (type) {
      case INSPECTION_STATUSES: {
        return t('INSPECTION_STATUSES');
      }
      case COMPLETED_INSPECTION: {
        return t('COMPLETED_INSPECTION');
      }
      case DETAILED_INSPECTION_REPORT: {
        return t('DETAILED_INSPECTION_REPORT');
      }
      default: {
        return 'report';
      }
    }
  };

  const handleDownload = () => {
    validateRequiredFields();
    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{ rowContent: [{ type: 'text', text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS') }] }],
        },
        variant: 'error',
      });
      return;
    }
    downloadReport(reportType, getReportName(reportType)).catch(() => {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('REQUEST_DEFAULT_ERROR')
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
    });
  };

  const isStartDateFilledCorrectly = startDate && isValid(new Date(startDate));
  const isFinishDateFilledCorrectly = finishDate && isValid(new Date(finishDate));
  const isNotValidDateRange = isStartDateFilledCorrectly && isFinishDateFilledCorrectly && isBefore(add(new Date(startDate), { months: maxReportPeriodInMonths }), new Date(finishDate));
  const isDataFilledCorrectly = isStartDateFilledCorrectly && isFinishDateFilledCorrectly;

  return (
    <PaperLayout>
      {isRootOrgUnitsLoading && <ProgressBar />}
      <Header
        titleGoBack={t('GO_BACK')}
        isActiveGoBack
        title={pageTitle}
        handleGoBack={() => {
          history.push({
            pathname: REPORTS,
          });
        }}
      >
        <Button
          disabled={isReportLoading}
          component="label"
          color="primary"
          variant="text"
          startIcon={<FIleDownload />}
          data-test="importButton"
          onClick={handleDownload}
        >
          {t('DOWNLOAD')}
        </Button>
      </Header>
      <PanelBox>
        <PanelTitle>{t('REPORT_ATTRIBUTES')}</PanelTitle>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <DateInput
              onBlur={() => {
                if (isNotValidDateRange) {
                  setIsStartDateValid(false);
                  setIsFinishDateValid(false);
                  return;
                }
                if (startDate && !isValid(new Date(startDate))) setIsStartDateValid(false);
                if (finishDate && isValid(new Date(finishDate))) setIsFinishDateValid(true);
              }}
              label={t('PERIOD_START')}
              name="startDate"
              value={startDate}
              minDate={isFinishDateFilledCorrectly ? add(new Date(finishDate), { months: -maxReportPeriodInMonths }) : new Date(minDate)}
              maxDate={finishDate}
              onChange={(value) => {
                updateField('startDate', value);
                setIsStartDateValid(true);
              }}
              required
              error={!isStartDateValid}
              data-test="startDate"
            />
          </Grid>
          <Grid item xs={6}>
            <DateInput
              onBlur={() => {
                if (isNotValidDateRange) {
                  setIsStartDateValid(false);
                  setIsFinishDateValid(false);
                  return;
                }
                if (finishDate && !isValid(new Date(finishDate))) setIsFinishDateValid(false);
                if (startDate && isValid(new Date(startDate))) setIsStartDateValid(true);
              }}
              label={t('PERIOD_FINISH')}
              name="finishDate"
              value={finishDate}
              minDate={startDate || new Date(minDate)}
              {...isStartDateFilledCorrectly && { maxDate: add(new Date(startDate), { months: 6 }) }}
              onChange={(value) => {
                updateField('finishDate', value);
                setIsFinishDateValid(true);
              }}
              required
              error={!isFinishDateValid}
              data-test="finishDate"
            />
          </Grid>
        </Grid>
        {canSetRootOrgUnit
          ? (
            <Input
              fullOutlined
              label={t('THERMAL_POWER_PLANT')}
              placeholder={t('SELECT_VALUE')}
              value={rootOrgUnitCode}
              name="rootOrgUnit"
              onChange={(rootOrgUnit) => {
                const code = get(rootOrgUnit, 'code');
                updateField('rootOrgUnitCode', code);
              }}
              type="selectAlpha"
              options={rootOrgUnits}
              optionValueKey="code"
              optionTitleKey="name"
            />
          )
          : (
            <OptionRenderer
              title={t('THERMAL_POWER_PLANT')}
              value={rootOrgUnitName}
              wrapperClass={classes.optionRenderer}
              data-test="rootOrgUnitName"
            />
          )}
        {isDataFilledCorrectly ? <LoadReport /> : <FillData />}
        <p className={classes.helperText}>{isDataFilledCorrectly ? t('REPORT_IS_READY_TO_LOAD') : t('FILL_DATA_TO_LOAD_REPORT')}</p>
      </PanelBox>
    </PaperLayout>
  );
};

InspectionReport.propTypes = {
  reportType: PropTypes.oneOf([INSPECTION_STATUSES, COMPLETED_INSPECTION, DETAILED_INSPECTION_REPORT]).isRequired
};

export default observer(InspectionReport);
