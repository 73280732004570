import Schema from 'validate';
import isEmpty from 'lodash/isEmpty';
import { roles } from 'Shared/constants/roles';
import { taskStatusEnum } from 'Shared/constants/taskStatuses';

const queryValidation = val => typeof val === 'string' || typeof val === 'number';

const roleQuery = (val) => {
  if (typeof val === 'undefined') return true;
  if (typeof val !== 'undefined' && typeof val !== 'object') return false;
  if (isEmpty) return true;

  const [keys, values] = Object.entries(val);

  return !(!keys.every(role => roles.includes(role))
    || !values.every(status => taskStatusEnum.includes(status)));
};

const user = new Schema({
  title: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  query: {
    type: Array,
    each: { use: { queryValidation } },
    required: true,
  },
  access: {
    type: Array,
    each: { type: String },
  },
  queryByRole: {
    use: { roleQuery }
  }
});

export default user;
