import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    background: '#fff',
  },
  body: {
    margin: 0,
    padding: '0 24px',
    overflowY: 'auto',
    height: 'calc(100vh - 250px)',
    '&.footer-on': {
      height: 'calc(100vh - 338px)',
    }
  },
  commonDetails: {
    marginRight: -24,
    marginLeft: -24,
  },
  wrapperContent: {
    padding: '20px 0 25px',
  },
  header: {
    fontWeight: 'bold',
    marginTop: 35
  },
  divider: {
    marginRight: -24,
    marginLeft: -24,
  },
  actions: {
    textAlign: 'right',
    height: 96,
    margin: '0',
    padding: '24px',
    borderTop: '1px solid #f3f3f3',
    '& .MuiButtonBase-root': {
      marginLeft: 24,
      minWidth: 240
    }
  },
  reasonFieldset: {
    padding: '10px 5px',
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: '#4499ee',
    },
    '& .MuiFormControlLabel-root': {
      margin: '2px 0 0'
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 15
    }
  }
}));

export default useStyles;
