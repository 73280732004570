const TEXT = 'TEXT';
const NUMERIC = 'NUMERIC';
const MANUAL = 'MANUAL';
const BINARY = 'BINARY';
const SINGLE_SELECT = 'SINGLE_SELECT';

export const ParameterType = {
  MANUAL, BINARY, SINGLE_SELECT,
};

export const ParameterName = {
  TEXT, NUMERIC,
};

export const columnsType = {
  PARAMETER_TYPE: 'parameterType',
  DATA_TYPE: 'dataType',
  UNIT: 'unit',
  OPERATION_KEYS: 'operationKeys',
  DIRECTORIES: 'directories',
};

export default module;
