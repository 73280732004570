import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { get, isNull } from 'lodash';
import { Box, TextField } from '@material-ui/core';
import useStores from 'Store/useStores';
import OptionRenderer from 'Common/components/Forms/OptionRenderer';

import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const Description = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    coalTaskStore: {
      taskData,
      updateDataField,
    },
  } = useStores();
  const {
    description,
  } = taskData;

  const { isReadonlyByMode } = props;

  const onChangeDescription = (event) => {
    const value = get(event, 'target.value');
    updateDataField('description', value);
  };

  if (isReadonlyByMode) {
    if (isNull(description) || !description) return null;
    return (
      <OptionRenderer
        title={t('TASK_DESCRIPTION')}
        value={description}
        wrapperClass={classes.optionRenderer}
      />
    );
  }
  return (
    <Box css={{ marginBottom: '10px', maxWidth: 500 }}>
      <TextField
        label={t('TASK_DESCRIPTION')}
        fullWidth
        variant="outlined"
        InputProps={{
          style: { minHeight: '94px' }
        }}
        multiline
        rows={3}
        value={description}
        name="description"
        onChange={onChangeDescription}
      />
    </Box>
  );
};

Description.propTypes = {
  isReadonlyByMode: PropTypes.bool.isRequired,
};

export default observer(Description);
