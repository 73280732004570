import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams, withRouter } from 'react-router-dom';
import Layout from 'Common/widgets//Layout/Paper/Layout';
import HeaderLayout from 'Common/widgets/Layout/Header/Header';
import { observer } from 'mobx-react';
import Loader from 'Common/components/Progress/components/Circular/ProgressBar';
import { TASKS } from 'Shared/constants/routes';
import useStores from 'Store/useStores';
import {
  Box,
  Tab,
  Tabs,
} from '@material-ui/core';
import taskTabsConfig from 'Src/business/tpsRepairs/config/taskTabsConfig';
import View from 'Src/business/tpsRepairs/pages/Task/View/View';
import HistoryTab from 'Src/business/tpsRepairs/components/HistoryTab/HistoryTab';
import { MASTER, ROLE_MATCHING, FOREMAN_RU } from 'Shared/constants/roles';
import { ASSIGNED_ENUM, NEW_ENUM, PAUSE_ENUM } from 'Shared/constants/taskStatuses';
import TabPanel from 'Common/components/TabPanel';

const Task = observer(({
  match,
  history,
}) => {
  const {
    tasksStore: {
      assignTask,
      rejectTask,
      isLoading,
      getReasons,
      getTaskById,
      reasonsList,
      selectedTask = {},
      materials,
      dictionaries,
      getDictionaryByKeys,
      isDictionariesLoaded,
    },
    userStore: {
      loadUsersByRole,
      isLoading: isUsersLoading,
      role,
    },
    brigadeStore: {
      loadAll: loadBrigades
    },
    notificationStore: { enqueueSnackbar },
  } = useStores();

  const { t } = useTranslation();
  const location = useLocation();

  const translatedTaskTabsConfig = taskTabsConfig(t);
  const { taskStatus = '', assignments = [] } = selectedTask;

  const { tab = 'common' } = match.params;
  const { id } = useParams();
  const defaultTab = translatedTaskTabsConfig[0];

  const handleChange = (event, newValue) => {
    const { state } = location;
    history.push({ pathname: `/tasks/view/${id}/${newValue === defaultTab.alias ? '' : newValue}`, state });
  };

  useEffect(() => {
    if (!translatedTaskTabsConfig.some(item => item.alias === tab)) {
      history.push(`/tasks/view/${id}/`);
    }
  }, []);

  const loadPotentialAssignees = () => {
    if (role === MASTER) {
      //TODO: fetch brigades by orgunit
      loadBrigades().catch(() => {
        enqueueSnackbar({
          messageTemplate: {
            rows: [{
              rowContent: [{
                type: 'text',
                text: t('REQUEST_BRIGADES_LIST_ERROR')
              }]
            }],
          },
          variant: 'error',
        });
      });
    } else {
      // fetchUsersByOrgUnit();
      loadUsersByRole({
        roles: ROLE_MATCHING[role] ? ROLE_MATCHING[role].join(',') : ''
      });
    }
  };

  useEffect(() => {
    getDictionaryByKeys(['TaskType']);
    getReasons();
    loadPotentialAssignees();
  }, []);

  useEffect(() => {
    getTaskById(match.params.id);
  }, [match.params.id]);

  const handleGoBack = () => {
    const { state } = location;
    const { fromPath, ...rest } = state || {};
    if (fromPath) {
      history.push({
        pathname: fromPath,
        state: rest,
      });
      return;
    }
    let taskStatusPathPart = taskStatus.toLowerCase();
    if (taskStatus === ASSIGNED_ENUM) {
      const isOnNewTab = assignments.every(({ identityType, positionName }) => identityType === 'ORG_UNIT' || (identityType === 'USER' && positionName === FOREMAN_RU));
      taskStatusPathPart = isOnNewTab ? NEW_ENUM.toLowerCase() : ASSIGNED_ENUM.toLowerCase();
    }
    if (taskStatus === PAUSE_ENUM) {
      taskStatusPathPart = 'all';
    }
    history.push(`${TASKS}/${taskStatusPathPart}`);
  };

  if (isLoading || isUsersLoading) return <Loader />;

  return (
    <Layout autoHeight>
      <HeaderLayout
        title={t('TASK_VIEW')}
        handleGoBack={handleGoBack}
        isActiveGoBack
        titleGoBack={t('GO_BACK')}
      />
      <Box style={{ borderBottom: '1px solid #e9e9e9' }}>
        <Tabs
          value={!tab ? defaultTab.alias : tab}
          onChange={(event, newValue) => handleChange(event, newValue)}
          variant="scrollable"
        >
          {translatedTaskTabsConfig.map(
            item => (
              <Tab
                key={item.alias}
                label={item.title}
                value={item.alias}
              />
            )
          )
              }
        </Tabs>
      </Box>
      {selectedTask && selectedTask.taskId && (
      <>
        <TabPanel tab={tab} alias={translatedTaskTabsConfig[0].alias}>
          {isDictionariesLoaded && (
            <View
              task={selectedTask}
              reasons={reasonsList}
              materials={materials}
              dictionaries={dictionaries}
              assignTask={assignTask}
              rejectTask={rejectTask}
            />
          )}
        </TabPanel>
        {/*<TabPanel tab={tab} alias={translatedTaskTabsConfig[1].alias}>*/}
        {/*  {translatedTaskTabsConfig[1].title}*/}
        {/*</TabPanel>*/}
        <TabPanel tab={tab} alias={translatedTaskTabsConfig[1].alias}>
          <HistoryTab taskId={selectedTask.taskId} />
        </TabPanel>
      </>
      )}
    </Layout>
  );
});

Task.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
  match: PropTypes.object,
};

export default withRouter(Task);
