import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { toJS } from 'mobx'; // eslint-disable-line
import { isEmpty, isObject } from 'lodash';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  Typography,
} from '@material-ui/core/';
import useStores from 'Store/useStores';
import * as routes from 'Shared/constants/routes';

import Panel, { PanelBox, PanelTitle } from 'Common/widgets/Layout/Panel/Panel';
import TextInput from 'Common/components/Forms/Input/Text';
import EmptyDataBlock from 'Common/widgets/EmptyDataBlock';
import TaskRouteOperationsOverlay from 'Business/coal/components/TaskRouteOperationsOverlay';
import SortableTechnicalObjects from 'Business/coal/components/SortableTechnicalObjects';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import AddButtonWithValidation from 'Common/components/Validation/AddButtonWithValidation';
import useManageEntityModal from 'Common/components/Modal/ManageEntityModal/useManageEntityModal';
import { ReactComponent as IconSettings } from 'Assets/icon-settings.svg';
import OverflowTip from 'Common/widgets/OverflowTip/OverflowTip';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const TaskRouteDetails = observer(({
  id: viewerId,
  onClose
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { id, action: mode = 'view' } = viewerId ? {
    id: viewerId,
    action: 'view'
  } : useParams();

  const {
    coalRouteStore: {
      structureDictionary,
      isLoading,
      isUpdating,
      isEmptyDataReceived,
      technicalObjects,
      handleDeleteTechnicalObject,
      prepareForEditing,
      updateTechnicalObjects,
      toggleIsRequiredQRCode,
      discardState,
      routeName,
      onChangeRouteName,
      prepareForAdding,
      saveRoute,
      loadRouteById,
      addDepartmentsToStructureDictionary,
      addSavedTechObjectsToStructureDictionary,
      loadDepartments,
      departments,
      setOverlayMode,
    },
    coalRoutesListStore: {
      isDeleting,
      deleteRoute,
      isRouteIncludedInActiveTask,
    } = {},
    notificationStore: { enqueueSnackbar },
    validationStore: { validateRequiredFields, isValidFields, hasErrors },
  } = useStores();

  const {
    setManageEntityModalVisibility, setOnManageEntityModalCloseCallback, setManageEntityModalState, renderManageEntityModal
  } = useManageEntityModal();

  const [isOperationOverlayOpen, setOperationOverlayVisibility] = useState(false);

  const shouldLoadRouteById = ['edit', 'view'].includes(mode);
  const showTechnicalObjects = technicalObjects.length > 0;
  let showSortableDragElement;
  let showAddTechnicalObjectsButton;
  let requiredValidationMessage;
  let successSaveMessage;

  let isSortable;
  switch (mode) {
    case 'view':
      isSortable = false;
      showSortableDragElement = false;
      showAddTechnicalObjectsButton = false;
      break;
    case 'create':
      isSortable = technicalObjects.length > 1;
      showSortableDragElement = true;
      showAddTechnicalObjectsButton = true;
      requiredValidationMessage = t('CANT_CREATE_TASK_ROUTE');
      successSaveMessage = t('TASK_ROUTE_SUCCESSFULLY_CREATED');
      break;
    case 'edit':
      isSortable = technicalObjects.length > 1;
      showSortableDragElement = true;
      showAddTechnicalObjectsButton = true;
      requiredValidationMessage = t('CANT_UPDATE_TASK_ROUTE');
      successSaveMessage = t('CHANGES_SUCCESSFULLY_APPLIED');
      break;
    default:
      isSortable = false;
      showSortableDragElement = false;
      showAddTechnicalObjectsButton = false;
      requiredValidationMessage = '';
  }

  const classes = useStyles({ isSortable });

  const goToRoutesList = () => {
    history.push({
      pathname: routes.TASK_ROUTES,
    });
  };

  const handleDeleteRoute = () => async () => {
    const { error } = (await deleteRoute(id)) || {};
    if (error) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('REQUEST_DEFAULT_ERROR'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('TASK_ROUTE_SUCCESSFULLY_REMOVED'),
              },
            ],
          },
        ],
      },
      variant: 'success',
    });
    goToRoutesList();
  };

  const deleteItem = async () => {
    const isIncludedInActiveTask = await isRouteIncludedInActiveTask(id);
    setManageEntityModalState(() => ({
      title: t('DELETING_A_TASK_ROUTE'),
      entityName: <OverflowTip tooltipTitle={routeName} text={routeName} data-test="routeName" />,
      message: isIncludedInActiveTask ? `${t('THERE_ARE_OUTSTANDING_TASKS_ALONG_THIS_ROUTE')} ${t('ARE_YOU_SURE_YOU_WANT_TO_DELETE_TASK_ROUTE')}` : t('ARE_YOU_SURE_YOU_WANT_TO_DELETE_TASK_ROUTE'),
      type: 'action',
      actionButtonTitle: t('DELETE'),
    }));
    setOnManageEntityModalCloseCallback(() => handleDeleteRoute(id));
    setManageEntityModalVisibility(true);
  };

  const handleOperationsOverlayClose = () => {
    setOperationOverlayVisibility(false);
  };

  const editItem = () => {
    history.push({
      pathname: routes.EDIT_TASK_ROUTE
        .replace(':id', id)
        .replace(':action(edit)', 'edit')
    });
  };

  const goBack = () => {
    if (viewerId) {
      onClose(viewerId);
    } else {
      goToRoutesList();
    }
  };

  const viewItem = (newId) => {
    history.push({
      pathname: routes.VIEW_TASK_ROUTE.replace(':id', parseInt(newId, 10) ? newId : id).replace(
        ':action(view)',
        'view',
      ),
    });
  };

  const saveItem = async () => {
    validateRequiredFields();
    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: requiredValidationMessage,
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    const { res } = await saveRoute(id);
    if (isObject(res)) {
      const { id: newId } = res;
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: successSaveMessage,
                },
              ],
            },
          ],
        },
        variant: 'success',
      });
      if (id) {
        viewItem(newId);
      } else {
        goBack();
      }
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('REQUEST_DEFAULT_ERROR'),
              },
            ],
          },
        ],
      },
      variant: 'error',
    });
  };

  useEffect(() => {
    // if id is not valid
    if (shouldLoadRouteById && !parseInt(id, 10)) {
      goBack();
    }
  }, [id]);

  useEffect(() => () => { discardState(); }, []);

  useEffect(() => {
    if (id && parseInt(id, 10)) {
      loadRouteById(id);
    }
  }, [id, location.key]);

  if (isLoading || isUpdating) {
    return <ProgressBar />;
  }

  const renderProgressIndicator = () => technicalObjects.map(({ id: routeTechnicalObjectId }, index) => {
    const progressIndicatorKey = `progressIndicator${routeTechnicalObjectId}${index}`;
    return (
      <div key={progressIndicatorKey} className={classes.progressIndicator} />
    );
  });

  const loadDictionaryData = async () => {
    if (isEmpty(departments)) {
      await loadDepartments().catch(() => {
        enqueueSnackbar(
          {
            messageTemplate: {
              rows: [
                {
                  rowContent: [
                    {
                      type: 'text',
                      text: t('REQUEST_LOAD_DEPARTMENTS_ERROR'),
                    },
                  ],
                },
              ],
            },
            variant: 'error',
          }
        );
      });
    }
    if (isEmpty(structureDictionary)) {
      await addDepartmentsToStructureDictionary().catch(() => {
        enqueueSnackbar({
          messageTemplate: {
            rows: [
              {
                rowContent: [
                  {
                    type: 'text',
                    text: t('REQUEST_LOAD_TECH_OBJECTS_ERROR'),
                  },
                ],
              },
            ],
          },
          variant: 'error',
        });
      });
    }
    await addSavedTechObjectsToStructureDictionary();
  };

  const handleAdd = async () => {
    setOverlayMode('add');
    await loadDictionaryData();
    prepareForAdding();
    setOperationOverlayVisibility(true);
  };

  const handleEdit = async (technicalObjectId) => {
    setOverlayMode('edit');
    await loadDictionaryData();
    prepareForEditing(technicalObjectId);
    setOperationOverlayVisibility(true);
  };

  if (isLoading) return null;
  const showContent = !isEmptyDataReceived;
  return (
    <Panel
      mode={viewerId ? 'onlyView' : mode}
      viewItem={viewItem}
      editItem={editItem}
      saveItem={saveItem}
      goBack={goBack}
      deleteItem={deleteItem}
      labelGoToView={t('GO_BACK')}
      labelGoToList={t('GO_BACK')}
      labelView={routeName}
      labelCreate={t('ROUTE_CREATION')}
      labelEdit={t('ROUTE_EDITING')}
      actions={showContent ? ['edit', 'delete'] : []}
    >
      <>
        {showContent ? (
          <>
            {isDeleting && <ProgressBar />}
            <PanelBox>
              <PanelTitle>{t('ROUTE_ATTRIBUTES')}</PanelTitle>

              <TextInput
                label={t('NAME')}
                value={routeName}
                name="routeName"
                onChange={onChangeRouteName}
                isReadonly={mode === 'view'}
                required
                data-test="routeName"
              />

            </PanelBox>
            <PanelBox>
              <Typography variant="h3" className={classes.title} style={{ margin: '21px 1px' }}>{t('ROUTE_TECH_OBJECTS')}</Typography>
              {showTechnicalObjects && (
              <div className={classes.technicalObjectWrapper}>
                {isSortable && (
                <div className={classes.progressBar}>
                  {renderProgressIndicator()}
                </div>
                )}
                <div className={classes.technicalObject}>
                  <SortableTechnicalObjects
                    technicalObjects={technicalObjects}
                    updateTechnicalObjects={updateTechnicalObjects}
                    isSortable={isSortable}
                    showSortableDragElement={showSortableDragElement}
                    mode={mode}
                    toggleIsRequiredQRCode={toggleIsRequiredQRCode}
                    handleDeleteTechnicalObject={handleDeleteTechnicalObject}
                    onEdit={handleEdit}
                    setOperationOverlayVisibility={setOperationOverlayVisibility}
                  />
                </div>
              </div>
              )}
              {showAddTechnicalObjectsButton && (
              <AddButtonWithValidation
                title={t('ADD_EQUIPMENT')}
                iconComponent={(<IconSettings />)}
                data-test="add-technicalObjects-button"
                onClick={handleAdd}
                name="add-technicalObjects-button"
                value={!isEmpty(technicalObjects)}
                required
              />
              )}
            </PanelBox>
            {isOperationOverlayOpen && (
            <TaskRouteOperationsOverlay
              isOpen={isOperationOverlayOpen}
              handleClose={handleOperationsOverlayClose}
            />
            )}
            {renderManageEntityModal()}
          </>
        )
          : (
            <div className={classes.emptyDataBlockWrapper}>
              <EmptyDataBlock
                title={t('CANT_VIEW_ROUTE')}
                subTitle={t('MAYBE_ROUTE_WAS_DELETED')}
              />
            </div>
          )
  }
      </>
    </Panel>
  );
});

TaskRouteDetails.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func
};

TaskRouteDetails.defaultProps = {
  id: 0,
};

export default TaskRouteDetails;
