import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '50%',
    resize: 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  blockHeader: {
    padding: '24px 0px 18px 0px',
  },
  blockHeaderLabel: {
    fontSize: 20,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.4,
    letterSpacing: 'normal',
    color: theme.palette.black,
  },
  addPlantsButton: {
    boxShadow: 'none',
    minHeight: 80,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderStyle: 'dashed !important',
    fontSize: 16,
    fontWeight: 'normal',
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
      marginRight: 8,
    },
  },
  plant: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    resize: 'none',
    padding: '0px 16px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.16)',
    border: `solid 1px ${theme.palette.midGray}`,
    marginBottom: 8,
  },
  plantIdInputWrapper: {
    marginTop: 8,
    width: 'calc((100% - 72px) / 2)',
    marginRight: 16,
  },
  icon: {
    width: 24,
    height: 24,
  },
  deleteIconButton: {
    width: 32,
    height: 32,
    marginLeft: 16,
    color: theme.palette.negative,
    '&:hover': {
      backgroundColor: theme.palette.pink
    }
  },
}));

export default useStyles;
