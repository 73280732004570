import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Tooltip, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    appearance: 'none',
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',
    color: theme.palette.blue,
    '&::before, &::after': {
      display: 'none'
    },
  },
  value: {
    position: 'absolute',
    top: 0,
    left: 0,
    pointerEvents: 'none',
    opacity: 0
  }
}));

const CopyToClipboard = ({ value, shouldStopPropagation, ...rest }) => {
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const defaultTooltipTitle = t('COPY_TAG_CODE');
  const classes = useStyles();
  const [tooltipTitle, tooltipTitleSet] = useState(defaultTooltipTitle);

  const clearSelection = () => {
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    } else if (document.selection) {
      document.selection.empty();
    }
  };

  const clickHandler = (e) => {
    e.preventDefault();
    if (shouldStopPropagation) e.stopPropagation();
    inputRef.current.select();
    inputRef.current.focus();

    if (document.execCommand('copy')) {
      tooltipTitleSet(t('COPIED'));
      clearSelection();
    }
  };

  return (
    <Tooltip
      title={tooltipTitle}
      placement="bottom"
      data-test={`copy-to-clipboard-tooltip-${value}`}
    >
      <Box
        className={classes.root}
        onMouseEnter={() => tooltipTitleSet(defaultTooltipTitle)}
        onClick={clickHandler}
        data-test={`copy-to-clipboard-button-${value}`}
        {...rest}
      >
        <span>{value}</span>
        <input
          ref={inputRef}
          className={classes.value}
          readOnly
          value={value}
          data-test={`copy-to-clipboard-input-${value}`}
        />
      </Box>
    </Tooltip>
  );
};

CopyToClipboard.defaultProps = {
  shouldStopPropagation: false,
};

CopyToClipboard.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  shouldStopPropagation: PropTypes.bool,
};

export default CopyToClipboard;
