import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box, Typography, Grid, ButtonBase, IconButton, SvgIcon,
} from '@material-ui/core';
import CheckboxInput from 'Common/components/Forms/Input/Checkbox';
import { DeleteOutlined } from '@material-ui/icons';
import OverflowTipSimple from 'Common/widgets/OverflowTipSimple';
import operationStatusesTranslationKeys from 'Shared/constants/operationStatuses';

import useStyles from './styles';

const OperationView = (props) => {
  const {
    title,
    subTitle,
    status,
    counters,
    onClick,
    onDelete,
    disableRipple,
    checked,
    maxWidth,
    boxShadowOnHover,
    headerActionButton,
    hideHeaderButtonOnBlur,
  } = props;

  const invisibleTaskStatuses = ['TODO'];

  const { t } = useTranslation();
  const classes = useStyles({
    onClick, onDelete, maxWidth, boxShadowOnHover, hideHeaderButtonOnBlur,
  });

  const ButtonWrapper = ({ children }) => (
    <ButtonBase
      disableRipple={disableRipple}
      onClick={onClick}
      className={classes.buttonBase}
    >
      {children}
    </ButtonBase>
  );

  ButtonWrapper.propTypes = {
    children: PropTypes.node.isRequired,
  };

  const operationView = () => (
    <Box className={classes.wrapper}>
      {onClick && (
        <CheckboxInput
          name="operationCheckbox"
          disableRipple
          checked={checked}
          className={classes.checkbox}
          data-test="operationCheckbox"
        />
      )}
      {onDelete && (
        <IconButton
          disableRipple
          className={classes.deleteButton}
          onClick={onDelete}
        >
          <SvgIcon
            component={DeleteOutlined}
          />
        </IconButton>
      )}
      <div className={`${classes.header} ${(!status || invisibleTaskStatuses.includes(status)) ? 'without-status' : ''}`}>
        <OverflowTipSimple
          tooltipProps={{ placement: 'top' }}
          tooltipTitle={title}
          text={title}
          textProps={{ className: classes.headerTitleText }}
          data-test="equipment_name"
        />
        <div className={classes.headerActionButtonWrapper}>
          {status && (
          <Typography className={`${classes.status} status-${status}`}>
            {operationStatusesTranslationKeys[status] ? t(operationStatusesTranslationKeys[status]) : t('UNKNOWN') }
          </Typography>
          )}
          {headerActionButton && (
            <div className={classes.headerActionButton}>
              {headerActionButton}
            </div>
          )}
        </div>
      </div>

      <div className={classes.subTitle} data-test="subTitle">{subTitle}</div>

      {counters && counters.top && (
        <Grid container wrap="nowrap" spacing={2}>
          {counters.top.left && (
            <Grid item xs={12} data-test="counters_top_left">
              {counters.top.left}
            </Grid>
          )}
          {counters.top.right && (
            <Grid item xs={12} align="right" data-test="counters_top_right">
              {counters.top.right}
            </Grid>
          )}
        </Grid>
      )}

      {counters && counters.bottom && (
        <Grid container wrap="nowrap" spacing={2}>
          {counters.bottom.left && (
            <Grid item xs={12} style={{ maxWidth: counters.bottom.right ? '50%' : '100%' }}>
              {counters.bottom.left}
            </Grid>
          )}
          {counters.bottom.right && (
            <Grid item xs={12} align="right" style={{ maxWidth: counters.bottom.left ? '50%' : '100%' }}>
              {counters.bottom.right}
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );

  if (onClick) return <ButtonWrapper onClick={onClick}>{operationView()}</ButtonWrapper>;
  return operationView();
};

OperationView.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  status: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  counters: PropTypes.shape({
    top: PropTypes.shape({
      left: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.number
      ]),
      right: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.number
      ]),
    }),
    bottom: PropTypes.shape({
      left: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.number
      ]),
      right: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.number
      ]),
    }),
  }),
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  disableRipple: PropTypes.bool,
  checked: PropTypes.bool,
  maxWidth: PropTypes.bool,
  boxShadowOnHover: PropTypes.bool,
  headerActionButton: PropTypes.node,
  hideHeaderButtonOnBlur: PropTypes.bool,
};

OperationView.defaultProps = {
  status: null,
  subTitle: '',
  onClick: null,
  onDelete: null,
  disableRipple: false,
  checked: false,
  maxWidth: false,
  boxShadowOnHover: false,
  headerActionButton: null,
  hideHeaderButtonOnBlur: false,
  counters: undefined,
};

export default OperationView;
