import React from 'react';
import { formatDate } from 'Src/utils/datetime';
import withStyles from 'Src/utils/table/withColumnStyles';
import SelectionDate from 'Src/business/tpsRepairs/containers/SelectionDate/SelectionDate';
import AssignUserButton from 'Src/business/tpsRepairs/containers/AssignUser/AssignUserContainer';
import RejectionReasonButton from 'Src/business/tpsRepairs/containers/RejectionReasonButton/RejectionReasonButton';
import { taskStatusAliases } from 'Src/business/tpsRepairs/config/taskStatusAliases';
import { FOREMAN } from 'Shared/constants/roles';

const renderSelectionDate = (rowData) => {
  const {
    taskId,
    title,
    plannedStartDate,
    plannedStartDateNew = '',
    expectedStartDate,
    expectedFinishDate,
    taskStatus,
    currentAssignee,
    role
  } = rowData;

  return taskStatus && taskStatus.toLowerCase() === taskStatusAliases(it => it).ASSIGNED.name && role === FOREMAN ? (
    <SelectionDate
      taskId={taskId}
      taskTitle={title}
      plannedStartDate={plannedStartDateNew || plannedStartDate}
      expectedStartDate={expectedStartDate}
      expectedFinishDate={expectedFinishDate}
      currentAssignee={currentAssignee || {}}
    />
  ) : formatDate(plannedStartDate);
};

const renderAssignee = (task) => {
  const {
    title,
    taskId,
    taskStatus,
    currentAssignee,
    plannedStartDate
  } = task;

  return (
    <AssignUserButton
      id={taskId}
      status={taskStatus}
      title={title}
      currentAssignee={currentAssignee || {}}
      plannedStartDate={plannedStartDate}
    />
  );
};

const renderRejectionReason = task => (
  <RejectionReasonButton
    reason={task.taskRejectionReason}
    comment={task.rejectReasonComment}
    shouldStopPropagation
  />
);

const tableColumns = t => (
  {
    title: {
      field: 'title',
      title: t('NAME'),
      mainLink: true,
      withEllipsis: true,
      width: '30%',
      cellStyle: {
        width: '30%',
        whiteSpace: 'normal',
        overflowWrap: 'anywhere',
      },
    },
    rootTechnicalObjectIds: {
      field: 'rootTechnicalObjectIds',
      title: t('EQUIPMENT'),
      withEllipsis: true,
      cellStyle: {
        whiteSpace: 'normal',
        overflowWrap: 'anywhere',
      },
    },
    assignments: {
      field: 'assignments',
      title: t('EMPLOYEE'),
      align: 'center',
      sorting: false,
      render: renderAssignee
    },
    plannedStartDate: {
      field: 'plannedStartDate',
      title: t('EXPECTED_START_DATE'),
      align: 'center',
      render: renderSelectionDate
    },
    expectedDate: {
      field: 'expectedDate',
      title: t('EXECUTION_PERIOD'),
    },
    taskRejectionReason: {
      field: 'taskRejectionReason',
      title: t('REASON_COMMENT'),
      sorting: false,
      render: renderRejectionReason
    },
    taskStatus: {
      field: 'taskStatusTitle',
      title: t('STATE'),
    },
  }
);

export const statusesColumns = (t) => {
  const translatedTableColumns = tableColumns(t);
  return (
    {
      NEW: {
        columns: [
          withStyles(translatedTableColumns.title, { width: '30%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.rootTechnicalObjectIds, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.assignments, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.plannedStartDate, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.expectedDate, { width: '15%', whiteSpace: 'normal' }),
        ],
        actions: ['assign']
      },
      ASSIGNED: {
        columns: [
          withStyles(translatedTableColumns.title, { width: '30%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.rootTechnicalObjectIds, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.assignments, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.plannedStartDate, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.expectedDate, { width: '15%', whiteSpace: 'normal' }),
        ],
        actions: ['assign']
      },
      IN_PROGRESS: {
        columns: [
          withStyles(translatedTableColumns.title, { width: '30%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.rootTechnicalObjectIds, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.assignments, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.plannedStartDate, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.expectedDate, { width: '15%', whiteSpace: 'normal' }),
        ],
        actions: []
      },
      COMPLETED: {
        columns: [
          withStyles(translatedTableColumns.title, { width: '30%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.rootTechnicalObjectIds, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.assignments, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.plannedStartDate, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.expectedDate, { width: '15%', whiteSpace: 'normal' }),
        ],
        actions: []
      },
      CANCELED: {
        columns: [
          withStyles(translatedTableColumns.title, { width: '30%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.rootTechnicalObjectIds, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.assignments, { width: '10%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.plannedStartDate, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.expectedDate, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.taskRejectionReason, { width: '10%', whiteSpace: 'normal' }),
        ],
        actions: []
      },
      ALL: {
        columns: [
          withStyles(translatedTableColumns.title, { width: '30%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.rootTechnicalObjectIds, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.assignments, { width: '10%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.plannedStartDate, { width: '10%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.expectedDate, { width: '10%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.taskStatus, { width: '10%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.taskRejectionReason, { width: '10%', whiteSpace: 'normal' }),
        ],
        actions: []
      },
      ARCHIVE: {
        columns: [
          withStyles(translatedTableColumns.title, { width: '30%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.rootTechnicalObjectIds, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.assignments, { width: '10%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.plannedStartDate, { width: '10%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.expectedDate, { width: '10%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.taskStatus, { width: '10%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.taskRejectionReason, { width: '10%', whiteSpace: 'normal' }),
        ],
        actions: []
      }
    }
  );
};

export default tableColumns;
