import withStyles from 'Src/utils/table/withColumnStyles';

const tableColumns = t => (
  {
    title: {
      field: 'title',
      title: t('OSR_NAME'),
      withEllipsis: 'true',
      mainLink: 'true',
    },
    photosQuality: {
      field: 'photosQuality',
      title: t('PHOTOS_QUALITY'),
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      withEllipsis: 'true',
    },
    minAllowedSize: {
      field: 'minAllowedSize',
      title: t('MIN_ALLOWED_SIZE'),
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      withEllipsis: 'true',
    },
  }
);

export const tableConfig = (t) => {
  const translatedTableColumns = tableColumns(t);
  return (
    {
      columns: [
        withStyles(translatedTableColumns.title, { width: '40%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.photosQuality, { width: '30%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.minAllowedSize, { width: '30%', whiteSpace: 'normal' }),
      ],
      actions: [],
    }
  );
};

export default tableColumns;
