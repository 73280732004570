import React from 'react';
import PropTypes from 'prop-types';
import {
  Slider as SliderMaterial, Input, FormControl, FormLabel
} from '@material-ui/core';

import OptionRenderer from 'Common/components/Forms/OptionRenderer';

import useStyles from './sliderStyles';

const getValFromNumInput = e => (e.target.value === '' ? '' : Number(e.target.value));

const Slider = ({
  label,
  name,
  inputPosition,
  value,
  onChange,
  className,
  style,
  'data-test': dataTest,
  fullWidth,
  unitLabel,
  isReadonly,
  min,
  max,
  step,
  disabled,
  OptionRendererProps
}) => {
  if (isReadonly) {
    return (
      <OptionRenderer
        value={value}
        title={label}
        name={name}
        endAdornmentNode={unitLabel}
        {...OptionRendererProps}
      />
    );
  }

  const classes = useStyles();
  const direction = inputPosition === 'left' ? 'row-reverse' : 'row'; // eslint-disable-line

  const validateValue = (val) => {
    let validatedValue = val;
    if (min && validatedValue < min) {
      validatedValue = min;
    } if (max && validatedValue > max) {
      validatedValue = max;
    }
    if (step && val > step) {
      validatedValue = Math.trunc(val / step) * step;
    }
    return validatedValue;
  };

  const handleChangeSlider = (e, newVal) => {
    onChange(validateValue(newVal));
  };
  const handleInputChange = (e) => {
    const val = getValFromNumInput(e);
    onChange(val);
  };
  const handleBlur = (e) => {
    const val = getValFromNumInput(e);
    if (val < min) {
      onChange(min);
    } else if (val > max) {
      onChange(max);
    }
  };

  return (
    <FormControl
      classes={{ root: classes.formControl }}
      margin="dense"
      fullWidth={fullWidth}
      {...style && { style }}
      {...className && { className }}
      {...dataTest && { 'data-test': dataTest }}
    >
      <FormLabel className={classes.formLabel}>{label}</FormLabel>
      <div style={{ display: 'flex', padding: '8px 0px' }}>
        <SliderMaterial
          step={step}
          min={min}
          max={max}
          classes={{ thumb: classes.thumbPoint, root: classes.root }}
          value={typeof value === 'number' ? value : 0}
          onChange={handleChangeSlider}
        />
        <div className={classes.inputWrap}>
          <Input
            classes={{
              root: classes.input,
            }}
            value={value}
            margin="dense"
            onChange={handleInputChange}
            onBlur={handleBlur}
            disabled={disabled}
            inputProps={{
              type: 'number',
              className: classes.input,
            }}
          />
        </div>
        {unitLabel && (
          <div style={{ display: 'flex', alignItems: 'center' }}>{unitLabel}</div>
        )}
      </div>
    </FormControl>
  );
};

Slider.defaultProps = {
  label: '',
  name: '',
  unitLabel: null,
  min: 0,
  max: 100,
  step: undefined,
  inputPosition: 'right',
  value: 50,
  onChange: () => {},
  disabled: false,
  'data-test': undefined,
  className: undefined,
  style: undefined,
  fullWidth: false,
  isReadonly: false,
  OptionRendererProps: {}
};

Slider.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  unitLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  inputPosition: PropTypes.oneOf(['left', 'right']),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  'data-test': PropTypes.string,
  style: PropTypes.shape({
    marginTop: PropTypes.number,
  }),
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  isReadonly: PropTypes.bool,
  OptionRendererProps: PropTypes.shape({
    wrapperClass: PropTypes.string
  })
};

export default Slider;
