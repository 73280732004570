import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Input from 'Common/components/Forms/Input/Input';
import { makeStyles } from '@material-ui/core/styles';
import {
  ButtonBase
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  buttonBase: {
    cursor: 'text',
  },
}));

const InputWithTextEndAdornment = (props) => {
  const inputRef = useRef(null);
  const classes = useStyles();
  const { endAdornmentNode, ...restProps } = props;
  return (
    <Input
      inputRef={inputRef}
      {...restProps}
      InputEndAdornment={(
        <ButtonBase
          classes={{ root: classes.buttonBase }}
          disableRipple
          onClick={() => inputRef.current.focus()}
          onMouseDown={(e) => {
            e.preventDefault();
          }}
        >
          {endAdornmentNode}
        </ButtonBase>
      )}
    />
  );
};

InputWithTextEndAdornment.defaultProps = {
  endAdornmentNode: '',
};

InputWithTextEndAdornment.propTypes = {
  endAdornmentNode: PropTypes.node,
};

export default InputWithTextEndAdornment;
