export const TPS_REPAIR = 'TPS_REPAIR';
export const TPS_INSPECTION = 'TPS_INSPECTION';
export const UNKNOWN = 'UNKNOWN';
export const COAL = 'COAL';
export const PL = 'PL';

export const TPS_REPAIR_RU = 'ТЭС.Ремонты';
export const TPS_INSPECTION_RU = 'ТЭС.Обходы';
export const COAL_RU = 'Уголь';
export const PL_RU = 'Сети';

export const businessRU = {
  [TPS_REPAIR_RU]: TPS_REPAIR,
  [TPS_INSPECTION_RU]: TPS_INSPECTION,
  [COAL_RU]: COAL,
  [PL_RU]: PL,
};

export const business = {
  [TPS_REPAIR]: TPS_REPAIR_RU,
  [TPS_INSPECTION]: TPS_INSPECTION_RU,
  [COAL]: COAL_RU,
  [PL]: PL_RU,
};

export const businessTypeAlias = {
  [UNKNOWN]: 0,
  [PL]: 1,
  [TPS_REPAIR]: 2,
  [TPS_INSPECTION]: 3,
  [COAL]: 4
};
