import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip
} from '@material-ui/core/';
import useStores from 'Store/useStores';
import { DISPLAY_DATE_FORMAT } from 'Shared/constants/datetime';
import { formatDate, formatTime } from 'Src/utils/datetime';
import historyTabHeaderConfig from 'Business/tpsRepairs/components/ConsumptionsControl/MaterialValuesHistoryTab/historyTabHeaderConfig';
import Loader from 'Common/components/Progress/components/Circular/ProgressBar';

import useStyles from './styles';

const MaterialValuesHistoryTab = ({ taskId }) => {
  const {
    materialValuesControlStore: {
      history,
      getHistory,
      isHistoryLoading,
      isHistoryLoaded,
      resetHistoryStorePart,
    },
    notificationStore: { enqueueSnackbar },
  } = useStores();
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    getHistory(taskId).catch(() => {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('REQUEST_LOAD_LIST_ERROR')
            }]
          }],
        },
        variant: 'error',
      });
    });
  }, []);

  useEffect(() => () => resetHistoryStorePart(), []);

  return (
    <div className={classes.root}>
      {isHistoryLoading && <Loader />}
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <TableHead>
            <TableRow>
              {historyTabHeaderConfig(t).map(({
                id,
                numeric,
                disablePadding,
                label,
                ...rest
              }) => (
                <TableCell
                  key={id}
                  align={numeric ? 'right' : 'left'}
                  padding={disablePadding ? 'none' : 'default'}
                  {...rest}
                >
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {history.map((row, historyItemIndex) => {
              const {
                dateTime,
                operationTitle = '',
                materialValueTitle = '',
                previousQuantity = 0,
                quantity = 0,
                userName,
                userPosition,
                materialValueSapCode,
                materialValueUnitTitle,
                operationId,
                materialValueConsumptionId,
              } = row;

              const historyItemKey = `${dateTime}_${historyItemIndex}`;

              return (
                <TableRow
                  className={classes.row}
                  hover
                  tabIndex={-1}
                  key={historyItemKey}
                  data-test={`operationId_${operationId}_materialValueConsumptionId_${materialValueConsumptionId}`}
                >
                  <TableCell data-test="dateTime_cell">
                    <Typography data-test="date">
                      {formatDate(dateTime, DISPLAY_DATE_FORMAT)}
                    </Typography>
                    <Typography className={classes.grayColored} data-test="time">
                      {formatTime(dateTime)}
                    </Typography>
                  </TableCell>
                  <TableCell data-test="operationTitle">
                    {operationTitle}
                  </TableCell>
                  <TableCell className={classes.materialValue} data-test="materialValue_cell">
                    <Tooltip title={t('MATERIAL_VALUE_SHORT')}>
                      <div>
                        <Typography data-test="materialValueTitle">
                          {materialValueTitle}
                        </Typography>
                        {materialValueSapCode && (
                          <Typography className={classes.grayColored} data-test="materialValueSapCode">
                            {materialValueSapCode}
                          </Typography>
                        )}
                      </div>
                    </Tooltip>
                  </TableCell>
                  <TableCell data-test="initial_fact_quantity_cell">
                    <div className={classes.grayColored}>
                      <Tooltip title={t('INITIAL_FACT_QUANTITY')}>
                        <div data-test="initial_fact_quantity">{materialValueUnitTitle ? `${previousQuantity} ${materialValueUnitTitle}` : previousQuantity}</div>
                      </Tooltip>
                    </div>
                  </TableCell>
                  <TableCell data-test="updated_fact_quantity_cell">
                    <Tooltip title={t('QUANTITY_UPDATED_BY_MASTER')}>
                      <div data-test="updated_fact_quantity">{materialValueUnitTitle ? `${quantity} ${materialValueUnitTitle}` : quantity}</div>
                    </Tooltip>
                  </TableCell>
                  <TableCell data-test="user_cell">
                    <Typography data-test="userName">
                      {userName}
                    </Typography>
                    {userPosition && (
                      <Typography className={classes.grayColored} data-test="userPosition">
                        {userPosition}
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {isHistoryLoaded && isEmpty(history) && (
        <div className={classes.emptyHistoryLabel}>{t('EMPTY_OPERATION_CONFIRMATION_HISTORY')}</div>
      )}
    </div>
  );
};

MaterialValuesHistoryTab.propTypes = {
  taskId: PropTypes.number.isRequired
};

export default observer(MaterialValuesHistoryTab);
