import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  sortableListHeader: {
    height: 40,
    display: 'flex',
    paddingLeft: 34,
    fontSize: 12,
    color: theme.palette.black30,
    alignItems: 'flex-start',
  },
  list: {
    position: 'relative',
  },
  isVisibleSwitcher: {
    width: 134,
  },
  nameLabel: {
    flex: 1,
  },
  codeLabel: {
    flex: 1,
    paddingLeft: 40,
  },
  isPhotoRequiredSwitcher: {
    width: 204,
  },
}));

export default useStyles;
