import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  snackbarRoot: {
    top: '72px !important',
    right: '0px !important',
  },
});

export default useStyles;
