import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  addCheckpointButton: {
    opacity: ({ disabled }) => (disabled ? 0.5 : 1),
    boxShadow: 'none',
    minHeight: 80,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    fontSize: 16,
    fontWeight: 'normal',
    '&.MuiButtonBase-root': {
      borderStyle: 'dashed',
      borderColor: ({ error }) => (error ? theme.palette.negative : 'inherit'),
      color: ({ error, disabled }) => {
        const color = disabled
          ? theme.palette.black30
          : theme.palette.primaryLight;
        return error ? theme.palette.negative : color;
      },
    },
    '&::after': {
      display: ({ required }) => (required ? 'block' : 'none'),
      content: ({ t }) => (`'${t('REQUIRED')}'`),
      position: 'absolute',
      height: '50%',
      top: 'calc(100% + 2px)',
      left: 16,
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.33,
      letterSpacing: 'normal',
      color: ({ error }) => (error ? theme.palette.negative : theme.palette.black30),
    },
  },
}));

export default useStyles;
