import Agent from 'ApiAgents/Agent';
import getPaginationQueryObject from 'Src/utils/getPaginationQueryObject';
import qs from 'qs';


export default class InspectionTaskAgent extends Agent {
  fetchInspectionTasks = (
    params = {},
    shouldPaginate,
    pageNumber,
    pageSize,
  ) => {
    const pageQuery = getPaginationQueryObject(shouldPaginate, pageNumber, pageSize);
    return (
      this.request({
        url: '/automatedTasks/view',
        method: 'GET',
        params: { ...params, ...pageQuery }
      },
      'tasks'));
  };

  fetchInspectionTaskById = id => (
    this.request({
      url: '/automatedTasks/view',
      method: 'GET',
      params: {
        id
      }
    },
    'tasks'));

  fetchCreatedInspectionTaskById = id => (
    this.request({
      url: '/task/inspection/details',
      method: 'GET',
      params: {
        id
      }
    },
    'tasks'));

  fetchCreatedTasks = (
    params = {},
    shouldPaginate,
    pageNumber,
    pageSize,
  ) => {
    const pageQuery = getPaginationQueryObject(shouldPaginate, pageNumber, pageSize);
    return (
      this.request({
        url: '/task/inspection/view',
        method: 'GET',
        params: { ...params, ...pageQuery },
        paramsSerializer: parameters => qs.stringify(parameters)
      },
      'tasks'));
  };

  saveInspectionTask = (body = {}) => (
    this.request({
      url: '/automatedTasks/manage/create',
      method: 'POST',
      data: body,
    },
    'tasks')
  );

  deleteTask = automatedTaskId => (
    this.request({
      url: '/automatedTasks/manage/disable',
      method: 'POST',
      data: {
        Id: automatedTaskId
      },
    },
    'tasks'));
}
