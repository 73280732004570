import Agent from 'ApiAgents/Agent';


export default class DictionaryAgent extends Agent {
  loadDictionaryByKey = key => this.request({
    url: '/dictionary/getByKey',
    method: 'GET',
    params: { key },
  }, 'tasks');

  loadScanFailReasonDictionary = () => this.request({
    url: '/dictionary/getByKey',
    method: 'GET',
    params: { key: 'CoalQrScanFailReason' }
  }, 'tasks');
}
