import React from 'react';
import { observer } from 'mobx-react';
import useStores from 'Store/useStores';
import { withTheme } from '@material-ui/core/styles';
import { get, isEmpty } from 'lodash';
import OptionRenderer from 'Common/components/Forms/OptionRenderer';
import { useTranslation } from 'react-i18next';
import { VIEW_TASK } from 'Shared/constants/routes';
import StyledLink from 'Common/components/StyledLink';

import useStyles from './styles';

const ControlResultsBlock = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    coalTaskStore: {
      taskData: {
        child, controller: { identityName } = {}, taskCompletionReason, completionReasonComment
      } = {},
      taskCompletionReasonsDictionary,
    },
  } = useStores();

  const childId = get(child, 'id');
  const childTitle = get(child, 'title');
  const taskCompletionReasonObj = taskCompletionReasonsDictionary.find(item => item.enumValue === taskCompletionReason);
  const taskCompletionReasonName = get(taskCompletionReasonObj, 'value', '').replace(/ *\([^)]*\) */g, '');
  return (
    <div>
      <div className={classes.blockHeader}>
        <div className={classes.blockHeaderLabel}>{t('CONTROL_RESULT')}</div>
      </div>
      <div className={classes.twoColumns}>
        <div className={classes.column}>
          <OptionRenderer
            value={childId ? t('RESCHEDULED') : t('CONFIRMED')}
            title={t('CONTROL_RESULT')}
            name="childTaskControlResult"
            wrapperClass={classes.optionRenderer}
          />
          {!isEmpty(taskCompletionReasonName) && (
            <OptionRenderer
              value={taskCompletionReasonName}
              title={t('EXECUTION_APPROVAL_STATUS')}
              name="taskCompletionReason"
              wrapperClass={classes.optionRenderer}
            />
          )}
          {Boolean(completionReasonComment) && (
            <OptionRenderer
              value={completionReasonComment}
              title={t('COMMENT')}
              name="completionReasonComment"
              wrapperClass={classes.optionRenderer}
            />
          )}
        </div>
        <div className={classes.column}>
          <OptionRenderer
            value={identityName}
            title={t('CONTROLLER')}
            name="childTaskControler"
            wrapperClass={classes.optionRenderer}
          />
          {childId && childTitle && (
            <OptionRenderer
              value={childTitle}
              valueRenderer={value => <StyledLink to={`${VIEW_TASK}/${childId}`}>{value}</StyledLink>}
              title={t('CREATED_TASK')}
              name="childTaskLink"
              wrapperClass={classes.optionRenderer}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default withTheme(observer(ControlResultsBlock));
