import { useTheme } from '@material-ui/core/styles';

const useTableBodyHeight = ({ withPageTabs, withTableFooter = true } = {}) => {
  const theme = useTheme();
  const {
    headerHeight = 0,
    pageHeaderHeight = 0,
    tableFooterHeight = 0,
    mainContentPaddingTop = 0,
    mainContentPaddingBottom = 0,
    pageTabsHeight = 0
  } = theme;

  const reservedPageHeight = headerHeight
    + pageHeaderHeight
    + (withPageTabs ? pageTabsHeight : 0)
    + (withTableFooter ? tableFooterHeight : 0)
    + mainContentPaddingTop
    + mainContentPaddingBottom;

  return `calc(100vh - ${reservedPageHeight}px)`;
};

export default useTableBodyHeight;
