import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  hovered: {
    '&:hover': {
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.16)',
    },
  },
  changeableIndicator: {
    zIndex: 1,
    '&::after': {
      background: theme.palette.primaryLight,
    },
  },
  notChangeableIndicator: {
    '&::after': {
      background: theme.palette.gray,
    },
  },
  withPaddingLeft: {
    paddingLeft: theme.spacing(3),
  },
  boxWrapper: {
    margin: '16px 0px',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: 9,
      height: 9,
      top: '50%',
      left: 4,
      borderRadius: '50%',
      transform: 'translate(0, -50%)',
    },
    '&:first-child:before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: 1,
      height: '50%',
      background: theme.palette.white,
      top: 0,
      left: theme.spacing(1),
    },
    '&:last-child:before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: 1,
      height: '50%',
      background: theme.palette.white,
      top: '50%',
      left: theme.spacing(1),
    },
    '&:first-child:last-child:after': {
      display: 'none'
    },
    '&:first-child:last-child:before': {
      top: 0,
      height: '100%'
    },
  },
  boxInner: {
    width: '100%',
    padding: theme.spacing(2),
    display: 'block',
    textAlign: 'left',
    transition: 'all .2s',
    '&:hover': {
      '& $headerButtons': {
        display: 'block',
      },
    },
  },
  boxInnerChangeable: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(6),
  },
  box: {
    position: 'relative',
    padding: 0,
    background: theme.palette.white,
    boxShadow: '0 1px 3px 0 #f3f3f3',
    border: 'solid 1px #f3f3f3',
    fontSize: 16,
    userSelect: 'none',
    transition: 'all .4s'
  },
  boxDragHandler: {
    display: 'flex',
    position: 'absolute',
    cursor: 'row-resize',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: `inset -1px 0 0 ${theme.palette.gray}`,
    left: 0,
    top: 0,
    height: '100%',
    width: 34,
    background: theme.palette.lightGray,
    color: theme.palette.black30,
    zIndex: 2
  },
  boxTitle: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  boxSubTitle: {
    fontSize: 16,
    color: theme.palette.black30
  },
  expand: {
    // alignSelf: 'center',
    width: 48,
    height: 48,
    padding: 5,
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    color: theme.palette.black70,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  tooltip: {
    backgroundColor: theme.palette.black70,
  },
  innerBox: {
    backgroundColor: theme.palette.midGray,
    paddingLeft: ({ isReadonlyByMode }) => (isReadonlyByMode ? 0 : 32),
    fontSize: 16
  },
  technicalObjectWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 16,
  },
  technicalObjectTitle: {
    fontWeight: 'bold'
  },
  technicalObjectHeader: {
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'calc(50% - 4px)',
    backgroundColor: theme.palette.white,
    paddingLeft: 16,
    alignSelf: 'baseline',
    '&:hover': {
      '& $headerButtons': {
        display: 'block',
      },
    },
  },
  checkpointHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.white,
    // padding: '16px 0 16px 16px',
    alignItems: 'center',
    '&:hover': {
      '& $headerButtons': {
        display: 'block',
      },
    },
  },
  operationTypeHeader: {
    height: 24,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: theme.palette.white,
    // marginBottom: 16,
    alignSelf: 'baseline',
  },
  headerButtonsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  headerButtons: {
    display: 'none',
    minWidth: 72,
    overflow: 'visible',
  },
  technicalObjectInfo: {
    width: 'calc(50% - 4px)',
  },
  operationType: {
    width: '100%',
    backgroundColor: theme.palette.white,
    padding: 16,
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0
    },
    '&:hover': {
      '& $headerButtons': {
        display: 'block',
      },
    },
  },
  technicalObjectInfoTitle: {
    fontWeight: 'bold'
  },
  technicalObjectInfoText: {
    borderBottom: `1px solid ${theme.palette.midGray}`,
    padding: '8px 0',
    '&:last-child': {
      borderBottom: '0 none',
    },
  },
  deleteButton: {
    color: theme.palette.negative,
  },
  editButton: {
    color: theme.palette.primaryLight,
    marginLeft: 8,
  },
}));

export default useStyles;
