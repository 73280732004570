import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  selectedListItem: {
    listStyleType: 'none',
    width: '100%',
    paddingRight: 90
  },
  listItemTextOperation: {
    width: '70%',
    marginRight: 15
  },
  itemWrapper: {
    cursor: ({ disableClick }) => (disableClick ? 'default' : 'pointer'),
    marginBottom: theme.spacing(1),
    position: 'relative',
    '&::before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: 9,
      height: 9,
      background: theme.palette.primaryLight,
      borderRadius: '50%',
      top: '50%',
      left: 0,
      margin: '-4px 0',
      zIndex: 2,
    },
    '&:first-child:after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: 2,
      height: '50%',
      background: '#fff',
      top: 0,
      left: 4,
      zIndex: 1
    },
    '&:last-child:after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: 2,
      height: '50%',
      background: '#fff',
      top: '50%',
      left: 4,
      zIndex: 1
    }
  },
  historyItemCard: {
    border: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.midGray,
    boxShadow: '0 1px 3px 0 #f3f3f3'
  },
  primaryTypography: {
    fontWeight: 'bold'
  }
}));

const HistoryItem = ({
  title, subtitle, onItemClick, disableClick
}) => {
  const classes = useStyles({ disableClick });

  return (
    <Grid
      container
      wrap="nowrap"
      justify="center"
      className={classes.itemWrapper}
    >
      <Grid item xs={10}>
        <div className={classes.historyItemCard}>
          <ListItem
            classes={{ container: classes.selectedListItem }}
            onClick={(value) => {
              if (disableClick) return;
              onItemClick(value);
            }}
          >
            <ListItemText
              className={classes.listItemTextOperation}
              classes={{ primary: classes.primaryTypography }}
              primary={title}
              secondary={subtitle}
            />
          </ListItem>
        </div>
      </Grid>
    </Grid>
  );
};

HistoryItem.defaultProps = {
  disableClick: false,
};

HistoryItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  onItemClick: PropTypes.func.isRequired,
  disableClick: PropTypes.bool,
};

HistoryItem.defaultProps = {
  subtitle: '',
};

export default HistoryItem;
