import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import CoalTasksDetails from 'Business/coal/pages/Task';
import TaskListPage from 'Src/business/coal/pages/TasksList';
import DefectPage from 'Src/business/coal/pages/Defect';
import DefectsListPage from 'Src/business/coal/pages/DefectsList';
import * as routes from 'Shared/constants/routes';
import PrivateRoute from 'Common/components/Authorization/PrivateRoute';
import TaskRoutesList from 'Business/coal/pages/TaskRoutesList';
import TaskRoutesDetails from 'Business/coal/pages/TaskRouteDetails';
import InspectionsList from 'Business/coal/pages/InspectionsList';
import Inspection from 'Business/coal/pages/Inspection';

import {
  TASKS_VIEW,
  TASK_CREATE,
  TASK_VIEW,
  TASK_EDIT,
  TASK_CONTROL,
  DEFECT_VIEW,
  DEFECTS_VIEW,
  TASK_ROUTE_CREATE,
  TASK_ROUTE_EDIT,
  TASK_ROUTE_VIEW,
  TASK_ROUTES_VIEW,
  INSPECTION_CREATE,
  // INSPECTION_EDIT,
  INSPECTION_VIEW,
  INSPECTIONS_VIEW,
} from 'Src/RBAC/businessPermissions';
import CommonRoutes from 'Src/routes/CommonRoutes';

const CoalRoutes = () => (
  <CommonRoutes>
    <Route exact path={`${routes.HOME}`}>
      <Redirect to="/tasks" />
    </Route>
    <PrivateRoute
      exact
      path={routes.CREATE_TASK}
      component={props => <CoalTasksDetails {...props} mode="create" />}
      permission={TASK_CREATE}
    />

    <PrivateRoute
      exact
      path={`${routes.VIEW_TASK}/:id`}
      component={props => <CoalTasksDetails {...props} mode="view" />}
      permission={TASK_VIEW}
    />

    <PrivateRoute
      exact
      path={`${routes.EDIT_TASK}/:id`}
      component={props => <CoalTasksDetails {...props} mode="edit" />}
      permission={TASK_EDIT}
    />

    <PrivateRoute
      exact
      path={`${routes.CONTROL_TASK}/:id`}
      component={props => <CoalTasksDetails {...props} mode="control" />}
      permission={TASK_CONTROL}
    />

    <PrivateRoute
      path={routes.TASKS}
      component={props => <TaskListPage {...props} />}
      permission={TASKS_VIEW}
    />

    <PrivateRoute
      exact
      path={`${routes.VIEW_DEFECT}/:id`}
      component={props => <DefectPage {...props} />}
      permission={DEFECT_VIEW}
    />

    <PrivateRoute
      path={`${routes.DEFECTS}`}
      component={props => <DefectsListPage {...props} />}
      permission={DEFECTS_VIEW}
    />

    {/* TaskRoutes Маршруты */}
    <PrivateRoute
      exact
      path={routes.CREATE_TASK_ROUTE}
      component={TaskRoutesDetails}
      permission={TASK_ROUTE_CREATE}
    />
    <PrivateRoute
      exact
      path={routes.EDIT_TASK_ROUTE}
      component={TaskRoutesDetails}
      permission={TASK_ROUTE_EDIT}
    />
    <PrivateRoute
      exact
      path={routes.VIEW_TASK_ROUTE}
      component={TaskRoutesDetails}
      permission={TASK_ROUTE_VIEW}
    />
    <PrivateRoute
      exact
      path={routes.TASK_ROUTES}
      component={TaskRoutesList}
      permission={TASK_ROUTES_VIEW}
    />

    {/* TaskRoutes Обходы */}
    <PrivateRoute
      exact
      path={routes.CREATE_INSPECTION}
      component={props => <Inspection {...props} mode="create" />}
      permission={INSPECTION_CREATE}
    />
    {/* <PrivateRoute
      exact
      path={routes.EDIT_INSPECTION}
      component={TaskRoutesDetails}
      permission={INSPECTION_EDIT}
    /> */}
    <PrivateRoute
      exact
      path={`${routes.VIEW_INSPECTION}`}
      component={props => <Inspection {...props} mode="view" />}
      permission={INSPECTION_VIEW}
    />
    <PrivateRoute
      path={routes.INSPECTIONS}
      component={props => <InspectionsList {...props} />}
      permission={INSPECTIONS_VIEW}
    />
  </CommonRoutes>
);

export default CoalRoutes;
