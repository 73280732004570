import {
  action,
  runInAction,
  makeObservable,
  set,
  computed,
} from 'mobx';
import { isEmpty, find } from 'lodash';
import TaskRouteAgent from 'ApiAgents/TaskRoutes/TaskRouteAgent';
import DictionaryAgent from 'ApiAgents/Dictionary/DictionaryAgent';
import EquipmentAgent from 'ApiAgents/Equipment/EquipmentAgent';
import InspectionTaskAgent from 'ApiAgents/InspectionTasks/InspectionTaskAgent';
import { TPS_INSPECTION_ROLES } from 'Shared/constants/dictionaries';

const initialSelectedState = {
  assigneeRoles: [],
  techPlaceIds: [],
  routeId: '',
  expectedStartDate: '',
  expectedFinishDate: '',
  automatedTaskId: ''
};

const initialState = {
  isInitialFiltersLoading: false,
  isRoutesLoading: false,
  isAutotasksLoading: false,
  isLoadedInitialFilters: false,
  initialFiltersLoadError: '',
  dependentFiltersLoadErrors: {
    routesLoadError: '',
    autotasksLoadError: ''
  },
  routes: {},
  currentRoutes: [],
  powerUnits: [],
  autoTasks: {},
  currentAutoTasks: [],
  roles: [],
  selectedState: { ...initialSelectedState },
};

const taskRouteAgent = new TaskRouteAgent();
const equipmentAgent = new EquipmentAgent();
const dictionaryAgent = new DictionaryAgent();
const inspectionTaskAgent = new InspectionTaskAgent();

export class TaskListFilterStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
    this.rootStore.prepareState(initialState, this);
  }

  isInitialFiltersLoading;

  isRoutesLoading;

  isAutotasksLoading;

  isLoadedInitialFilters;

  initialFiltersLoadError;

  dependentFiltersLoadErrors;

  routes;

  currentRoutes;

  powerUnits;

  autoTasks;

  currentAutoTasks;

  roles;

  selectedState;

  @computed get filterState() {
    const {
      assigneeRoles,
      techPlaceIds,
      routeId,
      expectedStartDate,
      expectedFinishDate,
      automatedTaskId,
    } = this.selectedState;

    return {
      ...assigneeRoles.length && { assigneeRoles },
      ...techPlaceIds.length && { techPlaceIds },
      ...routeId && { routeId },
      ...expectedStartDate && { expectedStartDate },
      ...expectedFinishDate && { expectedFinishDate },
      ...automatedTaskId && { automatedTaskId },
    };
  }

  @computed get isFiltersSet() {
    return !isEmpty(this.filterState);
  }

  @action discardSelectedFilters = () => {
    this.selectedState = { ...initialSelectedState };
  };

  @action loadInitialFilters = async () => {
    try {
      this.isInitialFiltersLoading = true;
      const [tpsWithPowerUnits, roles] = await Promise.all([
        equipmentAgent.getTpsWithPowerUnitsWithCheckPoints(this.rootStore.rootOrgUnitCode),
        dictionaryAgent.loadDictionaryByKey(TPS_INSPECTION_ROLES)
      ]);

      runInAction(() => {
        this.isLoadedInitialFilters = true;
        this.powerUnits = this.rootStore.taskRouteManageStore.preparePowerUnits(tpsWithPowerUnits.children);
        this.roles = roles;
      });
    } catch (error) {
      runInAction(() => {
        this.initialFiltersLoadError = 'FAIL_TO_LOAD_FILTERS';
      });
    }

    runInAction(() => {
      this.isInitialFiltersLoading = false;
    });
  };

  getCurrentRoutes(powerUnitIds) {
    let currentRoutes = [];

    powerUnitIds.forEach((powerUnitId) => {
      const powerUnitsRoutes = this.routes[powerUnitId] || [];
      powerUnitsRoutes.forEach((route = {}) => {
        const { id: routeId } = route;
        if (!routeId) return;
        const isInCurrentRoutes = find(currentRoutes, { id: routeId });
        if (!isInCurrentRoutes) currentRoutes = [...currentRoutes, route];
      });
    });

    return currentRoutes;
  }

  @action handleRolesChange = async (roles) => {
    this.selectedState.assigneeRoles = roles.map(({ value }) => value);
  };

  @action handlePowerUnitChange = async (powerUnitIdsObj) => {
    const powerUnitIds = powerUnitIdsObj.map(({ id }) => id);
    this.selectedState.techPlaceIds = powerUnitIds;
    this.selectedState.routeId = '';
    this.selectedState.automatedTaskId = '';
    this.currentRoutes = [];
    this.currentAutoTasks = [];
    const notUsedPowerUnitIds = powerUnitIds.filter(id => !this.routes[id]);

    if (!notUsedPowerUnitIds.length) {
      this.currentRoutes = this.getCurrentRoutes(powerUnitIds);
      return;
    }

    const params = { powerUnitIds: this.selectedState.techPlaceIds };

    try {
      this.isRoutesLoading = true;
      const routes = await taskRouteAgent.getItemsBy(params);
      const groupedRoutes = {};
      routes.forEach((route = {}) => {
        const { checkpoints = [], id } = route;
        checkpoints.forEach(({ powerUnit: { id: powerUnitId } = {} } = {}) => {
          if (!groupedRoutes[powerUnitId]) {
            groupedRoutes[powerUnitId] = [route];
          } else {
            const isInGroupedRoutes = find(groupedRoutes[powerUnitId], { id });
            if (!isInGroupedRoutes) groupedRoutes[powerUnitId] = [...groupedRoutes[powerUnitId], route];
          }
        });
      });

      runInAction(() => {
        set(this.routes, { ...this.routes, ...groupedRoutes });
        this.currentRoutes = this.getCurrentRoutes(powerUnitIds);
      });
    } catch (error) {
      runInAction(() => {
        this.dependentFiltersLoadErrors.routesLoadError = 'FAIL_TO_LOAD_ROUTES';
      });
    }

    runInAction(() => {
      this.isRoutesLoading = false;
    });
  };

  @action handleRouteChange = async (routeId) => {
    this.selectedState.routeId = routeId;

    if (this.autoTasks[routeId]) {
      this.currentAutoTasks = this.autoTasks[routeId];
      return;
    }

    const params = { routeId };

    try {
      this.isAutotasksLoading = true;
      const autoTasks = await inspectionTaskAgent.fetchInspectionTasks(params);

      runInAction(() => {
        set(this.autoTasks, { [routeId]: autoTasks });
        this.currentAutoTasks = this.autoTasks[routeId];
      });
    } catch (error) {
      runInAction(() => {
        this.dependentFiltersLoadErrors.autotasksLoadError = 'FAIL_TO_LOAD_AUTOTASKS';
      });
    }

    runInAction(() => {
      this.isAutotasksLoading = false;
    });
  };

  @action setState = (value, name) => {
    this.selectedState[name] = value;
  };

  @action discardState = () => {
    this.rootStore.setInitialState(initialState, this);
  }
}

export default TaskListFilterStore;
