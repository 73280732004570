import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { EventOutlined } from '@material-ui/icons';
import withModalOpenHandling from 'HOC/withModalOpenHandling';
import { withRouter } from 'react-router-dom';
import { formatDate, isValid, isDateOverdue } from 'Src/utils/datetime';
import useStores from 'Store/useStores';
import { IconButton, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Modal from 'Common/components/Modal/Modal';
import ActionBtn from 'Common/components/Forms/Button/components/Action/Action';
import DateInput from 'Common/components/Forms/Input/Date';
import TextInput from 'Common/components/Forms/Input/Text';
import ColumnEdit from 'Common/components/Forms/Button/ColumnEdit/ColumnEdit';
import useCurrentListStatus from 'Hooks/useCurrentListStatus';
import { taskStatusAliases } from 'Src/business/tpsRepairs/config/taskStatusAliases';

import useStyles from './styles';


const SelectionDate = observer(({
  taskTitle,
  handleClose,
  taskId,
  plannedStartDate,
  expectedStartDate,
  expectedFinishDate,
  isOpened,
  handleClickOpen,
  currentAssignee,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    tasksStore: {
      updatePlannedDate
    },
    userStore: { auth: { id: authId } }
  } = useStores();
  const [plannedDate, plannedDateSet] = useState(isValid(plannedStartDate) ? plannedStartDate : '');
  const currentTaskListStatus = useCurrentListStatus('tasks');
  const translatedTaskStatusAliases = taskStatusAliases(t);
  const { isNew, identityId } = currentAssignee;
  const isDisabledByCurrentTaskListStatus = () => [
    translatedTaskStatusAliases.ALL.name,
  ].includes(currentTaskListStatus && currentTaskListStatus.toLowerCase());

  const isDisabledColumnEditing = () => (
    !(isNew || identityId === authId)
    || isDisabledByCurrentTaskListStatus()
  );

  const handleClick = () => {
    updatePlannedDate(taskId, plannedDate);
    handleClose();
  };

  const changeHandler = (date) => {
    plannedDateSet(date);
  };

  const renderActions = () => (
    <Grid container spacing={3} justify="flex-end">
      <Grid item>
        <ActionBtn text={t('CANCEL')} type="cancel" handleClick={handleClose} />
      </Grid>
      <Grid item>
        <ActionBtn
          text={t('SAVE')}
          type="confirm"
          disabled={isDateOverdue(plannedDate)}
          handleClick={handleClick}
        />
      </Grid>
    </Grid>
  );

  return (
    <div className={classes.inputDatePick}>
      {isValid(plannedStartDate) ? (
        <ColumnEdit
          handleClick={handleClickOpen}
          title={t('SELECT_DATE')}
          disabled={isDisabledColumnEditing()}
        >
          {formatDate(plannedStartDate)}
        </ColumnEdit>
      ) : (
        <Tooltip title={t('SELECT_DATE')}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleClickOpen(e);
            }}
            disabled={isDisabledByCurrentTaskListStatus()}
            style={{
              marginLeft: -20
            }}
          >
            <EventOutlined />
          </IconButton>
        </Tooltip>
      ) }

      <Modal
        onClick={e => e.stopPropagation()}
        handleClose={handleClose}
        title={t('SPECIFY_A_TASK_DUE_DATE')}
        isOpened={isOpened}
        actions={renderActions()}
      >
        <TextInput
          label={t('TASK_TITLE')}
          value={taskTitle}
          name="taskTitle"
          isReadonly
          data-test="taskTitle"
        />

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextInput
              label={t('PLANNED_START_DATE')}
              value={formatDate(expectedStartDate)}
              name="expectedStartDate"
              isReadonly
              data-test="expectedStartDate"
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              label={t('PLANNED_COMPLETION_DATE')}
              value={formatDate(expectedFinishDate)}
              name="expectedFinishDate"
              isReadonly
              data-test="expectedFinishDate"
            />
          </Grid>
        </Grid>

        <DateInput
          label={t('DUE_DATE')}
          value={plannedDate}
          name="plannedDate"
          onChange={value => changeHandler(value)}
          disablePast
          data-test="plannedDate"
        />
      </Modal>
    </div>
  );
});

SelectionDate.propTypes = {
  taskId: PropTypes.number.isRequired,
  taskTitle: PropTypes.string.isRequired,
  plannedStartDate: PropTypes.string,
  expectedStartDate: PropTypes.string.isRequired,
  expectedFinishDate: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleClickOpen: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
  currentAssignee: PropTypes.shape({
    isNew: PropTypes.bool,
    identityId: PropTypes.string,
  }),
};

SelectionDate.defaultProps = {
  plannedStartDate: '',
};

export default withRouter(withModalOpenHandling(SelectionDate));
