import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { MASTER } from 'Shared/constants/roles';
import useStores from 'Store/useStores';
import { sortObjectByEntryIndex } from 'Src/utils/sort';
import { searchInArrayOfObjects } from 'Src/utils/search';

const useStyles = makeStyles(theme => ({
  menuItem: {
    width: '100%',
    fontSize: 16
  },
  position: {
    color: theme.palette.black30,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  selectPaper: {
    border: '2px solid #4499ee',
    borderTopColor: 'white',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginTop: -1,
    maxHeight: 340,
    overflowY: 'auto'
  },
  formControl: {
    '& .MuiInputBase-input': {
      fontSize: 16
    },
    '& .MuiFormLabel-filled ~ .MuiInputBase-root .MuiAutocomplete-clearIndicator': {
      visibility: 'visible'
    }
  },
  formControlActive: {
    '& .MuiInputBase-root': {
      height: 64,
    },
    '& .MuiInputBase-input': {
      alignSelf: 'flex-start',
    }
  },
  subTitle: {
    fontSize: 16,
    position: 'absolute',
    left: 13,
    bottom: 11,
    width: 'calc(100% - 25px)',
    whiteSpace: 'nowrap'
  },
  autocompletePopper: { // TODO SELECT!!!
    '&[x-placement="bottom"]': {
      '& .MuiAutocomplete-paper': {
        border: '2px solid #4499ee',
        borderTop: 0,
        marginTop: -3,
        marginLeft: 1,
        width: '100%',
        borderRadius: '0 0 4px 4px',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14)'
      },
    },
    '&[x-placement="top"]': {
      '& .MuiAutocomplete-paper': {
        border: '2px solid #4499ee',
        borderBottom: 0,
        marginBottom: -4,
        marginLeft: 1,
        width: '100%',
        borderRadius: '4px 4px 0 0',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14)'
      },
    }
  }
}));

const SelectAssigneeControl = ({
  assigneeId,
  setAssignee,
  ...restProps
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { userStore: { orgUnitUsers, role }, brigadeStore: { brigadesList } } = useStores();
  const isBrigade = role === MASTER;
  const label = isBrigade ? t('BRIGADE') : t('EMPLOYEE');
  const potentialAssignees = isBrigade ? brigadesList : orgUnitUsers;
  const entityName = isBrigade ? 'name' : 'fullName';
  const SEARCH_FIELDS = [entityName];
  const [options, setOptions] = useState(potentialAssignees);
  const [searchString, setSearchString] = useState('');

  const currentValue = potentialAssignees.reduce((acc, item) => (item.id === assigneeId ? item : acc), null);

  const findAssignee = (assignees, selectedId) => assignees.find(({ id }) => id === selectedId) || {};

  const handleSelectChange = (e) => {
    const search = e.target.value.trim().toLowerCase();
    setSearchString(search);

    if (search.length < 2) {
      setOptions(potentialAssignees);
      return;
    }

    const searchedAssignees = searchInArrayOfObjects(potentialAssignees, SEARCH_FIELDS, search);
    setOptions(sortObjectByEntryIndex(searchedAssignees, search, entityName));
  };

  const handleSelect = (current) => {
    setSearchString('');
    if (current) {
      const assignee = findAssignee(potentialAssignees, current.id);
      setAssignee(assignee.id, assignee[entityName]);
    } else {
      setAssignee('', '');
    }
  };

  const renderMenuTitle = assignee => (
    <div>{isBrigade ? assignee.name : assignee.fullName}</div>
  );

  const renderMenuSubTitle = assignee => (
    <div className={classes.position}>
      {isBrigade ? assignee.brigadier.fullName : assignee.positionName}
    </div>
  );

  const getFilterOptions = () => (searchString.length > 1 ? options : potentialAssignees);

  return (
    <Autocomplete
      clearText=""
      closeText=""
      openText=""
      value={currentValue}
      classes={{
        popper: classes.autocompletePopper
      }}
      onChange={(e, value) => handleSelect(value)}
      getOptionLabel={option => (isBrigade ? option.name : option.fullName)}
      options={options}
      renderOption={option => (
        <div className={classes.menuItem} data-test="SelectAssigneeControl-item">
          {renderMenuTitle(option)}
          {renderMenuSubTitle(option)}
        </div>
      )}
      filterOptions={() => (getFilterOptions())}
      renderInput={params => (
        <div style={{ position: 'relative' }}>
          <TextField
            onChange={e => handleSelectChange(e)}
            className={`
              ${classes.formControl}
              ${assigneeId ? classes.formControlActive : ''}
            `}
            margin="dense"
            {...params}
            inputProps={{
              ...params.inputProps,
              'data-test': 'SelectAssigneeControl-input'
            }}
            variant="outlined"
            label={label}
            fullWidth
          />
          <div className={classes.subTitle}>
            {currentValue && renderMenuSubTitle(currentValue)}
          </div>
        </div>
      )}
      {...restProps}
    />
  );
};

SelectAssigneeControl.propTypes = {
  assigneeId: PropTypes.string.isRequired,
  setAssignee: PropTypes.func.isRequired,
};

export default SelectAssigneeControl;
