import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';

const EquipmentOption = (props) => {
  const { level, label, sapCode } = props;
  const classes = useStyles({ level });
  return (
    <div
      className={classes.equipmentSapCodeWrapper}
    >
      <div className={classes.equipmentSapCodeValueLabel}>{label}</div>
      <div className={classes.equipmentSapCodeValueSubLabel}>{sapCode}</div>
    </div>
  );
};

EquipmentOption.propTypes = {
  level: PropTypes.number,
  label: PropTypes.string,
  sapCode: PropTypes.string,
};

EquipmentOption.defaultProps = {
  level: 1,
  label: '',
  sapCode: '',
};

export default EquipmentOption;
