import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Box } from '@material-ui/core';
import { isEmpty, get, isEqual } from 'lodash';
import useStores from 'Store/useStores';
import SelectInput from 'Common/components/Forms/Input/Select';
import TextInput from 'Common/components/Forms/Input/Text';
import FormSwitch from 'Common/components/Forms/Input/FormSwitch';
import OptionRenderer from 'Common/components/Forms/OptionRenderer';
import { isLatinian } from 'Src/utils/symbolCheck';
import { hasPermission } from 'Common/components/Authorization/Can';
import { ADMIN_USER_ENTERPRISE_EDIT } from 'Src/RBAC/businessPermissions';
import accessibleRolesConfig from 'Common/config/adminAccessibleRolesConfig';
import { ADMINISTRATOR_OK_RU } from 'Src/shared/constants/roles';

import useStyles from './styles';

const UserGeneralBlock = (props) => {
  const { t } = useTranslation();
  const classes = useStyles({ t });
  const { mode } = props;
  const {
    adminUserStore: {
      userData = {},
      rolesDictionary,
      rootOrgUnits,
      onChangeRole,
      onChangeUserStatus,
      onChangePositionName,
      onChangeSapWorkPlace,
      onChangePersonnelNumber,
      onChangeRootOrgUnit,
      onChangeLastName,
      onChangeFirstName,
      onChangeSurName,
    },
    userStore: { role: currentUserRole, businessType, environment },
  } = useStores();

  const {
    status, position, department, personnelNumber, rootOrgUnit, role, firstName, lastName, surName
  } = userData;

  const [roles, setRoles] = useState([]);

  const setRolesAccessibility = (rolesFromDictionary) => {
    const accessibleRoles = get(accessibleRolesConfig, `${businessType}.${currentUserRole}`);
    if (!accessibleRoles) return rolesFromDictionary;
    return rolesFromDictionary.map(({ name, ...rest }) => ({ name, ...rest, disabled: !accessibleRoles.includes(name) }));
  };

  useEffect(() => {
    if (isEmpty(rolesDictionary) || isEqual(rolesDictionary, roles)) return;
    setRoles(setRolesAccessibility(rolesDictionary));
  }, [rolesDictionary]);

  const isUserActive = status === 'ACTIVE';
  const canUserEditEnterprise = hasPermission(environment, businessType, currentUserRole, ADMIN_USER_ENTERPRISE_EDIT);
  const userStatusText = isUserActive ? t('USER_IS_ACTIVE') : t('USER_IS_BLOCKED');
  const userRoleCode = get(role, 'code');
  const userRoleName = get(role, 'name');
  const sapWorkPlace = get(department, 'code');
  const rootOrgUnitName = get(rootOrgUnit, 'name');
  const rootOrgUnitCode = get(rootOrgUnit, 'code');
  const isAdminOKSelected = userRoleName === ADMINISTRATOR_OK_RU;

  useEffect(() => {
    if (isAdminOKSelected) {
      onChangeRootOrgUnit(null);
      onChangeSapWorkPlace(null);
    }
  }, [isAdminOKSelected]);

  const getRootOrgUnitsOptions = () => {
    const isPresent = rootOrgUnits.some(unit => unit.name === rootOrgUnitName);

    if (!isPresent && rootOrgUnitName && rootOrgUnitCode) {
      return [...rootOrgUnits, { name: rootOrgUnitName, code: rootOrgUnitCode }];
    }
    return rootOrgUnits;
  };

  let isReadonlyByMode = false;
  let showFullNameBlock = false;

  switch (mode) {
    case 'view':
      isReadonlyByMode = true;
      break;
    case 'control':
      isReadonlyByMode = true;
      break;
    case 'create':
      showFullNameBlock = true;
      break;
    case 'edit':
      showFullNameBlock = true;
      break;
    default:
      isReadonlyByMode = false;
  }

  return (
    <div className={classes.generalBlock}>
      {showFullNameBlock && (
        <>
          <div className={classes.blockHeader}>
            <div className={classes.blockHeaderLabel}>{t('FULL_NAME')}</div>
          </div>
          <div className={classes.twoColumns}>
            <div className={classes.column}>
              <TextInput
                label={t('LAST_NAME')}
                value={lastName}
                name="lastName"
                onChange={value => onChangeLastName(value)}
                error={isLatinian(lastName)}
                helperText={isLatinian(lastName) ? t('ONLY_CYRILLIC_SYMBOLS') : ''}
                required
                data-test="lastName"
              />
              <TextInput
                label={t('FIRST_NAME')}
                value={firstName}
                name="firstName"
                onChange={value => onChangeFirstName(value)}
                error={isLatinian(firstName)}
                helperText={isLatinian(firstName) ? t('ONLY_CYRILLIC_SYMBOLS') : ''}
                required
                data-test="firstName"
              />
            </div>
            <div className={classes.column}>
              <TextInput
                label={t('SUR_NAME')}
                value={surName}
                name="surName"
                onChange={value => onChangeSurName(value)}
                error={isLatinian(surName)}
                helperText={isLatinian(surName) ? t('ONLY_CYRILLIC_SYMBOLS') : ''}
                data-test="surName"
              />
            </div>
          </div>
        </>
      )}
      <div className={classes.blockHeader}>
        <div className={classes.blockHeaderLabel}>{t('USER_ROLE')}</div>
      </div>
      <div className={classes.twoColumns}>
        <div className={classes.column}>
          <Box css={{ maxWidth: 500 }}>
            <SelectInput
              multiLine={isReadonlyByMode}
              required
              label={t('ROLE')}
              disabled={isEmpty(rolesDictionary)}
              value={isReadonlyByMode ? userRoleName : userRoleCode}
              onChange={(value) => {
                onChangeRole(value);
              }}
              name="roleId"
              placeholder={t('SELECT_VALUE')}
              options={roles || []}
              getOptionDisabled={({ disabled } = {}) => Boolean(disabled)}
              isReadonly={isReadonlyByMode}
              optionValueKey="code"
              optionTitleKey="name"
              getOptionTitle={({ name, code }) => name || code}
              data-test="userRole"
              listBoxMaxHeight={500}
            />
          </Box>
        </div>
        <div className={classes.column}>
          {isReadonlyByMode ? (
            <OptionRenderer
              value={userStatusText}
              title={t('STATUS')}
              name="userActivityStatus"
              wrapperClass={classes.optionRenderer}
              data-test="status"
            />
          ) : (
            <FormSwitch
              label={t('USER_IS_ACTIVE')}
              name="userActivityStatus"
              checked={isUserActive}
              onChange={(e) => {
                const checked = get(e, 'target.checked');
                onChangeUserStatus(checked ? 'ACTIVE' : 'BLOCKED');
              }}
              data-test="switch-userActivityStatus"
            />
          )}
        </div>
      </div>
      <div className={classes.blockHeader}>
        <div className={classes.blockHeaderLabel}>{t('USER_ATTRIBUTES')}</div>
      </div>
      <div className={classes.twoColumns}>
        <div className={classes.column}>
          <TextInput
            label={t('POSITION')}
            value={position}
            name="position"
            onChange={onChangePositionName}
            required
            isReadonly={isReadonlyByMode}
            data-test="position"
          />
          <TextInput
            label={t('PL_DEPARTMENT')}
            value={sapWorkPlace}
            name="sapWorkPlace"
            onChange={value => onChangeSapWorkPlace(value)}
            required
            isReadonly={isAdminOKSelected || isReadonlyByMode}
            data-test="sapWorkPlace"
          />
          <TextInput
            label={t('PERSONNEL_NUMBER')}
            value={personnelNumber}
            name="personnelNumber"
            onChange={value => onChangePersonnelNumber(value)}
            isReadonly={isReadonlyByMode}
            required
            data-test="personnelNumber"
          />
        </div>
        <div className={classes.column}>
          <Box css={{ maxWidth: 500 }}>
            <SelectInput
              label={t('ENTERPRISE')}
              disabled={isEmpty(rootOrgUnits)}
              value={rootOrgUnitName}
              onChange={onChangeRootOrgUnit}
              name="rootOrgUnit"
              placeholder={t('SELECT_VALUE')}
              options={getRootOrgUnitsOptions()}
              isReadonly={isAdminOKSelected || isReadonlyByMode || !canUserEditEnterprise}
              optionValueKey="name"
              optionTitleKey="name"
              getOptionTitle={({ name, code }) => name || code}
              multiLine={isReadonlyByMode}
              required
              data-test="rootOrgUnit"
              listBoxMaxHeight={500}
            />
          </Box>
          {Boolean(rootOrgUnitCode) && (
          <OptionRenderer
            value={rootOrgUnitCode}
            title={t('ORG_UNIT_CODE_SHORT')}
            name="rootOrgUnitCode"
            wrapperClass={classes.optionRenderer}
            data-test="rootOrgUnitCode"
          />
          )}

        </div>
      </div>
    </div>
  );
};

UserGeneralBlock.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default observer(UserGeneralBlock);
