import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  optionRendererRoot: {
    display: ({ overflowType }) => (overflowType === 'simple' ? 'flex' : 'block'),
    alignItems: 'baseline',
    paddingTop: 8,
    paddingBottom: 24,
    maxWidth: ({ maxWidth }) => (maxWidth || '100%'),
  },
  value: {
    display: ({ overflowType }) => (overflowType === 'simple' ? 'flex' : 'block'),
    maxWidth: '100%',
    marginTop: 8,
    marginBottom: 8,
    position: 'relative',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: theme.palette.black,
    whiteSpace: 'normal',
    overflowWrap: 'anywhere',
  },
  title: {
    position: 'absolute',
    overflow: 'visible',
    whiteSpace: 'nowrap',
    // transform: 'translate(-13px) scale(0.75)',
    left: ({ titlePosition }) => (titlePosition === 'left' ? 0 : 'auto'),
    right: ({ titlePosition }) => (titlePosition === 'left' ? 'auto' : 0),
    top: -15,
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: theme.palette.black30,
  },
}));

export default useStyles;
