import React from 'react';
import PropTypes from 'prop-types';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { DragIndicator } from '@material-ui/icons';
import { Paper } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './styles';

const SortedDragList = ({
  list,
  itemTemplate,
  handleUpdateList,
  renderRightControls,
}) => {
  const classes = useStyles();

  const onSortEnd = parameters => ({ oldIndex, newIndex }) => (
    handleUpdateList(arrayMove(parameters, oldIndex, newIndex))
  );

  const DragHandle = sortableHandle(() => (
    <div className={classes.dragIndicator}>
      <DragIndicator />
    </div>
  ));

  const SortableElement = sortableElement(({ item }) => (
    <Paper elevation={1} className={classes.dragContainer}>
      <div className={classes.dragLeftSide}>
        <DragHandle />
        {itemTemplate(item)}
      </div>
      {renderRightControls && renderRightControls(item)}
    </Paper>
  ));

  const SortableContainer = sortableContainer(({ children }) => children);

  return (
    <SortableContainer
      useDragHandle
      useWindowAsScrollContainer
      onSortEnd={onSortEnd(list)}
    >
      <div className={classes.wrapper}>
        {list.map((item, index) => (
          <div key={item.id} className={classes.sortableElementWrapper}>
            <div className={clsx(classes.sortableElementNum, 'sortableElementNum')}>{index + 1}</div>
            <SortableElement index={index} item={item} />
          </div>
        ))}
      </div>
    </SortableContainer>
  );
};

SortedDragList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })).isRequired,
  itemTemplate: PropTypes.func.isRequired,
  handleUpdateList: PropTypes.func.isRequired,
  renderRightControls: PropTypes.func.isRequired,
};

export default SortedDragList;
