import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  const getBannerColor = (variant) => {
    let bannerColor;
    switch (variant) {
      case 'success':
        bannerColor = theme.palette.positive;
        break;
      case 'error':
        bannerColor = theme.palette.negative;
        break;
      case 'warning':
        bannerColor = theme.palette.orange;
        break;
      default:
        bannerColor = theme.palette.white;
    }
    return bannerColor;
  };
  return ({
    root: {
      borderRadius: '4px 0 0 4px',
      padding: '8px 40px 8px 8px',
      backgroundColor: theme.palette.white,
      position: 'relative',
      marginRight: 8,
      minHeight: 48,
      boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.16)',
      '&::before': {
        backgroundColor: ({ variant }) => (getBannerColor(variant)),
        height: '100%',
        content: '""',
        display: 'block',
        width: 8,
        position: 'absolute',
        top: 0,
        left: 0,
        borderRadius: '4px 0 0 4px',
      },
    },
    notificationContent: {
      backgroundColor: theme.palette.white,
      fontSize: 16,
      width: theme.notificationBannerWidth,
      resize: 'none',
      display: 'flex',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        marginLeft: 8,
        marginRight: 8,
        width: 24,
        height: 24,
      },
    },
    closeButton: {
      padding: 8,
      position: 'absolute',
      top: 0,
      right: 0,
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      '& .MuiSvgIcon-root': {
        marginLeft: 0,
        marginRight: 0,
      },
      color: theme.palette.black70,
    },
    successIcon: {
      color: theme.palette.positive,
    },
    errorIcon: {
      color: theme.palette.negative,
    },
    warningIcon: {
      color: theme.palette.orange,
    },
    notificationText: {
      color: theme.palette.black
    },
    row: { display: 'flex', flexWrap: 'wrap' },
  });
});

export default useStyles;
