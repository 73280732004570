import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import {
  IconButton, SvgIcon, Tooltip, Collapse,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { DeleteOutline, Edit, MessageOutlined } from '@material-ui/icons';

import OperationView from 'Common/widgets/OperationView/OperationView';
import { ReactComponent as QRCodeIcon } from 'Src/assets/qr_code_scanner.svg';
import CollapseButton from 'Common/components/Forms/Button/Collapse';
import FormSwitch from 'Common/components/Forms/Input/FormSwitch';
import OverflowTipSimple from 'Common/widgets/OverflowTipSimple';
import OperationRejection from 'Business/coal/components/OperationRejection';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const RouteTechObject = ({
  mode,
  handleDelete,
  handleEdit,
  onChangeIsRequiredQrCode,
  isHoverBlocked,
  data,
  isExpandedMeasurementPoints,
  onClickCollapseButton,
  operationRejectReasonDictionary,
  operationPauseReasonDictionary,
  scanFailReasonDictionary
} = {}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const {
    id = '',
    sapCode = '',
    title = '',
    measurementPoints = [],
    departmentName = '',
    isRequiredQrCode = false,
    location = '',
    operationStatus,
    pauseReason,
    rejectionReason,
    reasonComment,
    nfcScanFailReason,
  } = data;

  let showCollapseButton;
  let showActionButtons;
  let showQRCodeIcon;
  let showQRCodeSwitcher;

  switch (mode) {
    case 'view':
      showCollapseButton = true;
      showActionButtons = false;
      showQRCodeIcon = isRequiredQrCode;
      showQRCodeSwitcher = false;
      break;
    case 'create':
      showCollapseButton = false;
      showActionButtons = true;
      showQRCodeIcon = false;
      showQRCodeSwitcher = true;
      break;
    case 'edit':
      showCollapseButton = false;
      showActionButtons = true;
      showQRCodeIcon = false;
      showQRCodeSwitcher = true;
      break;
    default:
      showCollapseButton = true;
      showActionButtons = false;
      showQRCodeIcon = isRequiredQrCode;
      showQRCodeSwitcher = false;
  }

  const getRejectionReason = (enumValue) => {
    const reason = operationRejectReasonDictionary.find(item => item.enumValue === enumValue);
    if (reason) {
      return reason.value;
    }
  };

  const getPauseReason = (enumValue) => {
    const reason = operationPauseReasonDictionary.find(item => item.enumValue === enumValue);
    if (reason) {
      return reason.value;
    }
  };

  const getScanFailReason = (enumValue) => {
    const reason = scanFailReasonDictionary.find(item => item.enumValue === enumValue);
    if (reason) {
      return reason.value;
    }
  };

  const config = {
    top: {
      ...Boolean(location || getScanFailReason(nfcScanFailReason)) && {
        left: (
          <>
            {Boolean(location) && (
              <div className={classes.cardCornerTitle}>
                <OverflowTipSimple tooltipTitle={location} text={location} data-test="location" />
              </div>
            )}
            {Boolean(getScanFailReason(nfcScanFailReason)) && (
              <div className={classes.nfcScanFailReasonWrapper}>
                <SvgIcon component={QRCodeIcon} className={classes.nfcScanFailReasonIcon} />
                <OverflowTipSimple tooltipTitle={getScanFailReason(nfcScanFailReason)} text={getScanFailReason(nfcScanFailReason)} data-test="location" />
              </div>
            )}
          </>
        ),
      },
      ...Boolean(departmentName) && {
        right: (
          <div className={classes.cardCornerTitle} data-test="department_name">
            {departmentName}
          </div>
        ),
      },
    },
    ...Boolean(pauseReason || rejectionReason) && {
      bottom: {
        left: (
          <div>
            <OperationRejection rejectionType={pauseReason ? 'ON_HOLD' : 'REJECTED'} reason={getRejectionReason(rejectionReason) || getPauseReason(pauseReason)} />
            {Boolean(reasonComment) && (
              <div className={classes.reasonComment}>
                <OverflowTipSimple tooltipTitle={reasonComment} text={reasonComment} data-test="reasonComment" />
              </div>
            )}
          </div>
        ),
      },
    }
  };

  const renderSubtitle = () => (
    <div className={classes.cardSubTitle}>
      <div className={classes.sapCodeWrapper}>
        <OverflowTipSimple
          tooltipTitle={sapCode}
          text={sapCode}
          data-test="sap_code"
        />
      </div>
      {showQRCodeIcon && (
        <Tooltip title={t('IS_REQUIRED_QR_CODE')}>
          <div data-test="requiredQrCodeIcon">
            <SvgIcon component={QRCodeIcon} viewBox="0 0 24 24" className={classes.qrCodeIcon} />
          </div>
        </Tooltip>
      )}
      {showQRCodeSwitcher && (
        <div className={classes.switcherWrapper}>
          <FormSwitch
            className={classes.switcher}
            label={t('QR_CODE')}
            name="isRequiredQRCodeSwitcher"
            checked={isRequiredQrCode}
            onChange={() => {
              if (onChangeIsRequiredQrCode) onChangeIsRequiredQrCode(id);
            }}
            data-test="switch-isRequiredQRCodeSwitcher"
          />
        </div>
      )}
    </div>
  );

  const renderHeaderActionButtons = () => (
    <div className={classes.headerActionButtons}>
      {showCollapseButton && (
        <Tooltip
          title={isExpandedMeasurementPoints ? t('HIDE_EQUIPMENT_MEASUREMENTS') : t('SHOW_EQUIPMENT_MEASUREMENTS')}
        >
          <div className={classes.collapseButtonWrapper}>
            <CollapseButton
              className={classes.collapseButton}
              iconColor={theme.palette.black30}
              disableLabels
              isCollapsed={!isExpandedMeasurementPoints}
              handleClick={() => {
                if (onClickCollapseButton) onClickCollapseButton();
              }}
            />
          </div>
        </Tooltip>
      )}
      {showActionButtons && !isHoverBlocked && (
        <div className="actionButtons">
          {Boolean(handleEdit) && (
            <Tooltip title={t('EDIT')}>
              <IconButton
                size="small"
                color="primary"
                aria-label="edit"
                data-test="actionButton_edit"
                className={clsx(classes.iconButton, classes.editIconButton)}
                onClick={() => handleEdit(id)}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
          {Boolean(handleDelete) && (
            <Tooltip title={t('DELETE')}>
              <IconButton
                size="small"
                color="primary"
                aria-label="delete"
                data-test="actionButton_delete"
                className={clsx(classes.iconButton, classes.deleteIconButton)}
                onClick={() => handleDelete(id)}
              >
                <DeleteOutline />
              </IconButton>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );

  const renderMeasurementPoints = () => (
    <Collapse
      in={isExpandedMeasurementPoints}
      timeout={0}
      unmountOnExit
    >
      <div className={classes.measurementPointsWrapper}>
        {measurementPoints.map(({
          value, measuredDate, unit, name: measurementPointTitle, measurementPointSubtitle, unitTitle, measurementComment
        } = {}, index) => {
          const measurementPointKey = `measurementPoint${index}`;
          return (
            <div key={measurementPointKey} className={classes.measurementPoint}>
              <div className={classes.measurementPointTitle}>
                {measurementPointTitle}
                &nbsp;
                {unitTitle && unitTitle}
              </div>
              {(measurementPointSubtitle || !value) && <div className={classes.measurementPointSubtitle}>{measurementPointSubtitle || `${t('NO_READINGS')} ${unit}`}</div>}
              {value && (
                <div className={classes.valueUnitDateWrapper}>
                  <div>
                    <span className={classes.value}>
                      {value}
                    </span>
                    {unit && (
                      <span className={classes.measurementPointSubtitle}>
                        {unit}
                      </span>
                    )}
                  </div>
                  {measuredDate && (
                    <div className={classes.measurementPointSubtitle}>{measuredDate}</div>
                  )}
                </div>
              )}
              {(measurementComment) && (
                <div className={classes.measurementCommentWrapper}>
                  <SvgIcon component={MessageOutlined} className={classes.measurementCommentIcon} />
                  <OverflowTipSimple tooltipTitle={measurementComment} text={measurementComment} data-test="location" />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </Collapse>
  );

  return (
    <div className={classes.root}>
      <OperationView
        key={title}
        title={title}
        subTitle={renderSubtitle()}
        counters={config}
        status={operationStatus}
        headerActionButton={renderHeaderActionButtons()}
      />
      {!isEmpty(measurementPoints) && renderMeasurementPoints()}
    </div>
  );
};

RouteTechObject.defaultProps = {
  data: {
    title: '',
    measurementPoints: [],
    departmentName: '',
    isRequiredQrCode: false,
  },
  isHoverBlocked: false,
  isExpandedMeasurementPoints: false,
  onClickCollapseButton: undefined,
  onChangeIsRequiredQrCode: undefined,
  handleDelete: undefined,
  handleEdit: undefined,
  operationRejectReasonDictionary: [],
  operationPauseReasonDictionary: [],
  scanFailReasonDictionary: []
};

RouteTechObject.propTypes = {
  mode: PropTypes.string.isRequired,
  isHoverBlocked: PropTypes.bool,
  onChangeIsRequiredQrCode: PropTypes.func,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  data: PropTypes.shape({
    sapCode: PropTypes.string,
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    measurementPoints: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
    })),
    departmentName: PropTypes.string,
    isRequiredQrCode: PropTypes.bool,
    location: PropTypes.string,
    operationStatus: PropTypes.string,
    pauseReason: PropTypes.string,
    rejectionReason: PropTypes.string,
    reasonComment: PropTypes.string,
    nfcScanFailReason: PropTypes.string,
  }),
  isExpandedMeasurementPoints: PropTypes.bool,
  onClickCollapseButton: PropTypes.func,
  operationRejectReasonDictionary: PropTypes.arrayOf(PropTypes.shape({
    enumValue: PropTypes.string
  })),
  operationPauseReasonDictionary: PropTypes.arrayOf(PropTypes.shape({
    enumValue: PropTypes.string
  })),
  scanFailReasonDictionary: PropTypes.arrayOf(PropTypes.shape({
    enumValue: PropTypes.string
  })),
};

export default RouteTechObject;
