import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  circularProgress: {
    color: theme.palette.primaryLight,
  },
  actionButtonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiIconButton-root': {
      width: 32,
      height: 32,
      '&.Mui-disabled': {
        '& .MuiSvgIcon-root': {
          color: theme.palette.black20,
        },
      },
    }
  },
  remove: {
    '&.MuiIconButton-root': {
      color: theme.palette.negative,
      '& svg': {
        width: 24,
        height: 24,
      },
      '&:hover': {
        backgroundColor: theme.palette.pink,
      },
    }
  },
  activate: {
    '&.MuiIconButton-root': {
      color: theme.palette.primaryLight,
      '&:hover': {
        backgroundColor: theme.palette.lightSelection,
      },
    }
  },
  pause: {
    '&.MuiIconButton-root': {
      color: theme.palette.alert,
      marginRight: 8,
      marginLeft: 8,
      '&:hover': {
        backgroundColor: theme.palette.alertSelection,
      },
    }
  }
}));

export default useStyles;
