import {
  action,
  observable,
  runInAction,
  makeObservable,
} from 'mobx';
import { isEmpty } from 'lodash';
import TaskRouteAgent from 'ApiAgents/TaskRoutes/TaskRouteAgent';
import TaskAgent from 'ApiAgents/Tasks/TaskAgent';
import { useTranslation } from 'react-i18next'; // eslint-disable-line
import { INITIAL_PAGE_SIZE, INITIAL_PAGE_NUMBER } from 'Shared/constants/paging';
import { taskStatusesIds } from 'Business/coal/config/taskStatusesConfig';

const taskRouteAgent = new TaskRouteAgent();
const taskAgent = new TaskAgent();

export class CoalRoutesListStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable totalPages = null;

  @observable isRoutesListLoaded = false;

  @observable hasNextPage = null;

  @observable isLoadingByPage = false;

  @observable isLoading = false;

  @observable isLoaded = false;

  @observable routesList = [];

  @observable pageNumber = INITIAL_PAGE_NUMBER;

  @observable pageSize = INITIAL_PAGE_SIZE;

  @action loadRoutesList = async (pageNumber, pageSize) => {
    const isPaginating = !!pageNumber;
    this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', true);

    try {
      const {
        items = [],
        totalPages,
        hasNextPage,
        hasPreviousPage,
      } = await taskRouteAgent.loadCoalRoutesList(isPaginating, pageNumber, pageSize);
      this.pageNumber = pageNumber;
      this.pageSize = pageSize;

      runInAction(() => {
        this.routesList = items.map((route = {}) => {
          const { technicalObjects } = route;
          const routeDepartmentsNames = [];
          technicalObjects.forEach(({ departmentName }) => {
            if (departmentName && !routeDepartmentsNames.includes(departmentName)) {
              routeDepartmentsNames.push(departmentName);
            }
          });
          return {
            ...route,
            routeDepartmentsNames: routeDepartmentsNames.join(', '),
          };
        });
        this.totalPages = totalPages;
        this.hasNextPage = hasNextPage;
        this.hasPreviousPage = hasPreviousPage;
        this.isRoutesListLoaded = true;
        this.isLoaded = true;
      });
    } catch (error) {
      runInAction(() => {
        this.routesList = [];
        this.totalPages = 0;
        this.hasNextPage = false;
        this.hasPreviousPage = false;
        this.isRoutesListLoaded = true;
        this.isLoaded = true;
      });
      console.log('ERROR in ROUTES FETCHING: ', error);
      throw new Error(error);
    }

    runInAction(() => {
      this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', false);
    });
  };

  @action deleteRoute = async (id) => {
    this.isDeleting = true;
    try {
      await taskRouteAgent.deleteCoalRoute({ id });
      runInAction(() => {
        this.isDeleting = false;
      });
      return { error: false };
    } catch (error) {
      const errorMessage = 'ERROR IN ROUTE DELETING';
      console.log(`${errorMessage}: `, error);
      runInAction(() => {
        this.isDeleting = false;
      });
      return { error };
    }
  };

  isRouteIncludedInActiveTask = async (routeId) => {
    try {
      const taskStatuses = [taskStatusesIds.NEW, taskStatusesIds.ASSIGNED, taskStatusesIds.IN_PROGRESS];
      const { items = [] } = await taskAgent.loadList({ taskStatuses, routeId, taskType: 'COAL_INSPECTION' }, true, 1, 1) || {};
      return !isEmpty(items);
    } catch (error) {
      const errorMessage = 'ERROR IN FETCHING TASK LIST';
      console.log(`${errorMessage}: `, error);
    }
  }
}

export default CoalRoutesListStore;
