import Schema from 'validate';
import { cloneDeep } from 'lodash';
import SchemaValidator from 'Services/validation/SchemaValidator';

const optionalFields = {
  access: [],
  queryByRole: {}
};

// eslint-disable-next-line import/prefer-default-export
export function makeStatusDto(schema) {
  if (!(schema instanceof Schema)) {
    throw new TypeError('Parameter "schema" provided to function "makeStatusDto" should be an instance of Schema');
  }

  const cachedSchema = schema;

  return (statusObj) => {
    let finalStatusObject = cloneDeep(statusObj);

    if (statusObj.queryByRole) {
      const { queryByRole = {} } = statusObj;
      finalStatusObject = { ...statusObj, queryByRole: { ...queryByRole } };
    }

    const errors = SchemaValidator.validate(cachedSchema, statusObj);

    if (errors.length > 0) {
      throw new Error(`Validation errors in makeStatusDto: ${errors.map(({ message }) => message).toString()}`);
    }

    return {
      ...optionalFields, ...finalStatusObject
    };
  };
}
