import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  TPS_REPAIR, TPS_INSPECTION, PL, COAL
} from 'Shared/constants/business';

import { ReactComponent as TPSBanner } from 'Src/assets/TPS_NoAccess.svg';
import { ReactComponent as PLBanner } from 'Src/assets/PL_NoAccess.svg';
import { ReactComponent as COALBanner } from 'Src/assets/COAL_NoAccess.svg';
import useStyles from './styles';

const NoAccess = ({ businessType }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const renderBanner = () => {
    switch (businessType) {
      case TPS_REPAIR:
        return <TPSBanner />;
      case TPS_INSPECTION:
        return <TPSBanner />;
      case PL:
        return <PLBanner />;
      case COAL:
        return <COALBanner />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.noContent}>
      {renderBanner()}
      <div className={classes.subtitle}>{t('NO_ACCESS_TITLE')}</div>
      <div className={classes.text}>
        <p>{t('NO_ACCESS_MESSAGE')}</p>
      </div>
    </div>
  );
};

NoAccess.propTypes = {
  businessType: PropTypes.oneOf([TPS_REPAIR, TPS_INSPECTION, PL, COAL]).isRequired,
};

export default NoAccess;
