export const ASSIGNED_ENUM = 'ASSIGNED';
export const CANCELED_ENUM = 'CANCELED';
export const COMPLETED_ENUM = 'COMPLETED';
export const IN_PROGRESS_ENUM = 'IN_PROGRESS';
export const NEW_ENUM = 'NEW';
export const PAUSE_ENUM = 'PAUSE';
export const UNKNOWN_ENUM = 'UNKNOWN';

export const taskStatusEnum = [
  ASSIGNED_ENUM,
  CANCELED_ENUM,
  COMPLETED_ENUM,
  IN_PROGRESS_ENUM,
  NEW_ENUM,
  PAUSE_ENUM,
  UNKNOWN_ENUM,
];

export const inspectionStatusesTranslationKeys = {
  ASSIGNED: 'PENDING_DEFECT_STATUS',
  CANCELED: 'REJECTED_DEFECT_STATUS_SHORT',
  COMPLETED: 'COMPLETED_DEFECT_STATUS',
  IN_PROGRESS: 'PERFORMS_DEFECT_STATUS',
  NEW: 'NEW_DEFECT_STATUS',
  PAUSE: 'PAUSE',
  UNKNOWN: 'UNKNOWN',
};
