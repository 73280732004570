import React from 'react';
import PropTypes from 'prop-types';
import { ButtonBase } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import useStyles from './styles';

function FilterHeaderWithClearButton(props) {
  const {
    label, clearLabel, hasActiveFilters, handleClearFilters, hasDiff,
  } = props;
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={classes.filterHeader}
    >
      <div className={classes.filtersLabel}>{label}</div>
      <ButtonBase
        data-test="clearAllFiltersButton"
        disabled={!hasActiveFilters && !hasDiff}
        className={classes.clearFiltersButton}
        onClick={handleClearFilters}
      >
        {clearLabel}
      </ButtonBase>
    </Grid>
  );
}

FilterHeaderWithClearButton.defaultProps = {
  hasActiveFilters: false,
  hasDiff: false,
  handleClearFilters: () => {},
};

FilterHeaderWithClearButton.propTypes = {
  hasActiveFilters: PropTypes.bool,
  hasDiff: PropTypes.bool,
  handleClearFilters: PropTypes.func,
  label: PropTypes.string.isRequired,
  clearLabel: PropTypes.string.isRequired,
};

export default FilterHeaderWithClearButton;
