import {
  format,
  isDate,
  isValid as isValidDateFns,
  // formatISO as formatISOFns,
  parseISO,
  differenceInDays,
  set,
} from 'date-fns';
import { DISPLAY_DATE_FORMAT, DISPLAY_TIME_FORMAT } from 'Shared/constants/datetime';

const nulledTime = {
  hours: 0,
  minutes: 0,
  seconds: 0,
  milliseconds: 0
};

export const isValid = (date, noParse) => isValidDateFns(noParse ? date : parseISO(date));

const isValidDateString = (dateString) => {
  if (typeof dateString !== 'string') return false;

  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateString.match(regEx)) return false;

  const dateFromDateString = new Date(dateString);
  if (!isValid(dateFromDateString, true)) return false;
  return dateFromDateString.toISOString().slice(0, 10) === dateString;
};


export const formatToISOString = (date) => {
  if (isDate(date) && isValidDateFns(date)) return date.toISOString();
  if (isValidDateString(date)) return new Date(date).toISOString();
  return null;
};

export const isDateOverdue = (date) => {
  if (isValid(date)) {
    return differenceInDays(
      new Date(parseISO(date)),
      new Date()
    ) < 0;
  }
};

export const formatDate = (date, type = DISPLAY_DATE_FORMAT) => {
  if (isValid(date)) return format(new Date(parseISO(date)), type);
  return '';
};

export const formatTime = (date, type = DISPLAY_TIME_FORMAT) => {
  if (isValid(date)) return format(new Date(parseISO(date)), type);
  return '';
};

export const isToday = (date) => {
  if (isValid(date)) {
    return differenceInDays(
      new Date(parseISO(date)),
      set(new Date(), nulledTime)
    ) === 0;
  }
  return false;
};

export const diffByDate = (startDate, finishDate, noParse) => {
  if (isValid(startDate, noParse) && isValid(finishDate, noParse)) {
    return differenceInDays(
      noParse ? finishDate : new Date(parseISO(finishDate)),
      noParse ? startDate : new Date(parseISO(startDate)),
    );
  }
  return false;
};

export const getTime = (time) => {
  const [hours, minutes] = time.split(':');
  return {
    hours: hours ? parseInt(hours, 10) : 0,
    minutes: minutes ? parseInt(minutes, 10) : 0
  };
};
