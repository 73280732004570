import { INITIAL_PAGE_SIZE, INITIAL_PAGE_NUMBER } from 'Shared/constants/paging';

const getPaginationQueryObject = (
  shouldPaginate,
  pageNumber = INITIAL_PAGE_NUMBER,
  pageSize = INITIAL_PAGE_SIZE
) => (
  shouldPaginate
    ? {
      shouldPaginate,
      pageNumber,
      pageSize,
    } : {}
);

export default getPaginationQueryObject;
