import Agent from 'ApiAgents/Agent';
import qs from 'qs';


export default class EquipmentAgent extends Agent {
  get = (query = '') => (
    this.request({
      url: `/${this.entity}/view`,
      method: 'GET',
      params: query
    }, 'techObject')
  );

  getTechObjectsByOrgUnit = orgUnitId => (
    this.request({
      url: `/${this.entity}byorgunit/${orgUnitId}/view`,
      method: 'GET',
    }, 'techObject')
  );

  getDefectsByTechObjectId = id => (
    this.request({
      url: '/defects/view/byTechnicalObjectId',
      method: 'GET',
      params: { id }
    }, 'techObject')
  );

  getTechObjectsByIds = (body = [], levelsToLoadCount) => (
    this.request({
      url: '/techobjects/view/tree/byIds',
      params: { levelsToLoadCount },
      method: 'POST',
      data: {
        ids: [...body]
      }
    }, 'techObject')
  );

  getTechObjectByIds = id => (
    this.request({
      url: 'techobjects/view/byId',
      method: 'GET',
      params: { id }
    }, 'techObject')
  );

  getTechObjectsByDepartment = (externalWorkplaceCode, levelsToLoadCount) => (
    this.request({
      url: '/techobjects/view/tree/byDepartment',
      method: 'GET',
      params: { externalWorkplaceCode, levelsToLoadCount }
    }, 'techObject')
  );

  getMeasurementPoints = () => (
    this.request({
      url: '/measuringpoint/view/all',
      method: 'GET',
      params: {
        isStandard: true,
        isDeleted: false,
        isActive: true
      }
    }, 'techObject')
  );

  getMeasurementPointsByReport = () => (
    this.request({
      url: '/measuringpoint/view/byReport',
      method: 'GET',
      params: { isVisibleReport: true }
    }, 'techObject')
  );

  getDefects = () => (
    this.request({
      url: '/defects/view/all',
      method: 'GET',
      params: {
        isStandard: true,
        isDeleted: false,
        isActive: true
      }
    }, 'techObject')
  );

  getTechObjectsTrees = (sapCode, depth = null) => (
    this.request({
      url: '/techobjects/trees',
      method: 'GET',
      params: { sapCodes: [sapCode], levelsToLoadCount: depth },
      paramsSerializer: params => qs.stringify(params)
    }, 'techObject')
  );

  getTechObjectsByIdsArray = (body = []) => (
    this.request({
      url: '/techobjects/view',
      method: 'POST',
      data: {
        ids: [...body]
      }
    }, 'techObject')
  );

  getTechObjectParents = id => (
    this.request({
      url: '/techobjects/view/parents/byId',
      method: 'GET',
      params: { id }
    }, 'techObject')
  );

  getTechObjectParentsByIdsArray = id => (
    this.request({
      url: '/techobjects/view/parents/byIds',
      method: 'GET',
      params: { id },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    }, 'techObject')
  );

  getTpsWithPowerUnitsWithCheckPoints = orgUnitCode => (
    this.request({
      url: '/tpsInspectionHierarchy/view/tps/byOrgUnitCode',
      method: 'GET',
      params: { orgUnitCode, levelsToLoadCount: 1 }
    }, 'tasks')
  );

  getTechPlacesWithCheckPoints = powerUnitId => (
    this.request({
      url: '/tpsInspectionHierarchy/view/technicalPlaces/byPowerUnitId',
      method: 'GET',
      params: { powerUnitId, levelsToLoadCount: 1 }
    }, 'tasks')
  );
}
