import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import useStores from 'Store/useStores';
import * as routes from 'Shared/constants/routes';
import useManageEntityModal from 'Common/components/Modal/ManageEntityModal/useManageEntityModal';

import { useTranslation } from 'react-i18next';
import { HEAD_MECHANIC, MECHANIC } from 'Shared/constants/roles';
import { COMPLETED_ENUM, ON_CONTROL_ENUM } from 'Shared/constants/taskStatuses';

import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import { PanelBox } from 'Common/widgets/Layout/Panel/Panel';
import ConfirmOverlay from 'Business/coal/components/ConfirmOverlay';
import actionButtonTypes from 'Common/components/Forms/Button/configs/actionButtonTypes';
import ActionButton from 'Common/components/Forms/Button/components/Action/Action';
import ExecutionResultsBlock from 'Business/coal/components/ExecutionResultsBlock';
import GeneralBlock from 'Business/coal/components/GeneralBlock';
import OperationsBlock from 'Business/coal/components/OperationsBlock';
import AdditionalOperationsBlock from 'Business/coal/components/AdditionalOperationsBlock';
import ControlResultsBlock from 'Business/coal/components/ControlResultsBlock';

import useStyles from './styles';

const TaskAttributes = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { mode } = props;
  const history = useHistory();
  const params = useParams();
  const { id: taskId } = params;
  const [confirmOverlayIsVisible, setConfirmOverlayIsVisible] = useState(false);

  const {
    coalTaskStore: {
      taskData, isLoaded, isUpdating, reset, confirmTask, rescheduleTask
    },
    notificationStore: { enqueueSnackbar },
    userStore: { role, auth: { id: currentUserId } = {} },
    drawerStore: { isDrawerOpened },
  } = useStores();
  const classes = useStyles({ isDrawerOpened });

  const {
    title, isStandard, additionalOperations, updatedBy, taskStatus, operations
  } = taskData;

  let showExecutionResultsBlock = false;
  let showExecutionButtonsByMode = false;
  let showControlResultsBlock = false;
  let isReadonlyByMode = false;

  switch (mode) {
    case 'create':
      isReadonlyByMode = false;
      break;
    case 'view':
      isReadonlyByMode = true;
      break;
    case 'control':
      showExecutionResultsBlock = true;
      showExecutionButtonsByMode = true;
      isReadonlyByMode = true;
      break;
    default:
      showExecutionResultsBlock = false;
      showExecutionButtonsByMode = false;
      isReadonlyByMode = false;
  }

  switch (taskStatus) {
    case COMPLETED_ENUM:
      showControlResultsBlock = true;
      showExecutionResultsBlock = true;
      break;
    case ON_CONTROL_ENUM:
      showExecutionResultsBlock = true;
      break;
    default:
      showExecutionButtonsByMode = false;
      showControlResultsBlock = false;
  }

  const createdByCurrentUser = updatedBy === currentUserId;
  const showExecutionButtonsByUser = (role === MECHANIC && createdByCurrentUser) || role === HEAD_MECHANIC;
  const showExecutionButtons = showExecutionButtonsByUser && showExecutionButtonsByMode;

  const {
    setManageEntityModalVisibility,
    setOnManageEntityModalCloseCallback,
    setManageEntityModalState,
    renderManageEntityModal,
  } = useManageEntityModal();

  const goBack = () => {
    reset();
    const { state } = location;
    const { fromPath, ...rest } = state || {};
    history.push({
      pathname: fromPath || routes.TASKS,
      state: rest,
    });
  };

  const reschedule = async () => {
    const { res, error } = await rescheduleTask(taskId);
    if (error) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('REQUEST_DEFAULT_ERROR')
            }]
          }],
        },
        variant: 'error',
      });
      return;
    }
    const { child: { id: childTaskId, title: childTaskTitle } } = res;
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('TASK_SUCCESSFULLY_RESCHEDULED')
          }, { type: 'link', text: childTaskTitle, url: `${routes.VIEW_TASK}/${childTaskId}` }]
        }],
      },
      variant: 'success',
    });
    goBack();
  };

  const confirm = ({ reason, description }) => {
    const message = t('TASK_SUCCESSFULLY_CONFIRMED_SENT_TO_THE_COMPLETED_TAB');

    confirmTask({ taskId, taskCompletionReason: reason, completionReasonComment: description }).then(
      () => {
        enqueueSnackbar({
          messageTemplate: {
            rows: [{
              rowContent: [{
                type: 'text',
                text: message
              }]
            }],
          },
          variant: 'success',
        });
        goBack();
      },
    )
      .catch(() => {
        enqueueSnackbar({
          messageTemplate: {
            rows: [{
              rowContent: [{
                type: 'text',
                text: t('REQUEST_DEFAULT_ERROR')
              }]
            }],
          },
          variant: 'error',
        });
      });
  };


  if (!isLoaded && mode !== 'create') {
    return <ProgressBar />;
  }

  const showReschedulingTaskPrompt = () => {
    setManageEntityModalVisibility(true);
    setManageEntityModalState({
      title: t('TASK_RESCHEDULING'),
      entityName: `${t('REPAIR_TASK')} ${title}`,
      message: t('THE_TASK_WILL_BE_RESCHEDULED'),
      type: 'action',
      actionButtonTitle: t('RESCHEDULE'),
    });
    setOnManageEntityModalCloseCallback(() => reschedule);
  };

  const renderExecutionButtons = () => (
    <div className={classes.executionButtonsBlock}>
      <ActionButton
        className={classes.executionButton}
        type={actionButtonTypes.CANCEL}
        text={t('ON_RESCHEDULING')}
        handleClick={showReschedulingTaskPrompt}
      />
      <ActionButton
        className={classes.executionButton}
        type={actionButtonTypes.CONFIRM}
        text={t('CONFIRM')}
        handleClick={() => setConfirmOverlayIsVisible(true)}
      />
    </div>
  );

  return (
    (isLoaded || mode === 'create') && (
      <>
        {isUpdating && <ProgressBar />}
        <div>
          {showControlResultsBlock && (
            <PanelBox className={classes.panelBox} boxStyles={{ padding: 0, maxWidth: 'inherit' }}>
              <ControlResultsBlock />
            </PanelBox>
          )}
          {showExecutionResultsBlock && (
            <PanelBox className={classes.panelBox} boxStyles={{ padding: 0, maxWidth: 'inherit' }}>
              <ExecutionResultsBlock />
            </PanelBox>
          )}
          <PanelBox className={classes.panelBox} boxStyles={{ padding: 0, maxWidth: 'inherit' }}>
            <GeneralBlock mode={mode} />
          </PanelBox>
          <OperationsBlock mode={mode} isReadonlyByMode={isReadonlyByMode} />
          {(!isEmpty(additionalOperations) || (!isReadonlyByMode && !isStandard)) && (
            <PanelBox className={classes.panelBox} boxStyles={{ padding: 0 }}>
              <AdditionalOperationsBlock
                mode={mode}
                name="additionalOperationsBlock"
                value={!isEmpty(additionalOperations) || !isEmpty(operations)}
                required
              />
            </PanelBox>
          )}
        </div>
        {showExecutionButtons && renderExecutionButtons()}
        <ConfirmOverlay
          isOpen={confirmOverlayIsVisible}
          handleOverlayClose={() => setConfirmOverlayIsVisible(false)}
          handleConfirm={confirm}
        />
        {renderManageEntityModal()}
      </>
    )
  );
};

TaskAttributes.propTypes = {
  mode: PropTypes.string.isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      positive: PropTypes.string,
      black30: PropTypes.string,
      negative: PropTypes.string,
    }),
  }),
};

TaskAttributes.defaultProps = {
  theme: {},
};

export default observer(TaskAttributes);
