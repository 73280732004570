import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  parametersSettings: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: theme.spacing(2, 0, 5),
  },
  loadText: {
    textAlign: 'center',
    fontSize: 14,
    padding: theme.spacing(4),
  },
}));

export default useStyles;
