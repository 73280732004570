import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams, withRouter } from 'react-router-dom';
import * as routes from 'Shared/constants/routes';
import Layout from 'Common/widgets//Layout/Paper/Layout';
import HeaderLayout from 'Common/widgets/Layout/Header/Header';
import { observer } from 'mobx-react';
import Loader from 'Common/components/Progress/components/Circular/ProgressBar';
import { CONTROL } from 'Shared/constants/routes';
import useStores from 'Store/useStores';
import {
  Box,
  Tab,
  Tabs,
} from '@material-ui/core';
import taskTabsConfig from 'Src/business/tpsRepairs/config/controlTaskMaterialValuesTabsConfig';
import View from 'Business/tpsRepairs/pages/ConsumptionsControl/View/View';
import MaterialValuesHistoryTab from 'Src/business/tpsRepairs/components/ConsumptionsControl/MaterialValuesHistoryTab';
import TabPanel from 'Common/components/TabPanel';

const ConsumptionsControl = observer(({
  match,
  history,
}) => {
  const {
    tasksStore: {
      isLoading,
      getReasons,
      reasonsList,
      dictionaries,
      getDictionaryByKeys,
      isDictionariesLoaded,
    },
    userStore: {
      isLoading: isUsersLoading,
    },
    materialValuesControlStore: {
      materials,
      getTaskById,
      selectedTask,
      resetTaskStorePart,
    },
    notificationStore: { enqueueSnackbar },
  } = useStores();
  const { t } = useTranslation();
  const { tab = 'common' } = match.params;
  const { id, operationStatus } = useParams();
  const translatedTaskTabsConfig = taskTabsConfig(t);
  const defaultTab = translatedTaskTabsConfig[0];

  const handleChange = (event, newValue) => {
    history.replace(`${routes.CONTROL}/${operationStatus}/${id}/${newValue === defaultTab.alias ? '' : newValue}`);
  };

  useEffect(() => {
    if (!translatedTaskTabsConfig.some(item => item.alias === tab)) {
      history.push(`${routes.CONTROL}/${operationStatus}/${id}/`);
    }
  }, []);

  useEffect(() => {
    // getDictionaryByKeys(['TaskType', 'TaskStatus']);
    getDictionaryByKeys(['TaskType']);
    getReasons();
  }, []);

  useEffect(() => {
    getTaskById(id, operationStatus).catch(() => {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('REQUEST_DEFAULT_ERROR')
            }]
          }],
        },
        variant: 'error',
      });
    });
  }, [id, operationStatus]);

  useEffect(() => () => resetTaskStorePart(), []);

  const handleGoBack = () => {
    history.push(`${CONTROL}/${operationStatus}`);
  };

  if (isLoading || isUsersLoading) return <Loader />;

  return (
    <Layout autoHeight>
      <HeaderLayout
        title={t('TASK_VIEW')}
        handleGoBack={handleGoBack}
        isActiveGoBack
        titleGoBack={t('GO_BACK')}
      />
      <Box style={{ borderBottom: '1px solid #e9e9e9' }}>
        <Tabs
          value={!tab ? defaultTab.alias : tab}
          onChange={(event, newValue) => handleChange(event, newValue)}
          variant="scrollable"
        >
          {translatedTaskTabsConfig.map(
            item => (
              <Tab
                key={item.alias}
                label={item.title}
                value={item.alias}
                data-test={`${item.alias}_tab`}
              />
            )
          )
              }
        </Tabs>
      </Box>
      {selectedTask && selectedTask.taskId && (
      <>
        <TabPanel tab={tab} alias={translatedTaskTabsConfig[0].alias}>
          {isDictionariesLoaded && (
            <View
              task={selectedTask}
              reasons={reasonsList}
              materials={materials}
              dictionaries={dictionaries}
            />
          )}
        </TabPanel>
        <TabPanel tab={tab} alias={translatedTaskTabsConfig[1].alias}>
          <MaterialValuesHistoryTab taskId={selectedTask.taskId} />
        </TabPanel>
      </>
      )}
    </Layout>
  );
});

ConsumptionsControl.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tab: PropTypes.string,
    })
  }),
};

export default withRouter(ConsumptionsControl);
