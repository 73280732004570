import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import useStores from 'Store/useStores';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import Header from 'Common/widgets/Layout/Header/Header';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import VolumeOverlay from 'Business/plant/pages/AdministrationPage/components/VolumeOverlay';
import NoContentBlock from 'Business/plant/pages/AdministrationPage/components/NoContentBlock';
import VolumesView from 'Business/plant/pages/AdministrationPage/components/VolumesView';
import KeysEnabler from 'Business/plant/pages/AdministrationPage/components/KeysEnabler';
import EditVolumes from 'Business/plant/pages/AdministrationPage/components/EditVolumes';
import { SETTINGS } from 'Shared/constants/routes';

import useStyles from './styles';

const AdministrationPage = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const {
    volumeStore: {
      loadVolumes,
      updateVolumeOrder,
      deleteVolumes,
      volumeList,
      isLoadingVolumeList,
      isLoadedVolumeList,
      discardState,
    },
  } = useStores();
  const [isSavingOnEdit, setSavingOnEdit] = useState(false);
  const [idsForRemove, setIdsForRemove] = useState([]);
  const [volumeView, setVolumeView] = useState('view');
  const [valueType, setValueModalType] = useState('create');
  const [isActiveCreateValueModal, setStateCreateValueModal] = useState(false);
  const [parameterDataForEdit, setEditableData] = useState(null);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const handleCreateParameter = () => {
    setValueModalType('create');
    setStateCreateValueModal(true);
    setVolumeView('view');
  };

  const handleEditParameter = (parameter) => {
    setValueModalType('edit');
    setEditableData(parameter);
    setStateCreateValueModal(true);
  };

  const handleCancelEditing = () => {
    setIdsForRemove([]);
    setVolumeView('view');
  };

  const handleRemoveParameter = (id) => {
    setIdsForRemove([...idsForRemove, id]);
  };

  const handleSaveEditing = (sortedData) => {
    setSavingOnEdit(true);
    Promise.all([
      updateVolumeOrder(sortedData),
      deleteVolumes(idsForRemove)
    ]).then(() => {
      setVolumeView('view');
      loadVolumes();
      setSavingOnEdit(false);
    });
  };

  const hasSomeSelectedKey = () => selectedKeys.some(key => key.enable);

  const mapKeyList = keys => keys.map(key => ({
    title: key,
    enable: false,
  }));

  const checkOnSelected = key => selectedKeys
    .filter(itemKey => itemKey.enable)
    .map(itemKey => itemKey.title).indexOf(key) > -1;

  const filterByKeys = (parameters) => {
    if (!hasSomeSelectedKey()) {
      return parameters;
    }

    return parameters.filter(({ operationKeys }) => operationKeys.some(checkOnSelected));
  };

  useEffect(() => () => discardState(), []);

  useEffect(() => {
    if (isActiveCreateValueModal) return;
    loadVolumes();
  }, [isActiveCreateValueModal]);

  useEffect(() => {
    if (!parameterDataForEdit) return;

    handleEditParameter();
  }, [parameterDataForEdit]);

  useEffect(() => {
    setSelectedKeys(mapKeyList(volumeList.operationKeyList));
  }, [volumeList.operationKeyList]);

  const goToSettings = () => history.push({ pathname: SETTINGS });

  return (
    <PaperLayout autoHeight>
      {volumeView === 'view' && (
        <>
          <Header
            titleGoBack={t('GO_BACK')}
            isActiveGoBack
            handleGoBack={goToSettings}
            title={t('VOLUME_PAGE_TITLE')}
          >
            {Boolean(volumeList.parameters.length) && (
              <Button
                color="primary"
                variant="text"
                onClick={() => setVolumeView('edit')}
                startIcon={<Edit />}
              >
                {t('EDIT')}
              </Button>
            )}
          </Header>
          {isLoadingVolumeList || !isLoadedVolumeList ? <ProgressBar /> : (
            <>
              {isLoadedVolumeList && volumeList.parameters.length === 0 && (
                <NoContentBlock setStateCreateValueModal={setStateCreateValueModal} />
              )}
              {isLoadedVolumeList && volumeList.parameters.length > 0 && (
                <div className={classes.parametersSettings}>
                  <VolumesView
                    volumeList={filterByKeys(volumeList.parameters)}
                    lastEdit={volumeList.lastEdit}
                  />
                  <KeysEnabler
                    keyList={selectedKeys}
                    setSelectedKeys={setSelectedKeys}
                    hasSomeSelectedKey={hasSomeSelectedKey()}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
      {volumeView === 'edit' && (
        <EditVolumes
          isSavingOnEdit={isSavingOnEdit}
          idsForRemove={idsForRemove}
          isLoadingVolumeList={isLoadingVolumeList}
          volumeList={volumeList.parameters}
          onCreateParameter={handleCreateParameter}
          onEditParameter={handleEditParameter}
          onRemoveParameter={handleRemoveParameter}
          onCancelEditing={handleCancelEditing}
          onSaveEditing={handleSaveEditing}
        />
      )}

      <VolumeOverlay
        idsForRemove={idsForRemove}
        setValueModalType={setValueModalType}
        editData={parameterDataForEdit}
        parameters={volumeList.parameters}
        onCreateParameter={handleCreateParameter}
        allVolumes={volumeList.allVolumes}
        isOpen={isActiveCreateValueModal}
        onClose={() => setStateCreateValueModal(false)}
        isCreateValueType={valueType === 'create'}
        title={valueType === 'create' ? t('PARAMETER_CREATE') : t('PARAMETER_EDITING')}
      />
    </PaperLayout>
  );
});

export default AdministrationPage;
