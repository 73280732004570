import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  actionButton: {
    marginRight: 24,
    '&:last-child': {
      marginRight: 0,
    },
  },
  actionButtonsBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 24,
    backgroundColor: theme.palette.white,
    boxShadow: `0px 1px 0px ${theme.palette.midGray} inset`,
    zIndex: 3,
  },
  block: {
    borderBottom: `1px solid ${theme.palette.midGray}`,
    '&:last-child': {
      borderBottom: '0 none',
    },
  },
  blockHeader: {
    padding: '24px 24px 18px 24px',
  },
  photoBlockHeader: {
    padding: '24px 24px 18px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  photoLink: {
    color: theme.palette.primaryLight,
    fontSize: 16,
    padding: '0 24px',
    height: 48,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: theme.palette.lightSelection
    }
  },
  photoLinkIcon: {
    width: 20,
    height: 18,
    marginRight: 10,
    verticalAlign: 'middle'
  },
  blockHeaderLabel: {
    fontSize: 20,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.4,
    letterSpacing: 'normal',
    color: theme.palette.black,
  },
  twoColumns: {
    display: 'flex',
  },
  column: {
    flex: 1,
    padding: '0px 24px',
    maxWidth: '50%',
  },
  optionRenderer: {
    margin: '8px 0px 16px 0px',
  },
}));

export default useStyles;
