import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import useStores from 'Store/useStores';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import HeaderLayout from 'Common/widgets/Layout/Header/Header';
import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import DataTable from 'Common/components/DataTable/DataTable';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import * as routes from 'Shared/constants/routes';
import useManageEntityModal from 'Common/components/Modal/ManageEntityModal/useManageEntityModal';
import { useTranslation } from 'react-i18next';
import taskRoutesColumns from 'Business/coal/config/taskRoutesTableConfig';
import OverflowTip from 'Common/widgets/OverflowTip/OverflowTip';
import { INITIAL_PAGE_NUMBER } from 'Shared/constants/paging';

const TaskRouteList = observer(() => {
  const { t } = useTranslation();
  const translatedTaskRoutesColumns = taskRoutesColumns(t);
  const {
    coalRoutesListStore: {
      isDeleting,
      isLoading,
      isLoaded,
      routesList,
      deleteRoute,
      loadRoutesList,
      totalPages,
      isLoadingByPage,
      hasNextPage,
      pageNumber,
      pageSize,
      isRouteIncludedInActiveTask,
    } = {},
    notificationStore: { enqueueSnackbar },
  } = useStores();
  const history = useHistory();
  const {
    setManageEntityModalVisibility, setOnManageEntityModalCloseCallback, setManageEntityModalState, renderManageEntityModal
  } = useManageEntityModal();

  const loadList = (page, size) => {
    loadRoutesList(page, size).catch(() => {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('REQUEST_LOAD_LIST_ERROR'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
    });
  };

  const createHandler = () => {
    history.push({
      pathname: routes.CREATE_TASK_ROUTE
        .replace(':action(create)', 'create')
    });
  };

  const editHandler = (event, row) => {
    history.push({
      pathname: routes.EDIT_TASK_ROUTE
        .replace(':id', row.id)
        .replace(':action(edit)', 'edit')
    });
  };

  const onRouteClickHandler = (event, rowData = {}) => {
    if (rowData.id) {
      history.push({
        pathname: `${routes.TASK_ROUTES}/${rowData.id}`
      });
    }
  };

  const handleDeleteRoute = id => async () => {
    const { error } = (await deleteRoute(id)) || {};
    if (error) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('REQUEST_DEFAULT_ERROR'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('TASK_ROUTE_SUCCESSFULLY_REMOVED'),
              },
            ],
          },
        ],
      },
      variant: 'success',
    });
    loadList(pageNumber, pageSize);
  };

  const removeHandler = async (event, { id, name }) => {
    const isIncludedInActiveTask = await isRouteIncludedInActiveTask(id);
    setManageEntityModalState(() => ({
      title: t('DELETING_A_TASK_ROUTE'),
      entityName: <OverflowTip tooltipTitle={name} text={name} data-test="name" />,
      message: isIncludedInActiveTask ? `${t('THERE_ARE_OUTSTANDING_TASKS_ALONG_THIS_ROUTE')} ${t('ARE_YOU_SURE_YOU_WANT_TO_DELETE_TASK_ROUTE')}` : t('ARE_YOU_SURE_YOU_WANT_TO_DELETE_TASK_ROUTE'),
      type: 'action',
      actionButtonTitle: t('DELETE'),
    }));
    setOnManageEntityModalCloseCallback(() => handleDeleteRoute(id));
    setManageEntityModalVisibility(true);
  };

  useEffect(() => {
    loadList(INITIAL_PAGE_NUMBER);
  }, []);

  if (!isLoaded || isLoading) {
    return <ProgressBar />;
  }

  return (
    <>
      {(isDeleting || isLoadingByPage) && <ProgressBar />}
      <PaperLayout autoHeight>
        {isLoadingByPage && <ProgressBar />}
        <HeaderLayout title={t('ROUTES')} sticky>
          <HeaderButton title={t('CREATE_ROUTE')} type="add" handleClick={createHandler} />
        </HeaderLayout>
        <DataTable
          isDataLoaded={isLoaded}
          titleSingle={t('ROUTE').toLowerCase()}
          columns={translatedTaskRoutesColumns}
          data={routesList}
          onRowClick={onRouteClickHandler}
          onEdit={editHandler}
          onRemove={removeHandler}
          count={totalPages}
          hasNextPage={hasNextPage}
          onPageChange={loadList}
          onPerPageChange={loadList}
          dataTestRowSuffixEntityName="id"
          actions={['remove']}
        />
      </PaperLayout>
      {renderManageEntityModal()}
    </>
  );
});

TaskRouteList.propTypes = {};

export default TaskRouteList;
