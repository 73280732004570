import ParallelOperationsAgent from 'ApiAgents/Settings/ParallelOperationsAgent';
import {
  action, observable, runInAction, makeObservable,
} from 'mobx';
import { get } from 'lodash';


const parallelOperationsAgent = new ParallelOperationsAgent();

export class ParallelOperationsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable list = [];

  @observable id = null;

  @observable auartType = '';

  @observable parallelOperationCount = null;

  @observable isLoading = false;

  @observable isListLoaded = false;

  @observable isSaving = false;

  @observable isUpdating = false;

  @observable storedType = '';


  @action loadList = async () => {
    this.isLoading = true;
    this.isListLoaded = false;
    try {
      const data = await parallelOperationsAgent.loadList();
      const list = get(data, 'items');

      runInAction(() => {
        this.list = list;
        this.isLoading = false;
        this.isListLoaded = true;
      });
    } catch (error) {
      console.log('ERROR LOAD PARALLEL OPERATIONS: ', error);
      runInAction(() => {
        this.list = [];
        this.isLoading = false;
        this.isListLoaded = true;
      });
      throw new Error(error);
    }
  }

  @action save = async () => {
    this.isSaving = true;
    try {
      const { auartType, parallelOperationCount } = this;
      const res = await parallelOperationsAgent.save({
        auartType, parallelOperationCount
      });
      runInAction(() => {
        this.isSaving = false;
      });
      return { res };
    } catch (error) {
      console.log('ERROR SAVE PARALLEL OPERATIONS: ', error);
      runInAction(() => {
        this.isSaving = false;
      });
      return { error };
    }
  }

  @action update = async () => {
    this.isUpdating = true;
    try {
      const {
        id, auartType, parallelOperationCount
      } = this;
      const res = await parallelOperationsAgent.update({ id, auartType, parallelOperationCount });

      runInAction(() => {
        this.isUpdating = false;
      });
      return { res };
    } catch (error) {
      console.log('ERROR UPDATE PARALLEL OPERATIONS: ', error);
      runInAction(() => {
        this.isUpdating = false;
      });
      return { error };
    }
  }

  @action updateFormData = (field, value) => {
    this[field] = value;
  };

  @action resetFormData = () => {
    this.id = null;
    this.auartType = '';
    this.storedType = '';
    this.parallelOperationCount = null;
  };
}

export default ParallelOperationsStore;
