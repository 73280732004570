import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  executionResultsBlock: {
    // borderBottom: '1px solid #f3f3f3',
  },
  blockHeader: {
    padding: '24px 24px 18px 24px',
  },
  blockHeaderLabel: {
    fontSize: 20,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.4,
    letterSpacing: 'normal',
    color: theme.palette.black,
  },
  twoColumns: {
    display: 'flex',
  },
  column: {
    flex: 1,
    padding: '0px 24px',
  },
  optionRenderer: {
    margin: '8px 0px 16px 0px',
  },
  qrReadingStateBlock: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 24,
  },
  qrIcon: {
    height: 24,
    width: 24,
    marginRight: 10,
  },
  qrReadingStateLabel: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: theme.palette.black,
  },
  planedTime: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: theme.palette.black,
  },
  planedProgress: {
    marginBottom: 17,
  },
  actualProgress: {
    marginBottom: 9,
  },
  defectsBlock: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 24,
  },
  defectsIcon: {
    height: 24,
    width: 24,
    marginRight: 10,
    color: ({ hasDefects }) => (hasDefects ? theme.palette.negative : theme.palette.black20),
  },
  defectsLabel: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: theme.palette.black,
  },
}));

export default useStyles;
