import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
  sectionListRoot: {
    flexGrow: 1,
  },
}));

const SectionList = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.sectionListRoot} alignItems="stretch">
      {children}
    </Grid>
  );
};

SectionList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SectionList;
