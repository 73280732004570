import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';
import Input from 'Common/components/Forms/Input/Input';
import DateInput from 'Common/components/Forms/Input/Date';
import Panel, { PanelBox, PanelTitle } from 'Common/widgets/Layout/Panel/Panel';
import ActionButton from 'Common/components/Forms/Button/components/Action/Action';
import useStores from 'Store/useStores';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import { ORG_UNIT_SAP_CODE } from 'Shared/constants/dictionaries';

const TasksPerformanceReportScreen = () => {
  const {
    taskReportStore: {
      loadReportTypes,
      downloadReport,
      isLoading,
      isLoaded,
      isDownLoading,
      setState,
      reportTypesList,
      discardState,
      selectedState: {
        startDate,
        finishDate,
        reportType
      }
    },
    dictionaryStore: {
      loadDictionaryByKey,
      isLoading: isDictionaryLoading,
      isLoaded: isDictionaryLoaded
    },
    notificationStore: { enqueueSnackbar },
    validationStore: { validateRequiredFields, isValidFields, hasErrors },
    rootStore: {
      rootOrgunitName
    }
  } = useStores();

  const { t } = useTranslation();

  useEffect(() => {
    loadDictionaryByKey(ORG_UNIT_SAP_CODE);
    loadReportTypes();
    return () => discardState();
  }, []);

  if (isLoading || !isLoaded) {
    return <ProgressBar />;
  }

  if (isDictionaryLoading || !isDictionaryLoaded) {
    return <ProgressBar />;
  }

  const handleDownload = () => {
    validateRequiredFields();
    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{ rowContent: [{ type: 'text', text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS') }] }],
        },
        variant: 'error',
      });
      return;
    }
    downloadReport();
  };

  return (
    <>
      {isDownLoading && <ProgressBar />}
      <Panel
        mode="create"
        labelCreate={t('REPORT')}
        goBack={() => {}}
        labelGoToList={t('GO_BACK')}
        actions={[]}
        withActions={false}
        layoutStyles={{ minHeight: 'auto' }}
      >
        <PanelBox>
          <PanelTitle>{t('REPORT_ATTRIBUTES')}</PanelTitle>

          <Input
            label={t('THERMAL_POWER_PLANT')}
            value={rootOrgunitName}
            name="rootOrgUnitName"
            isReadonly
          />

          <Input
            label={t('REPORT_TYPE')}
            value={reportType}
            name="reportType"
            onChange={setState}
            type="select"
            options={reportTypesList}
            optionValueKey="type"
            optionTitleKey="title"
            required
          />
          <PanelTitle style={{ marginTop: 49 }}>{t('REPORT_PERIOD')}</PanelTitle>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <DateInput
                label={t('START_DATE')}
                name="startDate"
                value={startDate}
                maxDate={finishDate}
                onChange={value => setState(value, 'startDate')}
                required
                data-test="startDate"
              />
            </Grid>
            <Grid item xs={6}>
              <DateInput
                label={t('END_DATE')}
                name="finishDate"
                value={finishDate}
                minDate={startDate}
                onChange={value => setState(value, 'finishDate')}
                required
                data-test="finishDate"
              />
            </Grid>
          </Grid>

        </PanelBox>

        <PanelBox
          boxStyles={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            maxWidth: 'none'
          }}
        >
          <ActionButton
            text={t('DOWNLOAD_REPORT')}
            type="confirm"
            handleClick={handleDownload}
            data-test="download-report"
            style={{ margin: 19 }}
          />
        </PanelBox>
      </Panel>
    </>
  );
};

export default observer(TasksPerformanceReportScreen);
