import { makeStyles } from '@material-ui/core/styles';
import i18next from 'Shared/translations/i18n';

const useStyles = makeStyles(theme => ({
  isHighPriority: {
    color: theme.palette.primaryLight,
  },
  materialValue: {
    marginBottom: 24,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  panelBox: {
    paddingBottom: 24,
    marginBottom: 0,
    borderBottom: '1px solid #f3f3f3',
    '&:last-child': {
      paddingBottom: 121,
      borderBottom: '0px none',
    },
  },
  blockHeader: {
    padding: '24px 24px 18px 24px',
  },
  generalBlock: {
    // borderBottom: '1px solid #f3f3f3',
  },
  blockHeaderLabel: {
    fontSize: 20,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.4,
    letterSpacing: 'normal',
    color: theme.palette.black,
  },
  operationRow: {
    paddingTop: 0,
    paddingBottom: 8,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  operationRowWithMaterialValues: {
    paddingTop: 24,
    paddingBottom: 24,
    borderBottom: '1px solid #f3f3f3',
    '&:first-child': {
      borderTop: '1px solid #f3f3f3',
    },
    '&:last-child': {
      paddingBottom: 0,
      borderBottom: '0px none',
    },
  },
  executionResultsBlock: {
    // borderBottom: '1px solid #f3f3f3',
  },
  noMaterialsValuesLabel: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    textSlign: 'center',
    color: theme.palette.black30,
  },
  executionButton: {
    marginRight: 24,
    '&:last-child': {
      marginRight: 0,
    },
  },
  executionButtonsBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 24,
    position: 'fixed',
    bottom: 0,
    left: ({ isDrawerOpened }) => (`calc(${isDrawerOpened ? theme.drawerOpenedWidth : theme.drawerClosedWidth}px + 24px)`),
    right: '24px',
    backgroundColor: '#fff',
    boxShadow: '0px 1px 0px #f3f3f3 inset',
    zIndex: 3,
  },
  labelWithIconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  tasksTypeIcon: {
    marginRight: 4,
  },
  rejectionIcon: {
    marginRight: 8,
  },
  rejectionText: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: theme.palette.black,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  twoColumns: {
    display: 'flex',
  },
  column: {
    flex: 1,
    padding: '0px 24px',
  },
  optionRenderer: {
    margin: '8px 0px 16px 0px',
  },
  planedProgress: {
    marginBottom: 17,
  },
  actualProgress: {
    marginBottom: 9,
  },
  planedTime: {
    // display: 'flex',
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: theme.palette.black,
  },
  qrReadingStateBlock: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 24,
  },
  qrReadingStateLabel: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: theme.palette.black,
  },
  addEquipmentIcon: {
    marginRight: theme.spacing(1)
  },
  addAssignmentsButton: {
    boxShadow: 'none',
    minHeight: 80,
    marginBottom: theme.spacing(2),
    borderStyle: 'dashed !important',
    fontSize: 16,
    fontWeight: 'normal',
    '&::after': {
      display: 'block',
      content: `'${i18next.t('REQUIRED')}'`,
      position: 'absolute',
      height: '50%',
      background: theme.palette.white,
      top: 'calc(100% + 2px)',
      left: 16,
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.33,
      letterSpacing: 'normal',
      color: theme.palette.black30,
    },
  },
  readOnlyLabel: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: theme.palette.black,
  },
  priorityBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  qrBlock: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 24,
  },
  priorityIcon: {
    color: theme.palette.orange,
    height: 24,
    width: 24,
    marginRight: 8,
  },
  qrIcon: {
    height: 24,
    width: 24,
    marginRight: 10,
  },
  darkGrayColor: {
    color: theme.palette.black30,
  },
  orangeColor: {
    color: theme.palette.orange,
  },
  redColor: {
    color: theme.palette.negative,
  },
  blackColor: {
    color: theme.palette.black,
  },
  text: {
    whiteSpace: 'normal',
    overflowWrap: 'anywhere',
  },
  blockDescriptionLabel: {
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: theme.palette.black30,
  },
  operationsBlock: {
    marginBottom: 16,
  },
  operationsBlockItem: {
    marginBottom: 8,
  },
  equipmentSapCodeValueLabel: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: theme.palette.black,
  },
}));

export default useStyles;
