import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Done from '@material-ui/icons/Done';

import { ReactComponent as Arrow } from 'Assets/arrow-up.svg';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const RouteValidationBlock = ({ departmentId, routeId, hasErrors }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const departmentSelected = Boolean(departmentId);
  const routeSelected = Boolean(routeId);

  return (
    <div className={classes.root}>
      <div>
        <Arrow className={classes.arrow} />
        {t('CHOOSE_DEPARTMENT_AND_ROUTE')}
      </div>
      <div className={clsx(classes.item, hasErrors && !departmentSelected && classes.error)}>
        {departmentSelected && <Done className={classes.icon} />}
        {t('CHOOSE_DEPARTMENT')}
      </div>
      <div className={clsx(classes.item, hasErrors && !routeSelected && classes.error)}>
        {routeSelected && <Done className={classes.icon} />}
        {t('CHOOSE_ROUTE')}
      </div>
    </div>
  );
};

RouteValidationBlock.defaultProps = {
  departmentId: undefined,
  routeId: undefined,
  hasErrors: false,
};

RouteValidationBlock.propTypes = {
  departmentId: PropTypes.string,
  routeId: PropTypes.number,
  hasErrors: PropTypes.bool,
};

export default RouteValidationBlock;
