import qs from 'qs';
import Agent from 'ApiAgents/Agent';
import getPaginationQueryObject from 'Src/utils/getPaginationQueryObject';

export default class DefectsAgent extends Agent {
   loadList = (
     query = {},
     shouldPaginate,
     pageNumber,
     pageSize
   ) => {
     const pageQuery = getPaginationQueryObject(shouldPaginate, pageNumber, pageSize);

     return this.request({
       url: '/coalDefects',
       method: 'GET',
       params: { ...query, ...pageQuery },
       paramsSerializer: params => qs.stringify(params)
     }, 'techObject');
   };

   getDefectsByTechObjectId = id => (
     this.request({
       url: '/defects/view/byTechnicalObjectId',
       method: 'GET',
       params: { id }
     }, 'techObject')
   );

   getDefects = () => (
     this.request({
       url: '/defects/view/all',
       method: 'GET',
       params: { isStandard: true }
     }, 'techObject')
   );

   rejectDefect = data => (
     this.request({
       url: '/coalDefects/reject',
       method: 'PUT',
       params: { ...data }
     }, 'techObject')
   );

   confirmDefect = data => (
     this.request({
       url: '/coalDefects/approve',
       method: 'POST',
       params: { ...data }
     }, 'techObject')
   );

   getDefectsByTaskId = taskId => (
     this.request({
       url: '/coalDefects',
       method: 'GET',
       params: { taskId },
     }, 'techObject')
   );

   getDefectById = id => (
     this.request({
       url: '/coalDefects/details',
       method: 'GET',
       params: { id },
     }, 'techObject')
   );
}
