import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';

import HeaderLayout from 'Common/widgets/Layout/Header/Header';
import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';

const FullScreenOverlay = ({
  isOpen, title, onClose, children, classes, onSave, disabledClose, disabledSave, ...props
}) => (
  <Dialog
    fullScreen
    open={isOpen}
    className={classes}
    {...props}
  >
    <Grid
      container
      direction="column"
      wrap="nowrap"
      style={{ height: '100%' }}
    >
      <HeaderLayout title={title}>
        <HeaderButton type="close" handleClick={onClose} disabled={disabledClose} />
        <HeaderButton type="save" handleClick={onSave} disabled={disabledSave} />
      </HeaderLayout>
      {children}
    </Grid>
  </Dialog>
);

FullScreenOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  classes: PropTypes.string,
  disabledClose: PropTypes.bool,
  disabledSave: PropTypes.bool,
};
FullScreenOverlay.defaultProps = {
  classes: '',
  disabledClose: false,
  disabledSave: false,
};

export default FullScreenOverlay;
