import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Tooltip,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Box,
  Typography
} from '@material-ui/core/';
import {
  DeleteOutline,
  Edit,
} from '@material-ui/icons';
import { operationTypeTitles } from 'Business/tpsInspections/config/operationTypeMapping';

import useStyles from './styles';

const EquipmentBox = ({
  title,
  withButtons,
  actions,
  onEditButtonClick,
  onDeleteButtonClick,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.cardHeader}
        classes={{ title: classes.cardHeaderTitle }}
        action={withButtons ? (
          <>
            <Tooltip title={t('EDIT')}>
              <IconButton
                className={classes.editButton}
                size="small"
                aria-label="settings"
                onClick={onEditButtonClick}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('DELETE')}>
              <IconButton
                className={classes.deleteButton}
                size="small"
                aria-label="settings"
                onClick={onDeleteButtonClick}
              >
                <DeleteOutline />
              </IconButton>
            </Tooltip>
          </>
        ) : null}
        title={title}
      />
      <CardContent style={{
        padding: 0
      }}
      >
        {Object.entries(actions).map(([operationType, operations]) => (
          <Fragment key={operationType}>
            <div className={classes.cardSubTitle}>{t(operationTypeTitles[operationType])}</div>
            {operations.map(({ id, title: operationTitle }) => (
              <Box
                key={id}
                className={classes.cardActionBox}
              >
                <Typography style={{
                  fontSize: 16,
                  fontWeight: 'bold'
                }}
                >
                  {operationTitle}
                </Typography>
              </Box>
            ))}
          </Fragment>
        ))}
      </CardContent>
    </Card>
  );
};

EquipmentBox.propTypes = {
  title: PropTypes.string.isRequired,
  withButtons: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  actions: PropTypes.object.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  onDeleteButtonClick: PropTypes.func.isRequired,
};

EquipmentBox.defaultProps = {
  withButtons: false,
};

export default EquipmentBox;
