import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import Grid from '@material-ui/core/Grid';
import { formatDate } from 'Src/utils/datetime';
import DateInput from 'Common/components/Forms/Input/Date';
import TextInput from 'Common/components/Forms/Input/Text';
import SelectAssigneeControl from 'Business/tpsRepairs/components/SelectAssigneeControl';
import getDictionaryTitle from 'Business/tpsRepairs/utils/getDictionaryTitle';

import useStyles from './styles';

const ConsumptionsControlTaskAttributesBlock = ({
  task = {},
  dictionaries,
  materials,
  isEditableDate, // eslint-disable-line
  isEditableCurrentAssignee,
  assignee,
  handleAssigneeChange,
  taskPlannedStartDate,
  setTaskPlannedStartDate
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    title,
    sapCode,
    taskType,
    rootTechnicalObjectIds,
    expectedStartDate,
    expectedFinishDate,
    currentAssignee,
  } = task;

  const inputReadOnlyValue = '—';

  return (
    <Grid container wrap="nowrap" spacing={2} className={classes.wrapperContent}>
      <Grid item xs={12}>
        <TextInput
          name="title"
          label={t('NAME')}
          value={title}
          isReadonly
          data-test="title"
        />
        <TextInput
          name="rootTechnicalObjectIds"
          label={t('EQUIPMENT')}
          value={rootTechnicalObjectIds.map(id => (materials[id] ? materials[id].title : '')).join(', ') || inputReadOnlyValue}
          isReadonly
          data-test="rootTechnicalObjectIds"
        />
        <DateInput
          label={t('DATE_OF_COMPLETION')}
          value={taskPlannedStartDate}
          name="plannedStartDate"
          disablePast
          onChange={setTaskPlannedStartDate}
          maxWidth={400}
          isReadonly={!isEditableDate}
          data-test="plannedStartDate"
        />
        {isEditableCurrentAssignee ? (
          <SelectAssigneeControl
            setAssignee={(id, name) => handleAssigneeChange(id, name, true)}
            assigneeId={assignee.assigneeId}
            style={{
              marginBottom: 16,
              maxWidth: 400
            }}
          />
        ) : (
          <TextInput
            name="assigneeId"
            label={t('EMPLOYEE')}
            value={!isEmpty(currentAssignee) ? currentAssignee.identityName : ''}
            isReadonly
            data-test="assigneeId"
          />
        )}
      </Grid>
      <Grid item xs={12}>
        {
          rootTechnicalObjectIds.map(id => (materials[id] && materials[id].technicalObjectType === 'TECHNICAL_PLACE' && (
            <TextInput
              key={id}
              label={t('FUNCTIONAL_PLACEMENT')}
              value={materials[id].sapCode}
              name="technicalPlace"
              isReadonly
              data-test="technicalPlace"
            />
          )))
        }
        <TextInput
          label={t('ORDER_TYPE')}
          value={getDictionaryTitle(dictionaries, 'TaskType', taskType)}
          name="taskType"
          isReadonly
          data-test="taskType"
        />
        <TextInput
          name="sapCode"
          label={t('ORDER_NUMBER')}
          value={sapCode}
          isReadonly
          data-test="sapCode"
        />
        <TextInput
          label={t('PLANNED_START_DATE')}
          value={formatDate(expectedStartDate)}
          name="expectedStartDate"
          isReadonly
          data-test="expectedStartDate"
        />
        <TextInput
          label={t('PLANNED_COMPLETION_DATE')}
          value={formatDate(expectedFinishDate)}
          name="expectedFinishDate"
          isReadonly
          data-test="expectedFinishDate"
        />
      </Grid>
    </Grid>
  );
};

ConsumptionsControlTaskAttributesBlock.defaultProps = {
  assignee: {},
  isEditableDate: false,
  isEditableCurrentAssignee: false,
  materials: {},
  dictionaries: {},
  handleAssigneeChange: () => {},
  taskPlannedStartDate: '',
  setTaskPlannedStartDate: () => {},
};

ConsumptionsControlTaskAttributesBlock.propTypes = {
  assignee: PropTypes.shape({
    assigneeId: PropTypes.string,
  }),
  taskPlannedStartDate: PropTypes.string,
  setTaskPlannedStartDate: PropTypes.func,
  handleAssigneeChange: PropTypes.func,
  isEditableDate: PropTypes.bool,
  isEditableCurrentAssignee: PropTypes.bool,
  task: PropTypes.shape({
    title: PropTypes.string,
    sapCode: PropTypes.string,
    taskType: PropTypes.string,
    rootTechnicalObjectIds: PropTypes.arrayOf(PropTypes.number),
    expectedStartDate: PropTypes.string,
    expectedFinishDate: PropTypes.string,
    currentAssignee: PropTypes.shape({
      identityId: PropTypes.string,
      identityName: PropTypes.string,
    }),
  }).isRequired,
  materials: PropTypes.objectOf(PropTypes.shape({
    title: PropTypes.string,
    technicalObjectType: PropTypes.string,
    sapCode: PropTypes.string,
  })),
  dictionaries: PropTypes.shape({
    TaskStatus: PropTypes.objectOf(PropTypes.string),
    TaskType: PropTypes.objectOf(PropTypes.string),
  }),
};

export default ConsumptionsControlTaskAttributesBlock;
