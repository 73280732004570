import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import Grid from '@material-ui/core/Grid';
import OptionRenderer from 'Common/components/Forms/OptionRenderer';
import DetailsBlock from 'Common/components/Forms/DetailsBlock';
import TaskProgress from 'Common/components/TaskProgress';
import { taskStatusAliases } from 'Src/business/tpsRepairs/config/taskStatusAliases';
import { REASON_OTHER } from 'Src/shared/constants/reasons';
import { formatDate } from 'Src/utils/datetime';
import { taskStatuses } from 'Shared/constants/taskStatuses';
import useStyles from './styles';

const TaskExecutionResultBlock = ({
  reasons, task
}) => {
  const { t } = useTranslation();
  const translatedTaskStatuses = taskStatuses(t);
  const classes = useStyles();

  const {
    taskStatus: status,
    taskRejectionReason,
    rejectReasonComment,
    planDuration = 0,
    factDuration,
    factStartDate,
    factFinishDate,
  } = task;

  const taskStatus = status && status.toLowerCase();
  const shouldDisplayRejectReason = () => (
    [taskStatusAliases(it => it).CANCELED.name, taskStatusAliases(it => it).ASSIGNED.name, taskStatusAliases(it => it).ALL.name].includes(taskStatus)
    && taskRejectionReason
  );

  const getProgressConfig = () => {
    const basis = factDuration > planDuration ? factDuration : planDuration;
    const factDurationPercentage = factDuration / basis * 100;
    const planDurationPercentage = planDuration / basis * 100;

    return {
      factDurationPercentage,
      planDurationPercentage,
    };
  };

  return (
    <Grid
      container
      spacing={1}
      className={classes.taskExecutionResultBlock}
    >
      <Grid item xs={6}>
        <DetailsBlock>
          <OptionRenderer
            value={translatedTaskStatuses[status] ? translatedTaskStatuses[status] : translatedTaskStatuses.UNKNOWN}
            title={t('STATUS')}
            name="taskStatus"
            data-test="taskStatus"
          />
          {shouldDisplayRejectReason()
          && (
            <OptionRenderer
              value={taskRejectionReason === REASON_OTHER ? rejectReasonComment : reasons[taskRejectionReason]}
              title={t('REASON_FOR_REFUSAL_TO_EXECUTE')}
              data-test="taskRejection"
            />
          )}
        </DetailsBlock>
      </Grid>
      <Grid item xs={6}>
        <DetailsBlock>
          {factDuration > 0
            ? (
              <TaskProgress
                planDuration={planDuration}
                factDuration={factDuration}
                {...getProgressConfig()}
              />
            )
            : (
              <OptionRenderer
                value={`${planDuration} ${t('MIN')}`}
                title={t('PLANNED_LEAD_TIME')}
                data-test="planDuration"
              />
            )}
          <Grid container justify="space-between">
            {!isEmpty(factStartDate) && (
              <OptionRenderer
                value={formatDate(factStartDate)}
                title={t('FACT_START_DATE')}
                data-test="factStartDate"
              />
            )}
            {!isEmpty(factFinishDate) && (
              <OptionRenderer
                value={formatDate(factFinishDate)}
                title={t('FACT_FINISH_DATE')}
                titlePosition="right"
                data-test="factFinishDate"
              />
            )}
          </Grid>
        </DetailsBlock>
      </Grid>
    </Grid>
  );
};

TaskExecutionResultBlock.defaultProps = {
  reasons: {},
  dictionaries: {},
};

TaskExecutionResultBlock.propTypes = {
  task: PropTypes.shape({
    taskStatus: PropTypes.string,
    taskRejectionReason: PropTypes.string,
    rejectReasonComment: PropTypes.string,
    planDuration: PropTypes.number,
    factDuration: PropTypes.number,
    factStartDate: PropTypes.string,
    factFinishDate: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  reasons: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  dictionaries: PropTypes.object,
};

export default TaskExecutionResultBlock;
