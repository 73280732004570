import Agent from 'ApiAgents/Agent';
import getPaginationQueryObject from 'Src/utils/getPaginationQueryObject';
import qs from 'qs';

export default class DefectsDictionatyAgent extends Agent {
  loadDefectsGroups = () => (
    this.request({
      url: '/defectsFolders',
      method: 'GET',
      params: { ks: [9] },
      paramsSerializer: params => qs.stringify(params),
    }, 'techObject')
  );

  loadDefectsByGroup = (
    {
      groupId,
      shouldPaginate,
      pageNumber,
      perPage
    }
  ) => {
    const pageQuery = getPaginationQueryObject(shouldPaginate, pageNumber, perPage);
    return (
      this.request({
        url: '/defects/view',
        method: 'GET',
        params: { folderIds: [groupId], ...pageQuery },
        paramsSerializer: params => qs.stringify(params)
      }, 'techObject')
    );
  };

  getDefectByDefectId = defectId => (
    this.request({
      url: '/defects/view',
      method: 'GET',
      params: { id: defectId },
    }, 'techObject')
  );

  updateDefectData = ({ defectId, userText, isUnconstrained } = {}) => (
    this.request({
      url: '/defects/manage/update',
      method: 'POST',
      data: { defectId, userText, isUnconstrained },
    }, 'techObject')
  );
}
