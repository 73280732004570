import {
  action,
  computed,
  runInAction,
  makeObservable,
} from 'mobx';
import { get, isNull, isNaN } from 'lodash';
import TaskReportAgent from 'ApiAgents/Reports/TaskReportAgent';

const initialState = {
  isLoading: true,
  isRequestingReport: false,
  tpsId: null,
  tpsName: '',
  powerUnits: [],
  powerUnitId: null,
  shiftId: null,
  state: null,
};

const taskReportAgent = new TaskReportAgent();

export class RequestReportStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
    this.rootStore.prepareState(initialState, this);
  }

  isLoading;

  isRequestingReport;

  tpsId;

  tpsName;

  powerUnits;

  shiftId;

  powerUnitId;

  state;

  @action loadReportTypeData = async () => {
    try {
      const [reportTypeData] = await taskReportAgent.getTpsInspectionReportType() || [];
      const tpsId = get(reportTypeData, 'tpsId');
      const tpsName = get(reportTypeData, 'tpsName');
      const powerUnits = get(reportTypeData, 'powerUnits');
      runInAction(() => {
        this.tpsId = tpsId;
        this.tpsName = tpsName;
        this.powerUnits = powerUnits;
        this.isLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

@action powerUnitOnChange = (powerUnit) => {
  const powerUnitId = get(powerUnit, 'id', null);
  runInAction(() => {
    this.powerUnitId = powerUnitId;
  });
};

@action shiftOnChange = (shiftId) => {
  runInAction(() => {
    this.shiftId = shiftId;
  });
};

@action stateOnChange = (state) => {
  runInAction(() => {
    this.state = state;
  });
};

@action discardState = () => {
  runInAction(() => {
    this.tpsId = null;
    this.powerUnitId = null;
    this.shiftId = null;
    this.state = null;
  });
};

@computed get canMakeRequest() {
  return !isNull(this.tpsId)
    && !isNull(this.powerUnitId)
    && !isNull(this.shiftId)
    && !isNull(this.state)
    && !isNaN(+this.state);
}

@action createTpsInspectionReport = async () => {
  this.isRequestingReport = true;
  try {
    const res = await taskReportAgent.createTpsInspectionReport({
      tpsId: this.tpsId,
      powerUnitId: this.powerUnitId,
      shift: this.shiftId,
      state: +this.state,
    });

    runInAction(() => {
      this.isRequestingReport = false;
    });
    return { res };
  } catch (error) {
    console.log('ERROR IN DOWNLOADING REPORT: ', error);
    runInAction(() => {
      this.isRequestingReport = false;
    });
    return { error };
  }
};
}

export default RequestReportStore;
