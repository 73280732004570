import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import {
  CheckCircleOutline, HighlightOff, Add, Edit, DeleteOutline,
} from '@material-ui/icons';

import Header from 'Common/widgets/Layout/Header/Header';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import VolumesList from 'Business/plant/pages/AdministrationPage/components/VolumesList';
import SortedDragList from 'Business/plant/pages/AdministrationPage/components/SortedDragList';
import useManageEntityModal from 'Common/components/Modal/ManageEntityModal/useManageEntityModal';

import useStyles from './styles';

const EditVolumes = ({
  onCancelEditing, volumeList, onCreateParameter, onEditParameter, onRemoveParameter,
  onSaveEditing, isLoadingVolumeList, idsForRemove, isSavingOnEdit,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    setManageEntityModalVisibility, setOnManageEntityModalCloseCallback, setManageEntityModalState, renderManageEntityModal // eslint-disable-line
  } = useManageEntityModal();
  const [sortedVolumes, setVolumesSort] = useState(volumeList);

  useEffect(() => {
    setVolumesSort(volumeList);
  }, [volumeList]);

  const handleSaveEditing = () => {
    const sortedData = sortedVolumes.map((item, index) => ({
      id: item.id,
      order: index,
    }));

    onSaveEditing(sortedData);
  };

  const handleUpdateList = (parameters) => {
    setVolumesSort(parameters.map(item => item.parameter));
  };

  const filterFromDeleted = (item) => {
    const shouldDeleted = idsForRemove.some(it => it === item.id);

    return !shouldDeleted;
  };

  const removeHandler = (item) => {
    const parameterName = get(item, 'parameter.parameterName', '');
    const id = get(item, 'id', '');
    setManageEntityModalState(() => ({
      title: `${t('DELETING')} ${t('PARAMETER_GENITIVE')}`,
      entityName: `${'PARAMETER'} "${parameterName}"`,
      message: t('CONFIRM_DELETING_PARAMETER_MESSAGE'),
      type: 'action',
      actionButtonTitle: t('DELETE'),
    }));
    setOnManageEntityModalCloseCallback(() => () => onRemoveParameter(id));
    setManageEntityModalVisibility(true);
  };

  return (
    <div>
      {isSavingOnEdit && <ProgressBar />}
      <Header title={t('VOLUME_PAGE_TITLE')}>
        <Button
          color="primary"
          variant="text"
          onClick={onCancelEditing}
          startIcon={<HighlightOff />}
        >
          {t('CANCEL')}
        </Button>
        <Button
          color="primary"
          variant="text"
          onClick={handleSaveEditing}
          startIcon={<CheckCircleOutline />}
        >
          {t('SAVE')}
        </Button>
      </Header>

      <div className={classes.container}>
        <div className={classes.subtitle}>{t('VOLUME_TAB')}</div>

        {isLoadingVolumeList ? <ProgressBar /> : (
          <>
            <VolumesList
              volumeList={sortedVolumes.filter(filterFromDeleted)}
              render={parameters => (
                <SortedDragList
                  list={parameters}
                  handleUpdateList={handleUpdateList}
                  itemTemplate={item => item.view}
                  renderRightControls={item => (
                    <div>
                      <Tooltip title={t('PARAMETER_EDIT')}>
                        <IconButton
                          size="small"
                          aria-label="edit"
                          className={classes.editParameterIcon}
                          onClick={() => onEditParameter(item.parameter)}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t('PARAMETER_DELETE')}>
                        <IconButton
                          size="small"
                          aria-label="delete"
                          className={classes.removeParameterIcon}
                          onClick={() => removeHandler(item)}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                />
              )}
            />
            <Button
              variant="outlined"
              color="primary"
              className={classes.addCheckpointButton}
              onClick={onCreateParameter}
              fullWidth
              startIcon={<Add />}
            >
              {t('PARAMETER_ADD')}
            </Button>
          </>
        )}
      </div>
      {renderManageEntityModal()}
    </div>
  );
};

EditVolumes.propTypes = {
  isLoadingVolumeList: PropTypes.bool.isRequired,
  isSavingOnEdit: PropTypes.bool.isRequired,
  onCancelEditing: PropTypes.func.isRequired,
  onSaveEditing: PropTypes.func.isRequired,
  onEditParameter: PropTypes.func.isRequired,
  onCreateParameter: PropTypes.func.isRequired,
  onRemoveParameter: PropTypes.func.isRequired,
  volumeList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number
  })).isRequired,
  idsForRemove: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default EditVolumes;
