import Agent from 'ApiAgents/Agent';
import getPaginationQueryObject from 'Src/utils/getPaginationQueryObject';
import qs from 'qs';


export default class ControlAgent extends Agent {
  getTaskById = (
    params = {},
  ) => (
    this.request({
      url: '/control/details',
      method: 'GET',
      params: { ...params }
    },
    'tasks'));

  getHistory= (
    query = {},
  ) => (
    this.request({
      url: '/control/history',
      method: 'GET',
      params: query,
    },
    'tasks'));

  loadControlList = (
    query = '',
    shouldPaginate,
    pageNumber,
    pageSize
  ) => {
    const pageQuery = getPaginationQueryObject(shouldPaginate, pageNumber, pageSize);

    return this.request({
      url: '/control',
      method: 'GET',
      params: { ...query, ...pageQuery },
      paramsSerializer: params => qs.stringify(params)
    }, 'tasks');
  };

  setMaterialValues = (
    operations = [],
  ) => this.request({
    url: '/control/setMaterialValues',
    method: 'POST',
    data: { operations },
  }, 'tasks');

  confirmMaterialValues = data => this.request({
    url: '/control/approve',
    method: 'POST',
    data
  }, 'tasks');

  rejectMaterialValues = data => this.request({
    url: '/control/reject',
    method: 'POST',
    data,
  }, 'tasks');
}
