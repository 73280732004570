import React from 'react';
import PropTypes from 'prop-types';
import { CancelRounded, CheckCircle } from '@material-ui/icons';
import { ReactComponent as PhotoPlaceholder } from 'Src/assets/photo-placeholder.svg';
import useStyles from './styles';


const UserImage = ({
  disabled, isUserInSystem, photo, fullName
}) => {
  const classes = useStyles({ disabled, isUserInSystem });

  const renderStatusIcon = () => {
    if (!isUserInSystem) return null;
    if (disabled) return <CancelRounded className={classes.userImageIcon} />;
    return <CheckCircle className={classes.userImageIcon} />;
  };

  return (
    <div className={classes.userImage}>
      {photo ? (
        <img src={photo} alt={fullName} />
      ) : (
        <PhotoPlaceholder className={classes.placeholder} />
      )}
      {renderStatusIcon()}
    </div>
  );
};

UserImage.defaultProps = {
  disabled: false,
  isUserInSystem: false,
  photo: undefined,
  fullName: '',
};

UserImage.propTypes = {
  disabled: PropTypes.bool,
  isUserInSystem: PropTypes.bool,
  photo: PropTypes.string,
  fullName: PropTypes.string,
};

export default UserImage;
