import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';

import actionButtonTypes from 'Common/components/Forms/Button/configs/actionButtonTypes';


const useStyles = makeStyles(theme => ({
  buttonBase: {
    paddingLeft: 24,
    paddingRight: 24,
    minWidth: 144,
  },
  [actionButtonTypes.CONFIRM]: {
    backgroundColor: theme.palette.blue,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.lightPrimaryHover
    }
  },
  [actionButtonTypes.CANCEL]: {
    backgroundColor: 'white',
    color: theme.palette.blue,
    border: '1px solid',
    borderColor: theme.palette.blue,
    '&:hover': {
      backgroundColor: theme.palette.lightSelection
    }
  },
  [actionButtonTypes.MINIMISED]: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: theme.palette.blue,
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    }
  },
  [actionButtonTypes.STRONG]: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    backgroundColor: theme.palette.default,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.black20}`,
    color: theme.palette.blue,
    '&:hover': {
      backgroundColor: theme.palette.lightSelection,
      boxShadow: 'none',
    }
  },
  disabled: {
    '&:disabled': {
      backgroundColor: theme.palette.black20,
      color: 'white',
    }
  }
}));

const ActionButton = ({
  type, handleClick, value, text, className, ...props
}) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      disableRipple={type === actionButtonTypes.MINIMISED}
      className={
        clsx(
          classes.buttonBase,
          classes[type],
          className,
          classes.disabled
        )
      }
      onClick={handleClick}
      value={value}
      {...props}
    >
      {text}
    </Button>
  );
};

ActionButton.propTypes = {
  type: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
};

ActionButton.defaultProps = {
  value: '',
  className: '',
};

export default ActionButton;
