import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  boxes: {
    // minHeight: 200,
    // backgroundColor: 'red',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: 1,
      height: '100%',
      top: 0,
      left: theme.spacing(1),
      background: theme.palette.gray,
      // zIndex: 1,
    }
  },
  boxesClicked: {
    '& > .MuiBox-root': {
      '&::before': {
        display: 'none !important'
      },
    }
  },
  boxWrapperDrag: {
    '&::before': {
      display: 'none !important'
    },
    '&::after': {
      transform: 'translate(0, -50%) scale(1.25)'
    },
    '& > .MuiBox-root': {
      boxShadow: '0 5px 10px 0 rgba(0,0,0, .1)',
    }
  },
}));

export default useStyles;
