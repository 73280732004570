import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import * as routes from 'Shared/constants/routes';
import BrigadesListScreen from 'Src/business/tpsRepairs/pages/BrigadesList';
import BrigadeDetailScreen from 'Src/business/tpsRepairs/pages/BrigadeDetails';
import BrigadesManageContainer from 'Src/business/tpsRepairs/containers/BrigadesManageContainer';
import TasksScreen from 'Src/business/tpsRepairs/pages/TasksList';
import Task from 'Src/business/tpsRepairs/pages/Task';
import ReportCategoriesScreen from 'Src/business/tpsRepairs/pages/ReportCategories/ReportCategoriesScreen';
import TasksPerformanceReportScreen from 'Src/business/tpsRepairs/pages/TasksPerformanceReport/TasksPerformanceReportScreen';
import ConsumptionsControlList from 'Src/business/tpsRepairs/pages/ConsumptionsControlList';
import ConsumptionsControl from 'Src/business/tpsRepairs/pages/ConsumptionsControl';
import PrivateRoute from 'Common/components/Authorization/PrivateRoute';
import {
  TASKS_VIEW,
  TASK_CREATE,
  TASK_VIEW,
  BRIGADES_VIEW,
  BRIGADE_VIEW,
  BRIGADE_CREATE,
  BRIGADE_EDIT,
  STATISTICS_VIEW,
  TASK_PERFORMANCE_STATISTICS_VIEW,
  CONTROL_VIEW,
} from 'Src/RBAC/businessPermissions';
import CommonRoutes from 'Src/routes/CommonRoutes';

const TPSRepairRoutes = () => (
  <CommonRoutes>
    {/* Tasks */}
    <Route exact path={`${routes.HOME}`}>
      <Redirect to={routes.TASKS} />
    </Route>
    <PrivateRoute
      exact
      path={routes.CREATE_TASK}
      component={props => <Task {...props} typeRender="create" />}
      permission={TASK_CREATE}
    />
    <PrivateRoute
      exact
      path={`${routes.VIEW_TASK}/:id/:tab?`}
      component={props => <Task {...props} />}
      permission={TASK_VIEW}
    />
    <PrivateRoute path={routes.TASKS} component={TasksScreen} permission={TASKS_VIEW} />

    {/* Control Material Values */}
    <PrivateRoute
      exact
      path={`${routes.CONTROL}/viewTask/:id`}
      component={props => <Task {...props} />}
      permission={CONTROL_VIEW}
    />
    <PrivateRoute
      exact
      path={`${routes.CONTROL}/:operationStatus/:id/:tab?`}
      component={ConsumptionsControl}
      permission={CONTROL_VIEW}
    />
    <PrivateRoute
      path={`${routes.CONTROL}`}
      component={ConsumptionsControlList}
      permission={CONTROL_VIEW}
    />

    {/* Brigades */}
    <PrivateRoute
      exact
      path={routes.CREATE_BRIGADE}
      component={BrigadesManageContainer}
      permission={BRIGADE_CREATE}
    />
    <PrivateRoute
      exact
      path={`${routes.EDIT_BRIGADE}/:id`}
      component={BrigadesManageContainer}
      permission={BRIGADE_EDIT}
    />
    <PrivateRoute
      exact
      path={`${routes.BRIGADE_DETAILS}/:id`}
      component={BrigadeDetailScreen}
      permission={BRIGADE_VIEW}
    />
    <PrivateRoute
      path={routes.BRIGADES}
      component={BrigadesListScreen}
      permission={BRIGADES_VIEW}
    />

    {/* Statistics */}
    <PrivateRoute
      exact
      path={routes.STATISTICS}
      component={ReportCategoriesScreen}
      permission={STATISTICS_VIEW}
    />
    <PrivateRoute
      exact
      path={routes.TASKS_PERFORMANCE_STATISTICS}
      component={TasksPerformanceReportScreen}
      permission={TASK_PERFORMANCE_STATISTICS_VIEW}
    />
  </CommonRoutes>
);

export default TPSRepairRoutes;
