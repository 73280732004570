import {
  isArray, find, isUndefined, endsWith, get
} from 'lodash';

const getAggregatedMaterialValuesByOperations = (operations) => {
  const materialValuesAcc = [];
  operations.forEach((operation = {}) => {
    const {
      materialValues,
      materialValueConsumptions,
    } = operation;

    if (isArray(materialValues)) {
      materialValues.forEach((materialValue) => {
        const { materialValueId, sapCode, quantity, updatedQuantity } = materialValue; // eslint-disable-line
        const hasUpdatedQuantity = Boolean(operations.some(
          ({ materialValues: operationMaterialValues } = {}) => operationMaterialValues.some(
            ({ sapCode: operationMaterialValueSapCode, updatedQuantity: operationMaterialValueUpdatedQuantity } = {}) => !isUndefined(operationMaterialValueUpdatedQuantity) && operationMaterialValueSapCode === sapCode
          )
        ) || get(find(materialValuesAcc, { sapCode }), 'updatedQuantity'));
        const factQuantity = materialValueConsumptions.reduce((acc, consumptionMaterial) => ((endsWith(consumptionMaterial.sapCode, sapCode)) ? consumptionMaterial.quantity : acc), 0);
        const alreadyInMaterialValuesAcc = find(materialValuesAcc, { sapCode });
        const mergedQuantityPart = !isUndefined(updatedQuantity) ? updatedQuantity : factQuantity;
        if (!alreadyInMaterialValuesAcc) {
          materialValuesAcc.push({
            ...materialValue,
            mergedQuantity: !isUndefined(updatedQuantity) ? updatedQuantity : factQuantity,
            factQuantity,
            ...hasUpdatedQuantity && { updatedQuantity: mergedQuantityPart },
          });
        }
        if (alreadyInMaterialValuesAcc) {
          alreadyInMaterialValuesAcc.quantity = parseFloat((+alreadyInMaterialValuesAcc.quantity + quantity).toFixed(10));
          alreadyInMaterialValuesAcc.factQuantity = parseFloat((+alreadyInMaterialValuesAcc.factQuantity + factQuantity).toFixed(10));
          alreadyInMaterialValuesAcc.mergedQuantity = parseFloat((+alreadyInMaterialValuesAcc.mergedQuantity + +mergedQuantityPart).toFixed(10));
          if (hasUpdatedQuantity) {
            if (!isUndefined(updatedQuantity)) {
              alreadyInMaterialValuesAcc.updatedQuantity = parseFloat((parseFloat(alreadyInMaterialValuesAcc.updatedQuantity || 0) + parseFloat(updatedQuantity || 0)).toFixed(10));
            } else {
              alreadyInMaterialValuesAcc.updatedQuantity = parseFloat((parseFloat(alreadyInMaterialValuesAcc.updatedQuantity || 0) + parseFloat(factQuantity || 0)).toFixed(10));
            }
          }
        }
      });
    }
  });
  return materialValuesAcc;
};

export default getAggregatedMaterialValuesByOperations;
