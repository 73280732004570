import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Visibility } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import useStyles from './styles';

const KeysEnabler = ({ keyList, setSelectedKeys, hasSomeSelectedKey }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const handleKeyClick = (key) => {
    const newKeyList = keyList.map((item) => {
      if (key.title === item.title) {
        return {
          ...item,
          enable: !key.enable,
        };
      }

      return item;
    });

    setSelectedKeys(newKeyList);
  };

  const handleSwitchAllParameters = () => {
    if (!hasSomeSelectedKey) return;

    setSelectedKeys(keyList.map(item => ({
      ...item,
      enable: false,
    })));
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.subtitle}>{`${t('DISPLAY_ON_DEVICE_BY_KEY')}:`}</div>
      <>
        <IconButton
          color="default"
          onClick={handleSwitchAllParameters}
          size="medium"
        >
          <Visibility color={hasSomeSelectedKey ? 'inherit' : 'primary'} />
        </IconButton>
        {t('ALL_PARAMS')}
      </>
      {keyList.map(key => (
        <div key={key.title}>
          <IconButton
            color="default"
            onClick={() => handleKeyClick(key)}
            size="medium"
          >
            <Visibility color={key.enable ? 'primary' : 'inherit'} />
          </IconButton>
          {key.title}
        </div>
      ))}
    </div>
  );
};

KeysEnabler.propTypes = {
  keyList: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    enable: PropTypes.bool.isRequired,
  })).isRequired,
  setSelectedKeys: PropTypes.func.isRequired,
  hasSomeSelectedKey: PropTypes.bool.isRequired,
};

export default KeysEnabler;
