import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { get, isEmpty, find } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Input from 'Common/components/Forms/Input/Input';
import RadioInput from 'Common/components/Forms/Input/Radio';
import Panel, { PanelBox } from 'Common/widgets/Layout/Panel/Panel';
import ActionButton from 'Common/components/Forms/Button/components/Action/Action';
import useStores from 'Store/useStores';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import OptionRenderer from 'Common/components/Forms/OptionRenderer';
import { formatDate } from 'Src/utils/datetime';
import powerUnitStates from 'Src/shared/constants/powerUnitStates';
import { REPORT_NOT_FOUND, REPORT_ALREADY_EXISTS } from 'Shared/constants/responseErrors/errors';
import { MEASUREMENT_REPORTS, CREATE_REPORT } from 'Shared/constants/routes';
import { isWithinInterval } from 'date-fns';
import useStyles from './styles';

const RequestReport = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();

  const {
    tpsRequestReportStore: {
      isLoading,
      isRequestingReport,
      tpsName,
      powerUnits,
      loadReportTypeData,
      powerUnitId,
      powerUnitOnChange,
      shiftOnChange,
      shiftId,
      stateOnChange,
      state,
      canMakeRequest,
      createTpsInspectionReport,
      discardState,
    },
    measurementReportListStore: {
      getReportShifts,
      shifts = [],
      isShiftsLoaded,
    },
    notificationStore: {
      enqueueSnackbar,
    },
  } = useStores();

  const translatedPowerUnitStates = powerUnitStates(t);
  const getNotValidTimeErrorText = (currentShiftId) => {
    const currentShift = find(shifts, { id: currentShiftId }) || {};
    const {
      shiftName,
      availablePeriodStartHour = 0,
      availablePeriodStartMinutes = 0,
      availablePeriodEndHour = 0,
      availablePeriodEndMinutes = 0,
    } = currentShift;
    const startInterval = `${availablePeriodStartHour}:${availablePeriodStartMinutes >= 10 ? availablePeriodStartMinutes : `${availablePeriodStartMinutes}0`}`;
    const endInterval = `${availablePeriodEndHour}:${availablePeriodEndMinutes >= 10 ? availablePeriodEndMinutes : `${availablePeriodEndMinutes}0`}`;
    const errorText = `${t('REPORT_CREATION_IMPOSSIBLE')} ${t('NOT_VALID_SHIFT_MESSAGE')} “${shiftName}” ${t('IN_THE_TIME_RANGE')} ${t('FROM_SHORT')} ${startInterval} ${t('TO')} ${endInterval}`;
    return errorText;
  };

  const getCurrentShiftId = () => {
    let currentShiftId;
    shifts.forEach(({
      id,
      shiftStartHour,
      shiftEndHour,
      shiftStartMinutes,
      shiftEndMinutes,
    }) => {
      const shiftInterval = {
        start: new Date().setHours(shiftStartHour, shiftStartMinutes, 0),
        end: new Date().setHours(shiftEndHour, shiftEndMinutes, 0)
      };
      if (isWithinInterval(new Date(), shiftInterval)) {
        currentShiftId = id;
      }
    });
    return currentShiftId;
  };

  const isInAvailableInterval = () => {
    const shiftObj = find(shifts, { id: shiftId }) || {};
    const {
      availablePeriodStartHour,
      availablePeriodStartMinutes,
      availablePeriodEndHour,
      availablePeriodEndMinutes,
    } = shiftObj;
    const availableInterval = {
      start: new Date().setHours(availablePeriodStartHour, availablePeriodStartMinutes, 0),
      end: new Date().setHours(availablePeriodEndHour, availablePeriodEndMinutes, 0)
    };
    return isWithinInterval(new Date(), availableInterval);
  };

  useEffect(() => {
    getReportShifts();
    loadReportTypeData();
    return () => discardState();
  }, []);

  useEffect(() => {
    if (isEmpty(shifts)) return;
    shiftOnChange(getCurrentShiftId());
  }, [shifts]);

  const goBack = () => {
    history.push({
      pathname: MEASUREMENT_REPORTS,
    });
  };

  const goToReportCreation = (res) => {
    const { state: locationState } = location;
    history.push({
      pathname: CREATE_REPORT,
      state: { ...locationState, routerReportData: res }
    });
  };

  const saveItem = async () => {
    const currentShiftId = getCurrentShiftId();
    const isValidShift = currentShiftId === shiftId;
    if (!isValidShift) {
      const currentShift = find(shifts, { id: currentShiftId }) || {};
      const {
        shiftName,
      } = currentShift;
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('REPORT_CREATION_IMPOSSIBLE')
            }]
          }, {
            rowContent: [{
              type: 'text',
              text: `${t('NOT_VALID_SHIFT_MESSAGE')} ${shiftName}`
            }]
          }],
        },
        variant: 'error',
      });
      return;
    }
    const isValidTime = isInAvailableInterval();
    if (!isValidTime) {
      const notValidTimeText = getNotValidTimeErrorText(currentShiftId);
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: notValidTimeText
            }]
          }],
        },
        variant: 'error',
      });
      return;
    }
    const { res, error } = await createTpsInspectionReport() || {};
    if (res) {
      goToReportCreation(res);
      return;
    }
    const errorMessage = get(error, 'data.message', '');
    let errorText;
    switch (errorMessage) {
      case REPORT_NOT_FOUND:
        errorText = t('REPORT_NO_DATA_MESSAGE');
        break;
      case REPORT_ALREADY_EXISTS:
        errorText = t('REPORT_ALREADY_EXISTS_MESSAGE');
        break;
      default:
        errorText = t('REPORT_BUILDING_ERR_MESSAGE');
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: errorText
          }]
        }],
      },
      variant: 'error',
    });
  };

  if (isLoading || !isShiftsLoaded) {
    return <ProgressBar />;
  }

  return (
    <>
      {isRequestingReport && <ProgressBar />}
      <Panel
        labelView={t('REPORT_CREATION')}
        goBack={goBack}
        labelGoToList={t('GO_BACK')}
        layoutStyles={{ minHeight: 'auto' }}
      >
        <div className={classes.twoColumns}>
          <div className={classes.column}>
            <div className={classes.generalBlock}>
              <div className={classes.blockHeader}>
                <div className={classes.blockHeaderLabel}>
                  {t('REPORT_ATTRIBUTES')}
                </div>
              </div>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={7}>
                <Input
                  required
                  fullOutlined
                  label={t('POWER_UNIT')}
                  placeholder={t('SELECT_VALUE')}
                  value={powerUnitId}
                  name="poverUnit"
                  onChange={powerUnitOnChange}
                  type="selectAlpha"
                  options={powerUnits}
                  optionValueKey="id"
                  optionTitleKey="name"
                />
              </Grid>
              <Grid item xs={5}>
                <OptionRenderer
                  value={tpsName}
                  title={t('THERMAL_POWER_PLANT')}
                  wrapperClass={classes.optionRenderer}
                  name="tpsName"
                  data-test="tpsName"
                />
              </Grid>
            </Grid>
          </div>
          <div className={classes.column}>
            <div className={classes.generalBlock}>
              <div className={classes.blockHeader}>
                <div className={classes.blockHeaderLabel}>
                  {t('REPORT_PERIOD_SHORT')}
                </div>
              </div>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={7}>
                <Input
                  required
                  fullOutlined
                  label={t('SHIFT')}
                  placeholder={t('SELECT_VALUE')}
                  value={shiftId}
                  name="shift"
                  onChange={(shiftObj) => {
                    const id = get(shiftObj, 'id', null);
                    shiftOnChange(id);
                  }}
                  type="selectAlpha"
                  options={shifts}
                  optionValueKey="id"
                  optionTitleKey="shiftName"
                />
              </Grid>
              <Grid item xs={5}>
                <OptionRenderer
                  value={formatDate(new Date().toISOString())}
                  title={t('DATE')}
                  wrapperClass={classes.optionRenderer}
                  name="currentDate"
                  data-test="currentDate"
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.column}>
          <RadioInput
            label={t('POWER_UNIT_STATE')}
            value={state}
            name="powerUnitState"
            onChange={stateOnChange}
            options={translatedPowerUnitStates}
            data-test="powerUnitState"
          />
        </div>
        <PanelBox
          boxStyles={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            maxWidth: 'none',
            borderTop: `1px solid ${theme.palette.midGray}`
          }}
        >
          <ActionButton
            text={t('BUILD_REPORT')}
            type="confirm"
            handleClick={saveItem}
            data-test="build-report"
            style={{ margin: 19 }}
            disabled={!canMakeRequest}
          />
        </PanelBox>
      </Panel>
    </>
  );
};

export default observer(RequestReport);
