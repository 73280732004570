import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import useStores from 'Store/useStores';
import { get, find, head } from 'lodash';

const InspectionShiftCell = ({ shift }) => {
  const {
    coalInspectionStore: {
      shiftDictionary,
    },
  } = useStores();

  const shiftObj = find(shiftDictionary, { enumValue: shift });
  const shiftName = head(get(shiftObj, 'value', '').split(' ('));
  return shiftName;
};

InspectionShiftCell.defaultProps = {
  shift: undefined,
};

InspectionShiftCell.propTypes = {
  shift: PropTypes.string,
};

export default observer(InspectionShiftCell);
