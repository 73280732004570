import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  sortableItem: {
    display: 'flex',
    flex: 1,
    maxWidth: '100%',
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  hovered: {
    '&:hover': {
      boxShadow: `0 2px 4px 0 ${theme.palette.black20}`,
    },
  },
  dragIndicator: {
    display: 'flex',
    width: 34,
    minWidth: 34,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.lightGray,
  },
  grabbing: {
    cursor: 'grabbing',
  },
  grab: {
    cursor: 'grab',
  },
  helperClass: {
    zIndex: theme.sortableItemZIndex,
    maxWidth: '100%',
    pointerEvents: 'none',
  },
  routeTechObjectWrapper: {
    width: ({ showSortableDragElement }) => (showSortableDragElement ? 'calc(100% - 34px)' : '100%'),
    maxWidth: ({ showSortableDragElement }) => (showSortableDragElement ? 'calc(100% - 34px)' : '100%'),
  },
}));

export default useStyles;
