import React from 'react';
import { isArray } from 'lodash';
import withStyles from 'Src/utils/table/withColumnStyles';
import { formatDate } from 'Src/utils/datetime';
import InspectionStatusCell from 'Business/coal/components/InspectionStatusCell';
import InspectionNameCell from 'Business/coal/components/InspectionNameCell';
import InspectionShiftCell from 'Business/coal/components/InspectionShiftCell';

const tableColumns = t => (
  {
    title: {
      field: 'title',
      title: t('NAME'),
      mainLink: true,
      // eslint-disable-next-line
      render: ({ title = '', taskPriority }) => (<InspectionNameCell title={title} taskPriority={taskPriority} />),
    },
    departments: {
      field: 'departments',
      title: t('DEPARTMENT'),
      render: ({ departments }) => {
        if (!isArray(departments)) return null;
        return departments.map(({ title: departmentTitle } = {}) => departmentTitle).join(', ');
      },
    },
    shift: {
      field: 'shift',
      title: t('SHIFT'),
      // eslint-disable-next-line
      render: ({ shift }) => (<InspectionShiftCell shift={shift} />),
    },
    expectedStartDate: {
      field: 'expectedStartDate',
      title: t('START_DATE'),
      render: ({ expectedStartDate }) => formatDate(expectedStartDate),
    },
    factFinishDate: {
      field: 'factFinishDate',
      title: t('DUE_DATE'),
      render: ({ factFinishDate }) => formatDate(factFinishDate),
    },
    taskStatus: {
      field: 'taskStatus',
      title: t('STATUS'),
      // eslint-disable-next-line
      render: ({ taskStatus }) => (<InspectionStatusCell taskStatus={taskStatus} />),
    },
  }
);

export const statusesColumns = (t) => {
  const translatedTableColumns = tableColumns(t);
  return (
    {
      NEW: {
        columns: [
          withStyles(translatedTableColumns.title, { width: '35%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.departments, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.shift, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.expectedStartDate, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.taskStatus, { width: '15%', whiteSpace: 'normal' }),
        ],
        actions: ['remove']
      },
      IN_PROGRESS: {
        columns: [
          withStyles(translatedTableColumns.title, { width: '35%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.departments, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.shift, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.expectedStartDate, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.taskStatus, { width: '15%', whiteSpace: 'normal' }),
        ],
        actions: []
      },
      COMPLETED: {
        columns: [
          withStyles(translatedTableColumns.title, { width: '35%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.departments, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.shift, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.factFinishDate, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.taskStatus, { width: '15%', whiteSpace: 'normal' }),
        ],
        actions: []
      },
      ALL: {
        columns: [
          withStyles(translatedTableColumns.title, { width: '35%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.departments, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.shift, { width: '10%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.expectedStartDate, { width: '10%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.factFinishDate, { width: '10%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.taskStatus, { width: '15%', whiteSpace: 'normal' }),
        ],
        actions: ['remove']
      }
    }
  );
};

export default tableColumns;
