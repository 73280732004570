import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  card: {
    boxShadow: '0 1px 3px 0 #f3f3f3',
    border: 'solid 1px #f3f3f3',
    padding: 0,
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  cardHeader: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    '& .MuiCardHeader-title': {
      fontSize: 18
    },
    '& .MuiCardHeader-subheader': {
      fontSize: 16,
      color: theme.palette.black30,
      marginTop: theme.spacing(1),
    },
  },
  cardActionBox: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    marginTop: 2,
    marginBottom: theme.spacing(1),
    boxShadow: '0 1px 3px 0 #f3f3f3',
    border: 'solid 1px #f3f3f3',
  },
  cardHeaderTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.palette.black
  },
  cardSubTitle: {
    fontSize: 16,
    color: theme.palette.black30,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  editButton: {
    margin: 12,
    color: theme.palette.primaryLight,
    '&:hover': {
      backgroundColor: theme.palette.lightSelection,
    }
  },
  deleteButton: {
    margin: 12,
    color: theme.palette.negative,
    '&:hover': {
      backgroundColor: theme.palette.pink,
    }
  },
}));

export default useStyles;
