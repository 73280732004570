import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import ActionButton from 'Common/components/Forms/Button/components/Action/Action';
import OverflowTipSimple from 'Common/widgets/OverflowTipSimple';
import UserImage from 'Common/pages/User/components/UserImage';
import { VIEW_USER } from 'Shared/constants/routes';

import useStyles from './styles';

const UserListItem = ({ user, addUser }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const {
    id,
    fullName,
    photo,
    position,
    role: userRole,
    rootOrgUnit,
    department,
    status = false,
    isUserInSystem,
  } = user;

  const isUserActive = status === 'ACTIVE';

  const classes = useStyles({ isUserActive, isUserInSystem });

  const userOrgUnit = rootOrgUnit || t('ORG_UNIT_NOT_PROVIDED');

  const userLink = `${VIEW_USER}/${id}`;

  return (
    <div
      data-test={id}
      className={classes.user}
    >
      <div className={classes.userImageWrapper}>
        <UserImage
          disabled={!isUserActive}
          isUserInSystem={isUserInSystem}
          photo={photo}
          fullName={fullName}
        />
      </div>
      <div className={classes.userInfo}>
        <div className={classes.userBaseInfo}>
          <Link
            data-test="user-link"
            className={classes.userNameLink}
            to={{
              pathname: userLink,
              state: { withFilter: true, fromPath: pathname },
            }}
          >
            <OverflowTipSimple
              tooltipProps={{ placement: 'bottom' }}
              tooltipTitle={fullName}
              text={fullName}
              data-test="fullName"
            />
          </Link>
          <div className={classes.rightPartWrapper}>
            {isUserInSystem && userRole && <div data-test="user-role" className={classes.userRole}>{userRole}</div>}
            {!isUserInSystem && (
              <ActionButton
                className={classes.addUserButton}
                text={t('ADD')}
                type="cancel"
                handleClick={() => addUser(id)}
                data-test="add-user-button"
              />
            )}
          </div>
        </div>

        <div className={classes.positionName} data-test="positionName">
          {position}
        </div>
        <div className={classes.workDetails}>
          <div data-test="user-org-unit">{userOrgUnit}</div>
          <div data-test="department">{department}</div>
        </div>
      </div>
    </div>
  );
};

UserListItem.defaultProps = {
  addUser: () => {},
};

UserListItem.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    fullName: PropTypes.string,
    photo: PropTypes.string,
    position: PropTypes.string,
    role: PropTypes.string,
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    rootOrgUnit: PropTypes.string,
    department: PropTypes.string,
    status: PropTypes.string,
    isUserInSystem: PropTypes.bool,
  }).isRequired,
  addUser: PropTypes.func,
};

export default UserListItem;
