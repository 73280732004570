import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  selectArrow: {
    position: 'absolute',
    left: 'calc(100% + 40px)',
    top: 56,
    fontSize: 16,
    color: theme.palette.black30,
    display: 'flex',
    height: 100,
    alignItems: 'baseline',
  },
  sectionRoot: {
    position: 'relative',
    background: theme.palette.white,
  },
  arrowText: {
    width: 130,
    marginLeft: 10,
  },
  loadText: {
    textAlign: 'center',
    fontSize: 14,
    padding: `${theme.spacing(2)} 0`,
  },
}));

export default useStyles;
