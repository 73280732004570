import EquipmentAgent from 'ApiAgents/Equipment/EquipmentAgent';
import DefectsAgent from 'ApiAgents/Defects/DefectsAgent';
import RepairAgent from 'ApiAgents/Repairs/Repairs';
import isEmpty from 'lodash/isEmpty';
import {
  action, runInAction, makeObservable
} from 'mobx';
import {
  DEFECTS,
  MEASURING_POINTS,
  operationTypeTitles
} from 'Business/tpsInspections/config/operationTypeMapping';


const equipmentAgent = new EquipmentAgent();
const defectsAgent = new DefectsAgent();
const operationAgent = new RepairAgent();

const initialState = {
  operations: [],
  techObjectsForOperationSelect: {},
  isLoading: false,
  isLoadingOperations: false,
  isLoadedOperations: false,
  isLoadedTechObjectsForOperationSelect: false,
  isLoadingTechObjectsForOperationSelect: false,
  flatTechObjects: {},
  selectedTechObjectId: null,
  measurementPoints: [],
  defects: [],
  tpsId: null,
  tpsName: '',
};

export class EquipmentStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
    this.rootStore.prepareState(initialState, this);
  }

  operations;

  techObjectsForOperationSelect;

  isLoading;

  isLoadingOperations;

  isLoadedOperations;

  isLoadedTechObjectsForOperationSelect;

  isLoadingTechObjectsForOperationSelect;

  flatTechObjects;

  selectedTechObjectId;

  measurementPoints;

  defects;

  tpsId;

  tpsName;

  @action resetTechObjects = () => {
    this.flatTechObjects = {};
  };

  @action discardState() {
    this.rootStore.setInitialState(initialState, this);
  }

  @action loadTechObjectsForOperationSelect = async (firstLevelTechPlaceId) => {
    if (!firstLevelTechPlaceId) throw new Error('FirstLevelTechPlaceId is not provided.');
    if (!isEmpty(this.flatTechObjects)) return;

    this.isLoadingTechObjectsForOperationSelect = true;
    try {
      const techObjectTree = await equipmentAgent.getTechObjectsByIds([firstLevelTechPlaceId]);
      runInAction(() => {
        if (!isEmpty(techObjectTree)) {
          // eslint-disable-next-line prefer-destructuring
          this.techObjectsForOperationSelect = techObjectTree[0];

          if (this.techObjectsForOperationSelect.children.length > 0) {
            this.flattenNodes(this.techObjectsForOperationSelect.children);
          }
        }
        this.isLoadedTechObjectsForOperationSelect = true;
      });
    } catch (error) {
      console.log('ERROR in LOAD TECH_OBJECT by ID: ', error);
    }

    runInAction(() => {
      this.isLoadingTechObjectsForOperationSelect = false;
    });
  };

  loadTechObjectParentsPromise = id => equipmentAgent.getTechObjectParents(id);

  loadTechObjectsTrees = (sapCode, depth = null) => {
    if (isEmpty(sapCode)) throw new Error('SapCode is not provided.');

    return equipmentAgent.getTechObjectsTrees(sapCode, depth);
  };

  getTechObjectParentId = (techObjId) => {
    let foundParentId = null;
    Object.entries(this.flatTechObjects).forEach(([parentId, techObjects]) => {
      if (techObjects.some(({ id }) => id === techObjId)) {
        foundParentId = parentId;
      }
    });
    return foundParentId;
  };

  loadMeasurementPoints = () => operationAgent.getMeasurementPoints();

  loadDefectsByTechObjectId = id => defectsAgent.getDefectsByTechObjectId(id);

  @action flattenNodes = (nodes, depth = 0) => {
    if (!Array.isArray(nodes) || nodes.length === 0) {
      return;
    }

    nodes.forEach((node) => {
      const isParent = this.nodeHasChildren(node);
      const {
        parentId, technicalObjectId, title = '', technicalObjectType, children = []
      } = node;

      if (!this.flatTechObjects[parentId]) {
        this.flatTechObjects[parentId] = [];
      }

      this.flatTechObjects[parentId] = [...this.flatTechObjects[parentId], {
        id: technicalObjectId,
        title,
        parentId,
        type: technicalObjectType,
        isLeaf: !isParent,
        isRoot: depth === 0,
      }];
      this.flattenNodes(children, depth + 1);
    });
  };

  @action prepareSelectedOperations = () => {
    this.operations = [];

    if (this.defects.length) {
      this.operations.push({
        title: operationTypeTitles[DEFECTS],
        type: DEFECTS,
        actions: this.defects.map(({ defectId, shortText }) => ({ id: defectId, title: shortText }))
      });
    }

    if (this.measurementPoints.length) {
      this.operations.push({
        title: operationTypeTitles[MEASURING_POINTS],
        type: MEASURING_POINTS,
        actions: this.measurementPoints.map(({ measurementPointId, title }) => ({ id: measurementPointId, title }))
      });
    }
  };

  setOperations = async (techObjectId) => {
    if (this.selectedTechObjectId === techObjectId) return;

    this.isLoadingOperations = true;
    try {
      let measuringPoints = [];
      let defects = [];

      if (this.measurementPoints.length === 0) {
        measuringPoints = await equipmentAgent.getMeasurementPoints();
      }

      if (this.defects.length === 0) {
        defects = await defectsAgent.getDefects();
      }

      runInAction(() => {
        this.selectedTechObjectId = techObjectId;

        if (!defects.length && !measuringPoints.length) return;
        if (this.measurementPoints.length === 0) {
          this.measurementPoints = measuringPoints;
        }
        if (this.defects.length === 0) {
          this.defects = defects;
        }
        this.prepareSelectedOperations();
        this.isLoadingOperations = false;
        this.isLoadedOperations = true;
      });
    } catch (error) {
      console.log('ERROR in LOAD DEFECTS BY TECHOBJECT ID: ', error);
    }

    runInAction(() => {
      this.isLoadingOperations = false;
    });
  };

  nodeHasChildren({ children }) {
    return Array.isArray(children) && children.length > 0;
  }
}

export default EquipmentStore;
