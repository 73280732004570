import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import {
  get, last, startsWith, head, isEmpty
} from 'lodash';
import {
  Grid, Tooltip, Dialog, ListItemIcon, IconButton
} from '@material-ui/core';
import HeaderLayout from 'Common/widgets/Layout/Header/Header';
import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import SectionList from 'Common/widgets/Section/SectionList';
import Section from 'Common/widgets/Section/Section';
import SectionListItem from 'Common/widgets/Section/SectionListItem';
import History from 'Business/tpsInspections/components/History';
import HistoryItem from 'Business/tpsInspections/components/HistoryItem';
import PromptSection from 'Business/tpsInspections/components/PromptSection';
import DottedLoader from 'Common/components/Progress/components/Dotted';
import useStores from 'Store/useStores';
import { ReactComponent as CustomTasksIcon } from 'Src/assets/tasks_custom.svg';
import { ReactComponent as CustomTasksDoneIcon } from 'Src/assets/tasks_custom_done.svg';
import sectionTypes, {
  sectionTypeShortTitles,
  POWER_UNIT,
  CUSTOM_ACTION,
  ACTION,
  OPERATION,
  TAGS,
  EQUIPMENT,
  TECHNICAL_PLACE
} from 'Business/tpsInspections/config/sectionTypeMapping';
import TextInput from 'Common/components/Forms/Input/Text';
import {
  operationTypeTitles
} from 'Business/tpsInspections/config/operationTypeMapping';
import {
  makeStyles
} from '@material-ui/core/styles';
import {
  CancelOutlined
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  customActionInput: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  customActionIconButton: {
    width: 40,
    '&.MuiListItemIcon-root': {
      minWidth: 0,
    }
  },
  customActionIcon: {
    color: theme.palette.primaryLight
  },
  customActionInputClearButton: {},
  childrenLoaderHelper: {
    height: 48,
    width: '100%'
  },
  noItemsHelper: {
    fontSize: 16,
    color: theme.palette.black30,
    height: 84,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const OperationSelectOverlayNEW = observer(({ isOpen, handleClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    plannerTaskStore: {
      handleListItemClick,
      visibleSections,
      onOperationTypeClick,
      history,
      handleToggleAction,
      activeActions,
      removeActiveActions,
      handleHistoryClick,
      setConfirmedOperations,
      firstSectionItems,
      isEditing,
      addCustomActionSection,
      onChangeCustomAction,
      areTechObjectChildrenLoading,
    },
  } = useStores();

  const shouldShowHistory = history.length > 2;
  const showPromptSection = get(last(visibleSections), 'type', '') !== ACTION;
  const typeOfFirstSectionItem = get(head(firstSectionItems), 'type');

  const getPromptText = () => {
    const lastVisibleSection = last(visibleSections);
    const lastVisibleSectionType = get(lastVisibleSection, 'type');
    const lastVisibleSectionIndexByType = get(lastVisibleSection, 'indexByType');
    if (!lastVisibleSectionType) {
      if (typeOfFirstSectionItem === POWER_UNIT) {
        return t('CHOOSE_POWER_UNIT');
      }
      return t('CHOOSE_CHECKPOINT');
    }
    if (lastVisibleSectionType === TAGS) return t('CHOOSE_CHECKPOINT');
    if (lastVisibleSectionType === TECHNICAL_PLACE || lastVisibleSectionType === EQUIPMENT) {
      return `${t('CHOOSE')} ${t(sectionTypeShortTitles[lastVisibleSectionType])} (${t('LEVEL')} ${lastVisibleSectionIndexByType})`;
    }
    return `${t('CHOOSE')} ${t(sectionTypes[lastVisibleSectionType])}`;
  };

  const handleConfirm = () => {
    setConfirmedOperations();
    handleClose();
  };

  const getHistoryItemTitle = (type, indexByType) => {
    if (type === TECHNICAL_PLACE || type === EQUIPMENT) {
      return `${t(sectionTypeShortTitles[type])} (${t('LEVEL')} ${indexByType})`;
    }
    return null;
  };

  const renderHistory = () => (
    shouldShowHistory && (
      <Section title={t('PREVIOUS_ACTIONS')}>
        <History>
          {history.map((historyItem) => {
            const {
              title, id, branchIds = [], type, indexByType
            } = historyItem;
            const shouldHideHistoryItem = branchIds.length > get(head(visibleSections), 'branchIds', []).length;
            if (shouldHideHistoryItem) return null;
            return (
              <HistoryItem
                disableClick={isEditing}
                id={id}
                key={title}
                title={getHistoryItemTitle(type, indexByType) || title}
                onItemClick={() => handleHistoryClick(historyItem)}
              />
            );
          })}
        </History>
      </Section>
    )
  );

  return (
    <Dialog fullScreen open={isOpen} data-test="operationSelectDialog">
      <Grid
        container
        direction="column"
        wrap="nowrap"
        style={{ height: '100%' }}
      >
        {/*{(isLoadingOperations && !isLoadedOperations) && <ProgressBar />}*/}
        <HeaderLayout title={t('EQUIPMENT_ADDING')}>
          <HeaderButton type="close" handleClick={handleClose} />
          <HeaderButton type="save" handleClick={handleConfirm} disabled={false} />
        </HeaderLayout>
        <SectionList>
          {renderHistory()}
          {(!shouldShowHistory) && (
            <Section title={t(sectionTypes[typeOfFirstSectionItem])}>
              {firstSectionItems.map((powerUnit = {}) => {
                const { id, title, branchIds = [] } = powerUnit;
                return (
                  <SectionListItem
                    key={id}
                    id={id}
                    actionFunc={() => removeActiveActions(branchIds)}
                    onItemClick={() => handleListItemClick(powerUnit)}
                    title={title}
                    isCurrentlySelected={startsWith(get(last(visibleSections), 'branchIds', []).join('.'), branchIds.join('.'))}
                    isTreePassed={activeActions.some(activeAction => startsWith(activeAction, branchIds.join('.')))}
                  />
                );
              })}
            </Section>
          )}
          {/*Render visible sections*/}
          {visibleSections.map((section = {}) => {
            const {
              items = [],
              branchIds: sectionBranchIds,
              technicalObjectId,
              type,
              parentId,
              operationType,
              indexByType
            } = section;
            if (type === ACTION) {
              if (items.length > 0) {
                return (
                  <Section title={t(operationTypeTitles[operationType])} gridWidth={6} key={type}>
                    {items.map((item) => {
                      const id = get(item, 'id');
                      const title = get(item, 'title');
                      const branchIds = get(item, 'branchIds');
                      return (
                        <SectionListItem
                          variant="check"
                          checked={activeActions.includes(branchIds.join('.'))}
                          key={id}
                          id={id}
                          actionFunc={() => {}}
                          onItemClick={() => handleToggleAction(item)}
                          title={title}
                          noNextIcon
                        />
                      );
                    })}
                  </Section>
                );
              }
              return;
            }

            if (type === OPERATION) {
              return (
                <Section title={t(sectionTypes[OPERATION])} gridWidth={3} key={type}>
                  {items.map((item) => {
                    const { title, branchIds } = item;
                    return (
                      <SectionListItem
                        key={title}
                        actionFunc={() => removeActiveActions(branchIds)}
                        onItemClick={() => onOperationTypeClick(item)}
                        title={t(title)}
                        isCurrentlySelected={
                          startsWith(get(last(visibleSections), 'branchIds', []).join('.'), branchIds.join('.'))
                        }
                        isTreePassed={activeActions.some(activeAction => startsWith(activeAction, branchIds.join('.')))}
                      />
                    );
                  })}
                </Section>
              );
            }

            const getCustomActionValue = () => {
              const pathToValue = `${sectionBranchIds.join('.')}.`;
              const currentActiveAction = activeActions.find(activeAction => startsWith(activeAction, pathToValue)) || '';
              return currentActiveAction.substring(pathToValue.length);
            };

            const getSectionTitle = (sectionType) => {
              if (sectionType === TECHNICAL_PLACE || sectionType === EQUIPMENT) {
                return `${t(sectionTypeShortTitles[sectionType])} (${t('LEVEL')} ${indexByType})`;
              }
              return t(sectionTypes[sectionType]);
            };

            if (type === CUSTOM_ACTION) {
              return (
                <Section title={t('CUSTOM_ACTION')} gridWidth={6} key={type}>
                  <div className={classes.customActionInput}>
                    <TextInput
                      label={t('NOT_STANDARD_TASK_DESCRIPTION')}
                      name="customActionInput"
                      value={getCustomActionValue()}
                      onChange={value => onChangeCustomAction(value, technicalObjectId, sectionBranchIds)}
                      multiline
                      rows={4}
                      InputEndAdornment={(
                        <IconButton className={classes.customActionInputClearButton} onClick={() => onChangeCustomAction('', technicalObjectId, sectionBranchIds)} data-test="remove">
                          <CancelOutlined className={classes.inputClearButton} />
                        </IconButton>
                      )}
                    />
                  </div>
                </Section>
              );
            }
            return (
              <Section title={getSectionTitle(type)} key={parentId}>
                {items.map((item) => {
                  const {
                    title, technicalObjectId, parentId: techObjectParentId, type: techObjectType, branchIds // eslint-disable-line
                  } = item;
                  const hasCustomAction = activeActions.find(activeAction => startsWith(activeAction, [...branchIds, 'customAction'].join('.')));
                  return (
                    <SectionListItem
                      hideEndAdornmentOnBlur={type === 'EQUIPMENT' && !hasCustomAction}
                      stopPropagationOnActionButton
                      key={technicalObjectId}
                      id={technicalObjectId}
                      {...type === 'EQUIPMENT' && {
                        endAdornment: (
                          <ListItemIcon
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              addCustomActionSection(item, section);
                            }}
                            className={classes.customActionIconButton}
                          >
                            {hasCustomAction
                              ? (
                                <Tooltip title={t('EDIT_CUSTOM_TASK')}>
                                  <CustomTasksDoneIcon className={classes.customActionIcon} />
                                </Tooltip>
                              )
                              : (
                                <Tooltip title={t('ADD_CUSTOM_TASK')}>
                                  <CustomTasksIcon className={classes.customActionIcon} />
                                </Tooltip>
                              )
                            }
                          </ListItemIcon>
                        )
                      }}
                      actionFunc={() => removeActiveActions(branchIds)}
                      onItemClick={() => handleListItemClick(item)}
                      title={title}
                      isCurrentlySelected={startsWith(get(last(visibleSections), 'branchIds', []).join('.'), branchIds.join('.'))}
                      isTreePassed={activeActions.some(activeAction => startsWith(activeAction, branchIds.join('.')))}
                    />
                  );
                })}
                {isEmpty(items) && (
                  <div className={classes.noItemsHelper}>{t('NO_OPTIONS')}</div>
                )}
              </Section>
            );
          })}
          {areTechObjectChildrenLoading && (
            <Section
              title=""
              gridWidth={3}
              key="childrenLoaderHelper"
            >
              <DottedLoader isLoading>
                <div className={classes.childrenLoaderHelper} />
              </DottedLoader>
            </Section>
          )}
          {showPromptSection && !areTechObjectChildrenLoading
          && (
            <PromptSection title={getPromptText()} />
          )}
        </SectionList>
      </Grid>
    </Dialog>
  );
});

OperationSelectOverlayNEW.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default OperationSelectOverlayNEW;
