import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.black70,
    width: 24,
    height: 24,
  },
  iconButton: {
    width: 32,
    height: 32,
    padding: 4,
    marginRight: 20,
  },
  active: {
    color: theme.palette.primaryLight,
  },
}));

export default useStyles;
