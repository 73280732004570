import {
  action,
  observable,
  runInAction,
  makeObservable,
} from 'mobx';
import { assign } from 'lodash';
import { formatToISOString } from 'Src/utils/datetime';
import InspectionTaskAgent from 'ApiAgents/InspectionTasks/InspectionTaskAgent';

const inspectionTaskAgent = new InspectionTaskAgent();

export class CreatedTaskListStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable isTaskListLoaded = false;

  @observable totalPages = null;

  @observable hasNextPage = null;

  @observable hasPreviousPage = null;

  @observable isLoadingByPage = false;

  @observable isLoading = false;

  @observable taskList = [];

  @action loadTasksList = async (taskStatuses = [''], pageNumber, pageSize) => {
    const isPaginating = !!pageNumber;
    this.rootStore.setLoadingFlag(
      isPaginating,
      this,
      'isLoadingByPage',
      'isLoading',
      true
    );

    const { filterState = {} } = this.rootStore.tpsInspectTaskListFilterStore;
    const { expectedStartDate, expectedFinishDate, ...restFilters } = filterState;

    const params = {
      ...restFilters,
      taskStatuses,
    };

    if (expectedStartDate) assign(params, { expectedStartDate: formatToISOString(expectedStartDate) });
    if (expectedFinishDate) assign(params, { expectedFinishDate: formatToISOString(expectedFinishDate) });

    try {
      const {
        items, totalPages, hasNextPage, hasPreviousPage
      } = await inspectionTaskAgent.fetchCreatedTasks(
        params,
        isPaginating,
        pageNumber,
        pageSize
      );
      runInAction(() => {
        this.taskList = items;
        this.totalPages = totalPages;
        this.hasNextPage = hasNextPage;
        this.hasPreviousPage = hasPreviousPage;
        this.isTaskListLoaded = true;
        this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', false);
      });
    } catch (error) {
      console.log('ERROR in TASKS FETCHING: ', error);
      runInAction(() => {
        this.taskList = [];
        this.totalPages = 0;
        this.hasNextPage = false;
        this.isTaskListLoaded = true;
        this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', false);
      });
      throw new Error(error);
    }
  }
}

export default CreatedTaskListStore;
