import {
  AssignmentTurnedIn,
  AssignmentTurnedInOutlined,
  AssignmentOutlined,
  SwapCalls,
  Warning,
  FolderShared,
  ViewComfy,
  PeopleOutline,
  LocalShipping,
  BarChart,
  HelpOutline,
  Security,
  AccountCircleOutlined,
  ReportProblemOutlined,
  PlaceOutlined,
  PhonelinkSetupOutlined,
  ShowChartOutlined
} from '@material-ui/icons';
import * as routes from 'Shared/constants/routes';
import * as businessPermissions from 'Src/RBAC/businessPermissions';
import { ReactComponent as IconCheckpoints } from 'Assets/icon-checkpoints.svg';
import { ReactComponent as IconAdUnits } from 'Assets/icon_ad_units.svg';
import { ReactComponent as IconRule } from 'Assets/icon-rule.svg';

/**
 * To save NavLink active state when child is clicked,
 * add array of children routes to activeChildren prop

 * to show NavLink for everyone do not pass "permission" prop
*/

const drawerConfig = t => (
  [
    {
      title: t('STATUS'),
      Icon: AssignmentTurnedIn,
      url: routes.STATUSES,
      permission: businessPermissions.STATUSES_VIEW,
    },
    {
      title: t('PLANNER'),
      Icon: AssignmentTurnedInOutlined,
      url: routes.PLANNER_TASKS,
      activeChildren: routes.PLANNER_TASKS,
      permission: businessPermissions.PLANNER_TASKS_LIST_VIEW,
    },
    {
      title: t('AUTOTASKS'), // Задачи по обходам, автозадачи
      Icon: AssignmentTurnedInOutlined,
      url: routes.INSPECTIONTASKS,
      activeChildren: routes.INSPECTIONTASKS,
      permission: businessPermissions.INSPECTION_TASKS_VIEW,
    },
    {
      title: t('TASKS'),
      Icon: AssignmentOutlined,
      url: routes.TASKS,
      activeChildren: routes.TASKS,
      permission: businessPermissions.TASKS_VIEW,
    },
    {
      title: t('PROBLEMS'),
      Icon: Warning,
      url: routes.PROBLEMS,
      permission: businessPermissions.PROBLEMS_VIEW,
    },
    {
      title: t('RESOURCES'),
      Icon: FolderShared,
      url: routes.RESOURCES,
      permission: businessPermissions.RESOURCES_VIEW,
    },
    {
      title: t('MATERIALS'),
      Icon: ViewComfy,
      url: routes.MATERIALS,
      permission: businessPermissions.MATERIALS_VIEW,
    },
    {
      title: t('BRIGADES'),
      Icon: PeopleOutline,
      url: routes.BRIGADES,
      permission: businessPermissions.BRIGADES_VIEW,
    },
    {
      title: t('INSPECTIONS_LIST'),
      Icon: SwapCalls,
      url: routes.INSPECTIONS,
      activeChildren: routes.INSPECTIONS,
      permission: businessPermissions.INSPECTIONS_VIEW,
    },
    {
      title: t('ROUTES'),
      Icon: PlaceOutlined,
      url: routes.TASK_ROUTES,
      activeChildren: routes.TASK_ROUTES,
      permission: businessPermissions.TASK_ROUTES_VIEW,
    },
    {
      title: t('TRANSPORT'),
      Icon: LocalShipping,
      url: routes.TRANSPORTS,
      permission: businessPermissions.TRANSPORTS_VIEW,
    },
    {
      title: t('TAGS'),
      Icon: IconCheckpoints,
      url: routes.CHECKPOINTS,
      activeChildren: routes.CHECKPOINTS,
      permission: businessPermissions.CHECKPOINTS_VIEW,
    },
    {
      title: t('STATISTICS'),
      Icon: BarChart,
      url: routes.TASKS_PERFORMANCE_STATISTICS,
      permission: businessPermissions.STATISTICS_VIEW,
    },
    {
      title: t('CONTROL'),
      Icon: IconRule,
      url: routes.CONTROL,
      permission: businessPermissions.CONTROL_VIEW,
    },
    {
      title: t('OCCUPATIONAL_SAFETY'),
      Icon: Security,
      url: routes.OCCUPATIONAL_SAFETY,
      permission: businessPermissions.OCCUPATIONAL_SAFETY_VIEW,
    },
    {
      title: t('SETTINGS'),
      Icon: PhonelinkSetupOutlined,
      url: routes.SETTINGS,
      permission: businessPermissions.SETTINGS_VIEW,
    },
    {
      title: t('DICTIONARIES'),
      Icon: IconAdUnits,
      url: routes.DICTIONARIES,
      permission: businessPermissions.DICTIONARIES_VIEW,
    },
    {
      title: t('USERS'),
      Icon: AccountCircleOutlined,
      url: routes.USERS,
      permission: businessPermissions.USERS_VIEW,
    },
    {
      title: t('REPORT_LONG'),
      Icon: BarChart,
      url: routes.MEASUREMENT_REPORTS,
      permission: businessPermissions.MEASUREMENT_REPORT_LIST_VIEW,
    },
    {
      title: t('DEFECTS'),
      Icon: ReportProblemOutlined,
      url: routes.DEFECTS,
      permission: businessPermissions.DEFECTS_VIEW,
    },
    {
      title: t('REPORTS'),
      Icon: ShowChartOutlined,
      url: routes.REPORTS,
      permission: businessPermissions.INSPECTION_REPORTS_VIEW,
    },
    {
      title: t('FAQ'),
      Icon: HelpOutline,
      url: routes.FAQ,
    },
    {
      title: `${t('TAGS')} (NEW)`,
      Icon: IconCheckpoints,
      url: routes.CHECKPOINTS_NEW,
      activeChildren: routes.CHECKPOINTS_NEW,
      permission: businessPermissions.CHECKPOINTS_NEW_VIEW,
    },
  ]
);

export default drawerConfig;
