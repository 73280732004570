import React, { useState } from 'react';
import ManageEntityModal from 'Common/components/Modal/ManageEntityModal';

const useManageEntityModal = () => {
  const [isManageEntityModalOpen, setManageEntityModalVisibility] = useState(false);
  const [onManageEntityModalCloseCallback, setOnManageEntityModalCloseCallback] = useState(() => () => {});
  const [modalState, setManageEntityModalState] = useState({
    title: '',
    message: '',
    entityName: '',
    secondaryMessage: '',
    type: 'info',
    actionButtonTitle: '',
  });

  const handleInfoModalClose = () => {
    setManageEntityModalVisibility(false);
  };

  const renderManageEntityModal = () => (
    <ManageEntityModal
      isOpen={isManageEntityModalOpen}
      handleClose={handleInfoModalClose}
      onClose={onManageEntityModalCloseCallback}
      title={modalState.title}
      entityName={modalState.entityName}
      message={modalState.message}
      type={modalState.type}
      secondaryMessage={modalState.secondaryMessage}
      actionButtonTitle={modalState.actionButtonTitle}
    />
  );

  return {
    setManageEntityModalVisibility,
    setOnManageEntityModalCloseCallback,
    setManageEntityModalState,
    renderManageEntityModal
  };
};

export default useManageEntityModal;
