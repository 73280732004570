import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { isObject, isEmpty } from 'lodash';
import useStores from 'Store/useStores';
import { Button } from '@material-ui/core';
import { PersonAddOutlined, } from '@material-ui/icons';
import AssignmentsWidget from 'Business/coal/components/AssignmentsWidget';
import CoalTaskUsersSelectOverlay from 'Business/coal/components/UsersSelectOverlay';
import withValidation from 'Src/HOC/withValidation';

import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const Assignments = (props) => {
  const { t } = useTranslation();
  const { isReadonlyByMode, error, name } = props;

  const {
    coalTaskStore: {
      taskData,
      updateDataField,
      prepareAssignments,
    },
    validationStore: {
      onFieldUpdate,
    },
  } = useStores();

  const {
    departmentId,
    currentAssignee,
    assignments,
  } = taskData;

  const isForbiddenToAdd = !departmentId;

  const classes = useStyles({ error, isForbiddenToAdd, t });

  const [usersSelectOverlayIsVisible, setUsersSelectOverlayIsVisible] = useState(false);
  const shouldRenderAssignmentsWidget = isObject(currentAssignee) && !isEmpty(currentAssignee);

  useEffect(() => {
    if (currentAssignee) onFieldUpdate(name, currentAssignee);
  }, [currentAssignee]);

  return (
    <>
      <CoalTaskUsersSelectOverlay
        isOpen={usersSelectOverlayIsVisible}
        handleClose={() => setUsersSelectOverlayIsVisible(false)}
      />
      {shouldRenderAssignmentsWidget
        ? (
          <div style={{ marginTop: 14 }}>
            <AssignmentsWidget
              required
              title={t('ASSIGNEES')}
              assignments={assignments}
              isReadonly={isReadonlyByMode}
              currentAssignee={currentAssignee}
              onDelete={() => {
                updateDataField('currentAssignee', null);
                updateDataField('assignments', []);
              }}
              onEdit={() => {
                prepareAssignments();
                setUsersSelectOverlayIsVisible(true);
              }}
            />
          </div>
        )
        : (
          <div
            className={classes.addAssignmentsButtonWrapper}
          >
            <Button
              disabled={isForbiddenToAdd}
              variant="outlined"
              color="primary"
              className={classes.addAssignmentsButton}
              onClick={() => setUsersSelectOverlayIsVisible(true)}
              data-test="add-equipment-button"
              fullWidth
            >
              <PersonAddOutlined className={classes.addEquipmentIcon} />
              <span>{t('ASSIGN_PERFORMERS')}</span>
            </Button>
          </div>
        )
    }
    </>
  );
};
Assignments.defaultProps = {
  error: false,
};

Assignments.propTypes = {
  isReadonlyByMode: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

export default withValidation(observer(Assignments));
