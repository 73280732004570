import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import {
  useHistory,
  withRouter,
} from 'react-router-dom';

import { withTheme } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import useStores from 'Store/useStores';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import Header from 'Common/widgets/Layout/Header/Header';
import AddReasonHelperBlock from 'Business/plant/components/AddReasonHelperBlock';
import SortablePauseReasons from 'Business/plant/components/SortablePauseReasons';
import { DICTIONARIES, PAUSE_REASONS_DICTIONARIES } from 'Shared/constants/routes';

import useStyles from './styles';

const PauseReasonsDictionaryList = ({ theme }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    pauseReasonsDictionaryStore: {
      list,
      loadList,
      isListLoading,
      isUpdatingOrder,
      isLoaded,
      discardState,
      updateListOrder,
      onChangeIsVisible,
      updatingIsVisibleArray,
      onChangeIsPhotoRequired,
      updatingIsPhotoRequiredArray,
    } = {},
    notificationStore: { enqueueSnackbar },
  } = useStores();

  const history = useHistory();

  const handleEdit = (id) => {
    history.push({
      pathname: `${PAUSE_REASONS_DICTIONARIES}/${id}`,
    });
  };

  const showDefaultErrorNotification = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('REQUEST_DEFAULT_ERROR'),
              },
            ],
          },
        ],
      },
      variant: 'error',
    });
  };

  useEffect(() => {
    loadList().catch(() => showDefaultErrorNotification());
    return () => {
      discardState();
    };
  }, []);

  const {
    headerHeight = 0,
    pageHeaderHeight = 0,
    mainContentPaddingTop = 0,
    mainContentPaddingBottom = 0,
  } = theme;
  const tableBodyHeight = `calc(100vh - ${headerHeight}px - ${pageHeaderHeight}px - ${mainContentPaddingTop}px - ${mainContentPaddingBottom}px)`; // eslint-disable-line

  const showAddReasonHelperBlock = isLoaded && isEmpty(list);
  const showList = !isEmpty(list);
  const isSortable = list.length > 1;

  const goToDictionariesList = () => history.push({ pathname: DICTIONARIES });
  const goToPauseReasonCreation = () => history.push({ pathname: `${PAUSE_REASONS_DICTIONARIES}/create` });

  const renderSortableListHeader = () => (
    <div className={classes.sortableListHeader}>
      <div className={classes.isVisibleSwitcher}>{t('IS_VISIBLE_IN_MOBILE_APP_SHORT')}</div>
      <div className={classes.nameLabel}>{t('NAME')}</div>
      <div className={classes.codeLabel}>{t('CODE')}</div>
      <div className={classes.isPhotoRequiredSwitcher}>{t('PHOTO_REQUIREMENT')}</div>
    </div>
  );

  return (
    <PaperLayout>
      {(isListLoading || isUpdatingOrder) && <ProgressBar />}
      <Header
        isActiveGoBack
        titleGoBack={t('GO_BACK')}
        title={t('PAUSE_REASONS')}
        handleGoBack={goToDictionariesList}
        style={{ borderBottom: 'none' }}
      >
        <HeaderButton
          title={t('ADD_REASON')}
          type="add"
          handleClick={goToPauseReasonCreation}
        />
      </Header>
      <div className={classes.list}>
        {showAddReasonHelperBlock && <AddReasonHelperBlock />}
        {showList && (
          <>
            {renderSortableListHeader()}
            <SortablePauseReasons
              items={list}
              onItemsSortEnd={orderedList => updateListOrder(orderedList).catch(() => showDefaultErrorNotification())}
              isSortable={isSortable}
              showSortableDragElement={isSortable}
              onChangeIsVisible={(reasonId, isVisible) => onChangeIsVisible(reasonId, isVisible).catch(() => showDefaultErrorNotification())}
              updatingIsVisibleArray={updatingIsVisibleArray}
              handleEdit={handleEdit}
              onChangeIsPhotoRequired={(reasonId, isPhotoRequired) => onChangeIsPhotoRequired(reasonId, isPhotoRequired).catch(() => showDefaultErrorNotification())}
              updatingIsPhotoRequiredArray={updatingIsPhotoRequiredArray}
            />
          </>
        )}
      </div>
    </PaperLayout>
  );
};

PauseReasonsDictionaryList.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.shape({
    headerHeight: PropTypes.number,
    pageHeaderHeight: PropTypes.number,
    pageTabsHeight: PropTypes.number,
    tableHeaderHeight: PropTypes.number,
    tableFooterHeight: PropTypes.number,
    mainContentPaddingTop: PropTypes.number,
    mainContentPaddingBottom: PropTypes.number,
    pageSearchBlockHeight: PropTypes.number,
  }).isRequired,
};

export default withRouter(withTheme(observer(PauseReasonsDictionaryList)));
