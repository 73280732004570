/*
 * plannedStartDate should be valid date from current date
 * HEAD_MASTER can assign to MASTER and HEAD_MASTER, but cant assign to himself, FOREMAN
 * FOREMAN can assign to FOREMAN (and himself) and HEAD_MASTER
 * MASTER can assign to brigade only
 */
import { get } from 'lodash';
import { HEAD_MASTER, FOREMAN } from 'Shared/constants/roles';
import { isDateOverdue } from 'Src/utils/datetime';
import isEmpty from 'lodash/isEmpty';
import { taskStatusAliases } from 'Src/business/tpsRepairs/config/taskStatusAliases';

export const canAssignBy = ({
  userId,
  role,
  currentAssignee,
  oldPlannedStartDate,
  plannedStartDate,
  status,
}) => {
  const assigneeId = get(currentAssignee, 'identityId') || get(currentAssignee, 'assigneeId');

  if (isEmpty(assigneeId)) return false;

  if (isDateOverdue(plannedStartDate || oldPlannedStartDate)) return false;

  if (role === FOREMAN && !(plannedStartDate || oldPlannedStartDate)) return false;
  if (role === HEAD_MASTER && userId === assigneeId) return false;

  const isNewCurrentAssignee = get(currentAssignee, 'isNew', false);

  const newDateSelected = plannedStartDate && plannedStartDate !== oldPlannedStartDate;

  const allowByStatus = status === taskStatusAliases(it => it).ASSIGNED.name && (isNewCurrentAssignee || newDateSelected);

  return allowByStatus;
};

export default {
  canAssignBy
};
