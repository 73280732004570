import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import getAggregatedMaterialValuesByOperations from 'Business/tpsRepairs/utils/getAggregatedMaterialValuesByOperations';

import useStyles from './styles';

const MaterialValuesLayout = (props) => {
  const {
    blockTitle,
    operations = [],
    renderMaterialValues,
  } = props;

  const classes = useStyles();

  const materialValuesAcc = getAggregatedMaterialValuesByOperations(operations);

  return (
    <div className={classes.block}>
      <div className={classes.blockHeader}>
        <div className={classes.blockHeaderLabel}>{blockTitle}</div>
      </div>
      <div className={classes.layout}>
        {materialValuesAcc.map((materialValue = {}, materialValueIndex) => {
          const { materialValueId } = materialValue;
          const materialValueKey = `${materialValueId}Material${materialValueIndex}`;
          return (
            <div
              key={materialValueKey}
              className={clsx(classes.materialValue, materialValueIndex % 2 ? classes.secondInRow : classes.firstInRow)}
            >
              {renderMaterialValues(materialValue)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

MaterialValuesLayout.defaultProps = {
  blockTitle: '',
  operations: [],
};

MaterialValuesLayout.propTypes = {
  operations: PropTypes.arrayOf(PropTypes.shape({
    materialValues: PropTypes.arrayOf(PropTypes.shape({
      quantity: PropTypes.number,
    }))
  })),
  blockTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  renderMaterialValues: PropTypes.func.isRequired,
};

export default observer(MaterialValuesLayout);
