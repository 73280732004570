import React from 'react';
import PropTypes from 'prop-types';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import LinesEllipsis from 'react-lines-ellipsis';
import InspectionTypeIcon from 'Business/coal/components/InspectionTypeIcon';
import {
  Box,
} from '@material-ui/core/';
import { LOW } from 'Shared/constants/priority';

import useStyles from './styles';


const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

const InspectionNameCell = ({ title, taskPriority }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box className={classes.iconWrapper}>
        <InspectionTypeIcon taskPriority={taskPriority} />
      </Box>
      <div className={classes.textWrapper}>
        <ResponsiveEllipsis
          text={title}
          maxLine="2"
          ellipsis="..."
          trimRight
          basedOn="letters"
        />
      </div>
    </div>
  );
};

InspectionNameCell.defaultProps = {
  title: '',
  taskPriority: LOW,
};

InspectionNameCell.propTypes = {
  title: PropTypes.string,
  taskPriority: PropTypes.string,
};

export default InspectionNameCell;
