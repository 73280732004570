import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  circularProgress: {
    position: 'absolute',
    top: 14,
    left: 0,
  },
  switcher: {
    opacity: ({ isUpdating }) => (isUpdating ? 0 : 1),
    '& .MuiSwitch-colorPrimary': {
      color: theme.palette.disabled
    },
    '& .MuiSwitch-track': {
      opacity: '0.38 !important',
    },
    '& .Mui-checked': {
      color: theme.palette.primaryLight,
    },
  },
}));

export default useStyles;
