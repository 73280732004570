import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  subtitle: {
    color: theme.palette.black,
    fontSize: 20,
    lineHeight: '48px',
    fontWeight: 'bold',
    padding: theme.spacing(1, 0, 2),
  },
  wrapper: {
    fontSize: 16,
  },
}));

export default useStyles;
