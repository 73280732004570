import TaskReportAgent from 'ApiAgents/Reports/TaskReportAgent';
import {
  action,
  observable,
  runInAction,
  computed,
  makeObservable
} from 'mobx';
import { isValid, formatToISOString } from 'Src/utils/datetime';
import { onFileReceived } from 'Src/utils/file';

const taskReportAgent = new TaskReportAgent();

const initialState = {
  startDate: '',
  finishDate: '',
  reportType: ''
};

export class TaskReportStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable reportTypesList = [];

  @observable selectedState = initialState;

  @observable isLoading = false;

  @observable isDownLoading = false;

  @observable isDownLoaded = false;

  @observable isLoaded = false;

  @computed get selectedReportName() {
    return this.reportTypesList.find(it => this.selectedState.reportType === it.type).title;
  }

  @computed get downloadParams() {
    const { startDate, finishDate, reportType, } = this.selectedState;
    const { code } = this.rootStore.userStore.rootOrgUnit;

    return {
      startDate,
      finishDate,
      type: reportType,
      orgUnitCode: code
    };
  }

  @action discardState = () => {
    this.selectedState = initialState;
  };

  @action loadReportTypes = async () => {
    const { code } = this.rootStore.userStore.rootOrgUnit;
    if (!code) throw new Error('TPS code is not provided.');

    this.isLoading = true;
    try {
      const availableReportTypes = ['TIMEEXPENSES', 'OPERTIMEEXPENSES', 'EXECUTORTASKS', 'PLANTTASKS'];
      const reportTypes = await taskReportAgent.getReportTypes(code) || [];
      const filteredReportTypes = reportTypes.filter(({ type } = {}) => availableReportTypes.includes(type));

      runInAction(() => {
        this.reportTypesList = filteredReportTypes;
        this.isLoaded = true;
      });
    } catch (error) {
      console.log('ERROR IN FETCHING REPORT TYPES: ', error);
    }
    runInAction(() => {
      this.isLoading = false;
    });
  };

  @action downloadReport = async () => {
    const { code } = this.rootStore.userStore.rootOrgUnit;
    if (!code) throw new Error('TPS code is not provided.');

    const errors = this.validate();

    if (errors.length) {
      throw new Error(`Validation failed: ${errors.toString()}`);
    }

    this.isDownLoading = true;
    try {
      const { startDate, finishDate, ...restDownloadParams } = this.downloadParams;
      const report = await taskReportAgent.downloadReport({
        startDate: formatToISOString(startDate),
        finishDate: formatToISOString(finishDate),
        ...restDownloadParams
      });
      onFileReceived(report.data, this.selectedReportName);

      runInAction(() => {
        this.isDownLoaded = true;
      });
    } catch (error) {
      console.log('ERROR IN DOWNLOADING REPORT: ', error);
    }
    runInAction(() => {
      this.isDownLoading = false;
    });
  };

  @action setState = (value, name) => {
    this.selectedState[name] = value;
  };

  validate() {
    const errors = [];
    const { startDate, finishDate, reportType, } = this.selectedState;

    if (!reportType || typeof reportType !== 'string') {
      errors.push('Invalid report type provided');
    }

    if (startDate && !isValid(startDate)) {
      errors.push('Invalid startDate provided');
    }

    if (finishDate && !isValid(finishDate)) {
      errors.push('Invalid finishDate provided');
    }

    return errors;
  }
}

export default TaskReportStore;
