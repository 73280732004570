import { makeStyles } from '@material-ui/core/styles';
import {
  DONE,
  IN_PROGRESS,
  ON_HOLD,
  REJECTED,
} from 'Shared/constants/checkpointStatuses';

export default makeStyles(theme => ({
  checkpointCard: {
    position: 'relative',
    height: 'auto',
    padding: '16px 0 16px 16px',
    boxShadow: `0 1px 3px 0 ${theme.palette.midGray}`,
    border: `solid 1px ${theme.palette.midGray}`,
  },
  checkpointCardWrapper: {
    marginBottom: 16,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  expand: {
    position: 'absolute',
    padding: 5,
    right: 16,
    top: 14,
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    color: theme.palette.black70,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  checkpointStatus: {
    marginRight: 48,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14
  },
  checkpointStatusMargin: {
    marginRight: ({ status }) => (status === 'TODO' ? 42 : 48)
  },
  checkpointStatusIcon: {
    marginRight: 8,
    color: theme.palette.black20
  },
  checkpointOrder: {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 1.5,
    color: theme.palette.black
  },
  checkpointTitle: {
    fontSize: 16,
    lineHeight: 1.5,
    color: theme.palette.black30
  },
  tooltip: {
    backgroundColor: theme.palette.black70,
  },
  negative: {
    color: theme.palette.negative,
  },
  positive: {
    color: theme.palette.positive,
  },
  alert: {
    color: theme.palette.alert,
  },
  checkpointStatusColor: {
    color: ({ status }) => {
      switch (status) {
        case DONE: return theme.palette.positive;
        case IN_PROGRESS: return theme.palette.lightPrimaryHover;
        case ON_HOLD: return theme.palette.alert;
        case REJECTED: return theme.palette.negative;
        default: return theme.palette.black20;
      }
    }
  }
}));
