import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  notFoundContent: {
    borderRadius: 4,
    boxShadow: `0 2px 4px 0 ${theme.palette.black20}`,
    backgroundColor: theme.palette.white,
    paddingTop: 108,
    minHeight: `calc(100vh - ${theme.headerHeight}px - ${theme.spacing(8)}px)`,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    '& p': {
      margin: 0,
    },
    '*': {
      outline: 'none',
    },
  },
  text: {
    color: theme.palette.black30,
    fontSize: 16,
    marginTop: 24
  },
  subtitle: {
    color: theme.palette.black,
    fontSize: 20,
    lineHeight: '48px',
    fontWeight: 'bold',
  },
  button: {
    marginTop: 24
  }
}));

export default useStyles;
