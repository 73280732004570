import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  new: {
    color: theme.palette.black30,
  },
  in_progress: {
    color: theme.palette.black,
  },
  on_control: {
    color: theme.palette.orange,
  },
}));

export default useStyles;
