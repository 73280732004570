import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    color: 'red',
    fontSize: 14,
    '& img, svg': {
      marginRight: 8,
      fill: 'red',
      width: 20,
      height: 20
    },
  },
  success: {
    color: theme.palette.positive,
    '& img, svg': {
      fill: theme.palette.positive,
    },
  },
  error: {
    color: theme.palette.negative,
    '& img, svg': {
      fill: theme.palette.negative,
    },
  },
  pending: {
    color: theme.palette.black30,
    '& img, svg': {
      fill: theme.palette.black70,
    },
  }
}));

export default useStyles;
