import { MASTER, SPECIALIST_SDKR, ECONOMIST_IETS } from 'Shared/constants/roles';

const getMaterialValuesConfirmPromptConfig = (role, t) => {
  let message;
  let secondaryMessage;
  switch (role) {
    case MASTER:
      message = t('OPERATION_WILL_BE_CONFIRMED_AND_SENT_TO_THE_SPECIALIST_FOR_CONFIRMATION');
      secondaryMessage = t('THIS_OPERATION_WILL_BE_DISPLAYED_IN_THE_ON_APPROVAL_TAB');
      break;
    case SPECIALIST_SDKR:
      message = t('OPERATION_WILL_BE_CONFIRMED_AND_SENT_TO_THE_ECONOMIST_FOR_CONFIRMATION');
      secondaryMessage = t('THIS_OPERATION_WILL_BE_DISPLAYED_IN_THE_ON_APPROVAL_TAB');
      break;
    case ECONOMIST_IETS:
      message = '';
      secondaryMessage = t('OPERATION_WILL_BE_CONFIRMED_AND_SENT_TO_THE_SAP_SYSTEM');
      break;
    default:
      message = '';
      secondaryMessage = '';
  }
  return { message, secondaryMessage };
};

export default getMaterialValuesConfirmPromptConfig;
