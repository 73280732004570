import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: `calc(100vh - ${theme.headerHeight}px - ${theme.pageHeaderHeight}px - ${theme.pageTabsHeight}px - ${theme.tableFooterHeight}px - ${theme.mainContentPaddingTop}px)`,
  },
  table: {
    minWidth: 750,
    '& .MuiTableRow-root:last-child .MuiTableCell-body': {
      border: 'none'
    },
    '& .MuiTableCell-body': {
      verticalAlign: 'top',
      paddingTop: 12,
      color: theme.palette.black,
      '& p': {
        lineHeight: '24px'
      },
    }
  },
  row: {
    fontSize: 16,
    '& .MuiTableCell-root': {
      verticalAlign: 'middle'
    },
  },
  grayColored: {
    color: theme.palette.black30,
  },
  materialValue: {
    whiteSpace: 'break-spaces'
  },
  emptyHistoryLabel: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.black30,
    fontSize: 16,
    padding: 24,
  }
}));

export default useStyles;
