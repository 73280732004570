import { MASTER, SPECIALIST_SDKR, ECONOMIST_IETS } from 'Shared/constants/roles';
import { materialValuesAgreementsAliases } from 'Src/business/tpsRepairs/config/materialValuesAgreementsAliases';

const canConfirmMaterialValues = (operationStatus, role) => {
  let canConfirm = false;
  if (((operationStatus === materialValuesAgreementsAliases(it => it).NEW.name || operationStatus === materialValuesAgreementsAliases(it => it).RETURNED.name) && role === MASTER)
    || (operationStatus === materialValuesAgreementsAliases(it => it).NEW.name && role === SPECIALIST_SDKR)
    || (operationStatus === materialValuesAgreementsAliases(it => it).NEW.name && role === ECONOMIST_IETS)) canConfirm = true;
  return canConfirm;
};

export default canConfirmMaterialValues;
