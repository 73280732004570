import { MASTER, SPECIALIST_SDKR, ECONOMIST_IETS } from 'Shared/constants/roles';
import { makeStatusDto } from 'Services/dto/makeTaskStatusDto';
import taskStatusSchema from 'Services/validation/schemas/TaskStatusSchema';

const dtoMaker = makeStatusDto(taskStatusSchema);

export const materialValuesAgreementsAliases = t => (
  {
    NEW: dtoMaker({
      title: t('NEW'),
      name: 'new',
      access: [MASTER, SPECIALIST_SDKR, ECONOMIST_IETS],
      query: ['NEW']
    }),
    RETURNED: dtoMaker({
      title: t('RETURNED'),
      name: 'returned',
      query: ['RETURNED'],
      access: [MASTER, SPECIALIST_SDKR, ECONOMIST_IETS],
    }),
    APPROVED: dtoMaker({
      title: t('ON_APPROVAL'),
      name: 'approved',
      query: ['APPROVED'],
      access: [MASTER, SPECIALIST_SDKR],
    }),
  }
);

export default materialValuesAgreementsAliases;
