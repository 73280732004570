import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    height: 4,
    borderRadius: 2,
  },
  colorPrimary: {
    backgroundColor: ({ backgroundColor }) => backgroundColor,
  },
  bar: {
    borderRadius: 2,
    backgroundColor: ({ progressColor }) => progressColor,
  }
});

export default function Linear({
  value, progressColor, backgroundColor, wrapperClass
}) {
  const classes = useStyles({ progressColor, backgroundColor });

  return (
    <div className={clsx(classes.root, wrapperClass)}>
      <LinearProgress
        variant="determinate"
        value={value}
        classes={{ root: classes.root, colorPrimary: classes.colorPrimary, bar: classes.bar }}
      />
    </div>
  );
}

Linear.defaultProps = {
  wrapperClass: '',
  backgroundColor: '#f3f3f3',
  progressColor: '#a1a1a1'
};

Linear.propTypes = {
  value: PropTypes.number.isRequired,
  progressColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  wrapperClass: PropTypes.string,
};
