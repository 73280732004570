import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  materialValue: {
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  panelBox: {
    paddingBottom: 24,
    marginBottom: 0,
    borderBottom: '1px solid #f3f3f3',
    '&:last-child': {
      borderBottom: '0px none',
    },
  },
  blockHeader: {
    padding: '24px 24px 18px 24px',
  },
  blockHeaderLabel: {
    fontSize: 20,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.4,
    letterSpacing: 'normal',
    color: theme.palette.black,
  },
  blockHeaderButton: {
    paddingLeft: 48,
  },
  operationRow: {
    paddingTop: 0,
    paddingBottom: 8,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  operationRowWithMaterialValues: {
    paddingTop: 24,
    paddingBottom: 24,
    borderBottom: '1px solid #f3f3f3',
    '&:first-child': {
      paddingTop: ({ headerWithoutBottomBorder }) => (headerWithoutBottomBorder ? 0 : 24),
      borderTop: ({ headerWithoutBottomBorder }) => (headerWithoutBottomBorder ? '0px none' : '1px solid #f3f3f3'),
    },
    '&:last-child': {
      paddingBottom: 0,
      borderBottom: '0px none',
    },
  },
  noMaterialsValuesLabel: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    textSlign: 'center',
    color: theme.palette.black30,
  },
  operationWrapper: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  materialValuesWrapper: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  noMaterialsValuesWrapper: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
