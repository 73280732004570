import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  iconWrapper: {
    position: 'relative',
    color: theme.palette.black30,
    width: 24,
    height: 24,
  },
  substrate: {
    position: 'absolute',
    bottom: 5,
    left: 0,
    minWidth: 8,
    minHeight: 8,
    backgroundColor: theme.palette.white,
    borderRadius: '50%',
  },
  warningIcon: {
    position: 'absolute',
    bottom: 3,
    left: -1,
    width: 12,
    height: 12,
    color: theme.palette.primaryLight,
  },
}));

export default useStyles;
