import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 24,
    paddingBottom: 8,
  },
  headerLabel: {
    fontWeight: 'bold',
    marginBottom: 0,
  },
  divider: {
    marginRight: -24,
    marginLeft: -24,
    backgroundColor: theme.palette.midGray
  },
}));

export default useStyles;
