import Grid from '@material-ui/core/Grid';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  item: {
    flexDirection: 'column',
    width: 275,
    height: 240,
    borderRadius: 3,
    border: '1px solid',
    borderColor: theme.palette.midGray,
    '&:hover': {
      backgroundColor: theme.palette.lightSelection,
      borderColor: theme.palette.lightPrimaryHover,
    },
    '&:hover svg': {
      color: theme.palette.alert,
    }
  },
  icon: {
    fontSize: 72
  },
  title: {
    fontSize: 14,
    color: '#282828',
    paddingTop: 18
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  iconWrapper: {
    backgroundColor: theme.palette.dtekYellow,
    borderRadius: '50%',
    height: 96,
    width: 96,
  },
}));


const CategoryButton = ({ title, Icon, url }) => {
  const classes = useStyles();

  return (
    <Grid item key={title}>
      <Link to={url} key={title}>
        <div className={clsx(classes.flexCenter, classes.item)}>
          <div className={clsx(classes.flexCenter, classes.iconWrapper)}>
            <Icon className={classes.icon} />
          </div>
          <div className={classes.title}>
            {title}
          </div>
        </div>
      </Link>
    </Grid>
  );
};

CategoryButton.propTypes = {
  title: PropTypes.string.isRequired,
  Icon: PropTypes.elementType.isRequired,
  url: PropTypes.string.isRequired
};

export default CategoryButton;
