import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter } from 'react-router-dom';

import withAuth from 'HOC/withAuth';
import AppBar from 'Common/widgets/AppBar/components/AppBar/AppBar';
import Drawer from 'Common/widgets/Drawer/components/Drawer/Drawer';
import Content from 'Common/components/Content/components/Content/Content';
import ContentWrapper from 'Common/components/Content/components/ContentWrapper/ContentWrapper';
import Routes from 'Routes';
import {
  BLOCKED,
} from 'Shared/constants/userStatuses';

import 'react-virtualized/styles.css'; // should be imported once
import './App.scss';


const App = () => {
  useEffect(() => {
    try {
      const gaTrackId = window.localStorage.getItem('gaTrackId');
      if (gaTrackId) ReactGA.initialize(gaTrackId);
    } catch (e) {
      console.error('CANT INITIALIZE GA');
    }
  }, []);

  return (
    <BrowserRouter>
      <AppBar />
      <ContentWrapper render={({ businessType, status }) => {
        const isUserBlocked = status === BLOCKED;
        return (
          <>
            {isUserBlocked ? null : <Drawer />}
            <Content>
              <Routes businessType={businessType} />
            </Content>
          </>
        );
      }}
      />
    </BrowserRouter>
  );
};

// eslint-disable-next-line no-undef
export default NO_AUTH ? App : withAuth(App);
