import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import {
  Switch,
  Route,
  useHistory,
  Redirect,
  withRouter,
  useLocation
} from 'react-router-dom';

import { withTheme } from '@material-ui/core/styles';

import { DEFECTS, VIEW_DEFECT } from 'Shared/constants/routes';
import useStores from 'Store/useStores';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import useCurrentListStatus from 'Src/hooks/useCurrentListStatus';
import { defectStatusConfig } from 'Business/coal/config/defectStatuses';
import { statusesColumns } from 'Business/coal/config/defectsListTableConfig';
import DataTable from 'Common/components/DataTable/DataTable';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import ListTabs from 'Common/widgets/Tabs/ListTabs';
import Header from 'Common/widgets/Layout/Header/Header';
import { COAL_DEFECT_STATE } from 'Shared/constants/dictionaries';
import { INITIAL_PAGE_NUMBER } from 'Shared/constants/paging';
import { HEAD_MECHANIC } from 'Shared/constants/roles';

import { useTranslation } from 'react-i18next';

const DefectsList = ({ match, theme }) => {
  const { t } = useTranslation();
  const translatedDefectStatusConfig = defectStatusConfig(t);
  const translatedStatusesColumns = statusesColumns(t);
  const {
    coalDefectsListStore: {
      loadDefectsList,
      totalPages,
      isLoadingByPage,
      hasNextPage,
      hasPreviousPage,
      isDefectsListLoaded,
      defectsList,
      discardState,
    },
    dictionaryStore: {
      loadDictionaryByKey,
      isLoading: isDictionaryLoading,
    },
    userStore: {
      role,
    }
  } = useStores();

  const statusAliases = Object.keys(translatedDefectStatusConfig).map(alias => ({
    key: alias,
    ...translatedDefectStatusConfig[alias]
  }));

  const location = useLocation();
  const { pathname } = location;
  const history = useHistory();

  const currentStatus = useCurrentListStatus('defects');

  const goToInitialTab = () => history.replace(`${DEFECTS}/${translatedDefectStatusConfig.ACTUAL.name}`);

  const onRowClickHandler = (event, row) => {
    history.push({
      pathname: `${VIEW_DEFECT}/${row.id}`,
      state: { fromPath: pathname },
    });
  };

  const getStatusQuery = () => {
    if (!currentStatus) return [];

    const { query } = translatedDefectStatusConfig[currentStatus.toUpperCase()];
    return query;
  };

  const loadPaginatedDefects = (pageNumber, perPage) => {
    loadDefectsList(getStatusQuery(), pageNumber, perPage);
  };

  useEffect(() => {
    loadDictionaryByKey(COAL_DEFECT_STATE);
  }, []);

  useEffect(() => {
    if (currentStatus === '') {
      goToInitialTab();
      return;
    }
    discardState();
    loadPaginatedDefects(INITIAL_PAGE_NUMBER);
  }, [currentStatus]);

  if (!currentStatus) return null;

  const tableBodyHeight = `calc(100vh - ${theme.headerHeight}px - ${theme.pageHeaderHeight}px - ${theme.pageTabsHeight}px - ${theme.tableFooterHeight}px - ${theme.mainContentPaddingTop}px - ${theme.mainContentPaddingBottom}px)`;

  return (
    <PaperLayout autoHeight>
      {(isLoadingByPage || isDictionaryLoading) && <ProgressBar />}
      <Header
        title={t('DEFECTS')}
      />
      <Switch>
        {statusAliases.map(({ key, name }) => {
          let tableColumns = translatedStatusesColumns[key].columns;
          if (role !== HEAD_MECHANIC) {
            tableColumns = tableColumns.filter(({ field }) => field !== 'department');
          }
          return (
            <Route
              key={name}
              path={`${match.path}/${name}`}
              render={props => ((isDefectsListLoaded) ? (
                <>
                  <ListTabs
                    role={role}
                    shouldCheckRole
                    matchUrl={match.url}
                    statusConfig={statusAliases}
                    value={currentStatus}
                  />
                  <DataTable
                    isDataLoaded={isDefectsListLoaded}
                    {...props}
                    tableBodyHeight={tableBodyHeight}
                    titleSingle={t('TASK_ACCUSATIVE')}
                    columns={tableColumns}
                    data={defectsList}
                    onRowClick={onRowClickHandler}
                    count={totalPages}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    onPageChange={loadPaginatedDefects}
                    onPerPageChange={loadPaginatedDefects}
                    actions={translatedStatusesColumns[key].actions}
                    dataTestRowSuffixEntityName="id"
                  />
                </>
              ) : <ProgressBar />)}
            />
          );
        })}
        <Redirect to={`/defects/${translatedDefectStatusConfig.ACTUAL.name}`} />
      </Switch>
    </PaperLayout>
  );
};

DefectsList.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.shape({
    headerHeight: PropTypes.number,
    pageHeaderHeight: PropTypes.number,
    pageTabsHeight: PropTypes.number,
    tableHeaderHeight: PropTypes.number,
    tableFooterHeight: PropTypes.number,
    mainContentPaddingTop: PropTypes.number,
    mainContentPaddingBottom: PropTypes.number,
  }).isRequired,
};

export default withRouter(withTheme(observer(DefectsList)));
