import React from 'react';
import CopyToClipboardBtn from 'Common/components/Forms/Button/CopyToClipboard/CopyToClipboard';
import { get } from 'lodash';


export default t => ([
  {
    field: 'id',
    title: `${t('TAG')} №`,
    width: '10%',
    mainLink: true,
    cellStyle: {
      whiteSpace: 'normal',
      width: '10%',
    },
    headerStyle: {
      whiteSpace: 'normal',
      width: '10%'
    },
  },
  {
    field: 'powerUnit',
    title: `${t('BLOCK')} №`,
    withEllipsis: true,
    width: '15%',
    cellStyle: {
      whiteSpace: 'normal',
      width: '15%',
    },
    headerStyle: {
      whiteSpace: 'normal',
      width: '15%'
    },
    render: ({ powerUnit }) => get(powerUnit, 'title'),
  },
  {
    field: 'technicalPlace',
    title: t('EQUIPMENT'),
    withEllipsis: true,
    width: '20%',
    cellStyle: {
      whiteSpace: 'normal',
      width: '20%',
    },
    headerStyle: {
      whiteSpace: 'normal',
      width: '20%'
    },
    render: ({ technicalPlace }) => (technicalPlace ? technicalPlace.title : null),
  },
  {
    field: 'fullName',
    title: t('NAME'),
    withEllipsis: true,
    width: '40%',
    cellStyle: {
      whiteSpace: 'normal',
      width: '40%',
    },
    headerStyle: {
      whiteSpace: 'normal',
      width: '40%'
    },
  },
  {
    field: 'nfc',
    title: t('TAG_CODE'),
    width: '15%',
    cellStyle: {
      whiteSpace: 'normal',
      overflowWrap: 'anywhere',
      width: '15%',
    },
    headerStyle: {
      whiteSpace: 'normal',
      width: '15%'
    },
    render: ({ nfc }) => (nfc && <CopyToClipboardBtn value={nfc} shouldStopPropagation />), // eslint-disable-line
  },
]);
