import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  twoColumns: {
    display: 'flex',
  },
  column: {
    flex: 1,
    padding: '0px 24px',
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    margin: '25px 0 35px',
    paddingLeft: 24,
    color: theme.palette.black,
  },
  routeTitle: {
    fontSize: 20,
    fontWeight: 500,
    margin: '25px 0 35px',
    color: theme.palette.black,
  },
  unitedBlock: {
    display: 'flex',
  },
  routeTechObject: {
    marginBottom: 8,
    '&:last-child': {
      marginRight: 0,
    },
  },
  routeBlock: {
    marginTop: 24,
    paddingTop: 24,
    borderTop: `1px solid ${theme.palette.midGray}`
  },
  routeBlockLabel: {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 2.4,
  },
  collapseButton: {
    fontSize: 16,
    lineHeight: 1.5,
    color: theme.palette.primaryLight,
    padding: '8px 24px',
    '@media (max-width: 992px)': {
      display: 'none',
    }
  },
  folderIcon: {
    margin: 8,
  },
  priorityReadOnly: {
    display: 'flex',
    alignItems: 'center',
    height: 46,
    fontSize: 16,
    marginTop: 4,
  },
  orderRequiredIcon: {
    color: theme.palette.black30,
  },
  orderRequiredReadOnly: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
  },
  orderRequiredIconWrapper: {
    height: 24,
    width: 24,
    marginRight: 6,
  },
  startDateInput: {
    width: 250,
    marginRight: 16,
    marginTop: 6,
  },
  routeBlockHeader: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  routeBlockHeaderCenterPart: {
    width: 500,
    display: 'flex',
    justifyContent: 'space-between',
  },
  collapseAllWrapper: {
    position: 'absolute',
    top: 1,
    right: 0,
  },
  expectedStartDate: {
    '& .MuiFormControl-root': {
      width: 250,
      marginRight: 16,
    },
  },
  expectedStartDateOptionRenderer: {
    marginRight: 16,
  },
}));

export default useStyles;
