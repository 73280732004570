import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { FolderOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import DetailsBlockTitle from 'Common/components/Forms/DetailsBlock/DetailsBlockTitle';

const useStyles = makeStyles(theme => ({
  checkpointsBlockTitleWrapper: {
    padding: 24,
    position: 'sticky',
    top: theme.headerHeight + theme.tableHeaderHeight,
    background: theme.palette.white,
    zIndex: 100
  },
  toggleAllCheckpoints: {
    color: theme.palette.primaryLight,
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    cursor: 'pointer',
    outline: 'none',
    padding: 8,
    '&:hover': {
      backgroundColor: theme.palette.lightSelection,
      borderColor: theme.palette.lightPrimaryHover,
    },
  },
  folderIcon: {
    marginRight: 10
  },
}));

const CheckpointsBlockTitle = ({ isExpanded, onExpandClick }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={classes.checkpointsBlockTitleWrapper}
    >
      <DetailsBlockTitle title={t('TAGS')} />
      <div
        className={classes.toggleAllCheckpoints}
        onClick={onExpandClick}
        role="button"
        tabIndex={0}
      >
        <FolderOutlined className={classes.folderIcon} />
        <span>{isExpanded ? t('UNCOLLAPSE_ALL') : t('COLLAPSE_ALL')}</span>
      </div>
    </Grid>
  );
};

CheckpointsBlockTitle.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  onExpandClick: PropTypes.func.isRequired
};

export default CheckpointsBlockTitle;
