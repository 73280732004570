import { createMuiTheme } from '@material-ui/core/styles';

//TODO: change when designer provides
const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, .2)',
        backgroundColor: 'rgba(80,80,80,.95)'
      }
    },
    MuiPickersBasePicker: {
      pickerView: {
        minWidth: 270,
        minHeight: 220
      }
    },
    MuiPickersCalendar: {
      transitionContainer: {
        minHeight: 170,
        marginTop: 5,
      }
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        padding: '13px 15px 5px 0',
      },
      daysHeader: {
        maxHeight: 24,
        '& .MuiPickersCalendarHeader-dayLabel': {
          height: 24,
          fontSize: 14
        }
      },
      iconButton: {
        padding: 0,
        margin: '0 7px 0 0',
        width: 24,
        height: 24,
        '&:first-child': {
          order: 1
        },
        '&:last-child': {
          order: 2
        }
      },
      dayLabel: {
        width: 28,
        height: 28,
        margin: '0 2px 3px'
      },
      transitionContainer: {
        '& p': {
          fontSize: 16
        }
      }
    },
    MuiPickersDay: {
      day: {
        width: 28,
        height: 28,
        margin: '0 2px 4px',
        '& .MuiTypography-root': {
          fontSize: 12,
          lineHeight: '24px'
        }
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: '#edf4fc!important'
        }
      }
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        fontSize: '16px !important',
        fontWeight: 'normal',
        flex: 1,
        maxWidth: '100%',
        '&$textColorInherit': {
          opacity: 1,
          color: '#212121',
        },
        '&$selected': {
          backgroundColor: '#ecf4fc',
          color: '#4499ee',
          fontWeight: 600,
        },
      },
    },
    MuiTabs: {
      root: {
        boxShadow: 'inset 0 -2px 0 0px rgba(0, 0, 0, 0.2)',
      },
      indicator: {
        border: '3px solid #4499ee',
      },
    },
    MuiButton: {
      root: {
        minHeight: 48,
        textTransform: 'none',
        letterSpacing: 0.25,
        fontSize: 16,
        fontWeight: 'bold',
        borderRadius: 4,
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.5)'
      },
      contained: {
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.5)',
        fontWeight: 'bold',
        lineHeight: '24px',
      },
      text: {
        boxShadow: 'none',
        fontWeight: 'normal',
      },
    },
    MuiTableCell: {
      head: {
        height: 40,
        fontSize: '12px !important',
        color: '#a1a1a1'
      },
      root: {
        fontSize: '14px',
        whiteSpace: 'nowrap',
        height: 72,
        paddingTop: 0,
        paddingBottom: 0,
      }
    },
    MuiTableRow: {
      root: {
        '&:hover td': {
          background: '#fafafa',
        },
      }
    },
    MuiInputLabel: {
      outlined: {
        background: '#fff'
      }
    },
    MuiFab: {
      extended: {
        borderRadius: 4
      }
    }
  },
  palette: {
    primary: {
      light: '#f9f4f7',
      main: '#4499ee',
      dark: '#4499ee',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ffe300',
      main: '#009688',
      contrastText: '#4499ee'
    },
    error: {
      main: '#f44336',
      contrastText: '#fff'
    },
    disabled: '#e0e0e0',
    yellow: '#ffe300',
    green: '#009688',
    blue: '#4499ee',
    darkBlue: '#213963',
    lightPrimaryHover: '#55aaff',
    fillLightSecondaryHover: '#e9f0f9',
    default: '#fafafa',
    // new constants from a supposed library for designers
    richBlack: '#000000',
    black20: '#cccccc',
    black30: '#a1a1a1',
    black70: '#767676',
    black: '#212121',
    midBlack: '#282828',
    gray: '#e3e3e3',
    midGray: '#f3f3f3',
    lightGray: '#f9f9f9',
    white: '#ffffff',
    warmWhite: '#fcfcfc',
    primaryLight: '#4499ee',
    primaryLight2: '#4798e8',
    lightYellow: '#fffce9',
    brandYellow: '#ffe300',
    dtekYellow: '#ffe500',
    dirtyYellow: '#ab9800',
    orange: '#ff9900',
    lightSelection: '#ecf4fc',
    positive: '#44bb44',
    activeFilter: '#46be46',
    negative: '#dd4747',
    pink: '#fdf1f1',
    darkSelection: '#3c3300',
    alert: '#ff9900',
    alertSelection: '#fde9cb'
  },
  typography: {
    fontSize: 12,
    fontWeight: '600'
  },
  mainContentPaddingTop: 32,
  mainContentPaddingBottom: 28,
  drawerClosedWidth: 72,
  drawerOpenedWidth: 240,
  headerHeight: 64,
  tableHeaderHeight: 64,
  tableFooterHeight: 52,
  filterHeaderHeight: 48,
  filterPanelWidth: 350,
  filterApplyButtonHeight: 40,
  pageHeaderHeight: 73,
  sectionHeaderHeight: 60,
  breadcrumbHeight: 52,
  pageTabsHeight: 48,
  actionPanelHeight: 96,
  notificationBannerWidth: 320,
  filterPanelApplyButtonPaddingBottom: 16,
  pageSearchBlockHeight: 82,
  userCardWidth: 300,
  userCardHeight: 334,
  sortableItemZIndex: 9999,
  qrCodeSwitcherWidth: 120,
});

export default theme;
