import React from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { PowerSettingsNew } from '@material-ui/icons';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import logo from 'Assets/logo.svg';
import ElevationScroll from 'Common/widgets/AppBar/components/ElevationScroll/ElevationScroll';
import { observer } from 'mobx-react';
import useStores from 'Store/useStores';
import { rolesTranslationKeys } from 'Shared/constants/roles';
import LangSelector from 'Common/components/LangSelector/LangSelector';
import useStyles from './styles';


const MainAppBar = observer(() => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    userStore: {
      businessType,
      accessToken,
      auth,
      authFullName,
      logout,
      rootOrgUnit,
      role,
    } = {}
  } = useStores();

  const businessName = t(`${businessType}_BUSINESS_NAME`);
  const rootOrgUnitName = get(rootOrgUnit, 'name');
  const userRoleTranslationKey = get(rolesTranslationKeys, role, '');
  const userRole = t(userRoleTranslationKey);

  return (
    <div className={classes.grow}>
      <ElevationScroll>
        <AppBar className={classes.appBarBackground} data-test="header">
          <Toolbar>
            <Icon classes={{ root: classes.iconRoot }}>
              <img className={classes.imageIcon} src={logo} alt={t('COMPANY_NAME')} data-test="logo" />
            </Icon>
            {businessName && (
              <div className={classes.businessName} data-test="businessName">
                {businessName}
                {rootOrgUnitName && (<>{` — ${rootOrgUnitName}`}</>)}
              </div>
            )}
            <div className={classes.grow} />
            <LangSelector />
            {accessToken
            && (
              <>
                {auth
                && (
                  <div className={classes.fullNameWrapper}>
                    <Typography
                      noWrap
                      classes={{ root: classes.userNameTitle }}
                      data-test="fullName"
                    >
                      {authFullName ? authFullName.trim() : t('FUll_NAME')}
                    </Typography>
                    {userRole && (
                      <Typography
                        noWrap
                        classes={{ root: classes.userRoleTitle }}
                        data-test="userRole"
                      >
                        {userRole}
                      </Typography>
                    )}
                  </div>
                )
                }

                <Avatar
                  alt="Remy Sharp"
                  src={auth ? auth.avatarUrl : ''}
                  className={classes.avatar}
                  data-test="avatar"
                />

                <Tooltip title={t('EXIT')}>
                  <IconButton
                    edge="end"
                    aria-haspopup="true"
                    color="inherit"
                    classes={{ root: classes.logoutButton }}
                    onClick={logout}
                    data-test="logout"
                  >
                    <PowerSettingsNew />
                  </IconButton>
                </Tooltip>
              </>
            )
            }
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </div>
  );
});

export default MainAppBar;
