import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';

import ActionBtn from 'Common/components/Forms/Button/components/Action/Action';
import SelectAssigneeControl from 'Business/tpsRepairs/components/SelectAssigneeControl';
import Modal from 'Common/components/Modal/Modal';


const useStyles = makeStyles(theme => ({
  titleWrapper: {
    marginLeft: 16,
    marginBottom: 16,
  },
  title: {
    color: theme.palette.black30,
    fontSize: 12
  },
  titleValue: {
    fontSize: 14
  },
}));

const AssignUser = ({
  taskTitle,
  isOpened,
  isBrigade,
  handleClose,
  assigneeId,
  handleAssign,
  setUser
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const title = isBrigade ? t('CHOOSE_BRIGADE') : t('CHOOSE_EMPLOYEE');

  const renderActions = () => (
    <Grid container spacing={3} justify="flex-end">
      <Grid item>
        <ActionBtn
          text={t('CANCEL')}
          type="cancel"
          handleClick={(e) => {
            e.stopPropagation();
            handleClose(e);
          }}
        />
      </Grid>
      <Grid item>
        <ActionBtn
          text={t('SAVE')}
          type="confirm"
          handleClick={(e) => {
            e.stopPropagation();
            handleAssign(e);
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <Modal
      onClick={e => e.stopPropagation()}
      title={title}
      isOpened={isOpened}
      handleClose={handleClose}
      actions={renderActions()}
    >
      <div className={classes.titleWrapper}>
        <div className={classes.title}>{t('TASK_TITLE')}</div>
        <div className={classes.titleValue}>{taskTitle}</div>
      </div>
      <SelectAssigneeControl
        setAssignee={setUser}
        assigneeId={assigneeId}
      />
    </Modal>
  );
};

AssignUser.propTypes = {
  handleClose: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  taskTitle: PropTypes.string.isRequired,
  isOpened: PropTypes.bool.isRequired,
  isBrigade: PropTypes.bool,
  assigneeId: PropTypes.string.isRequired,
  handleAssign: PropTypes.func.isRequired,
};

AssignUser.defaultProps = {
  isBrigade: false
};

export default AssignUser;
