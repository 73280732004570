import { forEach, assign } from 'lodash';

export const IDENTITY_TYPE_BRIGADE = 'BRIGADE';
export const IDENTITY_TYPE_USER = 'USER';

export const FOREMAN = 'foreman';
export const HEAD_MASTER = 'headMaster';
export const MASTER = 'master';
export const COAL_MASTER = 'coalMaster';
export const PERFORMER = 'performer';
export const MECHANIC = 'mechanic';
export const HEAD_MECHANIC = 'headMechanic';
export const MANAGER = 'manager';
export const SUPER_ADMIN = 'superAdmin';
export const BOILER_OPERATOR = 'boilerOperator';
export const POWER_UNIT_OPERATOR = 'powerUnitOperator';
export const SENIOR_POWER_UNIT_OPERATOR = 'seniorPowerUnitOperator';
export const SHIFT_SUPERVISOR = 'shiftSupervisor';
export const SHIFT_SUPERVISOR_KTC = 'shiftSupervisorKtc';
export const TURBINE_OPERATOR = 'turbineOperator';
export const ADMINISTRATOR_OK = 'administratorOk';
export const ADMINISTRATOR_OCP = 'administratorOcp';
export const PLANNER = 'planner';
export const SPECIALIST_SDKR = 'specialistSdkr';
export const ECONOMIST_IETS = 'economistIets';

export const FOREMAN_RU = 'Начальник цеха';
export const HEAD_MASTER_RU = 'Ст. мастер';
export const MASTER_RU = 'Мастер';
export const COAL_MASTER_RU = 'Горный мастер';
export const PERFORMER_RU = 'Исполнитель';
export const MECHANIC_RU = 'Механик';
export const HEAD_MECHANIC_RU = 'Главный механик';
export const MANAGER_RU = 'Менеджер';
export const SUPER_ADMIN_RU = 'Суперадмин';
export const BOILER_OPERATOR_RU = 'Машинист-обходчик по котельному оборудованию';
export const BOILER_OPERATOR_GZU_RU = 'Машинист-обходчик по котельному оборудованию (ГЗУ)';
export const BOILER_OPERATOR_SHBM_RU = 'Машинист-обходчик по котельному оборудованию (ШБМ)';
export const BOILER_OPERATOR_RG_RU = 'Машинист-обходчик по котельному оборудованию (Ряд Г)';
export const POWER_UNIT_OPERATOR_RU = 'Машинист энергоблока';
export const SENIOR_POWER_UNIT_OPERATOR_RU = 'Старший машинист энергоблока';
export const SHIFT_SUPERVISOR_RU = 'Начальник смены';
export const SHIFT_SUPERVISOR_KTC_RU = 'Начальник смены КТЦ';
export const TURBINE_OPERATOR_RU = 'Машинист-обходчик по турбинному оборудованию';
export const ADMINISTRATOR_OK_RU = 'Администратор ОК';
export const ADMINISTRATOR_OCP_RU = 'Администратор Предприятия';
export const PLANNER_RU = 'Плановик';
export const SPECIALIST_SDKR_RU = 'Специалист СДКР';
export const ECONOMIST_IETS_RU = 'Экономист ИЭС';


export const roles = {
  FOREMAN,
  HEAD_MASTER,
  MASTER,
  COAL_MASTER,
  PERFORMER,
  MECHANIC,
  HEAD_MECHANIC,
  SUPER_ADMIN,
  MANAGER,
  BOILER_OPERATOR,
  POWER_UNIT_OPERATOR,
  SENIOR_POWER_UNIT_OPERATOR,
  SHIFT_SUPERVISOR,
  SHIFT_SUPERVISOR_KTC,
  TURBINE_OPERATOR,
  ADMINISTRATOR_OK,
  ADMINISTRATOR_OCP,
  PLANNER,
  SPECIALIST_SDKR,
  ECONOMIST_IETS,
};

export const rolesRU = {
  [FOREMAN_RU]: FOREMAN,
  [HEAD_MASTER_RU]: HEAD_MASTER,
  [MASTER_RU]: MASTER,
  [COAL_MASTER_RU]: COAL_MASTER,
  [PERFORMER_RU]: PERFORMER,
  [MECHANIC_RU]: MECHANIC,
  [HEAD_MECHANIC_RU]: HEAD_MECHANIC,
  [MANAGER_RU]: MANAGER,
  [SUPER_ADMIN_RU]: SUPER_ADMIN,
  [BOILER_OPERATOR_RU]: BOILER_OPERATOR,
  [POWER_UNIT_OPERATOR_RU]: POWER_UNIT_OPERATOR,
  [SENIOR_POWER_UNIT_OPERATOR_RU]: SENIOR_POWER_UNIT_OPERATOR,
  [SHIFT_SUPERVISOR_RU]: SHIFT_SUPERVISOR,
  [SHIFT_SUPERVISOR_KTC_RU]: SHIFT_SUPERVISOR_KTC,
  [TURBINE_OPERATOR_RU]: TURBINE_OPERATOR,
  [ADMINISTRATOR_OK_RU]: ADMINISTRATOR_OK,
  [ADMINISTRATOR_OCP_RU]: ADMINISTRATOR_OCP,
  [PLANNER_RU]: PLANNER,
  [SPECIALIST_SDKR_RU]: SPECIALIST_SDKR,
  [ECONOMIST_IETS_RU]: ECONOMIST_IETS,
};

export const rolesTranslationKeys = {
  [FOREMAN]: 'FOREMAN_ROLE_NAME',
  [HEAD_MASTER]: 'HEAD_MASTER_ROLE_NAME',
  [MASTER]: 'MASTER_ROLE_NAME',
  [COAL_MASTER]: 'COAL_MASTER_ROLE_NAME',
  [PERFORMER]: 'PERFORMER_ROLE_NAME',
  [MECHANIC]: 'MECHANIC_ROLE_NAME',
  [HEAD_MECHANIC]: 'HEAD_MECHANIC_ROLE_NAME',
  [MANAGER]: 'MANAGER_ROLE_NAME',
  [SUPER_ADMIN]: 'SUPER_ADMIN_ROLE_NAME',
  [BOILER_OPERATOR]: 'BOILER_OPERATOR_ROLE_NAME',
  [POWER_UNIT_OPERATOR]: 'POWER_UNIT_OPERATOR_ROLE_NAME',
  [SENIOR_POWER_UNIT_OPERATOR]: 'SENIOR_POWER_UNIT_OPERATOR_ROLE_NAME',
  [SHIFT_SUPERVISOR]: 'SHIFT_SUPERVISOR_ROLE_NAME',
  [SHIFT_SUPERVISOR_KTC]: 'SHIFT_SUPERVISOR_KTC_ROLE_NAME',
  [TURBINE_OPERATOR]: 'TURBINE_OPERATOR_ROLE_NAME',
  [ADMINISTRATOR_OK]: 'ADMINISTRATOR_OK_ROLE_NAME',
  [ADMINISTRATOR_OCP]: 'ADMINISTRATOR_OCP_ROLE_NAME',
  [PLANNER]: 'PLANNER_ROLE_NAME',
  [SPECIALIST_SDKR]: 'SPECIALIST_SDKR_ROLE_NAME',
  [ECONOMIST_IETS]: 'ECONOMIST_IETS_ROLE_NAME',
};

const RuToEn = () => {
  const rolesEnRu = {};
  forEach(rolesRU, (value, key) => {
    assign(rolesEnRu, { [value]: key });
  });
  return rolesEnRu;
};

export const rolesEnRu = RuToEn();

export const ROLE_MATCHING = {
  [FOREMAN]: [FOREMAN_RU, HEAD_MASTER_RU],
  [HEAD_MASTER]: [HEAD_MASTER_RU, MASTER_RU],
};
