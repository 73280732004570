import {
  action, observable, runInAction, makeObservable
} from 'mobx';
import TaskRouteAgent from 'ApiAgents/TaskRoutes/TaskRouteAgent';
import EquipmentAgent from 'ApiAgents/Equipment/EquipmentAgent';


const taskRouteAgent = new TaskRouteAgent();
const equipmentAgent = new EquipmentAgent();

const initialState = {
  items: [],
  item: {},
  isTechPlacesLoaded: false,
  isPowerUnitsLoaded: false,
  isLoading: false,
  isLoadingByPage: false,
  isTechPlacesLoading: false,
  isRouteSaved: false,
  isRouteSaving: false,
  isDeleting: false,
  isLoaded: false,
  tpsId: null,
  tpsName: '',
  totalPages: null,
  hasNextPage: null,
};

export class TaskRouteStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rootStore.prepareState(initialState, this);
    makeObservable(this);
  }

  items = [];

  item = {};

  isTechPlacesLoaded = false;

  isPowerUnitsLoaded = false;

  isLoading = false;

  isLoadingByPage = false;

  isTechPlacesLoading = false;

  isRouteSaved = false;

  isRouteSaving = false;

  isDeleting = false;

  isLoaded = false;

  totalPages = null;

  hasNextPage = null;

  tpsId = null;

  tpsName = '';

  @action getTpsWithPowerUnitsWithCheckPoints = async () => {
    const {
      rootOrgUnit: { code },
    } = this.rootStore.userStore;
    this.isLoading = true;
    try {
      const tpsWithPowerUnits = await equipmentAgent.getTpsWithPowerUnitsWithCheckPoints(code);
      runInAction(() => {
        this.tpsId = tpsWithPowerUnits.technicalObjectId;
        this.tpsName = tpsWithPowerUnits.title;
        this.isLoading = false;
        this.isPowerUnitsLoaded = true;
      });
      return tpsWithPowerUnits;
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
      });
      console.log('ERROR IN FETCHING POWER UNITS: ', error);
    }
  };

  @action getTechPlacesWithCheckPoints = async (powerUnitId = null) => {
    if (!powerUnitId) throw new Error('PowerUnitId is not provided.');

    this.isTechPlacesLoading = true;
    try {
      const techPlaces = await equipmentAgent.getTechPlacesWithCheckPoints(powerUnitId);
      runInAction(() => {
        this.isTechPlacesLoaded = true;
        this.isTechPlacesLoading = false;
      });
      return techPlaces;
    } catch (error) {
      console.log('ERROR IN FETCHING TECH PLACES: ', error);
      runInAction(() => {
        this.isTechPlacesLoading = false;
      });
    }
  };

  @action create = async (item) => {
    this.isRouteSaving = true;

    try {
      await taskRouteAgent.createItem(item);
      runInAction(() => {
        this.isRouteSaved = true;
        this.isRouteSaving = false;
      });
      return { error: false };
    } catch (error) {
      console.log('ERROR CREATING ROUTE', error);
      runInAction(() => {
        this.isRouteSaving = false;
      });
      return { error };
    }
  };

  @action update = async (item, id = null) => {
    this.isRouteSaving = true;

    try {
      await taskRouteAgent.updateItem(item, id);
      runInAction(() => {
        this.isRouteSaved = true;
        this.isRouteSaving = false;
      });
      return { error: false };
    } catch (error) {
      runInAction(() => {
        this.isRouteSaving = false;
      });
      console.log('ERROR UPDATING ROUTE', error);
      return { error };
    }
  };

  @observable preparePowerUnits = rawPowerUnits => rawPowerUnits.map(({ technicalObjectId, title }) => ({
    id: technicalObjectId,
    title,
  }));

  @action getItemsBy = async (pageNumber, pageSize) => {
    const isPaginating = !!pageNumber;
    this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', true);
    const {
      rootOrgUnit: { code: orgUnitCode },
    } = this.rootStore.userStore;
    let pagingOptions = { items: [], totalPages: null, hasNextPage: false };
    try {
      const taskRouteList = await taskRouteAgent.getItemsBy(
        { orgUnitCode },
        isPaginating,
        pageNumber,
        pageSize,
      );
      if (isPaginating) pagingOptions = taskRouteList;
      const { items, totalPages, hasNextPage } = pagingOptions;
      runInAction(() => {
        this.items = isPaginating ? items : taskRouteList;
        this.isLoaded = true;
        this.totalPages = totalPages;
        this.hasNextPage = hasNextPage;
        this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', false);
      });
    } catch (error) {
      runInAction(() => {
        this.items = [];
        this.isLoaded = true;
        this.totalPages = 0;
        this.hasNextPage = false;
        this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', false);
      });
      console.log('ERROR IN FETCHING ROTES ITEMS: ', error);
      throw new Error(error);
    }
  };

  @action getItem = async (id) => {
    this.isLoading = true;

    try {
      const route = await taskRouteAgent.getItemById(id);
      runInAction(() => {
        this.isLoading = false;
      });

      return route;
    } catch (error) {
      console.log('ERROR IN FETCHING ROUTE ITEM: ', error);
    }

    runInAction(() => {
      this.isLoading = false;
    });
  };

  @action deleteRoute = async (id) => {
    this.isDeleting = true;

    try {
      await taskRouteAgent.deleteItem(id);
      runInAction(() => {
        this.isDeleting = false;
      });
      return { error: false };
    } catch (error) {
      console.log('ERROR IN DELETING TASK ROUTE ITEM: ', error);
      runInAction(() => {
        this.isDeleting = false;
      });
      return { error };
    }
  };

  @action discardState = () => {
    this.rootStore.setInitialState(initialState, this);
  };

  @action removeRouteFromList = (routeId) => {
    this.items = this.items.filter(({ id }) => id !== routeId);
  };
}

export default TaskRouteStore;
