import React from 'react';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import DefectView from 'Business/coal/components/DefectView';
import useStores from 'Store/useStores';

import useStyles from './styles';

const TaskDefects = () => {
  const {
    coalTaskStore: { taskDefects },
  } = useStores();
  const classes = useStyles();

  return (
    <Grid direction="column" container justify="center" alignItems="center" className={classes.wrapper}>
      {taskDefects.map((defect = {}) => (
        <DefectView key={defect.id} defect={defect} />
      ))}
    </Grid>
  );
};

export default observer(TaskDefects);
