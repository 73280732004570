import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: 'calc(100% - 48px)',
    margin: '0 24px',
    position: 'relative',
    marginBottom: 8,
    boxShadow: `0 1px 3px 0 ${theme.palette.midGray}`,
    border: `solid 1px ${theme.palette.midGray}`,
    fontSize: 14,
    color: theme.palette.black,
    '&:hover': {
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.16)',
      '& $sapCode': {
        textDecoration: 'underline',
      },
      '& .defectsPhotoFolderLink': {
        color: theme.palette.primaryLight,
      },
    },
  },
  link: {
    display: 'block',
    padding: 16,
    color: 'inherit',
  },
  sapCode: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.black,
    lineHeight: '24px',
  },
  subBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.black30,
  },
  comment: {
    display: 'flex',
  },
  commentIcon: {
    height: 20,
    width: 20,
    marginRight: 10,
    color: theme.palette.positive,
  },
  commentText: {
    fontSize: 16,
    lineHeight: '20px',
    color: theme.palette.black,
  },
}));

export default useStyles;
