import {
  action, observable, runInAction, makeObservable
} from 'mobx';
import { filter, startsWith, assign, isObject, isArray, cloneDeep, find, get } from 'lodash'; // eslint-disable-line
import TaskAgent from 'ApiAgents/Tasks/TaskAgent';
import head from 'lodash/head';
import EquipmentAgent from 'ApiAgents/Equipment/EquipmentAgent';
import { transformDictionariesToObject } from 'Src/utils/transformData';
import { FOREMAN, HEAD_MASTER } from 'Shared/constants/roles';

const equipmentAgent = new EquipmentAgent('techobjects');
const taskAgent = new TaskAgent();

export class TasksStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable isLoading = false;

  @observable isLoadingByPage = false;

  @observable error = null;

  @observable isTaskLoaded = false;

  @observable totalPages = null;

  @observable hasNextPage = null;

  @observable tasks = [];

  @observable statusList = [];

  @observable isStatusListLoaded = false;

  @observable reasonsList = {};

  @observable materials = {};

  @observable selectedTask = {};

  @observable history = [];

  @observable isDictionariesLoaded = false;

  @observable dictionaries = {
    taskStatus: {},
    TaskType: {},
  };

  @action getMaterialsByIds = async (ids) => {
    if (ids.length === 0) return false;
    try {
      const materials = await equipmentAgent.getTechObjectsByIds(ids);

      runInAction(() => {
        this.materials = materials.reduce((acc, material) => ({ ...acc, [material.technicalObjectId]: material }), {});
      });
    } catch (error) {
      console.log('ERROR in GET TECH OBJECTS BY IDS: ', error);
    }
  };

  @action loadTaskList = async (taskStatuses = [''], tab, pageNumber, pageSize) => {
    const isPaginating = !!pageNumber;
    this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', true);

    const {
      userStore: {
        orgUnitId,
        auth,
        role
      }
    } = this.rootStore;
    this.materials = {};

    try {
      const params = {
        taskType: 'TPS_REPAIR',
        AssigneeId: [FOREMAN, HEAD_MASTER].includes(role) ? orgUnitId : auth.id,
        taskStatuses
      };

      if (tab) assign(params, { tab });

      const { items, totalPages, hasNextPage } = await taskAgent.loadList({ ...params }, true, pageNumber, pageSize);
      const materialsIds = new Set(items.map(({ rootTechnicalObjectIds }) => rootTechnicalObjectIds).flat());
      this.getMaterialsByIds([...materialsIds]);

      runInAction(() => {
        this.tasks = items;
        this.totalPages = totalPages;
        this.hasNextPage = hasNextPage;
        this.isTaskLoaded = true;
      });
    } catch (error) {
      this.tasks = [];
      this.totalPages = 0;
      this.hasNextPage = false;
      this.isTaskLoaded = true;
      console.log('ERROR in TASKS FETCHING: ', error);
      throw new Error(error);
    }

    runInAction(() => {
      this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', false);
    });
  };

  @action assignUser = (id, assigneeId, assigneeName) => {
    this.tasks = this.tasks.map((task) => {
      if (task.taskId === id) {
        return {
          ...task,
          currentAssignee: assigneeId ? {
            identityId: assigneeId,
            identityName: assigneeName,
            isNew: !!assigneeId,
          } : null
        };
      }
      return task;
    });
  };

  @action assignTask = async ({
    taskId,
    assigneeId,
    plannedStartDate
  }) => {
    const {
      userStore: {
        role,
        identityType
      }
    } = this.rootStore;

    try {
      if (role === FOREMAN && plannedStartDate) {
        await taskAgent.updatePlannedDate({
          taskId,
          plannedStartDate,
        });
      }

      const { taskId: id } = await taskAgent.assignTask({
        taskId,
        assigneeId,
        identityType,
      });

      return { id };
    } catch (error) {
      const errorMessage = 'ERROR in USER ASSIGNMENT';
      console.log(`${errorMessage}: `, error);
      return { error: errorMessage };
    }
  };

  @action updatePlannedDate = (taskId, plannedStartDate) => {
    this.tasks = this.tasks.map((task) => {
      const date = task.taskId === taskId ? plannedStartDate : (task.plannedStartDateNew || task.plannedStartDate);
      return {
        ...task,
        plannedStartDateNew: date,
      };
    });
  };

  @action getTaskById = async (taskId) => {
    this.isLoading = true;
    this.selectedTask = {};
    this.materials = {};
    try {
      // eslint-disable-next-line no-unused-vars
      const task = head(await taskAgent.filterById({ id: taskId }));

      const materialIds = [...task.rootTechnicalObjectIds];
      if (task.operations) {
        task.operations.forEach(({ equipmentId, technicalPlaceId }) => {
          if (equipmentId > 0) {
            materialIds.push(equipmentId);
          } else if (technicalPlaceId > 0) {
            materialIds.push(technicalPlaceId);
          }
        });
      }

      this.getMaterialsByIds([...(new Set(materialIds))]);
      runInAction(() => {
        this.selectedTask = task;
      });
    } catch (error) {
      console.log('ERROR in TASK SELECTION: ', error);
    }
    runInAction(() => {
      this.isLoading = false;
    });
  };

  @action getReasons = async () => {
    this.isLoading = true;
    try {
      const reasons = await taskAgent.getReasonList() || [];
      const filteredReasons = filter(reasons, ({ reason } = {}) => startsWith(reason, 'REASON') || startsWith(reason, 'OTHER'));
      runInAction(() => {
        this.reasonsList = filteredReasons.reduce((acc, { reason, value }) => ({ ...acc, [reason]: value }), {});
      });
    } catch (error) {
      console.log('ERROR in FETCHING REASONS LIST: ', error);
    }
    runInAction(() => {
      this.isLoading = false;
    });
  };

  @action getStatusList = async () => {
    this.isLoading = true;
    this.isStatusListLoaded = false;
    try {
      const statusList = await taskAgent.getStatusList();
      runInAction(() => {
        this.statusList = statusList.reduce((acc, { status, value }) => {
          acc[status] = value;
          return acc;
        }, {});
      });
    } catch (error) {
      console.log('ERROR IN FETCHING STATUS ITEM: ', error);
    }
    runInAction(() => {
      this.isLoading = false;
      this.isStatusListLoaded = true;
    });
  };

  @action rejectTask = async (params) => {
    try {
      const { taskId: id } = await taskAgent.rejectTask(params);
      return { id };
    } catch (error) {
      const errorMessage = 'ERROR IN REJECTING TASK';
      console.log(`${errorMessage}: `, error);
      return { error: errorMessage };
    }
  };

  @action rescheduleTask = async (taskId) => {
    try {
      const { taskId: id } = await taskAgent.rescheduleTask({
        taskId,
      });
      return { id };
    } catch (error) {
      const errorMessage = 'ERROR IN RESCHEDULING TASK';
      console.log(`${errorMessage}: `, error);
      return { error: errorMessage };
    }
  };

  @action getHistory = async (taskId) => {
    this.history = [];
    try {
      const history = await taskAgent.getHistory({ taskId });
      runInAction(() => {
        this.history = history;
      });
    } catch (error) {
      console.log('ERROR IN FETCHING HISTORY: ', error);
    }
  };

  @action getDictionaryByKey = async (key) => {
    const dictionary = await taskAgent.getDictionaryByKey({ key });
    return dictionary;
  };

  @action getDictionaryByKeys = async (keys) => {
    this.isDictionariesLoaded = false;
    try {
      // request-loop
      const dictionaries = await Promise.all(keys.map(async (key) => {
        const dictionary = await this.getDictionaryByKey(key);
        return dictionary;
      }));

      runInAction(() => {
        this.dictionaries = transformDictionariesToObject(keys, dictionaries);
        this.isDictionariesLoaded = true;
      });
    } catch (error) {
      console.log('ERROR IN FETCHING DICTIONARY BY KEYS: ', keys, error);
    }
  };
}

export default TasksStore;
