import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    maxHeight: ({ panelMaxHeight }) => (panelMaxHeight || 'calc(100vh - 240px)'),
    overflowY: 'auto',
    padding: '0 0 24px',
  },
}));

export default useStyles;
