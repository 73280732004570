import Agent from 'ApiAgents/Agent';

export default class UnitsAgent extends Agent {
  getDepartmentsByOrgUnit = rootId => this.request(
    {
      url: '/orgUnits/view/departments',
      method: 'GET',
      params: { rootId },
    },
    'user',
  );

  getDepartmentBySapCode = departmentSapCode => this.request(
    {
      url: '/orgUnits/view/department/byCode',
      method: 'GET',
      params: { departmentSapCode },
    },
    'user',
  );

  getDepartmentById = id => this.request(
    {
      url: '/orgUnits/view/department/byId',
      method: 'GET',
      params: { id },
    },
    'user',
  );

  fetchAllRootOrgUnits = () => this.request(
    {
      url: 'orgUnits/view/rootOrgUnits?businessType=3',
      method: 'GET',
    },
    'user',
  );

  getReportByType = params => this.request(
    {
      url: '/reports/download',
      method: 'GET',
      responseType: 'blob',
      headers: { 'Content-Disposition': 'attachment', Accept: 'application/vnd.ms-excel' },
      params
    },
    'tasks',
    true
  );
}
