import React from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import OverflowTip from 'Common/widgets/OverflowTip';
import OverflowTipSimple from 'Common/widgets/OverflowTipSimple';

import useStyles from './styles';


const OptionRenderer = ({
  value: val,
  valueRenderer,
  title,
  wrapperClass,
  titleClass,
  valueClass,
  titlePosition,
  name,
  endAdornmentNode,
  maxWidth,
  maxLine,
  'data-test': dataTest,
  withOverflowTip,
  overflowType,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ titlePosition, maxWidth, overflowType });
  const attributes = name ? { id: name } : {};
  const value = val || t('INPUT_EMPTY_VALUE');

  const renderValue = () => {
    if (valueRenderer) return valueRenderer(value);
    if (!withOverflowTip) return value;
    if (overflowType === 'simple') {
      return (
        <OverflowTipSimple
          tooltipTitle={value}
          text={value}
          {...dataTest && { 'data-test': dataTest }}
        />
      );
    }

    return (
      <OverflowTip
        tooltipTitle={value}
        text={value}
        maxLine={maxLine}
        {...dataTest && { 'data-test': dataTest }}
      />
    );
  };

  return (
    <div className={clsx(classes.optionRendererRoot, wrapperClass)}>
      <div
        value={value}
        {...attributes}
        className={clsx(classes.value, valueClass)}
        {...(!withOverflowTip && dataTest) && { 'data-test': dataTest }}
      >
        <div className={clsx(classes.title, titleClass)}>{title}</div>
        {renderValue()}
      </div>
      {endAdornmentNode && endAdornmentNode}
    </div>
  );
};

export default OptionRenderer;

OptionRenderer.defaultProps = {
  value: '',
  name: '',
  wrapperClass: '',
  titleClass: '',
  valueClass: '',
  titlePosition: 'left',
  valueRenderer: null,
  endAdornmentNode: null,
  maxWidth: undefined,
  maxLine: 1,
  'data-test': undefined,
  withOverflowTip: true,
  overflowType: 'simple',
};

OptionRenderer.propTypes = {
  title: PropType.oneOfType([PropType.string, PropType.number]).isRequired,
  value: PropType.oneOfType([PropType.string, PropType.number]),
  wrapperClass: PropType.string,
  titleClass: PropType.string,
  valueClass: PropType.string,
  titlePosition: PropType.oneOf(['left', 'right']),
  name: PropType.string,
  valueRenderer: PropType.func,
  endAdornmentNode: PropType.oneOfType([PropType.string, PropType.node]),
  maxWidth: PropType.oneOfType([PropType.string, PropType.number]),
  maxLine: PropType.oneOfType([PropType.string, PropType.number]),
  'data-test': PropType.string,
  withOverflowTip: PropType.bool,
  overflowType: PropType.string,
};
