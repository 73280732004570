import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { taskStatuses } from 'Shared/constants/taskStatuses';

import useStyles from './styles';

const TaskStatusCell = ({ taskStatus = '' }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const translatedTaskStatuses = taskStatuses(t);
  return (
    <div className={clsx(taskStatus && classes[taskStatus.toLowerCase()])}>
      {translatedTaskStatuses[taskStatus] ? translatedTaskStatuses[taskStatus] : translatedTaskStatuses.UNKNOWN}
    </div>
  );
};
TaskStatusCell.defaultProps = {
  taskStatus: ''
};

TaskStatusCell.propTypes = {
  taskStatus: PropTypes.string,
};

export default TaskStatusCell;
