import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'Common/components/Forms/Input/Switch';
import useStores from 'Store/useStores';
import { THERE_ARE_VOLUMES_WITH_OPERATION_KEY } from 'Src/shared/constants/responseErrors/errors';
import { observer } from 'mobx-react';
import { get, startsWith } from 'lodash';

import useStyles from './styles';


const IsActiveOperationKeySwitcher = (props) => {
  const {
    isActive = false, id, isUpdating,
  } = props;
  const classes = useStyles({ isUpdating });
  const {
    operationKeysSettingsStore: {
      onChangeIsActive
    } = {},
    notificationStore: { enqueueSnackbar },
  } = useStores();

  return (
    <div className={classes.root}>
      <Switch
        name="keyActivityState"
        value={isActive}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onChangeIsActive(id, !isActive).catch((error) => {
            const errorMessage = get(error, 'data.message', '');
            const isInUse = startsWith(errorMessage, THERE_ARE_VOLUMES_WITH_OPERATION_KEY);
            const t = () => '';
            let firstMessageRow = t('CANT_UPDATE');
            let secondMessageRow = t('REQUEST_DEFAULT_ERROR');
            if (isInUse) {
              firstMessageRow = t('CANT_CHANGE_STATE');
              secondMessageRow = t('OPERATION_KEY_IS_USED_IN_VOLUMES_PARAMS');
            }
            console.log('ERROR EDITING ACTIVE OPERATION KEY PARAM ', error);
            enqueueSnackbar({
              messageTemplate: {
                rows: [
                  {
                    rowContent: [
                      {
                        type: 'text',
                        text: firstMessageRow,
                      },
                    ],
                  },
                  {
                    rowContent: [
                      {
                        type: 'text',
                        text: secondMessageRow,
                      },
                    ],
                  },
                ],
              },
              variant: 'error',
            });
          });
        }}
        isUpdating={isUpdating}
        checked={isActive}
      />
    </div>
  );
};

IsActiveOperationKeySwitcher.defaultProps = {
  isActive: false,
  isUpdating: false,
};

IsActiveOperationKeySwitcher.propTypes = {
  isActive: PropTypes.bool,
  id: PropTypes.number.isRequired,
  isUpdating: PropTypes.bool,
};

export default observer(IsActiveOperationKeySwitcher);
