import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import useStyles from './styles';

function FilterApplyButton(props) {
  const classes = useStyles();
  const {
    label, setFilters, hasDiff,
  } = props;

  return (
    <Button
      data-test="applyFiltersButton"
      className={classes.filterApplyButton}
      disabled={!hasDiff}
      onClick={setFilters}
    >
      {label}
    </Button>
  );
}

FilterApplyButton.defaultProps = {
  hasDiff: false,
  setFilters: () => {},
};

FilterApplyButton.propTypes = {
  label: PropTypes.string.isRequired,
  setFilters: PropTypes.func,
  hasDiff: PropTypes.bool,
};

export default FilterApplyButton;
