import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import useStores from 'Store/useStores';
import { makeStyles } from '@material-ui/core/styles';
import {
  ButtonBase,
  Box,
  Popper,
  Paper,
  ClickAwayListener
} from '@material-ui/core/';
import { REASON_OTHER } from 'Src/shared/constants/reasons';
import Icon from 'Common/components/Icon';

const RejectionReasonButton = observer(({
  shouldStopPropagation,
  reason,
  comment
}) => {
  const {
    tasksStore: {
      reasonsList
    }
  } = useStores();

  const useStyles = makeStyles(theme => ({
    paper: {
      padding: theme.spacing(1),
    },
    btn: {
      color: theme.palette.lightPrimaryHover,
    },
  }));

  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    if (shouldStopPropagation) event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsOpen(!isOpen);
  };

  const handleClickAway = () => {
    setIsOpen(false);
  };

  const title = reasonsList[reason] ? reasonsList[reason] : '';
  const popperText = reason === REASON_OTHER ? comment : title;

  return (
    <Box style={{ textAlign: 'center' }}>
      {reason && (
        <div>
          <ButtonBase className={classes.btn} disableRipple onClick={handleClick}>
            <Icon color="primary" variant="comment" />
          </ButtonBase>
          <Popper open={isOpen} anchorEl={anchorEl}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <Paper className={classes.paper}>
                {popperText}
              </Paper>
            </ClickAwayListener>
          </Popper>
        </div>
      )}
    </Box>
  );
});

RejectionReasonButton.propTypes = {
  reason: PropTypes.string,
  comment: PropTypes.string,
  shouldStopPropagation: PropTypes.bool,
};

RejectionReasonButton.defaultProps = {
  reason: false,
  comment: '',
  shouldStopPropagation: false,
};

export default RejectionReasonButton;
