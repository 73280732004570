import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { inspectionStatusesTranslationKeys } from 'Shared/constants/inspectionStatuses';

import useStyles from './styles';

const InspectionStatusCell = ({ taskStatus = '' }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={clsx(taskStatus && classes[taskStatus.toLowerCase()])}>
      {inspectionStatusesTranslationKeys[taskStatus] ? t(inspectionStatusesTranslationKeys[taskStatus]) : t('UNKNOWN')}
    </div>
  );
};
InspectionStatusCell.defaultProps = {
  taskStatus: ''
};

InspectionStatusCell.propTypes = {
  taskStatus: PropTypes.string,
};

export default InspectionStatusCell;
