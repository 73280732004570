import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import useStyles from './styles';

const TableLink = ({ children, 'data-test': dataTest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.link, 'tableLink')}
      {...dataTest && { 'data-test': dataTest }}
    >
      {children}
    </div>
  );
};

TableLink.defaultProps = {
  'data-test': undefined,
};

TableLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  'data-test': PropTypes.string,
};

export default TableLink;
