import {
  action,
  runInAction,
  makeObservable,
  computed,
} from 'mobx';
import {
  isEqual, get, isArray, isEmpty,
} from 'lodash';
import UnitsAgent from 'ApiAgents/Units/UnitsAgent';
import UserAgent from 'ApiAgents/Users/UserAgent';
import { ADMIN_USER_ENTERPRISE_FILTER } from 'Src/RBAC/businessPermissions';
import { hasPermission } from 'Common/components/Authorization/Can';

const initialSelectedState = {
  rootOrgUnitCode: '',
  departmentCode: '',
  userStatus: '',
  activeUsers: false,
  blockedUsers: false,
  role: [],
};

const initialState = {
  departmentsAreLoading: false,
  isInitialFiltersLoading: false,
  departments: [],
  rootOrgUnits: [],
  rolesDictionary: [],
  searchWord: '',
  selectedState: { ...initialSelectedState },
  requestedFilters: { ...initialSelectedState },
};

const unitsAgent = new UnitsAgent();
const userAgent = new UserAgent();

export class UsersListFilterStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
    this.rootStore.prepareState(initialState, this);
  }

  isInitialFiltersLoading;

  isLoadedInitialFilters;

  initialFiltersLoadError;

  departmentsAreLoading;

  selectedState;

  searchWord;

  requestedFilters;

  departments;

  rootOrgUnits;

  rolesDictionary;

  role;

  @computed get filterState() {
    const {
      rootOrgUnitCode, departmentCode, role, activeUsers, blockedUsers
    } = this.requestedFilters;

    const state = {
      ...(rootOrgUnitCode && { rootOrgUnitCode }),
      ...(departmentCode && { departmentCode }),
      ...(role && { role }),
      ...(activeUsers && { activeUsers }),
      ...(blockedUsers && { blockedUsers }),
    };

    return state;
  }

  @computed get isFiltersSet() {
    const { environment, businessType, role } = this.rootStore.userStore;
    const canFilterByOrgUnit = hasPermission(environment, businessType, role, ADMIN_USER_ENTERPRISE_FILTER);
    const {
      rootOrgUnitCode, departmentCode, role: userRole, activeUsers, blockedUsers
    } = this.requestedFilters;
    const isFiltersSet = (rootOrgUnitCode && canFilterByOrgUnit)
      || departmentCode
      || activeUsers
      || blockedUsers
      // || userStatus !== initialSelectedState.userStatus
      || !isEmpty(userRole);

    return Boolean(isFiltersSet);
  }

  @computed get hasDiff() {
    const hasDiff = !isEqual(this.requestedFilters, this.selectedState);
    return hasDiff;
  }

  @action saveRequestedFilters = () => {
    this.requestedFilters = { ...this.selectedState };
  };

  @action discardSelectedFilters = () => {
    this.selectedState = { ...initialSelectedState };
    this.equipmentDictionary = [];
    this.requestedFilters = { ...initialSelectedState };
  };

  flatCollection(array, level = 0) {
    let result = [];
    array.forEach((item) => {
      const newItem = { ...item, level };
      result.push(newItem);
      if (isArray(item.children)) {
        result = result.concat(this.flatCollection(item.children, level + 1, item.title));
      }
    });
    return result;
  }

  @action loadInitialFilters = async () => {
    try {
      let orgUnitCode = '';
      let rootOrgUnits = [];

      let usersStatusesDictionary = (await userAgent.loadUsersStatuses({ key: 'UserStatus' })) || [];
      if (!isEmpty(usersStatusesDictionary)) {
        usersStatusesDictionary = usersStatusesDictionary.map(i => ({
          value: i.enumValue,
          title: i.value,
        }));
      }
      const rolesDictionary = await userAgent.loadUsersRoles() || [];
      const {
        auth: { rootOrgUnit },
        environment, businessType, role
      } = this.rootStore.userStore;
      this.isInitialFiltersLoading = true;
      let departments = [];

      const canFilterByOrgUnit = hasPermission(environment, businessType, role, ADMIN_USER_ENTERPRISE_FILTER);

      const rootOrgUnitId = get(rootOrgUnit, 'id');
      const rootOrgUnitCode = get(rootOrgUnit, 'code');
      if (canFilterByOrgUnit) {
        rootOrgUnits = await userAgent.fetchAllRootOrgUnits();
      } else {
        orgUnitCode = rootOrgUnitCode;
        departments = await unitsAgent.getDepartmentsByOrgUnit(rootOrgUnitId);
      }
      runInAction(() => {
        this.rolesDictionary = rolesDictionary;
        this.selectedState.rootOrgUnitCode = orgUnitCode;
        this.isLoadedInitialFilters = true;
        this.rootOrgUnits = rootOrgUnits;
        this.departments = departments;
        this.requestedFilters = { ...this.selectedState };
      });
    } catch (error) {
      runInAction(() => {
        this.initialFiltersLoadError = 'FAIL_TO_LOAD_FILTERS';
      });
    }

    runInAction(() => {
      this.isInitialFiltersLoading = false;
    });
  };

  @action onChangeRootOrgUnitCode = async (orgUnit) => {
    try {
      if (!orgUnit) {
        runInAction(() => {
          this.departments = [];
          this.selectedState.rootOrgUnitCode = '';
          this.selectedState.departmentCode = '';
          this.departmentsAreLoading = false;
        });
        return;
      }
      const orgUnitId = get(orgUnit, 'id');
      const orgUnitCode = get(orgUnit, 'code');
      if (orgUnitCode === this.selectedState.rootOrgUnitCode) return;
      this.departmentsAreLoading = true;
      this.departments = [];
      const departments = await unitsAgent.getDepartmentsByOrgUnit(orgUnitId);
      runInAction(() => {
        this.departments = departments;
        this.selectedState.rootOrgUnitCode = orgUnitCode;
        this.selectedState.technicalPlaceId = '';
        this.departmentsAreLoading = false;
      });
    } catch (error) {
      console.log('ERROR in FETCHING departments by OrgUnit: ', error);
    }
  };

  @action onChangeDepartmentCode = async (department) => {
    const departmentCode = get(department, 'code');
    if (departmentCode) {
      this.selectedState.departmentCode = departmentCode;
    } else {
      this.selectedState.departmentCode = '';
    }
  };

  @action onChangeUserStatus = (name) => {
    // this.selectedState.userStatus = userStatus;
    this.selectedState[name] = !this.selectedState[name];
  };

  @action discardState = () => {
    this.rootStore.setInitialState(initialState, this);
  };

  @action onChangeRoles = (name) => {
    if (this.selectedState.role.includes(name)) {
      this.selectedState = {
        ...this.selectedState,
        role: this.selectedState.role.filter(role => role !== name),
      };
    } else this.selectedState = { ...this.selectedState, role: [...this.selectedState.role, name] };
  };

  @action onChangeSearchWord = (value) => {
    this.searchWord = value;
  };
}

export default UsersListFilterStore;
