import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import CheckboxInput from 'Common/components/Forms/Input/Checkbox';

import { ParameterType } from 'Business/plant/constants';

import useStyles from './styles';

const VolumesList = ({ volumeList, render }) => {
  const classes = useStyles();

  const renderParameter = (parameter) => {
    const {
      id, parameterName, parameterType, unit,
    } = parameter;

    switch (parameterType) {
      case ParameterType.MANUAL:
        return (
          <div key={id} className={classes.manual}>
            <div>{parameterName}</div>
            <div className={classes.manualRightSide}>{unit}</div>
          </div>
        );

      case ParameterType.BINARY:
        return (
          <div key={id}>
            <FormControlLabel
              classes={{ label: classes.binary }}
              control={<CheckboxInput formControlFullWidth={false} checked disableRipple name="BinaryParameterType" data-test="BinaryParameterType" />}
              label={parameterName}
            />
          </div>
        );

      case ParameterType.SINGLE_SELECT:
        return (
          <div key={id} className={classes.manual}>
            <div>{parameterName}</div>
            <ArrowDropDown />
          </div>
        );

      default:
        return <div key={id}>{parameterName}</div>;
    }
  };

  if (render) {
    return render(volumeList.map(parameter => ({
      id: parameter.id,
      view: renderParameter(parameter),
      parameter,
    })));
  }

  return volumeList.map(renderParameter);
};

VolumesList.propTypes = {
  volumeList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })).isRequired,
  render: PropTypes.func,
};

VolumesList.defaultProps = {
  render: null,
};

export default VolumesList;
