import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { observer } from 'mobx-react';

import {
  Switch, Route, useHistory, Redirect, withRouter, useLocation
} from 'react-router-dom';

import { withTheme } from '@material-ui/core/styles';

import { INSPECTIONS, CREATE_INSPECTION } from 'Shared/constants/routes';
import useStores from 'Store/useStores';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import useManageEntityModal from 'Common/components/Modal/ManageEntityModal/useManageEntityModal';
import useCurrentListStatus from 'Src/hooks/useCurrentListStatus';
import { inspectionStatusConfig } from 'Business/coal/config/inspectionStatuses';
import { statusesColumns } from 'Business/coal/config/inspectionsListTableConfig';
import DataTable from 'Common/components/DataTable/DataTable';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import ListTabs from 'Common/widgets/Tabs/ListTabs';
import Header from 'Common/widgets/Layout/Header/Header';
import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import { ASSIGNED_ENUM } from 'Shared/constants/inspectionStatuses';
import { INITIAL_PAGE_NUMBER } from 'Shared/constants/paging';
import { useTranslation } from 'react-i18next';


const DefectsList = ({ match, theme }) => {
  const { t } = useTranslation();
  const translatedInspectionStatusConfig = inspectionStatusConfig(t);
  const translatedStatusesColumns = statusesColumns(t);
  const {
    coalInspectionsListStore: {
      loadInspectionsList,
      totalPages,
      isLoadingByPage,
      hasNextPage,
      hasPreviousPage,
      isInspectionsListLoaded,
      inspectionsList,
      discardState,
      currentPageNumber,
      currentPageSize,
    },
    coalInspectionStore: {
      deleteInspection,
      shiftDictionary,
      loadShiftDictionary,
    },
    userStore: { role },
    notificationStore: { enqueueSnackbar },
  } = useStores();

  const statusAliases = Object.keys(translatedInspectionStatusConfig).map(alias => ({
    key: alias,
    ...translatedInspectionStatusConfig[alias],
  }));

  const location = useLocation();
  const { pathname } = location;
  const history = useHistory();

  const currentStatus = useCurrentListStatus('inspections');

  const goToInitialTab = () => history.replace(`${INSPECTIONS}/${translatedInspectionStatusConfig.NEW.name}`);

  const currentStatusConfig = translatedInspectionStatusConfig[currentStatus.toUpperCase()];

  const getStatusQuery = () => {
    if (!currentStatus) return [];

    const { query } = currentStatusConfig || {};
    return query;
  };

  const getStatusDateLimits = () => {
    if (!currentStatus || !currentStatusConfig) return null;
    const { dateLimits = {} } = currentStatusConfig;
    const dateLimitsByCurrentRole = get(dateLimits, role);
    return dateLimitsByCurrentRole;
  };

  const loadPaginatedList = (pageNumber, perPage) => {
    loadInspectionsList(getStatusQuery(), pageNumber, perPage, getStatusDateLimits());
  };

  const {
    setManageEntityModalVisibility,
    setOnManageEntityModalCloseCallback,
    setManageEntityModalState,
    renderManageEntityModal,
  } = useManageEntityModal();

  const removeItem = id => async () => {
    try {
      await deleteInspection(id);
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('INSPECTION_DELETED'),
                },
              ],
            },
          ],
        },
        variant: 'success',
      });
      loadPaginatedList(currentPageNumber, currentPageSize);
      return;
    } catch (error) {
      const rows = [
        {
          rowContent: [
            {
              type: 'text',
              text: t('CANT_DELETE_INSPECTION'),
            },
          ],
        },
      ];
      const errors = get(error, 'data.errors');
      if (errors) {
        errors.forEach((err) => {
          switch (err) {
            case 'StatusIsNotAssigned':
              rows.push({
                rowContent: [
                  {
                    type: 'text',
                    text: t('CANT_DELETE_INSPECTION_STATUS_CHANGED'),
                  },
                ],
              },);
              break;

            case 'ExecutionDateHasArrived':
              rows.push({
                rowContent: [
                  {
                    type: 'text',
                    text: t('CANT_DELETE_INSPECTION_INVALID_DATE'),
                  },
                ],
              },);
              break;

            default:
              break;
          }
        });
      }
      enqueueSnackbar({
        messageTemplate: {
          rows
        },
        variant: 'error',
      });
    }
  };

  const showDeleteInspectionPrompt = (event, {
    id, title
  }) => {
    setManageEntityModalVisibility(true);
    setManageEntityModalState({
      title: t('INSPECTION_DELETING'),
      entityName: title,
      message: t('INSPECTION_DELETING_MESSAGE'),
      type: 'action',
      actionButtonTitle: t('DELETE'),
    });
    setOnManageEntityModalCloseCallback(() => removeItem(id));
  };

  const onRowClickHandler = (event, row) => {
    history.push({
      pathname: `${INSPECTIONS}/view/${row.id}`,
      state: { fromPath: pathname },
    });
  };

  const createHandler = () => {
    history.push({
      pathname: CREATE_INSPECTION
        .replace(':action(create)', 'create')
    });
  };

  const transform = data => data.map((item) => {
    const {
      taskStatus, canBeDeleted
    } = item;
    const hidden = taskStatus !== ASSIGNED_ENUM || !canBeDeleted;

    return {
      ...item,
      actions: {
        remove: {
          hidden
        }
      }
    };
  });

  useEffect(() => {
    if (isEmpty(shiftDictionary)) loadShiftDictionary();
    if (currentStatus === '') {
      goToInitialTab();
      return;
    }
    discardState();
    loadPaginatedList(INITIAL_PAGE_NUMBER);
  }, [currentStatus]);

  const tableBodyHeight = `calc(100vh - ${theme.headerHeight}px - ${theme.pageHeaderHeight}px - ${theme.pageTabsHeight}px - ${theme.tableFooterHeight}px - ${theme.mainContentPaddingTop}px - ${theme.mainContentPaddingBottom}px)`;
  return (
    <PaperLayout autoHeight>
      {isLoadingByPage && <ProgressBar />}
      <Header title={t('INSPECTIONS_LIST')} sticky>
        <HeaderButton title={t('CREATE_INSPECTION')} type="add" handleClick={createHandler} />
      </Header>
      <Switch>
        {statusAliases.map(({ key, name }) => {
          const tableColumns = get(translatedStatusesColumns, `[${key}].columns`, []);
          return (
            <Route
              key={name}
              path={`${match.path}/${name}`}
              render={props => (isInspectionsListLoaded ? (
                <>
                  {renderManageEntityModal()}
                  <ListTabs
                    role={role}
                    shouldCheckRole
                    matchUrl={match.url}
                    statusConfig={statusAliases}
                    value={currentStatus}
                  />
                  <DataTable
                    isDataLoaded={isInspectionsListLoaded}
                    {...props}
                    tableBodyHeight={tableBodyHeight}
                    titleSingle={t('INSPECTION_TITLE_NOMINATIVE')}
                    columns={tableColumns}
                    data={transform(inspectionsList)}
                    onRowClick={onRowClickHandler}
                    onRemove={showDeleteInspectionPrompt}
                    count={totalPages}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    onPageChange={loadPaginatedList}
                    onPerPageChange={loadPaginatedList}
                    actions={get(translatedStatusesColumns, `[${key}].actions`, [])}
                    dataTestRowSuffixEntityName="id"
                  />
                </>
              ) : (
                <ProgressBar />
              ))
              }
            />
          );
        })}
        <Redirect to={`${INSPECTIONS}/${translatedInspectionStatusConfig.NEW.name}`} />
      </Switch>
    </PaperLayout>
  );
};

DefectsList.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.shape({
    headerHeight: PropTypes.number,
    pageHeaderHeight: PropTypes.number,
    pageTabsHeight: PropTypes.number,
    tableHeaderHeight: PropTypes.number,
    tableFooterHeight: PropTypes.number,
    mainContentPaddingTop: PropTypes.number,
    mainContentPaddingBottom: PropTypes.number,
  }).isRequired,
};

export default withRouter(withTheme(observer(DefectsList)));
