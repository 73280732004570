import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, Checkbox as MaterialCheckbox } from '@material-ui/core';

const Checkbox = ({
  label,
  checked,
  onChange,
  name,
  disabled,
  className,
  'data-test': dataTest,
  color,
  edge,
  disableRipple,
  formControlFullWidth,
}) => (
  <FormControl
    margin="dense"
    fullWidth={formControlFullWidth}
    {...className && { classes: { root: className } }}
  >
    <FormControlLabel
      control={(
        <MaterialCheckbox
          checked={checked}
          onChange={(event, val) => onChange(val, name)}
          color={color}
          name={name}
          edge={edge}
          disableRipple={disableRipple}
          inputProps={{
            'aria-label': 'primary checkbox',
            ...dataTest && { 'data-test': dataTest },
            'data-active': Boolean(checked),
          }}
        />
        )}
      disabled={disabled}
      label={label}
    />
  </FormControl>
);

Checkbox.defaultProps = {
  label: '',
  checked: false,
  onChange: () => {},
  name: '',
  disabled: false,
  className: undefined,
  'data-test': undefined,
  color: 'primary',
  edge: false,
  disableRipple: false,
  formControlFullWidth: true,
};

Checkbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  'data-test': PropTypes.string,
  color: PropTypes.string,
  edge: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disableRipple: PropTypes.bool,
  formControlFullWidth: PropTypes.bool,
};

export default Checkbox;
