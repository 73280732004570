import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Paper } from '@material-ui/core';

import { formatDate, formatTime } from 'Src/utils/datetime';
import VolumesList from 'Business/plant/pages/AdministrationPage/components/VolumesList';

import useStyles from './styles';

const VolumesView = ({ volumeList, lastEdit }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper elevation={2} className={classes.papaerWrapper}>
      <div className={classes.subtitle}>{t('VOLUME_TAB')}</div>
      <VolumesList
        volumeList={volumeList}
        render={parameters => (
          parameters.map(({ view, parameter }) => (
            <div key={parameter.id} className={classes.parameterWrap}>
              {view}
            </div>
          ))
        )}
      />

      <div className={classes.subtitle}>{t('LAST_EDIT')}</div>
      <div className={classes.lastEditContainer}>
        <div className={classes.timeContainer}>
          <div className={classes.time}>
            <div className={classes.labelText}>{t('DATE')}</div>
            <div>{formatDate(lastEdit.dateTime)}</div>
          </div>
          <div>
            <div className={classes.labelText}>{t('TIME')}</div>
            <div>{formatTime(lastEdit.dateTime)}</div>
          </div>
        </div>
        <div>
          <div className={classes.labelText}>{t('AUTHOR')}</div>
          <div>{lastEdit.author}</div>
        </div>
      </div>
    </Paper>
  );
};

VolumesView.propTypes = {
  volumeList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })).isRequired,
  lastEdit: PropTypes.shape({
    author: PropTypes.string,
    dateTime: PropTypes.string,
  }).isRequired,
};

export default VolumesView;
