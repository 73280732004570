import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { throttle, isEmpty } from 'lodash';
import useStores from 'Store/useStores';
import { Box } from '@material-ui/core';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import Header from 'Common/widgets/Layout/Header/Header';
import InputSelect from 'Common/components/Forms/Input/Select';
import InputText from 'Common/components/Forms/Input/Text';
import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import DataTable from 'Common/components/DataTable/DataTable';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import * as routes from 'Shared/constants/routes';
import useManageEntityModal from 'Common/components/Modal/ManageEntityModal/useManageEntityModal';
import tableConfigNew from 'Business/tpsInspections/config/checkpiontsTableConfigNew';
import { INITIAL_PAGE_NUMBER } from 'Shared/constants/paging';
import NoResultsBlock from 'Common/widgets/NoResultsBlock';
import { Search, CancelOutlined } from '@material-ui/icons';

import useStyles from './styles';


const CheckpointsListNew = observer(() => {
  const {
    checkpointStoreNEW: {
      isLoading,
      isLoaded,
      isListLoading,
      isListLoaded,
      isLoadingByPage,
      powerUnits,
      loadPowerUnits,
      loadCheckpointsList,
      checkpointsList,
      totalPages,
      hasNextPage,
      powerUnitId,
      onChangePowerUnitId,
      onChangeTitleSearchString,
      titleSearchString,
      pageIndex,
      storedPageSize,
      isDeleting,
      deleteCheckpoint,
      discardState,
    },
    notificationStore: { enqueueSnackbar },
  } = useStores();
  const { t } = useTranslation();

  const {
    setManageEntityModalVisibility, setOnManageEntityModalCloseCallback, setManageEntityModalState, renderManageEntityModal
  } = useManageEntityModal();

  const history = useHistory();
  const classes = useStyles();

  const translatedTableConfigNew = tableConfigNew(t);

  const showNoResultsBlock = isListLoaded && isEmpty(checkpointsList);
  const showTable = isListLoading || (isListLoaded && !isEmpty(checkpointsList));

  const createHandler = () => {
    history.push({
      pathname: routes.CREATE_CHECKPOINT_NEW
    });
  };

  const onRowClickHandler = (event, rowData = {}) => {
    if (rowData.id) {
      history.push({
        pathname: `${routes.CHECKPOINTS_NEW}/edit/${rowData.id}`
      });
    }
  };

  const showLoadLIstError = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('REQUEST_LOAD_LIST_ERROR')
          }]
        }],
      },
      variant: 'error',
    });
  };

  const throttledLoadPaginatedCheckpoint = useCallback(
    throttle(() => loadCheckpointsList(INITIAL_PAGE_NUMBER, storedPageSize).catch(() => showLoadLIstError()), 1000),
    [storedPageSize],
  );

  const handleSearch = (value) => {
    onChangeTitleSearchString(value);
    throttledLoadPaginatedCheckpoint();
  };

  const handleDeleteCheckpoint = id => async () => {
    const { error } = await deleteCheckpoint(id);
    if (error) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('REQUEST_DEFAULT_ERROR'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('CHECKPOINT_SUCCESSFULLY_REMOVED')
          }]
        }],
      },
      variant: 'success',
    });
    loadCheckpointsList(INITIAL_PAGE_NUMBER, storedPageSize).catch(() => showLoadLIstError());
  };

  const removeHandler = (event, { id, title = 'title' }) => {
    setManageEntityModalState(() => ({
      title: `${t('DELETING')} ${t('TAG_GENITIVE')}`,
      entityName: title,
      message: `${t('CONFIRM_DELETING_MESSAGE')} ${t('TAG_ACCUSATIVE')}?`,
      type: 'action'
    }));
    setOnManageEntityModalCloseCallback(() => handleDeleteCheckpoint(id));
    setManageEntityModalVisibility(true);
  };

  useEffect(() => {
    loadPowerUnits();
    loadCheckpointsList().catch(() => showLoadLIstError());
    return () => discardState();
  }, []);

  return (isLoaded && !isLoading) ? (
    <>
      {isDeleting && <ProgressBar />}
      {isLoadingByPage && <ProgressBar />}
      <PaperLayout>
        <Header
          title={t('TAGS')}
          style={{ borderBottom: 'none' }}
        >
          <div className={classes.headerContent}>
            <InputText
              placeholder={t('SEARCH_BY_NAME')}
              className={classes.searchInput}
              value={titleSearchString}
              label={t('SEARCH_BY_NAME')}
              name="searchWord"
              onChange={handleSearch}
              data-test="searchQuery-input"
              InputStartAdornment={(<Search className={classes.searchIcon} />)}
              {...titleSearchString && {
                InputEndAdornment: <CancelOutlined className={classes.inputClearButton} />
              }}
              onClickEndAdornment={() => handleSearch('')}
            />
            <div className={classes.powerUnitInputWrapper}>
              <InputSelect
                fullOutlined
                label={t('POWER_UNIT')}
                disabled={isEmpty(powerUnits)}
                value={powerUnitId}
                onChange={(value) => {
                  onChangePowerUnitId(value);
                }}
                name="powerUnitId"
                placeholder={t('SELECT_POWER_UNIT')}
                options={powerUnits || []}
                optionValueKey="id"
                optionTitleKey="title"
                data-test="powerUnitId"
              />
            </div>
            <HeaderButton
              title={t('CREATE_TAG')}
              type="add"
              handleClick={createHandler}
            />
          </div>
        </Header>
        {showTable && (
          <DataTable
            titleSingle={t('TAG_ACCUSATIVE')}
            columns={translatedTableConfigNew}
            data={checkpointsList}
            onRowClick={onRowClickHandler}
            onRemove={removeHandler}
            count={totalPages}
            hasNextPage={hasNextPage}
            onPageChange={(page, size) => loadCheckpointsList(page, size).catch(() => showLoadLIstError())}
            onPerPageChange={(page, size) => loadCheckpointsList(page, size).catch(() => showLoadLIstError())}
            actions={['remove']}
            dataTestRowSuffixEntityName="id"
            pageIndex={pageIndex}
          />
        )}
        {showNoResultsBlock && (
          <Box css={{ marginTop: 112 }}>
            <NoResultsBlock />
          </Box>
        )}
      </PaperLayout>

      {renderManageEntityModal()}
    </>
  ) : <ProgressBar />;
});

CheckpointsListNew.propTypes = {};

export default CheckpointsListNew;
