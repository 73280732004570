import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import useStores from 'Store/useStores';
import { get } from 'lodash';
import { COAL_DEFECT_STATE } from 'Shared/constants/dictionaries';

import useStyles from './styles';

const DefectStatusCell = ({ defectState = '' }) => {
  const classes = useStyles();
  const {
    dictionaryStore: {
      loadedDictionaries,
    },
  } = useStores();
  const coalDefectStateDictionary = get(loadedDictionaries, COAL_DEFECT_STATE);
  if (!coalDefectStateDictionary) return null;

  return (
    <div className={classes.defectState}>
      {coalDefectStateDictionary[defectState] ? coalDefectStateDictionary[defectState] : get(coalDefectStateDictionary, 'UNKNOWN', '')}
    </div>
  );
};

DefectStatusCell.defaultProps = {
  defectState: ''
};

DefectStatusCell.propTypes = {
  defectState: PropTypes.string,
};

export default observer(DefectStatusCell);
