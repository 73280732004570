import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  taskExecutionResultBlock: {
    marginRight: -24,
    marginLeft: -24,
  }
}));

export default useStyles;
