import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  blockHeader: {
    padding: '24px 24px 18px 0px',
  },
  blockHeaderLabel: {
    fontSize: 20,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.4,
    letterSpacing: 'normal',
    color: theme.palette.black,
  },
  twoColumns: {
    display: 'flex',
  },
  column: {
    flex: 1,
    padding: '0px 24px',
  },
}));

export default useStyles;
