import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    alignContent: 'stretch',
    alignItems: 'center',
    paddingLeft: 12,
    paddingRight: 12,
    height: 73,
    minHeight: 73,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.midGray,
    width: '100%',
  },
  autoWidth: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontWeight: 500,
    marginTop: 8,
    marginLeft: 5,
    fontSize: 24,
    color: theme.palette.black,
    letterSpacing: -0.2,
  },
  arrowBackInHeader: {
    '& .MuiSvgIcon-root ': {
      width: 24,
      height: 24,
    },
    marginTop: 8,
  },
  sticky: {
    position: 'sticky',
    top: 64,
    backgroundColor: 'white',
    zIndex: 999,
  },
  children: {
    whiteSpace: 'nowrap',
    marginTop: 8,
  },
}));

export default useStyles;
