import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { CommentOutlined } from '@material-ui/icons';
import { formatTime } from 'Src/utils/datetime';
import { VIEW_DEFECT } from 'Shared/constants/routes';
import PhotoLink from 'Business/coal/components/PhotoLink';
import OverflowTipSimple from 'Common/widgets/OverflowTipSimple';

import useStyles from './styles';

const DefectView = (props) => {
  const classes = useStyles();
  const {
    defect: {
      id, sapCode, title, comment, createdDate, defectsPhotoFolderLink
    },
  } = props;

  return (
    <Box className={classes.wrapper} data-test={`defect_${id}`}>
      <Link to={`${VIEW_DEFECT}/${id}`} className={classes.link}>
        <div className={classes.subBlock}>
          <OverflowTipSimple
            tooltipTitle={sapCode}
            text={sapCode}
            data-test="sap_code"
            textProps={{
              className: classes.sapCode
            }}
          />
          <PhotoLink link={defectsPhotoFolderLink} />
        </div>
        <div className={classes.subBlock}>
          <div data-test="title">{title}</div>
          <div data-test="createdDate">{formatTime(createdDate)}</div>
        </div>
        {comment && (
          <div className={classes.comment}>
            <CommentOutlined className={classes.commentIcon} />
            <span className={classes.commentText} data-test="comment">
              {comment}
            </span>
          </div>
        )}
      </Link>
    </Box>
  );
};

DefectView.propTypes = {
  defect: PropTypes.shape({
    id: PropTypes.number,
    sapCode: PropTypes.string,
    title: PropTypes.string,
    comment: PropTypes.string,
    createdDate: PropTypes.string,
    defectsPhotoFolderLink: PropTypes.string,
  }).isRequired,
};

export default DefectView;
