import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FormControl, FormControlLabel } from '@material-ui/core';
import Switch from 'Common/components/Forms/Input/Switch';

import useStyles from './styles';

const FormSwitch = (props) => {
  const {
    onClick,
    disabled,
    isReadonly,
    label,
    error,
    name,
    onChange,
    checked,
    'data-test': dataTest,
    className,
  } = props;

  const classes = useStyles();

  return (
    <FormControl
      classes={{ root: clsx(Boolean(className) && className, classes.formControlRadio) }}
      margin="normal"
      fullWidth
      error={error}
    >
      <FormControlLabel
        control={(
          <Switch
            onClick={onClick}
            checked={checked}
            onChange={onChange}
            name={name}
            inputProps={{
              'data-test': dataTest,
              'data-active': checked
            }}
          />
          )}
        disabled={disabled || isReadonly}
        label={label}
      />
    </FormControl>
  );
};

FormSwitch.propTypes = {
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isReadonly: PropTypes.bool,
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  className: PropTypes.string,
  'data-test': PropTypes.string.isRequired,
};

FormSwitch.defaultProps = {
  label: '',
  checked: false,
  isReadonly: false,
  onChange: () => {},
  onClick: () => {},
  error: false,
  disabled: false,
  className: undefined,
};

export default FormSwitch;
