import DataTable from 'Common/components/DataTable/DataTable';
import useManageEntityModal from 'Common/components/Modal/ManageEntityModal/useManageEntityModal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import config from 'Src/business/tpsRepairs/config/brigadsListTable';
import { observer } from 'mobx-react';
import useStores from 'Store/useStores';
import { BRIGADE_DETAILS } from 'Shared/constants/routes';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';

const BrigadesList = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    brigadeStore: {
      brigadesList,
      totalPages,
      loadAll,
      isLoadingByPage,
      hasNextPage,
      delete: deleteBrigade,
      pageNumber,
      pageSize,
    },
    notificationStore: { enqueueSnackbar },
  } = useStores();
  const { columns } = config(t);
  const {
    setManageEntityModalVisibility, setOnManageEntityModalCloseCallback, setManageEntityModalState, renderManageEntityModal
  } = useManageEntityModal();

  const showLoadListError = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('REQUEST_BRIGADES_LIST_ERROR')
          }]
        }],
      },
      variant: 'error',
    });
  };

  const reloadBrigades = () => {
    if (brigadesList.length === 1 && pageNumber > 1) {
      loadAll(pageNumber - 1, pageSize).catch(() => showLoadListError());
      return;
    }

    loadAll(pageNumber, pageSize).catch(() => showLoadListError());
  };

  const handleDeleteBrigade = id => () => {
    setManageEntityModalVisibility(false);

    deleteBrigade(id)
      .then((isDeleted) => {
        if (isDeleted) {
          enqueueSnackbar({
            messageTemplate: {
              rows: [{
                rowContent: [{
                  type: 'text',
                  text: t('BRIGADE_SUCCESSFULLY_REMOVED')
                }]
              }],
            },
            variant: 'success',
          });
          reloadBrigades();
        } else {
          enqueueSnackbar({
            messageTemplate: {
              rows: [{
                rowContent: [{
                  type: 'text',
                  text: `${t('REMOVAL_IS_PROHIBITED')}${t('BRIGADE_HAS_ASSIGNED_TASK')}`
                }]
              }],
            },
            variant: 'error',
          });
        }
      })
      .catch(() => {
        enqueueSnackbar({
          messageTemplate: {
            rows: [{
              rowContent: [{
                type: 'text',
                text: t('REQUEST_DEFAULT_ERROR')
              }]
            }],
          },
          variant: 'error',
        });
      });
  };

  const removeHandler = (event, { id, name }) => {
    setManageEntityModalState(() => ({
      title: `${t('DELETING')} ${t('BRIGADE_TITLE_GENITIVE')}`,
      entityName: name,
      message: `${t('CONFIRM_DELETING_MESSAGE')} ${t('BRIGADE_TITLE_ACCUSATIVE')}?`,
      type: 'action'
    }));

    setOnManageEntityModalCloseCallback(() => handleDeleteBrigade(id, name));
    setManageEntityModalVisibility(true);
  };

  const onRowClickHandler = (event, row) => {
    history.push({
      pathname: `${BRIGADE_DETAILS}/${row.id}`
    });
  };

  return (
    <>
      {isLoadingByPage && <ProgressBar />}
      <DataTable
        titleSingle={t('BRIGADE_TITLE_ACCUSATIVE')}
        columns={columns}
        data={brigadesList}
        onRowClick={onRowClickHandler}
        count={totalPages}
        hasNextPage={hasNextPage}
        onPageChange={(page, size) => loadAll(page, size).catch(() => showLoadListError())}
        onPerPageChange={(page, size) => loadAll(page, size).catch(() => showLoadListError())}
        onRemove={removeHandler}
        actions={['remove']}
        dataTestRowSuffixEntityName="id"
      />
      {renderManageEntityModal()}
    </>
  );
});

export default BrigadesList;
