import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Divider, Collapse } from '@material-ui/core';
import CollapseButton from 'Common/components/Forms/Button/Collapse';

import useStyles from './styles';

const CollapseBlock = ({ headerLabel, children, isOpenedByDefault }) => {
  const classes = useStyles();
  const [isOpened, setIsOpened] = useState(isOpenedByDefault);
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.headerLabel} variant="h5" gutterBottom>
          {headerLabel}
        </Typography>
        <CollapseButton
          isCollapsed={!isOpened}
          handleClick={() => setIsOpened(!isOpened)}
        />
      </div>
      <Collapse
        in={isOpened}
      >
        {children}
      </Collapse>
      <Divider className={classes.divider} />
    </div>
  );
};

CollapseBlock.propTypes = {
  headerLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  isOpenedByDefault: PropTypes.bool,
};

CollapseBlock.defaultProps = {
  headerLabel: '',
  isOpenedByDefault: true,
};

export default CollapseBlock;
