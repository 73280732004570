import Agent from 'ApiAgents/Agent';
import getPaginationQueryObject from 'Src/utils/getPaginationQueryObject';


export default class CheckpointAgent extends Agent {
  getCheckpoints = (
    params = {},
    shouldPaginate,
    pageNumber,
    pageSize
  ) => {
    const pageQuery = getPaginationQueryObject(shouldPaginate, pageNumber, pageSize);

    return this.request({
      url: '/checkpoint/view',
      method: 'GET',
      params: { ...params, ...pageQuery }
    },
    'tasks');
  };

  getCheckpointById = id => (
    this.request({
      url: '/checkpoint/view/byId',
      method: 'GET',
      params: { id }
    },
    'tasks'));

  saveCheckpoint = (body = {}) => (
    this.request({
      url: '/checkpoint/manage/createorupdate',
      method: 'POST',
      data: body,
    },
    'tasks')
  );

  deleteCheckpoint = id => (
    this.request({
      url: '/checkpoint/manage/delete',
      method: 'DELETE',
      params: { id },
    },
    'tasks')
  );

  createCheckpoint = (data = {}) => (
    this.request({
      url: '/checkpoint/manage/create', // TODO CHANGE ENDPOINT ON INTEGRATION STAGE
      method: 'POST',
      data,
    },
    'tasks')
  );

  updateCheckpoint = (data = {}) => (
    this.request({
      url: '/checkpoint/manage/update', // TODO CHANGE ENDPOINT ON INTEGRATION STAGE
      method: 'PUT',
      data,
    },
    'tasks')
  );
}
