import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.black70,
  },
  negative: {
    color: theme.palette.negative,
  },
  checkpointNotScannedWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    lineHeight: 1.5,
  },
  checkpointNotScannedIcon: {
    marginRight: '8px!important',
    width: 24,
    height: 24
  },
}));

const CheckpointNotScannedBlock = ({
  title, iconClass, children, Icon
}) => {
  const classes = useStyles();

  return (
    <div className={classes.checkpointNotScannedWrapper}>
      <Tooltip title={title} classes={{ tooltip: classes.tooltip }}>
        <Icon className={clsx(classes.checkpointNotScannedIcon, iconClass || classes.negative)} />
      </Tooltip>
      { children }
    </div>
  );
};

CheckpointNotScannedBlock.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  iconClass: PropTypes.string,
  Icon: PropTypes.elementType.isRequired
};

CheckpointNotScannedBlock.defaultProps = {
  iconClass: ''
};

export default CheckpointNotScannedBlock;
