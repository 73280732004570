import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  generalParamsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  photosQualityWrapper: {
    width: 264,
    maxWidth: 264,
    marginRight: 16,
  },
  minAllowedSizeWrapper: {
    width: 300,
    maxWidth: 300,
    marginRight: 16,
  },
  osrNameWrapper: {
    flexGrow: 1,
    // minWidth: 240,
  },
  settingItem: {
    padding: '16px 16px 0px 16px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.16)',
    border: `1px solid ${theme.palette.midGray}`,
    borderRadius: 4,
    marginBottom: 8,
  },
  settingItemTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 1.5,
    marginBottom: 8,
  },
  settingItemParamsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  photosQuantityWrapper: {
    minWidth: 248,
    maxWidth: 248,
    marginRight: 16,
  },
  linkWrapper: {
    width: 'calc(100% - 248px - 16px)',
  },
  docLinkWrapper: {
    width: '100%',
  },
  endAdornmentLabel: {
    color: theme.palette.black30,
    marginLeft: 8,
  },
}));

export default useStyles;
