import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  isEmpty,
  get,
  head,
  upperFirst
} from 'lodash';
import { isBefore, isValid, add } from 'date-fns';
import useStores from 'Store/useStores';
import * as routes from 'Shared/constants/routes';
import { ButtonBase, Tooltip } from '@material-ui/core';
import { AddCircleOutline, Error, RemoveCircleOutline } from '@material-ui/icons';
import Panel, { PanelBox, PanelTitle } from 'Common/widgets/Layout/Panel/Panel';
import TextInput from 'Common/components/Forms/Input/Text';
import InputSelect from 'Common/components/Forms/Input/Select';
import RadioInput from 'Common/components/Forms/Input/Radio';
import SliderInput from 'Common/components/Forms/Input/Slider';
import DateInput from 'Common/components/Forms/Input/Date';
import NumberInput from 'Common/components/Forms/Input/Number';
import FormSwitch from 'Common/components/Forms/Input/FormSwitch';
import OptionRenderer from 'Common/components/Forms/OptionRenderer';
import OperationSelectOverlayNEW from 'Business/tpsInspections/components/OperationSelectOverlayNEW';
import AddEquipmentButton from 'Business/tpsInspections/components/AddEquipmentButton';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import useManageEntityModal from 'Common/components/Modal/ManageEntityModal/useManageEntityModal';
import PlannerTaskExecutionResult from 'Business/tpsInspections/components/PlannerTaskExecutionResult';
import PlannerTaskStatus from 'Business/tpsInspections/components/PlannerTaskStatus';
import PlannerCheckpointsBox from 'Business/tpsInspections/components/PlannerCheckpointsBox';
import { formatDate, formatTime } from 'Src/utils/datetime';
import { ReactComponent as OrderRequiredIcon } from 'Assets/order_required_icon.svg';
import { ReactComponent as IconFolder } from 'Assets/folder.svg';
import { ReactComponent as IconOpenFolder } from 'Assets/folder_open.svg';
import ActionButton from 'Common/components/Forms/Button/components/Action/Action';
import actionButtonTypes from 'Common/components/Forms/Button/configs/actionButtonTypes';
import PlannerTaskPauseModal from 'Src/business/tpsInspections/components/PlannerTaskPauseModal';
import {
  operationTypeTitles
} from 'Business/tpsInspections/config/operationTypeMapping';

import useStyles from './styles';

const MAX_TASKS_COUNT = 8;
const MIN_TASKS_COUNT = 1;

const PlannerTask = observer(({
  mode,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ mode });
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();

  const {
    plannerTaskStore: {
      plannerTask,
      isLoading,
      isSaving,
      isDeleting,
      loadPlannerTask,
      savePlannerTask,
      discardState,
      updateField,
      loadShiftsDictionary,
      loadShiftDurationDictionary,
      loadScheduleExecutionTypeDictionary,
      shiftsDictionary,
      shiftDurationDictionary,
      scheduleExecutionTypeDictionary,
      isDictionaryLoaded,
      loadPowerUnits,
      powerUnits,
      structureDictionary,
      addPowerUnitsToStructureDictionary,
      setSelectedCheckpoints,
      prepareForAdding,
      onCheckpointEdit,
      onTechnicalObjectEdit,
      onEditOperations,
      onDeleteCheckpoint,
      onDeleteTechnicalObject,
      onDeleteOperations,
      onUpdateCustomAction,
      shiftOptions = [],
      toggleCollapseCheckpoint,
      allCheckpointsAreExpanded,
      toggleCollapseAll,
      deletePlannerTask,
      changeStatus,
      loadPauseDictionary,
      pauseDictionary,
      isUpdatingReadyStatus,
      isUpdatingOnPauseStatus,
    },
    tasksStore: {
      dictionaries: {
        TpsInspectionRoles
      },
      getDictionaryByKeys,
    },
    validationStore: {
      validateRequiredFields, isValidFields, hasErrors, removeField
    },
    notificationStore: { enqueueSnackbar },
  } = useStores();

  const {
    title,
    role: { name: roleName } = {},
    executionType,
    startDate,
    finishDate,
    tasksCount,
    isShiftAcceptance,
    shiftDurationType,
    shiftIds = [],
    plannedDurationMinutes,
    executionResult,
    status,
    updatedBy,
    updatedAt,
    pauseReason = {},
    pauseReasonComment,
    checkpoints = [],
    isOrderRequired,
    isHightPriority,
  } = plannerTask;

  const isCommentRequired = get(pauseReason, 'isCommentRequired', false);
  const pauseReasonTitle = get(pauseReason, 'title', '');
  const pauseReasonValue = isCommentRequired ? pauseReasonComment : pauseReasonTitle;
  const isEmptyCheckpoints = isEmpty(checkpoints);
  const shouldLoadTaskById = ['edit', 'view'].includes(mode);
  const isReadonlyByMode = mode === 'view';
  const shiftDurationTypeOptions = shiftDurationDictionary && shiftDurationDictionary.map(item => ({ value: item.code, title: item.title }));

  const executionTypeOptions = scheduleExecutionTypeDictionary && scheduleExecutionTypeDictionary.map(item => ({ id: item.code, title: item.title }));

  const getExecutionTypeName = () => executionTypeOptions && executionTypeOptions.find(i => i.id === executionType).title;
  const getShiftDurationName = () => shiftDurationTypeOptions && shiftDurationTypeOptions.find(i => i.value === shiftDurationType).title;
  const getShiftName = () => (head(shiftIds) === 'all' ? t('ALL') : get(shiftsDictionary.find(({ id: shiftId } = {}) => shiftId === head(shiftIds)), 'title', ''));

  const [editableTaskId, setEditableTaskId] = useState('');
  const [isOperationOverlayOpen, setOperationOverlayVisibility] = useState(false);
  const {
    setManageEntityModalVisibility,
    setOnManageEntityModalCloseCallback,
    setManageEntityModalState,
    renderManageEntityModal
  } = useManageEntityModal();

  const loadDictionaryData = async () => {
    if (isEmpty(powerUnits)) await loadPowerUnits();
    if (isEmpty(structureDictionary)) await addPowerUnitsToStructureDictionary();
  };

  const handleAdd = async () => {
    await loadDictionaryData();
    await prepareForAdding();
    setOperationOverlayVisibility(true);
  };

  const handleOperationsOverlayClose = () => {
    setOperationOverlayVisibility(false);
  };

  const goToPlannerTasksList = () => {
    const { state } = location;
    const { paginationParams } = state || {};
    history.push({
      pathname: routes.PLANNER_TASKS,
      state: { paginationParams },
    });
  };

  const viewPlannerTask = (plannerTaskId) => {
    history.push({
      pathname: `${routes.VIEW_PLANNER_TASK}/${plannerTaskId}`,
    });
  };

  const handleDeletePlannerTask = plannerTaskId => async () => {
    const { error } = await deletePlannerTask(plannerTaskId) || {};

    if (error) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('REQUEST_DEFAULT_ERROR')
            }]
          }],
        },
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('TASK_SUCCESSFULLY_REMOVED')
          }]
        }],
      },
      variant: 'success',
    });
    goToPlannerTasksList();
  };

  const deleteItem = () => {
    if (mode !== 'view') return;
    setManageEntityModalState(() => ({
      title: t('DELETING_PLANNER_TASK'),
      entityName: title,
      message: `${t('CONFIRM_DELETING_MESSAGE')} ${t('JOB').toLowerCase()}?`,
      type: 'action',
      actionButtonTitle: t('DELETE'),
    }));
    setOnManageEntityModalCloseCallback(() => handleDeletePlannerTask(id));
    setManageEntityModalVisibility(true);
  };

  const handleDeleteCheckpoint = (checkpointId, checkpointTitle) => {
    setManageEntityModalState(() => ({
      title: t('DELETING_CHECKPOINT_FROM_TASK'),
      entityName: checkpointTitle,
      message: `${t('CONFIRM_DELETING_MESSAGE')} ${t('TAG_ACCUSATIVE')}?`,
      type: 'action',
      actionButtonTitle: t('DELETE'),
    }));
    setOnManageEntityModalCloseCallback(() => () => onDeleteCheckpoint(checkpointId));
    setManageEntityModalVisibility(true);
  };

  const handleDeleteTechnicalObject = ({ checkpoint, technicalObjectId, technicalObjectTitle }) => {
    setManageEntityModalState(() => ({
      title: `${t('DELETING_EQUIPMENT_FROM_CHECKPOINT')}`,
      entityName: technicalObjectTitle,
      message: `${t('CONFIRM_DELETING_MESSAGE')} ${t('EQUIPMENT').toLowerCase()}?`,
      type: 'action',
      actionButtonTitle: t('DELETE'),
    }));
    setOnManageEntityModalCloseCallback(() => () => onDeleteTechnicalObject({ checkpoint, technicalObjectId }));
    setManageEntityModalVisibility(true);
  };

  const handleDeleteOperations = ({ technicalObjectId, operationType, checkpoint }) => {
    setManageEntityModalState(() => ({
      title: `${t('DELETING_TASK_FROM_CHECKPOINT')}`,
      entityName: t(operationTypeTitles[operationType]),
      message: `${t('CONFIRM_DELETING_MESSAGE')} ${t('JOB').toLowerCase()}?`,
      type: 'action',
      actionButtonTitle: t('DELETE'),
    }));
    setOnManageEntityModalCloseCallback(() => () => onDeleteOperations({ technicalObjectId, operationType, checkpoint }));
    setManageEntityModalVisibility(true);
  };

  const editPlannerTask = () => {
    history.push({
      pathname: `${routes.EDIT_PLANNER_TASK}/${id}`
    });
  };

  const saveTask = async () => {
    validateRequiredFields();
    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: mode === 'create' ? t('CANT_CREATE') : t('CANT_UPDATE')
            }]
          }, {
            rowContent: [{
              type: 'text',
              text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS')
            }]
          }],
        },
        variant: 'error',
      });
      return;
    }
    const { result: { id: savedTaskId } = {} } = await savePlannerTask(mode, id) || {};
    if (savedTaskId) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: mode === 'create' ? t('PLANNER_TASK_SUCCESSFULLY_CREATED') : t('CHANGES_SUCCESSFULLY_APPLIED')
            }]
          }],
        },
        variant: 'success',
      });
      viewPlannerTask(savedTaskId);
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('REQUEST_DEFAULT_ERROR')
          }]
        }],
      },
      variant: 'error',
    });
  };

  const increaseTasksCount = () => {
    if (+tasksCount < +MAX_TASKS_COUNT) {
      updateField('tasksCount', +tasksCount + 1);
    }
  };

  const decreaseTasksCount = () => {
    if (+tasksCount > +MIN_TASKS_COUNT) {
      updateField('tasksCount', +tasksCount - 1);
    }
  };

  useEffect(() => {
    getDictionaryByKeys(['TpsInspectionRoles']);
    loadShiftsDictionary();
    loadShiftDurationDictionary();
    loadScheduleExecutionTypeDictionary();
    loadPauseDictionary();
  }, []);

  useEffect(() => {
    if (shouldLoadTaskById && id) {
      loadPlannerTask(id);
    }
  }, [id]);

  useEffect(() => () => { discardState(); }, [location.key]);

  if (isLoading || !isDictionaryLoaded) {
    return <ProgressBar />;
  }

  const renderIsOrderRequired = () => {
    if ((!isOrderRequired && isReadonlyByMode) || checkpoints.length < 2) return null;
    if (isOrderRequired && isReadonlyByMode) {
      return (
        <div className={classes.orderRequired}>
          <Tooltip title={t('ORDER_REQUIRED_IS_ON')}>
            <div className={classes.orderRequiredIconWrapper}>
              <OrderRequiredIcon className={classes.orderRequiredIcon} />
            </div>
          </Tooltip>
          <div>{t('ORDER_REQUIRED_SHORT')}</div>
        </div>
      );
    }
    return (
      <div className={classes.orderRequired}>
        <FormSwitch
          label={t('ORDER_REQUIRED')}
          name="isOrderRequired"
          checked={isOrderRequired}
          onChange={(e) => {
            const checked = get(e, 'target.checked');
            updateField('isOrderRequired', checked);
          }}
          data-test="isOrderRequired"
        />
      </div>
    );
  };

  const renderActionButtons = () => (
    <div className={classes.actionButtonsBlock}>
      <ActionButton
        disabled={status !== 'Ready'}
        data-test="pause"
        className={classes.actionButton}
        type={actionButtonTypes.CANCEL}
        text={t('SET_ON_PAUSE')}
        handleClick={() => {
          if (isUpdatingReadyStatus || isUpdatingOnPauseStatus) return;
          setEditableTaskId(id);
        }}
      />
      <ActionButton
        disabled={status !== 'OnPause'}
        data-test="run"
        className={classes.actionButton}
        type={actionButtonTypes.CONFIRM}
        text={t('RUN')}
        handleClick={async () => {
          if (isUpdatingReadyStatus || isUpdatingOnPauseStatus) return;
          await changeStatus({ id, status: 'Ready' });
          loadPlannerTask(id);
        }}
      />
    </div>
  );

  const renderShiftAcceptance = () => {
    if (!isReadonlyByMode && executionType !== 'OneTime') {
      return (
        <div className={classes.isShiftAcceptance}>
          <FormSwitch
            label={t('SHIFT_ACCEPTANCE')}
            name="isShiftAcceptance"
            checked={isShiftAcceptance}
            isReadonly={isReadonlyByMode}
            onChange={(e) => {
              const checked = get(e, 'target.checked');
              updateField('isShiftAcceptance', checked);
            }}
            data-test="switch-isShiftAcceptance"
          />
        </div>
      );
    }
    if (isReadonlyByMode && executionType !== 'OneTime' && isShiftAcceptance) {
      return (
        <div className={classes.shiftAcceptanceReadOnly}>
          <Error className={classes.shiftAcceptanceReadOnlyIcon} />
          <div>{t('SHIFT_ACCEPTANCE')}</div>
        </div>
      );
    }
  };

  const renderPriority = () => {
    if (!isReadonlyByMode && executionType === 'OneTime') {
      return (
        <div className={classes.isHightPriority}>
          <FormSwitch
            label={t('HIGH_PRIORITY')}
            name="isHightPriority"
            checked={isHightPriority}
            isReadonly={isReadonlyByMode}
            onChange={(e) => {
              const checked = get(e, 'target.checked');
              updateField('isHightPriority', checked);
            }}
            data-test="switch-isHightPriority"
          />
        </div>
      );
    }
    if (isReadonlyByMode && executionType === 'OneTime' && isHightPriority) {
      return (
        <div className={classes.priorityReadOnly}>
          <Error className={classes.priorityReadOnlyIcon} />
          <div>{t('HIGH_PRIORITY')}</div>
        </div>
      );
    }
    return null;
  };

  return !isLoading && (
    <Panel
      mode={mode}
      viewItem={() => viewPlannerTask(id)}
      editItem={editPlannerTask}
      saveItem={saveTask}
      goBack={goToPlannerTasksList}
      deleteItem={deleteItem}
      labelGoToView={t('GO_BACK')}
      labelGoToList={t('GO_BACK')}
      labelView={t('TAG_VIEW')}
      labelCreate={t('PLANNER_TASK_CREATION')}
      labelEdit={t('TAG_EDITING')}
      actions={['edit', 'delete']}
    >
      <div className={classes.content}>
        <div className={classes.scrollContent}>
          {(isSaving || isDeleting || isUpdatingReadyStatus || isUpdatingOnPauseStatus) && <ProgressBar />}
          <PanelBox boxStyles={{ maxWidth: 'inherit', padding: '0 24px' }}>
            <PanelTitle>{t('PLANNER_TASK_ATTRIBUTES')}</PanelTitle>
            {isReadonlyByMode && (
              <div style={{ display: 'flex', marginBottom: 27 }}>
                <div className={classes.lastExecutionResult}>
                  <h3>{t('LAST_EXECUTION_RESULT')}</h3>
                  <PlannerTaskExecutionResult executionResult={executionResult} />
                </div>
                <div className={classes.status}>
                  <h3>{t('STATE')}</h3>
                  <PlannerTaskStatus status={status} />
                </div>
              </div>
            )}
            <div style={{ display: 'flex' }}>
              <div style={{ flex: isReadonlyByMode ? 1 : 3 }}>
                <TextInput
                  className={classes.nameInput}
                  label={t('TITLE')}
                  value={title}
                  name="title"
                  isReadonly={isReadonlyByMode}
                  onChange={value => updateField('title', value)}
                  data-test="title"
                  required
                />
              </div>

              <div style={{ flex: isReadonlyByMode ? 1 : 2, marginLeft: 24 }}>
                <InputSelect
                  label={t('ROLE')}
                  value={roleName}
                  name="roleName"
                  isReadonly={isReadonlyByMode}
                  onChange={({ title: roleTitle } = {}) => updateField('role.name', roleTitle)}
                  options={TpsInspectionRoles ? Object.keys(TpsInspectionRoles).map(role => ({
                    value: TpsInspectionRoles[role],
                    title: TpsInspectionRoles[role]
                  })) : []}
                  optionValueKey="value"
                  optionTitleKey="title"
                  required
                  data-test="roleName"
                />
              </div>
            </div>
            {isReadonlyByMode && (
              <div style={{ display: 'flex' }}>
                <OptionRenderer
                  value={updatedBy}
                  title={t('AUTHOR')}
                  name="updatedBy"
                  wrapperClass={classes.optionRenderer}
                />
                <OptionRenderer
                  value={`${formatDate(updatedAt)} ${formatTime(updatedAt)}`}
                  title={t('UPDATE_DATE')}
                  name="updatedAt"
                  wrapperClass={classes.optionRenderer}
                />
              </div>
            )}

            {isReadonlyByMode && status === 'OnPause' && (
              <div style={{ display: 'flex' }}>
                <OptionRenderer
                  value={pauseReasonValue}
                  title={t('PAUSE_REASON')}
                  name="pauseReason"
                  wrapperClass={classes.optionRenderer}
                />
              </div>
            )}

          </PanelBox>
          <PanelBox boxStyles={{ maxWidth: 'inherit', padding: '0 24px' }}>
            <div className={classes.tagsBlockHeader}>
              <div className={classes.tagsBlockTitle}>{t('TAGS')}</div>
              {renderIsOrderRequired()}
              {isReadonlyByMode && (
                <>
                  <ButtonBase
                    disableRipple
                    className={classes.collapseButton}
                    onClick={toggleCollapseAll}
                  >
                    {allCheckpointsAreExpanded ? (
                      <IconFolder className={classes.folderIcon} />
                    ) : (
                      <IconOpenFolder className={classes.folderIcon} />
                    )}
                    <span>
                      {allCheckpointsAreExpanded ? t('UNCOLLAPSE_ALL') : t('COLLAPSE_ALL')}
                    </span>
                  </ButtonBase>
                </>
              )}
            </div>
            {!isEmptyCheckpoints && (
              <PlannerCheckpointsBox
                key="techPlaceId"
                techPlaceId="techPlaceId"
                withButtons={mode !== 'view'}
                title="techPlaceTitle"
                items={checkpoints}
                onChange={() => {}}
                isReadonlyByMode={isReadonlyByMode}
                setSelected={setSelectedCheckpoints}
                onDeleteCheckpoint={handleDeleteCheckpoint}
                onDeleteTechnicalObject={handleDeleteTechnicalObject}
                onDeleteOperations={handleDeleteOperations}
                onUpdateCustomAction={onUpdateCustomAction}
                onEditCheckpoint={async (checkpoint) => {
                  await loadDictionaryData();
                  await onCheckpointEdit(checkpoint);
                  setOperationOverlayVisibility(true);
                }}
                onEditTechnicalObject={async ({ checkpoint, technicalObjectId }) => {
                  await loadDictionaryData();
                  await onTechnicalObjectEdit({ checkpoint, technicalObjectId });
                  setOperationOverlayVisibility(true);
                }}
                onEditOperations={async ({ checkpoint, operationType, technicalObjectId }) => {
                  await loadDictionaryData();
                  await onTechnicalObjectEdit({ checkpoint, technicalObjectId });
                  await onEditOperations(operationType);
                  setOperationOverlayVisibility(true);
                }}
                toggleCollapseCheckpoint={toggleCollapseCheckpoint}
              />
            )}
            <AddEquipmentButton
              disabled={isReadonlyByMode}
              onClick={handleAdd}
              name="add-equipment-button"
              value={!isEmpty(checkpoints)}
              required
            />
          </PanelBox>

          <PanelBox boxStyles={{ maxWidth: 'inherit', padding: '0 24px' }}>
            <PanelTitle>{t('TASK_SCHEDULE')}</PanelTitle>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div className={classes.executionType}>
                <InputSelect
                  label={t('RUNTIME')}
                  value={isReadonlyByMode ? getExecutionTypeName() : executionType}
                  className={classes.executionType}
                  name="executionType"
                  isReadonly={isReadonlyByMode}
                  onChange={(value) => {
                    const type = get(value, 'id');
                    if (!type) return;
                    updateField('executionType', type);
                    if (executionType === 'Period') {
                      removeField('finishDate');
                      updateField('finishDate', '');
                      updateField('startDate', startDate);
                    }
                    if (type === 'OneTime') {
                      updateField('tasksCount', 1);
                      const { value: secondShift } = shiftOptions[1];
                      updateField('shiftIds', [secondShift]);
                    }
                  }}
                  options={executionTypeOptions}
                  optionValueKey="id"
                  optionTitleKey="title"
                  required
                  data-test="executionType"
                />
              </div>
              <div className={classes.dateInput}>
                <DateInput
                  required
                  maxDateMessage={t('START_DATE_CANNOT_BE_GREATER_THAN_THE_END_DATE')}
                  validationFunction={({ fieldName, validationState }) => {
                    const fieldValue = get(validationState, `${fieldName}.value`);
                    const finishDateValue = get(validationState, 'finishDate.value');
                    if (!fieldValue || !isValid(new Date(fieldValue))) {
                      return false;
                    }
                    if (finishDateValue && isValid(new Date(finishDateValue)) && isBefore(new Date(finishDateValue), new Date(fieldValue))) return false;
                    return isBefore(add(new Date(), { days: 1 }).setHours(0, 0, 0), new Date(fieldValue).setHours(0, 0, 59));
                  }}
                  label={t('START_DATE')}
                  name="startDate"
                  value={startDate}
                  minDate={add(new Date(), { days: 1 })}
                  {...finishDate && { maxDate: finishDate }}
                  isReadonly={isReadonlyByMode}
                  onChange={value => updateField('startDate', value)}
                  data-test="startDate"
                />
              </div>
              {
                executionType === 'Period' && (
                  <div className={classes.dateInput}>
                    <DateInput
                      disablePast
                      required
                      isReadonly={isReadonlyByMode}
                      label={t('END_DATE')}
                      name="finishDate"
                      value={finishDate}
                      minDateMessage={t('FINISH_DATE_CANNOT_BE_GREATER_THAN_START_DATE')}
                      minDate={isValid(new Date(startDate)) && isBefore(add(new Date(), { days: 1 }).setHours(0, 0), new Date(startDate).setHours(0, 0)) ? startDate : add(new Date(), { days: 1 })}
                      {...startDate && { minDate: startDate }}
                      onChange={value => updateField('finishDate', value)}
                      validationFunction={({ fieldName, validationState }) => {
                        const fieldValue = get(validationState, `${fieldName}.value`);
                        const startDateValue = get(validationState, 'startDate.value');
                        if (!fieldValue || !isValid(new Date(fieldValue))) {
                          return false;
                        }
                        if (isValid(new Date(startDateValue)) && isBefore(new Date(fieldValue), new Date(startDateValue))) return false;
                        return isBefore(add(new Date(), { days: 1 }).setHours(0, 0), new Date(fieldValue).setHours(0, 0, 59));
                      }}
                      data-test="finishDate"
                    />
                  </div>
                )
              }
              <div className={clsx(isReadonlyByMode || executionType === 'OneTime' ? classes.tasksCountReadonly : classes.tasksCount)}>
                <NumberInput
                  onlyPositiveNumbers
                  {...Boolean(tasksCount && tasksCount > MAX_TASKS_COUNT) && { error: true, helperText: `${t('MAX_AMOUNT')} ${MAX_TASKS_COUNT}` }}
                  {...Boolean(tasksCount && tasksCount < MIN_TASKS_COUNT) && { error: true, helperText: `${t('MIN_AMOUNT')} ${MIN_TASKS_COUNT}` }}
                  label={t('TASKS_PER_SHIFT')}
                  name="tasksCount"
                  value={tasksCount}
                  onChange={value => updateField('tasksCount', value)}
                  isReadonly={isReadonlyByMode || executionType === 'OneTime'}
                  InputEndAdornment={!isReadonlyByMode && executionType !== 'OneTime' && (
                    <div>
                      <button className={classes.tasksCountButton} type="button" onClick={decreaseTasksCount}><RemoveCircleOutline /></button>
                      <button className={classes.tasksCountButton} type="button" onClick={increaseTasksCount}><AddCircleOutline /></button>
                    </div>
                  )}
                  tooltip={t('THE_INTERVAL_OF_DAYS_BETWEEN_RECEIVING_A_TASK_ON_A_MOBILE_DEVICE')}
                  required
                  data-test="tasksCount"
                />
              </div>
              {renderShiftAcceptance()}
              {renderPriority()}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              {isReadonlyByMode ? (
                <OptionRenderer
                  value={getShiftDurationName()}
                  title={t('SHIFT_TYPE')}
                  name="shiftDurationType"
                  wrapperClass={classes.shiftType}
                />
              ) : (
                <RadioInput
                  className={classes.shiftTypeInput}
                  label={t('SHIFT_TYPE')}
                  value={shiftDurationType}
                  name="shiftDurationType"
                  onChange={(value) => {
                    updateField('shiftDurationType', value);
                    const { id: secondShift } = shiftsDictionary.filter(shift => shift.shiftDurationType === value)[1];
                    updateField('shiftIds', [secondShift]);
                  }}
                  options={shiftDurationTypeOptions}
                  data-test="shiftDurationType"
                />
              )
              }

              {
                isReadonlyByMode ? (
                  <OptionRenderer
                    value={getShiftName()}
                    title={t('SHIFT')}
                    name="shiftIds"
                    wrapperClass={classes.shiftDuration}
                  />
                ) : (
                  <RadioInput
                    className={classes.shiftInput}
                    label={t('SHIFT')}
                    value={head(shiftIds)}
                    onChange={value => updateField('shiftIds', [value])}
                    name="shiftIds"
                    options={executionType !== 'OneTime'
                      ? [{ value: 'all', title: t('ALL') }, ...shiftOptions]
                      : shiftOptions}
                    data-test="shiftIds"
                  />
                )
              }
              <SliderInput
                label={t('PLANNED_DURATION')}
                isReadonly={isReadonlyByMode}
                value={plannedDurationMinutes}
                min={1}
                max={60}
                step={10}
                name="plannedDurationMinutes"
                onChange={value => updateField('plannedDurationMinutes', value)}
                unitLabel={<div className={classes.unitLabel}>{`${upperFirst(t('MIN'))}.`}</div>}
                OptionRendererProps={{ wrapperClass: classes.plannedDuration }}
              />
            </div>
          </PanelBox>
          <OperationSelectOverlayNEW
            isOpen={isOperationOverlayOpen}
            handleClose={handleOperationsOverlayClose}
          />
        </div>
        {isReadonlyByMode && renderActionButtons()}
      </div>
      {renderManageEntityModal()}
      {editableTaskId && (
        <PlannerTaskPauseModal
          title={title}
          reasons={pauseDictionary}
          isOpened
          onClose={() => {
            setEditableTaskId(false);
          }}
          onSave={async (pauseReasonId, comment) => {
            setEditableTaskId(false);
            await changeStatus({
              id: editableTaskId, status: 'OnPause', pauseReasonId, pauseReasonComment: comment
            });
            loadPlannerTask(id);
          }}
        />
      )}
    </Panel>
  );
});

PlannerTask.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func
};

PlannerTask.defaultProps = {
  id: 0,
};

export default PlannerTask;
