import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { HighlightOff, NavigateNext } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import {
  Radio, Tooltip, Checkbox, ListItem, ListItemIcon, ListItemText
} from '@material-ui/core/';
import Divider from '@material-ui/core/Divider';
import { observer } from 'mobx-react';

const useStyles = makeStyles(theme => ({
  root: {
    '&:hover': {
      '& $hideEndAdornmentOnBlur': {
        display: 'block',
      },
    },
  },
  listItemPrimaryAction: {
    minWidth: 0,
    marginRight: 8,
  },
  activeListItem: {
    backgroundColor: theme.palette.lightSelection,
    '& $defaultNextIcon': {
      color: theme.palette.primaryLight,
    },
  },
  defaultAction: {
    color: theme.palette.midGray,
  },
  activeAction: {
    color: theme.palette.primaryLight,
  },
  navigateNext: {
    color: theme.palette.primaryLight,
  },
  notActiveWithinSelectedAction: {
    color: '#ffff',
  },
  defaultNextIcon: {
    color: theme.palette.black20,
  },
  activeNextIcon: {
    color: theme.palette.primaryLight,
  },
  detached: {
    marginTop: theme.spacing(1),
    boxShadow: '0 1px 3px 0 #f3f3f3',
    border: '1px solid',
    borderColor: theme.palette.midGray,
  },
  primaryText: {
    fontWeight: 'bold',
  },
  hideEndAdornmentOnBlur: {
    display: 'none',
  },
  action: {
    color: ({ error }) => (error ? theme.palette.negative : theme.palette.black70),
  },
  hoveredAction: {
    '&:hover': {
      borderRadius: '50%',
      boxShadow: `0 0 0 10px ${theme.palette.fillLightSecondaryHover}`,
    },
  },
  defaultActionMargin: {
    marginRight: 10,
  },
}));

const icons = {
  default: HighlightOff,
  check: Checkbox,
  radio: Radio
};

const SectionListItem = ({
  title,
  subTitle,
  onItemClick,
  actionFunc,
  variant,
  noNextIcon,
  isTreePassed,
  checked,
  isCurrentlySelected,
  detached,
  noAction,
  error,
  stopPropagationOnActionButton,
  disabled,
  endAdornment,
  hideEndAdornmentOnBlur
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ error });
  const Action = icons[variant];

  const getActionClassName = () => {
    if (isTreePassed) return classes.activeAction;
    if (isCurrentlySelected) return classes.notActiveWithinSelectedAction;

    return classes.defaultAction;
  };

  const renderAction = () => {
    switch (variant) {
      case 'check':
      case 'radio':
        return (
          <Action
            data-test={`checkpointAction_${subTitle}`}
            color="primary"
            checked={checked}
            className={classes.action}
            edge="start"
          />
        );
      default:
        if (!isTreePassed) return (<Action className={clsx(classes.defaultActionMargin, getActionClassName())} />);
        return (
          <Tooltip title={t('CLEAR')}>
            <Action className={clsx(getActionClassName(), classes.defaultActionMargin, classes.hoveredAction)} />
          </Tooltip>
        );
    }
  };

  return (
    <>
      <ListItem
        disabled={disabled}
        button
        onClick={onItemClick}
        selected={isCurrentlySelected}
        classes={{ selected: classes.activeListItem, root: classes.root }}
        className={clsx(detached && classes.detached)}
      >
        {!noAction && (
        <ListItemIcon
          onClick={(e) => {
            if (stopPropagationOnActionButton) {
              e.preventDefault();
              e.stopPropagation();
            }
            actionFunc(e);
          }}
          className={classes.listItemPrimaryAction}
        >
          {renderAction()}
        </ListItemIcon>
        )}
        <ListItemText
          primary={title}
          secondary={subTitle}
          classes={{ primary: clsx(subTitle && classes.primaryText) }}
        />
        {endAdornment && (<div className={clsx(hideEndAdornmentOnBlur && classes.hideEndAdornmentOnBlur)}>{endAdornment}</div>)}
        {noNextIcon !== true
          && <NavigateNext className={clsx(isTreePassed ? classes.navigateNext : classes.defaultNextIcon)} />}
      </ListItem>
      {!detached
        && (<Divider className={classes.defaultAction} />)
      }
    </>
  );
};

SectionListItem.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  noNextIcon: PropTypes.bool,
  detached: PropTypes.bool,
  isTreePassed: PropTypes.bool,
  checked: PropTypes.bool,
  variant: PropTypes.string,
  actionFunc: PropTypes.func,
  onItemClick: PropTypes.func.isRequired,
  noAction: PropTypes.bool,
  isCurrentlySelected: PropTypes.bool,
  error: PropTypes.bool,
  stopPropagationOnActionButton: PropTypes.bool,
  disabled: PropTypes.bool,
  endAdornment: PropTypes.node,
  hideEndAdornmentOnBlur: PropTypes.bool,
};

SectionListItem.defaultProps = {
  variant: 'default',
  subTitle: '',
  noNextIcon: false,
  isTreePassed: false,
  checked: false,
  isCurrentlySelected: false,
  detached: false,
  noAction: false,
  error: false,
  actionFunc: () => {},
  stopPropagationOnActionButton: false,
  disabled: false,
  endAdornment: null,
  hideEndAdornmentOnBlur: false,
};

export default observer(SectionListItem);
