import React from 'react';

import useStyles from './styles';

const PlFAQ = () => {
  const classes = useStyles();

  return (
    <>
      <h1 className={classes.title}>Вопросы по бизнесу Сети</h1>

      <div className={classes.question}>
        - Как создавать задачи для «Сети»?
      </div>
      <div className={classes.answer}>
        <p>
          Задачи для «Сети» не создаются вручную, а получаются непосредственно из SAP
          сразу на мобильное устройство исполнителя.
        </p>
      </div>

      <div className={classes.question}>
        - Как создать свой параметр объема для «Сети»?
      </div>
      <div className={classes.answer}>
        <p>
          Зайдите в раздел добавления параметра (Редактирование физ. объема – добавить параметр). Выберите любой номер выпадающего списка, введите/измените его название, выберите необходимые ключи и нажмите кнопку «Сохранить».
        </p>
      </div>

      <div className={classes.question}>
        - Что такое “Отображение на устройстве по ключу”?
      </div>
      <div className={classes.answer}>
        <p>
          Фильтр по ключам, для быстрого поиска и просмотра необходимых видов объёмов.
        </p>
      </div>

      <div className={classes.question}>
        - Как задать последовательность отображения объёмов у исполнителя?
      </div>
      <div className={classes.answer}>
        <p>
          В задачах объёмы будут отображаться в той же последовательности, что была
          установлена в режиме редактирования вкладки объёма.
        </p>
      </div>

      <div className={classes.question}>
        - Какие типы объемов можно создать?
      </div>
      <div className={classes.answer}>
        <ol>
          <li>
            Ручной ввод - исполнителю необходимо будет заполнить необходимые данные
            вручную.
          </li>
          <li>
            Подтверждение наличия/отсутствия - исполнителю необходимо будет отметить
            чекбоксом наличие необходимых данных.
          </li>
          <li>
            Выбор из справочника - исполнителю необходимо будет выбрать из
            выпадающего списка необходимое значение.
          </li>
        </ol>
      </div>
    </>
  );
};

export default PlFAQ;
