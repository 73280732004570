import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useStores from 'Store/useStores';
import * as routes from 'Shared/constants/routes';
import OptionRenderer from 'Common/components/Forms/OptionRenderer';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import Panel, { PanelBox, PanelTitle } from 'Common/widgets/Layout/Panel/Panel';
import FormSwitch from 'Common/components/Forms/Input/FormSwitch';
import TextInput from 'Common/components/Forms/Input/Text';
import isUrl from 'Src/utils/urlCheck';
import { ADMINISTRATOR_OCP } from 'Shared/constants/roles';

import useStyles from './styles';

const minDistance = 5;
const maxDistance = 1000;

const PresenceControlItem = ({ mode }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const classes = useStyles();
  const { id } = params;

  const isViewMode = mode === 'view';

  const {
    presenceControlStore: {
      formData,
      loadById,
      isItemLoading,
      update,
      updateFormData,
    },
    userStore: { role },
    notificationStore: { enqueueSnackbar },
    validationStore: { validateRequiredFields, isValidFields, hasErrors },
  } = useStores();

  const [isDistanceValid, setIsDistanceValid] = useState(true);
  const [isLinkValid, setIsLinkValid] = useState(true);

  const {
    name, distance = '', mapLink, isAlternativeEnabled
  } = formData;

  const goBack = () => {
    const { state } = location;
    const { fromPath, ...rest } = state || {};
    history.push({
      pathname: fromPath || routes.PRESENCE_CONTROL,
      state: rest,
    });
  };

  const editItem = () => {
    const { state } = location;
    history.push({
      pathname: `${routes.PRESENCE_CONTROL}/edit/${id}`,
      state
    });
  };

  const viewItem = () => {
    const { state } = location;
    const newPath = role === ADMINISTRATOR_OCP ? `${routes.PRESENCE_CONTROL}/view/${id}` : routes.PRESENCE_CONTROL;
    history.push({
      pathname: newPath,
      state
    });
  };

  const updateItem = async () => {
    validateRequiredFields();
    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('CANT_UPDATE'),
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    const { res } = await update();
    if (res) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('CHANGES_SUCCESSFULLY_APPLIED'),
                },
              ],
            },
          ],
        },
        variant: 'success',
      });
    }
    if (!res) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('CANT_SAVE'),
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('SERVER_IS_NOT_AVAILABLE'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    viewItem();
  };

  const showDefaultErrorNotification = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('REQUEST_DEFAULT_ERROR'),
              },
            ],
          },
        ],
      },
      variant: 'error',
    });
  };

  useEffect(() => {
    loadById(id).catch(() => showDefaultErrorNotification());
  }, []);

  useEffect(() => {
    loadById(id).catch(() => showDefaultErrorNotification());
  }, [mode]);

  return (
    <>
      {isItemLoading && <ProgressBar />}
      <Panel
        mode={mode}
        saveItem={updateItem}
        viewItem={viewItem}
        editItem={editItem}
        goBack={goBack}
        labelGoToList={t('GO_BACK')}
        labelEdit={t('PRESENCE_CONTROL')}
        labelView={t('PRESENCE_CONTROL')}
        actions={['edit']}
        withActions
      >
        <PanelBox>
          <PanelTitle>{t('PRESENCE_CONTROL_PARAMS')}</PanelTitle>
          <OptionRenderer
            value={name || t('INPUT_EMPTY_VALUE')}
            title={t('OSR_NAME')}
            name="name"
          />
          <div className={classes.block}>
            <div className={classes.column}>
              <TextInput
                // OptionRendererProps={{ withOverflowTip: false }}
                error={!isDistanceValid}
                // marginTop={1}
                // marginBottom={1}
                label={t('MAX_DIST_TO_OBJECT')}
                value={distance}
                name="distance"
                isReadonly={isViewMode}
                InputEndAdornment={(
                  <span className={classes.unitLabel}>
                    {t('METERS_UNIT_SHORT')}
                  </span>
                )}
                onBlur={() => {
                  if (distance && (distance < minDistance || distance > maxDistance)) setIsDistanceValid(false);
                }}
                onChange={(value = '') => {
                  const onlyDigitsValue = value.replace(/\D/g, '');
                  const valueWithoutLeadingZeros = onlyDigitsValue.replace(/^0+/, '');
                  updateFormData('distance', valueWithoutLeadingZeros);
                  setIsDistanceValid(true);
                }}
                required
                requiredMessage={`${t('REQUIRED')} (${minDistance} - ${maxDistance})`}
                data-test="distance"
              />
            </div>
            <div className={classes.column}>
              <FormSwitch
                className={classes.switch}
                label={t('ALTERNATIVE_SCENARIO')}
                name="isAlternativeEnabled"
                isReadonly={isViewMode}
                checked={isAlternativeEnabled}
                onChange={() => updateFormData('isAlternativeEnabled', !isAlternativeEnabled)}
                data-test="switch-isAlternativeEnabled"
              />
            </div>
          </div>
          <TextInput
            error={!isLinkValid}
            label={t('MAP_LINK')}
            value={mapLink}
            name="mapLink"
            onChange={(value) => {
              setIsLinkValid(true);
              updateFormData('mapLink', value);
            }}
            onBlur={() => { if (mapLink) setIsLinkValid(isUrl(mapLink)); }}
            isReadonly={isViewMode}
            data-test="mapLink"
          />
        </PanelBox>
      </Panel>
    </>
  );
};

PresenceControlItem.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default observer(PresenceControlItem);
