import { formatDate } from 'Src/utils/datetime';
import withStyles from 'Src/utils/table/withColumnStyles';

const tableColumns = t => (
  {
    title: {
      field: 'title',
      title: t('NAME'),
      mainLink: true,
      withEllipsis: true,
      width: '30%',
      cellStyle: {
        width: '30%',
        whiteSpace: 'normal',
      },
    },
    rootTechnicalObjectIds: {
      field: 'equipmentName',
      title: t('EQUIPMENT'),
      withEllipsis: true,
      cellStyle: {
        whiteSpace: 'normal',
        overflowWrap: 'anywhere',
      },
    },
    assignments: {
      field: 'currentAssignee',
      title: t('EMPLOYEE'),
      align: 'center',
      withEllipsis: true,
    },
    plannedStartDate: {
      field: 'plannedStartDate',
      title: t('EXPECTED_START_DATE'),
      align: 'center',
      render: ({ plannedStartDate }) => formatDate(plannedStartDate)
    },
    expectedDate: {
      field: 'expectedDate',
      title: t('EXECUTION_PERIOD'),
    },
  }
);

export const materialValuesAgreementsConfig = (t) => {
  const translatedTableColumns = tableColumns(t);
  return (
    {
      NEW: {
        columns: [
          withStyles(translatedTableColumns.title, { width: '30%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.rootTechnicalObjectIds, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.assignments, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.plannedStartDate, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.expectedDate, { width: '15%', whiteSpace: 'normal' }),
        ],
        actions: []
      },
      RETURNED: {
        columns: [
          withStyles(translatedTableColumns.title, { width: '30%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.rootTechnicalObjectIds, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.assignments, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.plannedStartDate, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.expectedDate, { width: '15%', whiteSpace: 'normal' }),
        ],
        actions: []
      },
      APPROVED: {
        columns: [
          withStyles(translatedTableColumns.title, { width: '30%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.rootTechnicalObjectIds, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.assignments, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.plannedStartDate, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.expectedDate, { width: '15%', whiteSpace: 'normal' }),
        ],
        actions: []
      },
    }
  );
};

export default tableColumns;
