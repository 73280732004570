import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  businessName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    position: 'relative',
    paddingLeft: 18,
    paddingRight: 16,
    fontSize: 24,
    lineHeight: 2,
    color: theme.palette.black,
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      background: theme.palette.black20,
      top: 'calc(50% - 15px)',
      height: 30,
      left: 0,
      width: 1,
    },
  },
  grow: {
    flexGrow: 1,
  },
  fullNameWrapper: {
    position: 'relative',
    paddingLeft: '15px',
    marginLeft: '15px',
    marginRight: 16,
    borderLeft: `1px solid ${theme.palette.black20}`
  },
  appBarBackground: {
    backgroundColor: theme.palette.white,
    color: theme.palette.black,
    zIndex: theme.zIndex.drawer + 1,
    '& .MuiToolbar-root': {
      marginLeft: 64,
    },
  },
  imageIcon: {
    height: '100%',
    display: 'block'
  },
  iconRoot: {
    width: '60px',
    marginRight: 16,
  },
  avatar: {
    width: 24,
    height: 24,
  },
  userNameTitle: {
    fontSize: 12,
    lineHeight: 1.33,
    color: theme.palette.black,
    fontWeight: 'bold',
  },
  userRoleTitle: {
    fontSize: 12,
    lineHeight: 1.33,
    color: theme.palette.black30,
  },
  logoutButton: {
    marginLeft: 8,
  },
}));

export default useStyles;
