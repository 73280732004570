import React, {
  useRef, useImperativeHandle, forwardRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { find, get, isNull } from 'lodash';
import clsx from 'clsx';
import {
  TextField, InputAdornment, SvgIcon, Checkbox
} from '@material-ui/core';

import OptionRenderer from 'Common/components/Forms/OptionRenderer';
import withValidation from 'Src/HOC/withValidation';

import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  CheckBoxOutlineBlank,
  Search,
  CheckBox,
  CancelOutlined,
} from '@material-ui/icons/';

import useStyles from './styles';

const Select = forwardRef((props, ref) => {
  const {
    className,
    helperText,
    errorMessage,
    getOptionTitle,
    optionTitleKey,
    optionValueKey,
    withSearch,
    customRender,
    value,
    name,
    label,
    placeholder,
    optionWithCheckbox,
    isReadonly,
    onChange,
    error,
    required,
    InputEndAdornment,
    maxWidth,
    marginBottom,
    marginTop,
    disabled,
    requiredMessage,
    multiline,
    'data-test': dataTest,
    OptionRendererProps,
    options,
    getOptionDisabled,
    disableClearable,
  } = props;

  const selfRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      selfRef.current.focus();
    },
    blur: () => {
      selfRef.current.blur();
    }
  }));

  const showIsRequiredLabel = required && !isReadonly; // eslint-disable-line

  const { t } = useTranslation();
  const classes = useStyles({
    value,
    maxWidth,
    marginBottom,
    marginTop,
    error,
    disabled,
    helperText,
    requiredMessage,
    t
  });

  const autocompleteClasses = {
    clearIndicator: clsx(!value && classes.hidden),
    popper: classes.popper
  };
  const inputValue = find(options, { [optionValueKey]: value }) || null;

  const [openedTips, seOpenedTips] = useState(false);

  if (isReadonly) {
    return (
      <OptionRenderer
        title={label}
        value={value || t('INPUT_EMPTY_VALUE')}
        endAdornmentNode={InputEndAdornment}
        maxWidth={maxWidth}
        {...OptionRendererProps}
        {...dataTest && { 'data-test': dataTest }}
      />
    );
  }

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Autocomplete
          disableClearable={disableClearable}
          closeIcon={(
            <SvgIcon className={classes.inputClearIcon} component={CancelOutlined} />
          )}
          onOpen={() => seOpenedTips(true)}
          onClose={() => seOpenedTips(false)}
          noOptionsText={t('NOTHING_FOUND')}
          clearText=""
          closeText=""
          openText=""
          openOnFocus
          disablePortal
          getOptionDisabled={getOptionDisabled}
          value={inputValue}
          id={name}
          classes={autocompleteClasses}
          onChange={(event, val) => {
            onChange(val, name);
          }}
          options={options}
          disabled={Boolean(isReadonly) || disabled}
          getOptionLabel={(option) => {
            if (getOptionTitle) return getOptionTitle(option);
            const {
              [optionValueKey]: optionValue,
              [optionTitleKey]: optionTitle = ''
            } = option;
            return (!isNull(optionTitle) ? optionTitle || '' : optionValue || '').toString();
          }}
          renderOption={(option, { selected }) => (
            <>
              {customRender
                ? customRender(option)
                : (
                  <div
                    className={classes.option}
                  >
                    {optionWithCheckbox && (
                      <Checkbox
                        icon={<CheckBoxOutlineBlank />}
                        checkedIcon={<CheckBox color="primary" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                    )}
                    {getOptionTitle ? getOptionTitle(option) : option[optionTitleKey]}
                  </div>
                )}
            </>
          )}
          renderInput={({
            inputProps: inputDefinedProps = {}, InputProps: InputDefinedProps, ...rest
          } = {}) => {
            const textFieldValue = get(inputDefinedProps, 'value', '');
            const showSearchIcon = withSearch && openedTips && (!value || textFieldValue !== value.toString());
            const newParams = {
              inputProps: {
                ...inputDefinedProps,
                // autoComplete: 'none',
                readOnly: !withSearch,
                ...dataTest && { 'data-test': `${dataTest}-input` },
              },
              ...rest,
              InputProps: {
                ...InputDefinedProps,
                ...showSearchIcon && openedTips && {
                  startAdornment: (
                    <InputAdornment style={{ color: '#a1a1a1' }}>
                      <Search />
                    </InputAdornment>
                  )
                },
              }
            };
            return (
              <TextField
                autoComplete="off"
                data-test={dataTest}
                multiline={multiline}
                type="text"
                onKeyDown={(event) => {
                  if (withSearch) return;
                  event.stopPropagation();
                  event.preventDefault();
                }}
                onPaste={(event) => {
                  if (withSearch) return;
                  event.stopPropagation();
                  event.preventDefault();
                }}
                {...{ ...openedTips && { placeholder } }}
                classes={{
                  root: clsx(
                    classes.textField,
                    !withSearch && classes.styledLikeButton,
                    Boolean(className) && className,
                  )
                }}
                {...newParams}
                variant="outlined"
                margin="dense"
                label={label}
                error={error}
              />
            );
          }}
        />
        <div className={classes.helper}>
          {required && (
            <div className={classes.required}>
              {requiredMessage || t('REQUIRED')}
            </div>
          )}
          {errorMessage && (
            <div className={classes.required}>
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    </>
  );
});

Select.defaultProps = {
  options: [],
  errorMessage: undefined,
  optionValueKey: '',
  optionTitleKey: '',
  getOptionTitle: undefined,
  customRender: undefined,
  getOptionDisabled: () => false,
  optionWithCheckbox: false,
  withSearch: false,
  className: undefined,
  autoFocus: false,
  helperText: '',
  value: '',
  maxLength: undefined,
  label: '',
  placeholder: '',
  inputProps: {},
  isReadonly: false,
  error: false,
  required: false,
  InputStartAdornment: null,
  InputEndAdornment: null,
  maxWidth: undefined,
  marginBottom: undefined,
  marginTop: undefined,
  disabled: false,
  requiredMessage: '',
  style: {},
  multiline: false,
  rows: undefined,
  rowsMax: undefined,
  'data-test': undefined,
  onBlur: undefined,
  onClickEndAdornment: undefined,
  OptionRendererProps: {},
  disableClearable: false,
};

Select.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  optionValueKey: PropTypes.string,
  optionTitleKey: PropTypes.string,
  getOptionTitle: PropTypes.func,
  customRender: PropTypes.func,
  getOptionDisabled: PropTypes.func,
  optionWithCheckbox: PropTypes.bool,
  withSearch: PropTypes.bool,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  inputProps: PropTypes.shape({ 'data-test': PropTypes.string }),
  isReadonly: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  InputStartAdornment: PropTypes.node,
  InputEndAdornment: PropTypes.node,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number,
  disabled: PropTypes.bool,
  requiredMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  style: PropTypes.shape({
    marginTop: PropTypes.number,
  }),
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  'data-test': PropTypes.string,
  onBlur: PropTypes.func,
  onClickEndAdornment: PropTypes.func,
  OptionRendererProps: PropTypes.shape({
    maxLine: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    valueRenderer: PropTypes.func,
    wrapperClass: PropTypes.string,
    titleClass: PropTypes.string,
    valueClass: PropTypes.string,
    titlePosition: PropTypes.oneOf(['left', 'right']),
  }),
  disableClearable: PropTypes.bool,
};

export default withValidation(Select);
