import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import Can from 'Common/components/Authorization/Can';
import * as routes from 'Shared/constants/routes';

const PrivateRoute = ({
  component: Component, permission, redirectUrl, ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      <Can
        perform={permission}
        yes={() => (
          <Component {...props} />
        )}
        no={() => (
          <Redirect to={{ pathname: redirectUrl || routes.NO_ACCESS }} />
        )}
      />
    )}
  />
);

PrivateRoute.defaultProps = {
  redirectUrl: '',
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  permission: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string,
};

export default PrivateRoute;
