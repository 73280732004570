import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  find, cloneDeep, isEmpty, isUndefined
} from 'lodash';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import HeaderLayout from 'Common/widgets/Layout/Header/Header';
import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import Breadcrumbs from 'Business/tpsInspections/components/Breadcrumbs';
import Breadcrumb from 'Business/tpsInspections/components/Breadcrumb';
import Subheader from 'Business/tpsInspections/components/Subheader';
import SectionHeader from 'Common/widgets/Section/SectionHeader';
import useStores from 'Store/useStores';
import OperationsLayout from 'Business/tpsRepairs/components/OperationsLayout';
import OperationView from 'Common/widgets/OperationView/OperationView';
import NumberInput from 'Common/components/Forms/Input/Number';
import getAggregatedMaterialValuesByOperations from 'Business/tpsRepairs/utils/getAggregatedMaterialValuesByOperations';

import useStyles from './styles';


const MaterialValuesEditorOverlay = observer(({
  isOpen, handleClose, operations = [], materials
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    materialValuesControlStore: {
      updateOperations,
    },
    notificationStore: { enqueueSnackbar },
  } = useStores();

  const [{ materialValues: [{ description = '' } = {}] = [] } = {}] = operations;

  const [operationsToEdit, setOperationsToEdit] = useState(operations);

  useEffect(() => {
    if (isEmpty(operations)) return;
    setOperationsToEdit(operations.map(({
      materialValues,
      materialValueConsumptions,
      ...restOperationOptions
    }) => ({
      materialValueConsumptions,
      materialValues: materialValues.map(({
        factQuantity,
        materialValueId,
        updatedQuantity,
        ...restMaterialValueOptions
      }) => ({
        factQuantity,
        updatedQuantity: !isUndefined(updatedQuantity)
          ? updatedQuantity
          : materialValueConsumptions.reduce((acc, consumptionMaterial) => ((consumptionMaterial.materialValueId === materialValueId) ? consumptionMaterial.quantity : acc), 0),
        materialValueId,
        ...restMaterialValueOptions,
        mergedQuantity: !isUndefined(updatedQuantity)
          ? updatedQuantity
          : materialValueConsumptions.reduce((acc, consumptionMaterial) => ((consumptionMaterial.materialValueId === materialValueId) ? consumptionMaterial.quantity : acc), 0)
      })),
      ...restOperationOptions
    })));
  }, [operations]);

  const materialValuesAcc = getAggregatedMaterialValuesByOperations(operationsToEdit);
  const [{ factQuantity: fact = 0, quantity = 0, mergedQuantity, unit: materialValueUnit } = {}] = materialValuesAcc; // eslint-disable-line

  const handleConfirm = () => {
    let isValid = true;
    operationsToEdit.forEach(({ materialValues }) => {
      const hasSomeNotValidValue = materialValues.some(({ notValid }) => notValid);
      if (hasSomeNotValidValue) isValid = false;
    });
    if (!isValid) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: `${t('INVALID_QUANTITY_SPECIFIED')} ${t('MATERIAL_VALUE_SHORT')}`,
            }]
          },
          {
            rowContent: [{
              type: 'text',
              text: t('FACT_QUANTITY_CANNOT_BE_MORE_THAN_PLANNED'),
            }]
          }],
        },
        variant: 'warning',
      });
      return;
    }
    const updatedOperations = operationsToEdit.map(({ materialValues, ...restOperationOptions }) => ({ materialValues: materialValues.map(({ updatedQuantity, ...restMaterialValueOptions }) => ({ updatedQuantity: +updatedQuantity, ...restMaterialValueOptions })), ...restOperationOptions }));
    updateOperations(updatedOperations);
    handleClose();
  };

  const onChangeMaterialValue = ({ val, operationId, sapCode }) => {
    const allOperations = cloneDeep(operationsToEdit);
    const editableOperation = find(allOperations, { operationId });
    const editableMaterialValue = find(editableOperation.materialValues, { sapCode });
    const { quantity: editableMaterialValueQuantity, notValid } = editableMaterialValue;
    if (notValid && val <= editableMaterialValueQuantity) editableMaterialValue.notValid = false;
    if (val && val > 0 && val > editableMaterialValueQuantity) {
      editableMaterialValue.notValid = true;
    }
    editableMaterialValue.updatedQuantity = val;
    setOperationsToEdit(allOperations);
  };

  return (
    <Dialog fullScreen open={isOpen} data-test="materialValuesDialog">
      <Grid
        container
        direction="column"
        wrap="nowrap"
        style={{ height: '100%' }}
      >
        <HeaderLayout title={t('MATERIAL_VALUES_EDITING')}>
          <HeaderButton type="close" handleClick={handleClose} />
          <HeaderButton type="save" handleClick={handleConfirm} />
        </HeaderLayout>
        <Subheader>
          <Breadcrumbs>
            <Breadcrumb title={t('MATERIAL_VALUE_SHORT')} value={description} data-test="title" />
            <Breadcrumb title={t('PLANNED_QUANTITY')} value={`${quantity} ${materialValueUnit}`} data-test="planned_quantity" />
            <Breadcrumb title={t('FACT_QUANTITY')} value={`${mergedQuantity || '0'} ${materialValueUnit}`} data-test="fact_quantity" />
          </Breadcrumbs>
        </Subheader>
        <div style={{ display: 'flex' }}>
          <SectionHeader title={t('OPERATIONS')} className={classes.sectionHeader} />
          <SectionHeader title={t('MATERIAL_VALUE_SHORT')} className={classes.sectionHeader} />
        </div>
        <div className={classes.operationsLayoutWrapper}>
          <OperationsLayout
            withoutShowMaterialValuesToggle
            showMaterialValuesDefaultValue
            headerWithoutBottomBorder
            blockTitle={t('MATERIAL_VALUES_BY_OPERATIONS')}
            operations={operationsToEdit}
            renderOperation={(operation) => {
              const {
                operationId, status, title, planDuration, factDuration, workersCount, factWorkersCount
              } = operation;
              const materialId = operation.equipmentId > 0 ? operation.equipmentId : operation.technicalPlaceId;
              const subTitle = materials[materialId] ? materials[materialId].title : '';

              return (
                <OperationView
                  key={operationId}
                  title={title}
                  subTitle={subTitle}
                  status={status}
                  counters={{
                    top: {
                      left: `${t('PLANNED_TIME')}: ${planDuration} ${t('MIN')}`,
                      right: factDuration ? `${t('FACT_TIME')}: ${factDuration} ${t('MIN')}` : '',
                    },
                    bottom: {
                      left: `${t('PLANNED_NUMBER_OF_PEOPLE')}: ${workersCount} ${t('PEOPLE_CUT')}`,
                      right: factWorkersCount ? `${t('FACT_NUMBER_OF_PEOPLE')}: ${factWorkersCount} ${t('PEOPLE_CUT')}` : '',
                    }
                  }}
                />
              );
            }}
            renderMaterialValues={(operation, materialValue) => {
              const { operationId } = operation;
              const {
                sapCode, description: descr, unit, updatedQuantity, quantity: materialValueQuantity, notValid // eslint-disable-line
              } = materialValue;
              const factQuantity = operation.materialValueConsumptions.reduce((acc, consumptionMaterial) => ((consumptionMaterial.materialValueId === materialValue.materialValueId) ? consumptionMaterial.quantity : acc), 0);

              const editableOperation = find(operationsToEdit, { operationId });
              const editableMaterialValue = find(editableOperation.materialValues, { sapCode });
              const { updatedQuantity: editableMergedQuantity = '0' } = editableMaterialValue;
              const shouldDisplayAsEdited = +editableMergedQuantity !== +factQuantity;
              const isNegativeColored = +editableMergedQuantity < +materialValueQuantity || notValid;
              return (
                <div>
                  <OperationView
                    boxShadowOnHover
                    key={sapCode}
                    title={descr}
                    subTitle={(
                      <div className={classes.operationViewSubTitle}>
                        {sapCode}
                        {shouldDisplayAsEdited && (
                          <div>
                            {`${t('FACT_QUANTITY_SHORT_UPDATED')}: ${factQuantity} ${unit}`}
                          </div>
                        )}
                      </div>
                    )}
                    counters={{
                      top: {
                        left: `${t('PLANNED_QUANTITY')}: ${materialValue.quantity} ${materialValue.unit}`,
                        right: (
                          <div className={clsx(classes.factQuantityBlock, isNegativeColored && classes.negativeColor)}>
                            <div className={classes.factQuantityLabel}>
                              {`${t('FACT_QUANTITY_SHORT')}:`}
                            </div>
                            <NumberInput
                              className={classes.quantityInput}
                              label=""
                              value={editableMergedQuantity}
                              name="factQuantity"
                              InputEndAdornment={(
                                <div className={classes.unitLabel}>
                                  {materialValue.unit}
                                </div>
                              )}
                              onChange={(val) => {
                                onChangeMaterialValue({ val, operationId, sapCode });
                              }}
                              data-test={`operationId_${operationId}_sapCode_${sapCode}`}
                            />
                          </div>
                        ),
                      }
                    }}
                  />
                </div>
              );
            }}
          />
        </div>
      </Grid>
    </Dialog>
  );
});

MaterialValuesEditorOverlay.defaultProps = {
  operations: [],
  materials: {},
};

MaterialValuesEditorOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  operations: PropTypes.arrayOf(PropTypes.shape({
    operationId: PropTypes.number,
    status: PropTypes.string,
    title: PropTypes.string,
    planDuration: PropTypes.number,
    factDuration: PropTypes.number,
    workersCount: PropTypes.number,
    factWorkersCount: PropTypes.number,
  })),
  materials: PropTypes.objectOf(PropTypes.shape({
    title: PropTypes.string,
  })),
};

export default MaterialValuesEditorOverlay;
