const controlTaskMaterialValuesTabsConfig = t => [
  {
    title: t('INFORMATION'),
    alias: 'common',
  },
  {
    title: t('HISTORY'),
    alias: 'history',
  }
];

export default controlTaskMaterialValuesTabsConfig;
