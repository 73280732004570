import React from 'react';

import withStyles from 'Src/utils/table/withColumnStyles';
import OverflowTip from 'Common/widgets/OverflowTip/OverflowTip';
import WorkplaceTypeCell from 'Business/plant/components/WorkplaceTypeCell';

const tableColumns = t => (
  {
    code: {
      field: 'code',
      title: t('CODE'),
      mainLink: true,
      // eslint-disable-next-line
      render: ({ code = '' }) => {
        return (
          <OverflowTip textProps={{ style: { color: '#4499ee' } }} tooltipTitle={code} text={code} data-test="code" />
        );
      },
    },
    name: {
      field: 'title',
      title: t('NAME'),
      // eslint-disable-next-line
      render: ({ title = '' }) => {
        return (
          <OverflowTip tooltipTitle={title} text={title} data-test="title" />
        );
      },
    },
    orgUnitTitle: {
      field: 'orgUnitTitle',
      title: t('ORG_UNIT_SHORT'),
      // eslint-disable-next-line
      render: ({ orgUnitTitle } = {}) => {
        if (!orgUnitTitle) return null;
        return (
          <OverflowTip tooltipTitle={orgUnitTitle} text={orgUnitTitle} data-test="orgUnitTitle" />
        );
      },
    },
    departmentTitle: {
      field: 'departmentTitle',
      title: t('PL_DEPARTMENT'),
      // eslint-disable-next-line
      render: ({ departmentTitle} = {}) => {
        if (!departmentTitle) return null;
        return (
          <OverflowTip tooltipTitle={departmentTitle} text={departmentTitle} data-test="departmentTitle" />
        );
      },
    },

    type: {
      field: 'type',
      title: t('TYPE'),
      // eslint-disable-next-line
      render: ({ type = '' } = {}) => {
        if (!type) return null;
        return (
          <WorkplaceTypeCell type={type} />
        );
      },
    },
  }
);

export const tableConfig = (t) => {
  const translatedTableColumns = tableColumns(t);
  return (
    {
      columns: [
        withStyles(translatedTableColumns.code, { width: '15%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.name, { width: '30%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.orgUnitTitle, { width: '20%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.departmentTitle, { width: '15%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.type, { width: '10%', whiteSpace: 'normal' }),
      ],
      actions: ['remove']
    }
  );
};

export default tableColumns;
