import { SPECIALIST_SDKR, ECONOMIST_IETS } from 'Shared/constants/roles';

const getMaterialValuesReturnModalConfig = (role, t) => {
  let title;
  let message;
  switch (role) {
    case SPECIALIST_SDKR:
      title = t('RETURNING_OPERATIONS_TO_THE_MASTER');
      message = `${t('PROVIDE_OPERATION_RETURNING_REASON')} ${t('REVISION_FOR_MASTER')}. ${t('THIS_OPERATION_WILL_BE_DISPLAYED_IN_THE_RETURNED_TAB')}`;
      break;
    case ECONOMIST_IETS:
      title = t('RETURNING_OPERATIONS_TO_THE_MASTER');
      message = `${t('PROVIDE_OPERATION_RETURNING_REASON')} ${t('REVISION_FOR_MASTER')}. ${t('THIS_OPERATION_WILL_BE_DISPLAYED_IN_THE_RETURNED_TAB')}`;
      break;
    default:
      title = '';
      message = '';
  }
  return { title, message };
};

export default getMaterialValuesReturnModalConfig;
