import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import withValidation from 'Src/HOC/withValidation';
import { format, parseISO } from 'date-fns';
import clsx from 'clsx';
import { isValid } from 'Src/utils/datetime';
import { Box } from '@material-ui/core';
import Datepicker from 'Common/components/Forms/DateTimePicker/components/Datepicker';
import OptionRenderer from 'Common/components/Forms/OptionRenderer';
import { CODE_DATE_FORMAT } from 'Shared/constants/datetime';

import useStyles from './styles';


const DateInput = ({
  name,
  className,
  value,
  onChange,
  onError,
  onBlur,
  label,
  helperText,
  isReadonly,
  required,
  error,
  minDate,
  maxDate,
  disablePast,
  maxWidth,
  marginTop,
  marginBottom,
  minDateMessage,
  maxDateMessage,
  disablePastMessage,
  disableFutureMessage,
  OptionRendererProps,
  'data-test': dataTest,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({
    maxWidth,
    error,
    marginTop,
    marginBottom,
    t
  });
  const showIsRequiredLabel = required && !isReadonly;

  if (isReadonly) {
    return (
      <OptionRenderer
        title={label}
        value={value || t('INPUT_EMPTY_VALUE')}
        {...OptionRendererProps}
        {...dataTest && { 'data-test': dataTest }}
      />
    );
  }

  const valueDate = value ? new Date(parseISO(value)) : null;

  return (
    <Box className={clsx(
      classes.formControlDate,
      showIsRequiredLabel && classes.isRequired,
      Boolean(className) && className,
    )}
    >
      <Datepicker
        name={name}
        fullWidth
        required={showIsRequiredLabel}
        label={label}
        value={value || valueDate}
        disableToolbar
        variant="inline"
        onChange={(val) => {
          if (isValid(val, true)) {
            onChange(format((val), CODE_DATE_FORMAT));
          } else {
            onChange(val);
          }
        }}
        onError={onError}
        readOnly={isReadonly}
        inputProps={{
          readOnly: Boolean(isReadonly),
          disabled: Boolean(isReadonly),
          onBlur: () => onBlur(),
        }}
        error={error}
        {...minDate && { minDate }}
        {...maxDate && { maxDate }}
        {...disablePast && { disablePast }}
        {...helperText && { helperText }}
        {...minDateMessage && { minDateMessage }}
        {...maxDateMessage && { maxDateMessage }}
        {...disablePastMessage && { disablePastMessage }}
        {...disableFutureMessage && { disableFutureMessage }}
      />
    </Box>
  );
};

DateInput.defaultProps = {
  className: undefined,
  value: '',
  onChange: () => {},
  onError: () => {},
  onBlur: () => {},
  label: '',
  helperText: '',
  isReadonly: false,
  required: false,
  error: false,
  minDate: undefined,
  maxDate: undefined,
  disablePast: false,
  marginBottom: undefined,
  marginTop: undefined,
  'data-test': undefined,
  OptionRendererProps: {},
  maxWidth: undefined,
  minDateMessage: '',
  maxDateMessage: '',
  disablePastMessage: '',
  disableFutureMessage: '',
};

DateInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  required: PropTypes.bool,
  isReadonly: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onBlur: PropTypes.func,
  helperText: PropTypes.string,
  disablePast: PropTypes.bool,
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  maxDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number,
  'data-test': PropTypes.string,
  OptionRendererProps: PropTypes.shape({
    maxLine: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    valueRenderer: PropTypes.func,
    wrapperClass: PropTypes.string,
    titleClass: PropTypes.string,
    valueClass: PropTypes.string,
    titlePosition: PropTypes.oneOf(['left', 'right']),
  }),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minDateMessage: PropTypes.string,
  maxDateMessage: PropTypes.string,
  disablePastMessage: PropTypes.string,
  disableFutureMessage: PropTypes.string,
};

export default withValidation(DateInput);
