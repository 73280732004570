import WorkplacesAgent from 'ApiAgents/Settings/WorkplacesAgent';
import {
  action, observable, runInAction, makeObservable
} from 'mobx';
import { INITIAL_PAGE_NUMBER, INITIAL_PAGE_SIZE } from 'Shared/constants/paging';

const workplacesAgent = new WorkplacesAgent();

export class WorkplacesListStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable list = [];

  @observable isListLoading = false;

  @observable isListLoaded = false;

  @observable isWorkplaceDeleting = false;

  @observable totalPages = 0;

  @observable hasNextPage = false;

  @observable hasPreviousPage = false;

  @observable currentPageNumber = INITIAL_PAGE_NUMBER;

  @observable currentPageSize = INITIAL_PAGE_SIZE;

  @action discardList = () => {
    this.list = [];
    this.isListLoading = false;
    this.isListLoaded = false;
    this.isWorkplaceDeleting = false;
    this.totalPages = 0;
    this.hasNextPage = false;
    this.hasPreviousPage = false;
    this.currentPageNumber = INITIAL_PAGE_NUMBER;
    this.currentPageSize = INITIAL_PAGE_SIZE;
  }

  @action loadWorkplacesList = async (pageNumber, pageSize) => {
    const isPaginating = !!pageNumber;
    this.isListLoading = true;
    this.isListLoaded = false;
    try {
      const {
        totalPages, hasPreviousPage, hasNextPage, items = []
      } = await workplacesAgent.getWorkplaces(isPaginating, pageNumber, pageSize);
      runInAction(() => {
        this.list = items;
        this.isListLoading = false;
        this.isListLoaded = true;
        this.totalPages = totalPages;
        this.hasNextPage = hasNextPage;
        this.hasPreviousPage = hasPreviousPage;
        this.currentPageNumber = pageNumber;
        this.currentPageSize = pageSize;
      });
    } catch (error) {
      console.log('ERROR LOAD WORKPLACES: ', error);
      runInAction(() => {
        this.list = [];
        this.isListLoading = false;
        this.isListLoaded = true;
        this.totalPages = 0;
        this.pageIndex = INITIAL_PAGE_NUMBER;
        this.hasNextPage = false;
        this.hasPreviousPage = false;
      });
      throw new Error(error);
    }
  }

  @action deleteWorkplace = async (id) => {
    this.isWorkplaceDeleting = true;
    try {
      const { error = false } = await workplacesAgent.deleteWorkplace(id) || {};
      runInAction(() => {
        this.isWorkplaceDeleting = false;
      });
      return { error };
    } catch (error) {
      console.log('ERROR DELETE WORKPLACE: ', error);
      runInAction(() => {
        this.isWorkplaceDeleting = false;
      });
      return { error };
    }
  }
}

export default WorkplacesListStore;
