import {
  action,
  runInAction,
  makeObservable,
  computed
} from 'mobx';

import InspectionTaskAgent from 'ApiAgents/InspectionTasks/InspectionTaskAgent';
import {
  TPS_ALTERNATIVE_SCRIPT_FOR_SCANNING_CHECKPOINT,
  TPS_CHECKPOINT_NFC_SCAN_FAIL_REASON,
  TPS_CHECKPOINT_PAUSE_REASON,
  TPS_CHECKPOINT_REJECT_REASON,
  OPERATION_STATUS
} from 'Shared/constants/dictionaries';
import { formatDate, formatTime } from 'Src/utils/datetime';

const INPUT_EMPTY_VALUE = '—';

const inspectionTaskAgent = new InspectionTaskAgent();
const initialState = {
  isTaskLoaded: false,
  loadingError: null,
  task: {},
};

export class CreatedTaskViewStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rootStore.prepareState(initialState, this);
    makeObservable(this);
  }

  isTaskLoaded;

  task;

  loadingError;

  @computed get expectedStartDateTime() {
    const { expectedStartDateTime } = this.task;

    return this.formatDateTime(expectedStartDateTime);
  }

  @computed get expectedFinishDateTime() {
    const { expectedFinishDateTime } = this.task;

    return this.formatDateTime(expectedFinishDateTime);
  }

  @computed get factStartDateTime() {
    const { factStartDateTime } = this.task;

    return this.formatDateTime(factStartDateTime);
  }

  @computed get factFinishDateTime() {
    const { factStartDateTime } = this.task;

    return this.formatDateTime(factStartDateTime);
  }

  formatDateTime(dateTime) {
    return dateTime ? `${formatDate(dateTime)}  ${formatTime(dateTime)}` : INPUT_EMPTY_VALUE;
  }

  @action loadTaskViewPageState = async (id) => {
    try {
      const [task] = await Promise.all([
        await inspectionTaskAgent.fetchCreatedInspectionTaskById(id),
        this.rootStore.dictionaryStore.loadDictionaryByKey(TPS_ALTERNATIVE_SCRIPT_FOR_SCANNING_CHECKPOINT),
        this.rootStore.dictionaryStore.loadDictionaryByKey(TPS_CHECKPOINT_NFC_SCAN_FAIL_REASON),
        this.rootStore.dictionaryStore.loadDictionaryByKey(TPS_CHECKPOINT_PAUSE_REASON),
        this.rootStore.dictionaryStore.loadDictionaryByKey(TPS_CHECKPOINT_REJECT_REASON),
        this.rootStore.dictionaryStore.loadDictionaryByKey(OPERATION_STATUS),
      ]);

      runInAction(() => {
        this.task = task;
        this.isTaskLoaded = true;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingError = error;
      });
    }
  };

  @action discardState = () => {
    this.rootStore.setInitialState(initialState, this);
  }
}

export default CreatedTaskViewStore;
