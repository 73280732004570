import Agent from 'ApiAgents/Agent';
import qs from 'qs';

export default class PauseReasonsAgent extends Agent {
  getPauseReasons = () => (
    this.request({
      url: '/v2/dictionaries/',
      method: 'GET',
      params: { businessType: 1, keys: ['SubOperationStopReason'] },
      paramsSerializer: params => qs.stringify(params)
    }, 'tasks')
  );

    updatePauseReasonsOrder = body => this.request({
      url: '/v2/dictionaries/updateOrder',
      method: 'PUT',
      data: body,
    }, 'tasks');

    updatePauseReasons = body => this.request({
      url: '/v2/dictionaries',
      method: 'PUT',
      data: body,
    }, 'tasks');

  addPauseReasons = body => this.request({
    url: '/v2/dictionaries',
    method: 'POST',
    data: body,
  }, 'tasks');
}
