import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { makeStyles, Grid, List } from '@material-ui/core';
import SectionTitle from 'Common/components/Forms/Title/components/SectionTitle';
import EmployeeListItem from 'Business/coal/components/EmployeeListItem';
import NoContent from 'Common/components/Content/components/NoContent/NoContent';
import ActionPrompt from 'Common/components/Prompt/ActionPrompt';
import { useTranslation } from 'react-i18next';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { ReactComponent as NoPerformers } from 'Assets/banners/no-performers.svg';

import Input from 'Common/components/Forms/Input/Input';

const useStyles = makeStyles((theme) => {
  // TODO KOTLIAR magic numbers
  const computedStaffSectionHeight = 'calc(100vh - 73px - 16px - 27px - 32px - 8px - 16px - 10px'
    + ' - 40px)';
  const computedSelectedSectionHeight = 'calc(100vh - 73px - 16px - 27px - 32px - 8px)';

  return {
    backButton: {
      paddingTop: 0,
      '&:hover': {
        backgroundColor: theme.palette.white,
      },
    },
    margin: {
      marginBottom: theme.spacing(2),
    },
    noContentColored: {
      backgroundColor: theme.palette.lightYellow,
      color: theme.palette.dirtyYellow,
    },
    staffSection: {
      height: computedStaffSectionHeight,
      overflowY: 'auto',
      paddingRight: 10
    },
    selectedSection: {
      height: computedSelectedSectionHeight,
      overflowY: 'auto',
      paddingRight: 10,
    },
    wrapperContent: {
      padding: 20,
    },
    title: {
      color: theme.palette.black
    }
  };
});

const UsersSelectWidget = observer(({
  filteredStaff,
  handleToggle,
  employeesState,
  toggleBrigadier,
  searchQuery,
  handleSearchQuery,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { selected, brigadier } = employeesState;
  const hasEmployees = !isEmpty(selected);
  const hasBrigadier = brigadier;

  const [actionPromptLabel, setActionPromptLabel] = useState('');
  useEffect(() => {
    if (!hasEmployees && brigadier) {
      setActionPromptLabel(t('CHOOSE_PERFORMERS'));
      return;
    }
    if (hasEmployees && !brigadier) {
      setActionPromptLabel(t('CHOOSE_RESPONSIBLE'));
      return;
    }
    setActionPromptLabel(t('SELECT_PERFORMERS_AND_APPOINT_A_RESPONSIBLE'));
  }, [hasEmployees, brigadier]);

  return (
    <Grid className={classes.wrapperContent}>
      <Grid
        container
        spacing={4}
      >
        <Grid item xs={6}>
          <SectionTitle text={t('STAFF_LIST')} />

          <Input
            value={searchQuery}
            style={{
              maxWidth: '100%',
              marginTop: 16,
              marginBottom: 10
            }}
            label={t('SEARCH_STAFF')}
            name="searchQuery"
            iconRight="search"
            inputProps={{
              'data-test': 'searchQuery-input'
            }}
            onChange={handleSearchQuery}
          />

          {filteredStaff.length ? (
            <List className={classes.staffSection}>
              {filteredStaff.map((employee) => {
                const { id } = employee;
                return (
                  <EmployeeListItem
                    noSecondaryActions
                    employee={employee}
                    handleToggle={handleToggle}
                    checked={Boolean(find(employeesState.checked, { id }))}
                    key={id}
                  />
                );
              })}
            </List>
          ) : (
            <NoContent text={t('EMPTY_PERFORMERS_LIST')} subText={t('EMPTY_PERFORMERS_LIST_SUBTITLE')} Banner={<NoPerformers />} />
          )}
        </Grid>
        <Grid item xs={6}>
          <SectionTitle text={t('PERFORMERS')} />

          <div className={classes.selectedSection}>
            <List>
              {(!hasEmployees || !hasBrigadier) && (
                <ActionPrompt
                  text={actionPromptLabel}
                  dataTestAttr="no-brigade-staff"
                  Icon={!hasEmployees ? KeyboardBackspaceIcon : null}
                />
              )}
              {hasBrigadier && (
                <EmployeeListItem
                  renderText={({ fullName } = {}) => fullName}
                  noActions
                  employee={employeesState.brigadier}
                  handleToggle={handleToggle}
                  isSelected
                  toggleBrigadier={toggleBrigadier}
                  handleRemoveFromBrigade={handleToggle}
                  key={employeesState.brigadierid}
                  isBrigadier
                />
              )}
              {hasEmployees && (
                employeesState.selected.map(employee => (
                  <EmployeeListItem
                    renderText={({ fullName } = {}) => fullName}
                    noActions
                    employee={employee}
                    handleToggle={handleToggle}
                    isSelected
                    toggleBrigadier={toggleBrigadier}
                    handleRemoveFromBrigade={handleToggle}
                    key={employee.id}
                  />
                )))
              }
            </List>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
});

UsersSelectWidget.propTypes = {
  sectorStaff: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line
  filteredStaff: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line
  handleToggle: PropTypes.func.isRequired,
  employeesState: PropTypes.shape({
    brigadier: PropTypes.object, // eslint-disable-line
    selected: PropTypes.array, // eslint-disable-line
    checked: PropTypes.array, // eslint-disable-line
  }).isRequired,
  brigadeTitle: PropTypes.string,
  toggleBrigadier: PropTypes.func.isRequired,
  discardState: PropTypes.func.isRequired,
};

UsersSelectWidget.defaultProps = {
  brigadeTitle: '',
  sectorStaff: [],
  filteredStaff: [],
};


export default UsersSelectWidget;
