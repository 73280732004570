import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 24,
    lineHeight: 2,
  },
  subTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 2.4,
  },
  message: {
    fontSize: 16,
    lineHeight: 1.5,
    paddingBottom: 16,
  },
  actions: {
    padding: 0,
    border: 'none !important',
    textAlign: 'right',
    borderTop: `1px solid ${theme.palette.midGray}`,
    '& .MuiButtonBase-root': {
      marginLeft: 24,
    }
  },
  comment: {
    margin: 0,
    fontSize: 15,
    paddingBottom: 14,
  },
}));

export default useStyles;
