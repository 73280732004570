import React from 'react';
import { FlashOn, FlashOff } from '@material-ui/icons';
import { find, get } from 'lodash';
import withStyles from 'Src/utils/table/withColumnStyles';
import { formatDate, formatTime } from 'Src/utils/datetime';
import powerUnitStates from 'Business/tpsInspections/config/measurementReportsTableConfig/powerUnitState';

const styles = { width: '20%', whiteSpace: 'normal' };

/* eslint-disable react/prop-types */
const config = (shifts, t) => [
  withStyles({
    field: 'powerUnitName',
    title: t('POWER_UNIT'),
    withEllipsis: true,
    mainLink: true,
  }, styles),
  withStyles({
    field: 'state',
    title: t('STATE'),
    render: ({ state }) => {
      const powerUnitState = get(powerUnitStates(t), state, '');
      return (
        <div style={{ display: 'flex' }}>
          <div style={{ color: '#a1a1a1' }}>
            {state === 'IN_PROGRESS'
              ? <FlashOn />
              : <FlashOff />}
          </div>
          {powerUnitState}
        </div>
      );
    }
  }, styles),
  withStyles({
    field: 'shift',
    title: t('SHIFT'),
    withEllipsis: true,
    render: ({ shift }) => {
      const shiftObj = find(shifts, { id: +shift });
      const shiftShortName = get(shiftObj, 'shiftShortName');
      return shiftShortName;
    }
  }, styles),
  withStyles({
    field: 'createdDate',
    title: t('DATE_AND_TIME'),
    render: ({ createdDate }) => `${formatDate(createdDate)} ${formatTime(createdDate)}`,
    withEllipsis: true,
  }, styles),
  withStyles({
    field: 'authorFullName',
    title: t('AUTHOR'),
    withEllipsis: true,
  }, styles),
];

export default config;
