import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import useStores from 'Store/useStores';
import { columnsType } from 'Business/plant/constants';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import SectionListItem from 'Common/widgets/Section/SectionListItem';
import Section from 'Common/widgets/Section/Section';

import useStyles from './styles';

const isChecked = (keyList, id) => Boolean(keyList.find(code => code === id));

const OperationKeysColumn = observer(({
  setColumnValue, gridWidth, defValue,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    volumeStore: {
      loadOperationKeys,
      operationKeys,
      isLoadingOperationKeys,
    }
  } = useStores();

  const handleKeyClick = (id) => {
    setColumnValue((() => {
      if (isChecked(defValue, id)) {
        return defValue.filter(curId => curId !== id);
      }

      return [...defValue, id];
    })(), columnsType.OPERATION_KEYS);
  };

  useEffect(() => {
    if (operationKeys.length) return;

    loadOperationKeys();
  }, []);

  return (
    <Section title={t('OPERATION_KEY')} gridWidth={gridWidth} customClass={classes.sectionRoot}>
      {isLoadingOperationKeys ? <ProgressBar /> : (
        operationKeys.map(key => (
          <SectionListItem
            key={key.enumValue}
            variant="check"
            noNextIcon
            onItemClick={() => handleKeyClick(key.enumValue)}
            title={`${key.enumValue} ${key.value}`}
            checked={isChecked(defValue, key.enumValue)}
          />
        ))
      )}
    </Section>
  );
});

OperationKeysColumn.propTypes = {
  setColumnValue: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  gridWidth: PropTypes.number.isRequired,
  defValue: PropTypes.arrayOf(PropTypes.string),
};

OperationKeysColumn.defaultProps = {
  defValue: [],
};

export default OperationKeysColumn;
