import InspectionTaskAgent from 'ApiAgents/InspectionTasks/InspectionTaskAgent';
import EquipmentAgent from 'ApiAgents/Equipment/EquipmentAgent';
import TaskRouteAgent from 'ApiAgents/TaskRoutes/TaskRouteAgent';
import { set, isBefore, isEqual as isEqualDates } from 'date-fns';
import {
  reaction,
  action,
  observable,
  runInAction,
  computed,
  makeObservable
} from 'mobx';
import {
  formatDate,
  formatTime,
  formatToISOString,
  diffByDate,
  getTime,
  isValid as isValidDate,
} from 'Src/utils/datetime';
import { CODE_DATE_FORMAT } from 'Shared/constants/datetime';

const equipmentAgent = new EquipmentAgent();
const inspectionTaskAgent = new InspectionTaskAgent();
const taskRouteAgent = new TaskRouteAgent();

const initItemData = {
  title: '',
  orgUnitCode: '',
  tpsId: '',
  routeId: '',
  roles: [],
  startDate: '',
  finishDate: '',
  startTime: '',
  finishTime: '',
  daysInterval: '',
  daily: false,
  hoursInterval: '',
  startDelay: 0,
  planDuration: ''
};

export class InspectionTaskStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable items = [];

  @observable mode = '';

  @observable inspectionTasksList = [];

  @observable inspectionTask = {};

  @observable inspectionTaskData = initItemData;

  @observable taskRouteList = [];

  @observable isLoading = false;

  @observable isUpdating = false;

  @observable isLoadingByPage = false;

  @observable isDeleting = false;

  @observable totalPages = null;

  @observable hasNextPage = null;

  @observable hasPreviousPage = null;

  @observable isLoaded = false;

  @observable taskRouteListIsLoading = false;

  @observable hoursIntervalList = [1, 2, 3, 4, 6, 8, 12, 24];

  @observable pageIndex = 0;

  @action reset = () => {
    this.inspectionTask = initItemData;
    this.inspectionTaskData = initItemData;
  };

  @action setMode = (mode) => {
    this.mode = mode;
  };

  isValidTime = () => {
    const {
      startDate, finishDate, startTime, finishTime
    } = this.inspectionTaskData;
    const { hours: startTimeHours, minutes: startTimeMinutes } = getTime(startTime);
    const { hours: finishTimeHours, minutes: finishTimeMinutes } = getTime(finishTime);
    const isValidTime = !(isValidDate(startDate) && isValidDate(finishDate) && isEqualDates(new Date(startDate), new Date(finishDate)) && startTime && finishTime && startTimeHours * 60 + startTimeMinutes >= finishTimeHours * 60 + finishTimeMinutes);
    return isValidTime;
  }

  isStartTimeInPast = () => {
    const {
      startDate, startTime
    } = this.inspectionTaskData;
    if (!startDate || !startTime) return false;
    const { hours: startTimeHours, minutes: startTimeMinutes } = getTime(startTime);
    return isBefore(new Date(startDate).setHours(startTimeHours, startTimeMinutes, 0), new Date());
  }

  isFinishTimeInPast = () => {
    const {
      finishDate, finishTime
    } = this.inspectionTaskData;
    if (!finishDate || !finishTime) return false;
    const { hours: finishTimeHours, minutes: finishTimeMinutes } = getTime(finishTime);
    return isBefore(new Date(finishDate).setHours(finishTimeHours, finishTimeMinutes, 0), new Date());
  }

  @computed get helperTextStartTime() {
    const isStartTimeInPast = this.isStartTimeInPast();
    if (isStartTimeInPast) return 'HELPER_TEXT_START_TIME_CURRENT_TIME';
    const isValidTime = this.isValidTime();
    if (!isValidTime) return 'START_TIME_MUST_NOT_BE_LESS_THAN_THE_END_TIME';
    return '';
  }

  @computed get helperTextFinishTime() {
    const isFinishTimeInPast = this.isFinishTimeInPast();
    if (isFinishTimeInPast) return 'HELPER_TEXT_START_TIME_CURRENT_TIME';
    const isValidTime = this.isValidTime();
    if (!isValidTime) return 'THE_END_TIME_MUST_NOT_BE_GREATER_THAN_THE_START_TIME';
    return '';
  }

  @computed get helperTextStartDate() {
    const { startDate, finishDate } = this.inspectionTaskData;
    const isStartDateBeforeToday = isValidDate(startDate) && isBefore(new Date(startDate), new Date().setHours(0, 0, 0));
    if (isStartDateBeforeToday) return 'START_DATE_CANNOT_BE_PAST_TENSE';
    if (isValidDate(startDate)
      && isValidDate(finishDate)
      && (isBefore(new Date(finishDate), new Date(startDate)))) {
      return 'START_DATE_CANNOT_BE_GREATER_THAN_THE_END_DATE';
    }
    return '';
  }

  @computed get helperTextFinishDate() {
    const { startDate, finishDate } = this.inspectionTaskData;
    const isFinishDateBeforeToday = isValidDate(finishDate) && isBefore(new Date(finishDate), new Date().setHours(0, 0, 0));
    if (isFinishDateBeforeToday) return 'END_DATE_CANNOT_BE_PAST_TENSE';
    if (isValidDate(startDate)
      && isValidDate(finishDate)
      && (isBefore(new Date(finishDate), new Date(startDate)))) {
      return 'END_DATE_CANNOT_BE_LESS_THAN_START_DATE';
    }
    return '';
  }

  // daysInterval should be greater than to startDate
  @computed get helperTextDaysInterval() {
    const { daysInterval, startDate, finishDate } = this.inspectionTaskData;
    return diffByDate(startDate, finishDate) < daysInterval ? 'HELPER_TEXT_DAYS_INTERVAL' : '';
  }

  // startDelay should be less than to hoursInterval
  @computed get helperTextStartDelay() {
    const { planDuration, startDelay, hoursInterval } = this.inspectionTaskData;
    if (parseInt(planDuration, 10) >= hoursInterval * 60) return '';
    if (hoursInterval > 0 && hoursInterval * 60 <= startDelay) {
      return 'HELPER_TEXT_STARTDELAY';
    } if (hoursInterval > 0 && (parseInt(planDuration, 10) + parseInt(startDelay, 10)) >= hoursInterval * 60) {
      return 'HELPER_TEXT_PLANDURATION_STARTDELAY_HOURSINTERVAL';
    }
    return '';
  }

  // startDelay should be less than to hoursInterval
  @computed get helperTextPlanDuration() {
    const { planDuration, startDelay, hoursInterval } = this.inspectionTaskData;
    if (hoursInterval > 0 && hoursInterval * 60 <= planDuration) {
      return 'HELPER_TEXT_PLANDURATION';
    } if (hoursInterval > 0 && (parseInt(planDuration, 10) + parseInt(startDelay, 10)) >= hoursInterval * 60) {
      return 'HELPER_TEXT_PLANDURATION_STARTDELAY_HOURSINTERVAL';
    }
    return '';
  }

  @action getTaskRouteList = async () => {
    const { rootOrgUnit: { code: orgUnitCode } } = this.rootStore.userStore;
    try {
      this.taskRouteListIsLoading = true;
      const taskRouteList = await taskRouteAgent.getItemsBy({ orgUnitCode });
      runInAction(() => {
        this.taskRouteList = taskRouteList;
      });
    } catch (error) {
      console.log('ERROR IN FETCHING ROTES ITEMS: ', error);
    }

    runInAction(() => {
      this.taskRouteListIsLoading = false;
    });
  };

  // if daily is true daysInterval is null
  isDaily = reaction(
    () => this.inspectionTaskData.daily,
    () => {
      if (this.inspectionTaskData.daily) {
        this.inspectionTaskData.daysInterval = '';
      }
    }
  );

  // if finishDate is equal to startDate daysInterval is 0
  isNulledDaysInterval = reaction(
    () => this.inspectionTaskData.startDate + this.inspectionTaskData.finishDate + this.mode,
    () => {
      const { startDate, finishDate } = this.inspectionTaskData;
      if (this.mode !== 'view') {
        this.inspectionTaskData.daysInterval = startDate && startDate === finishDate ? 0 : '';
      }
    }
  );

  @action fetchInspectionTasks = async (pageNumber, pageSize) => {
    const isPaginating = !!pageNumber;
    this.pageIndex = pageNumber;
    this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', true);

    try {
      const { rootOrgUnit: { code: orgUnitCode } } = this.rootStore.userStore;
      const {
        items, totalPages, hasNextPage, hasPreviousPage
      } = await inspectionTaskAgent.fetchInspectionTasks({ orgUnitCode }, true, pageNumber, pageSize);

      runInAction(() => {
        this.items = items;
        this.isLoaded = true;
        this.totalPages = totalPages;
        this.hasNextPage = hasNextPage;
        this.hasPreviousPage = hasPreviousPage;
      });
      this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', false);
    } catch (error) {
      runInAction(() => {
        this.items = [];
        this.isLoaded = true;
        this.totalPages = 0;
        this.hasNextPage = false;
      });
      console.log('ERROR IN FETCH INSPECTIONTASKSLIST: ', error);
      this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', false);
      throw new Error(error);
    }
  };

  @action updateDataField = (name, value) => {
    this.inspectionTaskData[name] = value;
  };

  @action save = async () => {
    const { inspectionTaskData } = this;

    this.isUpdating = true;
    try {
      if (inspectionTaskData.daily) {
        delete inspectionTaskData.daysInterval;
      }

      const { hours: startTimeHours, minutes: startTimeMinutes } = getTime(inspectionTaskData.startTime);
      const { hours: finishTimeHours, minutes: finishTimeMinutes } = getTime(inspectionTaskData.finishTime);
      const finishDate = formatToISOString(set(new Date(inspectionTaskData.finishDate), { hours: finishTimeHours, minutes: finishTimeMinutes }));
      const startDate = formatToISOString(set(new Date(inspectionTaskData.startDate), { hours: startTimeHours, minutes: startTimeMinutes }));
      const res = await inspectionTaskAgent.saveInspectionTask({
        ...inspectionTaskData,
        startDate,
        finishDate,
        startTime: startDate,
        finishTime: finishDate,
      });

      runInAction(() => {
        this.isUpdating = false;
      });

      return res;
    } catch (error) {
      console.log('ERROR in SAVE INSPECTIONTASK: ', error);
    }

    this.isUpdating = false;
  };

  @action getInspectionTaskById = async (id) => {
    this.isLoading = true;
    this.isLoaded = false;
    try {
      const result = await inspectionTaskAgent.fetchInspectionTaskById(id);
      if (result.length > 0) {
        const {
          tps,
          startDate,
          finishDate,
          startTime,
          finishTime,
          ...restOptions
        } = result[0];

        const { id: tpsId } = tps || {};

        const inspectionTask = {
          ...restOptions,
          tpsId,
          startDate: formatDate(startDate, CODE_DATE_FORMAT),
          finishDate: formatDate(finishDate, CODE_DATE_FORMAT),
          startTime: formatTime(startTime),
          finishTime: formatTime(finishTime)
        };

        runInAction(() => {
          this.inspectionTask = inspectionTask;
          this.inspectionTaskData = inspectionTask;
          this.isLoaded = true;
          this.isLoading = false;
        });
        return inspectionTask;
      }
    } catch (error) {
      console.log('ERROR IN FETCHING INSPECTIONTASK: ', error);
    }
    this.isLoading = false;
  };

  @action deleteTask = async (id) => {
    this.isDeleting = true;
    try {
      await inspectionTaskAgent.deleteTask(id);
    } catch (error) {
      console.log('ERROR IN DELETING TASK: ', error);
    }
    this.isDeleting = false;
  };

  @action removeTaskFromList = (taskId) => {
    this.items = this.items.filter(({ automatedTaskId }) => automatedTaskId !== taskId);
  };

  loadTpsId = async (sapCode) => {
    const TECHOBJECTS_LEVELS_TO_LOAD = 1;
    const [tps] = await equipmentAgent.getTechObjectsTrees(sapCode, TECHOBJECTS_LEVELS_TO_LOAD) || [];
    if (tps && tps.technicalObjectId) {
      this.inspectionTaskData = { ...this.inspectionTaskData, orgUnitCode: sapCode, tpsId: tps.technicalObjectId };
    }
  }
}

export default InspectionTaskStore;
