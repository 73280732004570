import React, { forwardRef, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  SvgIcon, ButtonBase,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { CheckCircleOutlined, HighlightOff, ReportProblemOutlined } from '@material-ui/icons';
import { useSnackbar, SnackbarContent } from 'notistack';
import useStyles from './styles';

const NotificationBanner = forwardRef(({ id, message }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const {
    messageTemplate: { rows = [] } = {}, withDismissButton, variant
  } = JSON.parse(message);

  const classes = useStyles({ variant });

  let notificationIconComponent;

  switch (variant) {
    case 'success':
      notificationIconComponent = CheckCircleOutlined;
      break;
    case 'error':
      notificationIconComponent = HighlightOff;
      break;
    case 'warning':
      notificationIconComponent = ReportProblemOutlined;
      break;
    default:
      notificationIconComponent = undefined;
  }

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const renderNotificationContent = () => rows.map(({ rowContent = [] } = {}, rowIndex) => {
    const rowKey = `row_${rowIndex}`;
    return (
      <div key={rowKey} className={classes.row}>
        {rowContent.map(({ type, text, url } = {}, itemIndex) => {
          const itemKey = `${rowKey}_${itemIndex}`;
          const splittedText = text.replace(/\s\s+/g, ' ').split(' ').map((word, wordIndex) => {
            const wordKey = `${itemKey}_${wordIndex}`;
            return (
              <span key={wordKey}>
                {word}
                  &nbsp;
              </span>
            );
          });
          if (type === 'link') {
            return (
              <Link to={url} key={itemKey}>
                {splittedText}
              </Link>
            );
          }
          return (
            <Fragment key={itemKey}>
              {splittedText}
            </Fragment>
          );
        })}
      </div>
    );
  });

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <div
        data-test="notificationContent"
        className={classes.notificationContent}
      >
        <SvgIcon
          className={classes[`${variant}Icon`]}
          component={notificationIconComponent}
          viewBox="0 0 24 24"
        />
        <div className={classes.notificationText}>
          {renderNotificationContent()}
        </div>
        {withDismissButton && (
          <ButtonBase className={classes.closeButton} onClick={handleDismiss}>
            <CloseIcon />
          </ButtonBase>
        )}
      </div>
    </SnackbarContent>
  );
});

NotificationBanner.propTypes = {
  message: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.node]
  ).isRequired,
  id: PropTypes.number.isRequired,
};

export default NotificationBanner;
