
export const transformDictionariesToObject = (keys, dictionaries) => keys.reduce((dicAcc, key, index) => ({
  ...dicAcc,
  [key]: dictionaries[index].reduce((acc, { enumValue, value }) => ({
    ...acc,
    [enumValue]: value
  }), {})
}), {});

export default {
  transformDictionariesToObject
};
