import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useHistory,
  withRouter,
} from 'react-router-dom';

import { withTheme } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import useStores from 'Store/useStores';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import DataTable from 'Common/components/DataTable/DataTable';
import { tableConfig } from 'Business/plant/config/operationKeysTableConfig';
import Header from 'Common/widgets/Layout/Header/Header';
import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import SelectGroupHelperBlock from 'Common/widgets/SelectGroupHelperBlock';
import { OPERATION_KEYS_SETTINGS, CREATE_OPERATION_KEY, SETTINGS } from 'Shared/constants/routes';

const OperationKeysList = ({ theme }) => {
  const { t } = useTranslation();
  const translatedTableConfig = tableConfig(t);
  const {
    operationKeysSettingsStore: {
      list,
      loadList,
      discardList,
      isListLoading,
      isListLoaded,
    } = {},
  } = useStores();

  const history = useHistory();

  const onRowClickHandler = (event, row = {}) => {
    const { id, isUpdating } = row;
    if (isUpdating) return;
    history.push({
      pathname: `${OPERATION_KEYS_SETTINGS}/${id}`,
    });
  };

  useEffect(() => {
    loadList();
    return () => {
      discardList();
    };
  }, []);

  const {
    headerHeight = 0,
    pageHeaderHeight = 0,
    mainContentPaddingTop = 0,
    mainContentPaddingBottom = 0,
  } = theme;
  const tableBodyHeight = `calc(100vh - ${headerHeight}px - ${pageHeaderHeight}px - ${mainContentPaddingTop}px - ${mainContentPaddingBottom}px)`;

  const showNoResultsBlock = isListLoaded && isEmpty(list);
  const showOperationsKeys = !isEmpty(list);

  const goToSettings = () => history.push({ pathname: SETTINGS });

  const goToOperationKeyCreation = () => {
    history.push({
      pathname: CREATE_OPERATION_KEY,
    });
  };

  return (
    <PaperLayout>
      {isListLoading && <ProgressBar />}
      <Header
        isActiveGoBack
        titleGoBack={t('GO_BACK')}
        title={t('OPERATION_KEYS')}
        handleGoBack={goToSettings}
        style={{ borderBottom: 'none' }}
      >
        <HeaderButton
          title={t('ADD_OPERATION_KEY')}
          type="add"
          handleClick={goToOperationKeyCreation}
        />
      </Header>
      <div style={{ position: 'relative', zIndex: 1 }}>
        {showNoResultsBlock && <SelectGroupHelperBlock title={t('NO_OPERATION_KEYS_SETTINGS')} subTitle={t('YOU_CAN_ADD_OPERATION_KEY')} />}
        {showOperationsKeys && (
          <DataTable
            withBoxShadow={false}
            tableBodyHeight={tableBodyHeight}
            data={list}
            titleSingle={t('TASK_ACCUSATIVE')}
            columns={translatedTableConfig.columns}
            onRowClick={onRowClickHandler}
            actions={translatedTableConfig.actions}
            dataTestRowSuffixEntityName="id"
          />
        )}
      </div>
    </PaperLayout>
  );
};

OperationKeysList.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.shape({
    headerHeight: PropTypes.number,
    pageHeaderHeight: PropTypes.number,
    pageTabsHeight: PropTypes.number,
    tableHeaderHeight: PropTypes.number,
    tableFooterHeight: PropTypes.number,
    mainContentPaddingTop: PropTypes.number,
    mainContentPaddingBottom: PropTypes.number,
    pageSearchBlockHeight: PropTypes.number,
  }).isRequired,
};

export default withRouter(withTheme(observer(OperationKeysList)));
