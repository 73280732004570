import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import CoalRoutes from 'Routes/CoalRoutes';
import PLRoutes from 'Routes/PLRoutes';
import TPSInspectionRoutes from 'Routes/TPSInspectionRoutes';
import TPSRepairRoutes from 'Routes/TPSRepairRoutes';
import {
  TPS_REPAIR,
  TPS_INSPECTION,
  PL,
  COAL
} from 'Src/shared/constants/business';

const businessRoutes = {
  [COAL]: CoalRoutes,
  [PL]: PLRoutes,
  [TPS_REPAIR]: TPSRepairRoutes,
  [TPS_INSPECTION]: TPSInspectionRoutes,
};

const Routes = ({ businessType }) => {
  const BusinessRoutes = businessRoutes[businessType];

  return (
    <BusinessRoutes />
  );
};

Routes.propTypes = {
  businessType: PropTypes.string.isRequired,
};

export default observer(Routes);
