import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import React, {
  useState, useEffect, useCallback, useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useHistory, withRouter, useLocation } from 'react-router-dom';

import { withTheme } from '@material-ui/core/styles';
import { isEmpty, get, throttle } from 'lodash';
import { Button } from '@material-ui/core';
import { CancelOutlined, Search } from '@material-ui/icons';
import { ReactComponent as FIleUpload } from 'Src/assets/file_upload.svg';
import { ReactComponent as FIleDownload } from 'Src/assets/file_download.svg';
import useStores from 'Store/useStores';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import FilterButton from 'Common/widgets/FilterButton/';
import FilterPanel from 'Common/widgets/FilterPanel/';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import DottedLoader from 'Common/components/Progress/components/Dotted';
import Header from 'Common/widgets/Layout/Header/Header';
import Input from 'Common/components/Forms/Input/Input';
import InputText from 'Common/components/Forms/Input/Text';
import CheckboxInput from 'Common/components/Forms/Input/Checkbox';
import UsersContainer from 'Common/containers/UsersContainer';
import ModeSwitchers from 'Business/plant/components/ModeSwitchers';
import { INITIAL_PAGE_NUMBER } from 'Shared/constants/paging';
import Can from 'Common/components/Authorization/Can';
import { ADMIN_USER_ENTERPRISE_FILTER } from 'Src/RBAC/businessPermissions';

import useStyles from './styles';

const UsersPage = ({ theme }) => {
  const classes = useStyles();
  const fileInputRef = useRef(null);

  const {
    usersListStore: {
      usersList,
      loadUsersList,
      loadSearchUsersList,
      isLoading,
      exportUsersList,
      isExporting,
      importUsersList,
      isImporting,
      discardUsers,
    } = {},
    usersListFilterStore: {
      loadInitialFilters,
      isLoadedInitialFilters,
      departments,
      onChangeDepartmentCode,
      onChangeRootOrgUnitCode,
      onChangeUserStatus,
      departmentsAreLoading,
      saveRequestedFilters,
      discardState,
      isFiltersSet,
      hasDiff,
      discardSelectedFilters,
      searchWord,
      onChangeSearchWord,
      rootOrgUnits,
      rolesDictionary,
      onChangeRoles,
      selectedState: {
        rootOrgUnitCode,
        departmentCode,
        role,
        activeUsers,
        blockedUsers
      },
    },
    notificationStore: { enqueueSnackbar },
  } = useStores();

  const { t } = useTranslation();
  const location = useLocation();
  // TODO next line will be needed on history.push from userPage
  const { pathname, state } = location; // eslint-disable-line
  const withFilter = get(state, 'withFilter');
  const history = useHistory();

  const [filterOpened, setFilterOpened] = useState(true);
  const [mode, setModeState] = useState(localStorage.getItem('userMode') || 'list');

  const loadPaginatedUsers = (pageNumber, perPage) => {
    loadUsersList(pageNumber, perPage).catch(() => {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('REQUEST_LOAD_LIST_ERROR'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
    });
  };

  const handleFiltersApply = () => {
    saveRequestedFilters();
    loadPaginatedUsers(INITIAL_PAGE_NUMBER);
  };

  const setMode = (userMode) => {
    setModeState(userMode);
    localStorage.setItem('userMode', userMode);
  };

  const preparePage = async () => {
    await discardState();
    await loadInitialFilters();
  };

  useEffect(() => {
    if (withFilter && !isEmpty(usersList)) {
      history.replace({
        state: {},
      });
      return;
    }
    preparePage();
    return () => discardUsers();
  }, []);

  const handleClickFilterCollapseBtn = () => {
    setFilterOpened(!filterOpened);
  };

  const throttledLoadPaginatedUsers = useCallback(
    throttle(() => loadSearchUsersList(INITIAL_PAGE_NUMBER).catch(() => {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('REQUEST_LOAD_LIST_ERROR'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
    }), 1000),
    [],
  );

  const handleSearch = (value) => {
    onChangeSearchWord(value);
    throttledLoadPaginatedUsers();
  };

  const {
    headerHeight = 0,
    pageHeaderHeight = 0,
    mainContentPaddingTop = 0,
    mainContentPaddingBottom = 0,
    pageSearchBlockHeight = 0,
  } = theme;
  const usersContainerHeight = `calc(100vh - ${headerHeight}px - ${pageHeaderHeight}px - ${mainContentPaddingTop}px - ${mainContentPaddingBottom}px - ${pageSearchBlockHeight}px)`;
  const reservedPageHeight = headerHeight + pageHeaderHeight + mainContentPaddingTop + mainContentPaddingBottom;
  const filterPanelHeight = `calc(100vh - ${reservedPageHeight}px)`;

  const handleImport = async (event) => {
    const { success, error } = await importUsersList(event).catch(() => {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('IMPORT_USERS_ERROR'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
    });
    if (success) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('USERS_IMPORTED_SUCCESSFULLY'),
                },
              ],
            },
          ],
        },
        variant: 'success',
      });
    } else if (error) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('USERS_IMPORT_ERROR_REPORT'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
    }
    const fileInputRefValue = get(fileInputRef, 'current.value');
    if (fileInputRefValue) fileInputRef.current.value = '';
    loadPaginatedUsers(INITIAL_PAGE_NUMBER);
  };

  return (
    <PaperLayout autoHeight>
      {(isLoading || !isLoadedInitialFilters || isExporting || isImporting) && <ProgressBar />}
      <Header title={t('USERS')}>
        <div className={classes.buttons}>
          <Button
            component="label"
            className={classes.importButton}
            color="primary"
            variant="text"
            startIcon={<FIleDownload className={classes.headerButtonIcon} />}
            data-test="importButton"
          >
            {t('IMPORT')}
            <input
              ref={fileInputRef}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              type="file"
              hidden
              onChange={handleImport}
            />
          </Button>
          <Button
            className={classes.exportButton}
            color="primary"
            variant="text"
            onClick={() => exportUsersList().catch(() => {
              enqueueSnackbar({
                messageTemplate: {
                  rows: [
                    {
                      rowContent: [
                        {
                          type: 'text',
                          text: t('EXPORT_USERS_ERROR'),
                        },
                      ],
                    },
                  ],
                },
                variant: 'error',
              });
            })}
            startIcon={<FIleUpload className={classes.headerButtonIcon} />}
            data-test="exportButton"
          >
            {t('EXPORT')}
          </Button>
          <ModeSwitchers mode={mode} setMode={setMode} />
          <FilterButton
            hasActiveFilters={isFiltersSet}
            onClickFilterButton={handleClickFilterCollapseBtn}
            filterOpened={filterOpened}
          />
        </div>
      </Header>
      <div style={{ position: 'relative' }}>
        <div
          className={clsx(
            classes.usersContainer,
            filterOpened ? classes.usersContainerOpened : classes.usersContainerClosed,
          )}
        >
          <div className={classes.pageSearchBlock}>
            <InputText
              placeholder={t('ENTER_THE_NAME_OR_EMAIL')}
              className={classes.searchInput}
              value={searchWord}
              label={t('ENTER_THE_NAME_OR_EMAIL')}
              name="searchWord"
              onChange={handleSearch}
              data-test="searchQuery-input"
              InputStartAdornment={(<Search className={classes.searchIcon} />)}
              {...searchWord && {
                InputEndAdornment: <CancelOutlined className={classes.inputClearButton} />
              }}
              onClickEndAdornment={() => handleSearch('')}
            />
          </div>
          <UsersContainer mode={mode} maxHeight={usersContainerHeight} />
        </div>
        <FilterPanel
          filterPanelHeight={filterPanelHeight}
          filterOpened={filterOpened}
          filterApplyBtnLabel={t('APPLY')}
          headerLabel={t('FILTRATION')}
          headerClearBtnLabel={t('CLEAR')}
          hasDiff={hasDiff}
          hasActiveFilters={isFiltersSet}
          handleClearFilters={() => {
            discardSelectedFilters();
            discardUsers();
          }}
          setFilters={handleFiltersApply}
        >
          <Can
            variant="user"
            perform={ADMIN_USER_ENTERPRISE_FILTER}
            yes={() => (
              <Input
                fullOutlined
                disabled={isEmpty(rootOrgUnits)}
                label={t('ENTERPRISE')}
                placeholder={t('SELECT_VALUE')}
                value={rootOrgUnitCode}
                onChange={onChangeRootOrgUnitCode}
                name="rootOrgUnitCode"
                type="selectAlpha"
                options={rootOrgUnits}
                optionValueKey="code"
                optionTitleKey="name"
                inputProps={{
                  'data-test': 'rootOrgUnit',
                }}
              />
            )}
          />

          <DottedLoader isLoading={departmentsAreLoading}>
            <Input
              disabled={isEmpty(departments)}
              listBoxMaxHeight={300}
              fullOutlined
              label={t('PL_DEPARTMENT')}
              placeholder={t('SELECT_VALUE')}
              value={departmentCode}
              onChange={onChangeDepartmentCode}
              name="departmentCode"
              type="selectAlpha"
              options={departments}
              optionValueKey="code"
              optionTitleKey="name"
              inputProps={{
                'data-test': 'workPlace',
              }}
            />
          </DottedLoader>
          <div data-test="roles" className={classes.filterGroupLabel}>
            {t('ROLES')}
          </div>
          {rolesDictionary.map(({ name, code }, roleIndex) => {
            const inputName = `role_${roleIndex}`;
            return (
              <CheckboxInput
                key={inputName}
                className={classes.taskType}
                label={name}
                checked={role.includes(name)}
                name={inputName}
                onChange={() => onChangeRoles(name)}
                data-test={code}
              />
            );
          })}
          <div data-test="status" className={classes.filterGroupLabel}>
            {t('STATUS')}
          </div>
          <CheckboxInput
            className={classes.taskType}
            label={t('ACTIVE_PLURAL')}
            checked={activeUsers}
            name="activeUsers"
            onChange={() => onChangeUserStatus('activeUsers')}
            data-test="activeUsers"
          />
          <CheckboxInput
            className={classes.taskType}
            label={t('BLOCKED_PLURAL')}
            checked={blockedUsers}
            name="blockedUsers"
            onChange={() => onChangeUserStatus('blockedUsers')}
            data-test="blockedUsers"
          />
        </FilterPanel>
      </div>
    </PaperLayout>
  );
};

UsersPage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.shape({
    headerHeight: PropTypes.number,
    pageHeaderHeight: PropTypes.number,
    pageTabsHeight: PropTypes.number,
    tableHeaderHeight: PropTypes.number,
    tableFooterHeight: PropTypes.number,
    mainContentPaddingTop: PropTypes.number,
    mainContentPaddingBottom: PropTypes.number,
    pageSearchBlockHeight: PropTypes.number,
  }).isRequired,
};

export default withRouter(withTheme(observer(UsersPage)));
