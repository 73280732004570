import Schema from 'validate';


export default class SchemaValidator {
  static validate(schema, obj) {
    if (typeof obj !== 'object') {
      throw new TypeError(`Parameter "obj" provided to function "validate" should be an object type. ${typeof obj} provided`);
    }
    if (!(schema instanceof Schema)) {
      throw new TypeError('Parameter "schema" provided to function "validate" should be an instance of Schema');
    }

    return schema.validate(obj);
  }
}
