import { SPECIALIST_SDKR, ECONOMIST_IETS } from 'Shared/constants/roles';

const getSuccessReturnMessage = (role, t) => {
  let message;
  switch (role) {
    case SPECIALIST_SDKR:
      message = t('MATERIAL_VALUES_WERE_SUCCESSFULLY_RETURNED_TO_THE_MASTER_FOR_REVISION');
      break;
    case ECONOMIST_IETS:
      message = t('MATERIAL_VALUES_WERE_SUCCESSFULLY_RETURNED_TO_THE_MASTER_FOR_REVISION');
      break;
    default:
      message = '';
  }
  return message;
};

export default getSuccessReturnMessage;
