import React from 'react';
import { useTranslation } from 'react-i18next';
import HeaderLayout from 'Common/widgets/Layout/Header/Header';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import CategoryButtonList from 'Common/components/Category/CategoryButtonList';
import { ReactComponent as ReportIcon } from 'Src/assets/excel_report.svg';
import { ReactComponent as StatisticsReportIcon } from 'Src/assets/xls_report.svg';
import { TASKS_PERFORMANCE_STATISTICS, MEASUREMENTS_STATISTICS } from 'Src/shared/constants/routes';

const ReportCategoriesScreen = () => {
  const { t } = useTranslation();
  const categoryList = [
    {
      title: t('REPORT_LONG'),
      Icon: ReportIcon,
      url: MEASUREMENTS_STATISTICS
    },
    {
      title: t('REPORT'),
      Icon: StatisticsReportIcon,
      url: TASKS_PERFORMANCE_STATISTICS
    }
  ];
  return (
    <PaperLayout fixedHeight>
      <HeaderLayout title={t('STATISTICS')} />
      <CategoryButtonList
        listTitle={t('CHOOSE_CATEGORY_TO_VIEW')}
        list={categoryList}
      />
    </PaperLayout>
  );
};

export default ReportCategoriesScreen;
