import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useHistory,
  withRouter,
  useLocation
} from 'react-router-dom';

import { withTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { isEmpty, get } from 'lodash';
import useStores from 'Store/useStores';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import SelectInput from 'Common/components/Forms/Input/Select';
import DataTable from 'Common/components/DataTable/DataTable';
import { tableConfig } from 'Business/plant/config/defectsDictionaryTableConfig';
import Header from 'Common/widgets/Layout/Header/Header';
import NoResultsBlock from 'Common/widgets/NoResultsBlock';
import SelectGroupHelperBlock from 'Common/widgets/SelectGroupHelperBlock';
import { DEFECTS_DICTIONARIES, DICTIONARIES } from 'Shared/constants/routes';
import { INITIAL_PAGE_NUMBER } from 'Shared/constants/paging';

import useStyles from './styles';

const DefectsDictionaryList = ({ theme }) => {
  const { t } = useTranslation();
  const translatedtableConfig = tableConfig(t);
  const classes = useStyles();
  const {
    defectsDictionaryStore: {
      list,
      loadListByGroup,
      isLoading,
      discardList,
      groupValue,
      setGroupValue,
      loadDefectsGroups,
      defectsGroups,
      isLoadingDefectsGroups,
      isListLoading,
      isListLoaded,
      totalPages,
      hasNextPage,
      hasPreviousPage,
    } = {},
    notificationStore: { enqueueSnackbar },
  } = useStores();

  const location = useLocation();
  const { state: { defectsGroup } = {} } = location;
  const history = useHistory();

  const showDefaultErrorNotification = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('REQUEST_DEFAULT_ERROR'),
              },
            ],
          },
        ],
      },
      variant: 'error',
    });
  };

  const onRowClickHandler = (event, row = {}) => {
    const { defectId, isUpdating } = row;
    if (isUpdating) return;
    history.push({
      pathname: `${DEFECTS_DICTIONARIES}/${defectId}`,
      state: { defectsGroup: groupValue },
    });
  };

  useEffect(() => {
    if (isEmpty(defectsGroups)) {
      loadDefectsGroups().catch(() => {
        showDefaultErrorNotification();
      });
    }
    if (defectsGroup) {
      history.replace({
        state: {},
      });
      setGroupValue(defectsGroup);
      loadListByGroup({ groupId: defectsGroup, pageNumber: INITIAL_PAGE_NUMBER }).catch(() => showDefaultErrorNotification());
    }
    return () => {
      setGroupValue('');
      discardList();
    };
  }, []);

  const {
    headerHeight = 0,
    pageHeaderHeight = 0,
    mainContentPaddingTop = 0,
    mainContentPaddingBottom = 0,
    tableFooterHeight = 0,
  } = theme;
  const tableBodyHeight = `calc(100vh - ${headerHeight}px - ${pageHeaderHeight}px - ${mainContentPaddingTop}px - ${mainContentPaddingBottom}px - ${tableFooterHeight}px)`;

  const showInitialBlock = !groupValue;
  const showNoResultsBlock = isListLoaded && isEmpty(list);
  const showDefects = !isEmpty(list);

  const goToDictionariesList = () => history.push({ pathname: DICTIONARIES });

  const loadPaginatedList = (pageNumber, perPage) => {
    loadListByGroup({ groupId: groupValue, pageNumber, perPage }).catch(() => showDefaultErrorNotification());
  };

  return (
    <PaperLayout>
      {(isLoading || isLoadingDefectsGroups || isListLoading) && <ProgressBar />}
      <Header
        twoColumns
        isActiveGoBack
        titleGoBack={t('GO_BACK')}
        title={t('DEFECTS_FOR_RE_REGISTRATION')}
        handleGoBack={goToDictionariesList}
        style={{ borderBottom: 'none' }}
      >
        <SelectInput
          className={classes.defectsGroupInput}
          disabled={isEmpty(defectsGroups)}
          listBoxMaxHeight={300}
          withSearch
          placeholder={t('ENTER_CODE_OR_DEFECT_NAME')}
          label={t('DEFECT_GROUP')}
          value={groupValue}
          name="technicalObjectId"
          onChange={(val) => {
            const groupId = get(val, 'id', '');
            setGroupValue(groupId);
            if (groupId) {
              loadListByGroup({ groupId, pageNumber: INITIAL_PAGE_NUMBER }).catch(() => showDefaultErrorNotification());
              return;
            }
            discardList();
          }}
          options={defectsGroups}
          optionValueKey="id"
          optionTitleKey="shortText"
          getOptionTitle={(option = {}) => {
            const { codeGroup, shortText } = option;
            return `${codeGroup} - ${shortText}`;
          }}
          data-test="technicalObjectId"
        />
      </Header>
      <div style={{ position: 'relative', zIndex: 1 }}>
        {showInitialBlock && <SelectGroupHelperBlock />}
        {showNoResultsBlock && (
          <Box css={{ marginTop: 144 }}>
            <NoResultsBlock
              title={t('NO_DEFECTS')}
              subTitle={t('FAILED_TO_DISPLAY_DEFECTS_FOR_THE_SELECTED_GROUP')}
            />
          </Box>
        )}
        {showDefects && (
          <DataTable
            withBoxShadow={false}
            tableBodyHeight={tableBodyHeight}
            data={list}
            titleSingle={t('TASK_ACCUSATIVE')}
            columns={translatedtableConfig.columns}
            onRowClick={onRowClickHandler}
            actions={translatedtableConfig.actions}
            dataTestRowSuffixEntityName="id"
            count={totalPages}
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPreviousPage}
            onPageChange={loadPaginatedList}
            onPerPageChange={loadPaginatedList}
          />
        )}
      </div>
    </PaperLayout>
  );
};

DefectsDictionaryList.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.shape({
    headerHeight: PropTypes.number,
    pageHeaderHeight: PropTypes.number,
    pageTabsHeight: PropTypes.number,
    tableHeaderHeight: PropTypes.number,
    tableFooterHeight: PropTypes.number,
    mainContentPaddingTop: PropTypes.number,
    mainContentPaddingBottom: PropTypes.number,
    pageSearchBlockHeight: PropTypes.number,
  }).isRequired,
};

export default withRouter(withTheme(observer(DefectsDictionaryList)));
