import {
  action,
  observable,
  runInAction,
  makeObservable,
} from 'mobx';
import MeasurementReportAgent from 'ApiAgents/Reports/MeasurementReportAgent';

const measurementReportAgent = new MeasurementReportAgent();

export class MeasurementReportListStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable isReportsListLoaded = false;

  @observable isShiftsLoaded = false;

  @observable totalPages = null;

  @observable hasNextPage = null;

  @observable isLoadingByPage = false;

  @observable isLoading = false;

  @observable loadReportsError = null;

  @observable reportsList = [];

  @observable shifts = [];

  @action loadReportsList = async (pageNumber, pageSize) => {
    const isPaginating = !!pageNumber;
    this.rootStore.setLoadingFlag(
      isPaginating,
      this,
      'isLoadingByPage',
      'isLoading',
      true
    );

    try {
      const { items, totalPages, hasNextPage } = await measurementReportAgent.fetchReports(
        isPaginating,
        pageNumber,
        pageSize
      );

      runInAction(() => {
        this.reportsList = items;
        this.totalPages = totalPages;
        this.hasNextPage = hasNextPage;
        this.isReportsListLoaded = true;
      });
    } catch (error) {
      this.loadReportsError = error;
    }

    runInAction(() => {
      this.rootStore.setLoadingFlag(
        isPaginating,
        this,
        'isLoadingByPage',
        'isLoading',
        false
      );
    });
  };

  @action getReportShifts = async () => {
    if (this.isShiftsLoaded) return;
    try {
      const shifts = await measurementReportAgent.getReportShifts();
      runInAction(() => {
        this.shifts = shifts;
        this.isShiftsLoaded = true;
      });
    } catch (error) {
      console.log('ERROR IN FETCHING SHIFTS DICT: ', error);
    }
  };
}

export default MeasurementReportListStore;
