import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  user: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    padding: 20,
    borderRadius: 4,
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.16)',
    border: `1px solid ${theme.palette.midGray}`,
    '&::after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: 60,
      zIndex: 1,
      backgroundColor: ({ isUserActive, isUserInSystem }) => {
        if (!isUserInSystem) return theme.palette.midGray;
        if (!isUserActive) return theme.palette.pink;
        return theme.palette.lightSelection;
      },
    },
    '&:hover': {
      boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.16), 0 4px 8px 0 rgba(0, 0, 0, 0.16)',
      '& $link': {
        textDecoration: 'underline',
      },
    },
  },
  userRole: {
    fontSize: '16px',
    lineHeight: 1.5,
    color: theme.palette.black,
    textAlign: 'center',
    marginTop: 16,
  },
  userName: {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: 1.5,
    textAlign: 'center',
    height: 48,
    marginTop: 8,
  },
  positionName: {
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.midBlack,
    textAlign: 'center',
    height: 36,
    marginTop: 8,
  },
  workDetails: {
    marginTop: 8,
    '& div': {
      fontSize: '12px',
      lineHeight: '16px',
      color: theme.palette.black30,
      textAlign: 'center',
      marginTop: 4,
      height: 32,
    },
  },
  addUserButton: {
    width: '100%',
    padding: '0 auto',
    minHeight: 40,
    maxHeight: 40,
    fontSize: 16,
    fontWeight: 'normal',
    boxShadow: 'none',
    borderColor: theme.palette.black20,
  },
  link: {
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  buttonWrapper: {
    width: '100%'
  },
}));

export default useStyles;
