import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import useStores from 'Store/useStores';
import { get, startsWith, isEmpty } from 'lodash';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import Panel, { PanelBox, PanelTitle } from 'Common/widgets/Layout/Panel/Panel';
import Input from 'Common/components/Forms/Input/Input';
import TextInput from 'Common/components/Forms/Input/TextTemp';
import DottedLoader from 'Common/components/Progress/components/Dotted';
import { WORKPLACES_SETTINGS } from 'Shared/constants/routes';
import { CANNOT_INSERT_DUPLICATE_KEY } from 'Shared/constants/responseErrors/errors';

import useStyles from './styles';


const WORKPLACE_CODE_MAX_LENGTH = 8;
const WORKPLACE_TITLE_MAX_LENGTH = 40;

const WorkplacesItem = ({ mode }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const { state: locationState } = location;
  const { id } = params;

  const {
    workplacesItemStore: {
      loadItem,
      discardItem,
      workplace,
      isItemLoading,
      updateField,
      loadWorkplaceTypes,
      isTypesLoading,
      workplaceTypes,
      loadAllRootOrgUnits,
      rootOrgUnits,
      loadDepartments,
      departments,
      isDepartmentsLoading,
      save,
      update
    },
    notificationStore: { enqueueSnackbar },
    validationStore: { validateRequiredFields, isValidFields, hasErrors },
  } = useStores();

  const [workplaceCodeHasError, setWorkplaceCodeHasError] = useState(false);
  const [workplaceTitleHasError, setWorkplaceTitleHasError] = useState(false);
  const [hasCodeError, setHasCodeError] = useState(false);

  const isEditingMode = mode === 'edit';

  const {
    code: workplaceCode = '',
    title: workplaceTitle = '',
    type = 1,
    orgUnit,
    department,
  } = workplace;

  const orgUnitId = get(orgUnit, 'id');
  const departmentId = get(department, 'id');

  const goToList = () => {
    history.push({
      pathname: WORKPLACES_SETTINGS,
      state: locationState,
    });
  };

  const showDefaultErrorNotification = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('REQUEST_DEFAULT_ERROR'),
              },
            ],
          },
        ],
      },
      variant: 'error',
    });
  };

  const updateItem = async () => {
    validateRequiredFields();
    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('CANT_UPDATE'),
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    const { error } = await update(id);

    if (error) {
      showDefaultErrorNotification();
      return;
    }

    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: `${t('CHANGES_SUCCESSFULLY_APPLIED')}!`,
              },
            ],
          },
        ],
      },
      variant: 'success',
    });
    goToList();
  };

  const saveItem = async () => {
    if (hasCodeError) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('CANT_SAVE'),
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('WORKPLACE_DUPLICATE_CODE'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    validateRequiredFields();
    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('CANT_SAVE'),
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    const { error } = await save();

    if (error) {
      const errorMessage = get(error, 'data.exceptionDto.innerException');
      const isDuplicate = startsWith(errorMessage, CANNOT_INSERT_DUPLICATE_KEY);
      if (isDuplicate) setHasCodeError(true);
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('CANT_SAVE'),
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: isDuplicate ? t('WORKPLACE_DUPLICATE_CODE') : t('REQUEST_DEFAULT_ERROR'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('WORKPLACE_SUCCESSFULLY_ADDED'),
              },
            ],
          },
        ],
      },
      variant: 'success',
    });
    goToList();
  };

  useEffect(() => {
    if (isEditingMode) {
      loadItem(id).catch(() => {
        showDefaultErrorNotification();
      });
    }
    loadWorkplaceTypes().catch(() => {
      showDefaultErrorNotification();
    });
    loadAllRootOrgUnits().catch(() => {
      showDefaultErrorNotification();
    });
    return () => discardItem();
  }, []);

  useEffect(() => {
    loadDepartments(orgUnitId).catch(() => {
      showDefaultErrorNotification();
    });
    if (!orgUnitId && department) updateField('department', null);
  }, [orgUnitId]);

  const workplaceCodeOnChange = (value) => {
    let workplaceCodeValue = value;
    if (value.length > WORKPLACE_CODE_MAX_LENGTH) {
      setWorkplaceCodeHasError(true);
      workplaceCodeValue = workplaceCodeValue.slice(0, WORKPLACE_CODE_MAX_LENGTH);
    } else if (setWorkplaceCodeHasError) {
      setWorkplaceCodeHasError(false);
    }
    updateField('code', workplaceCodeValue);
    if (hasCodeError) setHasCodeError(false);
  };

  const workplaceTitleOnChange = (value) => {
    let workplaceTitleValue = value;
    if (value.length > WORKPLACE_TITLE_MAX_LENGTH) {
      setWorkplaceTitleHasError(true);
      workplaceTitleValue = workplaceTitleValue.slice(0, WORKPLACE_TITLE_MAX_LENGTH);
    } else if (setWorkplaceTitleHasError) {
      setWorkplaceTitleHasError(false);
    }
    updateField('title', workplaceTitleValue);
  };

  return (
    <>
      {(isItemLoading || isTypesLoading) && <ProgressBar />}
      <Panel
        mode={mode}
        saveItem={mode === 'edit' ? updateItem : saveItem}
        goBack={goToList}
        viewItem={goToList}
        labelEdit={t('WORK_PLACE_EDITING')}
        labelCreate={t('WORK_PLACE_ADDING')}
        withActions
      >
        <PanelBox>
          <PanelTitle>{t('WORK_PLACE_ATTRIBUTES')}</PanelTitle>
          <TextInput
            className={classes.workplaceCode}
            onBlur={() => setWorkplaceCodeHasError(false)}
            maxLength={WORKPLACE_CODE_MAX_LENGTH}
            counterHasError={workplaceCodeHasError}
            label={t('CODE')}
            value={workplaceCode}
            name="code"
            isReadonly={isEditingMode}
            onChange={workplaceCodeOnChange}
            required
            error={hasCodeError}
            data-test="code"
          />
          <TextInput
            onBlur={() => setWorkplaceTitleHasError(false)}
            maxLength={WORKPLACE_TITLE_MAX_LENGTH}
            counterHasError={workplaceTitleHasError}
            label={t('NAME')}
            value={workplaceTitle}
            name="title"
            onChange={workplaceTitleOnChange}
            required
            data-test="name"
          />
          <Input
            fullOutlined
            label={t('ORG_UNIT_SHORT')}
            disabled={isEmpty(rootOrgUnits)}
            value={orgUnitId}
            onChange={(value) => {
              updateField('orgUnit', value);
            }}
            name="orgUnit"
            placeholder={t('SELECT_VALUE')}
            type="selectAlpha"
            options={rootOrgUnits || []}
            optionValueKey="id"
            optionTitleKey="name"
            getOptionTitle={({ name, code }) => name || code}
          />
          <DottedLoader isLoading={isDepartmentsLoading}>
            <Input
              fullOutlined
              label={t('PL_DEPARTMENT')}
              disabled={isEmpty(departments)}
              value={departmentId}
              onChange={(value) => {
                updateField('department', value);
              }}
              name="department"
              placeholder={t('SELECT_VALUE')}
              type="selectAlpha"
              options={departments || []}
              optionValueKey="id"
              optionTitleKey="name"
              getOptionTitle={({ name, code }) => name || code}
              required={Boolean(orgUnitId)}
            />
          </DottedLoader>
          <div style={{ width: '50%' }}>
            <Input
              label={t('TYPE')}
              value={type}
              name="type"
              onChange={(value) => {
                updateField('type', parseInt(value, 10));
              }}
              type="radio"
              options={workplaceTypes.map(({ value, title }) => ({ value, title: t(title) }))}
            />
          </div>
        </PanelBox>
      </Panel>
    </>
  );
};

WorkplacesItem.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default observer(WorkplacesItem);
