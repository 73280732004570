import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchNoResults } from 'Src/assets/search-no-results.svg';
import { ReactComponent as Arrow } from 'Src/assets/arrow-with-scribble.svg';

import useStyles from './styles';

const AddUserHelper = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Arrow className={classes.arrowImage} />
        <SearchNoResults className={classes.searchImage} />
        <div className={classes.title}>{t('ADD_CURRENT_USER')}</div>
        <div className={classes.subTitle}>{t('ADD_CURRENT_USER_HELPER_TEXT_MAIN')}</div>
        <div className={classes.subTitle}>{t('ADD_CURRENT_USER_HELPER_TEXT_ADDITIONAL')}</div>
      </div>
    </div>
  );
};

export default AddUserHelper;
