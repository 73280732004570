import get from 'lodash/get';

export default function withStyles(config, { width, ...rest }) {
  const styledConfig = { ...config, width };
  const configCellStyle = get(config, 'cellStyle', {});
  styledConfig.cellStyle = {
    ...configCellStyle, width, ...rest
  };
  const configHeaderStyle = get(config, 'headerStyle', {});
  styledConfig.headerStyle = { ...configHeaderStyle, width };

  return styledConfig;
}
