import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Done from '@material-ui/icons/Done';

import { ReactComponent as Arrow } from 'Assets/arrow.svg';

import useStyles from './styles';

const BrigadesStaffValidationBlock = ({ employeesState, error }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const isEmployeesSelected = employeesState.selected.length >= 2;
  const isBrigadierSelected = Boolean(employeesState.brigadier.id);

  return (
    <div className={classes.root}>
      <div>
        <Arrow className={classes.arrow} />
        {t('SELECT_EMPLOEES_FROM_LIST')}
      </div>
      <div className={clsx(classes.item, error && !isEmployeesSelected && classes.error)}>
        {isEmployeesSelected && <Done className={classes.icon} />}
        {t('BRIGADE_COMPOSITION')}
      </div>
      <div className={clsx(classes.item, error && !isBrigadierSelected && classes.error)}>
        {isBrigadierSelected && <Done className={classes.icon} />}
        {t('SELECT_PRODUCER')}
      </div>
    </div>
  );
};

BrigadesStaffValidationBlock.propTypes = {
  employeesState: PropTypes.shape({
    brigadier: PropTypes.shape({
      id: PropTypes.string,
    }),
    selected: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    checked: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
  }).isRequired,
  error: PropTypes.bool.isRequired,
};

export default BrigadesStaffValidationBlock;
