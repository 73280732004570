import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  helperText: {
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center'
  },
  optionRenderer: {
    marginLeft: 16,
    marginTop: 16,
    marginBottom: 0,
  },
}));

export default useStyles;
