import { makeStyles } from '@material-ui/core/styles';
import { isUndefined } from 'lodash';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    display: 'block',
  },
  textField: {
    position: 'relative',
    '& input': {
      fontSize: 16,
    },
    maxWidth: ({ maxWidth }) => (maxWidth || '100%'),
    '& .MuiInputBase-root': {
      overflow: 'hidden',
      color: theme.palette.black,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.primaryLight,
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: theme.palette.negative,
    },
    marginTop: ({ marginTop }) => (!isUndefined(marginTop) ? marginTop : theme.spacing(1)),
    marginBottom: ({ marginBottom }) => (!isUndefined(marginBottom) ? marginBottom : theme.spacing(3)),
    '& .MuiInputLabel-shrink': {
      color: ({ error }) => (error ? theme.palette.negative : theme.palette.black30),
    },
    '& .MuiInputLabel-shrink.Mui-focused': {
      color: ({ error }) => (error ? theme.palette.negative : theme.palette.primaryLight),
    },
  },
  required: {
    display: 'block',
    background: theme.palette.white,
    opacity: ({ disabled }) => (disabled ? 0.5 : 1),
    marginLeft: 16,
    fontSize: 12,
    lineHeight: '16px',
    color: ({ error }) => (error ? theme.palette.negative : theme.palette.black30),
  },
  helper: {
    display: 'flex',
    alignItems: 'flex-start',
    position: 'absolute',
    fontSize: 12,
    left: 0,
    right: 0,
    lineHeight: '16px',
    bottom: 6,
  },
  counter: {
    marginLeft: 'auto',
    marginRight: 16,
    color: ({ error, counterHasError }) => (error || counterHasError ? theme.palette.negative : theme.palette.black30),
  },
  buttonBase: {
    cursor: 'text',
  },
}));

export default useStyles;
