import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Tooltip } from '@material-ui/core/';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import LinesEllipsis from 'react-lines-ellipsis';
import ActionButton from 'Common/components/Forms/Button/components/Action/Action';
import UserImage from 'Common/pages/User/components/UserImage';
import OverflowTip from 'Common/widgets/OverflowTip';
import { VIEW_USER } from 'Shared/constants/routes';

import useStyles from './styles';

const UserCard = ({ user, addUser }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;

  const {
    id,
    fullName,
    photo,
    position,
    role: userRole,
    rootOrgUnit,
    department,
    status = false,
    isUserInSystem,
  } = user;

  const isUserActive = status === 'ACTIVE';

  const classes = useStyles({ isUserActive, isUserInSystem });
  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

  const userOrgUnit = rootOrgUnit || t('ORG_UNIT_NOT_PROVIDED');
  const userLink = `${VIEW_USER}/${id}`;

  return (
    <div
      data-test={id}
      className={classes.user}
    >
      <UserImage
        disabled={!isUserActive}
        isUserInSystem={isUserInSystem}
        photo={photo}
        fullName={fullName}
      />
      <div className={classes.userName}>
        <Link
          className={classes.link}
          data-test="user-link"
          to={{
            pathname: userLink,
            state: { withFilter: true, fromPath: pathname },
          }}
        >
          <OverflowTip
            maxLine={2}
            tooltipTitle={fullName}
            text={fullName}
            data-test="fullName"
          />
        </Link>
      </div>
      <div className={classes.positionName}>
        <ResponsiveEllipsis
          text={position || ''}
          maxLine="2"
          ellipsis="..."
          trimRight
          basedOn="letters"
          data-test="position"
        />
      </div>
      <div className={classes.workDetails}>
        <ResponsiveEllipsis
          text={userOrgUnit}
          maxLine="2"
          ellipsis="..."
          trimRight
          basedOn="letters"
          data-test="user-org-unit"
        />
        {department && (
          <ResponsiveEllipsis
            text={department}
            maxLine="2"
            ellipsis="..."
            trimRight
            basedOn="letters"
            data-test="department"
          />
        )}
      </div>
      {isUserInSystem && userRole && <div className={classes.userRole}>{userRole}</div>}
      {!isUserInSystem && (
      <div className={classes.buttonWrapper}>
        <Tooltip title={t('ADD_USER')}>
          <ActionButton
            className={classes.addUserButton}
            text={t('ADD')}
            type="cancel"
            handleClick={() => addUser(id)}
            data-test="add-user-button"
          />
        </Tooltip>
      </div>
      )}
    </div>
  );
};

UserCard.defaultProps = {
  addUser: () => {},
};

UserCard.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    fullName: PropTypes.string,
    photo: PropTypes.string,
    position: PropTypes.string,
    role: PropTypes.string,
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    rootOrgUnit: PropTypes.string,
    department: PropTypes.string,
    status: PropTypes.string,
    isUserInSystem: PropTypes.bool,
  }).isRequired,
  addUser: PropTypes.func,
};

export default UserCard;
