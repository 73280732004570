import { makeStyles } from '@material-ui/core/styles';
import { isUndefined } from 'lodash';

const useStyles = makeStyles(theme => ({
  formControlDate: {
    '& .MuiFormControl-root': {
      marginTop: ({ marginTop }) => (!isUndefined(marginTop) ? marginTop : theme.spacing(1)),
      marginBottom: ({ marginBottom }) => (!isUndefined(marginBottom) ? marginBottom : theme.spacing(3)),
      maxWidth: ({ maxWidth }) => (!isUndefined(maxWidth) || '100%'),
      '& .MuiFormHelperText-root': {
        fontSize: 12,
        color: ({ error }) => (error ? theme.palette.negative : theme.palette.black30),
        lineHeight: 1.1,
      },
    },
    '& input': {
      fontSize: 16,
    },
    '& label': {
      color: theme.palette.black30,
    },
    '& .MuiInputBase-root': {
      '& fieldset': {
        fontSize: 16,
      },
      '&:hover': {
        '& fieldset': {
          borderColor: ({ error }) => (error ? theme.palette.negative : theme.palette.primaryLight),
        },
      },
      color: theme.palette.black,
    },
    '& .MuiFormLabel-root': {
      fontSize: 16,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.primaryLight,
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: theme.palette.negative,
    },
    '& .MuiInputLabel-shrink': {
      color: ({ error }) => (error ? theme.palette.negative : theme.palette.black30),
    },
    '& .MuiInputLabel-shrink.Mui-focused': {
      color: ({ error }) => (error ? theme.palette.negative : theme.palette.primaryLight),
    },
  },
  isRequired: {
    '& .MuiFormControl-root': {
      '& .MuiFormHelperText-root': {
        marginTop: 20,
      },
    },
    '& .MuiInputBase-root': {
      '&::after': {
        display: 'block',
        content: ({ t }) => (`'${t('REQUIRED')}'`),
        position: 'absolute',
        background: theme.palette.white,
        top: 'calc(100% + 2px)',
        left: 16,
        fontFamily: 'Roboto',
        fontSize: 12,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: 'normal',
        color: ({ error }) => (error ? theme.palette.negative : theme.palette.black30),
      },
    },
  },
}));

export default useStyles;
