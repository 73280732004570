import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core/';
import { ReactComponent as IconSettings } from 'Assets/icon-settings.svg';
import withValidation from 'Src/HOC/withValidation';

import useStyles from './styles';

const AddEquipmentButton = ({
  onClick, disabled, error, required
}) => {
  const { t } = useTranslation();
  const classes = useStyles({
    error, required, disabled, t
  });
  return (
    <Button
      variant="outlined"
      color="primary"
      className={classes.addEquipmentButton}
      onClick={onClick}
      data-test="add-equipment-button"
      fullWidth
      disabled={disabled}
    >
      <IconSettings className={classes.addEquipmentIcon} />
      <span>{t('ADD_EQUIPMENT')}</span>
    </Button>
  );
};

AddEquipmentButton.defaultProps = {
  error: false,
  required: false,
};

AddEquipmentButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  required: PropTypes.bool,
};

export default withValidation(AddEquipmentButton);
