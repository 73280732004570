import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { get, toSafeInteger } from 'lodash';
import clsx from 'clsx';
import { TextField, InputAdornment, ButtonBase } from '@material-ui/core';

import OptionRenderer from 'Common/components/Forms/OptionRenderer';
import withValidation from 'Src/HOC/withValidation';

import useStyles from './styles';

const Number = forwardRef((props, ref) => {
  const {
    helperText,
    value,
    min,
    max,
    name,
    label,
    placeholder,
    className,
    inputProps,
    isReadonly,
    onChange,
    error,
    required,
    InputEndAdornment,
    maxWidth,
    marginBottom,
    marginTop,
    disabled,
    requiredMessage,
    style,
    autoFocus,
    multiline,
    rows,
    rowsMax,
    'data-test': dataTest,
    onBlur,
    onClickEndAdornment,
    OptionRendererProps,
  } = props;

  const selfRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      selfRef.current.focus();
    },
    blur: () => {
      selfRef.current.blur();
    }
  }));


  const { t } = useTranslation();
  const classes = useStyles({
    value,
    maxWidth,
    marginBottom,
    marginTop,
    error,
    disabled,
    helperText,
    requiredMessage,
    t
  });

  if (isReadonly) {
    return (
      <OptionRenderer
        title={label}
        value={value || t('INPUT_EMPTY_VALUE')}
        endAdornmentNode={InputEndAdornment}
        maxWidth={maxWidth}
        {...OptionRendererProps}
        {...dataTest && { 'data-test': dataTest }}
      />
    );
  }

  const onlyPositiveNumbers = (min && toSafeInteger(min) >= 0);
  return (
    <TextField
      multiline={multiline}
      rows={rows}
      rowsMax={rowsMax}
      autoFocus={autoFocus}
      placeholder={placeholder}
      autoComplete="off"
      type="number"
      classes={{ root: clsx(Boolean(className) && className, classes.textField, required && classes.required) }}
      value={value || ''}
      id={name}
      name={name}
      label={label}
      variant="outlined"
      margin="dense"
      InputProps={{
        inputRef: selfRef,
        onKeyDown: (event) => {
          if (!onlyPositiveNumbers) return;
          const { keyCode } = event;
          const minusKeyCodes = [109, 189, 173];
          if (minusKeyCodes.includes(keyCode)) {
            event.preventDefault();
            event.stopPropagation();
          }
        },
        inputProps: {
          ...min && { min },
          ...max && { max },
          ...inputProps,
          ...dataTest && { 'data-test': dataTest },
        },
        ...InputEndAdornment && {
          endAdornment: (
            <InputAdornment>
              <ButtonBase
                classes={{ root: classes.buttonBase }}
                disableRipple
                onClick={() => {
                  if (onClickEndAdornment) onClickEndAdornment();
                  selfRef.current.focus();
                }}
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
              >
                {InputEndAdornment}
              </ButtonBase>
            </InputAdornment>
          )
        },
        readOnly: Boolean(isReadonly),
        disabled: Boolean(isReadonly),
      }}
      fullWidth
      error={error}
      onChange={(event) => {
        let val = get(event, 'target.value', '');
        const inputName = get(event, 'target.name', '');
        if (onlyPositiveNumbers) val = val.replace(/[^0-9]/g, '');
        onChange(val, inputName);
      }}
      {...onBlur && { onBlur }}
      {...style && { style }}
      {...helperText && { helperText }}
    />
  );
});

Number.defaultProps = {
  className: undefined,
  autoFocus: false,
  helperText: '',
  value: '',
  label: '',
  placeholder: '',
  inputProps: {},
  isReadonly: false,
  error: false,
  required: false,
  InputEndAdornment: null,
  maxWidth: null,
  marginBottom: undefined,
  marginTop: undefined,
  disabled: false,
  requiredMessage: '',
  style: {},
  multiline: false,
  rows: undefined,
  rowsMax: undefined,
  'data-test': undefined,
  onBlur: undefined,
  onClickEndAdornment: undefined,
  min: '',
  max: '',
  OptionRendererProps: {},
};

Number.propTypes = {
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  inputProps: PropTypes.shape({ 'data-test': PropTypes.string }),
  isReadonly: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  InputEndAdornment: PropTypes.node,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number,
  disabled: PropTypes.bool,
  requiredMessage: PropTypes.string,
  style: PropTypes.shape({
    marginTop: PropTypes.number,
  }),
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  'data-test': PropTypes.string,
  onBlur: PropTypes.func,
  onClickEndAdornment: PropTypes.func,
  min: PropTypes.string,
  max: PropTypes.string,
  OptionRendererProps: PropTypes.shape({
    maxLine: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    valueRenderer: PropTypes.func,
    wrapperClass: PropTypes.string,
    titleClass: PropTypes.string,
    valueClass: PropTypes.string,
    titlePosition: PropTypes.oneOf(['left', 'right']),
  }),
};

export default withValidation(Number);
