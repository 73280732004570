import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  subheader: {
    paddingLeft: 12,
    paddingRight: 12,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.midGray,
  },
}));

const Subheader = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.subheader}>
      {children}
    </div>
  );
};

Subheader.propTypes = {
  children: PropTypes.node.isRequired
};

export default Subheader;
