import { MASTER, SPECIALIST_SDKR, ECONOMIST_IETS } from 'Shared/constants/roles';

const getSuccessConfirmMessage = (role, t) => {
  let message;
  switch (role) {
    case MASTER:
      message = t('MATERIAL_VALUES_SUCCESSFULLY_CONFIRMED_SENT_TO_THE_SPECIALIST_FOR_CONFIRMATION');
      break;
    case SPECIALIST_SDKR:
      message = t('MATERIAL_VALUES_SUCCESSFULLY_CONFIRMED_SENT_TO_THE_ECONOMIST_FOR_CONFIRMATION');
      break;
    case ECONOMIST_IETS:
      message = t('MATERIAL_VALUES_SUCCESSFULLY_CONFIRMED_SENT_TO_THE_SAP_SYSTEM');
      break;
    default:
      message = '';
  }
  return message;
};

export default getSuccessConfirmMessage;
