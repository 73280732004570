import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    boxShadow: `0 1px 3px 0 ${theme.palette.midGray}`,
    border: `solid 1px ${theme.palette.midGray}`,
    backgroundColor: '#fff',
    padding: '16px 16px 24px 16px',
  },
  widgetHeader: {
    display: 'flex',
    marginBottom: 16,
  },
  widgetTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: theme.palette.black,
  },
  widgetButtons: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  deleteIconButton: {
    marginLeft: 16,
    padding: 2,
    color: theme.palette.negative,
    '&:hover': {
      backgroundColor: theme.palette.pink,
    }
  },
  editIconButton: {
    marginLeft: 16,
    padding: 2,
    color: theme.palette.primaryLight,
    '&:hover': {
      backgroundColor: theme.palette.lightSelection,
    }
  },
  assignmentsIcon: {
    color: theme.palette.primaryLight,
  },
  assignmentsBlock: {
    color: theme.palette.primaryLight,
    display: 'flex',
    alignItems: 'center',
  },
  assignmentsBlockTooltip: {
    textAlign: 'center',
  },
  assignmentsCountLabel: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
  },
  required: {
    '&::after': {
      display: 'block',
      content: ({ t } = {}) => (`'${t('REQUIRED')}'`),
      position: 'absolute',
      background: theme.palette.white,
      top: 'calc(100% + 2px)',
      left: 16,
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.33,
      letterSpacing: 'normal',
      color: theme.palette.black30,
    },
  },
}));

export default useStyles;
