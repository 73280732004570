import {
  action, observable, makeObservable
} from 'mobx';
import { v4 as uuidv4 } from 'uuid';

export class NotificationStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable notifications = [];

  @action enqueueSnackbar = (note) => {
    this.notifications.push({
      key: uuidv4(),
      ...note,
    });
  };

  @action removeSnackbar = (key) => {
    this.notifications = this.notifications.filter(notification => notification.key !== key);
  };
}

export default NotificationStore;
