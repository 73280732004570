import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/IconButton';
import {
  Edit, CheckCircleOutlineSharp, HighlightOffRounded, DeleteOutline, Add
} from '@material-ui/icons';
import clsx from 'clsx';


const useStyles = makeStyles(theme => ({
  btn: {
    fontSize: '16px',
    padding: theme.spacing(1),
    margin: '0px 12px 0',
    borderRadius: 4,
    color: ({ type }) => (type === 'delete' ? theme.palette.negative : theme.palette.blue),
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
    },
    '&:hover': {
      backgroundColor: theme.palette.default,
    },
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  disabled: {
    color: theme.palette.black20,
  },
}));

const HeaderButton = ({
  handleClick, type, disabled, title,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ type });

  const content = {
    save: { icon: CheckCircleOutlineSharp, title: t('SAVE') },
    reject: { title: t('REJECT') },
    close: { icon: HighlightOffRounded, title: t('CANCEL') },
    edit: { icon: Edit, title: t('EDIT') },
    delete: { icon: DeleteOutline, title: t('DELETE') },
    clear: { icon: DeleteOutline, title: t('CLEAR') },
    add: { icon: Add, title: t('ADD') },
    confirm: { icon: CheckCircleOutlineSharp, title: t('CONFIRM') },
  };

  const { icon: Icon, title: defaultTitle } = content[type];

  return (
    <Button
      variant="contained"
      component="a"
      className={clsx(classes.btn, disabled && classes.disabled)}
      onClick={handleClick}
      data-test={`${type}-button`}
      disabled={disabled}
    >
      {Icon && <Icon className={classes.leftIcon} />}
      <span className={clsx(disabled && classes.disabled)}>{title || defaultTitle}</span>
    </Button>
  );
};

HeaderButton.defaultProps = {
  type: 'edit',
  disabled: false,
  title: undefined,
};

HeaderButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};


export default HeaderButton;
