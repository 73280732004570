import {
  action, observable, makeObservable, set, computed, toJS
} from 'mobx';
import { keys } from 'lodash';

export class ValidationStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable validationState = {};

  @computed get hasNotValidFields() {
    const isValid = keys(this.validationState).every(fieldName => this.validationState[fieldName].isValid);
    return !isValid;
  }

  @action addFieldToValidationState = ({ name, required, ...rest }, isValid = true) => {
    set(this.validationState, name, {
      isValid, validationText: '', required, ...rest
    });
  };

  @action isValidFields = () => keys(this.validationState).every(fieldName => this.validationState[fieldName].isValid);

  @action hasErrors = () => keys(this.validationState).some(fieldName => this.validationState[fieldName].error);

  @action validateRequiredFields = () => {
    keys(this.validationState).forEach((fieldName) => {
      const field = this.validationState[fieldName];
      const {
        value, error, validationError, required, validationFunction, helperText, isReadonly
      } = field;
      console.log('field', toJS(field)); // eslint-disable-line
      this.validationState[fieldName].isValid = validationFunction ? validationFunction({ fieldName, validationState: this.validationState }) : Boolean(value || !required || isReadonly) && !error && !validationError && !helperText;
      this.validationState[fieldName].error = Boolean(helperText) || error || (validationFunction && !validationFunction({ fieldName, validationState: this.validationState })) || validationError;
    });
  };

  @action setFieldValid = (fieldName) => {
    const field = this.validationState[fieldName];
    if (field) {
      this.validationState[fieldName].error = false;
      this.validationState[fieldName].isValid = true;
    }
  };

  @action onFieldUpdate = (fieldName, value) => {
    const field = this.validationState[fieldName];
    if (field) {
      this.validationState[fieldName].value = value;
    }
  };

  @action setHelperText = (fieldName, helperText) => {
    const field = this.validationState[fieldName];
    if (field) {
      this.validationState[fieldName].helperText = helperText;
    }
  };

  @action setValidationText = (fieldName, validationText) => {
    const field = this.validationState[fieldName];
    if (field) {
      this.validationState[fieldName].validationText = validationText;
    }
  };

  @action setFieldError = (fieldName) => {
    const field = this.validationState[fieldName];
    if (field) {
      this.validationState[fieldName].error = true;
    }
  };

  @action removeField = fieldName => delete this.validationState[fieldName];

  @action discardState = () => {
    this.validationState = {};
  };
}

export default ValidationStore;
