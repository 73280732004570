import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useStores from 'Store/useStores';
import Modal from 'Common/components/Modal/Modal';
import { Box, TextField, Typography } from '@material-ui/core';
import ActionButton from 'Common/components/Forms/Button/components/Action/Action';
import getReturnModalConfig from 'Business/tpsRepairs/utils/materialValuesConfirmationUtils/getReturnModalConfig';
import useStyles from './styles';

const modalWidth = 696;

const ReturnMaterialValuesModal = ({
  taskTitle,
  onClose,
  onSave,
}) => {
  const classes = useStyles();
  const {
    userStore: {
      role,
    },
  } = useStores();

  const { t } = useTranslation();
  const [comment, commentSet] = useState('');

  const handleSave = () => {
    onSave(comment);
  };

  const { title, message } = getReturnModalConfig(role, t);
  return (
    <Modal
      modalPaperWidth={modalWidth}
      size={false}
      handleClose={onClose}
      contentStyle={{
        margin: 0,
      }}
      isOpened
      actions={(
        <Box className={classes.actions}>
          <ActionButton
            text={t('CANCEL')}
            type="cancel"
            handleClick={onClose}
          />
          <ActionButton
            disabled={!comment}
            text={t('SAVE')}
            type="confirm"
            handleClick={handleSave}
          />
        </Box>
      )}
    >

      <Typography className={classes.title}>
        {title}
      </Typography>

      <Typography className={classes.subTitle}>
        {taskTitle}
      </Typography>

      <Typography className={classes.message}>
        {message}
      </Typography>

      <TextField
        className={classes.comment}
        id="other_reason"
        variant="outlined"
        label={t('COMMENT')}
        rows={7}
        fullWidth
        value={comment}
        onChange={event => commentSet(event.target.value)}
        multiline
      />

    </Modal>
  );
};

ReturnMaterialValuesModal.propTypes = {
  taskTitle: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ReturnMaterialValuesModal;
