import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CheckpointDetailsOverlay from 'Business/tpsInspections/components/CheckpointDetailsOverlay';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import HeaderLayout from 'Common/widgets/Layout/Header/Header';
import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import SectionList from 'Common/widgets/Section/SectionList';
import Section from 'Common/widgets/Section/Section';
import SectionListItem from 'Common/widgets/Section/SectionListItem';
import DottedLoader from 'Common/components/Progress/components/Dotted';
import PromptSection from 'Business/tpsInspections/components/PromptSection';
import useStores from 'Store/useStores';
import { makeStyles } from '@material-ui/core/styles';

const FIRST_LEVEL_TITLE = '1';

const useStyles = makeStyles(() => ({
  gridContainer: {
    height: '100%',
  },
  techPlacesLoaderHelper: {
    height: 48,
    width: '100%'
  },
}));

const CheckpointSelectOverlay = observer(({ isOpen, handleClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    taskRouteManageStore: {
      powerUnits,
      techPlaces,
      checkPoints,
      isTechPlaceInSelectedState,
      handleSelectPowerUnit,
      clearCheckpointsByPowerUnitId,
      handleSelectTechPlace,
      clearCheckpointsByTechnicalPlaceId,
      handleToggleCheckpoint,
      isPowerUnitInSelectedState,
      isCheckpointInSelectedState,
      confirmSelectedCheckpoints,
      intermediateState: {
        checkpoints: intermediateCheckpoints,
        selectedTechPlaceId,
        selectedPowerUnitId,
      },
      prepareIntermediateState,
      areTechPlacesLoading,
    }
  } = useStores();
  const isSaveDisabled = intermediateCheckpoints.length === 0;
  const [checkpointId, setCheckpointId] = useState(0);

  useEffect(() => {
    if (!isOpen) return;
    prepareIntermediateState();
  }, [isOpen]);

  const handleConfirm = () => {
    confirmSelectedCheckpoints();
    handleClose();
  };

  const handleOverlayClose = () => {
    handleClose();
  };

  const handleCheckpointDetailsOverlayClose = () => {
    setCheckpointId(0);
  };

  const viewCheckpointBy = cpId => (e) => {
    e.stopPropagation();
    setCheckpointId(cpId);
  };

  const showPowerUnitPromptSection = !selectedPowerUnitId;
  const showTechPlacePromptSection = selectedPowerUnitId && isEmpty(checkPoints);
  const showTechPlaceSection = selectedPowerUnitId;
  const showCheckpointsSection = !isEmpty(checkPoints);

  return (
    <Dialog fullScreen open={isOpen} data-test="checkpointSelectDialog">
      <Grid
        container
        direction="column"
        wrap="nowrap"
        className={classes.gridContainer}
      >
        <HeaderLayout title={t('TAG_ADDING')}>
          <HeaderButton type="close" handleClick={handleOverlayClose} />
          <HeaderButton type="save" handleClick={handleConfirm} disabled={isSaveDisabled} />
        </HeaderLayout>
        <SectionList>
          <Section title={t('POWER_UNIT')}>
            {powerUnits.map(({ id: powerUnitId, title }) => (
              <SectionListItem
                isTreePassed={isPowerUnitInSelectedState(powerUnitId)}
                key={powerUnitId}
                id={powerUnitId}
                onItemClick={handleSelectPowerUnit(powerUnitId)}
                title={title}
                isCurrentlySelected={selectedPowerUnitId === powerUnitId}
                actionFunc={clearCheckpointsByPowerUnitId(powerUnitId)}
                stopPropagationOnActionButton
              />
            ))}
          </Section>
          {showTechPlaceSection && (
            <Section title={`${t('TECHNICAL_PLACE_SHORT')} (${t('LEVEL')} ${FIRST_LEVEL_TITLE})`}>
              {techPlaces.map(({ id, title }) => (
                <SectionListItem
                  isTreePassed={isTechPlaceInSelectedState(id)}
                  key={id}
                  id={id}
                  onItemClick={handleSelectTechPlace(id)}
                  title={title}
                  isCurrentlySelected={id === selectedTechPlaceId}
                  actionFunc={clearCheckpointsByTechnicalPlaceId(id)}
                  stopPropagationOnActionButton
                />
              ))}
              {areTechPlacesLoading && (
                <DottedLoader isLoading>
                  <div className={classes.techPlacesLoaderHelper} />
                </DottedLoader>
              )}
            </Section>
          )}
          {showPowerUnitPromptSection
          && (
            <PromptSection title={t('CHOOSE_POWER_UNIT')} />
          )}
          {showCheckpointsSection
            && (
              <Section title={t('TAGS')} gridWidth={6} detachedChildren>
                {checkPoints.map((checkPoint) => {
                  const { id, name } = checkPoint;
                  return (
                    <SectionListItem
                      detached
                      variant="check"
                      checked={isCheckpointInSelectedState(id)}
                      key={id}
                      id={id}
                      actionFunc={handleToggleCheckpoint(checkPoint)}
                      onItemClick={viewCheckpointBy(id)}
                      title={`№${id}`}
                      subTitle={name}
                      noNextIcon
                    />
                  );
                })}
              </Section>
            )}
          {showTechPlacePromptSection
            && (
              <PromptSection title={t('CHOOSE_TECHNICAL_PLACE')} />
            )}
        </SectionList>
      </Grid>

      {checkpointId > 0
        && (
          <CheckpointDetailsOverlay
            checkpointId={checkpointId}
            handleClose={handleCheckpointDetailsOverlayClose}
          />
        )}
    </Dialog>
  );
});

CheckpointSelectOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default CheckpointSelectOverlay;
