import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useLocation, useParams } from 'react-router-dom';
import useStores from 'Store/useStores';
import clsx from 'clsx';
import {
  isEmpty, isArray, toSafeInteger, get, isUndefined
} from 'lodash';
import Loader from 'Common/components/Progress/components/Circular/ProgressBar';
import {
  Box, IconButton, Tooltip
} from '@material-ui/core';
import { Edit, AssignmentTurnedInOutlined } from '@material-ui/icons';
import OperationView from 'Common/widgets/OperationView/OperationView';
import actionButtonTypes from 'Common/components/Forms/Button/configs/actionButtonTypes';
import ActionButton from 'Common/components/Forms/Button/components/Action/Action';
import canConfirm from 'Business/tpsRepairs/utils/materialValuesConfirmationUtils/canConfirm';
import canReturn from 'Business/tpsRepairs/utils/materialValuesConfirmationUtils/canReturn';
import getConfirmModalConfig from 'Business/tpsRepairs/utils/materialValuesConfirmationUtils/getConfirmModalConfig';
import getSuccessConfirmMessage from 'Business/tpsRepairs/utils/materialValuesConfirmationUtils/getSuccessConfirmMessage';
import getSuccessReturnMessage from 'Business/tpsRepairs/utils/materialValuesConfirmationUtils/getSuccessReturnMessage';
import OperationsLayout from 'Business/tpsRepairs/components/OperationsLayout';
import CollapseBlock from 'Common/widgets/Layout/CollapseBlock';
import MaterialValuesEditorOverlay from 'Business/tpsRepairs/components/ConsumptionsControl/MaterialValuesEditorOverlay';
import ReturnMaterialValuesModal from 'Src/business/tpsRepairs/components/ConsumptionsControl/ReturnMaterialValuesModal';
import EditableOperationView from 'Business/tpsRepairs/components/ConsumptionsControl/EditableOperationView';
import MaterialValuesLayout from 'Business/tpsRepairs/components/ConsumptionsControl/MaterialValuesLayout';
import TaskExecutionResultBlock from 'Business/tpsRepairs/containers/TaskExecutionResultBlock';
import ConsumptionsControlTaskAttributesBlock from 'Business/tpsRepairs/containers/ConsumptionsControlTaskAttributesBlock';
import CollapsableCommentsBlock from 'Common/widgets/CollapsableCommentsBlock';
import useManageEntityModal from 'Common/components/Modal/ManageEntityModal/useManageEntityModal';
import StyledLink from 'Common/components/StyledLink';
import { materialValuesAgreementsAliases } from 'Src/business/tpsRepairs/config/materialValuesAgreementsAliases';
import { CONTROL } from 'Shared/constants/routes';
import { MASTER } from 'Shared/constants/roles';
import NoOperationsToConfirm from 'Common/widgets/NoOperationsToConfirm';

import useStyles from './styles';

const View = observer((props) => {
  const {
    task,
    materials,
    dictionaries,
    reasons
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();

  const { pathname } = location;
  const {
    setManageEntityModalVisibility, setOnManageEntityModalCloseCallback, setManageEntityModalState, renderManageEntityModal
  } = useManageEntityModal();

  const translatedMaterialValuesAgreementsAliases = materialValuesAgreementsAliases(t);

  const {
    taskId,
    title,
    plannedStartDate,
    rejectionComments = [],
    operations = [],
  } = task;
  const {
    materialValuesControlStore: {
      setMaterialValues,
      confirmMaterialValues,
      rejectMaterialValues,
      removeOperation,
      isTaskLoading,
      isTaskLoaded,
      isOperationUpdating,
    },
    notificationStore: {
      enqueueSnackbar,
    },
    userStore: {
      role,
    },
  } = useStores();


  const [operationsForEditor, setOperationsForEditor] = useState([]);
  const [rejectionOperationId, setRejectionOperationId] = useState(undefined);

  const { operationStatus } = useParams();
  const actions = {
    canConfirm: canConfirm(operationStatus, role),
    canReturn: canReturn(operationStatus, role),
  };

  const canEdit = role === MASTER && (operationStatus === translatedMaterialValuesAgreementsAliases.NEW.name || operationStatus === translatedMaterialValuesAgreementsAliases.RETURNED.name);

  const prepareOperationsByMaterialValue = (materialValueSapCode) => {
    if (!materialValueSapCode) return;
    const operationsToEdit = [];
    operations.forEach((operation) => {
      const { materialValues } = operation;
      if (isArray(materialValues)) {
        materialValues.forEach((materialValue) => {
          const {
            sapCode: materialValueSap,
          } = materialValue;
          if (materialValueSapCode === materialValueSap) {
            operationsToEdit.push({ ...operation, materialValues: [materialValue] });
          }
        });
      }
    });
    if (!isEmpty(operationsToEdit)) setOperationsForEditor(operationsToEdit);
  };

  const reject = async (operationId, comment) => {
    const response = await rejectMaterialValues(operationId, comment);
    const error = get(response, 'error');
    if (error) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('REQUEST_DEFAULT_ERROR')
            }]
          }],
        },
        variant: 'error',
      });
      setRejectionOperationId(undefined);
      return;
    }
    const message = getSuccessReturnMessage(role, t);
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: message
          }]
        }],
      },
      variant: 'success',
    });
    removeOperation(operationId);
    setRejectionOperationId(undefined);
  };

  const updateMaterialValues = async (operationId) => {
    const response = await setMaterialValues(operationId);
    const error = get(response, 'error');

    if (error) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('REQUEST_DEFAULT_ERROR')
            }]
          }],
        },
        variant: 'error',
      });
      return;
    }
    const message = getSuccessConfirmMessage(role, t);
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: message
          }]
        }],
      },
      variant: 'success',
    });
    removeOperation(operationId);
  };

  const confirm = async (operationId) => {
    const { error } = await confirmMaterialValues(operationId);
    if (error) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('REQUEST_DEFAULT_ERROR')
            }]
          }],
        },
        variant: 'error',
      });
      return;
    }
    const message = getSuccessConfirmMessage(role, t);
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: message
          }]
        }],
      },
      variant: 'success',
    });
    removeOperation(operationId);
  };

  const showConfirmModal = (operationId) => {
    setManageEntityModalVisibility(true);
    const { message, secondaryMessage } = getConfirmModalConfig(role, t);
    setManageEntityModalState({
      title: t('OPERATION_CONFIRMATION'),
      entityName: title,
      message,
      secondaryMessage,
      type: 'action',
      actionButtonTitle: t('CONFIRM'),
    });
    setOnManageEntityModalCloseCallback(() => (role === MASTER ? () => updateMaterialValues(operationId) : () => confirm(operationId)));
  };

  const showNotValidMaterialValueQuantity = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('FACT_QUANTITY_CANNOT_BE_MORE_THAN_PLANNED')
          }]
        }],
      },
      variant: 'error',
    });
  };

  const hasActions = Object.keys(actions).some(key => actions[key]);

  const renderMaterialValuesByTask = () => (
    <MaterialValuesLayout
      blockTitle={(
        <div className={classes.materialValuesLayoutHeader}>
          <div>{t('MATERIAL_VALUES_BY_OPERATIONS')}</div>
          <StyledLink
            to={{
              pathname: `${CONTROL}/viewTask/${taskId}`,
              state: { fromPath: pathname },
            }}
          >
            <div className={classes.taskLink}>
              <AssignmentTurnedInOutlined />
              <div>{t('VIEW_A_TASK')}</div>
            </div>
          </StyledLink>
        </div>
      )}
      operations={task.operations}
      renderMaterialValues={(materialValue) => {
        const {
          sapCode: materialValueSapCode, description, updatedQuantity, factQuantity, quantity, unit,
        } = materialValue;
        const negativeColored = quantity && quantity > factQuantity;
        const shouldDisplayAsEdited = !isUndefined(updatedQuantity) && +updatedQuantity !== +factQuantity;
        return (
          <OperationView
            hideHeaderButtonOnBlur
            boxShadowOnHover={canEdit}
            key={materialValueSapCode}
            title={`${description} ${description} ${description}`}
            subTitle={(
              <div className={classes.operationSubTitle}>
                {materialValueSapCode}
                {shouldDisplayAsEdited && (
                  <div>
                    {`${t('FACT_QUANTITY_SHORT_UPDATED')}: ${factQuantity} ${unit}`}
                  </div>
                )}
              </div>
            )}
            counters={{
              top: {
                left: `${t('PLANNED_QUANTITY')}: ${quantity} ${unit}`,
                right: shouldDisplayAsEdited
                  ? (
                    <div className={clsx(negativeColored && classes.negativeColored)}>
                      {`${t('FACT_QUANTITY_SHORT')}: ${!isUndefined(updatedQuantity) ? updatedQuantity : factQuantity} ${unit}`}
                    </div>
                  )
                  : (
                    <div className={clsx(negativeColored && classes.negativeColored)}>
                      {`${t('FACT_QUANTITY_SHORT')}: ${factQuantity} ${unit}`}
                    </div>
                  ),
              }
            }}
            headerActionButton={canEdit && (
              <Tooltip title={t('EDIT')}>
                <IconButton
                  size="small"
                  color="primary"
                  aria-label="settings"
                  onClick={() => prepareOperationsByMaterialValue(materialValueSapCode)}
                  data-test="edit"
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            )}
          />
        );
      }}
    />
  );

  const renderMaterialValuesByOperations = () => (
    <OperationsLayout
      withoutShowMaterialValuesToggle
      showMaterialValuesDefaultValue
      headerWithoutBottomBorder
      blockTitle={t('OPERATIONS_IN_DONE_STATUS')}
      operations={task.operations}
      renderOperation={(operation) => {
        const materialId = operation.equipmentId > 0 ? operation.equipmentId : operation.technicalPlaceId;
        const subTitle = materials[materialId] ? materials[materialId].title : '';

        return (
          <OperationView
            key={operation.operationId}
            title={operation.title}
            subTitle={subTitle}
            status={operation.status}
            counters={{
              top: {
                left: `${t('PLANNED_TIME')}: ${operation.planDuration} ${t('MIN')}`,
                right: `${t('FACT_TIME')}: ${toSafeInteger(operation.factDuration)} ${t('MIN')}`,
              },
              bottom: {
                left: `${t('PLANNED_NUMBER_OF_PEOPLE')}: ${operation.workersCount} ${t('PEOPLE_CUT')}`,
                right: `${t('FACT_NUMBER_OF_PEOPLE')}: ${toSafeInteger(operation.factWorkersCount)} ${t('PEOPLE_CUT')}`,
              }
            }}
          />
        );
      }}
      renderComments={(operationId) => {
        const operationComments = rejectionComments.filter(({ operationId: commentOperationId }) => commentOperationId === operationId);
        if (isEmpty(operationComments)) return null;
        return (
          <CollapsableCommentsBlock
            comments={operationComments}
          />
        );
      }}
      renderMaterialValues={(operation, materialValue = {}) => {
        const factQuantity = operation.materialValueConsumptions.reduce((acc, consumptionMaterial) => ((consumptionMaterial.materialValueId === materialValue.materialValueId) ? consumptionMaterial.quantity : acc), 0);
        return (
          <div>
            <EditableOperationView
              isEditable={canEdit}
              operation={operation}
              materialValue={materialValue}
              factQuantity={factQuantity}
              showNotValidMaterialValueQuantity={showNotValidMaterialValueQuantity}
            />
          </div>
        );
      }}
      renderActions={(operationId) => {
        if (!hasActions) return null;
        return (
          <div className={classes.actionButtonsWrapper}>
            {actions.canReturn && (
              <ActionButton
                type={actionButtonTypes.CANCEL}
                text={t('RETURN')}
                handleClick={() => setRejectionOperationId(operationId)}
                data-test="return"
              />
            )}
            {actions.canConfirm && (
              <ActionButton
                type={actionButtonTypes.CONFIRM}
                text={t('CONFIRM_MATERIAL_VALUES')}
                handleClick={() => showConfirmModal(operationId)}
                data-test="confirm"
              />
            )}
          </div>
        );
      }}
    />
  );

  if (isTaskLoading) return <Loader />;

  return (
    <Box css={{ display: 'flex', flexDirection: 'column', marginBottom: -24 }}>
      {isOperationUpdating && <Loader />}
      <Box className={classes.body}>
        <CollapseBlock
          headerLabel={t('EXECUTION_RESULT')}
          isOpenedByDefault={false}
        >
          <TaskExecutionResultBlock
            dictionaries={dictionaries}
            reasons={reasons}
            task={task}
          />
        </CollapseBlock>
        <CollapseBlock
          headerLabel={t('TASK_ATTRIBUTES')}
        >
          <ConsumptionsControlTaskAttributesBlock
            taskPlannedStartDate={plannedStartDate}
            dictionaries={dictionaries}
            materials={materials}
            task={task}
          />
        </CollapseBlock>
        {!isEmpty(operations) && (
          <div className={classes.commonDetails}>
            {renderMaterialValuesByTask()}
            {renderMaterialValuesByOperations()}
          </div>
        )}
        {isTaskLoaded && isEmpty(operations) && (
          <NoOperationsToConfirm />
        )}
      </Box>

      {rejectionOperationId && (
        <ReturnMaterialValuesModal
          taskTitle={title}
          isOpened
          onClose={() => setRejectionOperationId(undefined)}
          onSave={(comment) => {
            reject(rejectionOperationId, comment);
          }}
        />
      )}
      {!isEmpty(operationsForEditor) && (
        <MaterialValuesEditorOverlay
          materials={materials}
          operations={operationsForEditor}
          isOpen
          handleClose={() => { setOperationsForEditor([]); }}
        />
      )}

      {renderManageEntityModal()}
    </Box>
  );
});

View.propTypes = {
  task: PropTypes.shape({
    title: PropTypes.string,
    taskId: PropTypes.number,
    sapCode: PropTypes.string,
    taskType: PropTypes.string,
    taskStatus: PropTypes.string,
    plannedStartDate: PropTypes.string,
    currentAssignee: PropTypes.shape({
      identityId: PropTypes.string,
      identityName: PropTypes.string,
    }),
    taskRejectionReason: PropTypes.string,
    rejectReasonComment: PropTypes.string,
    planDuration: PropTypes.number,
    factDuration: PropTypes.number,
    factStartDate: PropTypes.string,
    factFinishDate: PropTypes.string,
    rootTechnicalObjectIds: PropTypes.arrayOf(PropTypes.number),
    expectedStartDate: PropTypes.string,
    expectedFinishDate: PropTypes.string,
  }).isRequired,
  materials: PropTypes.objectOf(PropTypes.shape({
    title: PropTypes.string,
  })),
  dictionaries: PropTypes.shape({
    TaskStatus: PropTypes.objectOf(PropTypes.string),
    TaskType: PropTypes.objectOf(PropTypes.string),
  }),
};

View.defaultProps = {
  materials: {},
  dictionaries: {},
};

export default View;
