const taskRoutesColumns = t => (
  [
    {
      field: 'name',
      mainLink: true,
      title: t('TITLE'),
      withEllipsis: true,
      width: '70%',
      cellStyle: {
        width: '70%',
        whiteSpace: 'normal',
      },
      headerStyle: {
        width: '70%',
        whiteSpace: 'normal',
      },
    },
    {
      field: 'routeDepartmentsNames',
      title: t('DEPARTMENT'),
      withEllipsis: true,
      width: '30%',
      cellStyle: {
        width: '30%',
        whiteSpace: 'normal',
      },
      headerStyle: {
        width: '30%',
        whiteSpace: 'normal',
      },
    },
  ]
);

export default taskRoutesColumns;
