import React, { useRef, useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { useResizeDetector } from 'react-resize-detector';
import PropTypes from 'prop-types';

const OverflowTipSimple = ({
  text, tooltipTitle, tooltipProps, textProps, 'data-test': dataTest
}) => {
  const { width, ref } = useResizeDetector();
  // Create Ref
  const textElementRef = useRef();

  const [hoverStatus, setHover] = useState(false);

  const compareSize = () => {
    const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  useEffect(() => {
    compareSize();
  }, []);

  useEffect(() => {
    compareSize();
  }, [width]);

  const { style: textPropsStyle = {}, className } = textProps;

  return (
    <Tooltip
      ref={ref}
      title={tooltipTitle}
      interactive
      disableHoverListener={!hoverStatus}
      {...tooltipProps}
    >
      <div
        value={text}
        ref={textElementRef}
        {...dataTest && { 'data-test': dataTest }}
        {...className && { className }}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...textPropsStyle,
        }}
      >
        {text}
      </div>
    </Tooltip>
  );
};

OverflowTipSimple.defaultProps = {
  text: '',
  tooltipProps: {},
  textProps: {},
  'data-test': '',
};

OverflowTipSimple.propTypes = {
  tooltipTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tooltipProps: PropTypes.shape({
    placement: PropTypes.string,
  }),
  textProps: PropTypes.shape({
    placement: PropTypes.string,
    style: PropTypes.any, // eslint-disable-line
    className: PropTypes.string
  }),
  'data-test': PropTypes.string,
};

export default OverflowTipSimple;
