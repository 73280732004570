import {
  action,
  observable,
  runInAction,
  makeObservable,
} from 'mobx';
import {
  assign, isArray, isEmpty, find, get, toSafeInteger,
} from 'lodash';
import {
  HEAD_MECHANIC,
} from 'Shared/constants/roles';
import TaskAgent from 'ApiAgents/Tasks/TaskAgent';
import EquipmentAgent from 'ApiAgents/Equipment/EquipmentAgent';
import { useTranslation } from 'react-i18next'; // eslint-disable-line

const taskAgent = new TaskAgent();
const equipmentAgent = new EquipmentAgent();


export class TaskListStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable totalPages = null;

  @observable isTaskListLoaded = false;

  @observable hasNextPage = null;

  @observable isLoadingByPage = false;

  @observable isLoading = false;

  @observable taskList = [];

  @observable pageIndex = 0;

  getGetTechObjects = async (items) => {
    const technicalObjectIds = [];
    items.forEach(({ rootTechnicalObjectIds = [] } = {}) => {
      if (isArray(rootTechnicalObjectIds) && !isEmpty(rootTechnicalObjectIds)) {
        rootTechnicalObjectIds.forEach((rootTechnicalObjectId) => {
          if (!technicalObjectIds.includes(rootTechnicalObjectId)) technicalObjectIds.push(rootTechnicalObjectId);
        });
      }
    });
    const taskListTechObjects = await equipmentAgent.getTechObjectsByIdsArray(technicalObjectIds);
    return taskListTechObjects;
  };

  @action loadTasksList = async (taskStatuses = [], pageNumber, pageSize, dateLimits) => {
    const isPaginating = !!pageNumber;
    this.pageIndex = pageNumber;
    this.rootStore.setLoadingFlag(
      isPaginating,
      this,
      'isLoadingByPage',
      'isLoading',
      true
    );

    const { filterState } = this.rootStore.coalTaskListFilterStore;
    const params = {
      taskType: 'COAL_REPAIR',
      taskStatuses,
      ...filterState,
    };
    if (dateLimits) assign(params, dateLimits);
    const { auth: { department }, role } = this.rootStore.userStore;
    const departmentId = get(department, 'id');
    if (role !== HEAD_MECHANIC && departmentId) {
      assign(params, { departmentId });
    }
    try {
      const {
        items = [], totalPages, hasNextPage, hasPreviousPage
      } = await taskAgent.loadList(
        params,
        isPaginating,
        pageNumber,
        pageSize
      );

      const taskListTechObjects = await this.getGetTechObjects(items);

      items.forEach((item = {}) => {
        const rootTechnicalObjectIds = get(item, 'rootTechnicalObjectIds');
        if (isArray(rootTechnicalObjectIds) && !isEmpty(rootTechnicalObjectIds)) {
          rootTechnicalObjectIds.forEach((rootTechnicalObjectId) => {
            const technicalObject = find(taskListTechObjects, { id: rootTechnicalObjectId });
            const technicalObjectTitle = get(technicalObject, 'title', '');
            assign(item, { technicalObjectTitle });
          });
        }
      });

      runInAction(() => {
        this.taskList = items;
        this.totalPages = totalPages;
        this.hasNextPage = hasNextPage;
        this.hasPreviousPage = hasPreviousPage;
        this.isTaskListLoaded = true;
      });
    } catch (error) {
      runInAction(() => {
        this.taskList = [];
        this.totalPages = 0;
        this.hasNextPage = false;
        this.hasPreviousPage = false;
        this.isTaskListLoaded = true;
      });
      console.log('ERROR in TASKS FETCHING: ', error);
      throw new Error(error);
    }

    runInAction(() => {
      this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', false);
    });
  };

  @action deleteTaskFromList = async (taskId) => {
    this.isDeleting = true;
    try {
      await taskAgent.disableTasksByIds([toSafeInteger(taskId)]);
      this.taskList = this.taskList.filter(({ taskId: coalTaskId }) => coalTaskId !== taskId);
    } catch (error) {
      const errorMessage = 'ERROR IN TASK DELETING';
      console.log(`${errorMessage}: `, error);
      throw new Error(errorMessage);
    }
    this.isDeleting = false;
  };
}

export default TaskListStore;
