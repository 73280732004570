import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { assign } from 'lodash';
import 'date-fns';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import ruLocale from 'date-fns/locale/ru';
import uaLocale from 'date-fns/locale/uk';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiFormHelperText-root': {
      display: ({ readOnly }) => (readOnly ? 'none' : 'block'),
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0
    },
  },
  withRequiredLabel: {
    '& .MuiFormHelperText-root': {
      marginTop: 16,
    },
  },
}));

const TextFieldComponent = props => <TextField {...props} margin="dense" />;

const DatePicker = ({
  onChange,
  label,
  value,
  minDate,
  maxDate,
  required,
  readOnly,
  error,
  disablePast,
  disableFuture,
  onError,
  minDateMessage,
  maxDateMessage,
  disablePastMessage,
  disableFutureMessage,
  ...restProps
}) => {
  const { t, i18n = {} } = useTranslation();
  const classes = useStyles({ error });
  const { language } = i18n;

  let locale = uaLocale;
  if (language === 'ru-RU') {
    locale = ruLocale;
  }

  const validationParams = {
    invalidDateMessage: t('INVALID_FORMAT'),
  };

  if (!readOnly && minDate) assign(validationParams, { minDate: new Date(minDate), minDateMessage: minDateMessage || t('MIN_DATE_VALIDATION_MESSAGE') });
  if (!readOnly && maxDate) assign(validationParams, { maxDate: new Date(maxDate), maxDateMessage: maxDateMessage || t('MAX_DATE_VALIDATION_MESSAGE') });
  if (!readOnly && disablePast) assign(validationParams, { disablePast: true, minDateMessage: disablePastMessage || t('DATE_CANNOT_BE_IN_PAST_TENSE') });
  if (!readOnly && disableFuture) assign(validationParams, { disableFuture: true, maxDateMessage: disableFutureMessage || t('DATE_CANNOT_BE_IN_FUTURE_TENSE') });

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
      <KeyboardDatePicker
        inputVariant="outlined"
        className={clsx(classes.root, required && classes.withRequiredLabel)}
        margin="dense"
        label={label}
        value={value}
        onChange={onChange}
        format="dd/MM/yyyy"
        TextFieldComponent={TextFieldComponent}
        autoOk
        {...validationParams}
        error={error}
        onError={onError}
        {...restProps}
      />
    </MuiPickersUtilsProvider>
  );
};

DatePicker.propTypes = {
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  error: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func,
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  maxDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  minDateMessage: PropTypes.string,
  maxDateMessage: PropTypes.string,
  disablePastMessage: PropTypes.string,
  disableFutureMessage: PropTypes.string,
};

DatePicker.defaultProps = {
  label: '',
  value: '',
  minDate: '',
  maxDate: '',
  required: false,
  readOnly: false,
  error: false,
  disablePast: false,
  disableFuture: false,
  onError: () => {},
  minDateMessage: '',
  maxDateMessage: '',
  disablePastMessage: '',
  disableFutureMessage: '',
};

export default DatePicker;
