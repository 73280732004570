import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  selectedSection: {
    height: `calc(100vh - ${theme.headerHeight * 2}px - 270px)`, // TODO REWRITE MAGIC NUMBERS
    overflowY: 'auto',
  },
}));

export default useStyles;
