import { action, runInAction, makeObservable } from 'mobx';

import PlannerTaskAgent from 'ApiAgents/Planner/PlannerTaskAgent';

const plannerTaskAgent = new PlannerTaskAgent();

const initialState = {
  plannerTasks: [],
  isLoading: false,
  isLoaded: false,
  totalPages: 0,
  hasNextPage: false,
  hasPreviousPage: false,
  pageIndex: 0,
};

export class PlannerTaskListStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
    this.rootStore.prepareState(initialState, this);
  }

  isLoading;

  isLoaded;

  plannerTasks;

  totalPages;

  hasNextPage;

  hasPreviousPage;

  pageIndex;

  @action loadPlannerTasks = async (pageNumber = 1, pageSize) => {
    const shouldPaginate = !!pageNumber;
    this.isLoading = true;
    this.isLoaded = false;
    this.pageIndex = pageNumber;
    try {
      const {
        items = [], totalPages = 0, hasNextPage = false, hasPreviousPage = false
      } = await plannerTaskAgent.getPlannerTasks(shouldPaginate, pageNumber, pageSize) || {};
      runInAction(() => {
        this.plannerTasks = items;
        this.isLoading = false;
        this.isLoaded = true;
        this.totalPages = totalPages;
        this.hasNextPage = hasNextPage;
        this.hasPreviousPage = hasPreviousPage;
      });
    } catch (error) {
      console.log('ERROR IN FETCHING PLANNER TASKS: ', error);
      runInAction(() => {
        this.isLoading = false;
        this.isLoaded = false;
        this.totalPages = 0;
        this.hasNextPage = false;
      });
    }
  };

  @action discardState = () => {
    this.rootStore.setInitialState(initialState, this);
  };
}

export default PlannerTaskListStore;
