import { makeStyles } from '@material-ui/core/styles';

export const defaultSwitchStyles = makeStyles(() => ({
  switchBase: {
    '&$checked': {
      '& + $track': {
        backgroundColor: '#ecf4fc',
        opacity: 1,
      },
      '& $circle': {
        backgroundColor: '#4499ee',
        color: '#4499ee',
        '&$active': {
          backgroundColor: '#e9f0f9',
        }
      },
      '&.Mui-disabled': {
        '& + $track': {
          opacity: 1,
          backgroundColor: '#cccccc'
        },
        '& $circle': {
          backgroundColor: '#f3f3f3',
        },
      }
    },
    '&.Mui-disabled': {
      '& + $track': {
        opacity: 1,
        backgroundColor: '#cccccc'
      },
      '& $circle': {
        backgroundColor: '#f3f3f3',
      },
    }
  },
  checked: {},
  colorSecondary: {},
  track: {
    opacity: 1,
    backgroundColor: '#767676'
  },
  circle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: '#f3f3f3',
    color: '#767676'
  },
  active: {
    backgroundColor: '#e9f0f9',
  },
}));

export default defaultSwitchStyles;
