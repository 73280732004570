import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import { get, upperFirst } from 'lodash';
import useStores from 'Store/useStores';
import { useTheme } from '@material-ui/core/styles';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import Panel, { PanelBox, PanelTitle } from 'Common/widgets/Layout/Panel/Panel';
import TextInput from 'Common/components/Forms/Input/Text';
import OptionRenderer from 'Common/components/Forms/OptionRenderer';
import { SHAREPOINT_SETTINGS, SETTINGS } from 'Shared/constants/routes';
import isUrl from 'Src/utils/urlCheck';
import { ADMINISTRATOR_OCP } from 'Shared/constants/roles';

import useStyles from './styles';


const minPhotosQuality = 1;
const maxPhotosQuality = 100;

const minAllowedPixels = 100;
const maxAllowedPixels = 2048;

const minPhotosCount = 1;
const maxPhotosCount = 10;

const maxLinkLength = 500;

const SharePointSettingsItem = ({ mode }) => {
  const { t } = useTranslation();
  const minAllowedPixelsError = `${t('ALLOWED_VALUE')} ${t('FROM')} ${minAllowedPixels} ${t('TO')} ${maxAllowedPixels}`;
  const photosQualityError = `${t('ALLOWED_VALUE')} ${t('FROM')} ${minPhotosQuality} ${t('TO')} ${maxPhotosQuality}`;
  const maxCountInputPlaceholder = `${upperFirst(t('FROM'))} ${minPhotosCount} ${t('TO')} ${maxPhotosCount}`;
  const minAllowedSizePlaceholder = `${upperFirst(t('FROM'))} ${minAllowedPixels} ${t('TO')} ${maxAllowedPixels}`;
  const photosQualityPlaceholder = `${upperFirst(t('FROM'))} ${minPhotosQuality} ${t('TO')} ${maxPhotosQuality}`;
  const linkPlaceholder = 'https://';
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const { id } = params;

  const {
    sharePointSettingsStore: {
      getSettingsByOsrId,
      discardState,
      isLoading,
      updateField,
      settingData = {},
      updateSetting,
    },
    userStore: { role },
    notificationStore: { enqueueSnackbar },
    validationStore: { validateRequiredFields, isValidFields, hasErrors },
  } = useStores();

  const [photosQualityErrorMessage, setPhotosQualityErrorMessage] = useState('');
  const [minAllowedSizeErrorMessage, setMinAllowedSizeErrorMessage] = useState('');

  const [defectsUrlLengthError, setDefectsUrlLengthError] = useState(false);
  const [isDefectsUrlValid, setIsDefectsUrlValid] = useState(true);
  const [isDefectsPhotosCountValid, setIsDefectsPhotosCountValid] = useState(true);

  const [operationUrlLengthError, setOperationUrlLengthError] = useState(false);
  const [isOperationPhotosCountValid, setIsOperationPhotosCountValid] = useState(true);
  const [isOperationUrlValid, setIsOperationUrlValid] = useState(true);

  const [accessUrlLengthError, setAccessUrlLengthError] = useState(false);
  const [isAccessPhotosCountValid, setIsAccessPhotosCountValid] = useState(true);
  const [isAccessUrlValid, setIsAccessUrlValid] = useState(true);

  const [documentsUrlLengthError, setDocumentsUrlLengthError] = useState(false);
  const [isDocumentsUrlValid, setIsDocumentsUrlValid] = useState(true);

  const {
    title,
    photosQuality,
    minAllowedSize,
    defectsMaxPhotosCount,
    defectsLibraryUrl,
    operationConfirmationMaxPhotosCount,
    operationConfirmationLibraryUrl,
    workplaceAccessMaxPhotosCount,
    workplaceAccessLibraryUrl,
    documentsUploadingLibraryUrl,
  } = settingData;

  const isEditingMode = mode === 'edit';

  const goToList = () => {
    history.push({
      pathname: SHAREPOINT_SETTINGS,
    });
  };

  const goToSettingsPage = () => {
    history.push({
      pathname: SETTINGS,
    });
  };

  const viewItem = () => {
    history.push({
      pathname: `${SHAREPOINT_SETTINGS}/view/${id}`,
    });
  };

  const editItem = () => {
    history.push({
      pathname: `${SHAREPOINT_SETTINGS}/edit/${id}`,
    });
  };

  const goBack = () => {
    if (role === ADMINISTRATOR_OCP) {
      goToSettingsPage();
    } else {
      goToList();
    }
  };

  const showDefaultErrorNotification = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('REQUEST_DEFAULT_ERROR'),
              },
            ],
          },
        ],
      },
      variant: 'error',
    });
  };

  const updateItem = async () => {
    validateRequiredFields();
    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('CANT_UPDATE'),
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    const { error } = await updateSetting(id) || {};
    if (error) {
      showDefaultErrorNotification();
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: `${t('CHANGES_SUCCESSFULLY_APPLIED')}!`,
              },
            ],
          },
        ],
      },
      variant: 'success',
    });
    if (role === ADMINISTRATOR_OCP) {
      viewItem();
      return;
    }
    goToList();
  };

  useEffect(() => {
    getSettingsByOsrId(id).catch(() => showDefaultErrorNotification());
    return () => discardState();
  }, []);

  return (
    <>
      {isLoading && <ProgressBar />}
      <Panel
        disableGoBack={isEditingMode}
        mode={mode}
        saveItem={updateItem}
        editItem={editItem}
        goBack={goBack}
        viewItem={viewItem}
        labelEdit={t('PHOTOS_PROCESSING_AND_UPLOADING')}
        labelView={t('PHOTOS_PROCESSING_AND_UPLOADING')}
        actions={['edit']}
        withActions
      >
        <PanelBox
          boxStyles={{
            padding: '16px 16px 0px 16px',
            maxWidth: 'inherit',
            borderBottom: `1px solid ${theme.palette.midGray}`
          }}
        >
          <PanelTitle>{t('SHAREPOINT_GENERAL_SETTINGS')}</PanelTitle>
          <div className={classes.generalParamsWrapper}>
            <div className={classes.photosQualityWrapper}>
              <TextInput
                isReadonly={!isEditingMode}
                placeholder={photosQualityPlaceholder}
                error={Boolean(photosQualityErrorMessage)}
                label={t('PHOTOS_QUALITY_SHORT')}
                value={photosQuality}
                name="photosQuality"
                InputEndAdornment={(
                  <div className={classes.endAdornmentLabel}>
                    {t('PERCENT_SYMBOL')}
                  </div>
                )}
                onBlur={() => {
                  if (!photosQuality) return;
                  const isValid = (photosQuality >= minPhotosQuality && photosQuality <= maxPhotosQuality);
                  if (!isValid) setPhotosQualityErrorMessage(photosQualityError);
                }}
                onChange={(value = '') => {
                  if (photosQualityErrorMessage) setPhotosQualityErrorMessage('');
                  const onlyDigitsValue = value.replace(/\D/g, '');
                  updateField('photosQuality', onlyDigitsValue);
                }}
                required
                requiredMessage={photosQualityErrorMessage || `${t('REQUIRED')}`}
                data-test="photosQuality"
                validationFunction={({ fieldName, validationState }) => {
                  const fieldValue = get(validationState, `${fieldName}.value`);
                  if (!fieldValue) {
                    setPhotosQualityErrorMessage('');
                    return false;
                  }
                  const isValid = (fieldValue >= minPhotosQuality && fieldValue <= maxPhotosQuality);
                  if (!isValid) setPhotosQualityErrorMessage(photosQualityError);
                  return isValid;
                }}
              />
            </div>
            <div className={classes.minAllowedSizeWrapper}>
              <TextInput
                isReadonly={!isEditingMode}
                placeholder={minAllowedSizePlaceholder}
                error={Boolean(minAllowedSizeErrorMessage)}
                label={t('MIN_ALLOWED_SIZE_SHORT')}
                value={minAllowedSize}
                name="minAllowedSize"
                InputEndAdornment={(
                  <div className={classes.endAdornmentLabel}>
                    {t('PIXELS')}
                  </div>
                )}
                onBlur={() => {
                  if (!minAllowedSize) return;
                  const isValid = (minAllowedSize >= minAllowedPixels && minAllowedSize <= maxAllowedPixels);
                  if (!isValid) setMinAllowedSizeErrorMessage(minAllowedPixelsError);
                }}
                onChange={(value = '') => {
                  if (minAllowedSizeErrorMessage) setMinAllowedSizeErrorMessage('');
                  const onlyDigitsValue = value.replace(/\D/g, '');
                  updateField('minAllowedSize', onlyDigitsValue);
                }}
                required
                requiredMessage={minAllowedSizeErrorMessage || `${t('REQUIRED')}`}
                data-test="minAllowedSize"
                validationFunction={({ fieldName, validationState }) => {
                  const fieldValue = get(validationState, `${fieldName}.value`);
                  if (!fieldValue) {
                    setMinAllowedSizeErrorMessage('');
                    return false;
                  }
                  const isValid = (fieldValue >= minAllowedPixels && fieldValue <= maxAllowedPixels);
                  if (!isValid) setMinAllowedSizeErrorMessage(minAllowedPixelsError);
                  return isValid;
                }}
              />
            </div>
            <div className={classes.osrNameWrapper}>
              <OptionRenderer
                value={title || t('INPUT_EMPTY_VALUE')}
                title={t('OSR_NAME')}
                name="osrName"
                data-test="osrName"
              />
            </div>
          </div>
        </PanelBox>
        <PanelBox boxStyles={{ padding: '16px 16px 16px 16px', maxWidth: 'inherit' }}>
          <PanelTitle>{t('SETTINGS_BY_PHOTO_TYPE')}</PanelTitle>
          <div className={classes.settingItem}>
            <div className={classes.settingItemTitle}>{t('DEFECT_FIXATION')}</div>
            <div className={classes.settingItemParamsWrapper}>
              <div className={classes.photosQuantityWrapper}>
                <TextInput
                  isReadonly={!isEditingMode}
                  placeholder={maxCountInputPlaceholder}
                  error={!isDefectsPhotosCountValid}
                  label={t('MAX_PHOTOS_COUNT')}
                  value={defectsMaxPhotosCount}
                  name="defectsPhotosCount"
                  InputEndAdornment={(
                    <div className={classes.endAdornmentLabel}>
                      {t('ITEMS_COUNT_LABEL_SHORT')}
                    </div>
                  )}
                  onChange={(value = '') => {
                    setIsDefectsPhotosCountValid(true);
                    const onlyDigitsValue = value.replace(/\D/g, '');
                    updateField('defectsMaxPhotosCount', onlyDigitsValue);
                  }}
                  onBlur={() => {
                    if (!defectsMaxPhotosCount) return;
                    const isFieldValid = defectsMaxPhotosCount >= minPhotosCount && defectsMaxPhotosCount <= maxPhotosCount;
                    if (!isFieldValid) setIsDefectsPhotosCountValid(false);
                  }}
                  data-test="defectsPhotosCount"
                  validationFunction={({ fieldName, validationState }) => {
                    const fieldValue = get(validationState, `${fieldName}.value`);
                    if (!fieldValue) {
                      setIsDefectsPhotosCountValid(true);
                      return true;
                    }
                    const isFieldValid = fieldValue >= minPhotosCount && fieldValue <= maxPhotosCount;
                    setIsDefectsPhotosCountValid(isFieldValid);
                    return isFieldValid;
                  }}
                  {...!isDefectsPhotosCountValid && { errorMessage: `${t('ALLOWED_VALUE')} ${t('FROM')} ${minPhotosCount} ${t('TO')} ${maxPhotosCount}` }}
                />
              </div>
              <div className={classes.linkWrapper}>
                <TextInput
                  isReadonly={!isEditingMode}
                  placeholder={linkPlaceholder}
                  maxLength={maxLinkLength}
                  counterHasError={defectsUrlLengthError}
                  label={t('LINK_TO_LIBRARY')}
                  value={defectsLibraryUrl}
                  name="defectsLibraryUrl"
                  error={!isDefectsUrlValid}
                  data-test="defectsLibraryUrl"
                  onChange={(value) => {
                    let urlValue = value;
                    if (value.length > maxLinkLength) {
                      setDefectsUrlLengthError(true);
                      urlValue = urlValue.slice(0, maxLinkLength);
                    } else if (defectsUrlLengthError) {
                      setDefectsUrlLengthError(false);
                    }
                    setIsDefectsUrlValid(true);
                    updateField('defectsLibraryUrl', urlValue);
                  }}
                  onBlur={() => {
                    if (defectsUrlLengthError) {
                      setDefectsUrlLengthError(false);
                    }
                    if (defectsLibraryUrl) setIsDefectsUrlValid(isUrl(defectsLibraryUrl));
                  }}
                  {...!isDefectsUrlValid && { errorMessage: t('URL_IS_NOT_VALID') }}
                  validationFunction={({ fieldName, validationState }) => {
                    const fieldValue = get(validationState, `${fieldName}.value`);
                    if (!fieldValue) {
                      setIsDefectsUrlValid(true);
                      return true;
                    }
                    const isValidUrl = isUrl(fieldValue);
                    setIsDefectsUrlValid(isValidUrl);
                    return isValidUrl;
                  }}
                />
              </div>
            </div>
          </div>
          <div className={classes.settingItem}>
            <div className={classes.settingItemTitle}>{t('WORK_CONFIRMATION')}</div>
            <div className={classes.settingItemParamsWrapper}>
              <div className={classes.photosQuantityWrapper}>
                <TextInput
                  isReadonly={!isEditingMode}
                  placeholder={maxCountInputPlaceholder}
                  error={!isOperationPhotosCountValid}
                  label={t('MAX_PHOTOS_COUNT')}
                  value={operationConfirmationMaxPhotosCount}
                  name="operationPhotosCount"
                  InputEndAdornment={(
                    <div className={classes.endAdornmentLabel}>
                      {t('ITEMS_COUNT_LABEL_SHORT')}
                    </div>
                  )}
                  onChange={(value = '') => {
                    setIsOperationPhotosCountValid(true);
                    const onlyDigitsValue = value.replace(/\D/g, '');
                    updateField('operationConfirmationMaxPhotosCount', onlyDigitsValue);
                  }}
                  onBlur={() => {
                    if (!operationConfirmationMaxPhotosCount) return;
                    const isFieldValid = operationConfirmationMaxPhotosCount >= minPhotosCount && operationConfirmationMaxPhotosCount <= maxPhotosCount;
                    if (!isFieldValid) setIsOperationPhotosCountValid(false);
                  }}
                  data-test="operationPhotosCount"
                  validationFunction={({ fieldName, validationState }) => {
                    const fieldValue = get(validationState, `${fieldName}.value`);
                    if (!fieldValue) {
                      setIsOperationPhotosCountValid(true);
                      return true;
                    }
                    const isFieldValid = fieldValue >= minPhotosCount && fieldValue <= maxPhotosCount;
                    setIsOperationPhotosCountValid(isFieldValid);
                    return isFieldValid;
                  }}
                  {...!isOperationPhotosCountValid && { errorMessage: `${t('ALLOWED_VALUE')} ${t('FROM')} ${minPhotosCount} ${t('TO')} ${maxPhotosCount}` }}
                />
              </div>
              <div className={classes.linkWrapper}>
                <TextInput
                  isReadonly={!isEditingMode}
                  placeholder={linkPlaceholder}
                  maxLength={maxLinkLength}
                  counterHasError={operationUrlLengthError}
                  label={t('LINK_TO_LIBRARY')}
                  value={operationConfirmationLibraryUrl}
                  name="operationUrl"
                  error={!isOperationUrlValid}
                  data-test="operationUrl"
                  onChange={(value) => {
                    let urlValue = value;
                    if (value.length > maxLinkLength) {
                      setOperationUrlLengthError(true);
                      urlValue = urlValue.slice(0, maxLinkLength);
                    } else if (operationUrlLengthError) {
                      setOperationUrlLengthError(false);
                    }
                    setIsOperationUrlValid(true);
                    updateField('operationConfirmationLibraryUrl', urlValue);
                  }}
                  onBlur={() => {
                    if (operationUrlLengthError) {
                      setOperationUrlLengthError(false);
                    }
                    if (!operationConfirmationLibraryUrl) return;
                    setIsOperationUrlValid(isUrl(operationConfirmationLibraryUrl));
                  }}
                  {...!isOperationUrlValid && { errorMessage: t('URL_IS_NOT_VALID') }}
                  validationFunction={({ fieldName, validationState }) => {
                    const fieldValue = get(validationState, `${fieldName}.value`);
                    if (!fieldValue) {
                      setIsOperationUrlValid(true);
                      return true;
                    }
                    const isValidUrl = isUrl(fieldValue);
                    setIsOperationUrlValid(isValidUrl);
                    return (isValidUrl);
                  }}
                />
              </div>
            </div>
          </div>
          <div className={classes.settingItem}>
            <div className={classes.settingItemTitle}>{t('OBTAINING_APPROVALS')}</div>
            <div className={classes.settingItemParamsWrapper}>
              <div className={classes.photosQuantityWrapper}>
                <TextInput
                  isReadonly={!isEditingMode}
                  placeholder={maxCountInputPlaceholder}
                  error={!isAccessPhotosCountValid}
                  label={t('MAX_PHOTOS_COUNT')}
                  value={workplaceAccessMaxPhotosCount}
                  name="accessPhotosCount"
                  InputEndAdornment={(
                    <div className={classes.endAdornmentLabel}>
                      {t('ITEMS_COUNT_LABEL_SHORT')}
                    </div>
                  )}
                  onChange={(value = '') => {
                    setIsAccessPhotosCountValid(true);
                    const onlyDigitsValue = value.replace(/\D/g, '');
                    updateField('workplaceAccessMaxPhotosCount', onlyDigitsValue);
                  }}
                  onBlur={() => {
                    if (!workplaceAccessMaxPhotosCount) return;
                    const isFieldValid = workplaceAccessMaxPhotosCount >= minPhotosCount && workplaceAccessMaxPhotosCount <= maxPhotosCount;
                    if (!isFieldValid) setIsAccessPhotosCountValid(false);
                  }}
                  data-test="accessPhotosCount"
                  validationFunction={({ fieldName, validationState }) => {
                    const fieldValue = get(validationState, `${fieldName}.value`);
                    if (!fieldValue) {
                      setIsAccessPhotosCountValid(true);
                      return true;
                    }
                    const isFieldValid = fieldValue >= minPhotosCount && fieldValue <= maxPhotosCount;
                    setIsAccessPhotosCountValid(isFieldValid);
                    return isFieldValid;
                  }}
                  {...!isAccessPhotosCountValid && { errorMessage: `${t('ALLOWED_VALUE')} ${t('FROM')} ${minPhotosCount} ${t('TO')} ${maxPhotosCount}` }}
                />
              </div>
              <div className={classes.linkWrapper}>
                <TextInput
                  isReadonly={!isEditingMode}
                  placeholder={linkPlaceholder}
                  maxLength={maxLinkLength}
                  counterHasError={accessUrlLengthError}
                  label={t('LINK_TO_LIBRARY')}
                  value={workplaceAccessLibraryUrl}
                  name="accessUrl"
                  error={!isAccessUrlValid}
                  data-test="accessUrl"
                  onChange={(value) => {
                    let urlValue = value;
                    if (value.length > maxLinkLength) {
                      setAccessUrlLengthError(true);
                      urlValue = urlValue.slice(0, maxLinkLength);
                    } else if (accessUrlLengthError) {
                      setAccessUrlLengthError(false);
                    }
                    setIsAccessUrlValid(true);
                    updateField('workplaceAccessLibraryUrl', urlValue);
                  }}
                  onBlur={() => {
                    if (accessUrlLengthError) {
                      setAccessUrlLengthError(false);
                    }
                    if (workplaceAccessLibraryUrl) setIsAccessUrlValid(isUrl(workplaceAccessLibraryUrl));
                  }}
                  {...!isAccessUrlValid && { errorMessage: t('URL_IS_NOT_VALID') }}
                  validationFunction={({ fieldName, validationState }) => {
                    const fieldValue = get(validationState, `${fieldName}.value`);
                    if (!fieldValue) {
                      setIsAccessUrlValid(true);
                      return true;
                    }
                    const isValidUrl = isUrl(fieldValue);
                    setIsAccessUrlValid(isValidUrl);
                    return isValidUrl;
                  }}
                />
              </div>
            </div>
          </div>
          <div className={classes.settingItem}>
            <div className={classes.settingItemTitle}>{t('UPLOADING_DOCUMENTS')}</div>
            <div className={classes.settingItemParamsWrapper}>
              <div className={classes.docLinkWrapper}>
                <TextInput
                  // required
                  isReadonly={!isEditingMode}
                  placeholder={linkPlaceholder}
                  maxLength={maxLinkLength}
                  counterHasError={documentsUrlLengthError}
                  label={t('LINK_TO_LIBRARY')}
                  value={documentsUploadingLibraryUrl}
                  name="documentsUrl"
                  error={!isDocumentsUrlValid}
                  data-test="documentsUrl"
                  onChange={(value) => {
                    let urlValue = value;
                    if (value.length > maxLinkLength) {
                      setDocumentsUrlLengthError(true);
                      urlValue = urlValue.slice(0, maxLinkLength);
                    } else if (documentsUrlLengthError) {
                      setDocumentsUrlLengthError(false);
                    }
                    setIsDocumentsUrlValid(true);
                    updateField('documentsUploadingLibraryUrl', urlValue);
                  }}
                  onBlur={() => {
                    if (documentsUrlLengthError) {
                      setDocumentsUrlLengthError(false);
                    }
                    if (documentsUploadingLibraryUrl) setIsDocumentsUrlValid(isUrl(documentsUploadingLibraryUrl));
                  }}
                  {...!isDocumentsUrlValid && { errorMessage: t('URL_IS_NOT_VALID') }}
                  validationFunction={({ fieldName, validationState }) => {
                    const fieldValue = get(validationState, `${fieldName}.value`);
                    if (!fieldValue) {
                      setIsDocumentsUrlValid(true);
                      return true;
                    }
                    const isValidUrl = isUrl(fieldValue);
                    setIsDocumentsUrlValid(isValidUrl);
                    return isValidUrl;
                  }}
                />
              </div>
            </div>
          </div>
        </PanelBox>
      </Panel>
    </>
  );
};

SharePointSettingsItem.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default observer(SharePointSettingsItem);
