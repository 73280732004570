import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  layout: {
    marginBottom: theme.spacing(2),
  },
  boxWrapper: {
    borderBottom: '1px solid #f3f3f3'
  },
  box: {
    maxWidth: 500,
    padding: '14px 5px',
    margin: '0 auto',
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    margin: '35px 25px 25px',
    color: '#212121',
  },
}));

export default useStyles;
