import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Arrow } from 'Src/assets/arrow-with-scribble-left.svg';

const useStyles = makeStyles(theme => ({
  promptContent: {
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingTop: 195,
  },
  promptSectionIcon: {
    position: 'absolute',
    bottom: '100%',
    right: '100%',
    color: theme.palette.black30,
  },
  title: {
    width: 'calc(100% - 86px)',
    marginLeft: 'auto',
    fontSize: 16,
    lineHeight: 1.5,
    position: 'relative',
    color: ({ error }) => (error ? theme.palette.negative : theme.palette.black30),
  },
}));

const PromptSection = ({ title, error }) => {
  const classes = useStyles({ error });

  return (
    <div className={classes.promptContent}>
      <div className={classes.title}>
        {title}
        <Arrow className={classes.promptSectionIcon} />
      </div>
    </div>
  );
};
PromptSection.defaultProps = {
  error: false,
};

PromptSection.propTypes = {
  title: PropTypes.string.isRequired,
  error: PropTypes.bool,
};

export default PromptSection;
