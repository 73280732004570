import React from 'react';

import { formatDate } from 'Src/utils/datetime';
import PlannerTaskExecutionResult from 'Business/tpsInspections/components/PlannerTaskExecutionResult';
import PlannerTaskStatus from 'Business/tpsInspections/components/PlannerTaskStatus';

const getDuration = (executionType, startDate, finishDate, t) => {
  if (executionType === 'Indefinite') {
    return `${formatDate(startDate)} - ${t('INDEFINITE')}`;
  }
  if (executionType === 'Period') {
    return `${formatDate(startDate)} - ${formatDate(finishDate)}`;
  }
  if (executionType === 'OneTime') {
    return `${formatDate(startDate)}`;
  }
  return null;
};

export default t => ([
  {
    field: 'title',
    title: t('NOMINATION'),
    withEllipsis: true,
    mainLink: true,
    cellStyle: {
      whiteSpace: 'normal',
      overflowWrap: 'anywhere',
      width: '35%'
    },
    headerStyle: {
      whiteSpace: 'normal',
      width: '35%'
    },
  },
  {
    field: 'period',
    title: t('DURATION'),
    // withEllipsis: true,
    cellStyle: {
      whiteSpace: 'normal',
      overflowWrap: 'anywhere',
      width: '20%'
    },
    headerStyle: {
      whiteSpace: 'normal',
      width: '20%'
    },
    render: ({ executionType, startDate, finishDate }) => getDuration(executionType, startDate, finishDate, t)
  },
  {
    field: 'executionResult',
    title: t('LAST_EXECUTION_RESULT'),
    cellStyle: {
      whiteSpace: 'normal',
      overflowWrap: 'anywhere',
      width: '15%'
    },
    headerStyle: {
      whiteSpace: 'normal',
      width: '15%'
    },
    // eslint-disable-next-line react/prop-types
    render: ({ executionResult }) => <PlannerTaskExecutionResult executionResult={executionResult} />
  },
  {
    field: 'status',
    title: t('TASK_STATE'),
    width: '15%',
    cellStyle: {
      whiteSpace: 'normal',
      overflowWrap: 'anywhere',
      width: '15%',
    },
    headerStyle: {
      whiteSpace: 'normal',
      width: '15%'
    },
    // eslint-disable-next-line react/prop-types
    render: ({ status }) => <PlannerTaskStatus status={status} />
  },
]);
