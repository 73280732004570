import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import isEmpty from 'lodash/isEmpty';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import HeaderLayout from 'Common/widgets/Layout/Header/Header';
import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import Breadcrumbs from 'Business/tpsInspections/components/Breadcrumbs';
import Breadcrumb from 'Business/tpsInspections/components/Breadcrumb';
import Subheader from 'Business/tpsInspections/components/Subheader';
import SectionList from 'Common/widgets/Section/SectionList';
import Section from 'Common/widgets/Section/Section';
import SectionListItem from 'Common/widgets/Section/SectionListItem';
import History from 'Business/tpsInspections/components/History';
import HistoryItem from 'Business/tpsInspections/components/HistoryItem';
import PromptSection from 'Business/tpsInspections/components/PromptSection';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import useStores from 'Store/useStores';
import sectionTypes, { TECHNICAL_PLACE, ACTION, OPERATION } from 'Business/tpsInspections/config/sectionTypeMapping';

const OperationSelectOverlay = observer(({ isOpen, handleClose }) => {
  const {
    equipmentStore: {
      techObjectsForOperationSelect, operations, isLoadingOperations, isLoadedOperations, tpsName,
    },
    checkpointStore: { selectedPowerUnitName, selectedTechPlaceName },
    checkpointActionsStore: {
      history, visibleSections, handleListItemClick, isCurrentlySelected, handleToggleAction,
      selectedActions, selectedOperation, selectActions, sectionTypeToSelect, isCheckedAction, handleOperationActionClick,
      isTechObjectInSelectedState, handleHistoryClick, isOperationInSelectedState, handleTechObjectActionClick,
      setConfirmedOperations, noContentForOverlay
    }
  } = useStores();
  const { t } = useTranslation();
  const theme = useTheme();
  const shouldShowHistory = history.length > 2;
  const firstLevelObjectsToChoose = techObjectsForOperationSelect.children;
  const sectionHeight = `calc(100vh - ${theme.pageHeaderHeight}px - ${theme.sectionHeaderHeight}px - ${theme.breadcrumbHeight}px)`;
  const getPromptText = () => `${t('SELECT')} ${t(sectionTypes[sectionTypeToSelect])}`;

  const getBreadcrumbs = () => [
    { title: t('THERMAL_POWER_PLANT'), value: tpsName },
    { title: t('POWER_UNIT'), value: selectedPowerUnitName },
    { title: `${t('EQUIPMENT_LEVEL')} 1`, value: selectedTechPlaceName },
  ];

  const handleConfirm = () => {
    setConfirmedOperations();
    handleClose();
  };

  const getFirstLevelObjectsToChooseType = () => (
    !isEmpty(techObjectsForOperationSelect)
      ? techObjectsForOperationSelect.children[0].technicalObjectType
      : ''
  );

  if (noContentForOverlay()) return;

  const renderHistory = () => (
    shouldShowHistory && (
      <Section title={t('PREVIOUS_ACTIONS')}>
        <History>
          {history.map(({
            title, id, parentId, isRoot
          }) => (
            <HistoryItem
              id={id}
              key={title}
              title={title}
              onItemClick={handleHistoryClick(id, parentId, title, isRoot)}
            />
          ))}
        </History>
      </Section>
    )
  );

  return (
    <Dialog fullScreen open={isOpen} data-test="operationSelectDialog">
      <Grid
        container
        direction="column"
        wrap="nowrap"
        style={{ height: '100%' }}
      >
        {(isLoadingOperations && !isLoadedOperations) && <ProgressBar />}
        <HeaderLayout title={t('EQUIPMENT_ADDING')}>
          <HeaderButton type="close" handleClick={handleClose} />
          <HeaderButton type="save" handleClick={handleConfirm} disabled={false} />
        </HeaderLayout>
        <Subheader>
          <Breadcrumbs>
            {getBreadcrumbs().map(({ value, title } = {}) => <Breadcrumb key={title} title={title} value={value} />)}
          </Breadcrumbs>
        </Subheader>
        <SectionList>
          {renderHistory()}
          {(!shouldShowHistory && !isEmpty(techObjectsForOperationSelect)) && (
            //Render root TechObjects
            <Section title={t(sectionTypes[getFirstLevelObjectsToChooseType()])} maxHeight={sectionHeight}>
              {firstLevelObjectsToChoose.map(({ technicalObjectId: id, title, parentId }) => (
                <SectionListItem
                  key={id}
                  id={id}
                  actionFunc={handleTechObjectActionClick(id)}
                  onItemClick={handleListItemClick(id, title, parentId, TECHNICAL_PLACE, true)}
                  title={title}
                  isCurrentlySelected={isCurrentlySelected(id)}
                  isTreePassed={isTechObjectInSelectedState(id)}
                />
              ))}
            </Section>
          )}
          {/*Render visible sections*/}
          {visibleSections.map(({ items = [], type, parentId }) => {
            if (type === ACTION) {
              if (selectedActions.length > 0) {
                return (
                  <Section title={t(sectionTypes[ACTION])} gridWidth={7} key={type} maxHeight={sectionHeight}>
                    {selectedActions.map(({ title, id }) => (
                      <SectionListItem
                        variant="check"
                        checked={isCheckedAction(id)}
                        key={id}
                        id={id}
                        actionFunc={() => {}}
                        onItemClick={handleToggleAction(id, title)}
                        title={title}
                        noNextIcon
                      />
                    ))}
                  </Section>
                );
              }
              return;
            }

            if (type === OPERATION) {
              return (
                <Section title={t(sectionTypes[OPERATION])} gridWidth={2} key={type} maxHeight={sectionHeight}>
                  {operations.map(({ title, type: operationType }) => (
                    <SectionListItem
                      key={title}
                      actionFunc={handleOperationActionClick(operationType)}
                      onItemClick={() => selectActions(operationType)}
                      title={t(title)}
                      isCurrentlySelected={operationType === selectedOperation.type}
                      isTreePassed={isOperationInSelectedState(operationType)}
                    />
                  ))}
                </Section>
              );
            }

            return (
              <Section title={t(sectionTypes[type])} key={parentId} maxHeight={sectionHeight}>
                {items.map(({
                  title, id, parentId: techObjectParentId, type: techObjectType
                }) => (
                  <SectionListItem
                    key={id}
                    id={id}
                    actionFunc={handleTechObjectActionClick(id)}
                    onItemClick={handleListItemClick(id, title, techObjectParentId, techObjectType)}
                    title={title}
                    isCurrentlySelected={isCurrentlySelected(id)}
                    isTreePassed={isTechObjectInSelectedState(id)}
                  />
                ))}
              </Section>
            );
          })}
          {selectedActions.length === 0
          && (
            <PromptSection title={getPromptText()} />
          )}
        </SectionList>
      </Grid>
    </Dialog>
  );
});

OperationSelectOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default OperationSelectOverlay;
