import EmployeeListItem from 'Src/business/tpsRepairs/components/Employee/EmployeeListItem';
import SectionTitle from 'Common/components/Forms/Title/components/SectionTitle';
import TextInput from 'Common/components/Forms/Input/Text';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: 24
  },
  wrapper: {
    width: 488
  },
}));

const BrigadeDetailsWidget = ({ brigade }) => {
  const { users, brigadier, name } = brigade;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <SectionTitle text={t('CREATED_BRIGADE')} />
        <TextInput
          name="brigade-name"
          label={t('BRIGADE_TITLE')}
          value={name}
          data-test="brigade-name"
          isReadonly
        />
        <SectionTitle text={t('PRODUCER')} isSubtitle data-test="brigadier-section-title" />
        <EmployeeListItem employee={brigadier} noActions data-test="brigadier-name" />
        <SectionTitle text={t('BRIGADE_STAFF')} isSubtitle data-test="brigade-stuff-section-title" />
        {users.map(employee => (
          <EmployeeListItem
            employee={employee}
            key={employee.id}
            data-test="brigade-stuff-item-name"
            noActions
          />
        ))}
      </div>
    </div>
  );
};

BrigadeDetailsWidget.propTypes = {
  brigade: PropTypes.shape({
    users: PropTypes.array.isRequired, // eslint-disable-line
    brigadier: PropTypes.object.isRequired, // eslint-disable-line
    name: PropTypes.string.isRequired
  }).isRequired
};

export default BrigadeDetailsWidget;
