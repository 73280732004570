import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import useStyles from './styles';

const TabPanel = (props) => {
  const {
    children, tab, alias, panelMaxHeight, ...rest
  } = props;
  const classes = useStyles({ panelMaxHeight });
  if (tab !== alias) return null;
  return (
    <Box
      className={classes.root}
      component="div"
      role="tabpanel"
      id={`scrollable-auto-tabpanel-${alias}`}
      aria-labelledby={`scrollable-auto-tab-${alias}`}
      {...rest}
    >
      {children}
    </Box>
  );
};

TabPanel.defaultProps = {
  panelMaxHeight: undefined,
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  alias: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  panelMaxHeight: PropTypes.number,
};
export default TabPanel;
