import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import {
  Route, Switch, Redirect, useHistory
} from 'react-router-dom';
import PrivateRoute from 'Common/components/Authorization/PrivateRoute';
import useStores from 'Store/useStores';
import {
  NO_ACCESS, HOME, FAQ, NOT_FOUND, BLOCKED_USER
} from 'Shared/constants/routes';
import * as routes from 'Shared/constants/routes';
import NoAccess from 'Src/common/components/NoAccess';
import NotFound from 'Src/common/components/NotFound';
import BlockedUser from 'Src/common/components/BlockedUser';
import FAQPage from 'Src/common/components/FAQ';
import rules from 'Src/RBAC/rbacRules';
import rulesDev from 'Src/RBAC/rbacRulesDEV';
import drawerConfig from 'Shared/drawerConfig';
import { SnackbarProvider } from 'notistack';
import Notifier from 'Src/common/components/Notification/Notifier';
import NotificationBanner from 'Src/common/components/Notification/NotificationBanner';
import {
  BLOCKED,
} from 'Shared/constants/userStatuses';
import Users from 'Src/common/pages/Users';
import User from 'Src/common/pages/User';

import {
  USERS_VIEW,
  USER_CREATE,
  USER_VIEW,
  USER_EDIT,
} from 'Src/RBAC/businessPermissions';

import useStyles from 'Src/styles';


const CommonRoutes = ({ children }) => {
  const classes = useStyles();
  const {
    userStore: {
      role, businessType, environment, status
    },
    validationStore: {
      discardState,
    },
  } = useStores();

  const history = useHistory();

  const isUserBlocked = status === BLOCKED;

  useEffect(() => {
    history.listen((location) => {
      discardState();
      try {
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
      } catch (error) {
        console.log('REACT GA ERROR', error);
      }
    });
  }, []);

  const getInitialRoute = () => {
    const runTimeRules = (environment === 'prod' || environment === 'beta') ? rules : rulesDev;
    const permissions = businessType && get(runTimeRules, `${businessType}.${role}`, []);
    const [initialDrawerElement = {}] = drawerConfig(() => {}).filter(({ permission: drawerElementPermission }) => (permissions.some(permission => permission === drawerElementPermission)));
    const { url: initialRoute = NO_ACCESS } = initialDrawerElement;
    return initialRoute;
  };

  if (isUserBlocked) {
    return (
      <Switch>
        <Route path={BLOCKED_USER} component={() => <BlockedUser businessType={businessType} />} />
        <Redirect to={BLOCKED_USER} />
      </Switch>
    );
  }

  return (
    <SnackbarProvider
      content={(key, message) => <NotificationBanner id={key} message={message} />}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      preventDuplicate
      maxSnack={4}
      hideIconVariant
      classes={{
        containerRoot: classes.snackbarRoot,
      }}
    >
      <Notifier />
      <Switch>
        <Route exact path={`${HOME}`}>
          <Redirect to={getInitialRoute()} />
        </Route>
        <Route path={NO_ACCESS} component={() => <NoAccess businessType={businessType} />} />
        <Route path={NOT_FOUND} component={NotFound} />
        <Route path={FAQ} component={() => <FAQPage businessType={businessType} />} />
        <PrivateRoute
          exact
          path={`${routes.CREATE_USER}/:id`}
          component={props => <User {...props} mode="create" />}
          permission={USER_CREATE}
        />
        <PrivateRoute
          exact
          path={`${routes.VIEW_USER}/:id`}
          component={props => <User {...props} mode="view" />}
          permission={USER_VIEW}
        />
        <PrivateRoute
          exact
          path={`${routes.EDIT_USER}/:id`}
          component={props => <User {...props} mode="edit" />}
          permission={USER_EDIT}
        />
        <PrivateRoute path={routes.USERS} component={Users} permission={USERS_VIEW} />
        {children}
        <Redirect to={NOT_FOUND} />
      </Switch>
    </SnackbarProvider>
  );
};

CommonRoutes.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default observer(CommonRoutes);
