import {
  action, observable, makeObservable, computed
} from 'mobx';

import { UserStore } from 'Store/UserStore';
import { NotificationStore } from 'Store/NotificationStore';
import { ValidationStore } from 'Store/ValidationStore';
import DictionaryStore from 'Store/DictionaryStore';
import DrawerStore from 'Store/DrawerStore';

import { CheckpointStoreNEW } from 'Business/tpsInspections/store/CheckpointStoreNEW';
import { CheckpointStore } from 'Business/tpsInspections/store/CheckpointStore';
import { CheckpointActionsStore } from 'Business/tpsInspections/store/CheckpointActionsStore';
import { TaskRouteStore } from 'Business/tpsInspections/store/TaskRouteStore';
import { TaskRouteManageStore } from 'Business/tpsInspections/store/TaskRouteManageStore';
import InspectionTaskStore from 'Business/tpsInspections/store/InspectionTaskStore';
import { EquipmentStore } from 'Business/tpsInspections/store/EquipmentStore';
import TpsInspectCreatedTaskListStore from 'Business/tpsInspections/store/CreatedTaskListStore';
import { TaskListFilterStore as TpsInspectTaskListFilterStore } from 'Business/tpsInspections/store/TaskListFilterStore';
import { CreatedTaskViewStore as TpsCreatedTaskViewStore } from 'Business/tpsInspections/store/CreatedTaskViewStore';
import { MeasurementReportListStore } from 'Business/tpsInspections/store/MeasurementReportListStore';
import { RequestReportStore as TpsRequestReportStore } from 'Business/tpsInspections/store/RequestReportStore';
import { ReportStore as TpsReportStore } from 'Business/tpsInspections/store/ReportStore';
import { InspectionReportsStore } from 'Business/tpsInspections/store/InspectionReportsStore';

import { TaskStore as CoalTaskStore } from 'Src/business/coal/store/TaskStore';
import { TaskListFilterStore as CoalTaskListFilterStore } from 'Business/coal/store/TaskListFilterStore';
import { TaskListStore as CoalTaskListStore } from 'Business/coal/store/TaskListStore';
import { CoalRouteStore } from 'Business/coal/store/CoalRouteStore';
import { CoalRoutesListStore } from 'Business/coal/store/CoalRoutesListStore';
import { CoalInspectionsListStore } from 'Business/coal/store/InspectionsListStore';
import { CoalInspectionStore } from 'Business/coal/store/InspectionStore';
import { DefectsListStore as CoalDefectsListStore } from 'Business/coal/store/DefectsListStore';
import { DefectStore as CoalDefectStore } from 'Business/coal/store/DefectStore';

import TaskReportStore from 'Business/tpsRepairs/store/TaskReportStore';
import { BrigadeStore } from 'Business/tpsRepairs/store/BrigadeStore';
import { TasksStore } from 'Business/tpsRepairs/store/TaskStore';
import { MaterialValuesControlStore } from 'Business/tpsRepairs/store/MaterialValuesControlStore';

import VolumeStore from 'Business/plant/store/VolumeStore';
import UsersListStore from 'Store/Users/UsersListStore';
import UsersListFilterStore from 'Store/Users/UsersListFilterStore';
import AdminUserStore from 'Store/Users/AdminUserStore';
import DefectsDictionaryStore from 'Business/plant/store/DefectsDictionaryStore';
import ParallelOperationsStore from 'Business/plant/store/ParallelOperationsStore';
import PresenceControlStore from 'Business/plant/store/PresenceControlStore';
import { PauseReasonsDictionaryStore } from 'Business/plant/store/PauseReasonsDictionaryStore';
import { OperationKeysSettingsStore } from 'Business/plant/store/OperationKeysSettingsStore';
import { WorkplacesListStore } from 'Business/plant/store/WorkplacesListStore';
import { WorkplacesItemStore } from 'Business/plant/store/WorkplacesItemStore';
import { SharePointSettingsStore } from 'Business/plant/store/SharePointSettingsStore';

import { PlannerTaskStore } from 'Business/tpsInspections/store/PlannerTaskStore';
import { PlannerTaskListStore } from 'Business/tpsInspections/store/PlannerTaskListStore';

import { ORG_UNIT_SAP_CODE } from 'Shared/constants/dictionaries';

class RootStore {
  constructor() {
    this.userStore = new UserStore();
    this.notificationStore = new NotificationStore();
    this.tasksStore = new TasksStore(this);
    this.equipmentStore = new EquipmentStore(this);
    this.brigadeStore = new BrigadeStore(this);
    this.checkpointStore = new CheckpointStore(this);
    this.checkpointStoreNEW = new CheckpointStoreNEW(this);
    this.taskRouteStore = new TaskRouteStore(this);
    this.taskRouteManageStore = new TaskRouteManageStore(this);
    this.checkpointActionsStore = new CheckpointActionsStore(this);
    this.inspectionTaskStore = new InspectionTaskStore(this);
    this.coalTaskStore = new CoalTaskStore(this);
    this.coalTaskListFilterStore = new CoalTaskListFilterStore(this);
    this.coalTaskListStore = new CoalTaskListStore(this);
    this.coalDefectsListStore = new CoalDefectsListStore(this);
    this.coalDefectStore = new CoalDefectStore(this);
    this.coalRouteStore = new CoalRouteStore(this);
    this.coalRoutesListStore = new CoalRoutesListStore(this);
    this.taskReportStore = new TaskReportStore(this);
    this.dictionaryStore = new DictionaryStore(this);
    this.tpsInspectCreatedTaskListStore = new TpsInspectCreatedTaskListStore(this);
    this.tpsInspectTaskListFilterStore = new TpsInspectTaskListFilterStore(this);
    this.tpsCreatedTaskViewStore = new TpsCreatedTaskViewStore(this);
    this.measurementReportListStore = new MeasurementReportListStore(this);
    this.tpsRequestReportStore = new TpsRequestReportStore(this);
    this.tpsReportStore = new TpsReportStore(this);
    this.volumeStore = new VolumeStore(this);
    this.usersListStore = new UsersListStore(this);
    this.usersListFilterStore = new UsersListFilterStore(this);
    this.adminUserStore = new AdminUserStore(this);
    this.drawerStore = new DrawerStore(this);
    this.materialValuesControlStore = new MaterialValuesControlStore(this);
    this.validationStore = new ValidationStore(this);
    this.coalInspectionsListStore = new CoalInspectionsListStore(this);
    this.coalInspectionStore = new CoalInspectionStore(this);
    this.defectsDictionaryStore = new DefectsDictionaryStore(this);
    this.parallelOperationsStore = new ParallelOperationsStore(this);
    this.presenceControlStore = new PresenceControlStore(this);
    this.pauseReasonsDictionaryStore = new PauseReasonsDictionaryStore(this);
    this.operationKeysSettingsStore = new OperationKeysSettingsStore(this);
    this.workplacesListStore = new WorkplacesListStore(this);
    this.workplacesItemStore = new WorkplacesItemStore(this);
    this.inspectionReportsStore = new InspectionReportsStore(this);
    this.sharePointSettingsStore = new SharePointSettingsStore(this);
    this.plannerTaskStore = new PlannerTaskStore(this);
    this.plannerTaskListStore = new PlannerTaskListStore(this);

    makeObservable(this);
  }

  @observable isModalOpen = false;

  /* eslint-disable no-param-reassign */
  @action setLoadingFlag = (isPaginating, self, isLoadingByPage, isLoading, value) => {
    if (isPaginating) {
      self[isLoadingByPage] = value;
    } else {
      self[isLoading] = value;
    }
  };

  @action setModalState = (isOpen) => {
    this.isModalOpen = isOpen;
  };

  @action setInitialState(initialState, self) {
    Object.keys(initialState).forEach((key) => { self[key] = initialState[key]; });
  }

  @action prepareState(initialState, self) {
    Object.keys(initialState).forEach((key) => {
      makeObservable(self, { [key]: observable });
    });

    this.setInitialState(initialState, self);
  }
  /* eslint-enable no-param-reassign */

  @computed get rootOrgunitName() {
    const orgUnits = this.dictionaryStore.loadedDictionaries[ORG_UNIT_SAP_CODE];
    const { code } = this.userStore.rootOrgUnit;
    if (!orgUnits || !code) return '';

    return orgUnits[code];
  }

  @computed get rootOrgUnitCode() {
    const { rootOrgUnit: { code } = {} } = this.userStore;

    return code || '';
  }
}

export default new RootStore();
