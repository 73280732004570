import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  labelWithIconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  tasksTypeIcon: {
    marginRight: 4,
  },
  readOnlyLabel: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: theme.palette.black,
  },
}));

export default useStyles;
