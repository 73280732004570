export const UNKNOWN = 'UNKNOWN';
export const TODO = 'TODO';
export const IN_PROGRESS = 'IN_PROGRESS';
export const ON_HOLD = 'ON_HOLD';
export const DONE = 'DONE';
export const PARTIALLY_DONE = 'PARTIALLY_DONE';
export const REJECTED = 'REJECTED';


export const operationStatusesTranslationKeys = {
  [UNKNOWN]: 'UNKNOWN',
  [TODO]: 'SCHEDULED',
  [IN_PROGRESS]: 'IN_PROGRESS',
  [ON_HOLD]: 'PAUSE',
  [DONE]: 'COMPLETED_ENTITY',
  [PARTIALLY_DONE]: 'PARTIALLY_DONE',
  [REJECTED]: 'REJECTED_ENTITY'
};

export default operationStatusesTranslationKeys;
