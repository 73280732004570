export default t => ([
  {
    field: 'title',
    title: t('NAME'),
    width: '70%',
    mainLink: true,
    cellStyle: {
      whiteSpace: 'normal',
      width: '70%',
    },
    headerStyle: {
      whiteSpace: 'normal',
      width: '70%'
    },
  },
  {
    field: 'technicalObjectTitle',
    title: t('POWER_UNIT'),
    withEllipsis: true,
    width: '15%',
    cellStyle: {
      whiteSpace: 'normal',
      width: '15%',
    },
    headerStyle: {
      whiteSpace: 'normal',
      width: '15%'
    },
  },
  {
    field: 'id',
    title: t('NUMBER'),
    width: '15%',
    cellStyle: {
      whiteSpace: 'normal',
      overflowWrap: 'anywhere',
      width: '15%',
    },
    headerStyle: {
      whiteSpace: 'normal',
      width: '15%'
    },
  },
]);
