import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  sectionHeader: {
    flex: 1,
  },
  quantityInput: {
    width: 84,
    margin: 0,
    '& .MuiInputBase-root': {
      height: 24,
      width: 84,
      fontSize: 14,
      '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
  },
  factQuantityBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  factQuantityLabel: {
    marginRight: 8,
  },
  negativeColor: {
    '& $quantityInput': {
      '& .MuiInputBase-root': {
        '& fieldset': {
          borderColor: theme.palette.negative,
        },
      },
    },
  },
  unitLabel: {
    color: theme.palette.black30,
    width: 24,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  operationsLayoutWrapper: {
    overflowY: 'auto',
  },
  operationViewSubTitle: {
    display: 'flex', justifyContent: 'space-between',
  },
}));

export default useStyles;
