import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import CopyToClipboard from 'Common/components/Forms/Button/CopyToClipboard/CopyToClipboard';
import { observer } from 'mobx-react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { isEmpty, get } from 'lodash';
import {
  Box,
  Typography
} from '@material-ui/core/';
import useStores from 'Store/useStores';
import * as routes from 'Shared/constants/routes';

import Panel, { PanelBox, PanelTitle } from 'Common/widgets/Layout/Panel/Panel';
import Input from 'Common/components/Forms/Input/Input';
import TextInput from 'Common/components/Forms/Input/Text';
import EquipmentBox from 'Business/tpsInspections/components/EquipmentBox';
import OperationSelectOverlay from 'Business/tpsInspections/components/OperationSelectOverlay';
import AddEquipmentButton from 'Business/tpsInspections/components/AddEquipmentButton';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import useManageEntityModal from 'Common/components/Modal/ManageEntityModal/useManageEntityModal';
import DottedLoader from 'Common/components/Progress/components/Dotted/DottedLoader';
import { getCheckpointAlreadyExistsMessage } from 'Shared/constants/responseErrors/errors';

import useStyles from './styles';

const CheckpointDetails = observer(({
  id: viewerId,
  onClose
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { id, action: mode = 'view' } = viewerId ? {
    id: viewerId,
    action: 'view'
  } : useParams();

  const {
    checkpointStore: {
      isLoading,
      isDeleting,
      powerUnits,
      firstLevelTechPlacesOfSelectedPowerUnit,
      isTechPlacesLading,
      loadAllForCheckpointCreation,
      loadAllForCheckpointById,
      mainState: { technicalPlaceId, powerUnitId, checkpointName },
      handleChange,
      handleSelectPowerUnit,
      handleSelectTechPlace,
      computedCheckpointName,
      discardState,
      save,
      deleteCheckpoint,
      loadedCheckpoint: {
        nfc, id: checkpointId, isEditable, fullName: loadedCheckpointName
      },
    },
    equipmentStore: {
      resetTechObjects,
      isLoadingTechObjectsForOperationSelect,
      isLoadedTechObjectsForOperationSelect,
      loadTechObjectsForOperationSelect,
      tpsName,
    },
    checkpointActionsStore: {
      confirmedOperations,
      noContentForOverlay,
      setSelectedStateFromConfirmedOperations,
      prepareTechObjectEditingState,
      prepareTechObjectAddingState,
      removeTechObjectFromConfirmed,
    },
    validationStore: {
      validateRequiredFields, isValidFields, onFieldUpdate, hasErrors
    },
    userStore: {
      rootOrgUnit: { code },
    },
    notificationStore: { enqueueSnackbar },
  } = useStores();

  const isVisibleConfirmedOperations = !isEmpty(confirmedOperations);

  useEffect(() => {
    onFieldUpdate('add-equipment-button', isVisibleConfirmedOperations);
  }, [isVisibleConfirmedOperations]);

  const [isOperationOverlayOpen, setOperationOverlayVisibility] = useState(false);
  const {
    setManageEntityModalVisibility, setOnManageEntityModalCloseCallback, setManageEntityModalState, renderManageEntityModal
  } = useManageEntityModal();

  const isByIdMode = ['edit', 'view'].includes(mode);

  const handleAddOperationsButtonClick = () => {
    loadTechObjectsForOperationSelect(technicalPlaceId)
      .then(() => {
        if (noContentForOverlay()) {
          enqueueSnackbar({
            messageTemplate: {
              rows: [{
                rowContent: [{
                  type: 'text',
                  text: t('NO_OVERLAY_TECH_OBJECTS_MESSAGE')
                }]
              }],
            },
            variant: 'warning',
          });
        } else {
          setSelectedStateFromConfirmedOperations();
          prepareTechObjectAddingState();
          setOperationOverlayVisibility(true);
        }
      })
      .catch(e => console.log(e));
  };

  const handleEditButtonClick = techObjectId => () => {
    loadTechObjectsForOperationSelect(technicalPlaceId)
      .then(() => {
        setSelectedStateFromConfirmedOperations();
        prepareTechObjectEditingState(techObjectId);
        setOperationOverlayVisibility(true);
      })
      .catch(e => console.log(e));
  };

  const handleDeleteButtonClick = techObjectId => () => {
    removeTechObjectFromConfirmed(techObjectId);
  };

  const handleOperationsOverlayClose = () => {
    setOperationOverlayVisibility(false);
  };

  const onPowerUnitSelect = (pUnitId) => {
    if (confirmedOperations.length === 0) {
      handleSelectPowerUnit(pUnitId)();
      return;
    }
    setOnManageEntityModalCloseCallback(() => handleSelectPowerUnit(powerUnitId));
    setManageEntityModalState(() => ({
      title: t('ATTENTION_TITLE'),
      message: t('SHOULD_CLEAN_STATE_MESSAGE'),
      type: 'action'
    }));
    setManageEntityModalVisibility(true);
  };

  const onTechPlaceSelect = (techPlaceId) => {
    resetTechObjects();
    if (confirmedOperations.length === 0) {
      handleSelectTechPlace(techPlaceId)();
      return;
    }
    setOnManageEntityModalCloseCallback(() => handleSelectTechPlace(techPlaceId));
    setManageEntityModalState(() => ({
      title: t('ATTENTION_TITLE'),
      message: t('SHOULD_CLEAN_STATE_MESSAGE'),
      type: 'action'
    }));
    setManageEntityModalVisibility(true);
  };

  const goToCheckpointList = () => {
    history.push({
      pathname: routes.CHECKPOINTS
    });
  };

  const goBack = () => {
    if (viewerId) {
      onClose(viewerId);
    } else {
      goToCheckpointList();
    }
  };

  const viewItem = () => {
    history.push({
      pathname: routes.VIEW_CHECKPOINT.replace(':id', id)
    });
  };

  const setNotEditableInfoState = (actionType) => { // eslint-disable-line
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('NOT_EDITABLE_MESSAGE'),
          }]
        },
        {
          rowContent: [{
            type: 'text',
            text: `${actionType} ${t('TAGS').toLowerCase()} ${t('RESTRICTED')}`,
          }]
        }],
      },
      variant: 'warning',
    });
  };

  const handleDeleteCheckpoint = checkPointId => async () => {
    const { error } = await deleteCheckpoint(checkPointId) || {};

    if (error) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('REQUEST_DEFAULT_ERROR')
            }]
          }],
        },
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('CHECKPOINT_SUCCESSFULLY_REMOVED')
          }]
        }],
      },
      variant: 'success',
    });
    goToCheckpointList();
  };

  const deleteItem = () => {
    if (mode !== 'view') return;

    if (!isEditable) {
      setNotEditableInfoState(t('DELETING'));
    } else {
      setManageEntityModalState(() => ({
        title: `${t('DELETING')} ${t('TAGS').toLowerCase()}`,
        entityName: loadedCheckpointName,
        message: `${t('CONFIRM_DELETING_MESSAGE')} ${t('TAG_ACCUSATIVE')}?`,
        type: 'action'
      }));
      setOnManageEntityModalCloseCallback(() => handleDeleteCheckpoint(parseInt(id, 10)));
      setManageEntityModalVisibility(true);
    }
  };

  const editItem = () => {
    if (mode !== 'view') return;

    if (!isEditable) {
      setNotEditableInfoState(t('EDITING'));
    } else {
      history.push({
        pathname: routes.EDIT_CHECKPOINT
          .replace(':id', id)
          .replace(':action(edit)', 'edit')
      });
    }
  };

  const saveItem = async () => {
    validateRequiredFields();
    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: mode === 'create' ? t('CANT_CREATE') : t('CANT_UPDATE')
            }]
          }, {
            rowContent: [{
              type: 'text',
              text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS')
            }]
          }],
        },
        variant: 'error',
      });
      return;
    }
    const { result, error } = await save(parseInt(id, 10)) || {};
    if (result) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: mode === 'create' ? t('CHECKPOINT_SUCCESSFULLY_CREATED') : t('CHANGES_SUCCESSFULLY_APPLIED')
            }]
          }],
        },
        variant: 'success',
      });
      goToCheckpointList();
      return;
    }
    const errorMessage = get(error, 'data.message', '');
    const checkpointAlreadyExists = getCheckpointAlreadyExistsMessage(checkpointName) === errorMessage;
    if (checkpointAlreadyExists) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: mode === 'create' ? t('CANT_CREATE') : t('CANT_UPDATE'),
            }]
          },
          {
            rowContent: [{
              type: 'text',
              text: t('CHECKPOINT_NAME_ALREADY_EXISTS'),
            }]
          }],
        },
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('REQUEST_DEFAULT_ERROR')
          }]
        }],
      },
      variant: 'error',
    });
  };

  const shouldRenderInfoDialog = () => {
    if (isByIdMode && !loadedCheckpointName) return false;
    return true;
  };

  useEffect(() => {
    // if id is not valid
    if (isByIdMode && !parseInt(id, 10)) {
      goBack();
    }
  }, [id]);

  useEffect(() => () => {
    discardState();
  }, [location.key]);

  useEffect(() => {
    if (id) {
      const withTechObjectParents = mode === 'edit';
      loadAllForCheckpointById(id, code, withTechObjectParents);
    } else {
      loadAllForCheckpointCreation(code);
    }
  }, [code, id, location.key]);

  if (isLoading) {
    return <ProgressBar />;
  }

  return !isLoading && (
    <Panel
      mode={viewerId ? 'onlyView' : mode}
      viewItem={viewItem}
      editItem={editItem}
      saveItem={saveItem}
      goBack={goBack}
      deleteItem={deleteItem}
      labelGoToView={t('GO_BACK')}
      labelGoToList={t('GO_BACK')}
      labelView={t('TAG_VIEW')}
      labelCreate={t('TAG_CREATION')}
      labelEdit={t('TAG_EDITING')}
      actions={['edit', 'delete']}
    >
      {((isLoadingTechObjectsForOperationSelect && !isLoadedTechObjectsForOperationSelect) || isDeleting)
        && <ProgressBar />
      }
      <PanelBox>
        <PanelTitle>{t('EQUIPMENT_CHOISE')}</PanelTitle>
        <Input
          label={t('THERMAL_POWER_PLANT')}
          value={tpsName}
          name="tpsName"
          isReadonly
        />

        <Box paddingBottom={2}>
          <Input
            label={t('POWER_UNIT')}
            value={powerUnitId}
            name="powerUnitId"
            onChange={onPowerUnitSelect}
            type="select"
            isReadonly={mode === 'view'}
            options={powerUnits}
            optionValueKey="id"
            optionTitleKey="title"
            required
          />
        </Box>

        <Box paddingBottom={2}>
          <DottedLoader isLoading={isTechPlacesLading}>
            <Input
              label={`${t('EQUIPMENT_LEVEL')} 1`}
              value={technicalPlaceId}
              name="technicalPlaceId"
              onChange={onTechPlaceSelect}
              type="select"
              isReadonly={mode === 'view'}
              options={firstLevelTechPlacesOfSelectedPowerUnit}
              optionValueKey="id"
              optionTitleKey="title"
              disabled={!powerUnitId}
              required
            />
          </DottedLoader>
        </Box>

        <TextInput
          label={t('NOMINATION')}
          value={checkpointName}
          name="checkpointName"
          onChange={handleChange}
          isReadonly={mode === 'view'}
          required
          data-test="checkpointName"
        />

        {isVisibleConfirmedOperations && (
          <>
            <Typography variant="h3" className={classes.title} style={{ margin: '21px 1px' }}>{t('ACTIONS_BY_TAG')}</Typography>

            {confirmedOperations.map(({ techObject: { id: techObjectId, title, }, operations }) => (
              <EquipmentBox
                withButtons={mode !== 'view'}
                key={techObjectId}
                title={title}
                actions={operations}
                onEditButtonClick={handleEditButtonClick(techObjectId)}
                onDeleteButtonClick={handleDeleteButtonClick(techObjectId)}
              />
            ))}
          </>
        )}
        <AddEquipmentButton
          disabled={mode === 'view' || !technicalPlaceId || noContentForOverlay()}
          onClick={handleAddOperationsButtonClick}
          name="add-equipment-button"
          value={isVisibleConfirmedOperations}
          required
        />
      </PanelBox>

      <PanelBox style={viewerId ? {} : { marginBottom: 21 }}>
        <PanelTitle>{t('GENERAL_PARAMS')}</PanelTitle>

        <TextInput
          label={t('TAG_NAME')}
          value={computedCheckpointName}
          name="computedCheckpointName"
          isReadonly
          data-test="computedCheckpointName"
        />
        {checkpointId
          && (
            <Grid container wrap="nowrap">
              <Grid item xs={6}>
                <TextInput
                  label={t('TAG_NUMBER')}
                  value={`№${id}`}
                  name="checkpointNumber"
                  isReadonly
                  data-test="checkpointNumber"
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  label={t('TAG_NFC_CODE')}
                  value={nfc}
                  name="checkpointCode"
                  isReadonly
                  type="custom"
                  render={
                  nfc ? (
                    <CopyToClipboard
                      style={{
                        fontSize: 16,
                        margin: '8px 13px 8px 0px'
                      }}
                      value={nfc}
                    />
                  ) : null
                }
                />
              </Grid>
            </Grid>
          )
        }

      </PanelBox>
      <OperationSelectOverlay
        isOpen={isOperationOverlayOpen}
        handleClose={handleOperationsOverlayClose}
      />

      {shouldRenderInfoDialog()
        && (
          renderManageEntityModal()
        )}
    </Panel>
  );
});

CheckpointDetails.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func
};

CheckpointDetails.defaultProps = {
  id: 0,
};

export default CheckpointDetails;
