import React from 'react';
import withStyles from 'Src/utils/table/withColumnStyles';
import { formatDate } from 'Src/utils/datetime';
import { get, isArray } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import OverflowTip from 'Common/widgets/OverflowTip/OverflowTip';
import UsersTableStaff from 'Business/coal/components/UsersTableStaff';
import TaskTypeIcon from 'Business/coal/components/TaskTypeIcon';

const tableColumns = t => (
  {
    type: {
      field: 'type',
      title: t('TYPE'),
      render: ({ taskPriority, isStandard }) => ( // eslint-disable-line
        <TaskTypeIcon priority={taskPriority} isStandard={isStandard} />
      ),
    },
    title: {
      field: 'title',
      title: t('NAME'),
      mainLink: true,
      withEllipsis: true,
    },
    department: {
      field: 'department',
      title: t('SECTOR'),
      render: ({ department }) => { // eslint-disable-line
        const departmentName = get(department, 'name');
        const departmentCode = get(department, 'code');
        const value = departmentName || departmentCode || '';
        return (
          <OverflowTip tooltipTitle={value} text={value} data-test="department" maxLine={2} />
        );
      },
    },
    technicalObjectTitle: {
      field: 'technicalObjectTitle',
      title: t('EQUIPMENT'),
      withEllipsis: true,
    },
    repairType: {
      field: 'repairType',
      title: t('TYPE_OF_REPAIR'),
      withEllipsis: true,
      render: ({ repairType }) => get(repairType, 'title', ''),
    },
    assigneeRole: {
      field: 'assigneeRole',
      title: t('EMPLOYEE'),
      render: ({ currentAssignee, assignments = [] }) => { // eslint-disable-line
        const currentAssigneeId = get(currentAssignee, 'id');
        if (currentAssigneeId) {
          let staff = [];
          if (isArray(assignments)) staff = assignments.filter(({ id: assigneeId } = {}) => assigneeId !== currentAssigneeId);
          const currentAssigneeName = get(currentAssignee, 'identityName', '');
          return (
            <>
              <span style={{ marginRight: 4 }}>{currentAssigneeName}</span>
              {!isEmpty(staff) && (
                <UsersTableStaff staff={staff} shouldStopPropagation />
              )}
            </>
          );
        }
      },
    },
    expectedStartDate: {
      field: 'expectedStartDate',
      title: t('EXPECTED_START_DATE'),
      render: ({ expectedStartDate }) => formatDate(expectedStartDate),
    },
  }
);

export const statusesColumns = (t) => {
  const translatedTableColumns = tableColumns(t);
  return (
    {
      ACTUAL: {
        columns: [
          withStyles(translatedTableColumns.type, { width: '5%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.title, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.department, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.technicalObjectTitle, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.repairType, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.assigneeRole, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.expectedStartDate, { width: '10%', whiteSpace: 'normal' }),
        ],
        actions: ['remove']
      },
      IN_PROGRESS: {
        columns: [
          withStyles(translatedTableColumns.type, { width: '5%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.title, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.department, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.technicalObjectTitle, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.repairType, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.assigneeRole, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.expectedStartDate, { width: '10%', whiteSpace: 'normal' }),
        ],
        actions: []
      },
      CONTROL: {
        columns: [
          withStyles(translatedTableColumns.type, { width: '5%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.title, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.department, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.technicalObjectTitle, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.repairType, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.assigneeRole, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.expectedStartDate, { width: '10%', whiteSpace: 'normal' }),
        ],
        actions: []
      },
      COMPLETED: {
        columns: [
          withStyles(translatedTableColumns.type, { width: '5%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.title, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.department, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.technicalObjectTitle, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.repairType, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.assigneeRole, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.expectedStartDate, { width: '10%', whiteSpace: 'normal' }),
        ],
        actions: []
      },
      CANCELED: {
        columns: [
          withStyles(translatedTableColumns.type, { width: '5%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.title, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.department, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.technicalObjectTitle, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.repairType, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.assigneeRole, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.expectedStartDate, { width: '10%', whiteSpace: 'normal' }),
        ],
        actions: []
      },
      ALL: {
        columns: [
          withStyles(translatedTableColumns.type, { width: '5%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.title, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.department, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.technicalObjectTitle, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.repairType, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.assigneeRole, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.expectedStartDate, { width: '10%', whiteSpace: 'normal' }),
        ],
        actions: []
      },
      ARCHIVE: {
        columns: [
          withStyles(translatedTableColumns.type, { width: '5%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.title, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.department, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.technicalObjectTitle, { width: '20%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.repairType, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.assigneeRole, { width: '15%', whiteSpace: 'normal' }),
          withStyles(translatedTableColumns.expectedStartDate, { width: '10%', whiteSpace: 'normal' }),
        ],
        actions: []
      }
    }
  );
};

export default tableColumns;
