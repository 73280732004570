import React from 'react';
import { ButtonBase } from '@material-ui/core';
import {
  WhereToVoteOutlined, VpnKeyOutlined, PhotoOutlined,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import useStores from 'Store/useStores';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import Header from 'Common/widgets/Layout/Header/Header';
import { useTranslation } from 'react-i18next';
import Can from 'Common/components/Authorization/Can';
import {
  PARALLEL_OPERATIONS, PRESENCE_CONTROL, VOLUMES, OPERATION_KEYS_SETTINGS, WORKPLACES_SETTINGS, SHAREPOINT_SETTINGS, SETTINGS
} from 'Shared/constants/routes';
import { ReactComponent as IconReusable } from 'Assets/published_with_changes.svg';
import { ReactComponent as IconAod } from 'Assets/icon_Aod.svg';
import { ReactComponent as AccountSettingsIcon } from 'Assets/account-settings-icon.svg';
import { ADMINISTRATOR_OCP, ADMINISTRATOR_OK } from 'Shared/constants/roles';
import { ADMIN_GENERAL_SETTINGS_VIEW } from 'Src/RBAC/businessPermissions';

import useStyles from './styles';


const SelectSetting = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const { userStore: { role, orgUnitRootId } } = useStores();
  const goToPage = (link, fromPath) => {
    history.push({
      pathname: link,
      ...fromPath && { state: { fromPath } }
    });
  };

  const onClickSharePointSettings = () => {
    if (role === ADMINISTRATOR_OCP && orgUnitRootId) {
      goToPage(`${SHAREPOINT_SETTINGS}/view/${orgUnitRootId}`, SETTINGS);
    } else if (role === ADMINISTRATOR_OK) {
      goToPage(SHAREPOINT_SETTINGS);
    }
  };

  const onClickPresenceControlSettings = () => {
    if (role === ADMINISTRATOR_OCP && orgUnitRootId) {
      goToPage(`${PRESENCE_CONTROL}/view/${orgUnitRootId}`, SETTINGS);
    } else if (role === ADMINISTRATOR_OK) {
      goToPage(PRESENCE_CONTROL);
    }
  };

  return (
    <PaperLayout>
      <Header title={t('SETTINGS')} />
      <Can
        variant="user"
        perform={ADMIN_GENERAL_SETTINGS_VIEW}
        yes={() => (
          <div className={classes.block}>
            <div className={classes.blockHeader}>{t('GENERAL_SETTINGS')}</div>
            <div className={classes.row}>
              <ButtonBase
                className={classes.item}
                onClick={() => goToPage(VOLUMES)}
                data-test="volumesButton"
              >
                <div className={classes.iconWrapper}>
                  <IconAod />
                </div>
                <div className={classes.itemText}>{t('VOLUMES_SHORT')}</div>
              </ButtonBase>
              <ButtonBase
                className={classes.item}
                onClick={() => goToPage(PARALLEL_OPERATIONS)}
                data-test="parallelOperationsButton"
              >
                <div className={classes.iconWrapper}>
                  <IconReusable />
                </div>
                <div className={classes.itemText}>{t('AMOUNT_OF_PARALLEL_OPERATIONS_SHORT')}</div>
              </ButtonBase>
            </div>
            <div className={classes.row}>
              <ButtonBase
                className={classes.item}
                onClick={() => goToPage(OPERATION_KEYS_SETTINGS)}
                data-test="operationKeysButton"
              >
                <div className={classes.iconWrapper}>
                  <VpnKeyOutlined />
                </div>
                <div className={classes.itemText}>{t('OPERATION_KEYS')}</div>
              </ButtonBase>
              <ButtonBase
                className={classes.item}
                onClick={() => goToPage(WORKPLACES_SETTINGS)}
                data-test="workplacesButton"
              >
                <div className={classes.iconWrapper}>
                  <AccountSettingsIcon />
                </div>
                <div className={classes.itemText}>{t('WORKPLACES')}</div>
              </ButtonBase>
            </div>
          </div>
        )}
      />

      <div className={classes.block}>
        <div className={classes.blockHeader}>{t('OSR_SETTINGS')}</div>
        <div className={classes.row}>
          <ButtonBase
            className={classes.item}
            onClick={onClickPresenceControlSettings}
            data-test="presenceControlButton"
          >
            <div className={classes.iconWrapper}>
              <WhereToVoteOutlined />
            </div>
            <div className={classes.itemText}>{t('PRESENCE_CONTROL')}</div>
          </ButtonBase>
          <ButtonBase
            className={classes.item}
            onClick={onClickSharePointSettings}
            data-test="sharePointSettingsButton"
          >
            <div className={classes.iconWrapper}>
              <PhotoOutlined />
            </div>
            <div className={classes.itemText}>{t('PHOTOS_PROCESSING_AND_UPLOADING')}</div>
          </ButtonBase>
        </div>
      </div>
    </PaperLayout>
  );
};

export default SelectSetting;
