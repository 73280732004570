import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import Switch from 'Common/components/Forms/Input/Switch';
import { Tooltip } from '@material-ui/core';
import useStores from 'Store/useStores';

import useStyles from './styles';


const DefectSwitcher = (props) => {
  const { t } = useTranslation();
  const {
    isUnconstrained = false, id, isUpdating,
  } = props;
  const classes = useStyles({ isUpdating });
  const {
    defectsDictionaryStore: {
      onChangeDefectStatus
    } = {},
    notificationStore: { enqueueSnackbar },
  } = useStores();

  const showServerIsNotAvailableNotification = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('CANT_SAVE'),
              },
            ],
          },
          {
            rowContent: [
              {
                type: 'text',
                text: t('SERVER_IS_NOT_AVAILABLE'),
              },
            ],
          },
        ],
      },
      variant: 'error',
    });
  };

  return (
    <div className={classes.root}>
      <Tooltip
        disableTouchListener={isUpdating}
        title={isUnconstrained ? t('REREGISTRATION_ALLOWED') : t('REREGISTRATION_NOT_ALLOWED')}
      >
        <div>
          <Switch
            name="isUnconstrained"
            value={isUnconstrained}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onChangeDefectStatus(id, !isUnconstrained).catch(() => showServerIsNotAvailableNotification());
            }}
            isUpdating={isUpdating}
            checked={isUnconstrained}
            data-test="switch-isUnconstrained"
          />
        </div>
      </Tooltip>
    </div>
  );
};

DefectSwitcher.defaultProps = {
  isUnconstrained: false,
  isUpdating: false,
};

DefectSwitcher.propTypes = {
  isUnconstrained: PropTypes.bool,
  id: PropTypes.number.isRequired,
  isUpdating: PropTypes.bool,
};

export default observer(DefectSwitcher);
