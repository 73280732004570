import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  blockTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.4,
    letterSpacing: 'normal',
    color: theme.palette.black,
  },
}));

const DetailsBlockTitle = ({ title }) => {
  const classes = useStyles();

  return (
    <div className={classes.blockTitle}>{title}</div>
  );
};

DetailsBlockTitle.propTypes = {
  title: PropTypes.string.isRequired
};

export default DetailsBlockTitle;
