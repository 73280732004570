import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  btn: {
    padding: theme.spacing(1),
    borderRadius: ({ disableLabels }) => (disableLabels ? '50%' : 4),
    width: ({ disableLabels }) => (disableLabels ? 40 : 140),
    height: 40,
    color: ({ iconColor }) => (iconColor || theme.palette.primaryLight),
    justifyContent: 'flex-start',
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
      marginRight: ({ disableLabels }) => (disableLabels ? 0 : theme.spacing(1)),
    },
    '& .MuiTypography-root': {
      fontSize: 16,
    },
  },
}));


const CollapseButton = ({
  isCollapsed, handleClick, disableLabels, iconColor, className, ...props
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ disableLabels, iconColor });

  return (
    <Button
      variant="contained"
      classes={{ root: clsx(classes.btn, className && className) }}
      onClick={handleClick}
      data-test="collapseBtn"
      {...props}
    >
      {isCollapsed ? (
        <>
          <KeyboardArrowDown />
          {!disableLabels && <Typography color="primary">{t('EXPAND')}</Typography>}
        </>
      ) : (
        <>
          <KeyboardArrowUp />
          {!disableLabels && <Typography color="primary">{t('COLLAPSE')}</Typography>}
        </>
      )}
    </Button>
  );
};

CollapseButton.defaultProps = {
  disableLabels: false,
  iconColor: '',
  className: undefined,
};

CollapseButton.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  disableLabels: PropTypes.bool,
  iconColor: PropTypes.string,
  className: PropTypes.string,
};

export default CollapseButton;
