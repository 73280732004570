import { isNaN } from 'lodash';

const timeUnitsTranslator = (t, unit) => {
  let timeUnit;
  switch (unit) {
    case 'H':
      timeUnit = t('H');
      break;
    case 'M':
      timeUnit = t('MIN');
      break;
    default:
      timeUnit = '';
  }
  return timeUnit;
};

export const convertDurationFromHoursToMinutes = ({ duration, durationUnit }) => {
  if (durationUnit === 'H' && !isNaN(duration)) return { duration: Math.round(duration * 60), durationUnit: 'M' };
  return { duration, durationUnit };
};

export default timeUnitsTranslator;
