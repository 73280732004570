import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
  breadcrumbs: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

const Breadcrumbs = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.breadcrumbs}>
      {children}
    </div>
  );
};

Breadcrumbs.propTypes = {
  children: PropTypes.node.isRequired
};

export default Breadcrumbs;
