import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as ArrowToHeaderButtonImage } from 'Assets/banners/arrowToHeaderButton.svg';

import ActionBtn from 'Common/components/Forms/Button/components/Action/Action';

const useStyles = makeStyles(theme => ({
  noContent: {
    height: 'calc(100% - 136px)',
    minHeight: 380,
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    border: `1px dashed ${theme.palette.black30}`,
    borderRadius: 4,
    marginTop: 38,
  },
  noContentColored: {
    backgroundColor: theme.palette.lightYellow,
    color: theme.palette.dirtyYellow
  },
  text: {
    color: theme.palette.black,
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 2.4,
  },
  subText: {
    color: theme.palette.black30,
    fontSize: 16,
  },
  arrowText: {
    position: 'relative',
    fontSize: 16,
    lineHeight: 1.5,
    color: theme.palette.text.darkGray,
  },
  arrowToHeaderButtonImage: {
    position: 'absolute',
    left: 'calc(100% + 20px)',
    bottom: 0,
  },
  button: {
    marginTop: 24
  }
}));

const NoContent = ({
  text, subText, arrowText, Banner, colored, action, actionText,
}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.noContent} ${colored ? classes.noContentColored : ''}`}>
      {Banner}
      {text && (<div className={classes.text}>{text}</div>)}
      {subText && (<div className={classes.subText}>{subText}</div>)}
      {arrowText && (
        <div className={classes.arrowText}>
          {arrowText}
          <div className={classes.arrowToHeaderButtonImage}>
            <ArrowToHeaderButtonImage />
          </div>
        </div>
      )}
      {action && (
        <div className={classes.button}>
          <ActionBtn
            single
            text={actionText}
            type="confirm"
            data-test="create"
            handleClick={action}
          />
        </div>
      )}
    </div>
  );
};

NoContent.propTypes = {
  text: PropTypes.string,
  subText: PropTypes.string,
  arrowText: PropTypes.string,
  colored: PropTypes.bool,
  Banner: PropTypes.node,
  action: PropTypes.func,
  actionText: PropTypes.string
};

NoContent.defaultProps = {
  colored: false,
  Banner: null,
  action: null,
  actionText: null,
  text: null,
  subText: null,
  arrowText: null,
};

export default NoContent;
