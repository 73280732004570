import withStyles from 'Src/utils/table/withColumnStyles';
import { isArray, isEmpty } from 'lodash';
import { formatDate, formatTime } from 'Src/utils/datetime';

const styles = { width: '20%', whiteSpace: 'normal' };
export default t => (
  [
    withStyles({
      field: 'title',
      title: t('AUTOMATED_TASK_NAME'),
      withEllipsis: true,
    }, styles),
    withStyles({
      field: 'powerUnits',
      title: t('POWER_UNIT'),
      withEllipsis: true,
      render: ({ powerUnits }) => {
        if (!isArray(powerUnits) || isEmpty(powerUnits)) return null;
        return powerUnits.map(({ title: powerUnitTitle = '' } = {}) => powerUnitTitle.replace(`${t('BLOCK')} `, '')).join('; ');
      }
    }, styles),
    withStyles({
      field: 'routeTitle',
      title: t('ROUTE'),
      withEllipsis: true,
      render: ({ routeTitle }) => routeTitle
    }, styles),
    withStyles({
      field: 'assigneeRole',
      title: t('PERFORMER'),
      withEllipsis: true,
      render: ({ assigneeRole }) => assigneeRole
    }, styles),
    withStyles({
      field: '',
      title: t('START_DATE'),
      render: ({ expectedStartDateTime }) => formatDate(expectedStartDateTime),
      withEllipsis: true,
    }, styles),
    withStyles({
      field: '',
      title: t('START_TIME'),
      render: ({ expectedStartDateTime }) => formatTime(expectedStartDateTime),
      withEllipsis: true,
      sorting: false
    }, styles),
  ]
);
