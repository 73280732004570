import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  booleanMeasurementWidth: {
    minWidth: 74,
  },
  textMeasurementWidth: {
    width: 128,
  },
  textMeasurementReadonlyPadding: {
    marginBottom: 14,
  },
  textInput: {
    marginTop: 10,
    marginBottom: 22,
  },
  measurement: {
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    // marginBottom: 6,
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '& .MuiInputBase-root': {
      paddingRight: 8,
    },
  },
  measurementValue: {
    paddingBottom: 0,
    marginBottom: 0,
  },
  booleanReadOnlyInput: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    lineHeight: 1.5,
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
      marginRight: 8,
    },
  },
  booleanReadOnlyInputWrapper: {
    padding: '12px 16px 12px 0px',
  },
  styledAsTextMeasurement: {
    marginTop: 8,
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    lineHeight: 1.5,
    width: 118,
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
      marginRight: 8,
    },
  },
  styledAsTextMeasurementWrapper: {
    padding: '0px 16px 14px 0px',
  },
  positiveColor: {
    color: theme.palette.positive,
  },
  negativeColor: {
    color: theme.palette.negative,
  },
  unitLabel: {
    fontSize: 12,
    lineHeight: 1.33,
    color: theme.palette.black30,
    maxWidth: 44,
  },
}));

export default useStyles;
