import * as Msal from 'msal';
import get from 'lodash/get';
import initTagManager from 'Src/services/logging/TagManager';

export default class MsalAuth {
  constructor(config, setAccessToken) {
    this.config = config;
    this.setAccessToken = setAccessToken;
    this.applicationConfig = {
      auth: {
        clientId: this.config.MSAL_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${this.config.MSAL_TENANT_ID}`,
        navigateToLoginRequestUrl: false,
        redirectUri: get(window, 'location.origin', this.config.MSAL_REDIRECT_URI)
      },
      cache: {
        cacheLocation: this.config.MSAL_CACHE_LOCATION,
        storeAuthStateInCookie: false
      }
    };
    this.loginRequest = this.config.SCOPE;
    this.tokenRequest = { scopes: this.config.SCOPE };
    this.app = new Msal.UserAgentApplication(this.applicationConfig);

    this.app.handleRedirectCallback((error) => {
      if (error) {
        throw error;
      }
    });
  }

  getAccount = () => this.app.getAccount();

  login = () => this.app.loginRedirect(this.loginRequest);

  logout = () => this.app.logout();

  acquireToken = async () => {
    try {
      const res = await this.app.acquireTokenSilent(this.tokenRequest);
      const { accessToken, account: { accountIdentifier, userName } } = res;
      if (this.setAccessToken) this.setAccessToken(accessToken);
      window.localStorage.setItem('accessToken', accessToken);
      window.localStorage.setItem('userId', accountIdentifier);
      const gtmToken = window.localStorage.getItem('gtmToken');
      if (gtmToken) initTagManager(gtmToken, { userId: accountIdentifier, userName });
    } catch (error) {
      if (error.name === 'InteractionRequiredAuthError') {
        this.app.acquireTokenRedirect(this.tokenRequest);
      }

      if (error.errorCode.indexOf('user_login_error') > -1) {
        this.login();
      }
    }
  };

  // handleAuthorization = async () => {
  //   try {
  //     //const { accessToken } = await this.app.acquireTokenSilent(this.tokenRequest);
  //     window.localStorage.setItem('groupTitle', 'plant');
  //
  //     //const { value: memberGroup } = await msGraphAgent.getMemberGroups(accessToken);
  //     // this.onAuthorize(accessToken, this.config.MEMBER_GROUPS, memberGroup[0]);
  //   } catch (error) {
  //     console.log('Error in onLoggedIn: ', error);
  //     if (this.acquireTokenErrorCodes.includes(error.errorCode)) {
  //       this.app.acquireTokenRedirect(this.tokenRequest);
  //     }
  //   }
  // };
}
