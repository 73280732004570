import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useHistory, withRouter } from 'react-router-dom';
import { withTheme } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import useStores from 'Store/useStores';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import DataTable from 'Common/components/DataTable/DataTable';
import { tableConfig } from 'Business/plant/config/parallelOperationsTableConfig';
import Header from 'Common/widgets/Layout/Header/Header';
import EmptyResultsBlock from 'Common/widgets/EmptyResultsBlock';
import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import Modal from 'Business/plant/pages/ParallelOperations/Modal';
import { SETTINGS } from 'Shared/constants/routes';

const ParallelOperations = ({ theme }) => {
  const { t } = useTranslation();
  const translatedTableConfig = tableConfig(t);
  const {
    parallelOperationsStore: {
      list,
      loadList,
      isLoading,
      isListLoaded,
      updateFormData,
      auartType,
      storedType,
      parallelOperationCount,
      save,
      update,
      resetFormData,
    } = {},
    validationStore: { validateRequiredFields, isValidFields, hasErrors },
    notificationStore: { enqueueSnackbar },
  } = useStores();

  const history = useHistory();

  const showDefaultErrorNotification = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('REQUEST_DEFAULT_ERROR'),
              },
            ],
          },
        ],
      },
      variant: 'error',
    });
  };

  useEffect(() => {
    loadList().catch(() => showDefaultErrorNotification());
  }, []);

  const [isModalOpen, setModalState] = useState(false);
  const [mode, setMode] = useState(null);

  const onRowClickHandler = (event, row = {}) => {
    setMode('edit');
    updateFormData('id', row.id);
    updateFormData('storedType', row.auartType);
    updateFormData('auartType', row.auartType);
    updateFormData('parallelOperationCount', row.parallelOperationCount);
    setModalState(true);
  };

  const goToCreate = () => {
    setMode('create');
    setModalState(true);
  };

  const handleSave = async () => {
    validateRequiredFields();
    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('CANT_SAVE'),
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    const isTypeUnique = !list.some(({ auartType: type } = {}) => type === auartType);
    if (!isTypeUnique) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('CANT_SAVE'),
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('RECORD_IS_NOT_UNIQUE'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    const { res } = await save();
    if (!res) {
      showDefaultErrorNotification();
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('RECORD_SUCCESSFULLY_ADDED'),
              },
            ],
          },
        ],
      },
      variant: 'success',
    });
    setModalState(false);
    setMode(null);
    loadList().catch(() => showDefaultErrorNotification());
  };

  const handleUpdate = async () => {
    validateRequiredFields();
    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('CANT_UPDATE'),
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    const isTypeUnique = !list.some(({ auartType: type } = {}) => type === auartType);
    const canEdit = auartType === storedType || isTypeUnique;
    if (!canEdit) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('CANT_SAVE'),
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('RECORD_IS_NOT_UNIQUE'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    const { res } = await update();

    if (!res) {
      showDefaultErrorNotification();
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('CHANGES_SUCCESSFULLY_APPLIED'),
              },
            ],
          },
        ],
      },
      variant: 'success',
    });
    setModalState(false);
    setMode(null);
    loadList().catch(() => showDefaultErrorNotification());
  };

  const {
    headerHeight = 0,
    pageHeaderHeight = 0,
    mainContentPaddingTop = 0,
    mainContentPaddingBottom = 0,
  } = theme;
  const tableBodyHeight = `calc(100vh - ${headerHeight}px - ${pageHeaderHeight}px - ${mainContentPaddingTop}px - ${mainContentPaddingBottom}px)`;

  const showEmptyResultsBlock = isEmpty(list) && isListLoaded;
  const showData = !isEmpty(list);

  const goToSettings = () => history.push({ pathname: SETTINGS });

  return (
    <PaperLayout>
      {isLoading && <ProgressBar />}
      <Header
        isActiveGoBack
        titleGoBack={t('GO_BACK')}
        title={t('AMOUNT_OF_PARALLEL_OPERATIONS')}
        handleGoBack={goToSettings}
        style={{ borderBottom: 'none' }}
      >
        <HeaderButton title={t('ADD_ITEM')} type="add" handleClick={goToCreate} />
      </Header>
      <div style={{ position: 'relative', zIndex: 1 }}>
        {showEmptyResultsBlock && <EmptyResultsBlock />}
        {showData && (
          <DataTable
            tableBodyHeight={tableBodyHeight}
            data={list}
            titleSingle={t('TASK_ACCUSATIVE')}
            columns={translatedTableConfig.columns}
            onRowClick={onRowClickHandler}
            actions={translatedTableConfig.actions}
            dataTestRowSuffixEntityName="id"
          />
        )}
        <Modal
          isOpen={isModalOpen}
          setModalState={setModalState}
          updateFormData={updateFormData}
          auartType={auartType}
          parallelOperationCount={parallelOperationCount}
          mode={mode}
          setMode={setMode}
          save={handleSave}
          update={handleUpdate}
          resetFormData={resetFormData}
        />
      </div>
    </PaperLayout>
  );
};

ParallelOperations.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.shape({
    headerHeight: PropTypes.number,
    pageHeaderHeight: PropTypes.number,
    pageTabsHeight: PropTypes.number,
    tableHeaderHeight: PropTypes.number,
    tableFooterHeight: PropTypes.number,
    mainContentPaddingTop: PropTypes.number,
    mainContentPaddingBottom: PropTypes.number,
    pageSearchBlockHeight: PropTypes.number,
  }).isRequired,
};

export default withRouter(withTheme(observer(ParallelOperations)));
