import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import useStores from 'Store/useStores';
import { ParameterType, columnsType } from 'Business/plant/constants';
import SectionList from 'Common/widgets/Section/SectionList';
import FullScreenOverlay from 'Common/components/FullScreenOverlay';
import DottedLoader from 'Common/components/Progress/components/Dotted';
import Input from 'Common/components/Forms/Input/Input';
import TextInput from 'Common/components/Forms/Input/Text';
import FormSwitch from 'Common/components/Forms/Input/FormSwitch';

import {
  columnsData,
  getDefaultValueForColumn,
  getModalColumnsData,
  defaultColumn,
} from './volumeUtils';

import useStyles from './styles';

const VolumeOverlay = observer(({
  isOpen, title, onClose, parameters, editData, setValueModalType, isCreateValueType, idsForRemove,
  allVolumes, onCreateParameter,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [columnContent, setColumnContent] = useState([defaultColumn]);
  const [isRequiredOnMob, setRequiredOnMob] = useState(false);
  const [parameterName, setParameterName] = useState('');
  const [parameterError, setParameterError] = useState('');
  const [sapCodeObj, setSapCodeObj] = useState('');
  const [valueId, setValueId] = useState('');
  const [selectedParameter, setSelectedParameter] = useState('');
  const {
    volumeStore: {
      createNewVolume,
      getVolumeByName,
      editVolume,
      isCreatingNewVolume,
      deleteVolumes,
    }
  } = useStores();
  const parameterErrorProps = parameterError ? {
    error: true,
    helperText: parameterError,
  } : {};

  const getDataFromColumns = (columns) => {
    const res = {};

    columns.forEach((column) => {
      res[column.type] = column.value;
    });

    return res;
  };

  const handleSave = async () => {
    const valueIdObj = !isCreateValueType ? { id: valueId } : {};

    const reqData = {
      sapCode: selectedParameter ? selectedParameter.enumValue : null,
      parameterName,
      isRequired: isRequiredOnMob,
      parameterType: 0,
      dataType: 0,
      operationKeys: [],
      unit: null,
      directories: null,
      ...valueIdObj,
      ...getDataFromColumns(columnContent),
    };
    const isParameterExists = Boolean(await getVolumeByName(parameterName));

    if (isParameterExists && isCreateValueType) {
      setParameterError(t('EXISTING_PARAMETER_NAME'));
    } else {
      Promise.all([
        (isCreateValueType ? createNewVolume : editVolume)(reqData),
        deleteVolumes(idsForRemove)
      ]).then(() => {
        setSelectedParameter('');
        onClose();
      });
    }
  };

  const handleChangeRequiredOnMob = () => {
    setRequiredOnMob(!isRequiredOnMob);
  };

  const handleChangeParameterName = (text) => {
    setParameterName(text);
    setParameterError('');
  };

  const handleSetColumnValue = (selectedKeysValue, columnType) => {
    const newColumnContent = columnContent.map((col) => {
      if (col.type === columnType) {
        return {
          ...col,
          value: selectedKeysValue,
        };
      }

      return col;
    });

    // check on disabled here
    setColumnContent(newColumnContent);
  };

  const onAction = (value, newColumnType, currentIndex) => {
    setColumnContent((prevState) => {
      const arr = [...prevState];
      arr.splice(currentIndex + 1); // remove unnecessary columns
      arr[currentIndex].value = value;
      arr.push({
        reconciliationKey: newColumnType,
        type: newColumnType,
        value: getDefaultValueForColumn(newColumnType),
        Component: columnsData[newColumnType],
      });

      return arr;
    });
  };

  const resetData = () => {
    setColumnContent([defaultColumn]);
    setRequiredOnMob(false);
    setParameterName('');
    setSapCodeObj('');
    setParameterError('');
  };

  const getGridWidth = (index, isLastColumn) => {
    const [parameterTypeColumn] = columnContent;
    const maxManualColumn = 3;

    if (parameterTypeColumn.value === ParameterType.BINARY && isLastColumn) return 9;
    if (parameterTypeColumn.value === ParameterType.SINGLE_SELECT && index === 1) return 4;
    if (parameterTypeColumn.value === ParameterType.SINGLE_SELECT && isLastColumn) return 5;

    if ((parameterTypeColumn.value === ParameterType.MANUAL && index + 1 === maxManualColumn && isLastColumn)
      || (parameterTypeColumn.value === ParameterType.SINGLE_SELECT && isLastColumn)) {
      return 6;
    }

    return 3;
  };

  const handleSelectVolumeCode = (newSapCodeObj) => {
    const selectedSapCodeObj = parameters.find(parameter => parameter.sapCode === newSapCodeObj.enumValue);
    if (selectedSapCodeObj) {
      setSapCodeObj(selectedSapCodeObj);
      setValueModalType('edit');
    } else {
      setParameterName('');
      onCreateParameter();
      setColumnContent([defaultColumn]);
    }

    setSelectedParameter(newSapCodeObj);
  };

  const handleEnteredModal = () => {
    resetData();
  };

  const checkOnDisabledSave = () => {
    const operationKeysColumn = columnContent.find(column => column.type === columnsType.OPERATION_KEYS);

    if (!operationKeysColumn) {
      return true;
    }

    return isCreatingNewVolume || !operationKeysColumn.value.length;
  };

  useEffect(() => {
    // update sapCodeObj
    if (!sapCodeObj) return;

    const modalData = getModalColumnsData(sapCodeObj);
    setParameterName(sapCodeObj.parameterName);
    setRequiredOnMob(sapCodeObj.isRequired);
    setValueId(sapCodeObj.id);
    setColumnContent(modalData);
  }, [sapCodeObj]);

  useEffect(() => {
    if (!editData) return;

    const modalData = getModalColumnsData(editData);
    const sapCodeData = allVolumes.find(parameter => parameter.enumValue === editData.sapCode);
    setParameterName(editData.parameterName);
    setRequiredOnMob(editData.isRequired);
    setValueId(editData.id);
    if (sapCodeData) setSelectedParameter(sapCodeData);
    setColumnContent(modalData);
  }, [editData]);

  return (
    <FullScreenOverlay
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      onSave={handleSave}
      classes={classes.overlay}
      disabledClose={isCreatingNewVolume}
      disabledSave={checkOnDisabledSave()}
      onExited={handleEnteredModal}
    >
      <Grid
        container
        wrap="nowrap"
        justify="center"
        className={classes.headerContainer}
      >
        <Grid item container xs={8}>
          <Input
            label={t('SAP_CODE_PARAMETER')}
            name="sapCode"
            type="select"
            options={allVolumes}
            isOptionValue
            optionValueKey="enumValue"
            optionTitleKey="enumValue"
            className={classes.leftInput}
            value={selectedParameter}
            onChange={handleSelectVolumeCode}
          />
          <TextInput
            label={t('PARAMETER_NAME')}
            helperText={t('REQUIRED')}
            name="parameterName"
            onChange={handleChangeParameterName}
            value={parameterName}
            className={classes.leftInput}
            {...parameterErrorProps}
            data-test="parameterName"
          />
        </Grid>
        <Grid item xs={4} container justify="flex-end">
          <FormSwitch
            label={t('REQUIRED_FIELD_FOR_DEVICE')}
            name="daily"
            checked={isRequiredOnMob}
            onChange={handleChangeRequiredOnMob}
            data-test="switch-daily"
          />
        </Grid>
      </Grid>
      <DottedLoader
        isLoading={isCreatingNewVolume}
        customClass={classes.dottedLoader}
      >
        {parameterName.length > 0 && (
          <SectionList>
            {columnContent.map((col, index) => (
              <col.Component
                key={col.reconciliationKey}
                index={index}
                defValue={col.value}
                activateNextColumn={onAction}
                setColumnValue={handleSetColumnValue}
                gridWidth={getGridWidth(index, col.type === columnsType.OPERATION_KEYS)}
              />
            ))}
          </SectionList>
        )}
      </DottedLoader>
    </FullScreenOverlay>
  );
});

VolumeOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isCreateValueType: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  setValueModalType: PropTypes.func.isRequired,
  parameters: PropTypes.arrayOf(PropTypes.shape({
    sapCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })).isRequired,
  idsForRemove: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default VolumeOverlay;
