import { makeStyles } from '@material-ui/core/styles';
import { isUndefined } from 'lodash';

const useStyles = makeStyles(theme => ({
  textField: {
    '& input': {
      fontSize: 16,
    },
    maxWidth: ({ maxWidth }) => (maxWidth || '100%'),
    '& .MuiInputBase-root': {
      '& fieldset': {
        fontSize: 16,
      },
      '&:hover': {
        '& fieldset': {
          borderColor: ({ error }) => (error ? theme.palette.negative : theme.palette.primaryLight),
        },
      },
      color: theme.palette.black,
    },
    '& .MuiFormLabel-root': {
      fontSize: 16,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.primaryLight,
      borderWidth: 4,
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: theme.palette.negative,
    },
    marginTop: ({ marginTop }) => (!isUndefined(marginTop) ? marginTop : theme.spacing(1)),
    marginBottom: ({ marginBottom }) => (!isUndefined(marginBottom) ? marginBottom : theme.spacing(3)),
    '& .MuiFormHelperText-root': {
      fontSize: 12,
      color: ({ error }) => (error ? theme.palette.negative : theme.palette.black30),
    },
  },
  required: {
    '&::after': {
      display: 'block',
      content: ({ requiredMessage, t }) => `'${requiredMessage || t('REQUIRED')}'`,
      position: 'absolute',
      background: theme.palette.white,
      opacity: ({ disabled }) => (disabled ? 0.5 : 1),
      top: 'calc(100% + 2px)',
      left: 16,
      fontFamily: 'Roboto',
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.33,
      letterSpacing: 'normal',
      color: ({ error }) => (error ? theme.palette.negative : theme.palette.black30),
    },
  },
  buttonBase: {
    cursor: 'text',
  },
}));

export default useStyles;
