import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { isBefore } from 'date-fns';
import { isValid } from 'Src/utils/datetime';
import { get, startsWith, isEmpty } from 'lodash';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import useStores from 'Store/useStores';
import * as routes from 'Shared/constants/routes';
import useManageEntityModal from 'Common/components/Modal/ManageEntityModal/useManageEntityModal';

import { useTranslation } from 'react-i18next';
import { HEAD_MECHANIC, PERFORMER } from 'Shared/constants/roles';
import { NEW_ENUM, ASSIGNED_ENUM } from 'Shared/constants/taskStatuses';
import { TASK_NAME_ALREADY_EXISTS } from 'Shared/constants/responseErrors/errors';
import { Box, Tab, Tabs } from '@material-ui/core';
import Panel from 'Common/widgets/Layout/Panel/Panel';
import TabPanel from 'Common/components/TabPanel';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import taskTabsConfig from 'Business/coal/config/taskTabsConfig';
import TaskAttributes from './TaskAttributes';
import TaskDefects from './TaskDefects';

const Details = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { mode } = props;
  const history = useHistory();
  const params = useParams();
  const { id: taskId } = params;
  const [tab, setTab] = useState(taskTabsConfig(t)[0].alias);

  const {
    coalTaskStore: {
      loadDepartmentAndDictionaries,
      setMode,
      getTaskById,
      taskData,
      getDefectsByTaskId,
      taskDefects,
      isEmptyLoadedTaskData,
      isLoaded,
      isUpdating,
      save,
      reset,
      deleteTask,
      discardEditorState,
      onChangeDepartment,
    },
    notificationStore: { enqueueSnackbar },
    userStore: { role, auth: { id: currentUserId, department } = {} },
    validationStore: {
      validateRequiredFields, isValidFields, hasErrors
    },
  } = useStores();

  const usersDepartmentId = get(department, 'id');
  const {
    title, taskStatus, expectedStartDate, updatedBy
  } = taskData;

  let shouldLoadTaskById = false;
  let withActions = true;
  let successSaveMessage;
  let requiredValidationMessage;
  let shouldLoadDefects = true;
  let tableMode = mode; // eslint-disable-line

  switch (mode) {
    case 'create':
      shouldLoadTaskById = false;
      shouldLoadDefects = false;
      successSaveMessage = t('TASK_SUCCESSFULLY_CREATED');
      requiredValidationMessage = t('CANT_CREATE');
      break;
    case 'view':
      shouldLoadTaskById = true;
      if (![ASSIGNED_ENUM, NEW_ENUM].includes(taskStatus)) withActions = false;
      break;
    case 'edit':
      successSaveMessage = t('CHANGES_SUCCESSFULLY_APPLIED');
      requiredValidationMessage = t('CANT_UPDATE');
      shouldLoadTaskById = true;
      break;
    case 'control':
      tableMode = 'onlyView';
      shouldLoadTaskById = true;
      break;
    default:
      shouldLoadDefects = false;
      shouldLoadTaskById = false;
  }

  const createdByCurrentUser = updatedBy === currentUserId;

  const {
    setManageEntityModalVisibility,
    setOnManageEntityModalCloseCallback,
    setManageEntityModalState,
    renderManageEntityModal,
  } = useManageEntityModal();

  let panelActions = ['delete', 'edit'];
  if (role === PERFORMER) panelActions = [];

  useEffect(() => {
    setMode(mode);
  }, [mode]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const goBack = () => {
    reset();
    const { state } = location;
    const { fromPath, ...rest } = state || {};
    history.push({
      pathname: fromPath || routes.TASKS,
      state: rest,
    });
  };

  const viewItem = () => {
    discardEditorState();
    history.push({
      pathname: `${routes.VIEW_TASK}/${taskId}`,
    });
  };

  const removeItem = () => {
    deleteTask(taskId)
      .then(() => {
        enqueueSnackbar({
          messageTemplate: {
            rows: [
              {
                rowContent: [
                  {
                    type: 'text',
                    text: t('TASK_SUCCESSFULLY_DELETED'),
                  },
                ],
              },
            ],
          },
          variant: 'success',
        });
        goBack();
      })
      .catch(() => {
        enqueueSnackbar({
          messageTemplate: {
            rows: [
              {
                rowContent: [
                  {
                    type: 'text',
                    text: t('REQUEST_DEFAULT_ERROR'),
                  },
                ],
              },
            ],
          },
          variant: 'error',
        });
      });
  };

  const editItem = () => {
    history.push({
      pathname: `${routes.EDIT_TASK}/${taskId}`,
    });
  };

  const showAlreadyExistsTaskNamePrompt = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('TASK_NAME_ALREADY_EXISTS')
          }]
        },
        {
          rowContent: [{
            type: 'text',
            text: t('CHANGE_THE_NAME_AND_SAVE_THE_TASK_AGAIN')
          }]
        }],
      },
      variant: 'warning',
    });
  };

  const saveItem = async () => {
    validateRequiredFields();
    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: requiredValidationMessage,
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }

    const { res, error } = await save();
    if (res) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: successSaveMessage,
                },
              ],
            },
          ],
        },
        variant: 'success',
      });
      goBack();
      return;
    }
    const errorMessage = get(error, 'data.message', '');
    if (startsWith(errorMessage, TASK_NAME_ALREADY_EXISTS)) {
      showAlreadyExistsTaskNamePrompt();
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('REQUEST_DEFAULT_ERROR'),
              },
            ],
          },
        ],
      },
      variant: 'error',
    });
  };

  useEffect(() => {
    if (isEmptyLoadedTaskData) {
      goBack();
    }
  }, [isEmptyLoadedTaskData]);

  useEffect(() => {
    reset();

    // if id is not valid
    if (shouldLoadTaskById && !parseInt(taskId, 10)) {
      goBack();
    }
    if (shouldLoadTaskById && parseInt(taskId, 10)) {
      getTaskById(taskId);
    }
    if (shouldLoadDefects && parseInt(taskId, 10)) {
      getDefectsByTaskId(taskId);
    }
  }, [taskId, shouldLoadTaskById, shouldLoadDefects]);

  useEffect(() => {
    loadDepartmentAndDictionaries();
    if (role !== HEAD_MECHANIC && usersDepartmentId) onChangeDepartment(usersDepartmentId);
  }, []);

  if (!isLoaded && mode !== 'create') {
    return <ProgressBar />;
  }

  const showDeleteTaskPrompt = () => {
    setManageEntityModalVisibility(true);
    setManageEntityModalState({
      title: t('DELETING_A_TASK'),
      entityName: `${t('REPAIR_TASK')} ${title}`,
      message: t('ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_TASK'),
      type: 'action',
      actionButtonTitle: t('DELETE'),
    });
    setOnManageEntityModalCloseCallback(() => removeItem);
  };

  const showNotDeletableTaskPrompt = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: `${t('REPAIR_TASK')} ${title}`
          }]
        },
        {
          rowContent: [{
            type: 'text',
            text: t('TASK_HAS_ALREADY_BEEN_TAKEN_INTO_OPERATION')
          }]
        },
        {
          rowContent: [{
            type: 'text',
            text: t('REMOVAL_IS_PROHIBITED')
          }]
        }],
      },
      variant: 'warning',
    });
  };

  const showNotEditableTaskPrompt = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: `${t('REPAIR_TASK')} ${title}`
          }]
        },
        {
          rowContent: [{
            type: 'text',
            text: t('TASK_HAS_ALREADY_BEEN_TAKEN_INTO_OPERATION')
          }]
        },
        {
          rowContent: [{
            type: 'text',
            text: t('EDITING_PROHIBITED')
          }]
        }],
      },
      variant: 'warning',
    });
  };

  const handleDeleteTask = () => {
    if (isValid(expectedStartDate) && isBefore(new Date(expectedStartDate), new Date())) {
      showNotDeletableTaskPrompt();
      return;
    }
    showDeleteTaskPrompt();
  };

  const handleEditTask = () => {
    if (isValid(expectedStartDate) && isBefore(new Date(expectedStartDate), new Date())) {
      showNotEditableTaskPrompt();
      return;
    }
    editItem();
  };

  return (
    (isLoaded || mode === 'create') && (
      <>
        {isUpdating && <ProgressBar />}
        <Panel
          mode={tableMode}
          viewItem={viewItem}
          editItem={handleEditTask}
          saveItem={saveItem}
          goBack={goBack}
          deleteItem={handleDeleteTask}
          labelGoToView={t('GO_BACK')}
          labelGoToList={t('GO_BACK')}
          labelView={title}
          labelCreate={t('TASK_CREATION')}
          labelEdit={t('EDITING_A_TASK')}
          labelControl={title}
          disableDelete={!createdByCurrentUser}
          disableEdit={!createdByCurrentUser}
          actions={panelActions}
          withActions={withActions}
        >
          <Box style={{ borderBottom: '1px solid #e9e9e9' }}>
            <Tabs
              value={tab}
              onChange={(event, newValue) => handleChange(event, newValue)}
              variant="scrollable"
            >
              {taskTabsConfig(t, taskDefects).map(({ alias, title: tabTitle } = {}) => (
                <Tab
                  data-test={alias}
                  disabled={isEmpty(taskDefects)}
                  key={alias}
                  label={tabTitle}
                  value={alias}
                />
              ))}
            </Tabs>
          </Box>
          <TabPanel tab={tab} alias={taskTabsConfig(t)[0].alias}>
            <TaskAttributes mode={mode} />
          </TabPanel>
          <TabPanel tab={tab} alias={taskTabsConfig(t)[1].alias}>
            <TaskDefects />
          </TabPanel>
          {renderManageEntityModal()}
        </Panel>
      </>
    )
  );
};

Details.propTypes = {
  mode: PropTypes.string.isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      positive: PropTypes.string,
      black30: PropTypes.string,
      negative: PropTypes.string,
    }),
  }),
};

Details.defaultProps = {
  theme: {},
};

export default observer(Details);
