import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { startsWith } from 'lodash';

import Modal from 'Common/components/Modal/Modal';
import TextInput from 'Common/components/Forms/Input/Text';
import ActionBtn from 'Common/components/Forms/Button/components/Action/Action';

const minParallelOperationCount = 0;
const maxParallelOperationCount = 100;

const ParallelOperationModal = ({
  isOpen,
  setModalState,
  auartType,
  parallelOperationCount,
  updateFormData,
  mode,
  save,
  update,
  resetFormData
}) => {
  const { t } = useTranslation();
  const title = mode === 'edit' ? t('EDITING_ITEM') : t('CREATING_ITEM');

  const [isParallelOperationCountValid, setIsParallelOperationCountValid] = useState(true);

  const renderActions = () => (
    <Grid container spacing={3} justify="flex-end">
      <Grid item>
        <ActionBtn text={t('CANCEL')} type="cancel" handleClick={() => setModalState(false)} />
      </Grid>
      <Grid item>
        <ActionBtn
          text={t('SAVE')}
          type="confirm"
          handleClick={mode === 'edit' ? update : save}
        />
      </Grid>
    </Grid>
  );

  return (
    <Modal
      onClick={e => e.stopPropagation()}
      title={title}
      isOpened={isOpen}
      onExited={resetFormData}
      actions={renderActions()}
    >
      <TextInput
        label={t('AUART_TYPE')}
        name="auartType"
        required
        onChange={val => updateFormData('auartType', val)}
        value={auartType}
        data-test="auartType"
      />
      <TextInput
        error={!isParallelOperationCountValid}
        label={t('MAX_OPERATIONS_AMOUNT')}
        name="parallelOperationCount"
        required
        requiredMessage={`${t('REQUIRED')} (${minParallelOperationCount} - ${maxParallelOperationCount})`}
        onBlur={() => {
          if (parallelOperationCount && (parallelOperationCount < minParallelOperationCount || parallelOperationCount > maxParallelOperationCount)) setIsParallelOperationCountValid(false);
        }}
        onChange={(val) => {
          setIsParallelOperationCountValid(true);
          let onlyDigitsValue = val.replace(/\D/g, '');
          if (val.length > 1 && startsWith(onlyDigitsValue, '0')) onlyDigitsValue = '0';
          updateFormData('parallelOperationCount', onlyDigitsValue);
        }}
        value={parallelOperationCount}
        maxWidth="60%"
        data-test="parallelOperationCount"
      />
    </Modal>
  );
};

ParallelOperationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setModalState: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    auartType: PropTypes.string,
    parallelOperationCount: PropTypes.string,
  }),
  updateFormData: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['edit', 'create']),
  save: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  resetFormData: PropTypes.func.isRequired,
  parallelOperationCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  auartType: PropTypes.string,
};

ParallelOperationModal.defaultProps = {
  formData: null,
  mode: null,
  parallelOperationCount: '',
  auartType: ''
};

export default ParallelOperationModal;
