import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Box } from '@material-ui/core';
import { isEmpty, isObject, get } from 'lodash';
import useStores from 'Store/useStores';
import Input from 'Common/components/Forms/Input/Input';
import DateInput from 'Common/components/Forms/Input/Date';
import TextInput from 'Common/components/Forms/Input/Text';
import TaskType from 'Business/coal/components/GeneralBlock/parts/TaskType';
import Priority from 'Business/coal/components/GeneralBlock/parts/Priority';
import Equipment from 'Business/coal/components/GeneralBlock/parts/Equipment';
import RepairType from 'Business/coal/components/GeneralBlock/parts/RepairType';
import Description from 'Business/coal/components/GeneralBlock/parts/Description';
import QRCodeBlock from 'Business/coal/components/GeneralBlock/parts/QRCodeBlock';
import Assignments from 'Business/coal/components/GeneralBlock/parts/Assignments';
import { useTranslation } from 'react-i18next';
import OptionRenderer from 'Common/components/Forms/OptionRenderer';
import { VIEW_TASK } from 'Shared/constants/routes';
import StyledLink from 'Common/components/StyledLink';
import { isValid, isBefore } from 'date-fns';

import {
  HEAD_MECHANIC,
} from 'Shared/constants/roles';

import useStyles from './styles';


const GeneralBlock = (props) => {
  const { t } = useTranslation();
  const classes = useStyles({ t });
  const { mode } = props;
  const {
    coalTaskStore: {
      taskData,
      updateDataField,
      departmentsDictionary,
      onChangeDepartment,
    },
    userStore: {
      role,
    }
  } = useStores();

  const [expectedStartDateIsValid, setExpectedStartDateIsValid] = useState(true);

  const {
    title,
    isStandard,
    expectedStartDate,
    departmentId,
    currentAssignee,
    parent
  } = taskData;

  let isReadonlyByMode;
  let showAssignmentsWidget = true;

  switch (mode) {
    case 'create':
      isReadonlyByMode = false;
      break;
    case 'view':
      isReadonlyByMode = true;
      showAssignmentsWidget = isObject(currentAssignee) && !isEmpty(currentAssignee);
      break;
    case 'edit':
      isReadonlyByMode = false;
      break;
    case 'control':
      isReadonlyByMode = true;
      break;
    default:
      isReadonlyByMode = false;
  }

  const isReadonlyDepartment = isReadonlyByMode || role !== HEAD_MECHANIC;
  const parentId = get(parent, 'id');
  const parentTitle = get(parent, 'title');

  const validateExpectedStartDate = () => {
    if ((!isValid(new Date(expectedStartDate)) || isBefore(new Date(expectedStartDate), new Date().setHours(0, 0, 0))) && expectedStartDateIsValid) {
      setExpectedStartDateIsValid(false);
    }
  };

  return (
    <div className={classes.generalBlock}>
      <div className={classes.blockHeader}>
        <div className={classes.blockHeaderLabel}>{t('TASK_ATTRIBUTES')}</div>
      </div>
      {parentId && parentTitle && (
        <div className={classes.twoColumns}>
          <div className={classes.column}>
            <OptionRenderer
              value={parentTitle}
              valueRenderer={value => (
                <StyledLink to={`${VIEW_TASK}/${parentId}`}>{value}</StyledLink>
              )}
              title={t('TASK_SOURCE')}
              name="parentTaskLink"
              wrapperClass={classes.optionRenderer}
            />
          </div>
        </div>
      )}
      <div className={classes.twoColumns}>
        <div className={classes.column}>
          <Box css={{ maxWidth: 500 }}>
            {!isStandard && (
              <TextInput
                maxWidth={500}
                label={t('TITLE')}
                value={title}
                name="title"
                onChange={value => updateDataField('title', value)}
                autoFocus
                isReadonly={isReadonlyByMode}
                required
                data-test="title"
              />
            )}
            <Input
              multiLine={isReadonlyDepartment}
              required
              fullOutlined
              label={t('DEPARTMENT')}
              disabled={isEmpty(departmentsDictionary)}
              value={departmentId}
              onChange={(value) => {
                onChangeDepartment(value);
              }}
              name="departmentId"
              placeholder={t('SELECT_VALUE')}
              type="selectAlpha"
              options={departmentsDictionary || []}
              isReadonly={isReadonlyDepartment}
              optionValueKey="id"
              optionTitleKey="name"
              getOptionTitle={({ name, code }) => name || code}
            />
          </Box>
          <Box css={{ maxWidth: 232 }}>
            <DateInput
              required
              isReadonly={isReadonlyByMode}
              disablePast
              label={t('EXPECTED_START_DATE')}
              name="expectedStartDate"
              value={expectedStartDate}
              onChange={(value) => {
                if (!expectedStartDateIsValid) {
                  setExpectedStartDateIsValid(true);
                }
                updateDataField('expectedStartDate', value);
              }}
              onBlur={validateExpectedStartDate}
              validationError={!expectedStartDateIsValid}
              data-test="expectedStartDate"
            />
          </Box>
          <TaskType isReadonlyByMode={isReadonlyByMode} />

          <Priority isReadonlyByMode={isReadonlyByMode} />
        </div>
        <div className={classes.column}>
          <Equipment isReadonlyByMode={isReadonlyByMode} />

          <RepairType isReadonlyByMode={isReadonlyByMode} />

          <Description isReadonlyByMode={isReadonlyByMode} />

          <QRCodeBlock isReadonlyByMode={isReadonlyByMode} />
          {showAssignmentsWidget && (
            <Assignments
              name="assignments"
              value={!isEmpty(currentAssignee)}
              type="custom"
              required
              isReadonlyByMode={isReadonlyByMode}
            />
          )}
        </div>
      </div>
    </div>
  );
};

GeneralBlock.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default observer(GeneralBlock);
