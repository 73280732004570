import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import useStores from 'Store/useStores';
import { isNull, get } from 'lodash';
import { SvgIcon } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { ReactComponent as StatisticsReportIcon } from 'Src/assets/qr_code_scanner.svg';
import FormSwitch from 'Common/components/Forms/Input/FormSwitch';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const QRCodeBlock = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    coalTaskStore: {
      taskData,
      updateDataField,
    },
  } = useStores();

  const {
    isRequiredQrCode,
    equipmentSapCode,
  } = taskData;

  useEffect(() => {
    if (isNull(equipmentSapCode)) updateDataField('isRequiredQrCode', false);
  }, [equipmentSapCode]);

  const { isReadonlyByMode, theme } = props;
  if (isReadonlyByMode) {
    if (!isRequiredQrCode) return null;
    return (
      <div className={classes.qrBlock}>
        <SvgIcon
          htmlColor={theme.palette.black30}
          className={classes.qrIcon}
          component={StatisticsReportIcon}
        />
        <div className={classes.readOnlyLabel}>{t('IS_REQUIRED_QR_CODE')}</div>
      </div>
    );
  }
  return (
    <FormSwitch
      disabled={!equipmentSapCode}
      label={t('IS_REQUIRED_QR_CODE')}
      name="qrCode"
      checked={isRequiredQrCode}
      onChange={(e) => {
        const checked = get(e, 'target.checked');
        updateDataField('isRequiredQrCode', checked);
      }}
      data-test="switch-qrCode"
    />
  );
};

QRCodeBlock.propTypes = {
  isReadonlyByMode: PropTypes.bool.isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      black30: PropTypes.string,
    }),
  }),
};

QRCodeBlock.defaultProps = {
  theme: {},
};

export default withTheme(observer(QRCodeBlock));
