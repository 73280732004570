import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import SectionHeader from 'Common/widgets/Section/SectionHeader';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  sectionRoot: {
    borderRightColor: theme.palette.midGray,
    borderRight: '1px solid',
  },
  padded: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  sectionItems: {
    height: ({ maxHeight }) => maxHeight || `calc(100vh - ${theme.pageHeaderHeight}px - ${theme.sectionHeaderHeight}px)`,
    overflowY: 'auto'
  }
}));

const Section = ({
  children, title, gridWidth, detachedChildren, customClass, maxHeight
}) => {
  const classes = useStyles({ maxHeight });

  return (
    <Grid item xs={gridWidth} className={clsx(classes.sectionRoot, customClass)}>
      <SectionHeader title={title} />
      <div className={classes.sectionItems}>
        {detachedChildren
          ? (
            <div className={classes.padded}>
              {children}
            </div>
          ) : children
        }
      </div>
    </Grid>
  );
};

Section.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  gridWidth: PropTypes.number,
  detachedChildren: PropTypes.bool,
  maxHeight: PropTypes.string
};

Section.defaultProps = {
  customClass: '',
  gridWidth: 3,
  detachedChildren: false,
  maxHeight: undefined,
};

export default Section;
