import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Add, DeleteOutline, Edit } from '@material-ui/icons';

import { Button, IconButton, Tooltip } from '@material-ui/core';
import { columnsType } from 'Business/plant/constants';
import Section from 'Common/widgets/Section/Section';
import SortedDragList from 'Business/plant/pages/AdministrationPage/components/SortedDragList';

import PositionModal from './PositionModal';
import { ReactComponent as SelectArrow } from '../assets/arrow-with-scribble.svg';

import useCommonStyles from '../styles';
import useStyles from './styles';

const PositionsInSelectColumn = ({
  activateNextColumn,
  index,
  setColumnValue,
  defValue,
  gridWidth,
}) => {
  const { t } = useTranslation();
  const commonStyles = useCommonStyles();
  const classes = useStyles();
  const [editablePosition, setPosition] = useState(null);
  const [positionModalMode, setPositionModalMode] = useState('create');
  const [isOpenPositionModal, setPositionModalState] = useState(false);
  const setPositions = newValue => setColumnValue(newValue, columnsType.DIRECTORIES);

  const handleAddPosition = () => {
    setPositionModalMode('create');
    setPositionModalState(true);
  };

  const handleSavePosition = (newPosition) => {
    if (positionModalMode === 'edit') {
      // eslint-disable-next-line no-shadow
      return setPositions(defValue.map((position, index) => {
        if (position.id === newPosition.id) {
          return { ...newPosition, order: index };
        }

        return { ...position, order: index };
      }));
    }

    const newPositions = [...defValue, newPosition];
    setPositions(newPositions);

    // activate Next Column if we create first position
    if (newPositions.length === 1) {
      activateNextColumn(newPositions, columnsType.OPERATION_KEYS, index);
    }
  };

  const handleEditPosition = (position) => {
    setPositionModalMode('edit');
    setPositionModalState(true);
    setPosition(position);
  };

  const handleDeletePosition = (position) => {
    const newPositionValue = defValue.filter(item => item.id !== position.id);
    setPositions(newPositionValue);

    // remove last column if our positions is empty
    if (newPositionValue.length === 0) {
      activateNextColumn(newPositionValue, columnsType.DIRECTORIES, index - 1);
    }
  };

  return (
    <Section title={t('POSITIONS_IN_THE_DICTIONARY')} gridWidth={gridWidth} customClass={commonStyles.sectionRoot}>
      <div className={commonStyles.selectArrow}>
        <SelectArrow />
        <div className={commonStyles.arrowText}>{t('ADD_ONE_OR_MORE_POSITIONS')}</div>
      </div>
      <div className={classes.wrapper}>
        <SortedDragList
          list={defValue}
          handleUpdateList={setPositions}
          itemTemplate={item => (
            <div>
              <div>{item.name}</div>
              <div>{`(${item.code})`}</div>
            </div>
          )}
          renderRightControls={item => (
            <div>
              <Tooltip title={t('EDIT_POSITION')}>
                <IconButton
                  size="small"
                  aria-label="settings"
                  className={classes.editParameterIcon}
                  onClick={() => handleEditPosition(item)}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('DELETE_POSITION')}>
                <IconButton
                  size="small"
                  aria-label="settings"
                  className={classes.removeParameterIcon}
                  onClick={() => handleDeletePosition(item)}
                >
                  <DeleteOutline />
                </IconButton>
              </Tooltip>
            </div>
          )}
        />
        <Button
          variant="outlined"
          color="primary"
          className={classes.addCheckpointButton}
          onClick={handleAddPosition}
          fullWidth
          startIcon={<Add />}
        >
          {t('ADD_POSITION')}
        </Button>
        <PositionModal
          mode={positionModalMode}
          position={editablePosition}
          title={positionModalMode ? t('ADDING_POSITIONS') : t('EDITING_POSITIONS')}
          isOpen={isOpenPositionModal}
          setPositionModalState={setPositionModalState}
          handleSavePosition={handleSavePosition}
        />
      </div>
    </Section>
  );
};

PositionsInSelectColumn.propTypes = {
  activateNextColumn: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  setColumnValue: PropTypes.func.isRequired,
  defValue: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string.isRequired,
    order: PropTypes.number,
  })).isRequired,
  gridWidth: PropTypes.number.isRequired,
};

export default PositionsInSelectColumn;
