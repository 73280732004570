import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import useStores from 'Store/useStores';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: 0,
    paddingLeft: ({ isDrawerOpened }) => (isDrawerOpened
      ? (`calc(${theme.spacing(3)}px + ${theme.drawerOpenedWidth}px)`)
      : (`calc(${theme.spacing(3)}px + ${theme.drawerClosedWidth}px)`)),
    transition: theme.transitions.create('padding-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const Content = ({ children }) => {
  const {
    drawerStore: {
      isDrawerOpened
    }
  } = useStores();
  const classes = useStyles({ isDrawerOpened });

  return (
    <Container className={classes.container} maxWidth="xl">
      { children }
    </Container>
  );
};

Content.propTypes = {
  children: PropTypes.node.isRequired,
};

export default observer(Content);
