import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  userImage: {
    width: 68,
    height: 68,
    position: 'relative',
    marginTop: 4,
    zIndex: 2,
    '& img': {
      width: 68,
      height: 68,
      borderRadius: '50%',
      border: ({ disabled, isUserInSystem }) => {
        if (!isUserInSystem) return `2px solid ${theme.palette.black30}`;
        if (disabled) return `2px solid ${theme.palette.negative}`;
        return `2px solid ${theme.palette.primaryLight}`;
      },
    },
  },
  placeholder: {
    width: 68,
    height: 68,
    borderRadius: '50%',
    border: ({ disabled, isUserInSystem }) => {
      if (!isUserInSystem) return `2px solid ${theme.palette.black30}`;
      if (disabled) return `2px solid ${theme.palette.negative}`;
      return `2px solid ${theme.palette.primaryLight}`;
    },
  },
  userImageIcon: {
    position: 'absolute',
    width: 20,
    height: 20,
    zIndex: 3,
    bottom: 0,
    right: 0,
    backgroundColor: theme.palette.white,
    color: ({ disabled }) => (disabled ? theme.palette.negative : theme.palette.primaryLight),
    border: `2px solid ${theme.palette.white}`,
    borderRadius: '50%',
  },
}));

export default useStyles;
