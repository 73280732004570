import DictionaryAgent from 'ApiAgents/Dictionary/DictionaryAgent';
import {
  action,
  observable,
  runInAction,
  makeObservable
} from 'mobx';
import dictionaries from 'Shared/constants/dictionaries';

const dictionaryAgent = new DictionaryAgent();


export class DictionaryStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable isLoading = false;

  @observable isLoaded = false;

  @observable loadedDictionaries = {};

  @action loadDictionaryByKey = async (key) => {
    if (!dictionaries[key]) {
      throw new Error('The dictionary key is not valid.');
    }

    if (this.loadedDictionaries[key]) return;

    this.isLoading = true;

    try {
      const dictionary = await dictionaryAgent.loadDictionaryByKey(key);

      runInAction(() => {
        this.loadedDictionaries[key] = this.transform(dictionary);
        this.isLoaded = true;
      });
    } catch (error) {
      console.log('ERROR IN DICTIONARY BY KEY: ', error);
    }

    runInAction(() => {
      this.isLoading = false;
    });
  };

  transform(dictionary) {
    const transformed = {};
    dictionary.forEach(({ enumValue, value }) => {
      transformed[enumValue] = value;
    });

    return transformed;
  }
}

export default DictionaryStore;
