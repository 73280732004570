import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primaryLight,
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

export default useStyles;
