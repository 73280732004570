import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  user: {
    position: 'relative',
    padding: 16,
    borderRadius: '4px',
    boxShadow: `0 2px 4px 0 ${theme.palette.black20}`,
    border: `1px solid ${theme.palette.midGray}`,
    '&::after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 40,
      height: '100%',
      zIndex: 1,
      backgroundColor: ({ isUserActive, isUserInSystem }) => {
        if (!isUserInSystem) return theme.palette.midGray;
        if (!isUserActive) return theme.palette.pink;
        return theme.palette.lightSelection;
      },
    },
    '&:hover': {
      boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.16), 0 4px 8px 0 rgba(0, 0, 0, 0.16)',
      '& $userNameLink': {
        textDecoration: 'underline',
      },
    },
  },
  headerTitleText: {
    fontSize: 16,
  },
  userInfo: {
    marginLeft: 68,
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% -32px - 68px)',
    paddingLeft: 16,
  },
  userImageWrapper: {
    position: 'absolute',
    top: 'calc(50% - 36px)',
    left: 16,
  },
  userBaseInfo: {
    display: 'flex',
  },
  userRole: {
    fontSize: '16px',
    lineHeight: 1.5,
    color: theme.palette.black,
  },
  userName: {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: 1.5,
  },
  positionName: {
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.midBlack,
  },
  workDetails: {
    '& div': {
      fontSize: '12px',
      lineHeight: '16px',
      color: theme.palette.black30,
    },
  },
  rightPartWrapper: {
    height: 24,
    resize: 'none',
    whiteSpace: 'nowrap',
    maxWidth: '50%',
    marginLeft: 'auto',
    paddingLeft: 8,
  },
  addUserButton: {
    minWidth: 190,
    padding: '0 auto',
    minHeight: 40,
    maxHeight: 40,
    fontSize: 16,
    fontWeight: 'normal',
    boxShadow: 'none',
    borderColor: theme.palette.black20,
  },
  userNameLink: {
    display: 'grid',
    fontSize: 16,
    fontWeight: 'bold',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export default useStyles;
