import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { Tooltip, SvgIcon } from '@material-ui/core';
import {
  CloseOutlined,
  PauseOutlined,
} from '@material-ui/icons';
import OverflowTip from 'Common/widgets/OverflowTip';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const OperationRejection = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { rejectionType, reason } = props;
  let iconColorClass;
  let iconComponent;
  let iconTooltipTitle = '';
  switch (rejectionType) {
    case 'REJECTED':
      iconColorClass = classes.redColor;
      iconComponent = CloseOutlined;
      iconTooltipTitle = t('OPERATION_REJECTED');
      break;
    case 'ON_HOLD':
      iconColorClass = classes.orangeColor;
      iconComponent = PauseOutlined;
      iconTooltipTitle = t('OPERATION_ON_PAUSE');
      break;
    default:
      iconColorClass = classes.redColor;
      iconComponent = CloseOutlined;
      iconTooltipTitle = t('OPERATION_REJECTED');
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title={iconTooltipTitle}>
        <SvgIcon
          className={clsx(classes.rejectionIcon, iconColorClass)}
          component={iconComponent}
        />
      </Tooltip>
      <OverflowTip tooltipTitle={reason} text={reason} data-test="reason" />
    </div>
  );
};

OperationRejection.defaultProps = {
  reason: '',
};

OperationRejection.propTypes = {
  rejectionType: PropTypes.string.isRequired,
  reason: PropTypes.string,
};

export default observer(OperationRejection);
