const taskTabsConfig = (t, taskDefects = []) => {
  const defectsCountLabel = taskDefects.length ? ` (${taskDefects.length})` : '';
  return [
    {
      title: t('INFORMATION'),
      alias: 'attributes',
    },
    {
      title: `${t('IDENTIFIED_DEFECTS')}${defectsCountLabel}`,
      alias: 'defects',
    },
  ];
};

export default taskTabsConfig;
