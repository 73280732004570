import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  searchIcon: {
    color: theme.palette.black30,
  },
  searchInput: {
    width: '30vw',
    marginBottom: 8,
    marginLeft: 8,
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 4,
    },
    '& $inputClearButton': {
      display: 'none',
    },
    '&:hover': {
      '& $inputClearButton': {
        display: 'block',
      },
    },
    '& .MuiInputLabel-shrink': {
      display: 'none',
    },
    '& fieldset legend span': {
      display: 'none',
    },
    '& input': {
      '&::placeholder': {
        color: theme.palette.black30,
        opacity: 1
      },
      '&::-webkit-input-placeholder': {
        color: theme.palette.black30,
        opacity: 1
      },
      '&::-moz-placeholder': {
        color: theme.palette.black30,
        opacity: 1
      },
      '&:-moz-placeholder': {
        color: theme.palette.black30,
        opacity: 1
      },
      '&:-ms-input-placeholder': {
        color: theme.palette.black30,
        opacity: 1
      },
    },
    '& .Mui-focused': {
      '& $inputClearButton': {
        display: 'block',
      },
    },
  },
  inputClearButton: {
    color: theme.palette.primaryLight,
    borderRadius: '50%',
    padding: 4,
    width: 28,
    height: 28,
    '&:hover': {
      backgroundColor: theme.palette.lightSelection
    },
    cursor: 'pointer',
  },
  powerUnitInputWrapper: {
    width: 232,
    zIndex: 12,
    marginTop: 16,
    marginLeft: 16,
    marginRight: 12,
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
