import Agent from 'ApiAgents/Agent';
import qs from 'qs';
import getPaginationQueryObject from 'Src/utils/getPaginationQueryObject';


export default class TaskRouteAgent extends Agent {
  getItemsBy = (
    params = {},
    shouldPaginate,
    pageNumber,
    pageSize
  ) => {
    const pageQuery = getPaginationQueryObject(shouldPaginate, pageNumber, pageSize);

    return this.request({
      url: '/route/view',
      method: 'GET',
      params: { ...params, ...pageQuery },
      paramsSerializer: parameters => qs.stringify(parameters)
    }, 'tasks');
  };

  getItemById = id => this.request({
    url: '/route/view/byId',
    method: 'GET',
    params: { id },
  }, 'tasks');

  createItem = data => this.request({
    url: '/route/manage/create',
    method: 'POST',
    data
  }, 'tasks');

  updateItem = data => this.request({
    url: '/route/manage/update',
    method: 'PUT',
    data
  }, 'tasks');

  deleteItem = id => this.request({
    url: '/route/manage/delete',
    method: 'DELETE',
    params: { id },
  }, 'tasks');

  createCoalRoute = data => this.request({
    url: '/coalRoutes',
    method: 'POST',
    data
  }, 'techObject');

  loadCoalRoutesList = (shouldPaginate, pageNumber, pageSize) => {
    const pageQuery = getPaginationQueryObject(shouldPaginate, pageNumber, pageSize);
    return this.request({
      url: '/coalRoutes',
      method: 'GET',
      params: { ...pageQuery },
      paramsSerializer: parameters => qs.stringify(parameters)
    }, 'techObject');
  };

  loadCoalRouteById = id => this.request({
    url: '/coalRoutes',
    method: 'GET',
    params: { id },
    paramsSerializer: parameters => qs.stringify(parameters)
  }, 'techObject');

  deleteCoalRoute = data => this.request({
    url: '/coalRoutes',
    method: 'DELETE',
    data
  }, 'techObject');
}
