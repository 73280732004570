import React, { useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { get } from 'lodash';

import useStores from 'Store/useStores';

const withValidation = (WrappedComponent) => {
  const ExtendedInputComponent = forwardRef((props, ref) => {
    const {
      validationStore: {
        addFieldToValidationState,
        validationState,
        onFieldUpdate,
        setHelperText,
        setFieldValid,
      },
    } = useStores();

    const {
      name,
      value,
      error,
      validationError,
      required,
      onChange,
      helperText,
      isReadonly,
      validationFunction,
    } = props;

    useEffect(() => {
      addFieldToValidationState({
        name,
        value,
        validationError,
        required,
        error,
        isReadonly,
        validationFunction,
      });
    }, [required, error, validationError, isReadonly]);

    useEffect(() => {
      onFieldUpdate(name, value);
      if (!error) setFieldValid(name);
    }, [value]);

    useEffect(() => {
      setHelperText(name, helperText);
      if (!helperText && !error) setFieldValid(name);
    }, [helperText]);

    const handleChange = (currentValue, fieldName) => {
      if (onChange) onChange(currentValue, fieldName);
    };

    const isValid = get(validationState, `${name}.isValid`, true);
    const validationText = get(validationState, `${name}.validationText`, '');

    return <WrappedComponent ref={ref} {...props} onChange={handleChange} error={error || !isValid} helperText={helperText || validationText} />;
  });

  ExtendedInputComponent.defaultProps = {
    value: '',
    required: false,
    isReadonly: false,
    error: false,
    validationError: false,
    validationFunction: undefined,
    helperText: undefined,
    onChange: () => {},
  };

  ExtendedInputComponent.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
      PropTypes.array,
      PropTypes.any,
    ]),
    required: PropTypes.bool,
    isReadonly: PropTypes.bool,
    error: PropTypes.bool,
    validationError: PropTypes.bool,
    validationFunction: PropTypes.func,
    helperText: PropTypes.string,
    onChange: PropTypes.func,
  };

  return observer(ExtendedInputComponent);
};

export default withValidation;
