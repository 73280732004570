import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import { IconButton, Tooltip, List } from '@material-ui/core';
import { DeleteOutline, PeopleAltOutlined, Edit } from '@material-ui/icons';
import EmployeeListItem from 'Business/coal/components/EmployeeListItem';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const AssignmentsWidget = ({
  title = '',
  assignments = [],
  currentAssignee,
  onDelete,
  onEdit,
  isReadonly,
  required,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ t });
  const showIsRequiredLabel = required && !isReadonly;

  return (
    <div className={clsx(classes.root, showIsRequiredLabel && classes.required)}>
      <div className={classes.widgetHeader}>
        <span className={classes.widgetTitle}>{title}</span>
        <div className={classes.widgetButtons}>
          {!isEmpty(assignments) && (
            <Tooltip
              title={(
                <div className={classes.assignmentsBlockTooltip}>
                  {assignments.map((assignee = {}) => {
                    const {
                      id, fullName
                    } = assignee;
                    return (
                      <div
                        key={id}
                        data-test="assignmentFullName"
                      >
                        {fullName}
                      </div>
                    );
                  })}
                </div>
              )}
            >
              <div className={classes.assignmentsBlock}>
                <PeopleAltOutlined />
                <span className={classes.assignmentsCountLabel}>{assignments.length}</span>
              </div>
            </Tooltip>
          )}
          {!!onEdit && !isReadonly && (
            <Tooltip title={t('EDIT')}>
              <IconButton
                className={classes.editIconButton}
                onClick={onEdit}
                data-test="editAssignments"
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
          {!!onDelete && !isReadonly && (
            <Tooltip title={t('DELETE')}>
              <IconButton
                className={classes.deleteIconButton}
                onClick={onDelete}
                data-test="deleteAssignments"
              >
                <DeleteOutline />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      <List>
        <EmployeeListItem
          employee={currentAssignee}
          renderText={({ fullName }) => fullName}
          secondaryTitle={t('RESPONSIBLE')}
          isBrigadier
          noActions
        />
      </List>
    </div>
  );
};

AssignmentsWidget.propTypes = {
  title: PropTypes.string.isRequired,
  assignments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    surName: PropTypes.string,
  })),
  currentAssignee: PropTypes.shape({
    id: PropTypes.string,
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    surName: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  isReadonly: PropTypes.bool,
  required: PropTypes.bool,
};

AssignmentsWidget.defaultProps = {
  onDelete: null,
  onEdit: null,
  isReadonly: false,
  required: false,
  assignments: []
};

export default AssignmentsWidget;
