import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import {
  Switch,
  Route,
  useHistory,
  Redirect,
  withRouter,
  useLocation
} from 'react-router-dom';

import { withTheme } from '@material-ui/core/styles';
import { isEmpty, get } from 'lodash';
import { isBefore } from 'date-fns';

import { VIEW_TASK, CONTROL_TASK, CREATE_TASK } from 'Shared/constants/routes';
import {
  HEAD_MECHANIC,
} from 'Shared/constants/roles';
import useStores from 'Store/useStores';
import { Box } from '@material-ui/core';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import FilterButton from 'Common/widgets/FilterButton/';
import FilterPanel from 'Common/widgets/FilterPanel/';
import useCurrentListStatus from 'Src/hooks/useCurrentListStatus';
import useManageEntityModal from 'Common/components/Modal/ManageEntityModal/useManageEntityModal';
import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import { taskStatusesConfig } from 'Business/coal/config/taskStatusesConfig';
import { statusesColumns } from 'Business/coal/config/taskListTableConfig';
import DataTable from 'Common/components/DataTable/DataTable';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import DottedLoader from 'Common/components/Progress/components/Dotted';
import ListTabs from 'Common/widgets/Tabs/ListTabs';
import Header from 'Common/widgets/Layout/Header/Header';
import Input from 'Common/components/Forms/Input/Input';
import { INITIAL_PAGE_NUMBER } from 'Shared/constants/paging';
import RadioInput from 'Common/components/Forms/Input/Radio';
import NoResultsBlock from 'Common/widgets/NoResultsBlock';

import useStyles from './styles';

const TaskListPage = ({ match, theme }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const translatedTaskStatusesConfig = taskStatusesConfig(t);

  const {
    userStore: { role },
    coalTaskListStore: {
      loadTasksList,
      totalPages,
      isLoadingByPage,
      hasNextPage,
      hasPreviousPage,
      isTaskListLoaded,
      taskList,
      deleteTaskFromList,
      isLoading,
      pageIndex,
    },
    coalTaskListFilterStore: {
      loadInitialFilters,
      onChangeTaskType,
      onChangeDepartment,
      onChangeEquipment,
      onChangeEmployees,
      departmentsDictionary,
      equipmentDictionary,
      employeesDictionary,
      equipmentDictionaryIsLoading,
      saveRequestedFilters,
      discardState,
      isFiltersSet,
      hasDiff,
      discardSelectedFilters,
      selectedState: {
        departmentId,
        taskType,
        technicalPlaceId,
        assignments,
      }
    },
    notificationStore: {
      enqueueSnackbar,
    },
    userStore: {
      auth: { id: currentUserId } = {},
    }
  } = useStores();

  const {
    setManageEntityModalVisibility, setOnManageEntityModalCloseCallback, setManageEntityModalState, renderManageEntityModal
  } = useManageEntityModal();

  const statusAliases = Object.keys(translatedTaskStatusesConfig).map(alias => ({
    key: alias,
    ...translatedTaskStatusesConfig[alias]
  }));

  const location = useLocation();
  const { pathname, state } = location;
  const withFilter = get(state, 'withFilter');
  const history = useHistory();

  const [filterOpened, setFilterOpened] = useState(false);

  const currentStatus = useCurrentListStatus('tasks');
  const currentStatusConfig = translatedTaskStatusesConfig[currentStatus.toUpperCase()];

  const showTable = isLoading || (!isEmpty(taskList) && isTaskListLoaded);
  const showNoResultsBlock = isEmpty(taskList) && isTaskListLoaded && isFiltersSet;
  const goToInitialTab = () => history.replace(`/tasks/${translatedTaskStatusesConfig.ACTUAL.name}`);

  const onRowClickHandler = (event, row) => {
    history.push({
      pathname: `${currentStatus === 'control' ? CONTROL_TASK : VIEW_TASK}/${row.taskId}`,
      state: { withFilter: true, fromPath: pathname },
    });
  };

  const deleteTask = id => () => {
    deleteTaskFromList(id)
      .then(() => {
        enqueueSnackbar({
          messageTemplate: {
            rows: [{
              rowContent: [{
                type: 'text',
                text: t('TASK_SUCCESSFULLY_DELETED')
              }]
            }],
          },
          variant: 'success',
        });
      })
      .catch(() => {
        enqueueSnackbar({
          messageTemplate: {
            rows: [{
              rowContent: [{
                type: 'text',
                text: t('REQUEST_DEFAULT_ERROR')
              }]
            }],
          },
          variant: 'error',
        });
      });
  };

  const showDeleteTaskPrompt = (event, {
    taskId, title,
  }) => {
    setManageEntityModalVisibility(true);
    setManageEntityModalState({
      title: t('DELETING_A_TASK'),
      entityName: `${t('REPAIR_TASK')} ${title}`,
      message: t('ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_TASK'),
      type: 'action',
      actionButtonTitle: t('DELETE'),
    });
    setOnManageEntityModalCloseCallback(() => deleteTask(taskId));
  };

  const getStatusQuery = () => {
    if (!currentStatus || !currentStatusConfig) return [];

    const { queryByRole = {} } = currentStatusConfig;
    if (queryByRole[role]) return queryByRole[role];
    const { query } = currentStatusConfig;
    return query;
  };

  const getStatusDateLimits = () => {
    if (!currentStatus || !currentStatusConfig) return null;
    const { dateLimits = {} } = currentStatusConfig;
    const dateLimitsByCurrentRole = get(dateLimits, role);
    return dateLimitsByCurrentRole;
  };

  const loadPaginatedTasks = (pageNumber, perPage) => {
    loadTasksList(getStatusQuery(), pageNumber, perPage, getStatusDateLimits()).catch(() => {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('REQUEST_LOAD_LIST_ERROR')
            }]
          }],
        },
        variant: 'error',
      });
    });
  };

  const handleFiltersApply = () => {
    saveRequestedFilters();
    loadPaginatedTasks(INITIAL_PAGE_NUMBER);
  };

  useEffect(() => {
    if (currentStatus === '') {
      goToInitialTab();
      return;
    }
    loadPaginatedTasks(INITIAL_PAGE_NUMBER);
  }, [currentStatus]);

  useEffect(() => {
    if (withFilter && !isEmpty(taskList)) {
      history.replace({
        state: {},
      });
      return;
    }
    discardState();
    loadInitialFilters().catch(() => {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('REQUEST_DEFAULT_ERROR')
            }]
          }],
        },
        variant: 'error',
      });
    });
  }, []);

  if (!currentStatus) return null;

  const handleClickFilterCollapseBtn = () => {
    setFilterOpened(!filterOpened);
  };

  const goToTaskCreation = () => {
    history.push(CREATE_TASK);
  };

  const taskTypes = [
    { value: 'all', title: t('ALL_TASKS'), enumValue: 'all' },
    { value: 'standard', title: t('STANDARD_MULTIPLE'), enumValue: 'standard' },
    { value: 'notStandard', title: t('NOT_STANDARD_MULTIPLE'), enumValue: 'notStandard' }
  ];

  const translatedTableColumns = statusesColumns(t);

  const transform = data => data.map((item) => {
    const {
      expectedStartDate,
      updatedBy,
    } = item;
    const alreadyInWork = isBefore(new Date(new Date(expectedStartDate).setHours(0, 0)), new Date());
    const createdByCurrentUser = currentUserId === updatedBy;
    const disabled = alreadyInWork || !createdByCurrentUser;

    return {
      ...item,
      actions: {
        remove: {
          disabled,
        }
      }
    };
  });

  const tableBodyHeight = `calc(100vh - ${theme.headerHeight}px - ${theme.pageHeaderHeight}px - ${theme.pageTabsHeight}px - ${theme.tableFooterHeight}px - ${theme.mainContentPaddingTop}px - ${theme.mainContentPaddingBottom}px)`;

  return (
    <PaperLayout>
      {isLoadingByPage && <ProgressBar />}
      <Header
        title={t('TASKS')}
      >
        <HeaderButton
          title={t('CREATE_A_TASK')}
          type="add"
          handleClick={goToTaskCreation}
        />
        <FilterButton
          hasActiveFilters={isFiltersSet}
          onClickFilterButton={handleClickFilterCollapseBtn}
          filterOpened={filterOpened}
        />
      </Header>
      <Switch>
        {statusAliases.map(({ key, name }) => (
          <Route
            key={name}
            path={`${match.path}/${name}`}
            render={props => ((isTaskListLoaded) ? (
              <div style={{ position: 'relative' }}>
                {renderManageEntityModal()}
                <ListTabs
                  role={role}
                  shouldCheckRole
                  matchUrl={match.url}
                  statusConfig={statusAliases}
                  value={currentStatus}
                />
                {showTable && (
                  <DataTable
                    isDataLoaded={isTaskListLoaded}
                    {...props}
                    pageIndex={pageIndex}
                    tableBodyHeight={tableBodyHeight}
                    titleSingle={t('TASK_ACCUSATIVE')}
                    columns={translatedTableColumns[key].columns}
                    data={transform(taskList)}
                    onRowClick={onRowClickHandler}
                    onRemove={showDeleteTaskPrompt}
                    count={totalPages}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    onPageChange={loadPaginatedTasks}
                    onPerPageChange={loadPaginatedTasks}
                    actions={translatedTableColumns[key].actions}
                    dataTestRowSuffixEntityName="taskId"
                  />
                )}
                {showNoResultsBlock && (
                  <Box css={{ marginTop: 112 }}>
                    <NoResultsBlock />
                  </Box>
                )}
                <FilterPanel
                  filterOpened={filterOpened}
                  filterApplyBtnLabel={t('APPLY')}
                  headerLabel={t('FILTRATION')}
                  headerClearBtnLabel={t('CLEAR')}
                  hasDiff={hasDiff}
                  hasActiveFilters={isFiltersSet}
                  handleClearFilters={() => {
                    discardSelectedFilters();
                    if (isFiltersSet) loadPaginatedTasks(INITIAL_PAGE_NUMBER);
                  }}
                  setFilters={handleFiltersApply}
                >
                  <RadioInput
                    className={classes.taskType}
                    value={taskType}
                    name="taskType"
                    onChange={onChangeTaskType}
                    options={taskTypes}
                    data-test="taskType"
                  />
                  {role === HEAD_MECHANIC && (
                    <Input
                      fullOutlined
                      label={t('DEPARTMENT')}
                      placeholder={t('SELECT_VALUE')}
                      value={departmentId}
                      onChange={onChangeDepartment}
                      name="departmentCode"
                      type="selectAlpha"
                      options={departmentsDictionary}
                      optionValueKey="id"
                      optionTitleKey="name"
                    />
                  )}
                  <DottedLoader
                    isLoading={equipmentDictionaryIsLoading}
                  >
                    <Input
                      listBoxMaxHeight={300}
                      disabled={isEmpty(equipmentDictionary)}
                      withSearch
                      fullOutlined
                      iconRight="search"
                      placeholder={t('FILTER_BY_EQUIPMENT')}
                      label={t('EQUIPMENT')}
                      value={technicalPlaceId}
                      name="technicalObjectId"
                      type="selectAlpha"
                      onChange={onChangeEquipment}
                      options={equipmentDictionary}
                      optionValueKey="technicalObjectId"
                      optionTitleKey="title"
                    />
                  </DottedLoader>
                  <Input
                    fullOutlined
                    label={t('PERFORMERS')}
                    name="operations"
                    type="tags"
                    value={assignments}
                    onChange={onChangeEmployees}
                    options={employeesDictionary}
                    optionValueKey="id"
                    optionTitleKey="fullName"
                  />
                </FilterPanel>
              </div>
            ) : <ProgressBar />)}
          />
        ))}
        <Redirect to={`/tasks/${translatedTaskStatusesConfig.ACTUAL.name}`} />
      </Switch>
    </PaperLayout>
  );
};

TaskListPage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.shape({
    headerHeight: PropTypes.number,
    pageHeaderHeight: PropTypes.number,
    pageTabsHeight: PropTypes.number,
    tableHeaderHeight: PropTypes.number,
    tableFooterHeight: PropTypes.number,
    mainContentPaddingTop: PropTypes.number,
    mainContentPaddingBottom: PropTypes.number,
  }).isRequired,
};

export default withRouter(withTheme(observer(TaskListPage)));
