import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { DeleteOutline, PlayCircleOutlineOutlined, PauseCircleOutlineOutlined } from '@material-ui/icons';
import clsx from 'clsx';

import useStyles from './styles';

const PlannerTaskActionButtons = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    status,
    onDelete,
    onPause,
    onStart,
    isUpdatingReadyStatus,
    isUpdatingOnPauseStatus,
  } = props;

  return (
    <div className={clsx('customActionButtons', classes.actionButtonsWrapper)}>
      <Tooltip title={t('DELETE')}>
        <IconButton
          className={classes.remove}
          disabled={false}
          size="medium"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDelete();
          }}
        >
          <DeleteOutline />
        </IconButton>
      </Tooltip>
      {isUpdatingOnPauseStatus
        ? (
          <CircularProgress
            size={18}
            thickness={4}
            color="inherit"
          />
        )
        : (
          <Tooltip title={t('SET_ON_PAUSE')}>
            <IconButton
              className={classes.pause}
              disabled={status !== 'Ready'}
              size="medium"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onPause();
              }}
            >
              <PauseCircleOutlineOutlined />
            </IconButton>
          </Tooltip>
        )
      }
      {isUpdatingReadyStatus
        ? (
          <CircularProgress
            size={18}
            thickness={4}
            className={classes.circularProgress}
          />
        )
        : (
          <Tooltip title={t('RUN')}>
            <IconButton
              className={classes.activate}
              disabled={status !== 'OnPause'}
              size="medium"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onStart();
              }}
            >
              <PlayCircleOutlineOutlined />
            </IconButton>
          </Tooltip>
        )
      }
    </div>
  );
};

PlannerTaskActionButtons.propTypes = {
  status: PropTypes.oneOf(['ready', 'pause', 'done']).isRequired,
  onDelete: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  isUpdatingReadyStatus: PropTypes.bool.isRequired,
  isUpdatingOnPauseStatus: PropTypes.bool.isRequired,
};

export default PlannerTaskActionButtons;
