import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import { formatDate, formatTime } from 'Src/utils/datetime';
import { Divider } from '@material-ui/core';

import useStyles from './styles';


const CommentsBlock = ({ comments = [] }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div>
      {comments.map(({
        comment, authorName, authorPosition, dateTime
      } = {}, index) => {
        const commentKey = `${comment}_${index}`;
        return (
          <Fragment key={commentKey}>
            {index > 0 && (
              <Divider className={classes.divider} />
            )}
            <div data-test={`comment_${index}`} className={classes.commentHeader}>
              <div className={classes.avatarWrapper}>
                <Avatar
                  src=""
                  className={classes.avatar}
                  data-test="avatar"
                />
              </div>
              <div className={classes.author} data-test="author">
                <div className={classes.authorLabel} data-test="authorName">{authorName}</div>
                <div className={classes.authorPosition} data-test="authorPosition">{authorPosition}</div>
              </div>
              <div className={classes.dateTime} data-test="dateTime">
                {dateTime ? `${formatDate(dateTime)}  ${formatTime(dateTime)}` : t('INPUT_EMPTY_VALUE')}
              </div>
            </div>
            <div className={classes.comment} data-test="comment">{comment}</div>
          </Fragment>
        );
      })}
    </div>
  );
};

CommentsBlock.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.shape({
    comment: PropTypes.string,
    authorName: PropTypes.string,
    authorPosition: PropTypes.string,
    dateTime: PropTypes.string,
  })),
};

CommentsBlock.defaultProps = {
  comments: [],
};

export default CommentsBlock;
