import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  equipmentSapCodeWrapper: {
    marginLeft: ({ level }) => (16 * level),
    paddingTop: 4,
    paddingBottom: 4,
  },
  equipmentSapCodeValueLabel: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: theme.palette.black,
  },
  equipmentSapCodeValueSubLabel: {
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    color: theme.palette.black30,
  },
}));

export default useStyles;
