import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  sortableItem: {
    display: 'flex',
    width: '100%',
    borderTop: `1px solid ${theme.palette.midGray}`,
    '&:nth-child(2n)': {
      backgroundColor: theme.palette.white,
    },
    '&:nth-child(2n+1)': {
      backgroundColor: theme.palette.default,
    },
    '&::after': {
      position: 'absolute',
      content: '""',
      display: 'block',
      top: '100%',
      left: 0,
      right: 0,
      height: 1,
      background: theme.palette.midGray,
    },
  },
  hovered: {
    '&:hover': {
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.16)',
      position: 'relative',
      zIndex: 1,
    },
  },
  dragIndicator: {
    width: 34,
    minWidth: 34,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.lightGray,
    display: 'flex',
    borderRight: `1px solid ${theme.palette.midGray}`
  },
  grabbing: {
    cursor: 'grabbing',
  },
  grab: {
    cursor: 'grab',
  },
  helperClass: {
    zIndex: theme.sortableItemZIndex,
    maxWidth: '100%',
    pointerEvents: 'none',
    borderBottom: `1px solid ${theme.palette.midGray}`,
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.16)',
  },
}));

export default useStyles;
