import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  addAssignmentsButtonWrapper: {
    marginTop: 14,
  },
  addAssignmentsButton: {
    opacity: ({ isForbiddenToAdd }) => (isForbiddenToAdd ? 0.5 : 1),
    boxShadow: 'none',
    minHeight: 80,
    marginBottom: theme.spacing(2),
    borderStyle: 'dashed !important',
    '&.MuiButtonBase-root': {
      borderColor: ({ error }) => (error ? theme.palette.negative : 'inherit'),
      color: ({ error, isForbiddenToAdd }) => {
        const color = isForbiddenToAdd ? theme.palette.black30 : theme.palette.primaryLight;
        return (error ? theme.palette.negative : color);
      },
    },
    fontSize: 16,
    fontWeight: 'normal',
    '&::after': {
      display: 'block',
      content: ({ t } = {}) => (`'${t('REQUIRED')}'`),
      position: 'absolute',
      height: '50%',
      background: theme.palette.white,
      top: 'calc(100% + 2px)',
      left: 16,
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.33,
      letterSpacing: 'normal',
      color: ({ error }) => (error ? theme.palette.negative : theme.palette.black30),
    },
  },
  addEquipmentIcon: {
    marginRight: theme.spacing(1)
  },
}));

export default useStyles;
