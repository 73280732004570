import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Settings, SettingsApplicationsOutlined, PhotoCameraOutlined, Check, CommentOutlined
} from '@material-ui/icons';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';

import useStyles from './styles';

const techObjectIcon = {
  TECHNICAL_PLACE: SettingsApplicationsOutlined,
  EQUIPMENT: Settings,
};

const TechObjectDetails = ({ techObject, isCheckpointDisabled }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    technicalObjectId,
    technicalObjectTitle,
    technicalObjectSapCode,
    checkpointDefects,
    checkpointMeasuringPoints,
    technicalObjectType,
    comment
  } = techObject;

  const TechObjectIcon = techObjectIcon[technicalObjectType];

  return (
    <div key={technicalObjectId} className={classes.techObjectContainer}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>
          <TechObjectIcon className={classes.techObjIcon} />
          {technicalObjectTitle}
        </div>
        <div className={classes.subTitle}>{technicalObjectSapCode}</div>
      </div>

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <div className={clsx(classes.title, classes.marginBottom16)}>
            {t('SCHEDULED_INSPECTIONS')}
          </div>
          {checkpointDefects.length > 0
            ? checkpointDefects.map(({
              defectId,
              defectTitle,
              defectCode,
              defectPhotoUrl,
              defectDetectionTime,
            }) => (
              <Grid
                container
                spacing={2}
                justify="space-between"
                key={defectId}
                className={classes.operation}
              >
                <Grid item xs={10} className={clsx(classes.title, classes.noPadding)}>
                  {defectCode}
                </Grid>
                {defectPhotoUrl
                  && (
                    <div>
                      <a href={defectPhotoUrl} className={classes.primaryLight}>
                        <PhotoCameraOutlined />
                      </a>
                    </div>
                  )}
                <Grid item xs={10} className={clsx(classes.subTitle, classes.noPadding)}>
                  {defectTitle}
                </Grid>
                <div className={classes.subTitle}>
                  {defectDetectionTime}
                </div>
              </Grid>
            ))
            : (
              <div className={classes.noOperations}>
                <Check className={classes.techObjIcon} />
                {t('NO_DEFECTS_FOUND')}
              </div>
            )
          }
        </Grid>

        <Grid item xs={6}>
          <div className={clsx(classes.title, classes.marginBottom16)}>
            {t('SCHEDULED_MEASUREMENTS')}
          </div>
          {checkpointMeasuringPoints.length > 0
            ? checkpointMeasuringPoints.map(({
              measuringPointId,
              measuringPointTitle,
              measuringTime,
              measuringValue,
              measuringFailureReasonComment
            }) => (
              <Grid
                container
                spacing={2}
                justify="space-between"
                key={measuringPointId}
                className={classes.operation}
              >
                <Grid
                  item
                  xs={12}
                  className={clsx(classes.title, classes.noPadding, {
                    [classes.lightTitle]: !measuringValue || !isCheckpointDisabled,
                  })}
                >
                  {measuringPointTitle}
                </Grid>

                <Grid item xs={10} className={clsx(classes.subTitle, classes.noPadding)}>
                  {!measuringValue
                    ? (<span>{t('MEASUREMENTS_IMPOSSIBLE')}</span>)
                    : measuringValue
                  }
                </Grid>
                {measuringTime && isCheckpointDisabled
                  && (
                    <div className={classes.subTitle}>
                      {measuringTime}
                    </div>
                  )}
                {measuringFailureReasonComment
                  && (
                    <div className={classes.comment}>
                      <CommentOutlined className={clsx(classes.positive, classes.techObjIcon)} />
                      <span className={classes.commentText}>
                        {measuringFailureReasonComment}
                      </span>
                    </div>
                  )}
              </Grid>
            ))
            : (
              <div className={classes.noOperations}>
                <Check className={classes.techObjIcon} />
                {t('NO_MEASUREMENTS_FOUND')}
              </div>
            )
          }
        </Grid>
      </Grid>

      {comment
      && (
        <>
          <div className={classes.title}>{t('GENERAL_COMMENTS')}</div>
          <div className={classes.comment}>
            <CommentOutlined className={clsx(classes.positive, classes.techObjIcon)} />
            <span className={classes.commentText}>{comment}</span>
          </div>
        </>
      )}
    </div>
  );
};

TechObjectDetails.propTypes = {
  techObject: PropTypes.shape({
    technicalObjectId: PropTypes.number.isRequired,
    technicalObjectTitle: PropTypes.string.isRequired,
    technicalObjectSapCode: PropTypes.string.isRequired,
    checkpointDefects: PropTypes.array.isRequired, // eslint-disable-line
    checkpointMeasuringPoints: PropTypes.array.isRequired, // eslint-disable-line
    technicalObjectType: PropTypes.string.isRequired,
    comment: PropTypes.string,
  }).isRequired,
  isCheckpointDisabled: PropTypes.bool.isRequired
};

export default TechObjectDetails;
