import React from 'react';
import PropTypes from 'prop-types';
import { PhotoCameraOutlined } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const handleClick = (e) => {
  e.stopPropagation();
};

const PhotoLink = ({ link }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  if (!link) return null;

  return (
    <Tooltip title={t('VIEW_A_PHOTO')}>
      <a
        target="_blank"
        onClick={handleClick}
        className={classes.link}
        href={link}
        rel="noreferrer"
      >
        <PhotoCameraOutlined className={`${classes.icon} defectsPhotoFolderLink`} />
      </a>
    </Tooltip>
  );
};

PhotoLink.defaultProps = {
  link: '',
};

PhotoLink.propTypes = {
  link: PropTypes.string,
};

export default PhotoLink;
