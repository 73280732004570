import Agent from 'ApiAgents/Agent';

export default class OperationKeysAgent extends Agent {
  getOperationKeys = () => (
    this.request({
      url: '/operationKeys',
      method: 'GET',
    }, 'tasks')
  );

  getOperationKeyById = id => (
    this.request({
      url: `/operationKeys/${id}`,
      method: 'GET',
    }, 'tasks')
  );

  updateOperationKey = body => this.request({
    url: '/operationKeys',
    method: 'PUT',
    data: body,
  }, 'tasks');

  addOperationKey = body => this.request({
    url: '/operationKeys',
    method: 'POST',
    data: body,
  }, 'tasks');
}
