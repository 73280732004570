import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 168
  },
  inputWrap: {
    marginLeft: 8
  },
  thumbPoint: {
    width: '16px !important',
    height: '16px !important',
    marginLeft: '-8px !important',
    // marginTop: '-7px !important',
  },
  input: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    fontSize: 16,
    fontWeight: 'normal',
    padding: '0px 2px 1px 2px',
    width: 38,
    '&.MuiInput-underline': {
      '&::before': {
        borderColor: theme.palette.black30,
      },
      '&:hover:not(.Mui-disabled)::before': {
        borderColor: theme.palette.primaryLight,
      }
    }
  },
  formControl: {
    marginTop: ({ marginTop }) => (marginTop || theme.spacing(1)),
    marginBottom: ({ marginBottom }) => (marginBottom || theme.spacing(3)),
  },
  formLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: '2px 0 20px 2px',
    color: theme.palette.black,
    '&.Mui-focused': {
      color: theme.palette.black,
    }
  },
}));

export default useStyles;
