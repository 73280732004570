import {
  action, observable, runInAction, makeObservable,
} from 'mobx';
import { get, filter, some } from 'lodash';

const initialSelectedState = {
  loadedRoute: null,
  name: '',
  isEditable: true,
  checkpoints: [],
};

const initialIntermediateState = {
  selectedTechPlaceId: '',
  selectedPowerUnitId: '',
  checkpoints: [],
};

export class TaskRouteManageStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable powerUnits = [];

  @observable techPlaces = [];

  @observable checkPoints = [];

  @observable selectedState = initialSelectedState;

  @observable intermediateState = initialIntermediateState;

  @observable areTechPlacesLoading = false;

  storedTechPlacesByPowerUnits = {};

  preparePowerUnits = rawPowerUnits => (
    rawPowerUnits.map(({ technicalObjectId, title }) => ({
      id: technicalObjectId,
      title
    }))
  );

  prepareTechPlaces = rawTechObjects => (
    rawTechObjects.map(({ technicalObject: { technicalObjectId, title }, checkpoints }) => ({
      id: technicalObjectId,
      title,
      checkpoints
    }))
  );

  checkpointFilterCriteria = checkpointId => ({ id }) => id === checkpointId;

  @action setSelectedCheckpoints = (checkpoints) => {
    if (!Array.isArray(checkpoints)) {
      throw new TypeError("Argument provided to 'setSelectedCheckpoints()' is not array");
    }
    this.selectedState.checkpoints = checkpoints;
  };

  @action loadPowerUnits = async () => {
    try {
      const { children: tpsChildren } = await this.rootStore.taskRouteStore.getTpsWithPowerUnitsWithCheckPoints() || {};
      runInAction(() => {
        this.powerUnits = this.preparePowerUnits(tpsChildren);
      });
    } catch (error) {
      console.log('ERROR in FETCHING POWER UNITS: ', error);
      runInAction(() => {
        this.powerUnits = [];
      });
      throw new Error(error);
    }
  };

  @action prepareIntermediateState = () => {
    this.checkPoints = [];
    this.techPlaces = [];
    this.intermediateState.selectedTechPlaceId = '';
    this.intermediateState.selectedPowerUnitId = '';
    this.intermediateState.checkpoints = this.selectedState.checkpoints;
  }

  @action loadRouteById = async (routeId) => {
    try {
      const {
        checkpoints, name, isEditable
      } = await this.rootStore.taskRouteStore.getItem(routeId);
      runInAction(() => {
        this.selectedState = {
          name,
          isEditable,
          checkpoints,
        };
      });
    } catch (error) {
      console.log('ERROR in FETCHING ROUTE BY ID: ', error);
      runInAction(() => {
        this.selectedState = {
          name: '',
          isEditable: false,
          checkpoints: [],
        };
      });
      throw new Error(error);
    }
  };

  @action loadTechPlaces = async (powerUnitId = null) => {
    try {
      if (!powerUnitId) throw new Error('PowerUnitId is not provided.');
      const storedTechPlaces = get(this.storedTechPlacesByPowerUnits, powerUnitId);
      if (storedTechPlaces) {
        runInAction(() => {
          this.techPlaces = storedTechPlaces;
        });
      } else {
        this.areTechPlacesLoading = true;
        const techPlaces = await this.rootStore.taskRouteStore.getTechPlacesWithCheckPoints(powerUnitId);
        const preparedTechPlaces = this.prepareTechPlaces(techPlaces);
        this.storedTechPlacesByPowerUnits = { ...this.storedTechPlacesByPowerUnits, [powerUnitId]: preparedTechPlaces };
        runInAction(() => {
          this.techPlaces = preparedTechPlaces;
          this.areTechPlacesLoading = false;
        });
      }
    } catch (error) {
      console.log('ERROR in FETCHING TECHNICAL PLACES: ', error);
      runInAction(() => {
        this.techPlaces = [];
      });
    }
  };

  createRoute = async () => {
    await this.rootStore.taskRouteStore.create(this.buildFinalObject(null));
  };

  updateRoute = async (id = null) => {
    await this.rootStore.taskRouteStore.update(this.buildFinalObject(id), id);
  };

  @action handleChange = (value, name) => {
    this.selectedState[name] = value;
  };

  removeCheckpointFromSelected = checkPointId => action(() => {
    this.selectedState.checkpoints = this.selectedState.checkpoints.filter(({ id }) => id !== checkPointId);
  });

  @action discardState = () => {
    this.selectedState = initialSelectedState;
    this.intermediateState = initialIntermediateState;
    this.rootStore.taskRouteStore.discardState();
    this.areTechPlacesLoading = false;
    this.storedTechPlacesByPowerUnits = {};
  };

  @action cleanCheckpoints = () => {
    this.checkPoints = [];
  };

  @action cleanTechPlaces = () => {
    this.techPlaces = [];
  };

  handleSelectTechPlace = techPlaceId => action(() => {
    this.intermediateState.selectedTechPlaceId = techPlaceId;
    if (!techPlaceId) return;
    this.checkPoints = this.techPlaces.find(({ id }) => id === techPlaceId).checkpoints;
  });

  clearCheckpointsByTechnicalPlaceId = technicalPlaceId => action(() => {
    const { checkpoints = [] } = this.intermediateState;
    const filteredCheckpoints = filter(checkpoints, ({ technicalPlace: { id } = {} } = {}) => id !== technicalPlaceId);
    this.intermediateState.checkpoints = filteredCheckpoints;
  });

  handleSelectPowerUnit = powerUnitId => action(() => {
    this.intermediateState.selectedPowerUnitId = powerUnitId;
    this.cleanTechPlaces();
    this.cleanCheckpoints();
    this.loadTechPlaces(powerUnitId);
  });

  clearCheckpointsByPowerUnitId = powerUnitId => action(() => {
    const { checkpoints = [] } = this.intermediateState;
    const filteredCheckpoints = filter(checkpoints, ({ powerUnit: { id } = {} } = {}) => id !== powerUnitId);
    this.intermediateState.checkpoints = filteredCheckpoints;
  });

  isCheckpointInSelectedState = (checkPointId) => {
    const { checkpoints = [] } = this.intermediateState;
    return some(checkpoints, ({ id }) => checkPointId === id);
  };

  isPowerUnitInSelectedState = (powerUnitId) => {
    const { checkpoints = [] } = this.intermediateState;
    return some(checkpoints, ({ powerUnit: { id } = {} } = {}) => powerUnitId === id);
  }

  isTechPlaceInSelectedState = (techPlaceId) => {
    const { checkpoints = [] } = this.intermediateState;
    return some(checkpoints, ({ technicalPlace: { id } = {} } = {}) => techPlaceId === id);
  };

  handleToggleCheckpoint = checkPoint => action((e) => {
    e.stopPropagation();
    const { id: checkPointId } = checkPoint;
    if (this.isCheckpointInSelectedState(checkPointId)) {
      this.intermediateState.checkpoints = filter(this.intermediateState.checkpoints, ({ id }) => checkPointId !== id);
    } else {
      this.intermediateState.checkpoints = [checkPoint, ...this.intermediateState.checkpoints];
    }
  });

  @action confirmSelectedCheckpoints = () => {
    this.selectedState.checkpoints = this.intermediateState.checkpoints;
  };

  @action buildFinalObject = (id) => {
    const {
      name,
      checkpoints
    } = this.selectedState;
    const { rootOrgUnit: { code } } = this.rootStore.userStore;
    const checkpointIds = [];
    checkpoints.forEach(({ id: chpId }) => { checkpointIds.push(chpId); });

    return {
      ...id && { id },
      name,
      orgUnitCode: code,
      tpsId: this.rootStore.taskRouteStore.tpsId,
      checkpoints: checkpointIds.map((chpId, index) => ({
        checkpointId: chpId,
        order: index + 1
      }))
    };
  }
}

export default TaskRouteManageStore;
