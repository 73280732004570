import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useStores from 'Store/useStores';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import BrigadeListContainer from 'Src/business/tpsRepairs/containers/BrigadeListContainer/BrigadeListContainer';
import NoContent from 'Common/components/Content/components/NoContent/NoContent';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import HeaderLayout from 'Common/widgets/Layout/Header/Header';
import { CREATE_BRIGADE } from 'Shared/constants/routes';
import { ReactComponent as NoBrigadesBanner } from 'Assets/banners/createTeam.svg';
import Can from 'Common/components/Authorization/Can';
import { BRIGADE_CREATE } from 'Src/RBAC/businessPermissions';
import { INITIAL_PAGE_NUMBER } from 'Shared/constants/paging';

const BrigadesListScreen = observer(() => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    brigadeStore: {
      isBrigadeListLoaded, loadAll, isLoading, brigadesList, isDeleting
    },
    notificationStore: { enqueueSnackbar },
  } = useStores();
  const history = useHistory();

  const goToBrigadeCreation = () => {
    history.push(CREATE_BRIGADE);
  };

  const showLoadListError = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('REQUEST_BRIGADES_LIST_ERROR')
          }]
        }],
      },
      variant: 'error',
    });
  };

  useEffect(() => {
    loadAll(INITIAL_PAGE_NUMBER).catch(() => showLoadListError());
  }, []);

  if (!isBrigadeListLoaded || isLoading) {
    return <ProgressBar />;
  }

  return (
    <PaperLayout autoHeight>
      {isDeleting && <ProgressBar />}
      <HeaderLayout title={t('BRIGADES')} sticky>
        <Can
          variant="user"
          perform={BRIGADE_CREATE}
          yes={() => (
            <HeaderButton
              title={t('CREATE_BRIGADE_ACTION')}
              type="add"
              handleClick={goToBrigadeCreation}
            />
          )}
        />
      </HeaderLayout>
      {brigadesList.length
        ? (<BrigadeListContainer />)
        : (
          <Box
            minHeight={`calc(100vh - ${theme.headerHeight}px - ${theme.mainContentPaddingTop}px - ${theme.pageHeaderHeight}px - ${theme.mainContentPaddingBottom}px)`}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Can
              variant="user"
              perform={BRIGADE_CREATE}
              yes={() => (
                <NoContent
                  arrowText={t('CREATE_BRIGADE_WITH_BUTTON')}
                  text={t('EMPTY_BRIGADES_LIST')}
                  Banner={<NoBrigadesBanner />}
                />
              )}
            />
            <Can
              variant="user"
              perform={BRIGADE_CREATE}
              no={() => (
                <NoContent
                  text={t('EMPTY_BRIGADES_LIST')}
                  Banner={<NoBrigadesBanner />}
                />
              )}
            />
          </Box>
        )
      }
    </PaperLayout>
  );
});

BrigadesListScreen.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
};

export default BrigadesListScreen;
