import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { get } from 'lodash';
import {
  Switch,
  Route,
  withRouter,
  Redirect,
} from 'react-router-dom';
import useStores from 'Store/useStores';
import { formatDate, formatToISOString } from 'Src/utils/datetime';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import Header from 'Common/widgets/Layout/Header/Header';
import DataTable from 'Common/components/DataTable/DataTable';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import { taskStatusAliases } from 'Src/business/tpsRepairs/config/taskStatusAliases';
import { statusesColumns } from 'Business/tpsRepairs/config/plantTableConfig';
import ListTabs from 'Common/widgets/Tabs/ListTabs';
import { taskStatuses } from 'Shared/constants/taskStatuses';
import {
  FOREMAN,
  MASTER,
  ROLE_MATCHING
} from 'Shared/constants/roles';
import { canAssignBy } from 'Business/tpsRepairs/utils/CanAssign';
import useCurrentListStatus from 'Src/hooks/useCurrentListStatus';
import * as routes from 'Src/shared/constants/routes';

const TasksList = observer(({ match, history }) => {
  const { t } = useTranslation();
  const {
    tasksStore: {
      isLoading: isTasksLoading,
      isTaskLoaded,
      tasks,
      materials,
      loadTaskList,
      getStatusList,
      assignTask,
      getReasons,
      totalPages,
      isLoadingByPage,
      hasNextPage,
    },
    userStore: {
      loadUsersByRole,
      role,
      isLoading: isUsersLoading,
      isOrgUnitUsersLoaded,
      auth: { id: userId },
    },
    brigadeStore: { loadAll: loadBrigades, isLoading: isBrigadesLoading, isBrigadeListLoaded },
    notificationStore: { enqueueSnackbar },
  } = useStores();

  const translatedTaskStatusAliases = taskStatusAliases(t);

  const translatedStatusesColumns = statusesColumns(t);

  const translatedTaskStatuses = taskStatuses(t);

  const statusAliases = Object.keys(translatedTaskStatusAliases).map(alias => ({
    key: alias,
    ...translatedTaskStatusAliases[alias]
  }));

  const currentStatus = useCurrentListStatus('tasks');

  const checkAlias = currentAlias => Object.keys(translatedTaskStatusAliases).some(key => translatedTaskStatusAliases[key].name === currentAlias) && currentAlias;

  const getStatusQuery = () => {
    if (!currentStatus) return [''];

    if (currentStatus === 'all') {
      const { queryByRole } = translatedTaskStatusAliases[currentStatus.toUpperCase()];
      return queryByRole[role];
    }

    const query = get(translatedTaskStatusAliases[currentStatus.toUpperCase()], 'query', ['ASSIGNED']);
    return query;
  };

  const getTab = () => {
    if (!currentStatus) return null;
    const { tab } = translatedTaskStatusAliases[currentStatus.toUpperCase()] || {};
    return tab;
  };

  const handleAssign = async (event, {
    taskId,
    plannedStartDate,
    plannedStartDateNew,
    currentAssignee: { identityId, identityType },
  }) => {
    event.stopPropagation();
    const date = plannedStartDateNew || plannedStartDate;

    if (date && taskId /* && assigneeId && identityType */) {
      const params = {
        taskId,
        plannedStartDate: formatToISOString(date),
        assigneeId: identityId,
        identityType
      };
      const { id } = await assignTask(params);

      if (id) {
        loadTaskList(getStatusQuery(), getTab()).catch(() => {
          enqueueSnackbar({
            messageTemplate: {
              rows: [{
                rowContent: [{
                  type: 'text',
                  text: t('REQUEST_LOAD_LIST_ERROR')
                }]
              }],
            },
            variant: 'error',
          });
        });
        enqueueSnackbar({
          messageTemplate: {
            rows: [{
              rowContent: [{
                type: 'text',
                text: t('TASK_SUCCESSFULLY_ASSIGNED')
              }]
            }],
          },
          variant: 'success',
        });
        return;
      }
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('REQUEST_DEFAULT_ERROR')
            }]
          }],
        },
        variant: 'error',
      });
    }
  };

  const transform = data => data.map((item) => {
    const {
      taskId,
      rootTechnicalObjectIds = [],
      assignments = [],
      plannedStartDate,
      plannedStartDateNew,
      expectedStartDate,
      expectedFinishDate,
      taskStatus,
      currentAssignee
    } = item;
    const status = taskStatus && taskStatus.toLowerCase();


    return {
      ...item,
      id: taskId,
      rootTechnicalObjectIds: rootTechnicalObjectIds.map(id => (materials[id] ? materials[id].title : '')).join(', ') || t('INPUT_EMPTY_VALUE'),
      assignments: assignments.reduce((acc, assignment) => assignment.identityName, ''),
      expectedDate: `${formatDate(expectedStartDate)} - ${formatDate(expectedFinishDate)}`,
      taskStatusTitle: translatedTaskStatuses[taskStatus] ? translatedTaskStatuses[taskStatus] : translatedTaskStatuses.UNKNOWN,
      role,
      actions: {
        assign: {
          disabled: !canAssignBy({
            role,
            userId,
            oldPlannedStartDate: plannedStartDate,
            plannedStartDate: plannedStartDateNew,
            currentAssignee,
            status
          })
        }
      }
    };
  });

  const loadPotentialAssignees = () => {
    if (role === MASTER) {
      loadBrigades().catch(() => {
        enqueueSnackbar({
          messageTemplate: {
            rows: [{
              rowContent: [{
                type: 'text',
                text: t('REQUEST_BRIGADES_LIST_ERROR')
              }]
            }],
          },
          variant: 'error',
        });
      });
    } else {
      loadUsersByRole({
        roles: ROLE_MATCHING[role] ? ROLE_MATCHING[role].join(',') : ''
      });
    }
  };

  const loadPaginatedTasks = (pageNumber, perPage) => {
    loadTaskList(getStatusQuery(), getTab(), pageNumber, perPage);
  };

  useEffect(() => {
    getStatusList();
    loadPotentialAssignees();
    getReasons();
  }, []);

  useEffect(() => {
    // redirect to tab 'NEW' for FOREMAN
    if (role === FOREMAN) {
      if (currentStatus === '') history.push(`/tasks/${translatedTaskStatusAliases.NEW.name}`);
    } else if (role !== '' && !currentStatus) {
      history.push(`/tasks/${translatedTaskStatusAliases.ASSIGNED.name}`);
    }
  }, [role, currentStatus]);

  useEffect(() => {
    if (!currentStatus) return;
    loadTaskList(getStatusQuery(), getTab());
  }, [currentStatus]);

  const shouldRenderTable = () => {
    const isPotentialAssigneesLoaded = role === MASTER
      ? !isBrigadesLoading && isBrigadeListLoaded
      : !isUsersLoading && isOrgUnitUsersLoaded;

    return currentStatus
      && !isTasksLoading
      && isTaskLoaded
      && isPotentialAssigneesLoaded;
  };

  const onRowClickHandler = (event, row) => {
    history.push({
      pathname: `${routes.VIEW_TASK}/${row.taskId}`
    });
  };

  return (
    <PaperLayout autoHeight>
      <Header
        title={t('TASKS')}
      />
      {isLoadingByPage && <ProgressBar />}
      <ListTabs
        matchUrl={match.url}
        statusConfig={statusAliases}
        shouldCheckRole
        role={role}
        value={checkAlias(currentStatus) || (role === FOREMAN ? translatedTaskStatusAliases.NEW.name : translatedTaskStatusAliases.ASSIGNED.name)}
      />
      <Switch>
        {statusAliases.map(({ key, name }) => (
          <Route
            key={key}
            path={`${match.path}/${name}`}
            render={props => (shouldRenderTable() ? (
              <DataTable
                isDataLoaded={isTaskLoaded}
                {...props}
                titleSingle={t('TASK_ACCUSATIVE')}
                columns={translatedStatusesColumns[key].columns}
                data={transform(tasks)}
                onRowClick={onRowClickHandler}
                onAssign={handleAssign}
                count={totalPages}
                hasNextPage={hasNextPage}
                onPageChange={loadPaginatedTasks}
                onPerPageChange={loadPaginatedTasks}
                actions={translatedStatusesColumns[key].actions}
                withPageTabs
                dataTestRowSuffixEntityName="taskId"
              />
            ) : <ProgressBar />)}
          />
        ))}
        <Redirect to={`/tasks/${role === FOREMAN ? translatedTaskStatusAliases.NEW.name : translatedTaskStatusAliases.ASSIGNED.name}`} />
      </Switch>
    </PaperLayout>
  );
});

TasksList.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.shape({
      tabIndex: PropTypes.number
    })
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(TasksList);
