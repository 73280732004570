import Agent from 'ApiAgents/Agent';

export default class PresenceControlAgent extends Agent {
  loadList = () => this.request({
    url: '/settings/presenceControl/Items',
    method: 'GET',
  }, 'tasks');

  loadById = id => this.request({
    url: '/settings/presenceControl/Item',
    method: 'GET',
    params: { id },
  }, 'tasks');

  update = data => this.request({
    url: '/settings/presenceControl',
    method: 'PUT',
    data: { ...data },
  }, 'tasks');
}
