import {
  TPS_REPAIR, TPS_INSPECTION, COAL
} from 'Shared/constants/business';
import {
  FOREMAN,
  FOREMAN_RU,
  HEAD_MASTER_RU,
  MASTER_RU,
  SPECIALIST_SDKR_RU,
  ECONOMIST_IETS_RU,
  PERFORMER_RU,
  HEAD_MECHANIC,
  HEAD_MECHANIC_RU,
  MECHANIC_RU,
  COAL_MASTER_RU,
  MANAGER,
  MANAGER_RU,
  SHIFT_SUPERVISOR_KTC_RU,
  SENIOR_POWER_UNIT_OPERATOR_RU,
  BOILER_OPERATOR_RU,
  TURBINE_OPERATOR_RU,
  POWER_UNIT_OPERATOR_RU,
  BOILER_OPERATOR_GZU_RU,
  BOILER_OPERATOR_SHBM_RU,
  BOILER_OPERATOR_RG_RU,
} from 'Shared/constants/roles';

export const adminAccessibleRolesConfig = {
  [TPS_REPAIR]: {
    [FOREMAN]: [
      FOREMAN_RU,
      HEAD_MASTER_RU,
      MASTER_RU,
      SPECIALIST_SDKR_RU,
      ECONOMIST_IETS_RU,
      PERFORMER_RU
    ]
  },
  [TPS_INSPECTION]: {
    [MANAGER]: [
      MANAGER_RU,
      SHIFT_SUPERVISOR_KTC_RU,
      SENIOR_POWER_UNIT_OPERATOR_RU,
      POWER_UNIT_OPERATOR_RU,
      BOILER_OPERATOR_RU,
      TURBINE_OPERATOR_RU,
      BOILER_OPERATOR_GZU_RU,
      BOILER_OPERATOR_SHBM_RU,
      BOILER_OPERATOR_RG_RU
    ]
  },
  [COAL]: {
    [HEAD_MECHANIC]: [
      HEAD_MECHANIC_RU,
      MECHANIC_RU,
      COAL_MASTER_RU,
      PERFORMER_RU
    ],
  },
};

export default adminAccessibleRolesConfig;
