import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { SvgIcon } from '@material-ui/core';
import useStores from 'Store/useStores';
import { ReactComponent as CustomTasksIcon } from 'Src/assets/tasks_custom.svg';
import { ReactComponent as StandardTasksIcon } from 'Src/assets/tasks_standard.svg';
import RadioInput from 'Common/components/Forms/Input/Radio';
import {
  PanelSubTitle
} from 'Common/widgets/Layout/Panel/Panel';

import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const TaskType = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    coalTaskStore: {
      taskData: { isStandard } = {},
      onChangeTaskType,
    },
  } = useStores();

  const { isReadonlyByMode } = props;

  const taskTypes = [
    { value: true, title: t('STANDARD_TASK_TYPE'), enumValue: 'standardTaskType' },
    { value: false, title: t('NOT_STANDARD_TASK_TYPE'), enumValue: 'notStandardTaskType' }
  ];

  if (isReadonlyByMode) {
    return (
      <>
        <PanelSubTitle>{t('TASK_TYPE')}</PanelSubTitle>
        <div className={classes.labelWithIconWrapper}>
          <SvgIcon
            className={classes.tasksTypeIcon}
            component={isStandard ? StandardTasksIcon : CustomTasksIcon}
          />
          <div className={classes.readOnlyLabel}>
            {isStandard ? t('STANDARD_TASK_TYPE') : t('NOT_STANDARD_TASK_TYPE')}
          </div>
        </div>
      </>
    );
  }
  return (
    <RadioInput
      label={t('TASK_TYPE')}
      value={isStandard}
      name="taskType"
      onChange={(value) => {
        onChangeTaskType(value === 'true');
      }}
      options={taskTypes}
      data-test="taskType"
    />
  );
};

TaskType.propTypes = {
  isReadonlyByMode: PropTypes.bool.isRequired,
};

export default observer(TaskType);
