import { makeStyles } from '@material-ui/core/styles';
import { isUndefined } from 'lodash';

const useStyles = makeStyles(theme => ({
  hidden: {
    display: 'none',
  },
  inputClearIcon: {
    color: theme.palette.primaryLight
  },
  popper: {
    width: '100% !important',
    top: ({ marginTop }) => (`calc(100% - 24px + ${typeof marginTop !== 'undefined' ? 24 - marginTop : 0}px)`),
    zIndex: 2,
    '& .MuiAutocomplete-paper': {
      border: ({ error }) => (`2px solid ${error ? theme.palette.negative : theme.palette.primaryLight}`),
      marginTop: -3,
      width: '100%',
      borderRadius: '0 0 4px 4px',
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14)',
      '&.MuiPaper-root': {
        borderTop: '0px',
      },
    },
    '& .MuiAutocomplete-listbox': {
      maxHeight: ({ listBoxMaxHeight }) => (listBoxMaxHeight || 150),
      padding: '8px 0px 0px 0px',
    },
    '& .MuiAutocomplete-paper[x-placement="top"]': {
      display: 'none',
    },
    '& li': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  option: {
    fontSize: 16,
    paddingTop: 6,
    paddingBottom: 6,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  required: {
    display: 'block',
    opacity: ({ disabled }) => (disabled ? 0.5 : 1),
    marginLeft: 16,
    fontSize: 12,
    color: ({ error }) => (error ? theme.palette.negative : theme.palette.black30),
  },
  helper: {
    display: 'flex',
    alignItems: 'flex-start',
    position: 'absolute',
    fontSize: 12,
    left: 0,
    right: 0,
    lineHeight: '16px',
    bottom: 6,
  },
  styledLikeButton: {
    caretColor: 'transparent',
    '& input': {
      '&::selection': { background: 'transparent' },
      userSelect: 'none',
      textAlign: 'left',
      cursor: 'pointer',
    },
  },
  textField: {
    position: 'relative',
    '& input': {
      fontSize: 16,
    },
    maxWidth: ({ maxWidth }) => (maxWidth || '100%'),
    '& .MuiInputBase-root': {
      '& fieldset': {
        fontSize: 16,
      },
      '&:hover': {
        '& fieldset': {
          borderColor: ({ error }) => (error ? theme.palette.negative : theme.palette.primaryLight),
        },
      },
      color: theme.palette.black,
    },
    '& .MuiFormLabel-root': {
      fontSize: 16,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.primaryLight,
      borderWidth: 4,
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: theme.palette.negative,
    },
    marginTop: ({ marginTop }) => (!isUndefined(marginTop) ? marginTop : theme.spacing(1)),
    marginBottom: ({ marginBottom }) => (!isUndefined(marginBottom) ? marginBottom : theme.spacing(3)),
    '& .MuiFormHelperText-root': {
      fontSize: 12,
      color: ({ error }) => (error ? theme.palette.negative : theme.palette.black30),
    },
  },
}));

export default useStyles;
