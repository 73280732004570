import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import useManageEntityModal from 'Common/components/Modal/ManageEntityModal/useManageEntityModal';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import useStores from 'Store/useStores';
import { BRIGADES, EDIT_BRIGADE } from 'Shared/constants/routes';
import Header from 'Common/widgets/Layout/Header/Header';
import Layout from 'Common/widgets/Layout/Paper/Layout';
import BrigadeDetailsWidget from 'Src/business/tpsRepairs/widgets/BrigadeDetailsWidget';

const BrigadeDetailsContainer = observer(() => {
  const {
    brigadeStore: {
      managedBrigade,
      delete: deleteBrigade,
      checkIfAssignedTo,
      loadManagedBrigade,
      isLoading,
      isManagedBrigadeLoaded,
      isDeleting,
    },
    notificationStore: { enqueueSnackbar },
  } = useStores();
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const {
    setManageEntityModalVisibility, setOnManageEntityModalCloseCallback, setManageEntityModalState, renderManageEntityModal
  } = useManageEntityModal();

  useEffect(() => {
    loadManagedBrigade(id);
  }, [id]);

  const goToBrigadesList = () => history.push({ pathname: BRIGADES });

  const goToBrigadeEdit = () => history.push({ pathname: `${EDIT_BRIGADE}/${managedBrigade.id}` });

  const editBrigade = () => {
    checkIfAssignedTo(managedBrigade.id)
      .then((isBrigadeAssigned) => {
        if (isBrigadeAssigned) {
          enqueueSnackbar({
            messageTemplate: {
              rows: [{
                rowContent: [{
                  type: 'text',
                  text: t('BRIGADE_HAS_ASSIGNED_TASK'),
                }]
              },
              {
                rowContent: [{
                  type: 'text',
                  text: `${t('EDITING')} ${t('BRIGADE_TITLE_GENITIVE')} ${t('RESTRICTED')}`,
                }]
              }],
            },
            variant: 'warning',
          });
        } else {
          goToBrigadeEdit();
        }
      })
      // eslint-disable-next-line no-alert
      .catch(e => alert(`${t('FAILED_TO_CHECK')}: ${e.message}`));
  };

  const handleDeleteBrigade = () => {
    setManageEntityModalVisibility(false);

    deleteBrigade(id)
      .then((isDeleted) => {
        if (isDeleted) {
          enqueueSnackbar({
            messageTemplate: {
              rows: [{
                rowContent: [{
                  type: 'text',
                  text: t('BRIGADE_SUCCESSFULLY_REMOVED')
                }]
              }],
            },
            variant: 'success',
          });
          goToBrigadesList();
        } else {
          enqueueSnackbar({
            messageTemplate: {
              rows: [{
                rowContent: [{
                  type: 'text',
                  text: `${t('REMOVAL_IS_PROHIBITED')}${t('BRIGADE_HAS_ASSIGNED_TASK')}`
                }]
              }],
            },
            variant: 'error',
          });
        }
      })
      .catch(() => {
        enqueueSnackbar({
          messageTemplate: {
            rows: [{
              rowContent: [{
                type: 'text',
                text: t('REQUEST_DEFAULT_ERROR')
              }]
            }],
          },
          variant: 'error',
        });
      });
  };

  const removeBrigade = () => {
    setManageEntityModalState(() => ({
      title: `${t('DELETING')} ${t('BRIGADE_TITLE_GENITIVE')}`,
      entityName: managedBrigade.name,
      message: `${t('CONFIRM_DELETING_MESSAGE')} ${t('BRIGADE_TITLE_ACCUSATIVE')}?`,
      type: 'action'
    }));

    setOnManageEntityModalCloseCallback(() => handleDeleteBrigade);
    setManageEntityModalVisibility(true);
  };

  if (isLoading || !isManagedBrigadeLoaded) {
    return <ProgressBar />;
  }

  const { name } = managedBrigade;

  return (
    <Layout>
      {isDeleting && <ProgressBar />}
      <Header
        sticky
        title={name}
        isActiveGoBack
        handleGoBack={goToBrigadesList}
        titleGoBack={t('GO_BACK')}
      >
        <HeaderButton type="delete" handleClick={removeBrigade} />
        <HeaderButton type="edit" handleClick={editBrigade} />
      </Header>
      <BrigadeDetailsWidget brigade={managedBrigade} />
      {renderManageEntityModal()}
    </Layout>
  );
});

export default BrigadeDetailsContainer;
