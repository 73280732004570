import qs from 'qs';
import Agent from 'ApiAgents/Agent';
import getPaginationQueryObject from 'Src/utils/getPaginationQueryObject';

export default class InspectionsAgent extends Agent {
  getCoalInspectionsList = (query = {}, shouldPaginate, pageNumber, pageSize) => {
    const pageQuery = getPaginationQueryObject(shouldPaginate, pageNumber, pageSize);
    return this.request(
      {
        url: '/coalInspections',
        method: 'GET',
        params: { ...query, ...pageQuery },
        paramsSerializer: params => qs.stringify(params),
      },
      'tasks',
    );
  };

  getInspectionById = id => this.request(
    {
      url: `/coalInspections/${id}`,
      method: 'GET',
    },
    'tasks',
  );

  save = (body = {}) => (
    this.request({
      url: '/task/pl/manage/create',
      method: 'POST',
      data: body,
    },
    'tasks')
  );

  delete = id => (
    this.request({
      url: `/coalInspections/${id}`,
      method: 'DELETE',
    },
    'tasks')
  );

  loadShiftDictionary = () => this.request({
    url: '/dictionary/getByKey',
    method: 'GET',
    params: { key: 'Shift' }
  }, 'tasks');

  loadOperationRejectReasonDictionary = () => this.request({
    url: '/dictionary/getByKey',
    method: 'GET',
    params: { key: 'CoalOperationRejectReason' }
  }, 'tasks');

  loadOperationPauseReasonDictionary = () => this.request({
    url: '/dictionary/getByKey',
    method: 'GET',
    params: { key: 'SubOperationStopReason' }
  }, 'tasks');

  loadCoalRoutesDictionary = departmentId => this.request({
    url: '/coalRoutes',
    method: 'GET',
    params: { departmentIds: [departmentId] },
    paramsSerializer: params => qs.stringify(params)
  }, 'techObject');
}
