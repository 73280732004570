import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    minWidth: 32,
    height: 32,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: theme.palette.lightSelection,
      '& $icon': {
        color: theme.palette.primaryLight,
      }
    }
  },
  icon: {
    width: 20,
    height: 18,
    color: theme.palette.black70,
  },
}));

export default useStyles;
