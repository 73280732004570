import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    height: theme.sectionHeaderHeight,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: theme.palette.lightGray,
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: 20,
    fontWeight: 'bold'
  },
}));

const SectionHeader = ({
  title,
  className,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.header, className && className)}>{title}</div>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

SectionHeader.defaultProps = {
  className: undefined,
};

export default SectionHeader;
