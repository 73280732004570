export const DEFECTS_SAP = 'DEFECTS_SAP';
export const DEFECTS_WEB = 'DEFECTS_WEB';
export const MEASURING_POINTS_SAP = 'MEASURING_POINTS_SAP';
export const MEASURING_POINTS = 'MEASURING_POINTS';
export const DEFECTS = 'DEFECTS';
export const CUSTOM_ACTION = 'CUSTOM_ACTION';

export const operationTypeTitles = {
  [MEASURING_POINTS]: 'MEASUREMENTS',
  [DEFECTS]: 'INSPECTIONS',
  [CUSTOM_ACTION]: 'CUSTOM_TASK',
};
