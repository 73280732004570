import {
  action,
  observable,
  runInAction,
  makeObservable,
} from 'mobx';
import DefectsAgent from 'ApiAgents/Defects/DefectsAgent';

const defectsAgent = new DefectsAgent();


export class DefectsListStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable totalPages = null;

  @observable isDefectsListLoaded = false;

  @observable hasNextPage = false;

  @observable isLoadingByPage = false;

  @observable isLoading = false;

  @observable defectsList = [];

  @action loadDefectsList = async (status = [], pageNumber, pageSize) => {
    const isPaginating = !!pageNumber;
    this.rootStore.setLoadingFlag(
      isPaginating,
      this,
      'isLoadingByPage',
      'isLoading',
      true
    );

    try {
      const requestBody = { status };
      const {
        items = [], hasPreviousPage, hasNextPage, totalPages
      } = await defectsAgent.loadList(
        requestBody,
        isPaginating,
        pageNumber,
        pageSize
      ) || {};

      runInAction(() => {
        this.defectsList = items;
        this.totalPages = totalPages;
        this.hasNextPage = hasNextPage;
        this.hasPreviousPage = hasPreviousPage;
        this.isDefectsListLoaded = true;
      });
    } catch (error) {
      console.log('ERROR in TASKS FETCHING: ', error);
    }

    runInAction(() => {
      this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', false);
    });
  };

  @action discardState = () => {
    runInAction(() => {
      this.totalPages = null;
      this.isDefectsListLoaded = false;
      this.hasNextPage = false;
      this.isLoadingByPage = false;
      this.isLoading = false;
      this.defectsList = [];
    });
  };
}

export default DefectsListStore;
