import React from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core/';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  flexContainer: {
    backgroundColor: theme.palette.default
  }
}));

const ListTabs = ({
  value, statusConfig, matchUrl, shouldCheckRole, role
}) => {
  const classes = useStyles();

  const renderTabs = () => statusConfig.map(({
    title, name, access
  }) => {
    if (shouldCheckRole && !access.includes(role)) return;

    return (
      <Tab
        key={title}
        label={title}
        component={Link}
        to={`${matchUrl}/${name}`}
        value={name}
        data-test={name}
      />
    );
  });

  return (
    <Tabs
      variant="scrollable"
      value={value}
      className={classes.flexContainer}
    >
      {renderTabs()}
    </Tabs>
  );
};

ListTabs.propTypes = {
  value: PropTypes.string.isRequired,
  matchUrl: PropTypes.string.isRequired,
  role: PropTypes.string,
  shouldCheckRole: PropTypes.bool,
  statusConfig: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      access: PropTypes.array, // eslint-disable-line
      queryByRole: PropTypes.object, // eslint-disable-line
      query: PropTypes.array, // eslint-disable-line
    })
  ).isRequired,
};

ListTabs.defaultProps = {
  shouldCheckRole: false,
  role: ''
};

export default ListTabs;
