import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CheckboxInput from 'Common/components/Forms/Input/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { DeleteOutline } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch';
import clsx from 'clsx';
import buildUserFullName from 'Src/utils/buildUserFullName';


const useStyles = makeStyles(theme => ({
  listItem: {
    boxShadow: '0 1px 3px 0 #f3f3f3',
    border: 'solid 1px #f3f3f3',
    marginBottom: 8
  },
  removeButton: {
    padding: 2,
    color: theme.palette.negative,
    '&:hover': {
      backgroundColor: theme.palette.pink,
    }
  },
  rightPadding: {
    paddingRight: '5em'
  },
  itemIcon: {
    minWidth: 0
  },
  switchThumb: {
    color: theme.palette.disabled
  },
  brigadier: {
    marginRight: 10,
  },
  disabled: {
    color: theme.palette.black30
  },
  active: {
    color: theme.palette.black
  },
}));

const EmployeeListItem = ({
  employee, handleToggle, checked, isSelected, noActions,
  toggleBrigadier, handleRemoveFromBrigade, isBrigadier, ...props
}) => {
  const {
    firstName,
    lastName,
    surName,
    positionName,
    id,
  } = employee;
  const { t } = useTranslation();
  const classes = useStyles();

  const buildFullName = () => buildUserFullName(firstName, lastName, surName);

  const renderCheckBoxes = () => {
    if (noActions || isSelected) return;

    return (
      <ListItemIcon className={classes.itemIcon}>
        <CheckboxInput
          name="add"
          onChange={handleToggle(id)}
          checked={checked.indexOf(id) !== -1}
          data-test="add"
        />
      </ListItemIcon>
    );
  };

  const renderSecondaryActions = () => {
    if (noActions || !isSelected) return;

    return (
      <ListItemSecondaryAction>
        <Grid container wrap="nowrap" justify="center" spacing={1}>
          <Grid item>
            <span className={clsx(isBrigadier ? classes.active : classes.disabled, classes.brigadier)}>{t('PRODUCER')}</span>
            <Tooltip title={!isBrigadier ? t('ASSIGN_PRODUCER') : t('REMOVE_APPOINTMENT')}>
              <Switch
                classes={{ switchBase: classes.switchThumb }}
                size="small"
                checked={isBrigadier}
                onChange={toggleBrigadier(isBrigadier)}
                color="primary"
                value={id}
                data-test={isBrigadier ? 'repeal-brigadier' : 'make-brigadier'}
              />
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={t('REMOVE_FROM_LIST')}>
              <IconButton className={classes.removeButton} onClick={handleRemoveFromBrigade} data-test="remove">
                <DeleteOutline />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </ListItemSecondaryAction>
    );
  };

  return (
    <ListItem className={classes.listItem} data-test={id}>
      {renderCheckBoxes()}
      <ListItemText
        className={classes.rightPadding}
        primary={buildFullName()}
        secondary={positionName}
        {...props}
      />
      {renderSecondaryActions()}
    </ListItem>
  );
};

EmployeeListItem.propTypes = {
  handleToggle: PropTypes.func,
  noActions: PropTypes.bool,
  handleRemoveFromBrigade: PropTypes.func,
  toggleBrigadier: PropTypes.func,
  isSelected: PropTypes.bool,
  isBrigadier: PropTypes.bool,
  checked: PropTypes.arrayOf(PropTypes.string),
  employee: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    surName: PropTypes.string,
    fullName: PropTypes.string,
    positionName: PropTypes.string.isRequired
  }).isRequired,
};

EmployeeListItem.defaultProps = {
  toggleBrigadier: null,
  handleToggle: null,
  handleRemoveFromBrigade: null,
  isSelected: false,
  isBrigadier: false,
  noActions: false,
  checked: []
};

export default EmployeeListItem;
