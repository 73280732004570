import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '24px 24px 24px 58px',
    position: 'relative',
    border: `dashed 1px ${theme.palette.black20}`,
    color: theme.palette.black30,
    fontSize: '14px',
    marginBottom: 24,
  },
  item: {
    position: 'relative',
  },
  arrow: {
    position: 'absolute',
    left: 18,
    top: 28,
    width: 21
  },
  icon: {
    position: 'absolute',
    color: theme.palette.positive,
    left: -40,
  },
  error: {
    color: theme.palette.negative,
  },
}));

export default useStyles;
