import {
  action, computed, observable, runInAction, makeObservable
} from 'mobx';
import { computedFn } from 'mobx-utils';
import UserAgent from 'ApiAgents/Users/UserAgent';
import EquipmentAgent from 'ApiAgents/Equipment/EquipmentAgent';
import {
  IDENTITY_TYPE_BRIGADE,
  IDENTITY_TYPE_USER,
  MASTER,
} from 'Shared/constants/roles';
import { getRole } from 'Src/utils/getRole';
import {
  businessRU, businessTypeAlias,
} from 'Shared/constants/business';

import {
  ACTIVE,
} from 'Shared/constants/userStatuses';

const userAgent = new UserAgent();
const equipmentAgent = new EquipmentAgent();

export class UserStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable isLoading = false;

  @observable isUsersFullLoaded = false;

  @observable isOrgUnitUsersLoaded = false;

  @observable usersFull = [];

  @observable orgUnitUsers = [];

  @observable auth = null;

  @observable orgUnitId = null;

  //TODO: Find usages, fetch from orgUnitRoot and remove
  @observable orgUnitRootId = null;

  @observable rootOrgUnit = {};

  @observable isLoggedIn = false;

  @observable accessToken = '';

  @observable role = '';

  @observable status = ACTIVE;

  @observable businessType = '';

  @observable availableBusinessTypes = [];

  @observable identityType = '';

  @observable environment = '';

  logoutHandler = null;

  @computed get authFullName() {
    const {
      lastName = '', firstName = '', surName = '', fullName
    } = this.auth;
    if (fullName) return fullName;

    return this.auth
      ? `${lastName} ${firstName} ${surName}`
      : '';
  }

  @computed get users() {
    return this.usersFull.map(({ id, fullName, positionName }) => ({
      id,
      name: fullName || '',
      position: positionName || '',
    }));
  }

  @computed get azureUserId() {
    return window.localStorage.getItem('userId');
  }

  @action fetchUsers = async () => {
    this.isLoading = true;
    try {
      const users = await userAgent.fetchAll();
      runInAction(() => {
        this.usersFull = users;
        this.isUsersFullLoaded = true;
      });
    } catch (error) {
      this.error = error;
    }
    runInAction(() => {
      this.isLoading = false;
    });
  };

  @action fetchUsersByOrgUnit = async () => {
    const { orgUnitId } = this;

    this.isLoading = true;
    try {
      const users = await userAgent.fetchAllByOrgUnit(orgUnitId);
      runInAction(() => {
        this.orgUnitUsers = users;
        this.isOrgUnitUsersLoaded = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
      });
    }
    runInAction(() => {
      this.isLoading = false;
    });
  };

  @action loadUsersByRole = async (params) => {
    const { orgUnitId } = this;

    if (params && !params.roles) {
      this.isOrgUnitUsersLoaded = true;
      return false;
    }

    this.isLoading = true;
    try {
      const users = await userAgent.loadUsersByRole({
        ...params,
        orgUnitId: params.orgUnitId || orgUnitId
      });
      runInAction(() => {
        this.orgUnitUsers = users;
        this.isOrgUnitUsersLoaded = true;
        this.isLoading = false;
      });
      return users;
    } catch (error) {
      this.error = error;
    }

    runInAction(() => {
      this.isLoading = false;
    });
  };

  @action setLoggedIn = (isLoggedIn) => {
    if (this.isLoggedIn) return;
    this.isLoggedIn = isLoggedIn;
  };

  setLogoutHandler = (logoutHandler) => {
    if (this.logoutHandler) return;
    this.logoutHandler = logoutHandler;
  };

  setOrgUnitIds = (orgUnits) => {
    if (!Array.isArray(orgUnits)) {
      // throw new TypeError(`OrgUnits are expected to be an array. "${typeof orgUnits}" provided.`);
      console.error(`OrgUnits are expected to be an array. "${typeof orgUnits}" provided.`);
      this.logout();
      return;
    }

    const { orgUnitId = null, orgUnitRootId = null } = orgUnits.reduce((acc, { enumValue, id }) => {
      if (enumValue === 'DEPARTMENT') acc.orgUnitId = id;
      if (enumValue === 'ROOT') acc.orgUnitRootId = id;
      return acc;
    }, {});
    this.orgUnitId = orgUnitId;
    this.orgUnitRootId = orgUnitRootId;
  };

  @action setAccessToken = (token) => {
    this.accessToken = token;
  };

  @action loadAuth = async (env) => {
    this.environment = env;
    this.isLoading = true;
    try {
      const userId = this.azureUserId;
      const user = await userAgent.filterById({ userId });
      const { orgUnits, roles, status } = user;
      let tpsId = '';
      let tpsTitle = '';
      if (user.rootOrgUnit && user.rootOrgUnit.code && user.businessType === businessTypeAlias.TPS_INSPECTION) {
        const { id, title } = await this.loadTpsId(user.rootOrgUnit.code);
        tpsId = id;
        tpsTitle = title;
      }

      runInAction(() => {
        this.auth = user;
        this.rootOrgUnit = { ...user.rootOrgUnit, tpsId, tpsTitle };
        this.role = getRole(roles);
        this.status = status;
        this.availableBusinessTypes = this.getAvailableBusinessTypes(user);
        this.initBusinessType();
        this.identityType = this.getIdentityType();
        this.setOrgUnitIds(orgUnits);
      });
    } catch (error) {
      console.log('ERROR In AUTH FETCH: ', error);
    }
    runInAction(() => {
      this.isLoading = false;
    });
  };

  userById = computedFn((userId) => {
    if (!userId) {
      return [];
    }

    return this.users.find(user => user.id === userId);
  });

  logout = () => {
    window.localStorage.clear();
    this.logoutHandler();
  };

  setBusinessType = (businessType) => {
    this.businessType = businessType;
    localStorage.setItem('businessType', this.businessType);
  };

  // define started businessType
  initBusinessType = () => {
    const localBusinessType = localStorage.getItem('businessType');
    let businessType = '';

    if (this.availableBusinessTypes.length === 1) {
      // eslint-disable-next-line
      businessType = this.availableBusinessTypes[0];
    } else {
      businessType = this.availableBusinessTypes.includes(localBusinessType) ? localBusinessType : '';
    }
    this.setBusinessType(businessType);
  };

  getAvailableBusinessTypes = ({ roles }) => (roles ? roles.reduce((acc, { name }) => (businessRU[name] ? [...acc, businessRU[name]] : acc), []) : []);

  getIdentityType = () => (this.role === MASTER ? IDENTITY_TYPE_BRIGADE : IDENTITY_TYPE_USER);

  loadTpsId = async (sapCode) => {
    const TECHOBJECTS_LEVELS_TO_LOAD = 1;
    const [{ technicalObjectId, title }] = await equipmentAgent.getTechObjectsTrees(sapCode, TECHOBJECTS_LEVELS_TO_LOAD);
    return { id: technicalObjectId, title };
  };

  @action setEnvironment(environment = 'HZ') {
    console.log('setEnvironment', environment);
    this.environment = environment;
  }
}

export default UserStore;
