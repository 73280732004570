import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import AssignUserAction from 'Common/components/Forms/Button/components/Action/AssignUserAction';
import AssignUser from 'Src/business/tpsRepairs/widgets/AssignUser/AssignUser';
import withModalOpenHandling from 'HOC/withModalOpenHandling';
import ColumnEdit from 'Common/components/Forms/Button/ColumnEdit/ColumnEdit';
import {
  FOREMAN, MASTER, MASTER_RU, HEAD_MASTER, HEAD_MASTER_RU, IDENTITY_TYPE_BRIGADE
} from 'Shared/constants/roles';
import { isDateOverdue } from 'Src/utils/datetime';
import useStores from 'Store/useStores';
import useCurrentListStatus from 'Hooks/useCurrentListStatus';
import useAssigneeSelect from 'Hooks/useAssigneSelect';
import { taskStatusAliases } from 'Src/business/tpsRepairs/config/taskStatusAliases';


const AssignUserContainer = observer(({
  id,
  status,
  title,
  isOpened,
  handleClickOpen,
  handleClose,
  currentAssignee,
  plannedStartDate
}) => {
  const { tasksStore, userStore: { role, identityType, auth: { id: authId } } } = useStores();
  const { assignee, handleAssigneeChange } = useAssigneeSelect();
  const currentTaskListStatus = useCurrentListStatus('tasks');
  const { t } = useTranslation();
  const translatedTaskStatusAliases = taskStatusAliases(t);
  const {
    identityName, isNew, identityId, positionName: currentAssigneePositionName
  } = currentAssignee;
  const isForBrigade = identityType === IDENTITY_TYPE_BRIGADE;

  useEffect(() => {
    if (!identityId || identityId === assignee.assigneeId) return;
    handleAssigneeChange(identityId);
  }, [identityId]);

  useEffect(() => {
    if (!isOpened) return;
    handleAssigneeChange(identityId || '', '');
  }, [isOpened]);

  const handleAssign = () => {
    const { assigneeId, assigneeName } = assignee;
    tasksStore.assignUser(id, assigneeId, assigneeName);
    handleClose();
  };

  const isDisabledByStatus = () => ![
    translatedTaskStatusAliases.ASSIGNED.name,
  ].includes(status && status.toLowerCase());

  const isDisabledByCurrentTaskListStatus = () => [
    translatedTaskStatusAliases.ALL.name,
  ].includes(currentTaskListStatus && currentTaskListStatus.toLowerCase());

  const isDisabledColumnEditing = () => (
    !((isNew || identityId === authId)
      || (!isDateOverdue(plannedStartDate) && (
        (role === FOREMAN && currentAssigneePositionName === HEAD_MASTER_RU)
        || (role === HEAD_MASTER && currentAssigneePositionName === MASTER_RU)
        || (role === MASTER && isForBrigade))
      )
    )
    || isDisabledByStatus()
    || isDisabledByCurrentTaskListStatus()
  );

  const isDisabledAssignButton = () => isDisabledByCurrentTaskListStatus();

  const renderAssignee = () => {
    if (!isEmpty(currentAssignee)) {
      return (
        <ColumnEdit
          handleClick={(e) => {
            e.stopPropagation();
            handleClickOpen(e);
          }}
          title={t('CHANGE_PERFORMER')}
          disabled={isDisabledColumnEditing()}
        >
          {identityName}
        </ColumnEdit>
      );
    }
    return (
      <AssignUserAction
        handleClick={(e) => {
          e.stopPropagation();
          handleClickOpen(e);
        }}
        disabled={isDisabledAssignButton()}
      />
    );
  };

  return (
    <>
      {renderAssignee()}
      {isOpened && (
        <AssignUser
          setUser={handleAssigneeChange}
          handleAssign={handleAssign}
          assigneeId={assignee.assigneeId}
          handleClose={handleClose}
          taskTitle={title}
          isOpened={isOpened}
          isBrigade={isForBrigade}
        />
      )}
    </>
  );
});

AssignUserContainer.propTypes = {
  currentAssignee: PropTypes.shape({
    identityName: PropTypes.string,
    isNew: PropTypes.bool,
    identityId: PropTypes.string,
  }),
  handleClose: PropTypes.func.isRequired,
  taskId: PropTypes.number,
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  isOpened: PropTypes.bool.isRequired,
  handleClickOpen: PropTypes.func.isRequired,
};

AssignUserContainer.defaultProps = {
  currentAssignee: { identityName: '', isNew: false }
};

export default withRouter(withModalOpenHandling(AssignUserContainer));
