export const ASSIGNED_ENUM = 'ASSIGNED';
export const CANCELED_ENUM = 'CANCELED';
export const COMPLETED_ENUM = 'COMPLETED';
export const IN_PROGRESS_ENUM = 'IN_PROGRESS';
export const NEW_ENUM = 'NEW';
export const PAUSE_ENUM = 'PAUSE';
export const UNKNOWN_ENUM = 'UNKNOWN';
export const ACTUAL_ENUM = 'ACTUAL';
export const ON_CONTROL_ENUM = 'ON_CONTROL';

export const taskStatusEnum = [
  ASSIGNED_ENUM,
  CANCELED_ENUM,
  COMPLETED_ENUM,
  IN_PROGRESS_ENUM,
  NEW_ENUM,
  PAUSE_ENUM,
  UNKNOWN_ENUM,
  ACTUAL_ENUM,
  ON_CONTROL_ENUM
];

export const taskStatuses = t => (
  {
    UNKNOWN: t('UNKNOWN'),
    OUT_OF_SYNC: t('SYNC_IS_IN_PROGRESS'),
    NEW: t('NEW_ENTITY'),
    IN_PROGRESS: t('IN_PROGRESS'),
    PAUSE: t('PAUSE'),
    COMPLETED: t('COMPLETED_ENTITY'),
    NOT_COMPLETED: t('NOT_COMPLETED'),
    PARTIALLY_DONE: t('PARTIALLY_DONE'),
    REJECTED: t('REJECTED_ENTITY'),
    ON_CONTROL: t('ON_CONTROL'),
    CANCELED: t('REJECTED_ENTITY'),
    ASSIGNED: t('ASSIGNED_ENTITY'),
  }
);
