import DefectsAgent from 'ApiAgents/Defects/DefectsAgent';
import {
  action,
  observable,
  runInAction,
  makeObservable,
} from 'mobx';

const defectsAgent = new DefectsAgent();

export class DefectStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable defect = {};

  @observable isLoading = false;

  @observable isLoaded = false;

  @observable isRejecting = false;

  @observable isConfirming = false;

  @action getDefectById = async (id) => {
    this.isLoading = true;
    this.isLoaded = false;
    try {
      const defect = await defectsAgent.getDefectById(id);
      runInAction(() => {
        this.defect = defect;
        this.isLoaded = true;
        this.isLoading = false;
      });
    } catch (error) {
      console.log('ERROR IN DEFECT FETCHING: ', error);
    }
  };

  @action rejectDefect = async (id) => {
    this.isRejecting = true;
    try {
      await defectsAgent.rejectDefect({ id });
      runInAction(() => {
        this.isRejecting = false;
      });
      return { error: false };
    } catch (error) {
      console.log('ERROR IN DEFECT REJECTING: ', error);
      runInAction(() => {
        this.isRejecting = false;
      });
      return { error };
    }
  };

  @action confirmDefect = async (id) => {
    this.isConfirming = true;
    try {
      await defectsAgent.confirmDefect({ id });
      runInAction(() => {
        this.isConfirming = false;
      });
      return { error: false };
    } catch (error) {
      console.log('ERROR IN TASK DEFECT CONFIRMATION: ', error);
      runInAction(() => {
        this.isConfirming = false;
      });
      return { error };
    }
  };

  @action discardState = () => {
    runInAction(() => {
      this.defect = {};
      this.isLoading = false;
      this.isLoaded = false;
      this.isRejecting = false;
      this.isConfirming = false;
    });
  };
}

export default DefectStore;
