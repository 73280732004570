import Agent from 'ApiAgents/Agent';
import getPaginationQueryObject from 'Src/utils/getPaginationQueryObject';


export default class CheckpointAgent extends Agent {
  getCheckpoints = (
    params = {},
    shouldPaginate,
    pageNumber,
    pageSize
  ) => {
    const pageQuery = getPaginationQueryObject(shouldPaginate, pageNumber, pageSize);

    return this.request({
      url: '/Label',
      method: 'GET',
      params: { ...params, ...pageQuery }
    },
    'tpsInspectionTask');
  };

  getCheckpointById = id => (
    this.request({
      url: `/Label/${id}`,
      method: 'GET',
    },
    'tpsInspectionTask'));

  deleteCheckpoint = id => (
    this.request({
      url: `/Label/${id}`,
      method: 'DELETE',
      params: { id },
    },
    'tpsInspectionTask')
  );

  createCheckpoint = (data = {}) => (
    this.request({
      url: '/Label',
      method: 'POST',
      data,
    },
    'tpsInspectionTask')
  );

  updateCheckpoint = (data = {}) => (
    this.request({
      url: '/Label',
      method: 'PUT',
      data,
    },
    'tpsInspectionTask')
  );
}
