import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 1.5,
    color: theme.palette.black,
    display: 'flex',
    alignItems: 'center',
  },
  lightTitle: {
    color: theme.palette.black30
  },
  titleContainer: {
    marginBottom: 16
  },
  subTitle: {
    color: theme.palette.black30,
    fontSize: 16,
  },
  techObjIcon: {
    marginRight: 8
  },
  techObjectContainer: {
    backgroundColor: theme.palette.midGray,
    padding: '18px 16px 18px 16px',
    marginBottom: 8
  },
  marginBottom16: {
    marginBottom: 16
  },
  operation: {
    marginBottom: 16,
    padding: 16,
    boxShadow: `0 1px 3px 0 ${theme.palette.midGray}`,
    backgroundColor: theme.palette.white
  },
  noPadding: {
    padding: '0!important'
  },
  primaryLight: {
    color: theme.palette.primaryLight
  },
  positive: {
    color: theme.palette.positive
  },
  noOperations: {
    height: 'auto',
    minHeight: 80,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.black30,
    fontSize: 14
  },
  commentText: {
    fontSize: 14
  },
  comment: {
    display: 'flex',
    marginTop: 16,
  }
}));
