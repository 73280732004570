import {
  action, observable, runInAction, makeObservable
} from 'mobx';
import {
  get, startsWith, find, reverse,
} from 'lodash';
import InspectionsAgent from 'ApiAgents/Inspections/InspectionsAgent';
import UnitsAgent from 'ApiAgents/Units/UnitsAgent';
import TaskAgent from 'ApiAgents/Tasks/TaskAgent';
import EquipmentAgent from 'ApiAgents/Equipment/EquipmentAgent';
import DictionaryAgent from 'ApiAgents/Dictionary/DictionaryAgent';
import {
  MECHANIC_RU, PERFORMER_RU, MECHANIC, HEAD_MECHANIC, COAL_MASTER_RU,
} from 'Shared/constants/roles';
import { getByRoleQuery } from 'Src/utils/roles';
import { formatToISOString, formatDate, formatTime } from 'Src/utils/datetime';

import { useTranslation } from 'react-i18next'; // eslint-disable-line
import { set } from 'date-fns';

const inspectionsAgent = new InspectionsAgent();
const unitsAgent = new UnitsAgent();
const taskAgent = new TaskAgent();
const equipmentAgent = new EquipmentAgent();
const dictionaryAgent = new DictionaryAgent();

const initialData = {
  currentAssignee: null,
  assignments: [],
  departmentId: null,
  taskType: 'COAL_INSPECTION',
  taskPriority: 5,
  isOrderRequired: false,
  shift: undefined,
};
export class CoalInspectionStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable inspection = initialData;

  @observable shiftDictionary = [];

  @observable operationRejectReasonDictionary = [];

  @observable operationPauseReasonDictionary = [];

  @observable scanFailReasonDictionary = [];

  @observable routesDictionary = [];

  @observable isLoading = false;

  @observable isDeleting = false;

  @observable isUpdating = false;

  @observable isLoaded = false;

  @observable isEmployeesLoaded = false;

  @observable employeesList = [];

  @observable taskRejectionReasonsDictionary = [];

  @observable isReasonDictionariesLoaded = false;

  @observable isShiftsDictionaryLoaded = false;

  @observable isDepartmentsLoaded = false;

  @observable usersSelectEditorData = {
    employees: {
      checked: [],
      selected: [],
      brigadier: null,
    },
  };

  @observable departmentsDictionary = [];

  @observable isDepartmentsReadonly = false;

  @observable isRoutesLoading = false;

  @action updateDataField = (name, value) => {
    this.inspection[name] = value;
  };

  @action prepareAssignments = () => {
    const {
      inspection,
    } = this;

    const {
      currentAssignee,
      assignments,
    } = inspection;

    this.usersSelectEditorData.employees = {
      checked: [...assignments, { ...currentAssignee }],
      selected: [...assignments],
      brigadier: { ...currentAssignee },
    };
  };

  @action setEmployees = (employees) => {
    this.usersSelectEditorData.employees = employees;
  };

  @action confirmSelectedUsers = ({ selected, currentAssignee }) => {
    runInAction(() => {
      this.inspection.currentAssignee = currentAssignee;
      this.inspection.assignments = selected;
    });
  };

  @action loadPotentialAssignees = async () => {
    let EMPLOYEES_ROLES = [];
    try {
      const { role, auth: { rootOrgUnit: { id: rootOrgUnitId } = {} } = {} } = this.rootStore.userStore;
      if (role === HEAD_MECHANIC) EMPLOYEES_ROLES = [MECHANIC_RU, COAL_MASTER_RU];
      if (role === MECHANIC) EMPLOYEES_ROLES = [PERFORMER_RU];
      const params = {
        roles: getByRoleQuery(EMPLOYEES_ROLES),
        ...(role === HEAD_MECHANIC && rootOrgUnitId) && { orgUnitId: rootOrgUnitId }
      };

      const employees = await this.rootStore.userStore.loadUsersByRole(params);
      runInAction(() => {
        this.employeesList = employees;
        this.isEmployeesLoaded = true;
      });
    } catch (error) {
      console.log('ERROR in FETCHING BRIGADE BY ID: ', error);
    }
  };

  @action getInspectionById = async (id) => {
    console.log('getInspectionById!!!!');
    this.isLoading = true;
    this.isLoaded = false;
    try {
      const inspection = await inspectionsAgent.getInspectionById(id);
      const currentAssignee = get(inspection, 'currentAssignee') || {};
      const currentAssigneeId = get(currentAssignee, 'id');
      const assignments = get(inspection, 'assignments') || [];
      const { position: currentAssigneePosition, ...restCurrentAssigneeOptions } = currentAssignee;
      const mappedCurrentAssignee = { ...restCurrentAssigneeOptions, positionName: currentAssigneePosition };
      const operations = get(inspection, 'operations') || [];
      const techObjectsIds = [];
      operations.forEach((operation) => {
        const technicalObjectId = get(operation, 'technicalObject.id');
        if (technicalObjectId && !techObjectsIds.includes(technicalObjectId)) techObjectsIds.push(technicalObjectId);
      });
      const techObjectsDictionary = await equipmentAgent.getTechObjectsByIdsArray(techObjectsIds);
      const mappedTechnicalObjects = operations.map((
        {
          technicalObject, ...restOperationOptions
        }
      ) => {
        const technicalObjectId = get(technicalObject, 'id');
        const measurementPoints = get(technicalObject, 'measurementPoints');
        const techObject = find(techObjectsDictionary, { id: technicalObjectId }) || {};
        const { measuringPoints = [], sapCode } = techObject;
        const mergedMeasurementPoints = measuringPoints.map(({ id: measurementPointId, unit, title: measurementPointTitle } = {}) => {
          const measurementPoint = find(reverse(measurementPoints), { id: measurementPointId }) || {};
          const { value, measuredDate, comment: measurementComment } = measurementPoint;
          return ({
            value,
            unit,
            measuredDate: `${formatDate(measuredDate)} ${formatTime(measuredDate)}`,
            name: measurementPointTitle,
            measurementComment,
          });
        });
        return ({
          ...technicalObject, ...restOperationOptions, sapCode, measurementPoints: mergedMeasurementPoints
        });
      });

      runInAction(() => {
        this.inspection = {
          ...inspection,
          currentAssignee: mappedCurrentAssignee,
          assignments: assignments.filter(({ id: assignmentId }) => assignmentId !== currentAssigneeId),
          technicalObjects: mappedTechnicalObjects,
        };
        this.isLoaded = true;
        this.isLoading = false;
      });
    } catch (error) {
      console.log('ERROR IN INSPECTION FETCHING: ', error);
    }
  };

  getInspectionName = (routeId) => {
    const route = this.routesDictionary.find(item => item.id === routeId);
    const routeName = get(route, 'name', '');
    return routeName;
  }

  @action saveInspection = async (data) => {
    this.isUpdating = true;
    const expectedStartDate = formatToISOString(set(new Date(data.expectedStartDate), { hours: 0, minutes: 0 }));
    const formattedData = {
      ...data,
      title: this.getInspectionName(data.routeId),
      currentAssignee: data.currentAssignee.id,
      expectedStartDate,
      assignments: [...data.assignments.map(item => item.id), data.currentAssignee.id],
    };

    try {
      const res = await inspectionsAgent.save(formattedData);
      runInAction(() => {
        this.isUpdating = false;
      });
      return { res };
    } catch (error) {
      console.log('ERROR IN INSPECTION SAVING: ', error);
      runInAction(() => {
        this.isUpdating = false;
      });
      return { error };
    }
  };

  @action deleteInspection = async (id) => {
    try {
      const res = inspectionsAgent.delete(id);
      return res;
    } catch (error) {
      console.log('ERROR IN INSPECTION DELETEING: ', error);
      return error;
    }
  };

  @action loadShiftDictionary = async () => {
    try {
      const shiftDictionary = await inspectionsAgent.loadShiftDictionary() || [];
      runInAction(() => {
        this.shiftDictionary = shiftDictionary;
        this.isShiftsDictionaryLoaded = true;
      });
      return { error: false };
    } catch (error) {
      console.log('ERROR IN LOAD SHIFT DICTIONARY: ', error);
      return { error };
    }
  }

  @action loadReasonDictionaries = async () => {
    try {
      const operationRejectReasonDictionary = await inspectionsAgent.loadOperationRejectReasonDictionary() || [];
      const taskRejectionReasonsDictionary = await taskAgent.getDictionaryByKey({ key: 'TaskRejectionReason' }) || [];
      const operationPauseReasonDictionary = await inspectionsAgent.loadOperationPauseReasonDictionary() || [];
      const scanFailReasonDictionary = await dictionaryAgent.loadScanFailReasonDictionary() || [];
      runInAction(() => {
        this.taskRejectionReasonsDictionary = taskRejectionReasonsDictionary.filter(item => startsWith(item.enumValue, 'COAL'));
        this.operationRejectReasonDictionary = operationRejectReasonDictionary;
        this.operationPauseReasonDictionary = operationPauseReasonDictionary;
        this.scanFailReasonDictionary = scanFailReasonDictionary;
        this.isDictionariesLoaded = true;
      });
      return { error: false };
    } catch (error) {
      console.log('ERROR IN LOAD DICTIONARIES: ', error);
      return { error };
    }
  }

  @action loadDepartments = async () => {
    try {
      const rootId = get(this.rootStore, 'userStore.auth.rootOrgUnit.id');
      const departments = await unitsAgent.getDepartmentsByOrgUnit(rootId);

      if (departments.length === 1) {
        runInAction(() => {
          this.inspection.departmentId = departments[0].id;
          this.isDepartmentsReadonly = true;
        });
      }
      runInAction(() => {
        this.departmentsDictionary = departments;
        this.isDepartmentsLoaded = true;
      });
      return { error: false };
    } catch (error) {
      console.log('ERROR IN LOADING DEPARTMENTS DICTIONARY: ', error);
      return { error };
    }
  }

  @action loadRoutesDictionary = async (departmentId) => {
    if (!departmentId) return;
    this.isRoutesLoading = true;
    try {
      const routesDictionary = await inspectionsAgent.loadCoalRoutesDictionary(departmentId) || [];
      runInAction(() => {
        this.routesDictionary = routesDictionary;
        this.isRoutesLoading = false;
      });
      return { error: false };
    } catch (error) {
      console.log('ERROR IN LOADING ROUTES DICTIONARY: ', error);
      runInAction(() => {
        this.isRoutesLoading = false;
      });
      return { error };
    }
  }

  @action discardState = () => {
    runInAction(() => {
      this.inspection = initialData;
      this.isLoading = false;
      this.isLoaded = false;
      this.isDepartmentsReadonly = false;
      this.isDictionariesLoaded = false;
      this.isShiftsDictionaryLoaded = false;
      this.isDepartmentsLoaded = false;
    });
  };
}

export default CoalInspectionStore;
