import React from 'react';

import withStyles from 'Src/utils/table/withColumnStyles';
import { formatDate, formatTime } from 'Src/utils/datetime';
import TaskStatusCell from 'Business/coal/components/TaskStatusCell';
import DefectStatusCell from 'Business/coal/components/DefectStatusCell';
import PhotoLink from 'Business/coal/components/PhotoLink';

const tableColumns = t => (
  {
    title: {
      field: 'title',
      title: t('DEFECT_NAME'),
      withEllipsis: true,
      mainLink: true,
    },
    equipment: {
      field: 'equipment',
      title: t('EQUIPMENT'),
      withEllipsis: true,
    },
    sapCode: {
      field: 'sapCode',
      title: t('SAP_CODE'),
      withEllipsis: true,
    },
    department: {
      field: 'department',
      title: t('DEPARTMENT'),
      withEllipsis: true,
    },
    createdDate: {
      field: 'createdDate',
      title: t('DEFECT_DATE'),
      withEllipsis: true,
      render: ({ createdDate = new Date().toISOString() }) => `${formatDate(createdDate)} ${formatTime(createdDate)}`,
    },
    taskStatus: {
      field: 'taskStatus',
      title: t('TASK_STATUS'),
      // eslint-disable-next-line
      render: ({ taskStatus }) => (<TaskStatusCell taskStatus={taskStatus} />),
    },
    defectState: {
      field: 'defectState',
      title: t('STATUS'),
      // eslint-disable-next-line
      render: ({ defectState }) => <DefectStatusCell defectState={defectState} />,
    },
    reporterName: {
      field: 'reporterName',
      title: t('IDENTIFIED'),
      withEllipsis: true,
    },
    controllerName: {
      field: 'controllerName',
      title: t('RESPONSIBLE'),
      withEllipsis: true,
    },
    fixerName: {
      field: 'fixerName',
      title: t('DEFECT_RESOLVED_BY'),
      withEllipsis: true,
    },
    defectsPhotoFolderLink: {
      field: 'defectsPhotoFolderLink',
      title: '',
      // eslint-disable-next-line
      render: ({ defectsPhotoFolderLink }) => <PhotoLink link={defectsPhotoFolderLink} />,
    },
  }
);

export const statusesColumns = (t) => {
  const translatedTableColumns = tableColumns(t);
  return ({
    ACTUAL: {
      columns: [
        withStyles(translatedTableColumns.title, { width: '20%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.equipment, { width: '15%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.sapCode, { width: '20%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.department, { width: '15%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.createdDate, { width: '10%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.reporterName, { width: '15%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.defectsPhotoFolderLink, { width: '5%', whiteSpace: 'normal' }),
      ],
      actions: []
    },
    IN_PROGRESS: {
      columns: [
        withStyles(translatedTableColumns.title, { width: '20%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.equipment, { width: '15%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.sapCode, { width: '20%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.department, { width: '15%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.createdDate, { width: '10%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.taskStatus, { width: '15%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.defectsPhotoFolderLink, { width: '5%', whiteSpace: 'normal' }),
      ],
      actions: []
    },
    CANCELED: {
      columns: [
        withStyles(translatedTableColumns.title, { width: '20%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.equipment, { width: '15%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.sapCode, { width: '15%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.department, { width: '15%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.createdDate, { width: '10%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.controllerName, { width: '10%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.defectState, { width: '10%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.defectsPhotoFolderLink, { width: '5%', whiteSpace: 'normal' }),
      ],
      actions: []
    },
    ARCHIVE: {
      columns: [
        withStyles(translatedTableColumns.title, { width: '20%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.equipment, { width: '15%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.sapCode, { width: '20%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.department, { width: '15%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.createdDate, { width: '10%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.fixerName, { width: '15%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.defectsPhotoFolderLink, { width: '5%', whiteSpace: 'normal' }),
      ],
      actions: []
    }
  });
};

export default tableColumns;
