import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import EmployeeListItem from 'Src/business/tpsRepairs/components/Employee/EmployeeListItem';
import withValidation from 'Src/HOC/withValidation';
import BrigadesStaffValidationBlock from 'Src/business/tpsRepairs/components/BrigadesStaffValidationBlock';

import useStyles from './styles';

const BrigadesStaff = ({
  handleToggle,
  employeesState,
  toggleBrigadier,
  handleRemoveFromBrigade,
  error,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.selectedSection}>
      <List>
        <BrigadesStaffValidationBlock employeesState={employeesState} error={error} />
        {Boolean(employeesState.selected.length)
          && employeesState.selected.map(employee => (
            <EmployeeListItem
              employee={employee}
              handleToggle={handleToggle}
              isSelected
              toggleBrigadier={toggleBrigadier}
              handleRemoveFromBrigade={handleRemoveFromBrigade(employee.id)}
              key={employee.id}
              isBrigadier={employeesState.brigadier.id === employee.id}
            />
          ))}
      </List>
    </div>
  );
};

BrigadesStaff.defaultProps = {
  error: false,
};

BrigadesStaff.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  employeesState: PropTypes.shape({
    brigadier: PropTypes.shape({
      id: PropTypes.string,
    }),
    selected: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
    })),
    checked: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
    })),
  }).isRequired,
  toggleBrigadier: PropTypes.func.isRequired,
  handleRemoveFromBrigade: PropTypes.func.isRequired,
  error: PropTypes.bool,
};

export default withValidation(BrigadesStaff);
