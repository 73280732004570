import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import useStores from 'Store/useStores';
import { get, startsWith } from 'lodash';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import Panel, { PanelBox, PanelTitle } from 'Common/widgets/Layout/Panel/Panel';
import TextInput from 'Common/components/Forms/Input/Text';
import FormSwitch from 'Common/components/Forms/Input/FormSwitch';
import { OPERATION_KEYS_SETTINGS } from 'Shared/constants/routes';
import { THERE_ARE_VOLUMES_WITH_OPERATION_KEY } from 'Src/shared/constants/responseErrors/errors';
import { Tooltip } from '@material-ui/core';

const OperationKeysItem = ({ mode }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const { id } = params;

  const {
    operationKeysSettingsStore: {
      loadList,
      list,
      isListLoading,
      getOperationById,
      operationKeyData,
      updateOperationKey,
      addOperationKey,
      discardEditorState,
      isAddingOperationKey,
      isUpdatingOperationKey,
      updateField,
    },
    notificationStore: { enqueueSnackbar },
    validationStore: { validateRequiredFields, isValidFields, hasErrors },
  } = useStores();

  const [isValidKey, setIsValidKey] = useState(true);

  const isEditingMode = mode === 'edit';

  const {
    key = '',
    isActive = true,
    name = '',
  } = operationKeyData;

  const goToList = () => {
    history.push({
      pathname: OPERATION_KEYS_SETTINGS,
    });
  };

  const updateItem = async () => {
    validateRequiredFields();
    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('CANT_UPDATE'),
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    const response = await updateOperationKey() || {};
    const { error } = response;
    if (error) {
      const errorMessage = get(error, 'data.message', '');
      const isInUse = startsWith(errorMessage, THERE_ARE_VOLUMES_WITH_OPERATION_KEY);
      let firstMessageRow = t('CANT_UPDATE');
      let secondMessageRow = t('REQUEST_DEFAULT_ERROR');
      if (isInUse) {
        updateField('isActive', true);
        firstMessageRow = t('CANT_CHANGE_STATE');
        secondMessageRow = t('OPERATION_KEY_IS_USED_IN_VOLUMES_PARAMS');
      }
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: firstMessageRow,
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: secondMessageRow,
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: `${t('CHANGES_SUCCESSFULLY_APPLIED')}!`,
              },
            ],
          },
        ],
      },
      variant: 'success',
    });
    goToList();
  };

  const isKeyUnique = operationKey => !list.some(i => i.key === operationKey);

  const saveItem = async () => {
    validateRequiredFields();
    if (!isKeyUnique(key)) {
      setIsValidKey(false);
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('CANT_SAVE'),
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('KEY_ALREADY_EXISTS'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('CANT_SAVE'),
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    const response = await addOperationKey() || {};
    const error = get(response, 'error');
    if (error) {
      const exceptionType = get(error, 'data.exceptionType');
      const isDictionaryDuplicateKeyException = exceptionType === 'DictionaryDuplicateKeyException';
      const errorMessage = isDictionaryDuplicateKeyException ? t('REASON_WITH_SAME_CODE_EXISTS') : t('REQUEST_DEFAULT_ERROR');
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('CANT_SAVE'),
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: errorMessage,
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('OPERATION_KEY_SUCCESSFULLY_ADDED'),
              },
            ],
          },
        ],
      },
      variant: 'success',
    });
    goToList();
  };

  const showDefaultErrorNotification = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('REQUEST_DEFAULT_ERROR'),
              },
            ],
          },
        ],
      },
      variant: 'error',
    });
  };

  const prepareEditor = async () => {
    await loadList().catch(() => {
      showDefaultErrorNotification();
    });
    if (isEditingMode) {
      getOperationById(id).catch(() => {
        showDefaultErrorNotification();
      });
    }
  };

  useEffect(() => {
    prepareEditor();
    return () => discardEditorState();
  }, []);
  return (
    <>
      {(isUpdatingOperationKey || isAddingOperationKey || isListLoading) && <ProgressBar />}
      <Panel
        mode={mode}
        saveItem={mode === 'edit' ? updateItem : saveItem}
        goBack={goToList}
        viewItem={goToList}
        labelEdit={t('OPERATION_KEY_EDITING')}
        labelCreate={t('OPERATION_KEY_ADDING')}
        withActions
      >
        <PanelBox>
          <PanelTitle>{t('OPERATION_KEY_ATTRIBUTES')}</PanelTitle>
          <div style={{ width: '50%' }}>
            <Tooltip title={isActive ? t('ACTIVE_KEY') : t('INACTIVE_KEY')}>
              <div>
                <FormSwitch
                  label={t('ACTIVE_KEY')}
                  name="isActive"
                  checked={isActive}
                  onChange={() => updateField('isActive', !isActive)}
                  data-test="switch-isActive"
                />
              </div>
            </Tooltip>
          </div>
          <div style={{ width: '50%' }}>
            <TextInput
              error={!isValidKey}
              label={t('OPERATION_KEY')}
              value={key}
              name="key"
              onChange={(value) => {
                setIsValidKey(true);
                updateField('key', value);
              }}
              required
              isReadonly={isEditingMode}
              data-test="key"
            />
          </div>
          <TextInput
            multiline
            label={t('NAME')}
            value={name}
            name="name"
            onChange={value => updateField('name', value)}
            required
            data-test={name}
          />
        </PanelBox>
      </Panel>
    </>
  );
};

OperationKeysItem.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default observer(OperationKeysItem);
