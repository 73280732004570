import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import useStores from 'Store/useStores';

import rules from 'Src/RBAC/rbacRules';
import rulesDev from 'Src/RBAC/rbacRulesDEV';

export const hasPermission = (environment, businessType, role, action) => {
  const runTimeRules = (environment === 'prod' || environment === 'beta') ? rules : rulesDev;
  const permissions = businessType && get(runTimeRules, `${businessType}.${role}`, []);
  return permissions && permissions.includes(action);
};

const Can = ({ perform, yes, no }) => {
  const {
    userStore: {
      businessType,
      role,
      environment,
    },
  } = useStores();
  return hasPermission(environment, businessType, role, perform) ? yes() : no();
};

Can.propTypes = {
  perform: PropTypes.string.isRequired,
  yes: PropTypes.func,
  no: PropTypes.func,
};

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default observer(Can);
