/* eslint-disable */
import Box from '@material-ui/core/Box';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { observer } from 'mobx-react';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import Header from 'Common/widgets/Layout/Header/Header';
import * as routes from 'Shared/constants/routes';
import useStores from 'Store/useStores';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import CheckpointCard from 'Business/tpsInspections/components/CheckpointCard';
import { taskStatuses } from 'Shared/constants/taskStatuses';
import TaskProgress from 'Common/components/TaskProgress';
import CheckpointBlockTitle from 'Business/tpsInspections/components/CheckpointBlockTitle';
import OptionRenderer from 'Common/components/Forms/OptionRenderer';
import StyledLink from 'Common/components/StyledLink';
import DetailsBlock from 'Common/components/Forms/DetailsBlock';

const useStyles = makeStyles(theme => ({
  commonDetails: {
    borderBottom: `1px solid ${theme.palette.midGray}`
  },
  taskTitle: {
    color: theme.palette.primaryLight
  },
}));

const TaskPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();
  const {
    tpsCreatedTaskViewStore: {
      loadTaskViewPageState,
      isTaskLoaded,
      expectedStartDateTime,
      expectedFinishDateTime,
      factStartDateTime,
      factFinishDateTime,
      discardState,
      task: {
        title,
        routeTitle,
        assigneeRole,
        taskStatus,
        factDuration,
        planDuration,
        checkpoints = [],
        automatedTask = {},
      }
    }
  } = useStores();

  const translatedTaskStatuses = taskStatuses(t);
  const automatedTaskId = get(automatedTask, 'automatedTaskId');
  const automatedTaskTitle = get(automatedTask, 'title');

  const checkpointIds = checkpoints.map(({ id }) => id);
  const location = useLocation();
  const [expandedCheckpoints, setExpandedCheckpoints] = useState([]);
  const [isAllExpanded, setIsAllExpanded] = useState(false);

  const handleToggleCheckpoints = () => {
    setExpandedCheckpoints(() => isAllExpanded ? [] : checkpointIds);
    setIsAllExpanded(!isAllExpanded);
  };

  const handleExpand = (checkpointId) => () => {
    if (expandedCheckpoints.includes(checkpointId)) {
      setExpandedCheckpoints(prevState => prevState.filter(id => checkpointId !== id));
    } else {
      setExpandedCheckpoints(prevState => [...prevState, checkpointId]);
    }
  };

  const getProgressConfig = () => {
    if (factDuration === 0 && planDuration === 0) {
      return {
        factDurationPercentage: 0,
        planDurationPercentage: 0,
      }
    }
    const basis = factDuration > planDuration ? factDuration : planDuration;
    const factDurationPercentage = factDuration / basis * 100;
    const planDurationPercentage = planDuration / basis * 100;

    return {
      factDurationPercentage,
      planDurationPercentage,
    }
  };

  const isExpanded = (id) => expandedCheckpoints.includes(id);

  const renderCheckpoints = () => (
    <Box margin={3} marginTop={0} marginBottom={0} paddingBottom={3}>
      {checkpoints.map(checkpoint => (
        <CheckpointCard
          checkpoint={checkpoint}
          key={checkpoint.id}
          onExpand={handleExpand}
          isExpanded={isExpanded(checkpoint.id)}
        />
      ))}
    </Box>
  );

  const goToTasksList = (shouldReplace = false) => {
    const historyAction = shouldReplace ? history.replace : history.push;
    const { state } = location;
    const { fromPath, ...rest } = state || {};
    historyAction({
      pathname: fromPath || routes.TASKS,
      state: rest,
    });
  };

  useEffect(() => {
    if (!id) {
      goToTasksList(true);
    }

    loadTaskViewPageState(id);
  }, [id]);

  useEffect(() => () => { discardState(); }, [location.key]);

  if (!isTaskLoaded) {
    return <ProgressBar />
  }

  return (
    <PaperLayout>
      <Header
        isActiveGoBack
        sticky
        title={t('TASK_VIEW')}
        handleGoBack={goToTasksList}
        titleGoBack={t('GO_BACK')}
      />
      <Grid container spacing={1} className={classes.commonDetails}>
        <Grid item xs={6}>
          <DetailsBlock title={t('TASK_ATTRIBUTES')}>
            <OptionRenderer
              value={translatedTaskStatuses[taskStatus] || taskStatus}
              title={t('STATE')}
            />
            {automatedTaskId && automatedTaskTitle && (
              <OptionRenderer
                name="automatedTaskLink"
                value={automatedTaskTitle}
                valueRenderer={value => (
                  <StyledLink to={`${routes.INSPECTIONTASKS}/${automatedTaskId}`}>{value}</StyledLink>
                )}
                title={t('AUTOMATED_TASK_NAME')}
                valueClass={classes.taskTitle}
              />
            )}
            <OptionRenderer
              value={routeTitle}
              title={t('ROUTE')}
            />
            <OptionRenderer
              value={assigneeRole}
              title={t('EMPLOYEE')}
            />
            <OptionRenderer
              value={expectedStartDateTime}
              title={t('PLANNED_START_TIME')}
            />
            <OptionRenderer
              value={expectedFinishDateTime}
              title={t('PLANNED_END_TIME')}
            />
          </DetailsBlock>
        </Grid>
        <Grid item xs={6}>
          <DetailsBlock title={t('EXECUTION_RESULT')}>
            <TaskProgress
              planDuration={planDuration}
              factDuration={factDuration}
              {...getProgressConfig()}
            />
            <Grid container justify="space-between">
              <div>
                <OptionRenderer
                  value={factStartDateTime}
                  title={t('FACT_START_TIME')}
                  data-test="factStartDateTime"
                />
              </div>
              <div>
                <OptionRenderer
                  value={factFinishDateTime}
                  title={t('FACT_END_TIME_LONG')}
                  titlePosition="right"
                  data-test="factFinishDateTime"
                />
              </div>
            </Grid>
          </DetailsBlock>
        </Grid>
      </Grid>
      <CheckpointBlockTitle isExpanded={isAllExpanded} onExpandClick={handleToggleCheckpoints}/>

      {renderCheckpoints()}

    </PaperLayout>
  );
};

export default observer(TaskPage);
