import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  hovered: {
    '&:hover': {
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.16)',
    },
  },
  cardHeader: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    '& .MuiCardHeader-title': {
      fontSize: 18,
      fontWeight: 'bold'
    },
    '& .MuiCardHeader-subheader': {
      fontSize: 16,
      color: theme.palette.black30,
      marginTop: theme.spacing(1),
    },
  },
  boxes: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: 1,
      height: '100%',
      top: 0,
      left: theme.spacing(1),
      background: theme.palette.gray,
      zIndex: 1,
    }
  },
  boxesClicked: {
    '& > .MuiBox-root': {
      '&::before': {
        display: 'none !important'
      },
    }
  },
  changeableIndicator: {
    '&::after': {
      background: theme.palette.primaryLight,
    },
  },
  notChangeableIndicator: {
    '&::after': {
      background: theme.palette.gray,
    },
  },
  boxWrapper: {
    margin: '16px 0px',
    paddingLeft: theme.spacing(3),
    position: 'relative',
    zIndex: 2,
    '&::after': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: 9,
      height: 9,
      top: '50%',
      left: 4,
      borderRadius: '50%',
      transform: 'translate(0, -50%)'
    },
    '&:first-child:before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: 1,
      height: '50%',
      background: theme.palette.white,
      top: 0,
      left: theme.spacing(1),
    },
    '&:last-child:before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: 1,
      height: '50%',
      background: theme.palette.white,
      top: '50%',
      left: theme.spacing(1),
    },
    '&:first-child:last-child:after': {
      display: 'none'
    },
    '&:first-child:last-child:before': {
      top: 0,
      height: '100%'
    },
    '&:hover': {
      '& $boxButtonDelete': {
        display: 'block',
      },
    },
  },
  boxWrapperDrag: {
    '&::before': {
      display: 'none !important'
    },
    '&::after': {
      transform: 'translate(0, -50%) scale(1.25)'
    },
    '& > .MuiBox-root': {
      boxShadow: '0 5px 10px 0 rgba(0,0,0, .1)',
    }
  },
  boxInner: {
    padding: theme.spacing(2),
    display: 'block',
    textAlign: 'left',
    width: '100%',
    transition: 'all .2s'
  },
  boxInnerChangeable: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(6),
  },
  box: {
    position: 'relative',
    padding: 0,
    background: theme.palette.white,
    boxShadow: '0 1px 3px 0 #f3f3f3',
    border: 'solid 1px #f3f3f3',
    fontSize: 16,
    userSelect: 'none',
    transition: 'all .4s'
  },
  boxDragHandler: {
    display: 'flex',
    position: 'absolute',
    cursor: 'row-resize',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: `inset -1px 0 0 ${theme.palette.gray}`,
    left: 0,
    top: 0,
    height: '100%',
    width: 34,
    background: theme.palette.lightGray,
    color: theme.palette.black30,
    zIndex: 2
  },
  boxTitle: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  boxSubTitle: {
    fontSize: 16,
    color: theme.palette.black30
  },
  boxButtonDelete: {
    display: 'none',
    zIndex: 3,
    position: 'absolute',
    top: 5,
    right: 5,
    color: theme.palette.negative,
  }
}));

export default useStyles;
