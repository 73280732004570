import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Reorder as ReorderIcon } from '@material-ui/icons';
import { ReactComponent as GridIcon } from 'Src/assets/grid.svg';
import { Tooltip, IconButton } from '@material-ui/core';

import useStyles from './styles';


const ModeSwitchers = ({ mode, setMode }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Tooltip title={t('TILE_DISPLAY')}>
        <IconButton
          data-test="filtersButton"
          size="medium"
          className={clsx(classes.iconButton, mode === 'card' && classes.active)}
          onClick={() => setMode('card')}
        >
          <GridIcon
            className={clsx(classes.icon, mode === 'card' && classes.active)}
            data-test="gridIconButton"
          />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('LIST_DISPLAY')}>
        <IconButton
          data-test="filtersButton"
          size="medium"
          className={classes.iconButton}
          onClick={() => setMode('list')}
        >
          <ReorderIcon
            className={clsx(classes.icon, mode === 'list' && classes.active)}
            data-test="listIconButton"
          />
        </IconButton>
      </Tooltip>
    </>
  );
};

ModeSwitchers.propTypes = {
  mode: PropTypes.string.isRequired,
  setMode: PropTypes.func.isRequired,
};

export default ModeSwitchers;
