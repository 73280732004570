import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ReportProblemOutlined,
  PauseCircleOutlineOutlined,
} from '@material-ui/icons';
import {
  ButtonBase
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ReactComponent as IconVoltmeter } from 'Assets/voltmeter.svg';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import Header from 'Common/widgets/Layout/Header/Header';
import { DEFECTS_DICTIONARIES, PAUSE_REASONS_DICTIONARIES } from 'Shared/constants/routes';

import useStyles from './styles';

const SelectDictionary = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const goToDefectsDictionaries = () => {
    history.push({
      pathname: DEFECTS_DICTIONARIES,
    });
  };

  const goToPauseReasonsDictionaries = () => {
    history.push({
      pathname: PAUSE_REASONS_DICTIONARIES,
    });
  };
  return (
    <PaperLayout>
      <Header title={t('DICTIONARIES')} />
      <div className={classes.block}>
        <div className={classes.blockHeader}>
          {t('USERS_DICTIONARIES')}
        </div>
        <div className={classes.row}>
          <ButtonBase
            className={classes.item}
            onClick={goToDefectsDictionaries}
            data-test="defectsButton"
          >
            <div className={classes.iconWrapper}>
              <ReportProblemOutlined />
            </div>
            <div className={classes.itemText}>{t('DEFECTS_FOR_RE_REGISTRATION')}</div>
          </ButtonBase>
          <ButtonBase
            className={classes.item}
            onClick={goToPauseReasonsDictionaries}
            data-test="pauseReasonsButton"
          >
            <div className={classes.iconWrapper}>
              <PauseCircleOutlineOutlined />
            </div>
            <div className={classes.itemText}>{t('PAUSE_REASONS')}</div>
          </ButtonBase>
        </div>
        <div className={classes.row}>
          <ButtonBase
            disabled
            className={classes.item}
            data-test="measurementsUnitsButton"
          >
            <div className={classes.iconWrapper}>
              <IconVoltmeter />
            </div>
            <div className={classes.itemText}>{t('MEASUREMENTS_UNITS')}</div>
          </ButtonBase>
        </div>
      </div>
    </PaperLayout>
  );
};

export default SelectDictionary;
