import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  block: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 24,
    borderBottom: `1px solid ${theme.palette.midGray}`
  },
  blockHeader: {
    padding: '24px 0px 18px 0px',
  },
  blockHeaderLabel: {
    fontSize: 20,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 2.4,
    letterSpacing: 'normal',
    color: theme.palette.black,
  },
  layout: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  materialValue: {
    maxWidth: '50%',
    flex: '50%',
    resize: 'none',
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  firstInRow: {
    paddingRight: 24
  },
  secondInRow: {
    paddingLeft: 24
  },
}));

export default useStyles;
