import { ApplicationInsights } from '@microsoft/applicationinsights-web';


const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: 'c91bc6ab-d600-4f14-b49f-2aa8762b19b4',
    maxBatchInterval: 0,
    disableFetchTracking: false,
  }
});

appInsights.loadAppInsights();

export default appInsights;
