import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  title: ({ noSpacing }) => ({
    marginBottom: noSpacing ? 0 : theme.spacing(1),
    marginTop: noSpacing ? 0 : theme.spacing(1),
  }),
  font: props => ({
    fontSize: props.isSubtitle
      ? theme.typography.fontSize
      : theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightBold
  })
}));

const SectionTitle = ({
  text, isSubtitle, noSpacing, ...props
}) => {
  const classes = useStyles({ isSubtitle, noSpacing });

  return (
    <Typography
      variant="h6"
      gutterBottom
      className={`${classes.title} ${classes.font}`}
      {...props}
    >
      {text}
    </Typography>
  );
};

SectionTitle.propTypes = {
  text: PropTypes.string.isRequired,
  isSubtitle: PropTypes.bool,
  noSpacing: PropTypes.bool,
};

SectionTitle.defaultProps = {
  isSubtitle: false,
  noSpacing: false,
};

export default SectionTitle;
