import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  warningBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.black30,
    marginTop: 24,
    fontSize: 16
  },
  optionRenderer: {
    marginLeft: 14,
    marginTop: 16,
  },
}));

export default useStyles;
