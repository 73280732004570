import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { find, get } from 'lodash';
import Modal from 'Common/components/Modal/Modal';
import { Box, TextField, Typography } from '@material-ui/core';
import ActionButton from 'Common/components/Forms/Button/components/Action/Action';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { REASON_OTHER } from 'Src/shared/constants/reasons';

const PlannerTaskPauseModal = ({
  reasons = [],
  classes = {},
  onClose,
  onSave,
  title,
}) => {
  const { t } = useTranslation();
  const [reason, reasonSet] = useState('');
  const [comment, commentSet] = useState('');
  const isCommentRequired = reason && get(find(reasons, { id: reason }), 'isCommentRequired', false);

  const handleSave = () => {
    onSave(reason, comment);
  };

  const canSave = () => (reason === REASON_OTHER && comment.trim()) || (reason && reason !== REASON_OTHER);

  return (
    <Modal
      size="md"
      handleClose={onClose}
      title={t('PAUSING_A_TASK')}
      titleStyle={{
        borderBottom: '1px solid #f3f3f3',
        fontSize: 24,
        lineHeight: '40px',
        margin: '-24px -24px 0',
        padding: '15px 24px 15px'
      }}
      contentStyle={{
        margin: 0
      }}
      isOpened
      actions={(
        <Box
          {...classes && { className: classes.actions }}
          style={{
            padding: 0,
            margin: 0,
            height: 'auto',
            border: 'none'
          }}
        >
          <ActionButton
            text={t('CANCEL')}
            type="cancel"
            handleClick={onClose}
            style={{ marginRight: 24 }}
          />
          <ActionButton
            disabled={!canSave()}
            text={t('SAVE')}
            type="confirm"
            handleClick={handleSave}
          />
        </Box>
      )}
    >
      <Typography className={classes.title} style={{ marginTop: 10, fontWeight: 'bold' }} variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography className={classes.header} style={{ marginTop: 10 }} gutterBottom>
        {t('ENTER_REJECTION_REASON')}
      </Typography>

      <FormControl className={classes.reasonFieldset} component="fieldset">
        <RadioGroup
          aria-label="reason"
          name="reason"
          value={reason}
          onChange={e => reasonSet(e.target.value)}
        >
          {reasons.map(({ id, title: reasonTitle }) => (
            <FormControlLabel
              key={id}
              value={id}
              control={<Radio />}
              label={reasonTitle}
            />
          ))}
        </RadioGroup>
      </FormControl>

      {isCommentRequired && (
        <TextField
          id="other_reason"
          variant="outlined"
          label={t('COMMENT')}
          rows={4}
          fullWidth
          value={comment}
          style={{
            margin: '0 10px',
            maxWidth: 'calc(100% - 20px)'
          }}
          onChange={event => commentSet(event.target.value)}
          multiline
        />
      )}

    </Modal>
  );
};

PlannerTaskPauseModal.propTypes = {
  // eslint-disable-next-line
  reasons: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  classes: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default PlannerTaskPauseModal;
