const taskTabsConfig = t => [
  {
    title: t('INFORMATION'),
    alias: 'common',
  },
  // {
  //   title: t('DOCUMENTATION'),
  //   alias: 'docs',
  // },
  {
    title: t('HISTORY'),
    alias: 'history',
  }
];

export default taskTabsConfig;
