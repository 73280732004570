import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import useStores from 'Store/useStores';
import { Box } from '@material-ui/core';
import Input from 'Common/components/Forms/Input/Input';

import { useTranslation } from 'react-i18next';

const RepairType = (props) => {
  const { t } = useTranslation();
  const {
    coalTaskStore: {
      taskData,
      repairTypesDictionary,
      onChangeRepairType,
    },
  } = useStores();

  const {
    isStandard,
    repairTypeSapCode,
  } = taskData;

  const { isReadonlyByMode } = props;
  if (!repairTypeSapCode && isReadonlyByMode) return null;
  return (
    <Box css={{ maxWidth: 500 }}>
      <Input
        required={isStandard}
        fullOutlined
        label={t('TYPE_OF_REPAIR')}
        placeholder={t('SELECT_VALUE')}
        value={repairTypeSapCode}
        name="repairTypeSapCode"
        disabled={isEmpty(repairTypesDictionary)}
        onChange={onChangeRepairType}
        type="selectAlpha"
        isReadonly={isReadonlyByMode}
        options={repairTypesDictionary || []}
        optionValueKey="id"
        optionTitleKey="title"
      />
    </Box>
  );
};

RepairType.propTypes = {
  isReadonlyByMode: PropTypes.bool.isRequired,
};
export default observer(RepairType);
