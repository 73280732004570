import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: '16px 24px 24px 24px',
    backgroundColor: ({ showAddUserHelper }) => (showAddUserHelper ? theme.palette.white : theme.palette.default),
  },
  userInfo: {
    color: theme.palette.black30,
    fontSize: 16,
    lineHeight: 1.5,
    padding: '0px 36px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  userInfoString: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      marginRight: 12,
    },
  },
}));

export default useStyles;
