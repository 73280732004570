import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import useStores from 'Store/useStores';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import HeaderLayout from 'Common/widgets/Layout/Header/Header';
import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import DataTable from 'Common/components/DataTable/DataTable';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import * as routes from 'Shared/constants/routes';
import useManageEntityModal from 'Common/components/Modal/ManageEntityModal/useManageEntityModal';
import { INITIAL_PAGE_NUMBER } from 'Shared/constants/paging';
import tableConfig from 'Business/tpsInspections/config/taskRoutesTableConfig';

const TaskRouteList = observer(() => {
  const {
    taskRouteStore: {
      isDeleting,
      isLoading,
      isLoaded,
      items,
      deleteRoute,
      getItemsBy: loadRoutesList,
      removeRouteFromList,
      totalPages,
      isLoadingByPage,
      hasNextPage,
    },
    notificationStore: { enqueueSnackbar },
  } = useStores();
  const { t } = useTranslation();
  const history = useHistory();
  const {
    setManageEntityModalVisibility, setOnManageEntityModalCloseCallback, setManageEntityModalState, renderManageEntityModal
  } = useManageEntityModal();

  const translatedTableConfig = tableConfig(t);

  const createHandler = () => {
    history.push({
      pathname: routes.CREATE_TASK_ROUTE
        .replace(':action(create)', 'create')
    });
  };

  const editHandler = (event, row) => {
    history.push({
      pathname: routes.EDIT_TASK_ROUTE
        .replace(':id', row.id)
        .replace(':action(edit)', 'edit')
    });
  };

  const onRouteClickHandler = (event, rowData = {}) => {
    if (rowData.id) {
      history.push({
        pathname: `${routes.TASK_ROUTES}/${rowData.id}`
      });
    }
  };

  const handleDeleteRoute = id => async () => {
    const { error } = await deleteRoute(id) || {};
    if (error) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('REQUEST_DEFAULT_ERROR')
            }]
          }],
        },
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('TASK_ROUTE_SUCCESSFULLY_REMOVED')
          }]
        }],
      },
      variant: 'success',
    });
    removeRouteFromList(id);
  };

  const removeHandler = (event, { id, name, isEditable }) => {
    if (isEditable) {
      setManageEntityModalState(() => ({
        title: `${t('DELETING')} ${t('ROUTE_ACCUSATIVE')}`,
        entityName: name,
        message: `${t('CONFIRM_DELETING_MESSAGE')} ${t('ROUTE').toLowerCase()}?`,
        type: 'action',
      }));
      setOnManageEntityModalCloseCallback(() => handleDeleteRoute(id));
      setManageEntityModalVisibility(true);
    } else {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: `${t('REMOVAL_IS_PROHIBITED')} ${t('THERE_ARE_OUTSTANDING_TASKS_ALONG_THIS_ROUTE')}`
            }]
          }],
        },
        variant: 'error',
      });
    }
  };

  const showLoadListError = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('REQUEST_LOAD_LIST_ERROR')
          }]
        }],
      },
      variant: 'error',
    });
  };

  useEffect(() => {
    loadRoutesList(INITIAL_PAGE_NUMBER).catch(() => showLoadListError());
  }, []);

  if (!isLoaded || isLoading) {
    return <ProgressBar />;
  }

  return (
    <>
      {isLoadingByPage && <ProgressBar />}
      {isDeleting && <ProgressBar />}
      <PaperLayout autoHeight>
        {isLoadingByPage && <ProgressBar />}
        <HeaderLayout title={t('ROUTES')} sticky>
          <HeaderButton
            title={t('CREATE_ROUTE')}
            type="add"
            handleClick={createHandler}
          />
        </HeaderLayout>
        <DataTable
          isDataLoaded={isLoaded}
          titleSingle={t('ROUTE').toLowerCase()}
          columns={translatedTableConfig}
          data={items}
          onRowClick={onRouteClickHandler}
          onEdit={editHandler}
          onRemove={removeHandler}
          count={totalPages}
          hasNextPage={hasNextPage}
          onPageChange={(page, size) => loadRoutesList(page, size).catch(() => showLoadListError())}
          onPerPageChange={(page, size) => loadRoutesList(page, size).catch(() => showLoadListError())}
          actions={['remove']}
          dataTestRowSuffixEntityName="id"
        />
      </PaperLayout>
      {renderManageEntityModal()}
    </>
  );
});

TaskRouteList.propTypes = {};

export default TaskRouteList;
