import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  historyWrapper: {
    margin: '8px 0 16px 16px',
    position: 'relative',
    '&::before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: 1,
      height: '100%',
      background: theme.palette.gray,
      top: 0,
      left: 4
    }
  },
}));

const History = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.historyWrapper}>
      {children}
    </div>
  );
};

History.propTypes = {
  children: PropTypes.node.isRequired,
};

export default History;
