import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import {
  get, isEqual, isEmpty, find,
} from 'lodash';
import { Box, Grid } from '@material-ui/core';
import useStores from 'Store/useStores';
import Input from 'Common/components/Forms/Input/Input';
import FormSwitch from 'Common/components/Forms/Input/FormSwitch';
import DottedLoader from 'Common/components/Progress/components/Dotted';
import { useTranslation } from 'react-i18next';
import EquipmentOption from './part/EquipmentOption';

const Equipment = (props) => {
  const { t } = useTranslation();
  const { isReadonlyByMode } = props;
  const {
    coalTaskStore: {
      taskData,
      equipmentDictionary = [],
      equipmentDictionaryIsLoading,
      onChangeEquipment,
    },
    notificationStore: { enqueueSnackbar },
  } = useStores();

  const {
    isStandard,
    equipmentSapCode,
  } = taskData;

  const [showHierarchy, setShowHierarchy] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (isEmpty(equipmentDictionary)) return;
    if (!showHierarchy && !isReadonlyByMode) {
      const equipmentObj = find(equipmentDictionary, { sapCode: equipmentSapCode });
      const parentsIds = get(equipmentObj, 'parentsIds', []);
      setOptions(equipmentDictionary.filter(({ level, sapCode, technicalObjectId }) => level === 0 || equipmentSapCode === sapCode || parentsIds.includes(technicalObjectId)));
    } else {
      setOptions(equipmentDictionary);
    }
  }, [showHierarchy, equipmentSapCode, equipmentDictionary, isReadonlyByMode]);

  const handleEquipmentChange = (equipmentObj) => {
    const technicalObjectId = get(equipmentObj, 'technicalObjectId', null);
    if (isEqual(equipmentSapCode, technicalObjectId)) return;
    const currentEquipment = find(equipmentDictionary, { technicalObjectId });
    const { repairType } = currentEquipment || {};
    if (equipmentObj && isEmpty(repairType) && isStandard) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('NO_REPAIR_TYPES_SELECT_ANOTHER_EQUIPMENT')
            }]
          }],
        },
        variant: 'warning',
      });
      onChangeEquipment({});
      return;
    }
    onChangeEquipment(equipmentObj);
  };

  if (!equipmentSapCode && isReadonlyByMode) return null;
  return (
    <Box css={{ maxWidth: 500 }}>
      <Grid container>
        <Grid item xs>
          <DottedLoader
            isLoading={equipmentDictionaryIsLoading}
          >
            <Input
              marginBottom={24}
              listBoxMaxHeight={400}
              required={isStandard}
              fullOutlined
              label={t('EQUIPMENT')}
              value={equipmentSapCode}
              name="equipmentSapCode"
              placeholder={t('SELECT_VALUE')}
              disabled={isEmpty(equipmentDictionary)}
              onChange={handleEquipmentChange}
              type="selectAlpha"
              withSearch
              isReadonly={isReadonlyByMode}
              options={options}
              customRender={({ title: label, level, sapCode } = {}) => (
                <EquipmentOption
                  label={label}
                  level={level}
                  sapCode={sapCode}
                />
              )}
              optionValueKey="sapCode"
              optionTitleKey="title"
            />
          </DottedLoader>
        </Grid>
        {!isReadonlyByMode && (
          <Grid item style={{ marginLeft: 24 }}>
            <FormSwitch
              disabled={isEmpty(equipmentDictionary)}
              label={t('HIERARCHY')}
              name="showHierarchy"
              checked={showHierarchy}
              onChange={(e) => {
                const checked = get(e, 'target.checked');
                setShowHierarchy(checked);
              }}
              data-test="switch-showHierarchy"
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

Equipment.propTypes = {
  isReadonlyByMode: PropTypes.bool.isRequired,
};

export default observer(Equipment);
