import React from 'react';
import withStyles from 'Src/utils/table/withColumnStyles';
import { Tooltip } from '@material-ui/core';
import IsActiveOperationKeySwitcher from 'Business/plant/components/IsActiveOperationKeySwitcher';

const tableColumns = t => (
  {
    isActive: {
      field: 'isActive',
      title: t('STATE'),
      // eslint-disable-next-line
      render: ({ id, isActive, isUpdating }) => {
        return (
          <Tooltip title={isActive ? t('ACTIVE_KEY') : t('INACTIVE_KEY')}>
            <div>
              <IsActiveOperationKeySwitcher
                id={id}
                isActive={isActive}
                isUpdating={isUpdating}
              />
            </div>
          </Tooltip>
        );
      },
    },
    key: {
      field: 'key',
      title: t('KEY'),
      mainLink: true,
      withEllipsis: true,
    },
    name: {
      field: 'name',
      title: t('NAME'),
      withEllipsis: true,
    },
  }
);

export const tableConfig = (t) => {
  const translatedTableColumns = tableColumns(t);
  return (
    {
      columns: [
        withStyles(translatedTableColumns.isActive, { width: '15%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.key, { width: '30%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.name, { width: '55%', whiteSpace: 'normal' }),
      ],
      actions: []
    }
  );
};

export default tableColumns;
