export const OPERATION_STATUS = 'OperationStatus';
export const ORG_UNIT_SAP_CODE = 'OrgUnitSapCode';
export const TASK_REPORT_TYPE = 'TaskReportType';
export const TASKS_STATUS = 'TaskStatus';
export const TASK_TYPE = 'TaskType';
export const TECH_OBJECT_TYPE = 'TechObjectType';
export const TPS_INSPECTION_ROLES = 'TpsInspectionRoles';
export const TPS_REPAIR_ROLES = 'TpsRepairRoles';
export const TPS_ALTERNATIVE_SCRIPT_FOR_SCANNING_CHECKPOINT = 'TpsAlternativeScriptForScanningCheckpoint';
export const TPS_CHECKPOINT_NFC_SCAN_FAIL_REASON = 'TpsCheckpointNfcScanFailReason';
export const TPS_CHECKPOINT_PAUSE_REASON = 'TpsCheckpointPauseReason';
export const TPS_CHECKPOINT_REJECT_REASON = 'TpsCheckpointRejectReason';
export const COAL_DEFECT_STATE = 'CoalDefectState';

export default {
  [OPERATION_STATUS]: OPERATION_STATUS,
  [ORG_UNIT_SAP_CODE]: ORG_UNIT_SAP_CODE,
  [TASK_REPORT_TYPE]: TASK_REPORT_TYPE,
  [TASKS_STATUS]: TASKS_STATUS,
  [TASK_TYPE]: TASK_TYPE,
  [TECH_OBJECT_TYPE]: TECH_OBJECT_TYPE,
  [TPS_INSPECTION_ROLES]: TPS_INSPECTION_ROLES,
  [TPS_REPAIR_ROLES]: TPS_REPAIR_ROLES,
  [TPS_ALTERNATIVE_SCRIPT_FOR_SCANNING_CHECKPOINT]: TPS_ALTERNATIVE_SCRIPT_FOR_SCANNING_CHECKPOINT,
  [TPS_CHECKPOINT_NFC_SCAN_FAIL_REASON]: TPS_CHECKPOINT_NFC_SCAN_FAIL_REASON,
  [TPS_CHECKPOINT_PAUSE_REASON]: TPS_CHECKPOINT_PAUSE_REASON,
  [TPS_CHECKPOINT_REJECT_REASON]: TPS_CHECKPOINT_REJECT_REASON,
  [COAL_DEFECT_STATE]: COAL_DEFECT_STATE,
};
