import React from 'react';
import BrigadesList from 'Src/business/tpsRepairs/components/BrigadesList/BrigadesList';
import { withRouter } from 'react-router-dom';


const BrigadeListContainer = () => (
  <BrigadesList />
);

export default withRouter(BrigadeListContainer);
