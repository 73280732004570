import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import { observer } from 'mobx-react';
import useStores from 'Store/useStores';
import { autorun } from 'mobx';

const defaultOptions = {
  withDismissButton: true,
  autoHideDuration: 5000,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

const Notifier = ({ enqueueSnackbar }) => {
  const {
    notificationStore: {
      notifications,
      removeSnackbar
    },
  } = useStores();

  useEffect(() => autorun(() => {
    notifications.forEach((notification) => {
      const {
        messageTemplate, variant, options = {}, key
      } = notification;
      let action;
      const mergedOptions = { ...defaultOptions, ...options };
      const { withDismissButton, ...restOptions } = mergedOptions;
      enqueueSnackbar(
        JSON.stringify({
          messageTemplate,
          withDismissButton,
          variant
        }),
        {
          ...restOptions,
          variant,
          action,
          persist: variant === 'error'
        }
      );
      removeSnackbar(key);
    });
  }), [notifications]);

  return null;
};

Notifier.defaultProps = {
  enqueueSnackbar: () => {},
};

Notifier.propTypes = {
  enqueueSnackbar: PropTypes.func,
};

export default withSnackbar((observer(Notifier)));
