import Divider from '@material-ui/core/Divider';
import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const spacing = 3;

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 24,
  },
  noSpacing: {
    margin: '0 !important',
    padding: '0 !important'
  },
  content: {
    paddingTop: theme.spacing(spacing),
    paddingBottom: theme.spacing(spacing),
  },
  contentWrapper: {
    '&:first-child': {
      paddingTop: theme.spacing(spacing),
    }
  },
  actions: {
    padding: theme.spacing(spacing),
  },
  paper: {
    minWidth: 445,
    width: ({ modalPaperWidth }) => (modalPaperWidth || '100%'),
    padding: 0
  }
}));

const Modal = ({
  size,
  title,
  titleStyle,
  contentStyle,
  children,
  isOpened,
  handleClose,
  actions,
  modalPaperWidth,
  ...rest
}) => {
  const classes = useStyles({ modalPaperWidth });

  return (
    <Dialog
      open={isOpened}
      onClose={handleClose}
      maxWidth={size}
      classes={{ paper: classes.paper }}
      data-test="manage-entity-modal"
      {...rest}
    >
      <DialogContent className={classes.contentWrapper} style={contentStyle}>
        {title && (
          <Typography
            data-test="modal-title"
            className={classes.title}
            style={titleStyle}
            variant="h5"
          >
            {title}
          </Typography>
        )}
        <Box className={title ? classes.content : classes.noSpacing}>{children}</Box>
      </DialogContent>
      {
        actions && (
          <>
            <Divider className={classes.divider} />
            <DialogActions className={classes.actions}>
              {actions}
            </DialogActions>
          </>
        )
      }
    </Dialog>
  );
};

Modal.propTypes = {
  size: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  modalPaperWidth: PropTypes.number,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  // eslint-disable-next-line
  titleStyle: PropTypes.any,
  // eslint-disable-next-line
  contentStyle: PropTypes.any,
  children: PropTypes.node.isRequired,
  actions: PropTypes.node,
  isOpened: PropTypes.bool.isRequired,
};

Modal.defaultProps = {
  size: 'xs',
  modalPaperWidth: undefined,
  title: '',
  titleStyle: {},
  contentStyle: {},
  actions: false,
  handleClose: () => {},
};

export default Modal;
