import React from 'react';

import useStyles from './styles';

const InspectionFAQ = () => {
  const classes = useStyles();

  return (
    <>
      <h1 className={classes.title}>Вопросы по бизнесу “ТЭС Обходы”</h1>

      <div className={classes.question}>
        - Как создавать задачи для «ТЭС Обходы»?
      </div>
      <div className={classes.answer}>
        <p>
          Задачи для «ТЭС Обходы» не создаются вручную. Вам необходимо создать и настроить автозадачу в разделе «Автозадачи», которая сама будет создавать задачи по заданному Вами алгоритму.
        </p>
      </div>

      <div className={classes.question}>
        - При создании автозадачи в «ТЭС Обходы» нет ТЭС/исполнителя или они не мои?
      </div>
      <div className={classes.answer}>
        <p>
          Доступность выбора некоторых полей регулируется Вашими правами доступа в систему. Права выдаются заявками на доступ от Вашей организации. Обратитесь в соответствующий отдел для добавления/изменения необходимых прав доступа.
        </p>
      </div>

      <div className={classes.question}>
        - При создании автозадачи в «ТЭС Обходы» нет нужного маршрута?
      </div>
      <div className={classes.answer}>
        <p>
          Все маршруты, которые Вы видите при создании автозадачи, создаются и хранятся в разделе “Маршруты”. Если при создании автозадачи нужного маршрута нет, предварительно создайте маршрут в разделе «Маршруты».
        </p>
      </div>

      <div className={classes.question}>
        - При создании/редактировании маршрута в «ТЭС Обходы» нет нужной метки?
      </div>
      <div className={classes.answer}>
        <p>
          Все метки, которые Вы видите при создании маршрута, создаются и хранятся в разделе “Метки”. Если при создании маршрута нужной метки нет, предварительно создайте метку в разделе «Метки».
        </p>
      </div>

      <div className={classes.question}>
        - При создании/редактировании маршрута в «ТЭС Обходы» нет энергоблока или не мои?
      </div>
      <div className={classes.answer}>
        <p>
          Доступность выбора некоторых полей регулируется Вашими правами доступа в систему. Права выдаются заявками на доступ от Вашей организации. Обратитесь в соответствующий отдел для добавления/изменения необходимых прав доступа.
        </p>
      </div>

      <div className={classes.question}>
        - Не могу редактировать/удалить маршрут или метку в «ТЭС Обходы»?
      </div>
      <div className={classes.answer}>
        <p>
          Маршрут или метка, который Вы хотите изменить/удалить используется в задаче/задачах, которые находятся в работе у исполнителя. Изменить/удалить маршрут или метку возможно только если он не используется в других задачах в
          работе.
        </p>
      </div>

      <div className={classes.question}>
        - Нет кнопки создания рапортички в «ТЭС Обходы»?
      </div>
      <div className={classes.answer}>
        <p>
          Доступность выбора некоторых кнопок регулируется Вашими правами доступа в систему. Права выдаются заявками на доступ от Вашей организации. Обратитесь в соответствующий отдел для добавления/изменения необходимых прав доступа.
        </p>
      </div>

      <div className={classes.question}>
        - Когда создаются рапортички в «ТЭС Обходы»?
      </div>
      <div className={classes.answer}>
        <p>
          Создание рапортичек привязано к работе бригад на предприятиях ДТЕК. Все бригады работают по 8 часов по четко установленному временному графику. Весь день разделен на промежутки для 3 бригад и четкое время, когда каждая из них может создавать рапортички.
        </p>
        <p>
          1 бригада - работает с 0:00 до 8:00, рапортички создаются в промежутке с 7:00 до 7:40.
        </p>
        <p>
          2 бригада - работает с 8:00 до 16:00, рапортички создаются в промежутке с 15:00 до 15:40.
        </p>
        <p>
          3 бригада - работает с 16:00 до 24:00, рапортички создаются в промежутке с 23:00 до 23:40.
        </p>
      </div>
    </>
  );
};

export default InspectionFAQ;
