import { SPECIALIST_SDKR, ECONOMIST_IETS } from 'Shared/constants/roles';
import { materialValuesAgreementsAliases } from 'Src/business/tpsRepairs/config/materialValuesAgreementsAliases';

const canConfirmMaterialValues = (operationStatus, role) => {
  let canReturn = false;
  if (operationStatus === materialValuesAgreementsAliases(it => it).NEW.name && (role === SPECIALIST_SDKR || role === ECONOMIST_IETS)) canReturn = true;
  return canReturn;
};

export default canConfirmMaterialValues;
