import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import 'Shared/translations/i18n';
import { Provider } from 'mobx-react';
import rootStore from 'Store/RootStore';
import theme from './theme';
import App from './App';
import './App.scss';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Provider
      rootStore={rootStore}
      userStore={rootStore.userStore}
      notificationStore={rootStore.notificationStore}
      tasksStore={rootStore.tasksStore}
      equipmentStore={rootStore.equipmentStore}
      brigadeStore={rootStore.brigadeStore}
      inspectionStore={rootStore.inspectionStore}
      inspectEquipmentStore={rootStore.inspectEquipmentStore}
      inspectEmployeeStore={rootStore.inspectEmployeeStore}
      checkpointStore={rootStore.checkpointStore}
      checkpointStoreNEW={rootStore.checkpointStoreNEW}
      checkpointActionsStore={rootStore.checkpointActionsStore}
      taskRouteStore={rootStore.taskRouteStore}
      taskRouteManageStore={rootStore.taskRouteManageStore}
      inspectionTaskStore={rootStore.inspectionTaskStore}
      coalTaskStore={rootStore.coalTaskStore}
      coalTaskListFilterStore={rootStore.coalTaskListFilterStore}
      coalTaskListStore={rootStore.coalTaskListStore}
      coalRouteStore={rootStore.coalRouteStore}
      coalRoutesListStore={rootStore.coalRoutesListStore}
      taskReportStore={rootStore.taskReportStore}
      dictionaryStore={rootStore.dictionaryStore}
      tpsInspectCreatedTaskListStore={rootStore.tpsInspectCreatedTaskListStore}
      tpsInspectTaskListFilterStore={rootStore.tpsInspectTaskListFilterStore}
      tpsCreatedTaskViewStore={rootStore.tpsCreatedTaskViewStore}
      measurementReportListStore={rootStore.measurementReportListStore}
      tpsRequestReportStore={rootStore.tpsRequestReportStore}
      tpsReportStore={rootStore.tpsReportStore}
      volumeStore={rootStore.volumeStore}
      usersListStore={rootStore.usersListStore}
      usersListFilterStore={rootStore.usersListFilterStore}
      adminUserStore={rootStore.adminUserStore}
      drawerStore={rootStore.drawerStore}
      materialValuesControlStore={rootStore.materialValuesControlStore}
      coalDefectsListStore={rootStore.coalDefectsListStore}
      coalInspectionsListStore={rootStore.coalInspectionsListStore}
      coalInspectionStore={rootStore.coalInspectionStore}
      coalDefectStore={rootStore.coalDefectStore}
      validationStore={rootStore.validationStore}
      defectsDictionaryStore={rootStore.defectsDictionaryStore}
      parallelOperationsStore={rootStore.parallelOperationsStore}
      presenceControlStore={rootStore.presenceControlStore}
      pauseReasonsDictionaryStore={rootStore.pauseReasonsDictionaryStore}
      operationKeysSettingsStore={rootStore.operationKeysSettingsStore}
      workplacesListStore={rootStore.workplacesListStore}
      workplacesItemStore={rootStore.workplacesItemStore}
      inspectionReportsStore={rootStore.inspectionReportsStore}
      sharePointSettingsStore={rootStore.sharePointSettingsStore}
      plannerTaskStore={rootStore.plannerTaskStore}
      plannerTaskListStore={rootStore.plannerTaskListStore}
    >
      <App />
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);
