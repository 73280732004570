import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import withValidation from 'Src/HOC/withValidation';

import useStyles from './styles';

const AddButtonWithValidation = ({
  title, iconComponent, visible, onClick, disabled, error, required
}) => {
  const { t } = useTranslation();
  const classes = useStyles({
    error, required, disabled, t
  });

  if (!visible) return null;

  return (
    <Button
      variant="outlined"
      color="primary"
      className={classes.addButton}
      onClick={onClick}
      data-test="add-checkpoint-button"
      fullWidth
      disabled={disabled}
      startIcon={iconComponent}
    >
      {title}
    </Button>
  );
};

AddButtonWithValidation.defaultProps = {
  title: '',
  iconComponent: null,
  visible: true,
  error: false,
  disabled: false,
  required: false,
};

AddButtonWithValidation.propTypes = {
  title: PropTypes.string,
  iconComponent: PropTypes.node,
  visible: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
};

export default withValidation(AddButtonWithValidation);
