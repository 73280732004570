import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  formControlRadio: {
    marginTop: ({ marginTop }) => (marginTop || theme.spacing(1)),
    marginBottom: ({ marginBottom }) => (marginBottom || theme.spacing(3)),
  },
}));

export default useStyles;
