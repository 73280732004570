import {
  action, makeObservable, runInAction, toJS,
} from 'mobx';
import {
  cloneDeep, find, set, get, head, assign, startsWith, values, isEqual, omit, last, some, findIndex // eslint-disable-line
} from 'lodash';
import isEmpty from 'lodash/isEmpty';
import UnitsAgent from 'ApiAgents/Units/UnitsAgent';
import EquipmentAgent from 'ApiAgents/Equipment/EquipmentAgent';
import TaskRouteAgent from 'ApiAgents/TaskRoutes/TaskRouteAgent';
import {
  EQUIPMENT, NODE, DEPARTMENT, ACTION
} from 'Business/coal/config/sectionTypeMapping';
import {
  HEAD_MECHANIC, MECHANIC,
} from 'Shared/constants/roles';
import { useTranslation } from 'react-i18next'; // eslint-disable-line

const initialState = {
  isEmptyDataReceived: false,
  routeName: '',
  isLoading: false,
  isUpdating: false,
  isAdding: false,
  activeMP: [],
  temporaryEditorActiveMP: [],
  techObjectsBranchIndexes: {},
  departments: [],
  structureDictionary: {},
  isLoadingTechnicalObjectsDictionary: true,
  isAddedDepartmentsToDictionary: false,
  isAddedSavedTechObjectsToDictionary: false,
  areTechObjectChildrenLoading: false,
  visibleSections: [],
  history: [],
  technicalObjects: [],
};

const unitsAgent = new UnitsAgent();
const equipmentAgent = new EquipmentAgent();
const taskRouteAgent = new TaskRouteAgent();

export class CoalRouteStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
    this.rootStore.prepareState(initialState, this);
  }

  routeName;

  isLoading;

  isEmptyDataReceived;

  isUpdating;

  isAdding;

  activeMP;

  temporaryEditorActiveMP;

  techObjectsBranchIndexes;

  technicalObjects;

  departments;

  areTechObjectChildrenLoading;

  structureDictionary;

  isLoadingTechnicalObjectsDictionary;

  isAddedDepartmentsToDictionary;

  isAddedSavedTechObjectsToDictionary;

  visibleSections;

  history;

  @action prepareForAdding = () => {
    this.history = [];
    this.visibleSections = [];
    this.temporaryEditorActiveMP = cloneDeep(this.activeMP);
  }

  @action onChangeRouteName = (value) => {
    this.routeName = value;
  }

  @action loadRouteById = async (routeId) => {
    try {
      this.isLoading = true;
      const [coalRoute = {}] = await taskRouteAgent.loadCoalRouteById(routeId) || [];


      const { name, technicalObjects = [] } = coalRoute;

      runInAction(() => {
        this.isEmptyDataReceived = isEmpty(coalRoute);
        this.isLoading = false;
        this.routeName = name;
        this.technicalObjects = technicalObjects;
      });
    } catch (error) {
      console.log('ERROR in LOADING ROUTE: ', error);
      runInAction(() => {
        this.isLoading = false;
        this.technicalObjects = [];
      });
    }
  }

  @action saveRoute = async (id) => {
    this.isUpdating = true;
    try {
      const requestData = {
        ...id && { id },
        name: this.routeName,
        technicalObjects: this.technicalObjects.map((technicalObject, index) => {
          const filteredTechnicalObject = omit(technicalObject, ['branchIds', 'branchIdsString']);
          const { isRequiredQrCode, measurementPoints } = filteredTechnicalObject;
          return ({
            ...filteredTechnicalObject,
            isRequiredQrCode: Boolean(isRequiredQrCode),
            order: index + 1,
            measurementPoints: measurementPoints.map(({ measurementPointId, title, ...rest }) => omit(({
              id: measurementPointId, name: title, measurementPointId, ...rest
            }), ['branchIds', 'branchIdsString'])),
          });
        }),
      };
      const res = await taskRouteAgent.createCoalRoute(requestData);

      runInAction(() => {
        this.isUpdating = false;
      });
      return { res };
    } catch (error) {
      console.log('ERROR in SAVE ROUTE: ', error);
      runInAction(() => {
        this.isUpdating = false;
      });
      return { error };
    }
  }

  @action setOverlayMode = (mode) => {
    switch (mode) {
      case 'edit': {
        this.isEditing = true;
        this.isAdding = false;
        break;
      }
      case 'add': {
        this.isEditing = false;
        this.isAdding = true;
        break;
      }
      default: {
        this.isEditing = false;
        this.isAdding = false;
      }
    }
  }

  @action prepareForEditing = (technicalObjectId) => {
    this.history = [];
    this.visibleSections = [];
    const technicalObjectBranch = get(this.techObjectsBranchIndexes, technicalObjectId.toString()) || [];
    if (!technicalObjectBranch) return;
    const branchIds = [];
    technicalObjectBranch.forEach((path) => {
      branchIds.push(path);
      const currentTechObj = get(this.structureDictionary, branchIds.join('.childrenObj.'));
      this.history.push(currentTechObj);
      const measurementPoints = get(currentTechObj, 'measurementPoints', []);
      const childrenObj = get(currentTechObj, 'childrenObj', []);
      if (!isEmpty(measurementPoints) && isEmpty(childrenObj)) {
        this.visibleSections.push({
          technicalObjectId,
          title: 'title',
          type: 'ACTION',
          parentsLength: this.history.length,
          items: measurementPoints,
        });
      }
    });
    this.temporaryEditorActiveMP = cloneDeep(this.activeMP);
  }

  @action saveEditorState = async () => {
    this.activeMP = cloneDeep(this.temporaryEditorActiveMP);
    const collectedTechnicalObjects = {};
    this.temporaryEditorActiveMP.forEach((item) => {
      const pathArray = item.split(',');
      const measurementId = last(pathArray);
      const technicalObjectBranchIndexes = pathArray.slice(0, -1);
      const technicalObjectPath = technicalObjectBranchIndexes.join('.childrenObj.');
      const { technicalObjectId, measurementPoints, ...rest } = get(this.structureDictionary, technicalObjectPath, {});
      const { isRequiredQrCode = false, order } = this.technicalObjects.find(i => i.id === technicalObjectId) || {};

      const currentMeasurementPoint = find(measurementPoints, ({ measurementPointId } = {}) => measurementId.toString() === measurementPointId.toString());
      if (!collectedTechnicalObjects[technicalObjectId]) {
        assign(collectedTechnicalObjects, {
          [technicalObjectId]: {
            ...rest, order, id: technicalObjectId, isRequiredQrCode, measurementPoints: [currentMeasurementPoint]
          }
        });
      } else {
        collectedTechnicalObjects[technicalObjectId].measurementPoints = [...collectedTechnicalObjects[technicalObjectId].measurementPoints, currentMeasurementPoint];
      }
    });
    const newTechnicalObjects = values(collectedTechnicalObjects);
    const tempArray = [...new Array(this.technicalObjects.length)];
    const newTempArray = [];
    newTechnicalObjects.forEach((newTechnicalObject) => {
      const { id } = newTechnicalObject;
      const order = findIndex(this.technicalObjects, { id });
      if (order >= 0) {
        tempArray[order] = newTechnicalObject;
      } else {
        newTempArray.push(newTechnicalObject);
      }
    });
    this.technicalObjects = [...newTempArray, ...tempArray.filter(it => it)];
  }

  @action handleDeleteTechnicalObject = (technicalObjectId) => {
    this.technicalObjects = this.technicalObjects.filter(
      ({ id }) => technicalObjectId !== id,
    );
    this.activeMP = this.activeMP.filter(item => item.indexOf(`,${technicalObjectId},`) < 0);
  }

  @action updateTechnicalObjects = (technicalObjects) => {
    this.technicalObjects = technicalObjects;
  }

  @action toggleIsRequiredQRCode = (technicalObjectId) => {
    this.technicalObjects = this.technicalObjects.map(({ id, isRequiredQrCode, ...rest }) => {
      if (technicalObjectId !== id) {
        return ({ id, isRequiredQrCode, ...rest });
      }
      return ({ id, isRequiredQrCode: !isRequiredQrCode, ...rest });
    });
  }

  @action discardState = () => {
    this.rootStore.setInitialState(initialState, this);
  }

  @action handleToggleAction = (branchIdsString) => { // checkbox clicked
    if (this.temporaryEditorActiveMP.includes(branchIdsString)) {
      this.temporaryEditorActiveMP = this.temporaryEditorActiveMP.filter(activeBranchIdsString => activeBranchIdsString !== branchIdsString);
    } else this.temporaryEditorActiveMP.push(branchIdsString);
  };

  handleDeleteBranch = branchIdsString => action(() => {
    this.temporaryEditorActiveMP = this.temporaryEditorActiveMP.filter(activeMP => !startsWith(activeMP, branchIdsString));
  });

  handleHistoryClick = technicalObjectId => action(() => {
    if (get(last(this.history), technicalObjectId) === technicalObjectId) return;
    const itemIndex = this.history.findIndex(historyItem => historyItem.technicalObjectId.toString() === technicalObjectId.toString());
    const historyItems = this.history.filter((it, index) => index <= itemIndex);
    if (this.history.length > 3 && itemIndex >= 2 && this.history[itemIndex + 1]) {
      this.handleListItemClick(this.history[itemIndex], true);
      return;
    }
    historyItems.forEach((historyItem) => {
      this.handleListItemClick(historyItem);
    });
  });

  isCurrentlySelected = id => this.history.some(({ technicalObjectId } = {}) => technicalObjectId === id);

  @action loadDepartments = async () => {
    const { auth: { department }, role } = this.rootStore.userStore;
    let departments = [department];
    try {
      if (role === HEAD_MECHANIC) {
        departments = await unitsAgent.getDepartmentsByOrgUnit(get(this.rootStore, 'userStore.auth.rootOrgUnit.id'));
      }
      runInAction(() => { // eslint-disable-line
        this.departments = departments;
      });
    } catch (error) {
      console.log('ERROR in LOADING DEPARTMENTS: ', error);
      throw new Error();
    }
  }

  addBranchIdsToMeasurementPoints = (measurementPoints, branchIds) => measurementPoints.map((measurementPoint) => {
    const { measurementPointId } = measurementPoint;
    const isSelected = some(this.technicalObjects, ({ measurementPoints: measurements = [] } = {}) => measurements.some(({ id } = {}) => measurementPointId === id));
    const measurementBranchIds = [...branchIds, measurementPointId.toString()];
    const measurementBranchIdsString = measurementBranchIds.join(',');
    if (isSelected) {
      this.activeMP = [...this.activeMP, measurementBranchIdsString];
    }
    return ({ ...measurementPoint, branchIds: measurementBranchIds, branchIdsString: measurementBranchIdsString });
  })

  addChildrenToStructureDictionary = ({
    children, branchIds, departmentId, departmentName
  }) => {
    if (!isEmpty(toJS(children))) {
      children.forEach((child) => {
        const { technicalObjectId, children: childChildren = [], measurementPoints = [] } = child;
        const childBranchIds = [...branchIds, technicalObjectId.toString()];
        const childBranchIdsString = childBranchIds.join(',');
        runInAction(() => {
          this.structureDictionary = set(this.structureDictionary, branchIds.map(branchId => `${branchId}.childrenObj`).join('.'), {
            [technicalObjectId.toString()]: {
              ...child,
              departmentId,
              departmentName,
              childrenObj: {},
              branchIds: childBranchIds,
              branchIdsString: childBranchIdsString,
              ...!isEmpty(measurementPoints) && { measurementPoints: this.addBranchIdsToMeasurementPoints(measurementPoints, childBranchIds) }
            }
          });
        });
        this.techObjectsBranchIndexes = { ...this.techObjectsBranchIndexes, [technicalObjectId.toString()]: childBranchIds };
        this.addChildrenToStructureDictionary({
          children: childChildren, branchIds: childBranchIds, departmentId, departmentName
        });
      });
    } else {
      this.temporaryEditorActiveMP = this.activeMP;
    }
  }

  @action addSavedTechObjectsToStructureDictionary = async () => { // добавляем в справочник информацию по сохраненным техническим объетам (edit mode)
    if (this.isAddedSavedTechObjectsToDictionary) return;
    const technicalObjectsIds = this.technicalObjects.map(({ id } = {}) => id);
    const { items } = await equipmentAgent.getTechObjectParentsByIdsArray(technicalObjectsIds);
    items.forEach((item) => {
      let techObj = cloneDeep(item);
      const { externalWorkPlaceId, children: [child] = [] } = techObj;
      let department = find(this.departments, { code: externalWorkPlaceId });
      if (!department) {
        techObj = child;
        const childExternalWorkPlaceId = get(child, 'externalWorkPlaceId');
        department = find(this.departments, { code: childExternalWorkPlaceId });
      }
      if (department) {
        const { technicalObjectId, children = [] } = techObj;
        const { id: departmentId, name: departmentName } = department;
        const rootTechObBranchIds = [departmentId.toString(), technicalObjectId.toString()];
        const rootTechObBranchIdsString = rootTechObBranchIds.join(',');
        const branchIds = [departmentId, technicalObjectId.toString()];
        this.techObjectsBranchIndexes = { ...this.techObjectsBranchIndexes, [technicalObjectId.toString()]: branchIds };

        if (!get(this.structureDictionary, departmentId)) {
          this.structureDictionary = {
            ...this.structureDictionary,
            [departmentId]: {
              ...department,
              technicalObjectId: departmentId,
              title: departmentName,
              branchIds: [departmentId.toString()],
              branchIdsString: departmentId.toString(),
              childrenObj: {
                [technicalObjectId.toString()]: {
                  ...techObj,
                  departmentId,
                  departmentName,
                  childrenObj: {},
                  branchIds: rootTechObBranchIds,
                  branchIdsString: rootTechObBranchIdsString
                }
              }
            }
          };
        } else {
          this.structureDictionary = set(this.structureDictionary, `${departmentId}.childrenObj`, {
            ...get(this.structureDictionary, `${departmentId}.childrenObj`, {}),
            [technicalObjectId.toString()]: {
              ...techObj,
              departmentId,
              departmentName,
              childrenObj: {},
              branchIds: rootTechObBranchIds,
              branchIdsString: rootTechObBranchIdsString
            }
          });
        }
        this.addChildrenToStructureDictionary({
          children,
          branchIds,
          departmentId,
          departmentName,
        });
      }
    });
    this.isAddedSavedTechObjectsToDictionary = true;
  }

  @action addDepartmentsToStructureDictionary = async () => {
    if (this.isAddedDepartmentsToDictionary) return;
    this.isLoadingTechnicalObjectsDictionary = true;
    try {
      const { role } = this.rootStore.userStore;
      let technicalObjects = {};
      if (role === MECHANIC) {
        const [techObjectsByFirstDepartment] = await Promise.all(
          this.departments.map(({ code: departmentCode }) => equipmentAgent.getTechObjectsByDepartment(departmentCode, 1))
        );
        technicalObjects = techObjectsByFirstDepartment.map(item => ({ ...item, isRoot: true }));
      } else {
        this.departments.forEach(({ name: departmentName, id: departmentId, code }) => {
          technicalObjects = {
            ...technicalObjects,
            [departmentId]: {
              title: departmentName,
              technicalObjectId: departmentId,
              isRoot: true,
              technicalObjectType: DEPARTMENT,
              technicalObjectCode: code,
              children: [],
              branchIds: [departmentId.toString()],
              branchIdsString: departmentId.toString()
            }
          };
        });
      }
      runInAction(() => {
        this.structureDictionary = technicalObjects;
        this.isLoadingTechnicalObjectsDictionary = false;
        this.isAddedDepartmentsToDictionary = true;
      });
    } catch (error) {
      runInAction(() => {
        this.isLoadingTechnicalObjectsDictionary = false;
        this.isAddedDepartmentsToDictionary = false;
      });
      console.log('ERROR in LOADING TECHNICAL OBJ DICT: ', error);
      throw new Error(error);
    }
  }

  getChildrenInfo = (branchIds) => {
    const pathToChildren = branchIds.map(branchId => branchId.toString()).join('.childrenObj.');
    const { childrenObj = {}, measurementPoints = [] } = get(this.structureDictionary, pathToChildren, {});
    if (isEmpty(toJS(childrenObj)) && !isEmpty(measurementPoints)) {
      return { children: measurementPoints, childrenType: 'measurementPoints' };
    }
    return { children: values(childrenObj), childrenType: 'technicalObject' };
  };

  lazyLoadChildren = async (clickedItem) => {
    const {
      technicalObjectId, branchIds, technicalObjectType, technicalObjectCode
    } = clickedItem;

    let temp = cloneDeep(this.structureDictionary);
    const isInTechObjectsBranchIndexes = get(this.techObjectsBranchIndexes, technicalObjectId);
    if (!isInTechObjectsBranchIndexes) {
      this.techObjectsBranchIndexes = { ...this.techObjectsBranchIndexes, [technicalObjectId.toString()]: branchIds };
    }
    try {
      const { childrenLoaded } = get(temp, toJS(branchIds).map(it => it.toString()).join('.childrenObj.'), {});
      if (!childrenLoaded) {
        this.areTechObjectChildrenLoading = true;
        if (technicalObjectType === 'DEPARTMENT') {
          const departmentChildren = await equipmentAgent.getTechObjectsByDepartment(technicalObjectCode, 1);
          const [branchId] = branchIds;
          temp[branchId].childrenLoaded = true;
          const alreadyLoadedChildren = temp[branchId].childrenObj; // eslint-disable-line
          let departmentChildrenObj = {};
          departmentChildren.forEach((child) => {
            const childMeasurements = get(child, 'measurementPoints');
            const childId = get(child, 'technicalObjectId', '').toString();
            const childBranchIds = [...toJS(branchIds), childId];
            const childBranchString = childBranchIds.join(',');
            departmentChildrenObj = {
              ...departmentChildrenObj,
              [childId.toString()]: {
                ...child,
                branchIds: childBranchIds,
                branchIdsString: childBranchString,
                childrenLoaded: false,
                ...childMeasurements && { measurementPoints: childMeasurements.map(measurementPoint => ({ ...measurementPoint, branchIds: [...childBranchIds, measurementPoint.measurementPointId.toString()], branchIdsString: [...childBranchIds, measurementPoint.measurementPointId.toString()].join(',') })) }

              }
            };
          });
          temp[branchId].childrenObj = { ...departmentChildrenObj, ...alreadyLoadedChildren };
          runInAction(() => {
            this.structureDictionary = temp;
            this.areTechObjectChildrenLoading = false;
          });
        } else {
          const [techObj] = await equipmentAgent.getTechObjectsByIds([technicalObjectId.toString()], 2) || [];
          const techObjChildren = get(techObj, 'children', []);
          let mappedChildrenObj = {};
          techObjChildren.forEach((child) => { // eslint-disable-line
            const childMeasurements = get(child, 'measurementPoints');
            const childId = get(child, 'technicalObjectId');
            const childDepartmentId = get(child, 'department.id');
            const childDepartmentName = get(child, 'department.name');
            const childBranchIds = [...toJS(branchIds), childId.toString()];
            const childBranchString = childBranchIds.join(',');
            mappedChildrenObj = {
              ...mappedChildrenObj,
              [childId.toString()]: {
                ...child,
                ...childDepartmentId && { departmentId: childDepartmentId },
                ...childDepartmentName && { departmentName: childDepartmentName },
                branchIds: childBranchIds,
                branchIdsString: childBranchString,
                childrenLoaded: false,
                ...childMeasurements && { measurementPoints: childMeasurements.map(measurementPoint => ({ ...measurementPoint, branchIds: [...childBranchIds, measurementPoint.measurementPointId.toString()], branchIdsString: [...childBranchIds, measurementPoint.measurementPointId.toString()].join(',') })) }

              }
            };
          });
          const alreadyLoadedChildren = get(temp, branchIds.map(branchId => `${branchId}.childrenObj`).join('.'), {});
          const mergedChildren = { ...mappedChildrenObj, ...alreadyLoadedChildren };
          temp = set(temp, branchIds.map(branchId => `${branchId}.childrenObj`).join('.'), mergedChildren);
          temp = set(temp, `${branchIds.map(branchId => branchId.toString()).join('.childrenObj.')}.childrenLoaded`, true);
          runInAction(() => {
            this.structureDictionary = temp;
            this.areTechObjectChildrenLoading = false;
          });
        }
      }
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.areTechObjectChildrenLoading = false;
      });
    }
  }

  @action handleListItemClick = async (clickedItem = {}, prevent = false) => {
    const {
      technicalObjectId, branchIds, title, isRoot = false, sectionIndex,
    } = clickedItem;

    let penultimateVisibleSectionIndex;
    let maxTechObjSections;
    const { role } = this.rootStore.userStore;

    switch (role) {
      case HEAD_MECHANIC:
        penultimateVisibleSectionIndex = 1;
        maxTechObjSections = 2;
        break;
      case MECHANIC:
        penultimateVisibleSectionIndex = 0;
        maxTechObjSections = 1;
        break;

      default:
        penultimateVisibleSectionIndex = 1;
        maxTechObjSections = 2;
    }

    if (this.visibleSections.length > 0) {
      this.visibleSections = this.visibleSections.filter((item = {}) => {
        const { branchIds: itemBranchIds } = item;
        return itemBranchIds.length <= branchIds.length - 1;
      });
    }
    await this.lazyLoadChildren(clickedItem);
    let itemType;
    const { children, childrenType } = this.getChildrenInfo(branchIds);
    const items = children || [];
    const [{ technicalObjectType: firstChildTechnicalObjectType } = {}] = items;
    if (isRoot) {
      this.history = [clickedItem];
      this.visibleSections = [];
      itemType = firstChildTechnicalObjectType;
    } else if (prevent) {
      this.history.length = branchIds.length;
      this.visibleSections = [];
    } else {
      this.history.length = branchIds.length - 1;
      this.history.push(clickedItem);
    }
    if (isEmpty(items)) {
      this.visibleSections.push({
        technicalObjectId,
        branchIds,
        title: '',
        type: ACTION,
        parentsLength: this.history.length,
        items,
      });
      return;
    }
    if (childrenType !== 'measurementPoints') {
      if (sectionIndex === penultimateVisibleSectionIndex) {
        this.visibleSections = this.visibleSections.slice(maxTechObjSections);
      }
      itemType = firstChildTechnicalObjectType;
      const typeOfLastInHistory = get(last(this.history), 'technicalObjectType');
      if (itemType === EQUIPMENT && typeOfLastInHistory === EQUIPMENT) itemType = NODE;
    } else {
      itemType = 'ACTION';
    }
    this.visibleSections.push({
      technicalObjectId,
      branchIds,
      title,
      type: itemType,
      parentsLength: this.history.length,
      items,
    });
  };
}

export default CoalRouteStore;
