import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import NumberInput from 'Common/components/Forms/Input/Number';

import useStyles from './styles';

const MaterialValueInput = ({
  value, unit, label, negativeColored, onChange
}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.factQuantityBlock, negativeColored && classes.negativeColor)}>
      <div className={classes.factQuantityLabel}>
        {label}
      </div>
      <NumberInput
        className={classes.quantityInput}
        label=""
        value={value}
        name="materialValueInput"
        InputEndAdornment={(
          <div className={classes.unitLabel}>
            {unit}
          </div>
        )}
        onChange={onChange}
        data-test="materialValueInput"
      />
    </div>
  );
};

MaterialValueInput.defaultProps = {
  label: '',
  negativeColored: false,
  onChange: null,
};

MaterialValueInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  unit: PropTypes.string.isRequired,
  label: PropTypes.string,
  negativeColored: PropTypes.bool,
  onChange: PropTypes.func,
};

export default MaterialValueInput;
