import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import useStores from 'Store/useStores';
import * as routes from 'Shared/constants/routes';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import Panel, { PanelBox, PanelTitle } from 'Common/widgets/Layout/Panel/Panel';
import FormSwitch from 'Common/components/Forms/Input/FormSwitch';
import TextInput from 'Common/components/Forms/Input/Text';

const USER_TEXT_MAX_LENGTH = 250;

const DefectsDictionaryItem = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation();
  const params = useParams();
  const { id } = params;

  const {
    defectsDictionaryStore: {
      defectData,
      getDefectDataById,
      updateDefectData,
      updateField,
      isUpdating,
      isDefectLoading,
    },
    notificationStore: { enqueueSnackbar },
    validationStore: { validateRequiredFields, isValidFields, hasErrors },
  } = useStores();

  const {
    isUnconstrained = false,
    shortText,
    code,
    folderCodeGroup,
    folderShortText,
    userText,
  } = defectData;

  const defectFolderName = `${folderCodeGroup} - ${folderShortText}`;
  const defectCodeName = `${code} - ${shortText}`;

  const goToList = () => {
    history.push({
      pathname: routes.DEFECTS_DICTIONARIES,
      state,
    });
  };

  const showDefaultErrorNotification = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('REQUEST_DEFAULT_ERROR'),
              },
            ],
          },
        ],
      },
      variant: 'error',
    });
  };

  const showServerIsNotAvailableNotification = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('CANT_SAVE'),
              },
            ],
          },
          {
            rowContent: [
              {
                type: 'text',
                text: t('SERVER_IS_NOT_AVAILABLE'),
              },
            ],
          },
        ],
      },
      variant: 'error',
    });
  };

  const updateItem = async () => {
    validateRequiredFields();
    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('CANT_UPDATE'),
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    const { error } = await updateDefectData();

    if (error) {
      showServerIsNotAvailableNotification();
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('DEFECT_SUCCESSFULLY_UPDATED'),
              },
            ],
          },
        ],
      },
      variant: 'success',
    });
    goToList();
  };

  useEffect(() => {
    getDefectDataById(id).catch(() => {
      showDefaultErrorNotification();
    });
  }, []);

  return (
    <>
      {(isUpdating || isDefectLoading) && <ProgressBar />}
      <Panel
        mode="edit"
        saveItem={updateItem}
        goBack={goToList}
        viewItem={goToList}
        labelGoToView={t('GO_BACK')}
        labelEdit={t('DEFECT_EDITING')}
        withActions
      >
        <PanelBox>
          <PanelTitle>{t('DEFECT_ATRIBUTES')}</PanelTitle>
          <FormSwitch
            label={t('REREGISTRATION_ALLOWED')}
            name="isUnconstrained"
            checked={isUnconstrained}
            onChange={() => updateField('isUnconstrained', !isUnconstrained)}
            data-test="switch-isUnconstrained"
          />
          <TextInput
            autoComplete="off"
            label={t('DEFECT_GROUP')}
            value={defectFolderName}
            name="defectFolderName"
            isReadonly
            data-test="defectFolderName"
          />
          <TextInput
            autoComplete="off"
            label={t('DEFECT_CODE_NAME')}
            value={defectCodeName}
            name="defectCodeName"
            isReadonly
            data-test="defectCodeName"
          />

          <TextInput
            multiline
            autoComplete="off"
            label={t('USER_TEXT')}
            value={userText}
            name="userText"
            onChange={value => updateField('userText', value)}
            inputProps={{
              maxLength: USER_TEXT_MAX_LENGTH
            }}
            data-test="userText"
          />
        </PanelBox>
      </Panel>
    </>
  );
};

export default observer(DefectsDictionaryItem);
