import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { ListItem, IconButton, ListItemText } from '@material-ui/core';
import {
  Close,
  Menu,
} from '@material-ui/icons';

import useStores from 'Store/useStores';
import drawerConfig from 'Shared/drawerConfig';
import Can from 'Common/components/Authorization/Can';
import useStyles from 'Common/widgets/Drawer/styles';

const MainDrawer = () => {
  const { t } = useTranslation();
  const translatedDrawerConfig = drawerConfig(t);
  const {
    drawerStore: {
      isDrawerOpened,
      toggleDrawerState
    }
  } = useStores();
  const classes = useStyles();
  /*eslint no-undef: "error"*/
  /*eslint-env browser*/
  const ver = VERSION; // eslint-disable-line
  /*eslint no-undef: "error"*/
  /*eslint-env browser*/
  const info = INFO; // eslint-disable-line

  useEffect(() => {
    if (ver) window.appVersion = ver;
    if (info) window.appInfo = info;
  });

  return (
    <div className={classes.root}>
      <div className={classes.drawer}>
        <div className={classes.menuButtonWrapper}>
          <IconButton
            color="inherit"
            aria-label="open menu"
            onClick={toggleDrawerState}
            edge="start"
            className={classes.menuButton}
          >
            {!isDrawerOpened && <Menu />}
            {isDrawerOpened && <Close />}
          </IconButton>
        </div>
        <Drawer
          open={false}
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, {
              [classes.drawerOpen]: isDrawerOpened,
              [classes.drawerClose]: !isDrawerOpened,
            })
          }}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: isDrawerOpened,
            [classes.drawerClose]: !isDrawerOpened,
          })}
          data-test="menu"
        >
          <div className={classes.toolbar} />
          <List className={classes.list}>
            {
              translatedDrawerConfig.map((item) => {
                const {
                  Icon, url, title, permission, disabled
                } = item;
                const renderLink = () => (
                  <NavLink
                    to={url}
                    activeClassName="nav-link-active"
                    key={title}
                    disabled={disabled}
                    data-test={url.slice(1)}
                  >
                    <ListItem button classes={{ root: classes.linkItem }}>
                      <Icon />
                      <ListItemText primary={item.title} />
                    </ListItem>
                  </NavLink>
                );
                if (!permission) {
                  return renderLink();
                }
                return (
                  <Can
                    variant="business"
                    perform={permission}
                    key={`${title}-${url}`}
                    yes={renderLink}
                  />
                );
              })
          }
          </List>
        </Drawer>
      </div>
    </div>
  );
};

export default observer(MainDrawer);
