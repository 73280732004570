import WorkplacesAgent from 'ApiAgents/Settings/WorkplacesAgent';
import UserAgent from 'ApiAgents/Users/UserAgent';
import UnitsAgent from 'ApiAgents/Units/UnitsAgent';
import { get, isEmpty } from 'lodash';
import {
  action, observable, runInAction, makeObservable
} from 'mobx';

const workplacesAgent = new WorkplacesAgent();
const userAgent = new UserAgent();
const unitsAgent = new UnitsAgent();

const initialWorkplace = {
  type: 1
};
export class WorkplacesItemStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable workplace = initialWorkplace;

  @observable rootOrgUnits = [];

  @observable departments = [];

  @observable workplaceTypes = [];

  @observable isItemLoading = false;

  @observable isDepartmentsLoading = false;

  @observable isTypesLoading = false;

  @action discardItem = () => {
    this.isItemLoading = false;
    this.workplace = initialWorkplace;
    this.departments = [];
  }

  @action loadItem = async (id) => {
    this.isListLoading = true;
    this.isListLoaded = false;
    try {
      const workplace = await workplacesAgent.getWorkplaceById(id);
      runInAction(() => {
        this.workplace = workplace;
        this.isItemLoading = false;
      });
    } catch (error) {
      console.log('ERROR LOAD WORKPLACE: ', error);
      runInAction(() => {
        this.workplace = initialWorkplace;
        this.isItemLoading = false;
      });
      throw new Error(error);
    }
  }

  @action save = async () => {
    try {
      const {
        title, type, code, department
      } = this.workplace;
      const departmentId = get(department, 'id');

      const response = await workplacesAgent.addWorkplace({
        title, type, code, departmentId
      });
      return { response };
    } catch (error) {
      console.log('ERROR SAVE WORKPLACE TYPES: ', error);
      return { error };
    }
  }

  @action update = async (id) => {
    try {
      const { title, type, department } = this.workplace;
      const departmentId = get(department, 'id');

      const response = await workplacesAgent.updateWorkplace({
        id, title, type, departmentId
      });
      return { response };
    } catch (error) {
      console.log('ERROR UPDATE WORKPLACE TYPES: ', error);
      return { error };
    }
  }

  @action loadWorkplaceTypes = async () => {
    if (!isEmpty(this.workplaceTypes)) return;
    this.isTypesLoading = true;
    try {
      const { items } = await workplacesAgent.getWorkplaceTypes() || {};
      const formattedData = items.map(({ value, key }) => ({
        value,
        title: key
      }));
      runInAction(() => {
        this.workplaceTypes = formattedData;
        this.isTypesLoading = false;
      });
    } catch (error) {
      console.log('ERROR LOAD WORKPLACE TYPES: ', error);
      runInAction(() => {
        this.workplaceTypes = [];
        this.isTypesLoading = false;
      });
      throw new Error(error);
    }
  }

  @action loadAllRootOrgUnits = async () => {
    if (!isEmpty(this.rootOrgUnits)) return;
    try {
      const rootOrgUnits = await userAgent.fetchAllRootOrgUnits();
      runInAction(() => {
        this.rootOrgUnits = rootOrgUnits;
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  @action loadDepartments = async (orgUnitId) => {
    if (!orgUnitId) {
      this.departments = [];
      return;
    }

    this.isDepartmentsLoading = true;

    try {
      const departments = await unitsAgent.getDepartmentsByOrgUnit(orgUnitId);
      runInAction(() => {
        this.departments = departments;
        this.isDepartmentsLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.isDepartmentsLoading = false;
      });
      throw new Error(error);
    }
  };

  @action updateField = (name, value) => {
    this.workplace[name] = value;
  };
}

export default WorkplacesItemStore;
