import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  inputDatePick: {
    '& .MuiFormControl-marginNormal': {
      margin: '10px 0'
    },
    '& .MuiIconButton-root': {
      padding: 7
    },
    '& .MuiInput-underline:before': {
      display: 'none!important'
    },
  },
  divider: {
    margin: '25px -20px 10px'
  },
}));

export default useStyles;
