import { makeStyles } from '@material-ui/core/styles';

// const reservedForStatus = 110;

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.white,
  },
  buttonBase: {
    textAlign: 'left',
    width: '100%',
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  wrapper: {
    width: '100%',
    position: 'relative',
    padding: 16,
    paddingLeft: ({ onClick } = {}) => (onClick ? 48 : 16),
    paddingRight: ({ onDelete } = {}) => (onDelete ? 48 : 16),
    boxShadow: `0 1px 3px 0 ${theme.palette.midGray}`,
    border: `solid 1px ${theme.palette.midGray}`,
    borderRadius: 4,
    fontSize: 14,
    color: theme.palette.black,
    maxWidth: ({ maxWidth }) => (maxWidth || 'auto'),
    '&:hover': {
      '& $headerActionButton': {
        display: 'block',
      },
      boxShadow: ({ boxShadowOnHover }) => (boxShadowOnHover ? '0 4px 8px 0 rgba(0, 0, 0, 0.16)' : '0px none')
    },
  },
  headerActionButtonWrapper: {
    marginLeft: 'auto',
    height: 4,
    resize: 'none',
    overflow: 'visible',
    alignSelf: 'flex-start',
  },
  headerActionButton: {
    display: ({ hideHeaderButtonOnBlur }) => (hideHeaderButtonOnBlur ? 'none' : 'block'),
  },
  checkbox: {
    position: 'absolute',
    top: 'calc(50% - 20px)',
    left: 8,
    height: 40,
    width: 40,
    '& .MuiSvgIcon-root': {
      color: theme.palette.primaryLight,
    },
  },
  deleteButton: {
    position: 'absolute',
    top: 'calc(50% - 20px)',
    right: 8,
    height: 40,
    width: 40,
    '&.MuiIconButton-root:hover': {
      backgroundColor: theme.palette.white,
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.primaryLight,
    },
    '&:hover': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.negative,
      },
    },
  },
  header: {
    display: 'flex',
    fontWeight: 'bold',
    color: theme.palette.black,
    marginBottom: 8,
  },
  headerTitleText: {
    fontSize: 16,
    lineHeight: 2,
  },
  status: {
    marginLeft: 'auto',
    display: 'flex',
    whiteSpace: 'nowrap',
    paddingLeft: 8,
    color: theme.palette.black30,
    '&.status-DONE': {
      color: theme.palette.positive,
    },
    '&.status-NEW': {
      color: theme.palette.positive,
    },
    '&.status-TODO': {
      display: 'none',
    },
    '&.status-REJECTED': {
      color: theme.palette.negative,
    },
    '&.status-ON_HOLD': {
      color: theme.palette.orange,
    },
    '&.status-IN_PROGRESS': {
      color: theme.palette.primary,
    },
  },
  subTitle: {
    fontSize: 14,
    color: theme.palette.black30,
    marginBottom: 8
  }
}));

export default useStyles;
