import { isArray } from 'lodash';

export const assignmentsMap = (assignments) => {
  if (!isArray(assignments)) return [];
  return assignments.map(({ identityId: assignmentId, identityName: assignmentName, ...restAssignmentProp }) => ({ ...restAssignmentProp, id: assignmentId, fullName: assignmentName }));
};

export const currentAssigneeMap = ({ identityId, identityName: currentAssigneeName, ...restCurrentAssigneeProp }) => ({
  ...restCurrentAssigneeProp, id: identityId, fullName: currentAssigneeName
});

export const operationsMap = (operations) => {
  if (!isArray(operations)) return [];
  return operations.map(({ sapCode, materialValues, ...rest }) => ({
    materialValues: isArray(materialValues) ? materialValues.map(({ sapCode: materialValueSapCode, ...restMaterialOptions }) => ({ materialValueSapCode, ...restMaterialOptions })) : null,
    operationSapCode: sapCode,
    ...rest
  }));
};

export const operationsMapforSaving = (operations) => {
  if (!isArray(operations)) return [];
  return operations.map(({
    shortText, number, materialValues, ...rest
  }) => ({
    operationSapCode: number,
    materialValues: isArray(materialValues) ? materialValues.map(({ materialNumber, ...restValues }) => ({ materialValueSapCode: materialNumber, ...restValues })) : null,
    ...rest
  }));
};

export const repairOperationsMap = (repairOperations) => {
  if (!isArray(repairOperations)) return [];
  return repairOperations.map(({
    shortText, number, materialValues, work, workUnit, fullText, ...rest
  }) => ({
    title: shortText,
    operationSapCode: number,
    materialValues: materialValues.map(({ materialName, materialNumber, ...restMaterialOptions }) => ({ description: materialName, materialValueSapCode: materialNumber, ...restMaterialOptions })),
    planDuration: work,
    durationUnit: workUnit,
    description: fullText,
    ...rest,
  }));
};

export default {
  assignmentsMap
};
