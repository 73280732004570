import Agent from 'ApiAgents/Agent';

export default class TaskReportAgent extends Agent {
  getReportTypes = rootOrgUnitCode => this.request({
    url: '/reports/types',
    method: 'GET',
    params: { rootOrgUnitCode },
  }, 'tasks');

  getTpsInspectionReportType = () => this.request({
    url: '/tpsInspectionReport/report-types',
    method: 'GET',
  }, 'tasks');

  createTpsInspectionReport = requestData => this.request({
    url: '/tpsInspectionReport/create-report',
    method: 'POST',
    params: requestData
  }, 'tasks');

  getTpsInspectionReport = reportId => this.request({
    url: `tpsInspectionReport/report/${reportId}`,
    method: 'GET',
  }, 'tasks');

  saveTpsInspectionReport = reportData => this.request({
    url: '/tpsInspectionReport/save-report',
    method: 'POST',
    data: reportData,
  }, 'tasks');

  updateTpsInspectionReport = reportData => this.request({
    url: '/tpsInspectionReport/save-report',
    method: 'PUT',
    data: reportData,
  }, 'tasks');


  downloadReport = (params) => {
    const {
      type, orgUnitCode, startDate, finishDate
    } = params;

    return this.request({
      url: '/reports/download',
      method: 'GET',
      responseType: 'blob',
      headers: { 'Content-Disposition': 'attachment', Accept: 'application/vnd.ms-excel' },
      params: {
        type,
        orgUnitCode,
        startDate,
        finishDate
      },
    }, 'tasks', true);
  };
}
