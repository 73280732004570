import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    backgroundColor: theme.palette.white,
    '& .actionButtons': {
      display: 'none',
    },
    '&:hover': {
      '& .actionButtons': {
        display: 'inline-block',
      },
    },
  },
  measurementPointsWrapper: {
    padding: 16,
    backgroundColor: theme.palette.midGray,
  },
  measurementPoint: {
    padding: 16,
    backgroundColor: theme.palette.white,
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  measurementPointTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 1.5,
    color: theme.palette.black,
  },
  measurementPointSubtitle: {
    fontSize: 16,
    lineHeight: 1.5,
    color: theme.palette.black30,
  },
  switcherWrapper: {
    height: 24,
    position: 'relative',
    overflow: 'visible',
  },
  switcher: {
    marginTop: 0,
    width: 110,
    position: 'absolute',
    top: 'calc(50% - 19px)',
    right: -16,
  },
  qrCodeIcon: {
    width: 24,
    height: 24,
  },
  cardCornerTitle: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: 14,
  },
  cardSubTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  sapCodeWrapper: {
    maxWidth: `calc(100% - ${theme.qrCodeSwitcherWidth}px)`,
  },
  headerActionButtons: {
    overflow: 'visible',
    height: 32,
  },
  collapseButton: {
    marginTop: -8,
    marginRight: -8,
    marginLeft: 4
  },
  collapseButtonWrapper: {
    // width: 40,
    // height: 40
  },
  iconButton: {
    marginLeft: 16,
    padding: 2,
  },
  deleteIconButton: {
    color: theme.palette.negative,
    '&:hover': {
      backgroundColor: theme.palette.pink,
    },
  },
  editIconButton: {
    color: theme.palette.primaryLight,
    '&:hover': {
      backgroundColor: theme.palette.lightSelection,
    },
  },
  value: {
    fontSize: 16,
    lineHeight: 1.5,
    color: theme.palette.black,
    marginRight: 4,
  },
  nfcScanFailReasonWrapper: {
    display: 'flex',
    fontSize: 16,
  },
  nfcScanFailReasonIcon: {
    color: theme.palette.negative,
    marginRight: 8,
  },
  valueUnitDateWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  measurementCommentWrapper: {
    display: 'flex',
    fontSize: 16,
  },
  measurementCommentIcon: {
    color: theme.palette.positive,
    transform: 'scaleX(-1)',
    marginRight: 8,
  },
  reasonComment: {
    fontSize: 16,
  },
}));

export default useStyles;
