import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  row: {
    // display: 'flex',
    padding: '24px 24px 0px 24px',
    '&:last-child': {
      paddingBottom: 24,
    },
  },
  rowLabel: {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 2.4,
    color: theme.palette.black,
    marginBottom: 16,
  },
  columnLabel: {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 2.4,
    color: theme.palette.black,
    marginBottom: 16,
  },
  reportRequestAttrHeaderLabel: {
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: 2.4,
    color: theme.palette.black,
    paddingRight: 140,
  },
  groupLabel: {
    height: 24,
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 1.5,
    color: theme.palette.black,
    marginBottom: 4,
  },
  twoColumns: {
    display: 'flex',
  },
  column: {
    flex: 1,
    padding: '0px 24px',
  },
  reportRequestAttrHeader: {
    position: 'relative',
    alignItems: 'center',
    paddingTop: 16,
    paddingBottom: 16,
  },
  reportRequestAttrBlock: {
    position: 'relative',
    borderBottom: `1px solid ${theme.palette.midGray}`,
  },
  reportRequestAttr: {
    paddingTop: 7,
    paddingBottom: 24,
  },
  collapseButtonWrapper: {
    position: 'absolute',
    right: 16,
    bottom: 16,
  },
  powerUnitLabel: {
    marginTop: 16,
    marginBottom: 8,
  },
  powerUnitValueWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    lineHeight: 1.5,
    padding: '12px 8px 12px 0px',
    color: theme.palette.black,
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
      marginRight: 8,
      color: theme.palette.black30,
    },
  },
  withoutMarginBottom: {
    marginBottom: 0,
  },
  optionRenderer: {
    marginTop: 0,
    marginBottom: 0,
  },
  author: {
    marginTop: 4,
  },
  measurementsGroup: {
    marginBottom: 16,
    '&:last-child': {
      marginBottom: 0,
    },
  },
}));

export default useStyles;
