import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  ClickAwayListener,
  Paper,
  Popper,
  ButtonBase
} from '@material-ui/core';

import Icon from 'Common/components/Icon';


const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
  },
  item: {
    color: 'black',
    paddingTop: 2,
    paddingBottom: 2
  },
  button: {
    color: theme.palette.lightPrimaryHover,
    fontSize: 16,
  },
  count: {
    marginLeft: 2,
  }
}));

const BrigadeTableStaff = ({ staff, shouldStopPropagation }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    if (shouldStopPropagation) event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsOpen(!isOpen);
  };

  const handleClickAway = () => {
    setIsOpen(false);
  };

  return (
    <>
      <ButtonBase disableRipple className={classes.button} onClick={handleClick}>
        <Icon color="primary" variant="people" />
        <span className={classes.count}>{staff.length}</span>
      </ButtonBase>
      <Popper open={isOpen} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper className={classes.paper}>
            {staff.map(({ id, fullName }) => <div key={id} className={classes.item}>{fullName}</div>)}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

BrigadeTableStaff.defaultProps = {
  shouldStopPropagation: false
};

BrigadeTableStaff.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  staff: PropTypes.array.isRequired,
  shouldStopPropagation: PropTypes.bool,
};

export default BrigadeTableStaff;
