import React from 'react';
import BrigadesTableStaff from 'Src/business/tpsRepairs/components/BrigadesTable/BrigadesTableStaff';
import Brigadier from 'Src/business/tpsRepairs/components/BrigadesTable/Brigadier';

const fields = t => (
  {
    brigadeTitle: {
      title: t('NAME'),
      field: 'name',
    },
    brigadier: {
      title: t('PRODUCER'),
      field: 'brigadier',
    },
    staff: {
      title: t('STAFF'),
      field: '',
    },
  }
);

const columns = (t) => {
  const translatedFields = fields(t);
  return (
    {
      brigadeTitle: {
        title: translatedFields.brigadeTitle.title,
        field: translatedFields.brigadeTitle.field,
        mainLink: true,
        withEllipsis: true,
        width: '40%',
        cellStyle: {
          width: '40%',
          whiteSpace: 'normal',
          overflowWrap: 'anywhere',
        },
        headerStyle: {
          width: '40%',
          whiteSpace: 'normal',
        },
      },
      brigadier: {
        title: translatedFields.brigadier.title,
        field: translatedFields.brigadier.field,
        width: '30%',
        cellStyle: {
          width: '30%',
          whiteSpace: 'normal',
          overflowWrap: 'anywhere',
        },
        headerStyle: {
          width: '30%',
          whiteSpace: 'normal',
        },
        render: (rowData) => {
          const { brigadier = {} } = rowData;
          return (
            <Brigadier brigadier={brigadier} />
          );
        },
      },
      staff: {
        title: translatedFields.staff.title,
        field: translatedFields.staff.field,
        // eslint-disable-next-line react/prop-types
        render: ({ users }) => (
          <BrigadesTableStaff staff={users} shouldStopPropagation />
        ),
        cellStyle: {
          textAlign: 'center',
        },
        headerStyle: {
          textAlign: 'center',
        },
        sorting: false
      },
    }
  );
};

const options = {
  paging: false,
  toolbar: false,
  search: false,
  sorting: false,
  padding: 'dense',
  draggable: false,
  headerStyle: {
    fontSize: 10,
    color: '#75767e'
  },
  pageSize: 30,
  cellStyle: {}
};


export default t => (
  {
    columns: [
      columns(t).brigadeTitle,
      columns(t).brigadier,
      columns(t).staff,
    ],
    options
  }
);
