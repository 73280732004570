import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  boxWrapper: {
    borderBottom: '1px solid #f3f3f3',
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  box: {
    minWidth: 500,
    maxWidth: `calc((100vw - ${theme.drawerClosedWidth}px) / 2)`,
    padding: '14px 5px',
    margin: '0 auto'
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    margin: '25px 0 35px',
    color: theme.palette.black,
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: '20px 0 20px',
    color: theme.palette.black,
  },
}));

export default useStyles;
