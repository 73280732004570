import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  subtitle: {
    color: theme.palette.black,
    fontSize: 20,
    lineHeight: '48px',
    fontWeight: 'bold',
    padding: theme.spacing(1, 0, 2),
  },
  container: {
    width: 520,
    margin: '0 auto',
    padding: '24px 0 50px',
  },
  dragContainer: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  dragLeftSide: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dragIndicator: {
    cursor: 'pointer',
    background: theme.palette.lightGray,
    height: 80,
    width: 37,
    borderRight: `1px solid ${theme.palette.gray}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
  },
  addCheckpointButton: {
    boxShadow: 'none',
    minHeight: 80,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    border: `1px dashed ${theme.palette.black20} !important`,
    fontSize: 16,
    fontWeight: 'normal'
  },
  editParameterIcon: {
    margin: 12,
    color: theme.palette.primaryLight,
    '&:hover': {
      backgroundColor: theme.palette.lightSelection,
    },
  },
  removeParameterIcon: {
    margin: 12,
    color: theme.palette.negative,
    '&:hover': {
      backgroundColor: theme.palette.pink,
    },
  },
  loadText: {
    textAlign: 'center',
    fontSize: 14,
    padding: theme.spacing(4),
  },
}));

export default useStyles;
