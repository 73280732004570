import TagManager from 'react-gtm-module';

const initTagManager = (token, userInfo) => {
  const tagManagerArgs = {
    gtmId: token,
    events: {
      sendUserInfo: userInfo
    }
  };
  TagManager.initialize(tagManagerArgs);
};
export default initTagManager;
