import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import clsx from 'clsx';
import {
  TextField, InputAdornment, ButtonBase, FormControl
} from '@material-ui/core';

import OptionRenderer from 'Common/components/Forms/OptionRenderer';
import withValidation from 'Src/HOC/withValidation';

import useStyles from './styles';

const Text = forwardRef((props, ref) => {
  const {
    helperText,
    value,
    name,
    label,
    placeholder,
    className,
    inputProps,
    isReadonly,
    onChange,
    error,
    required,
    InputStartAdornment,
    InputEndAdornment,
    maxWidth,
    marginBottom,
    marginTop,
    disabled,
    requiredMessage,
    errorMessage,
    style,
    autoFocus,
    multiline,
    rows,
    rowsMax,
    'data-test': dataTest,
    onBlur,
    onClickEndAdornment,
    OptionRendererProps,
    maxLength,
  } = props;

  const selfRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      selfRef.current.focus();
    },
    blur: () => {
      selfRef.current.blur();
    }
  }));


  const { t } = useTranslation();
  const classes = useStyles({
    value,
    maxWidth,
    marginBottom,
    marginTop,
    error,
    disabled,
    helperText,
    requiredMessage,
    t
  });

  if (isReadonly) {
    return (
      <OptionRenderer
        title={label}
        value={value || t('INPUT_EMPTY_VALUE')}
        endAdornmentNode={InputEndAdornment}
        maxWidth={maxWidth}
        {...OptionRendererProps}
        {...dataTest && { 'data-test': dataTest }}
      />
    );
  }

  return (
    <FormControl classes={{ root: classes.formControl }}>
      <TextField
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
        autoFocus={autoFocus}
        placeholder={placeholder}
        autoComplete="off"
        type="text"
        classes={{ root: clsx(Boolean(className) && className, classes.textField) }}
        value={value || ''}
        id={name}
        name={name}
        label={label}
        variant="outlined"
        margin="dense"
        InputProps={{
          inputRef: selfRef,
          inputProps: {
            ...inputProps,
            ...dataTest && { 'data-test': dataTest },
          },
          ...InputEndAdornment && {
            endAdornment: (
              <InputAdornment>
                <ButtonBase
                  classes={{ root: classes.buttonBase }}
                  disableRipple
                  onClick={() => {
                    if (onClickEndAdornment) onClickEndAdornment();
                    selfRef.current.focus();
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                >
                  {InputEndAdornment}
                </ButtonBase>
              </InputAdornment>
            )
          },
          ...InputStartAdornment && {
            startAdornment: (
              <InputAdornment>
                <ButtonBase
                  classes={{ root: classes.buttonBase }}
                  disableRipple
                  onClick={() => {
                    if (onClickEndAdornment) onClickEndAdornment();
                    selfRef.current.focus();
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                >
                  {InputStartAdornment}
                </ButtonBase>
              </InputAdornment>
            )
          },
        }}
        fullWidth
        error={error}
        onChange={(event) => {
          const val = get(event, 'target.value', '');
          const inputName = get(event, 'target.name', '');
          onChange(val, inputName);
        }}
        {...onBlur && { onBlur }}
        {...style && { style }}
        {...helperText && { helperText }}
      />
      <div className={classes.helper}>
        {required && (
          <div className={classes.required}>
            {requiredMessage || t('REQUIRED')}
          </div>
        )}
        {errorMessage && (
          <div className={classes.required}>
            {errorMessage}
          </div>
        )}
        {maxLength && (
          <div className={classes.counter}>{`${value && value.length ? value.length : '0'}/${maxLength}`}</div>
        )}
      </div>
    </FormControl>
  );
});

Text.defaultProps = {
  className: undefined,
  autoFocus: false,
  helperText: '',
  value: '',
  maxLength: undefined,
  label: '',
  placeholder: '',
  inputProps: {},
  isReadonly: false,
  error: false,
  required: false,
  InputStartAdornment: null,
  InputEndAdornment: null,
  maxWidth: undefined,
  marginBottom: undefined,
  marginTop: undefined,
  disabled: false,
  requiredMessage: '',
  errorMessage: '',
  style: {},
  multiline: false,
  rows: undefined,
  rowsMax: undefined,
  'data-test': undefined,
  onBlur: undefined,
  onClickEndAdornment: undefined,
  OptionRendererProps: {},
};

Text.propTypes = {
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  inputProps: PropTypes.shape({ 'data-test': PropTypes.string }),
  isReadonly: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  InputStartAdornment: PropTypes.node,
  InputEndAdornment: PropTypes.node,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number,
  disabled: PropTypes.bool,
  requiredMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  style: PropTypes.shape({
    marginTop: PropTypes.number,
  }),
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  'data-test': PropTypes.string,
  onBlur: PropTypes.func,
  onClickEndAdornment: PropTypes.func,
  OptionRendererProps: PropTypes.shape({
    maxLine: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    valueRenderer: PropTypes.func,
    wrapperClass: PropTypes.string,
    titleClass: PropTypes.string,
    valueClass: PropTypes.string,
    titlePosition: PropTypes.oneOf(['left', 'right']),
  }),
};

export default withValidation(Text);
