import React from 'react';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  IconButton,
  Tooltip,
  Grid
} from '@material-ui/core/';
import clsx from 'clsx';

import useStyles from './styles';

// eslint-disable-next-line consistent-return
const Header = ({
  title,
  handleGoBack,
  titleGoBack,
  children,
  isActiveGoBack,
  sticky,
  style,
  filterButton,
  twoColumns
}) => {
  const classes = useStyles({ twoColumns });

  return (
    <Grid
      container
      wrap="nowrap"
      className={clsx(classes.root, sticky && classes.sticky)}
      justify="space-between"
      data-test="layout-header"
      style={style}
    >
      <Grid item className={classes.autoWidth}>
        <Grid container wrap="nowrap" justify="flex-start" alignItems="center">
          {isActiveGoBack && (
            <Grid item>
              <Tooltip title={titleGoBack}>
                <IconButton
                  size="medium"
                  onClick={handleGoBack}
                  classes={{ root: classes.arrowBackInHeader }}
                  data-test="back-button"
                >
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          <Grid item className={classes.title} data-test="header-title">
            {title}
          </Grid>
        </Grid>
      </Grid>
      {children && (
        <Grid item xs={twoColumns ? 6 : 'auto'} className={classes.children}>
          {children}
        </Grid>
      )}
      {filterButton && <Grid item>{filterButton}</Grid>}
    </Grid>
  );
};

/* eslint-disable react/require-default-props */
Header.propTypes = {
  children: PropTypes.node,
  titleGoBack: PropTypes.string,
  handleGoBack: PropTypes.func,
  title: PropTypes.string,
  isActiveGoBack: PropTypes.bool,
  sticky: PropTypes.bool,
  /* eslint-disable-next-line react/forbid-prop-types */
  style: PropTypes.object,
  filterButton: PropTypes.node,
  twoColumns: PropTypes.bool,
};

Header.defaultProps = {
  titleGoBack: '',
  isActiveGoBack: false,
  sticky: false,
  style: {},
  filterButton: null,
  twoColumns: false,
};

export default Header;
