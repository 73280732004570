import {
  action, observable, runInAction, makeObservable
} from 'mobx';

export class DrawerStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable isDrawerOpened = false;

  @action toggleDrawerState = () => {
    runInAction(() => {
      this.isDrawerOpened = !this.isDrawerOpened;
    });
  };
}

export default DrawerStore;
