import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { isNull, find, get } from 'lodash';
import { withTheme } from '@material-ui/core/styles';
import OptionRenderer from 'Common/components/Forms/OptionRenderer';
import {
  inspectionStatusesTranslationKeys, COMPLETED_ENUM, IN_PROGRESS_ENUM, PAUSE_ENUM,
} from 'Shared/constants/inspectionStatuses';
import { formatDate, formatTime, isValid } from 'Src/utils/datetime';

import useStyles from './styles';


const InspectionExecutionBlock = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data, taskRejectionReasonsDictionary = {} } = props;

  const {
    taskStatus,
    factStartDate,
    factEndDate,
    factDurationMinutes,
    rejectionReason,
    rejectionReasonComment,
    updatedOn,
  } = data;

  const startDate = `${formatDate(factStartDate)} ${formatTime(factStartDate)}`;
  const endDate = `${formatDate(factEndDate)} ${formatTime(factEndDate)}`;

  const rejectionReasonObj = find(taskRejectionReasonsDictionary, { enumValue: rejectionReason });
  const rejectionReasonValue = get(rejectionReasonObj, 'value');


  return (
    <div className={classes.executionResultsBlock}>
      <div className={classes.blockHeader}>
        <div className={classes.blockHeaderLabel}>{t('EXECUTION_RESULT')}</div>
        {(taskStatus === IN_PROGRESS_ENUM || taskStatus === PAUSE_ENUM) && isValid(updatedOn) && (<div className={classes.updatedOn}>{`${t('UPDATED_ON')} ${formatDate(updatedOn)} ${formatTime(updatedOn)}`}</div>)}
      </div>
      <div className={classes.twoColumns}>
        <div className={classes.column}>
          <OptionRenderer
            value={inspectionStatusesTranslationKeys[taskStatus] ? t(inspectionStatusesTranslationKeys[taskStatus]) : t('UNKNOWN')}
            title={t('STATUS')}
            wrapperClass={classes.optionRenderer}
            data-test="status"
          />
          {rejectionReasonValue && (
            <OptionRenderer
              value={rejectionReasonValue}
              title={t('REJECTION_REASON')}
              wrapperClass={classes.optionRenderer}
              data-test="rejectionReasonValue"
            />
          )}
          {rejectionReasonComment && (
            <OptionRenderer
              value={rejectionReasonComment}
              title={t('COMMENT')}
              wrapperClass={classes.optionRenderer}
              data-test="rejectionReasonComment"
            />
          )}
        </div>
        {(isValid(factStartDate) || isValid(factEndDate) || !isNull(factDurationMinutes)) && (
          <div className={classes.column}>
            <div style={{ display: 'flex' }}>
              {isValid(factStartDate) && (
                <OptionRenderer
                  value={startDate}
                  title={t('FACT_START_TIME')}
                  wrapperClass={classes.optionRenderer}
                  data-test="startDate"
                />
              )}
              {isValid(factEndDate) && (
                <OptionRenderer
                  value={endDate}
                  title={t('FACT_END_TIME')}
                  wrapperClass={classes.optionRenderer}
                  data-test="endDate"
                />
              )}
            </div>
            {!isNull(factDurationMinutes) && taskStatus === COMPLETED_ENUM && (
              <OptionRenderer
                value={`${factDurationMinutes} ${t('MIN')}`}
                title={t('ACTUAL_LEAD_TIME')}
                wrapperClass={classes.optionRenderer}
                data-test="factDurationMinutes"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

InspectionExecutionBlock.propTypes = {
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      positive: PropTypes.string,
      black30: PropTypes.string,
      negative: PropTypes.string,
    }),
  }),
  taskRejectionReasonsDictionary: PropTypes.arrayOf(PropTypes.shape({
    enumValue: PropTypes.string
  })),
  data: PropTypes.shape({
    taskStatus: PropTypes.string,
    factStartDate: PropTypes.string,
    factDurationMinutes: PropTypes.number,
    factEndDate: PropTypes.string,
    rejectionReason: PropTypes.string,
    rejectionReasonComment: PropTypes.string,
    updatedOn: PropTypes.string,
  })
};

InspectionExecutionBlock.defaultProps = {
  theme: {},
  taskRejectionReasonsDictionary: [],
  data: {}
};

export default withTheme(observer(InspectionExecutionBlock));
