/* eslint-disable lines-between-class-members */
import {
  action, observable, makeObservable, runInAction
} from 'mobx';

import VolumeAgent from 'ApiAgents/Volume/VolumeAgent';

const volumeAgent = new VolumeAgent();

export class VolumeStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable units = [];
  @observable isLoadingUnits = false;
  @action loadUnits = async () => {
    this.isLoadingUnits = true;
    try {
      const units = await volumeAgent.loadUnits();

      runInAction(() => {
        this.units = units;
      });
    } catch (error) {
      console.log('ERROR: ', error);
    }

    runInAction(() => {
      this.isLoadingUnits = false;
    });
  };

  @observable operationKeys = [];
  @observable isLoadingOperationKeys = false;
  @action loadOperationKeys = async () => {
    this.isLoadingOperationKeys = true;
    try {
      const operationKeys = await volumeAgent.loadOperationKeys();

      runInAction(() => {
        this.operationKeys = operationKeys.filter(({ isActive } = {}) => isActive).map(({ key, name } = {}) => ({ enumValue: key, value: name }));
      });
    } catch (error) {
      console.log('ERROR: ', error);
    }

    runInAction(() => {
      this.isLoadingOperationKeys = false;
    });
  };

  @observable volumeList = {
    parameters: [],
    lastEdit: {},
    operationKeyList: [],
    allVolumes: [],
  };
  @observable isLoadingVolumeList = false;
  @observable isLoadedVolumeList = false;
  @action loadVolumes = async () => {
    this.isLoadingVolumeList = true;
    try {
      const [{ parameters, lastEdit, operationKeyList }, allVolumes] = await Promise.all([
        volumeAgent.loadVolumes(),
        volumeAgent.loadAllVolumes(),
      ]);

      runInAction(() => {
        this.volumeList = {
          parameters,
          lastEdit,
          operationKeyList,
          allVolumes,
        };
        this.isLoadedVolumeList = true;
      });
    } catch (error) {
      console.log('ERROR: ', error);
    }

    runInAction(() => {
      this.isLoadingVolumeList = false;
    });
  };

  @observable isCreatingNewVolume = false;
  @action createNewVolume = async (body) => {
    this.isCreatingNewVolume = true;
    try {
      await volumeAgent.createNewVolume(body);
    } catch (error) {
      console.log('ERROR: ', error);
    }

    runInAction(() => {
      this.isCreatingNewVolume = false;
    });
  };
  @action editVolume = async (body) => {
    this.isCreatingNewVolume = true;
    try {
      await volumeAgent.editVolume(body);
    } catch (error) {
      console.log('ERROR: ', error);
    }

    runInAction(() => {
      this.isCreatingNewVolume = false;
    });
  };
  @action getVolumeByName = async (name) => {
    this.isCreatingNewVolume = true;
    try {
      const volumeData = await volumeAgent.loadVolumeByName(name);
      this.isCreatingNewVolume = false;
      return volumeData;
    } catch (error) {
      console.log('ERROR: ', error);
    }
  };

  @action deleteVolume = async (id) => {
    this.volumeList.parameters = this.volumeList.parameters.filter(item => item.id !== id);

    try {
      await volumeAgent.deleteVolume(id);
    } catch (error) {
      console.log('ERROR: ', error);
    }
  };

  @action deleteVolumes = async (idsForRemove) => {
    this.volumeList.parameters = this.volumeList.parameters.filter(item => !idsForRemove.some(it => it === item.id));

    try {
      await Promise.all(idsForRemove.map(volumeId => volumeAgent.deleteVolume(volumeId)));
    } catch (error) {
      console.log('ERROR: ', error);
    }
  };

  @action updateVolumeOrder = async (data) => {
    try {
      await volumeAgent.updateVolumeOrder(data);
    } catch (error) {
      console.log('ERROR: ', error);
    }
  };

  @action discardState = () => {
    this.units = [];
    this.isLoadingUnits = false;
    this.operationKeys = [];
    this.isLoadingOperationKeys = false;
    this.volumeList = {
      parameters: [],
      lastEdit: {},
      operationKeyList: [],
      allVolumes: [],
    };
    this.isLoadingVolumeList = false;
    this.isLoadedVolumeList = false;
    this.isCreatingNewVolume = false;
  }
}

export default VolumeStore;
