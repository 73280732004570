import React, { useState } from 'react';

const withModalOpenHandling = (WrappedComponent) => {
  const WithModalOpenHandling = ({ ...props }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <WrappedComponent
        {...props}
        isOpened={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
      />
    );
  };

  return WithModalOpenHandling;
};

export default withModalOpenHandling;
