import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { get, isEmpty } from 'lodash';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Grid, Collapse, } from '@material-ui/core';
import { FlashOnOutlined, FlashOffOutlined } from '@material-ui/icons';
import useStores from 'Store/useStores';
import { MEASUREMENT_REPORTS, VIEW_REPORT, EDIT_REPORT } from 'Shared/constants/routes';
import Panel from 'Common/widgets/Layout/Panel/Panel';
import OptionRenderer from 'Common/components/Forms/OptionRenderer';
import CollapseButton from 'Common/components/Forms/Button/Collapse';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import useManageEntityModal from 'Common/components/Modal/ManageEntityModal/useManageEntityModal';
import Measurement from 'Business/tpsInspections/pages/Report/widgets/Measurement';
import MeasurementComment from 'Business/tpsInspections/pages/Report/widgets/MeasurementComment';
import { formatDate, formatTime } from 'Src/utils/datetime';
import powerUnitStates from 'Business/tpsInspections/config/measurementReportsTableConfig/powerUnitState';
import {
  SHIFT_SUPERVISOR_KTC
} from 'Shared/constants/roles';
import useStyles from './styles';

const Report = ({ mode }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const {
    setManageEntityModalVisibility,
    setOnManageEntityModalCloseCallback,
    setManageEntityModalState,
    renderManageEntityModal,
  } = useManageEntityModal();

  const { id: reportId } = params;

  const {
    tpsReportStore: {
      isLoading,
      isLoadingMeasuringPoints,
      isSaving,
      reportData = {},
      getReportById,
      saveReport,
      updateReport,
      onMeasurementChange, // eslint-disable-line
      onCommentChange,
      discardEditedData,
      setValidatedReportData,
      getMeasuringPoints,
    },
    notificationStore: {
      enqueueSnackbar,
    },
    userStore: {
      role,
    },
    validationStore: {
      validateRequiredFields, isValidFields, hasErrors
    },
  } = useStores();

  const {
    rows: reportRows = [],
    authorFullName = '',
    tpsName = '',
    powerUnitId = '',
    powerUnitName = '',
    state,
    createdDate = '',
    shift = '',
    shiftName = '',
    isEditable,
  } = reportData;

  const panelActions = ['save'];

  const canEditReport = role === SHIFT_SUPERVISOR_KTC && isEditable;

  if (canEditReport) panelActions.push('edit');

  const powerUnitInProgress = state === 'IN_PROGRESS';

  const [isExpandedAttrBlock, setIsExpandedAttrBlock] = useState(true);

  const { state: locationState = {}, pathname } = location;

  const buildPageTitle = () => {
    if (isEmpty(reportData)) return '';
    const powerUnitState = powerUnitInProgress ? t('WORK') : t('RESERVE');
    return `${t('REPORT_LONG')}.${t('BLOCK')}№${powerUnitId}_${shift}_${powerUnitState}_${formatDate(createdDate)}`;
  };

  let shouldLoadReportById;
  let shouldUseRouterState;
  let readByMode;

  switch (mode) {
    case 'create':
      shouldLoadReportById = false;
      shouldUseRouterState = true;
      readByMode = false;
      break;
    case 'view':
      shouldLoadReportById = true;
      shouldUseRouterState = false;
      readByMode = true;
      break;
    case 'edit':
      shouldLoadReportById = true;
      shouldUseRouterState = false;
      readByMode = false;
      break;
    default:
      shouldLoadReportById = false;
      shouldUseRouterState = false;
      readByMode = false;
  }

  const goBack = () => {
    history.push({
      pathname: MEASUREMENT_REPORTS,
    });
  };

  useEffect(() => {
    getMeasuringPoints();
  }, []);

  useEffect(() => {
    if (shouldUseRouterState) {
      const { routerReportData } = locationState;
      if (!routerReportData) {
        goBack();
      } else {
        setValidatedReportData(routerReportData);
        history.replace({
          pathname,
          state: {}
        });
      }
    }
    // if id is not valid
    if (shouldLoadReportById && !parseInt(reportId, 10)) {
      goBack();
    }
    if (shouldLoadReportById && parseInt(reportId, 10)) {
      getReportById(reportId);
    }
  }, [reportId, shouldLoadReportById, shouldUseRouterState]);

  const handleView = () => {
    discardEditedData();
    history.push({
      pathname: `${VIEW_REPORT}/${reportId}`
    });
  };

  const handleEdit = () => {
    history.push({
      pathname: `${EDIT_REPORT}/${reportId}`
    });
  };

  const save = async () => {
    const { result } = await saveReport() || {};
    if (result) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('REPORT_SUCCESSFULLY_CREATED')
            }]
          }],
        },
        variant: 'success',
      });
      handleView();
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('REPORT_IS_NOT_SAVED')
          }]
        }],
      },
      variant: 'error',
    });
  };

  const update = async () => {
    const { result } = await updateReport() || {};
    if (result) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('CHANGES_SUCCESSFULLY_APPLIED')
            }]
          }],
        },
        variant: 'success',
      });
      handleView();
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('REPORT_IS_NOT_EDITED')
          }]
        }],
      },
      variant: 'error',
    });
  };

  const showSaveConformationModal = () => {
    setManageEntityModalVisibility(true);
    setManageEntityModalState({
      title: t('REPORT_SAVING'),
      entityName: buildPageTitle(),
      message: t('REPORT_WILL_BE_SEND_MESSAGE'),
      secondaryMessage: t('NOT_POSIBLE_CHANGE_REPORT_MESSAGE'),
      type: 'action',
      actionButtonTitle: 'Ok',
    });
    setOnManageEntityModalCloseCallback(() => save);
  };

  const showEditConformationModal = () => {
    setManageEntityModalVisibility(true);
    setManageEntityModalState({
      title: t('REPORT_EDITING'),
      entityName: buildPageTitle(),
      message: t('REPORT_CAN_BE_EDITET_ONCE'),
      secondaryMessage: t('NOT_POSIBLE_CHANGE_TWICE_REPORT_MESSAGE'),
      type: 'action',
      actionButtonTitle: 'Ok',
    });
    setOnManageEntityModalCloseCallback(() => update);
  };

  const handleSave = () => {
    validateRequiredFields();
    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: t('REPORT_VALIDATION_MESSAGE'),
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }

    if (mode === 'create') {
      showSaveConformationModal();
    }

    if (mode === 'edit') {
      showEditConformationModal();
    }
  };

  if (isLoading || isLoadingMeasuringPoints) {
    return <ProgressBar />;
  }

  const powerUnitState = get(powerUnitStates(t), state, '');

  return (
    <div>
      {isSaving && <ProgressBar />}
      <Panel
        mode={mode}
        viewItem={handleView}
        editItem={handleEdit}
        saveItem={handleSave}
        goBack={goBack}
        labelCreate={t('REPORT_CREATION')}
        labelView={buildPageTitle()}
        labelEdit={buildPageTitle()}
        labelGoToList={t('GO_BACK')}
        actions={panelActions}
        layoutStyles={{ minHeight: 'auto' }}
        withActions
      >
        <div className={clsx(classes.twoColumns, classes.reportRequestAttrHeader)}>
          <div className={classes.column}>
            <div className={classes.reportRequestAttrHeaderLabel}>
              {t('REPORT_ATTRIBUTES')}
            </div>
          </div>
          <div className={classes.column}>
            <div className={classes.reportRequestAttrHeaderLabel}>
              {t('REPORT_PERIOD_SHORT')}
            </div>
          </div>
        </div>
        <div className={classes.reportRequestAttrBlock}>
          <div className={classes.collapseButtonWrapper}>
            <CollapseButton
              data-test="collapseButton"
              isCollapsed={!isExpandedAttrBlock}
              handleClick={() => setIsExpandedAttrBlock(!isExpandedAttrBlock)}
            />
          </div>
          <Collapse
            in={isExpandedAttrBlock}
          >
            <div className={clsx(classes.twoColumns, classes.reportRequestAttr)}>
              <div className={classes.column}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <OptionRenderer
                      value={powerUnitName}
                      title={t('POWER_UNIT')}
                      wrapperClass={classes.optionRenderer}
                      name="powerUnitName"
                      data-test="powerUnitName"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <OptionRenderer
                      value={tpsName}
                      title={t('THERMAL_POWER_PLANT')}
                      wrapperClass={classes.optionRenderer}
                      name="tpsName"
                      data-test="tpsName"
                    />
                  </Grid>
                </Grid>
                <Grid container direction="column">
                  <Grid item>
                    <div className={clsx(classes.groupLabel, classes.powerUnitLabel)}>
                      {t('POWER_UNIT_STATE')}
                    </div>
                  </Grid>
                  <Grid item>
                    <div className={classes.powerUnitValueWrapper} data-test="powerUnitState" value={powerUnitState}>
                      {powerUnitInProgress ? <FlashOnOutlined /> : <FlashOffOutlined />}
                      {powerUnitState}
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.column}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <OptionRenderer
                      value={shiftName}
                      title={t('SHIFT')}
                      wrapperClass={classes.optionRenderer}
                      name="shiftName"
                      data-test="shiftName"
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <OptionRenderer
                      value={`${formatDate(createdDate) || ''} ${formatTime(createdDate) || ''}`}
                      title={t('DATE_COMMA_TIME')}
                      wrapperClass={classes.optionRenderer}
                      name="createdDateTime"
                      data-test="createdDateTime"
                    />
                  </Grid>
                </Grid>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <OptionRenderer
                      value={authorFullName}
                      title={t('AUTHOR')}
                      wrapperClass={clsx(classes.optionRenderer, classes.author)}
                      name="authorFullName"
                      data-test="authorFullName"
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </Collapse>
        </div>
        {reportRows.map(({ columns = [], rowLabel }, rowIndex) => {
          const rowKey = `row_${rowIndex}`;
          const columnWidth = columns.length > 1 ? 12 / columns.length : 12;
          const labelsByRowExistence = [];
          columns.forEach((column = {}) => {
            const { groups = [] } = column;
            groups.forEach(({ groupLabel }, groupIndex) => {
              if (!labelsByRowExistence[groupIndex]) labelsByRowExistence[groupIndex] = Boolean(groupLabel);
            });
          });
          return (
            <div key={rowKey} className={classes.row} data-test={rowKey}>
              {rowLabel && (
                <div className={classes.rowLabel} data-test="rowLabel">
                  {rowLabel}
                </div>
              )}
              <Grid container>
                {columns.map((column = {}, columnIndex) => {
                  const columnKey = rowKey + columnIndex;
                  const { columnLabel, groups } = column;
                  return (
                    <Grid
                      key={columnKey}
                      item
                      xs={columnWidth}
                      data-test={`column_${columnIndex}`}
                    >
                      {columnLabel && (
                        <div className={classes.columnLabel} data-test="columnLabel">
                          {columnLabel}
                        </div>
                      )}
                      {groups.map((group = {}, groupIndex) => {
                        const { groupLabel, measurements = [], comments = [] } = group;
                        const hasMeasurements = !isEmpty(measurements);
                        const isTextMeasurementPresent = Array.isArray(measurements) && measurements.some(({ type }) => type === 'text');
                        const hasComments = !isEmpty(comments);
                        const groupKey = columnKey + groupIndex;
                        return (
                          <div key={groupKey} className={classes.measurementsGroup} data-test={`measurementGroup_${groupIndex}`}>
                            {labelsByRowExistence[groupIndex] && (
                              <div className={classes.groupLabel} data-test="groupLabel">{groupLabel}</div>
                            )}
                            {hasMeasurements && (
                              <Grid container spacing={1}>
                                {measurements.map((measurement, measurementIndex) => {
                                  const measurementKey = groupKey + measurementIndex;
                                  const measurementPath = `rows[${rowIndex}].columns[${columnIndex}].groups[${groupIndex}].measurements[${measurementIndex}]`;
                                  const measurementName = `measurement${rowIndex}${columnIndex}${groupIndex}${measurementIndex}`;
                                  const measurementObj = get(reportData, measurementPath);
                                  return (
                                    <Grid item key={measurementKey} xs="auto">
                                      <Measurement
                                        inputName={measurementName}
                                        styledAsTextMeasurement={isTextMeasurementPresent}
                                        measurement={measurementObj}
                                        onMeasurementChange={value => onMeasurementChange({
                                          value,
                                          rowIndex,
                                          columnIndex,
                                          groupIndex,
                                          measurementIndex,
                                        })}
                                        readByMode={readByMode}
                                      />
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            )}
                            {hasComments && (
                              <Grid container direction="column" spacing={2}>
                                {comments.map((comment, commentIndex) => {
                                  const commentKey = `${groupKey}Comment${commentIndex}`;
                                  const commentPath = `rows[${rowIndex}].columns[${columnIndex}].groups[${groupIndex}].comments[${commentIndex}]`;
                                  const commentName = `comment${rowIndex}${columnIndex}${groupIndex}${commentIndex}`;
                                  const commentObj = get(reportData, commentPath, {});

                                  return (
                                    <Grid item key={commentKey}>
                                      <MeasurementComment
                                        inputName={commentName}
                                        comment={commentObj}
                                        onCommentChange={value => onCommentChange({
                                          value,
                                          rowIndex,
                                          columnIndex,
                                          groupIndex,
                                          commentIndex,
                                        })}
                                        readByMode={readByMode}
                                      />
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            )}
                          </div>
                        );
                      })}
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          );
        })}
      </Panel>
      {renderManageEntityModal()}
    </div>
  );
};

Report.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default observer(Report);
