import withStyles from 'Src/utils/table/withColumnStyles';
import { formatDate, formatTime } from 'Src/utils/datetime';

const tableColumns = t => (
  {
    auartType: {
      field: 'auartType',
      title: t('AUART_TYPE'),
      mainLink: true,
      withEllipsis: true,
    },
    parallelOperationCount: {
      field: 'parallelOperationCount',
      title: t('MAX_OPERATIONS_AMOUNT'),
    },
    updatedDateTime: {
      field: 'updatedDateTime',
      title: t('LAST_EDITING_TIME'),
      withEllipsis: true,
      render: ({ updatedDateTime = new Date().toISOString() }) => `${formatDate(updatedDateTime)} ${formatTime(updatedDateTime)}`,
    },
    authorName: {
      field: 'authorName',
      title: t('AUTHOR'),
      withEllipsis: true,
    },
  }
);

export const tableConfig = (t) => {
  const translatedTableColumns = tableColumns(t);
  return (
    {
      columns: [
        withStyles(translatedTableColumns.auartType, { width: '50%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.parallelOperationCount, { width: '16%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.updatedDateTime, { width: '17%', whiteSpace: 'normal' }),
        withStyles(translatedTableColumns.authorName, { width: '17%', whiteSpace: 'normal' }),
      ],
      actions: [],
    }
  );
};

export default tableColumns;
