import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import {
  AutoSizer, List, CellMeasurer, CellMeasurerCache,
} from 'react-virtualized';

import useStores from 'Store/useStores';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import { columnsType } from 'Business/plant/constants';
import SectionListItem from 'Common/widgets/Section/SectionListItem';
import Section from 'Common/widgets/Section/Section';
import UnitSearch from './components/UnitSearch';

import { ReactComponent as SelectArrow } from '../assets/arrow-with-scribble.svg';
import useStyles from '../styles';

const overscanRowCount = 25;
const titleHeight = 130;
const rowHeight = 48;
const cache = new CellMeasurerCache({
  defaultHeight: rowHeight,
  fixedWidth: true
});

const UnitsColumn = observer(({
  activateNextColumn,
  setColumnValue,
  index,
  gridWidth,
  defValue,
}) => {
  const { t } = useTranslation();
  const [searchVal, setSearchVal] = useState('');

  const {
    volumeStore: {
      loadUnits,
      units,
      isLoadingUnits,
    }
  } = useStores();
  const classes = useStyles();

  const handleUnitClick = (shortText) => {
    setColumnValue(shortText, columnsType.UNIT);
    activateNextColumn(shortText, columnsType.OPERATION_KEYS, index);
  };

  const handleUpdateSearch = (val) => {
    setSearchVal(val);
    cache.clearAll();
  };

  const getFilteredList = () => units.filter((unit) => {
    if (unit.longText && unit.longText.indexOf) {
      return unit.longText.indexOf(searchVal) > -1;
    }

    return null;
  });

  const renderRow = ({ index, key, style, parent }) => { // eslint-disable-line
    const unit = getFilteredList()[index];

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        <div style={style}>
          <SectionListItem
            onItemClick={() => handleUnitClick(unit.shortText)}
            title={`${unit.longText} (${unit.shortText})`}
            isCurrentlySelected={defValue === unit.shortText}
            noAction
          />
        </div>
      </CellMeasurer>
    );
  };

  useEffect(() => {
    if (units.length) return;

    loadUnits();
  }, []);

  // refresh cache after reload units
  useEffect(() => {
    cache.clearAll();
  }, [isLoadingUnits]);

  return (
    <Section title={t('MEASUREMENT_UNIT')} gridWidth={gridWidth} customClass={classes.sectionRoot}>
      {isLoadingUnits ? <ProgressBar /> : (
        <>
          <div className={classes.selectArrow}>
            <SelectArrow />
            <div className={classes.arrowText}>{t('SPECIFY_MEASUREMENT_UNIT')}</div>
          </div>
          <UnitSearch
            value={searchVal}
            onChange={handleUpdateSearch}
            label={t('FIND_MEASUREMENT_UNIT')}
            bottomText={t('ENTER_REQUIRED_MEASUREMENT_UNIT_NAME')}
          />
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                estimatedRowSize={rowHeight}
                rowRenderer={renderRow}
                rowCount={getFilteredList().length}
                height={height - titleHeight}
                deferredMeasurementCache={cache}
                rowHeight={cache.rowHeight}
                overscanRowCount={overscanRowCount}
              />
            )}
          </AutoSizer>
        </>
      )}
    </Section>
  );
});

UnitsColumn.propTypes = {
  activateNextColumn: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  gridWidth: PropTypes.number.isRequired,
  defValue: PropTypes.string,
};

UnitsColumn.defaultProps = {
  defValue: '',
};

export default UnitsColumn;
