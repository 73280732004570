import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SectionListItem from 'Common/widgets/Section/SectionListItem';
import Section from 'Common/widgets/Section/Section';
import { ParameterType, columnsType } from 'Business/plant/constants';

import { ReactComponent as SelectArrow } from '../assets/arrow-with-scribble.svg';
import useStyles from '../styles';

const ParameterTypeColumn = ({
  activateNextColumn,
  index,
  gridWidth,
  defValue,
  setColumnValue,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const handleParameterClick = (type) => {
    setColumnValue(type, columnsType.PARAMETER_TYPE);
    switch (type) {
      case ParameterType.MANUAL:
        return activateNextColumn(type, columnsType.DATA_TYPE, index);
      case ParameterType.BINARY:
        return activateNextColumn(type, columnsType.OPERATION_KEYS, index);
      case ParameterType.SINGLE_SELECT:
        return activateNextColumn(type, columnsType.DIRECTORIES, index);
      default:
        return null;
    }
  };

  return (
    <Section title={t('PARAMETER_TYPE')} gridWidth={gridWidth} customClass={classes.sectionRoot}>
      <SectionListItem
        onItemClick={() => handleParameterClick(ParameterType.MANUAL)}
        title={t('MANUAL_DATA_ENTRY')}
        isCurrentlySelected={defValue === ParameterType.MANUAL}
        noAction
      />
      <SectionListItem
        onItemClick={() => handleParameterClick(ParameterType.BINARY)}
        title={t('CONFIRMATION_OF_PRESENCE_OR_ABSENCE')}
        isCurrentlySelected={defValue === ParameterType.BINARY}
        noAction
      />
      <SectionListItem
        onItemClick={() => handleParameterClick(ParameterType.SINGLE_SELECT)}
        title={t('SELECTION_FROM_THE_DIRECTORY')}
        isCurrentlySelected={defValue === ParameterType.SINGLE_SELECT}
        noAction
      />
      <div className={classes.selectArrow}>
        <SelectArrow />
        <div className={classes.arrowText}>{t('SPECIFY_FIELD_TYPE_AND_NAME')}</div>
      </div>
    </Section>
  );
};

ParameterTypeColumn.propTypes = {
  activateNextColumn: PropTypes.func.isRequired,
  setColumnValue: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  gridWidth: PropTypes.number.isRequired,
  defValue: PropTypes.string,
};

ParameterTypeColumn.defaultProps = {
  defValue: '',
};

export default ParameterTypeColumn;
