import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  actionsWrapper: {
    display: 'block',
  },
  paper: {
    boxShadow: 'none',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    '& .MuiTypography-h6': {
      fontSize: 24,
      lineHeight: '36px',
      margin: '20px 0 10px',
    },
    '&  .MuiPaper-root': {
      // boxShadow: 'inherit',
    },
    '& .MuiTableRow-root': {
      '& $actionsWrapper': {
        // display: 'none',
        visibility: 'hidden',
      },
      '& .customActionButtons': {
        // display: 'none',
        visibility: 'hidden',
      },
    },
    '& .MuiTableRow-root .MuiTableCell-head': {
      padding: '13px 16px 10px',
      borderBottom: 'none',
      '&:last-child': {
      }
    },
    '&  .MuiTableCell-root': {
      borderBottom: ({ showEmptyBlock }) => (showEmptyBlock
        ? '1px solid #fff'
        : '1px solid #f3f3f3'),
    },
    '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root:last-child': {
      textAlign: 'left',
      '& > div': {
        justifyContent: 'center'
      }
    },
    '& .MuiTableRow-root .MuiTableCell-root:first-child': {
      paddingLeft: 24,
    },
    '& .MuiTableRow-root .MuiTableCell-root:last-child': {
      textAlign: 'left',
    },
    '& .MuiTableBody-root .MuiTableRow-root': {
      '&:nth-child(2n+1)': {
        '& td': {
          pointerEvents: ({ showEmptyBlock }) => (showEmptyBlock ? 'none' : 'auto'),
          background: ({ showEmptyBlock }) => (showEmptyBlock ? theme.palette.white : theme.palette.default),
        },
      },
      '&:nth-child(2n)': {
        '& td': {
          background: theme.palette.white,
        },
      },
      '& td': {
        // border: ({ showEmptyBlock }) => (showEmptyBlock ? '0px none' : 'inherit'),
      },
      '&:hover': {
        '&:nth-child(2n+1)': {
          '& td': {
            background: theme.palette.default,
          },
        },
        '&:nth-child(2n)': {
          '& td': {
            background: theme.palette.white,
          },
        },
      },
    },
    '& .MuiTableFooter-root .MuiTableRow-root:hover td': {
      background: 'none'
    },
    '& .MuiTableBody-root': {
      color: theme.palette.midBlack,
      '& .MuiTableRow-root': {
        '&:hover': {
          position: 'relative',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.16)',
          '& $actionsWrapper': {
            visibility: 'visible',
          },
          '& .customActionButtons': {
            visibility: 'visible',
          },
          '& .tableLink': {
            textDecoration: 'underline',
          },
          '& .defectsPhotoFolderLink': {
            color: theme.palette.primaryLight,
          },
        },
      }
    },
    '& .MuiIconButton-root': {
      color: '#4499ee',
      '&.Mui-disabled': {
        color: '#ccc'
      }
    },
    '& .MuiTableCell-footer': {
      border: 'none',
      minHeight: 52,
      height: 52
    },
    '& .MuiTablePagination-toolbar': {
      paddingRight: 60,
      borderTop: '1px solid #f3f3f3'
    },
    '& .MuiTableFooter-root': {
      display: 'table-footer-group !important'
    }
  },
  pageSelect: {
    marginRight: 20,
    '&::after': {
      display: 'none',
    },
    '&::before': {
      display: 'none',
    }
  },
  removeButton: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.negative,
    },
    '&.Mui-disabled': {
      '& .MuiSvgIcon-root': {
        color: theme.palette.black20,
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.pink,
    },
  },
}));

export default useStyles;
