import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Switch from 'Common/components/Forms/Input/Switch';
import useStores from 'Store/useStores';
import { observer } from 'mobx-react';

import useStyles from './styles';

const IsAlternativeSwitcher = (props) => {
  const {
    id, isUpdating, isAlternativeEnabled,
  } = props;
  const {
    presenceControlStore: { onAlternativeFieldUpdate } = {},
    notificationStore: { enqueueSnackbar },
  } = useStores();

  const { t } = useTranslation();
  const classes = useStyles({ isUpdating });

  const showDefaultErrorNotification = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('REQUEST_DEFAULT_ERROR'),
              },
            ],
          },
        ],
      },
      variant: 'error',
    });
  };

  return (
    <div className={classes.root}>
      <Switch
        name="isAlternativeEnabled"
        value={isAlternativeEnabled}
        onClick={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          const { error } = await onAlternativeFieldUpdate(id, !isAlternativeEnabled);
          if (error) {
            showDefaultErrorNotification();
            return;
          }
          enqueueSnackbar({
            messageTemplate: {
              rows: [
                {
                  rowContent: [
                    {
                      type: 'text',
                      text: t('CHANGES_SUCCESSFULLY_APPLIED'),
                    },
                  ],
                },
              ],
            },
            variant: 'success',
          });
        }}
        isUpdating={isUpdating}
        checked={isAlternativeEnabled}
      />
    </div>
  );
};

IsAlternativeSwitcher.propTypes = {
  isAlternativeEnabled: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  isUpdating: PropTypes.bool.isRequired,
};

export default observer(IsAlternativeSwitcher);
