import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@material-ui/core';
import { PersonAddOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';
import useStyles from 'Common/components/Forms/Button/styles/btn_table_styles';
import clsx from 'clsx';


const AssignUserAction = ({ handleClick, disabled }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Tooltip title={t('ASSIGN_PERFORMER')}>
      <IconButton
        onClick={handleClick}
        className={clsx(classes.root, disabled ? classes.disabled : classes.active)}
        disabled={disabled}
      >
        <PersonAddOutlined />
      </IconButton>
    </Tooltip>
  );
};

AssignUserAction.propTypes = {
  handleClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

AssignUserAction.defaultProps = {
  disabled: false,
};

export default AssignUserAction;
