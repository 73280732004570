import SharePointSettingsAgent from 'ApiAgents/Settings/SharePointSettingsAgent';
import {
  action, observable, runInAction, makeObservable,
} from 'mobx';
import { get } from 'lodash';


const sharePointSettingsAgent = new SharePointSettingsAgent();

const initialSettingData = {
  title: '',
  photosQuality: '',
  minAllowedSize: undefined,
  defectsMaxPhotosCount: undefined,
  defectsLibraryUrl: '',
  operationConfirmationMaxPhotosCount: undefined,
  operationConfirmationLibraryUrl: '',
  workplaceAccessMaxPhotosCount: undefined,
  workplaceAccessLibraryUrl: '',
  documentsUploadingLibraryUrl: '',
};

export class SharePointSettingsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable list = [];

  @observable id = null;

  @observable isLoading = false;

  @observable isListLoaded = false;

  @observable isUpdating = false;

  @observable settingData = initialSettingData;


  @action loadList = async () => {
    this.isLoading = true;
    this.isListLoaded = false;
    try {
      const data = await sharePointSettingsAgent.loadList();
      const list = get(data, 'items', []);

      runInAction(() => {
        this.list = list;
        this.isLoading = false;
        this.isListLoaded = true;
      });
    } catch (error) {
      console.log('ERROR LOAD SHAREPOINT SETTINGS: ', error);
      runInAction(() => {
        this.list = [];
        this.isLoading = false;
        this.isListLoaded = true;
      });
      throw new Error(error);
    }
  }

  @action getSettingsByOsrId = async (osrId) => {
    try {
      const settingData = await sharePointSettingsAgent.getSettingsByOsrId(osrId);
      runInAction(() => {
        this.settingData = settingData;
      });
    } catch (error) {
      runInAction(() => {
        this.settingData = initialSettingData;
      });
      console.log('ERROR LOAD SETTINGS BY OSR ID: ', error);
      throw new Error(error);
    }
  }

  @action updateSetting = async (id) => {
    this.isUpdating = true;
    const {
      title,
      photosQuality,
      minAllowedSize,
      defectsMaxPhotosCount,
      defectsLibraryUrl,
      operationConfirmationMaxPhotosCount,
      operationConfirmationLibraryUrl,
      workplaceAccessMaxPhotosCount,
      workplaceAccessLibraryUrl,
      documentsUploadingLibraryUrl,
    } = this.settingData;
    try {
      const res = await sharePointSettingsAgent.update({
        id,
        title,
        photosQuality: +photosQuality,
        minAllowedSize: +minAllowedSize,
        defectsMaxPhotosCount: +defectsMaxPhotosCount || null,
        defectsLibraryUrl: defectsLibraryUrl || null,
        operationConfirmationMaxPhotosCount: +operationConfirmationMaxPhotosCount || null,
        operationConfirmationLibraryUrl: operationConfirmationLibraryUrl || null,
        workplaceAccessMaxPhotosCount: +workplaceAccessMaxPhotosCount || null,
        workplaceAccessLibraryUrl: workplaceAccessLibraryUrl || null,
        documentsUploadingLibraryUrl: documentsUploadingLibraryUrl || null,
      });
      runInAction(() => {
        this.isUpdating = false;
      });
      return { res };
    } catch (error) {
      console.log('ERROR UPDATE SHAREPOINT SETTING: ', error);
      runInAction(() => {
        this.isUpdating = false;
      });
      return { error };
    }
  }

  @action updateField = (field, value) => {
    this.settingData[field] = value;
  };

  @action discardState = () => {
    this.list = [];
    this.id = null;
    this.isLoading = false;
    this.isListLoaded = false;
    this.isUpdating = false;
    this.settingData = initialSettingData;
  };
}

export default SharePointSettingsStore;
