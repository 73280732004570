import axios from 'axios';
import appInsights from 'Services/logging/AppInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

window.ajaxCounter = 0;
let retryCount = 0;

const trackError = (error) => {
  appInsights.trackException({
    error: new Error('Custom message from MobWalker: Request failed'),
    properties: {
      status: error.response.status,
      trace: error.response.data,
    },
    severityLevel: SeverityLevel.Error
  });
};

const logFailedRequest = (error) => {
  if (process.env.NODE_ENV === 'production') return;

  console.error('Request Failed:', error.config);
  if (error.response) {
    console.error('Status:', error.response.status);
    console.error('Data:', error.response.data);
    console.error('Headers:', error.response.headers);
  } else {
    console.error('Error Message:', error.message);
  }
};

export const client = {
  instance: axios.create(),
  accountUrl: null,
  taskUrl: null,
  techObjectUrl: null,
  msGraphApiUrl: null,

  //Temporarily set 2 base urls - for tasks and users until devops create load balancer
  setAccountUrl(accountUrl) {
    this.accountUrl = accountUrl;
    return this;
  },

  setTaskUrl(taskUrl) {
    this.taskUrl = taskUrl;
    return this;
  },

  setTechObjectUrl(techUrl) {
    this.techObjectUrl = techUrl;
    return this;
  },

  setTpsInspectionTaskUrl(tpsInspectionTaskUrl) {
    this.tpsInspectionTaskUrl = tpsInspectionTaskUrl;
    return this;
  },

  setMsGraphApiUrl(msGraphApiUrl) {
    this.msGraphApiUrl = msGraphApiUrl;
    return this;
  },

  setBaseUrl(type) {
    switch (type) {
      case 'tasks':
        this.instance.defaults.baseURL = this.taskUrl;
        break;
      case 'user':
        this.instance.defaults.baseURL = this.accountUrl;
        break;
      case 'techObject':
        this.instance.defaults.baseURL = this.techObjectUrl;
        break;
      case 'tpsInspectionTask':
        this.instance.defaults.baseURL = this.tpsInspectionTaskUrl;
        break;
      case 'msGraphApiUrl':
        this.instance.defaults.baseURL = this.msGraphApiUrl;
        break;
      default:
        this.instance.defaults.baseURL = '';
    }

    return this;
  },

  getClient() {
    return this.instance;
  }
};

/* eslint-disable no-plusplus */
const request = (options, type = null, withFullResponse = false) => {
  const clientInstance = client.setBaseUrl(type).getClient();

  clientInstance.interceptors.response.use((response) => {
    if (retryCount > 0) retryCount = 0;
    return response;
  },
  async (error) => {
    const status = error.response ? error.response.status : '';
    if (+status === 401) {
      if (retryCount >= 3) return;
      retryCount += 1;
      try {
        const { response: { config } = {} } = error;
        const msalInstance = window.authService;
        await msalInstance.acquireToken();
        const accessToken = window.localStorage.getItem('accessToken');
        const newRequestConfig = {
          ...config,
          headers: { ...config.headers, Authorization: `Bearer ${accessToken}` },
        };
        return clientInstance.request(newRequestConfig);
      } catch (e) {
        console.log('401', e); // eslint-disable-line
      }
    }
    return Promise.reject(error);
  });

  const token = window.localStorage.getItem('accessToken');
  clientInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  window.ajaxCounter++;

  const onSuccess = (response) => {
    window.ajaxCounter--;
    console.debug('Request Successful!', response);
    return withFullResponse ? response : response.data;
  };

  const onError = (error) => {
    window.ajaxCounter--;
    logFailedRequest(error);
    trackError(error);

    return Promise.reject(error.response || error.message);
  };

  return clientInstance(options)
    .then(onSuccess)
    .catch(onError);
};


export default request;
