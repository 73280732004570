import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase, Tooltip } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  btn: {
    position: 'relative',
    color: theme.palette.blue,
    fontSize: 14,
    borderRadius: 4,
    display: 'inline-flex',
    textAlign: 'center',
    '& .MuiSvgIcon-root': {
      marginLeft: 6,
      opacity: 0,
      transition: 'all .3s'
    },
    '&:hover .MuiSvgIcon-root': {
      opacity: 1
    },
    '&:disabled': {
      color: theme.palette.black,
    }
  },
  editBtn: {
    position: 'absolute',
    left: '100%'
  },
}));

const ColumnEdit = ({
  handleClick,
  title,
  children,
  ...rest
}) => {
  const classes = useStyles();

  const onClickHandler = (event) => {
    event.stopPropagation();
    handleClick(event);
  };

  return (
    <ButtonBase
      onClick={onClickHandler}
      className={classes.btn}
      {...rest}
    >
      <span>{children}</span>
      <Tooltip title={title}>
        <Edit
          className={classes.editBtn}
          fontSize="small"
        />
      </Tooltip>
    </ButtonBase>
  );
};

ColumnEdit.propTypes = {
  handleClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

ColumnEdit.defaultProps = {
  handleClick: () => {},
  children: null
};

export default ColumnEdit;
