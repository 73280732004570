import Agent from 'ApiAgents/Agent';

export default class Repairs extends Agent {
  getOperations = id => (
    this.request({
      url: `/${this.entity}/${id}/view`,
      method: 'GET'
    }, 'techObject')
  );

  getRepairTypeById = id => (
    this.request({
      url: '/repairtype/view/byId',
      method: 'GET',
      params: { id }
    }, 'techObject')
  );
}
