import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import useStores from 'Store/useStores';
import {
  remove, isEqual, filter, isArray, isEmpty, head, get,
} from 'lodash';
import {
  Grid,
  Box,
} from '@material-ui/core';
import AdditionalOperationsInput from 'Business/coal/components/AdditionalOperationsInput';
import OperationView from 'Common/widgets/OperationView/OperationView';
import OperationRejection from 'Business/coal/components/OperationRejection';
import { useTranslation } from 'react-i18next';
import withValidation from 'Src/HOC/withValidation';

import useStyles from './styles';

const AdditionalOperationsBlock = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    coalTaskStore: {
      taskData: { additionalOperations = [] },
      updateDataField,
    },
  } = useStores();

  const { mode, error } = props;

  const getWorkLog = (subOperations) => {
    if (!isArray(subOperations)) return {};
    if (isEmpty(subOperations)) return {};
    const subOperation = head(subOperations);
    const workLogs = get(subOperation, 'worklogs', []);
    const workLog = head(workLogs);
    return workLog || {};
  };

  return (
    <div>
      <div className={classes.blockHeader}>
        <div className={classes.blockHeaderLabel}>
          {t('NON_STANDARD_OPERATIONS')}
        </div>
      </div>
      <div>
        {(mode === 'create' || mode === 'edit') && (
          <Box css={{ padding: '2px 24px 22px 24px' }}>
            <AdditionalOperationsInput
              title={t('ADDING_AN_OPERATION')}
              placeholder={t('ENTER_THE_OPERATION_NAME')}
              onOptionAdded={(operation) => {
                updateDataField('additionalOperations', [{ title: operation }, ...additionalOperations]);
              }}
              error={error}
              options={additionalOperations}
              onOptionDelete={(operationIndex) => {
                updateDataField('additionalOperations', remove(additionalOperations, (value, index) => index !== operationIndex));
              }}
            />
          </Box>
        )}
        { additionalOperations.map((additionalOperation = {}, index) => {
          const {
            title: additionalOperationTitle,
            status,
            factDuration = 0,
            subOperations,
          } = additionalOperation;

          const {
            pauseReason, stopReason
          } = getWorkLog(subOperations);

          const reason = pauseReason || stopReason;

          const additionalOperationKey = `${additionalOperationTitle}${index}`;

          const renderAdditionalOperationSubTitle = () => (
            <div
              className={classes.additionalOperationSubTitleWrapper}
            >
              <div className={classes.blackColor}>{`${t('ACTUAL_TIME')}: ${factDuration} ${t('MIN')}`}</div>
            </div>
          );

          let operationViewConfig;
          let operationSubTitle = '';
          switch (status) {
            case 'DONE':
              operationSubTitle = renderAdditionalOperationSubTitle();
              break;
            case 'REJECTED':
              if (reason) {
                operationViewConfig = {
                  bottom: {
                    left: (<OperationRejection rejectionType="REJECTED" reason />),
                  }
                };
              }
              break;
            case 'ON_HOLD':
              if (reason) {
                operationViewConfig = {
                  bottom: {
                    left: (<OperationRejection rejectionType="ON_HOLD" reason />),
                  }
                };
              }
              break;
            default:
              operationSubTitle = '';
          }

          return (
            <Grid
              key={additionalOperationKey}
              className={classes.operationRow}
              direction="row"
              container
            >
              <div
                className={classes.operationViewWrapper}
              >
                <OperationView
                  title={additionalOperationTitle}
                  subTitle={operationSubTitle}
                  status={status}
                  counters={operationViewConfig}
                  onDelete={(mode === 'create' || mode === 'edit')
                    ? () => {
                      updateDataField('additionalOperations', filter(additionalOperations, (it, additionalOperationIndex) => !isEqual(additionalOperationIndex, index)));
                    }
                    : null}
                />
              </div>
            </Grid>
          );
        })}
      </div>
    </div>
  );
};

AdditionalOperationsBlock.defaultProps = {
  error: false
};

AdditionalOperationsBlock.propTypes = {
  mode: PropTypes.string.isRequired,
  error: PropTypes.bool,
};

export default withValidation(observer(AdditionalOperationsBlock));
