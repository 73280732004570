import { useState } from 'react';


const useAssigneeSelect = () => {
  const [assignee, setAssignee] = useState({ assigneeId: '', assigneeName: '', isNew: false });

  const handleAssigneeChange = (assigneeId, assigneeName, updateNew) => {
    let isNew = false;
    if (updateNew) {
      const { assigneeId: oldAssigneeId } = assignee;
      isNew = assigneeId && assigneeId !== oldAssigneeId;
    }
    return (
      setAssignee({ assigneeId, assigneeName, isNew })
    );
  };

  return { assignee, handleAssigneeChange };
};

export default useAssigneeSelect;
