import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useStores from 'Store/useStores';
import {
  find, isString,
} from 'lodash';
import { Box, Tooltip } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import OptionRenderer from 'Common/components/Forms/OptionRenderer';
import TextInput from 'Common/components/Forms/Input/Text';
import InputWithTextEndAdornment from 'Business/tpsInspections/components/InputWithTextEndAdornment';
import useStyles from './styles';

const Measurement = ({
  inputName, measurement, onMeasurementChange, readByMode, styledAsTextMeasurement
}) => {
  const classes = useStyles();

  const {
    tpsReportStore: {
      measuringPoints,
    },
  } = useStores();

  const {
    value, sapCode, type, isVisible, isRequired,
  } = measurement || {};

  let valueToRender = value;
  const isBoolean = type === 'checkbox' || type === 'switch';

  const {
    title = '', shortTitle = '', unit = '',
  } = find(measuringPoints, { sapCode }) || {};

  const renderUnit = () => {
    if (!isString(unit)) return '';
    if (unit.length <= 3) return unit;
    const unitParts = unit.split('/');
    return unitParts.map((part, index) => {
      const partKey = `${part}_${index}`;
      return (<div key={partKey}>{`${part}${index === unitParts.length - 1 ? '' : '/'}`}</div>);
    });
  };

  if (!isVisible) {
    return (
      <Box className={clsx(
        classes.measurement,
        classes.textMeasurementReadonlyPadding,
        isBoolean && classes.booleanMeasurementWidth,
        !isBoolean && classes.textMeasurementWidth
      )}
      />
    );
  }
  if (readByMode) {
    if (isBoolean) {
      return (
        <div className={clsx(styledAsTextMeasurement ? classes.styledAsTextMeasurementWrapper : classes.booleanReadOnlyInputWrapper)}>
          <Tooltip title={title}>
            <Box className={clsx(styledAsTextMeasurement ? classes.styledAsTextMeasurement : classes.booleanReadOnlyInput)}>
              {value
                ? (<Check className={classes.positiveColor} />)
                : (<Close className={classes.negativeColor} />)
              }
              <div data-test={`measurementSapCode_${sapCode}`} data-active={Boolean(value)}>{shortTitle}</div>
            </Box>
          </Tooltip>
        </div>
      );
    }
    return (

      <Box
        className={
            clsx(
              classes.textMeasurementReadonlyPadding,
              isBoolean && classes.booleanMeasurementWidth,
              !isBoolean && classes.textMeasurementWidth
            )
          }
      >
        <Tooltip title={title}>
          <div>
            <OptionRenderer
              value={`${value} ${unit || ''}`}
              title={shortTitle || ''}
              wrapperClass={classes.measurement}
              valueClass={classes.measurementValue}
              name={`measurementSapCode_${sapCode}`}
              data-test={`measurementSapCode_${sapCode}`}
              withOverflowTip={false}
            />
          </div>
        </Tooltip>
      </Box>
    );
  }
  if (isBoolean && (!valueToRender || valueToRender === 'false')) {
    valueToRender = false;
  }

  const onChangeValue = (val) => {
    if (isBoolean) {
      onMeasurementChange(val);
      return;
    }
    const replacedValue = val.replace(/[^+|\-|/\d+$|,]/g, ''); // allow '+', '-', '/', ',' and digits
    onMeasurementChange(replacedValue);
  };

  const dataTestAttr = {
    'data-test': `measurementSapCode_${sapCode}`,
    ...!readByMode && isBoolean && { 'data-active': Boolean(value) }
  };

  return (
    <Box
      className={
          clsx(
            classes.measurement,
            isBoolean
              ? classes.booleanMeasurementWidth
              : classes.textMeasurementWidth
          )
        }
    >
      <Tooltip title={title}>
        <div>
          {isBoolean && (
            <InputWithTextEndAdornment // TODO INSERT NEW BOOLEAN INPUTS
              autoComplete="off"
              isReadonly={readByMode}
              marginTop={10}
              marginBottom={22}
              label={shortTitle}
              value={valueToRender}
              type={type}
              required={isRequired && !isBoolean}
              name={inputName}
              endAdornmentNode={(
                <div className={classes.unitLabel}>
                  {renderUnit()}
                </div>
              )}
              onChange={onChangeValue}
              {...dataTestAttr}
            />
          )}
          {!isBoolean && (
            <TextInput
              className={classes.textInput}
              isReadonly={readByMode}
              label={shortTitle}
              value={valueToRender}
              required={isRequired && !isBoolean}
              name={inputName}
              InputEndAdornment={(
                <div className={classes.unitLabel}>
                  {renderUnit()}
                </div>
              )}
              onChange={onChangeValue}
              {...dataTestAttr}
            />
          )}
        </div>
      </Tooltip>
    </Box>
  );
};

Measurement.defaultProps = {
  onMeasurementChange: () => {},
  readByMode: false,
  styledAsTextMeasurement: false,
};

Measurement.propTypes = {
  inputName: PropTypes.string.isRequired,
  measurement: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    sapCode: PropTypes.string,
    type: PropTypes.string,
    isVisible: PropTypes.bool,
    isRequired: PropTypes.bool,
    hasError: PropTypes.bool,
  }).isRequired,
  onMeasurementChange: PropTypes.func,
  readByMode: PropTypes.bool,
  styledAsTextMeasurement: PropTypes.bool,
};

export default Measurement;
