import { makeStatusDto } from 'Services/dto/makeTaskStatusDto';
import taskStatusSchema from 'Services/validation/schemas/TaskStatusSchema';
import { HEAD_MECHANIC, MECHANIC, COAL_MASTER } from 'Shared/constants/roles';

const dtoMaker = makeStatusDto(taskStatusSchema);

export const defectStatusConfig = t => (
  {
    ACTUAL: dtoMaker({
      title: t('NEW'),
      name: 'actual',
      query: ['NEW'],
      access: [HEAD_MECHANIC, MECHANIC, COAL_MASTER],
    }),
    IN_PROGRESS: dtoMaker({
      title: t('IN_PROGRESS'),
      name: 'in_progress',
      query: ['IN_PROGRESS'],
      access: [HEAD_MECHANIC, MECHANIC, COAL_MASTER],
    }),
    CANCELED: dtoMaker({
      title: t('CANCELED'),
      name: 'canceled',
      query: ['REJECTED'],
      access: [HEAD_MECHANIC, MECHANIC, COAL_MASTER],
    }),
    ARCHIVE: dtoMaker({
      title: t('ARCHIVE'),
      name: 'archive',
      access: [HEAD_MECHANIC, MECHANIC, COAL_MASTER],
      query: ['ARCHIVED']
    })
  }
);

export default defectStatusConfig;
