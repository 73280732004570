import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import useStores from 'Store/useStores';
import { get } from 'lodash';
import {
  Dialog,
  Grid,
  Box,
  TextField,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import HeaderLayout from 'Common/widgets/Layout/Header/Header';
import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

const ConfirmOverlay = ({
  isOpen, handleOverlayClose, handleConfirm,
}) => {
  const {
    coalTaskStore: { taskCompletionReasonsDictionary },
  } = useStores();

  const { t } = useTranslation();
  const classes = useStyles();
  const [description, setDescription] = useState('');
  const [reason, setReason] = useState('');

  useEffect(() => {
    setReason('');
    setDescription('');
  }, [isOpen]);

  const onChangeDescription = (event) => {
    const value = get(event, 'target.value');
    setDescription(value);
  };

  const handleChangeReason = (event) => {
    setReason(event.target.value);
    setDescription('');
  };

  const isCommentRequired = (currentReason) => {
    const currentReasonObj = taskCompletionReasonsDictionary.find(item => item.enumValue === currentReason);
    return currentReasonObj && currentReasonObj.isCommentRequired;
  };

  return (
    <Dialog fullScreen open={isOpen} data-test="confirmDialog">
      <Grid container direction="column" wrap="nowrap" style={{ height: '100%' }}>
        <HeaderLayout title={t('EXECUTION_APPROVAL_STATUS')}>
          <HeaderButton type="close" handleClick={handleOverlayClose} />
          <HeaderButton
            type="confirm"
            handleClick={() => handleConfirm({ reason, description })}
            disabled={!reason || (isCommentRequired(reason) && !description)}
          />
        </HeaderLayout>
        <div className={classes.root}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={reason}
              onChange={handleChangeReason}
            >
              {taskCompletionReasonsDictionary.map(item => (
                <FormControlLabel
                  key={item.enumValue}
                  classes={{ root: classes.formControlLabel }}
                  value={item.enumValue}
                  control={<Radio color="primary" />}
                  label={item.value || ''}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {isCommentRequired(reason) && (
            <Box css={{ marginBottom: '10px' }}>
              <TextField
                label={t('COMMENT')}
                fullWidth
                variant="outlined"
                multiline
                rows={10}
                value={description}
                name="description"
                onChange={onChangeDescription}
              />
            </Box>
          )}
        </div>
      </Grid>
    </Dialog>
  );
};

ConfirmOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleOverlayClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default observer(ConfirmOverlay);
