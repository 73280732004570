import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 20,
    lineHeight: 2.4,
    fontWeight: 'bold',
    color: theme.palette.black,
  },
  question: {
    fontWeight: 'bold',
    fontSize: 16,
    marginTop: 16,
  },
  answer: {
    fontSize: 16,
    paddingLeft: 40
  },
  link: {
    color: theme.palette.primaryLight,
    '&::visited': {
      color: theme.palette.primaryLight,
    },
    '&:hover': {
      textDecoration: 'underline',
    }
  }
}));

export default useStyles;
