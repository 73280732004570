import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  isArray, isEmpty, first, get
} from 'lodash';
import {
  Button, Box, IconButton, Tooltip
} from '@material-ui/core/';
import { Add, DeleteOutline } from '@material-ui/icons';
import PlantStorageInput from 'Business/plant/components/PlantStorageInput';
import TextInput from 'Common/components/Forms/Input/Text';

import useStyles from './styles';


const PlantsBlock = ({
  isReadonly,
  plants,
  onAddPlant,
  onRemovePlantByIndex,
  onChangeStorageCode,
  onChangePlantCode,
  removeField,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [plantsErrors, setPlantsErrors] = useState([]);
  const [storagesErrors, setStoragesErrors] = useState([]);

  return (
    <div className={classes.root}>
      <div className={classes.blockHeader}>
        <div className={classes.blockHeaderLabel}>{t('PLANTS_STORAGES_CODES')}</div>
      </div>
      <Button
        variant="outlined"
        color="primary"
        className={classes.addPlantsButton}
        onClick={onAddPlant}
        data-test="add-plants-button"
        disabled={isReadonly}
        fullWidth
      >
        <Add />
        <span>{t('ADD')}</span>
      </Button>
      {plants.map(({ code: plantCode, storages, uniqId }, plantIndex) => {
        const storage = isArray(storages) && !isEmpty(storages) ? first(storages) : {};
        const storageCode = get(storage, 'code', '');
        const uniqKey = uniqId;
        return (
          <div key={uniqKey} className={classes.plant} data-test={`row-${plantIndex + 1}`}>
            <Box className={classes.plantIdInputWrapper}>
              <TextInput
                inputComponent={PlantStorageInput}
                error={plantsErrors.includes(uniqKey)}
                onBlur={() => {
                  const pureValue = plantCode.replace(/[ _]/g, '');
                  if (pureValue.length !== 4 && !plantsErrors.includes(uniqKey)) setPlantsErrors([...plantsErrors, uniqKey]);
                }}
                value={plantCode}
                label={t('PLANT_CODE')}
                name={`plantCode${uniqKey}`}
                onChange={(value) => {
                  setPlantsErrors(plantsErrors.filter(item => item !== uniqKey));
                  onChangePlantCode(value, uniqKey);
                  if (!value) {
                    onChangeStorageCode('', uniqKey);
                    setStoragesErrors(storagesErrors.filter(item => item !== uniqKey));
                  }
                }}
                isReadonly={isReadonly}
                required
                data-test="plantCode"
                requiredMessage={`${t('REQUIRED')} (4 ${t('SYMBOLS')})`}
              />
            </Box>
            <Box className={classes.plantIdInputWrapper}>
              <TextInput
                inputComponent={PlantStorageInput}
                error={storagesErrors.includes(uniqKey)}
                onBlur={() => {
                  const pureValue = storageCode.replace(/[ _]/g, '');
                  if (pureValue.length !== 4 && !storagesErrors.includes(uniqKey)) setStoragesErrors([...storagesErrors, uniqKey]);
                }}
                label={t('STORAGE_CODE')}
                value={storageCode}
                name={`storageCode${uniqKey}`}
                onChange={(value) => {
                  setStoragesErrors(storagesErrors.filter(item => item !== uniqKey));
                  onChangeStorageCode(value, uniqKey);
                }}
                type="text"
                isReadonly={isReadonly || !plantCode}
                required
                data-test="storageCode"
                requiredMessage={`${t('REQUIRED')} (4 ${t('SYMBOLS')})`}
              />
            </Box>
            {!isReadonly && (
              <Tooltip title={t('DELETE')}>
                <IconButton
                  className={classes.deleteIconButton}
                  onClick={() => {
                    onRemovePlantByIndex(uniqKey);
                    removeField(`plantCode${uniqKey}`);
                    removeField(`storageCode${uniqKey}`);
                  }}
                  data-test="deletePlant"
                >
                  <DeleteOutline className={classes.icon} />
                </IconButton>
              </Tooltip>
            )}
          </div>
        );
      })}
    </div>
  );
};

PlantsBlock.propTypes = {
  isReadonly: PropTypes.bool.isRequired,
  onAddPlant: PropTypes.func.isRequired,
  onRemovePlantByIndex: PropTypes.func.isRequired,
  onChangeStorageCode: PropTypes.func.isRequired,
  onChangePlantCode: PropTypes.func.isRequired,
  removeField: PropTypes.func.isRequired,
  plants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
};

export default PlantsBlock;
