import {
  action, observable, runInAction, makeObservable
} from 'mobx';
import InspectionsAgent from 'ApiAgents/Inspections/InspectionsAgent';
import { INITIAL_PAGE_NUMBER, INITIAL_PAGE_SIZE } from 'Shared/constants/paging';

const inspectionsAgent = new InspectionsAgent();

export class CoalInspectionsListStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable totalPages = null;

  @observable isInspectionsListLoaded = false;

  @observable hasNextPage = false;

  @observable isLoadingByPage = false;

  @observable isLoading = false;

  @observable inspectionsList = [];

  @observable currentPageNumber = INITIAL_PAGE_NUMBER;

  @observable currentPageSize = INITIAL_PAGE_SIZE;

  @action loadInspectionsList = async (taskStatuses = [], pageNumber, pageSize, dateLimits) => {
    const isPaginating = !!pageNumber;
    this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', true);

    try {
      const requestBody = { taskStatuses, ...dateLimits && { ...dateLimits } };
      const {
        items = [],
        hasPreviousPage,
        hasNextPage,
        totalPages,
      } = (await inspectionsAgent.getCoalInspectionsList(requestBody, isPaginating, pageNumber, pageSize)) || {};

      runInAction(() => {
        this.inspectionsList = items;
        this.totalPages = totalPages;
        this.hasNextPage = hasNextPage;
        this.hasPreviousPage = hasPreviousPage;
        this.isInspectionsListLoaded = true;
        this.currentPageNumber = pageNumber;
        this.currentPageSize = pageSize;
      });
    } catch (error) {
      console.log('ERROR in INSPECTIONS FETCHING: ', error);
    }

    runInAction(() => {
      this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', false);
    });
  };

  @action discardState = () => {
    runInAction(() => {
      this.totalPages = null;
      this.isInspectionsListLoaded = false;
      this.hasNextPage = false;
      this.isLoadingByPage = false;
      this.isLoading = false;
      this.inspectionsList = [];
    });
  };
}

export default CoalInspectionsListStore;
