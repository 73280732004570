import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import PartFilterHeaderWithClearButton from './components/PartFilterHeaderWithClearButton';
import PartFilterApplyButton from './components/PartFilterApplyButton';

const FilterPanel = ({
  headerLabel,
  headerClearBtnLabel,
  filterOpened,
  children,
  filterApplyBtnLabel,
  hasActiveFilters,
  hasDiff,
  setFilters,
  handleClearFilters,
  filterPanelHeight,
}) => {
  const useStyles = makeStyles((theme = {}) => {
    const {
      headerHeight = 0,
      pageHeaderHeight = 0,
      tableFooterHeight = 0,
      mainContentPaddingTop = 0,
      mainContentPaddingBottom = 0,
      filterHeaderHeight = 0,
      filterPanelApplyButtonPaddingBottom = 0,
    } = theme;
    const reservedPageHeight = headerHeight + pageHeaderHeight + tableFooterHeight + mainContentPaddingTop + mainContentPaddingBottom;
    const panelHeight = filterPanelHeight || `calc(100vh - ${reservedPageHeight}px)`;
    const filtersInputsHeight = `calc(${panelHeight} - ${filterHeaderHeight}px - ${filterPanelApplyButtonPaddingBottom}px)`;
    return ({
      root: {
        overflow: 'hidden',
        zIndex: 11,
        position: 'absolute',
        top: 0,
        right: 0,
        width: filterOpened ? theme.filterPanelWidth : 0,
        height: panelHeight,
        backgroundColor: '#fff',
        transition: 'width 0.4s',
        boxShadow: '0 2px 4px 0 #ccc',
      },
      slidingContent: {
        width: theme.filterPanelWidth,
        height: panelHeight,
        maxHeight: panelHeight,
        borderLeft: '1px solid #ccc',
        paddingBottom: theme.spacing(2),
      },
      filtersWrapper: {
        zIndex: 100,
        position: 'relative',
        overflowY: 'visible',
      },
      filtersInputs: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        position: 'relative',
        maxHeight: filtersInputsHeight,
        overflowY: 'scroll',
        paddingBottom: 48,
        '&::before': {
          zIndex: 1,
          position: 'sticky',
          content: '""',
          display: 'block',
          top: 0,
          left: 0,
          right: 0,
          height: theme.spacing(1),
          background: 'linear-gradient(to top, rgba(255, 255, 255,0)0%,rgba(255,255,255,0.5)25%,rgba(255, 255, 255, 0.85) 100%)',
        },
      },
      filterApplyButton: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        position: 'sticky',
        bottom: 0,
        paddingBottom: 16,
        zIndex: 2,
        '&::after': {
          position: 'absolute',
          content: '""',
          display: 'block',
          bottom: '100%',
          left: 0,
          right: 0,
          height: theme.spacing(2),
          background: 'linear-gradient(to bottom, rgba(255, 255, 255,0)0%,rgba(255,255,255,0.5)75%,rgba(255, 255, 255, 0.85) 100%)',
        },
        '&::before': {
          position: 'absolute',
          content: '""',
          display: 'block',
          top: 0,
          left: 0,
          right: 0,
          height: '100%',
          backgroundColor: '#fff',
        },
      },
    });
  });
  const classes = useStyles();

  return (
    <div
      className={classes.root}
    >
      <div className={classes.slidingContent}>
        <PartFilterHeaderWithClearButton
          label={headerLabel}
          clearLabel={headerClearBtnLabel}
          hasActiveFilters={hasActiveFilters}
          handleClearFilters={handleClearFilters}
          hasDiff={hasDiff}
        />
        <div className={classes.filtersWrapper}>
          <div className={classes.filtersInputs}>
            {children}
          </div>
          <div className={classes.filterApplyButton}>
            <PartFilterApplyButton
              label={filterApplyBtnLabel}
              setFilters={setFilters}
              hasDiff={hasDiff}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

FilterPanel.defaultProps = {
  filterOpened: false,
  hasActiveFilters: false,
  children: null,
  hasDiff: null,
  setFilters: () => {},
  handleClearFilters: () => {},
  filterPanelHeight: undefined,
};

FilterPanel.propTypes = {
  filterOpened: PropTypes.bool,
  hasActiveFilters: PropTypes.bool,
  hasDiff: PropTypes.bool,
  children: PropTypes.node,
  filterApplyBtnLabel: PropTypes.string.isRequired,
  setFilters: PropTypes.func,
  handleClearFilters: PropTypes.func,
  headerLabel: PropTypes.string.isRequired,
  headerClearBtnLabel: PropTypes.string.isRequired,
  filterPanelHeight: PropTypes.string,
};

export default FilterPanel;
