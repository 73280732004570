import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { ReactComponent as DragIndicator } from 'Src/assets/drag-indicator.svg';
import RouteTechObject from 'Business/coal/components/RouteTechObject';
import arrayMove from 'array-move';

import { includes, get } from 'lodash';
import useStyles from './styles';

const DragHandle = SortableHandle(({ isSortable, classes, isHoverBlocked }) => (
  <div className={clsx(classes.dragIndicator, isSortable && !isHoverBlocked && classes.grab, isSortable && isHoverBlocked && classes.grabbing)}>
    {isSortable && (
    <DragIndicator />
    )}
  </div>
));

const SortableItem = SortableElement(({
  value,
  mode,
  classes,
  isSortable,
  isHoverBlocked,
  toggleIsRequiredQRCode,
  handleDeleteTechnicalObject,
  onEdit,
  setOperationOverlayVisibility,
  showSortableDragElement,
  onClickCollapseButton,
  unCollapsedTechnicalObjects
}) => {
  const technicalObjectId = get(value, 'id');
  return (
    <div className={clsx(classes.sortableItem, !isHoverBlocked && classes.hovered)}>
      {showSortableDragElement && (
        <DragHandle
          classes={classes}
          isSortable={isSortable}
          isHoverBlocked={isHoverBlocked}
        />
      )}
      <div className={classes.routeTechObjectWrapper}>
        <RouteTechObject
          mode={mode}
          data={value}
          onChangeIsRequiredQrCode={toggleIsRequiredQRCode}
          handleDelete={handleDeleteTechnicalObject}
          handleEdit={(idToEdit) => {
            onEdit(idToEdit);
            setOperationOverlayVisibility(true);
          }}
          isHoverBlocked={isHoverBlocked}
          onClickCollapseButton={() => onClickCollapseButton(technicalObjectId)}
          isExpandedMeasurementPoints={includes(unCollapsedTechnicalObjects, technicalObjectId)}
        />
      </div>
    </div>
  );
});

const SortableList = SortableContainer(({
  items,
  mode,
  isSortable,
  classes,
  isHoverBlocked,
  toggleIsRequiredQRCode,
  handleDeleteTechnicalObject,
  onEdit,
  setOperationOverlayVisibility,
  showSortableDragElement,
  onClickCollapseButton,
  unCollapsedTechnicalObjects
}) => (
  <div className={clsx(isHoverBlocked && classes.grabbing)}>
    {items.map((value, index) => {
      const sortableItemKey = `sortableItem-${index}`;
      return (
        <SortableItem
          key={sortableItemKey}
          index={index}
          value={value}
          disabled={!isSortable}
          isSortable={isSortable}
          classes={classes}
          mode={mode}
          isHoverBlocked={isHoverBlocked}
          toggleIsRequiredQRCode={toggleIsRequiredQRCode}
          handleDeleteTechnicalObject={handleDeleteTechnicalObject}
          onEdit={onEdit}
          setOperationOverlayVisibility={setOperationOverlayVisibility}
          showSortableDragElement={showSortableDragElement}
          onClickCollapseButton={onClickCollapseButton}
          unCollapsedTechnicalObjects={unCollapsedTechnicalObjects}
        />
      );
    })}
  </div>
));

const SortableTechnicalObjects = ({
  technicalObjects, mode,
  updateTechnicalObjects,
  isSortable,
  showSortableDragElement,
  toggleIsRequiredQRCode,
  handleDeleteTechnicalObject,
  onEdit,
  setOperationOverlayVisibility,
}) => {
  const [isHoverBlocked, setIsHoverBlocked] = useState(false);
  const classes = useStyles({ showSortableDragElement });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setIsHoverBlocked(false);
    updateTechnicalObjects(arrayMove(technicalObjects, oldIndex, newIndex));
  };

  const [unCollapsedTechnicalObjects, setUnCollapsedTechnicalObjects] = useState([]);

  const onClickCollapseButton = (technicalObjectId) => {
    if (includes(unCollapsedTechnicalObjects, technicalObjectId)) {
      setUnCollapsedTechnicalObjects(unCollapsedTechnicalObjects.filter(({ id: itemId }) => itemId === technicalObjectId));
    } else {
      setUnCollapsedTechnicalObjects([...unCollapsedTechnicalObjects, technicalObjectId]);
    }
  };

  return (
    <SortableList
      items={technicalObjects}
      updateBeforeSortStart={() => setIsHoverBlocked(true)}
      onSortEnd={onSortEnd}
      distance={1}
      useDragHandle
      lockAxis="y"
      lockToContainerEdges
      isSortable={isSortable}
      classes={classes}
      mode={mode}
      isHoverBlocked={isHoverBlocked}
      showSortableDragElement={showSortableDragElement}
      toggleIsRequiredQRCode={toggleIsRequiredQRCode}
      handleDeleteTechnicalObject={handleDeleteTechnicalObject}
      onEdit={onEdit}
      setOperationOverlayVisibility={setOperationOverlayVisibility}
      onClickCollapseButton={onClickCollapseButton}
      unCollapsedTechnicalObjects={unCollapsedTechnicalObjects}
    />
  );
};

SortableTechnicalObjects.defaultProps = {
  technicalObjects: [],
  updateTechnicalObjects: () => {},
  isSortable: false,
  showSortableDragElement: false,
  mode: 'view',
  toggleIsRequiredQRCode: () => {},
  handleDeleteTechnicalObject: () => {},
  onEdit: () => {},
  setOperationOverlayVisibility: () => {},
};

SortableTechnicalObjects.propTypes = {
  technicalObjects: PropTypes.arrayOf(PropTypes.shape({
    technicalObjectId: PropTypes.string,
  })),
  updateTechnicalObjects: PropTypes.func,
  isSortable: PropTypes.bool,
  showSortableDragElement: PropTypes.bool,
  mode: PropTypes.string,
  toggleIsRequiredQRCode: PropTypes.func,
  handleDeleteTechnicalObject: PropTypes.func,
  onEdit: PropTypes.func,
  setOperationOverlayVisibility: PropTypes.func,
};

export default SortableTechnicalObjects;
