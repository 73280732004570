import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import { isArray, isEmpty } from 'lodash';
import clsx from 'clsx';
import { formatDate, formatTime } from 'Src/utils/datetime';
import { Collapse, ButtonBase } from '@material-ui/core';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@material-ui/icons';

import useStyles from './styles';


const CollapsableCommentsBlock = ({ comments = [] }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  const [firstComment, ...otherComments] = comments;

  const handleCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  const renderComment = ({
    authorName, authorPosition, dateTime, comment
  }, index) => {
    const commentKey = `${comment}_${index}`;
    const notFirstComment = index > 1;
    return (
      <Fragment key={commentKey}>
        <div data-test={`comment_${index}`} className={clsx(classes.commentHeader, notFirstComment && classes.notFirstComment)}>
          <div className={classes.avatarWrapper}>
            <Avatar
              src=""
              className={classes.avatar}
              data-test="avatar"
            />
          </div>
          <div className={classes.author} data-test="author">
            <div className={classes.authorLabel} data-test="authorName">{authorName}</div>
            <div className={classes.authorPosition} data-test="authorPosition">{authorPosition}</div>
          </div>
          <div className={classes.dateTime} data-test="dateTime">
            {dateTime ? `${formatDate(dateTime)}  ${formatTime(dateTime)}` : t('INPUT_EMPTY_VALUE')}
          </div>
        </div>
        <div className={classes.comment} data-test="comment">{comment}</div>
      </Fragment>
    );
  };
  return (
    <div className={classes.root}>
      {renderComment(firstComment, 1)}
      {isArray(otherComments) && !isEmpty(otherComments) && (
        <>
          <Collapse
            in={isExpanded}
            unmountOnExit
          >
            {otherComments.map((comment, index) => renderComment(comment, index + 2))}
          </Collapse>
          <div className={classes.buttonWrapper}>
            <ButtonBase
              disableRipple
              className={classes.button}
              onClick={handleCollapse}
            >
              {isExpanded ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
              {isExpanded ? t('HIDE_COMMENTS') : t('SHOW_COMMENTS')}
            </ButtonBase>
          </div>
        </>
      )}
    </div>
  );
};

CollapsableCommentsBlock.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.shape({
    comment: PropTypes.string,
    authorName: PropTypes.string,
    authorPosition: PropTypes.string,
    dateTime: PropTypes.string,
  })),
};

CollapsableCommentsBlock.defaultProps = {
  comments: [],
};

export default CollapsableCommentsBlock;
