import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { ReactComponent as CheckpointIcon } from 'Assets/icon-checkpoints.svg';
import withValidation from 'Src/HOC/withValidation';

import useStyles from './styles';

const AddCheckpointsButton = ({
  visible, onClick, disabled, error, required
}) => {
  const { t } = useTranslation();
  const classes = useStyles({
    error, required, disabled, t
  });

  if (!visible) return null;

  return (
    <Button
      variant="outlined"
      color="primary"
      className={classes.addCheckpointButton}
      onClick={onClick}
      data-test="add-checkpoint-button"
      fullWidth
      disabled={disabled}
      startIcon={<CheckpointIcon />}
    >
      {t('ADD_TAG')}
    </Button>
  );
};

AddCheckpointsButton.defaultProps = {
  visible: true,
  error: false,
  required: false,
  disabled: false
};

AddCheckpointsButton.propTypes = {
  visible: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
};

export default withValidation(AddCheckpointsButton);
