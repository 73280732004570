import {
  action, runInAction, computed, makeObservable
} from 'mobx';
import { get } from 'lodash';
import {
  DEFECTS,
  MEASURING_POINTS,
} from 'Business/tpsInspections/config/operationTypeMapping';
import CheckpointAgent from 'ApiAgents/Checkpoints/CheckpointAgent';
import EquipmentAgent from 'ApiAgents/Equipment/EquipmentAgent';

const checkpointAgent = new CheckpointAgent();
const equipmentAgent = new EquipmentAgent();
const NAME_SEPARATOR = '.';

const initialState = {
  checkpointsList: [],
  loadedCheckpoint: {},
  powerUnits: [],
  firstLevelTechPlaces: [],
  firstLevelTechPlacesOfSelectedPowerUnit: [],
  isTechPlacesLading: false,
  technicalObjectParents: {},
  isLoading: false,
  isDeleting: false,
  isLoaded: false,
  selectedPowerUnitName: '',
  selectedTechPlaceName: '',
  isLoadingByPage: false,
  totalPages: null,
  hasNextPage: null,
  mainState: {
    technicalPlaceId: '',
    powerUnitId: '',
    checkpointName: ''
  }
};

const TECHOBJECTS_LEVELS_TO_LOAD = 2;

export class CheckpointStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
    this.rootStore.prepareState(initialState, this);
  }

  checkpointsList;

  loadedCheckpoint;

  powerUnits;

  firstLevelTechPlaces;

  firstLevelTechPlacesOfSelectedPowerUnit;

  isTechPlacesLading;

  technicalObjectParents;

  isLoading;

  isDeleting;

  isLoaded;

  selectedPowerUnitName;

  selectedTechPlaceName;

  isLoadingByPage;

  totalPages;

  hasNextPage;

  mainState;

  @computed get computedCheckpointName() {
    const { powerUnitId, technicalPlaceId, checkpointName } = this.mainState;
    if (!powerUnitId || !technicalPlaceId || !checkpointName) return '';

    return `${this.selectedPowerUnitName}${NAME_SEPARATOR}${this.selectedTechPlaceName}${NAME_SEPARATOR}${this.mainState.checkpointName}`;
  }

  @action loadCheckpointsList = async (pageNumber, pageSize) => {
    const isPaginating = !!pageNumber;
    this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', true);

    try {
      const { rootOrgUnit: { code: orgUnitCode } } = this.rootStore.userStore;
      const { items, totalPages, hasNextPage } = await checkpointAgent.getCheckpoints({ orgUnitCode }, true, pageNumber, pageSize);
      runInAction(() => {
        this.checkpointsList = items;
        this.isLoaded = true;
        this.totalPages = totalPages;
        this.hasNextPage = hasNextPage;
        this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', false);
      });
    } catch (error) {
      runInAction(() => {
        this.checkpointsList = [];
        this.isLoaded = true;
        this.totalPages = 0;
        this.hasNextPage = false;
        this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', false);
      });
      console.log('ERROR IN FETCH CHECKPOINTS: ', error);
      throw new Error(error);
    }
  };

  getPromisesToFetchParents = technicalObjects => technicalObjects.map(
    ({ technicalObjectId }) => this.rootStore.equipmentStore.loadTechObjectParentsPromise(technicalObjectId)
  );

  @action setTechObjectHistoryParents = (parents) => {
    parents
      .map(
        parent => parent.filter((it, parentIndex) => parentIndex > 2)
      )
      .forEach((filteredParents) => {
        const historyParents = filteredParents.map(({ id, title, parentId }, historyIndex) => ({
          id,
          title,
          parentId,
          isRoot: historyIndex === 0
        }));
        const key = historyParents[historyParents.length - 1].id;
        this.technicalObjectParents[key] = historyParents;
      });
  };

  @action save = async (id = null) => {
    this.isLoading = true;

    try {
      const final = this.buildFinalObject(id);
      const result = await checkpointAgent.saveCheckpoint(final);
      this.isLoading = false;
      return { result };
    } catch (error) {
      console.log('ERROR in SAVE CHECKPOINT: ', error);
      this.isLoading = false;
      return { error };
    }
  };

  @action deleteCheckpoint = async (id) => {
    if (!id) return { error: 'No checkpoint id provided' };

    this.isDeleting = true;

    try {
      const result = await checkpointAgent.deleteCheckpoint(id);
      runInAction(() => {
        this.isDeleting = false;
      });
      return { result };
    } catch (error) {
      runInAction(() => {
        this.isDeleting = false;
      });
      return { error };
    }
  };

  @action loadAllForCheckpointById = async (id, tpsCode, withTechObjectParents = false) => {
    this.isLoading = true;

    try {
      const checkpoint = await checkpointAgent.getCheckpointById(id);

      const {
        name,
        powerUnit: { id: powerUnitId, code: powerUnitSapCode, title: powerUnitTitle },
        technicalPlace: { id: techPlaceId, title: techPlaceTitle },
        technicalObjects
      } = checkpoint;

      const techObjectsRequests = [
        this.rootStore.equipmentStore.loadTechObjectsTrees(tpsCode, TECHOBJECTS_LEVELS_TO_LOAD),
        this.rootStore.equipmentStore.loadTechObjectsTrees(powerUnitSapCode, TECHOBJECTS_LEVELS_TO_LOAD),
      ];

      const [
        [{ title: tpsName, technicalObjectId: tpsId, children: powerUnits }],
        [{ children: firstLevelTechPlaces }],
      ] = await Promise.all(techObjectsRequests);

      let techObjectParents = [];

      if (withTechObjectParents) {
        techObjectParents = await Promise.all(this.getPromisesToFetchParents(technicalObjects));
        runInAction(() => {
          this.setTechObjectHistoryParents(techObjectParents);
        });
      }

      runInAction(() => {
        this.rootStore.equipmentStore.tpsName = tpsName;
        this.rootStore.equipmentStore.tpsId = tpsId;

        this.powerUnits = powerUnits.map(({ technicalObjectId, ...rest }) => ({ id: technicalObjectId, ...rest }));

        this.loadedCheckpoint = checkpoint;
        this.mainState = {
          technicalPlaceId: techPlaceId,
          powerUnitId,
          checkpointName: name
        };
        this.selectedPowerUnitName = powerUnitTitle;
        this.selectedTechPlaceName = techPlaceTitle;
        this.firstLevelTechPlacesOfSelectedPowerUnit = firstLevelTechPlaces
          .map(({ technicalObjectId, ...rest }) => ({ id: technicalObjectId, ...rest }))
          .filter(it => it.parentId === powerUnitId);

        this.rootStore.checkpointActionsStore.setConfirmedOperationsFromFetchedCheckpoint(technicalObjects);
        if (withTechObjectParents) {
          this.rootStore.checkpointActionsStore.setSelectedStateFromConfirmedOperations();
          this.rootStore.checkpointActionsStore.addHistoryToConfirmedOperations();
        }
        this.isLoaded = true;
      });
    } catch (error) {
      console.log('ERROR IN FETCH CHECKPOINT BY ID: ', error);
    }
    runInAction(() => {
      this.isLoading = false;
    });
  };

  @action loadAllForCheckpointCreation = async (tpsCode) => {
    this.isLoading = true;

    try {
      const [{ title: tpsName, technicalObjectId: tpsId, children: powerUnits } = {}] = await this.rootStore.equipmentStore.loadTechObjectsTrees(tpsCode, TECHOBJECTS_LEVELS_TO_LOAD);

      runInAction(() => {
        this.rootStore.equipmentStore.tpsName = tpsName;
        this.rootStore.equipmentStore.tpsId = tpsId;
        this.powerUnits = powerUnits.map(({ technicalObjectId, ...rest }) => ({ id: technicalObjectId, ...rest }));
        this.isLoading = false;
        this.isLoaded = true;
      });
    } catch (error) {
      console.log('ERROR IN FETCHING TECHOBJECTS FOR CHECKPOINT CREACION: ', error);
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action handleChange = (value, name) => {
    this.mainState[name] = value;
  };

    handleSelectPowerUnit = powerUnitId => action(async () => {
      this.mainState.powerUnitId = powerUnitId;
      this.discardSelectedActionState();
      this.mainState.technicalPlaceId = '';
      const { title, sapCode: powerUnitSapCode } = this.powerUnits.find(
        it => it.id === powerUnitId
      );
      this.selectedPowerUnitName = title;
      this.isTechPlacesLading = true;
      try {
        const [powerUnit] = await equipmentAgent.getTechObjectsTrees(powerUnitSapCode, TECHOBJECTS_LEVELS_TO_LOAD) || [];
        this.isTechPlacesLading = false;
        const powerUnitChildren = get(powerUnit, 'children', []).map(({ technicalObjectId, ...rest }) => ({ ...rest, id: technicalObjectId }));
        this.firstLevelTechPlacesOfSelectedPowerUnit = powerUnitChildren;
      } catch (error) {
        this.isTechPlacesLading = false;
        console.log('ERROR IN FETCH POWER UNIT: ', error);
      }
    });

  handleSelectTechPlace = technicalPlaceId => action(() => {
    this.mainState.technicalPlaceId = technicalPlaceId;
    this.discardSelectedActionState();
    if (!technicalPlaceId) return;

    const { title } = this.firstLevelTechPlacesOfSelectedPowerUnit.find(
      it => it.id === technicalPlaceId
    ) || {};
    this.selectedTechPlaceName = title;
  });

  buildFinalObject = (id) => {
    const { technicalPlaceId, powerUnitId, checkpointName } = this.mainState;
    const { rootOrgUnit: { code: orgUnitCode } } = this.rootStore.userStore;
    const { confirmedOperations } = this.rootStore.checkpointActionsStore;
    const { tpsId } = this.rootStore.equipmentStore;

    return {
      ...(id && { checkpointId: id }),
      name: checkpointName,
      technicalPlaceId,
      powerUnitId,
      tpsId,
      orgUnitCode,
      technicalObjects: confirmedOperations.map(({ techObject: { id: technicalObjectId }, operations }) => (
        {
          technicalObjectId,
          checkpointDefects: (operations[DEFECTS] && operations[DEFECTS].length > 0)
            ? operations[DEFECTS].map(({ id: defectId }) => ({ defectId }))
            : [],
          checkpointMeasuringPoints: (operations[MEASURING_POINTS] && operations[MEASURING_POINTS].length > 0)
            ? operations[MEASURING_POINTS].map(({ id: measuringPointId }) => ({ measuringPointId }))
            : [],
        }
      ))
    };
  };

  @action discardState = () => {
    this.rootStore.setInitialState(initialState, this);
    this.discardSelectedActionState();
    this.rootStore.equipmentStore.discardState();
  };

  @action discardSelectedActionState = () => {
    this.rootStore.checkpointActionsStore.discardState();
  };

  @action removeCheckpointFromList = (checkpointId) => {
    this.checkpointsList = this.checkpointsList.filter(({ id }) => id !== checkpointId);
  }
}

export default CheckpointStore;
