import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Typography
} from '@material-ui/core/';
import { business } from 'Shared/constants/business';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  panel: {
    background: theme.palette.white,
    position: 'fixed',
    maxWidth: 360,
    width: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 6,
    boxShadow: '0 2px 5px -1px rgba(0,0,0,.35)',
    zIndex: 10000
  },
  title: {
    padding: '24px 24px 20px',
    borderBottom: '1px solid #efefef'
  },
  hint: {
    display: 'block',
    fontSize: 14,
    marginTop: 10,
    color: theme.palette.black70
  },
  btns: {
    padding: '20px 24px 24px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  btn: {
    paddingLeft: 20,
    paddingRight: 20,
  }
}));

const BusinessSelector = ({
  availableBusinessTypes,
  setBusinessType,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.panel}>
      <Typography className={classes.title} variant="h5">
        <span>{t('CHOOSE_BUSINESS_TYPE_FOR_FURTHER_WORK')}</span>
        <span className={classes.hint}>{t('YOU_CAN_SWITCH_AT_ANY_TIME')}</span>
      </Typography>
      <Box className={classes.btns}>
        {availableBusinessTypes.map(availableBusinessType => (
          <Button
            className={classes.btn}
            key={availableBusinessType}
            onClick={() => setBusinessType(availableBusinessType)}
            variant="contained"
            color="primary"
            disableElevation
          >
            {business[availableBusinessType]}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

BusinessSelector.propTypes = {
  availableBusinessTypes: PropTypes.arrayOf(
    PropTypes.string
  ).isRequired,
  setBusinessType: PropTypes.func.isRequired
};

export default BusinessSelector;
