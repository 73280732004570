import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing('20px', 2, 2, 6)
  },
  addCheckpointButton: {
    boxShadow: 'none',
    minHeight: 80,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    border: `1px dashed ${theme.palette.black20} !important`,
    fontSize: 16,
    fontWeight: 'normal'
  },
  editParameterIcon: {
    margin: 12,
    color: theme.palette.primaryLight,
    '&:hover': {
      backgroundColor: theme.palette.lightSelection
    },
  },
  removeParameterIcon: {
    margin: 12,
    color: theme.palette.negative,
    '&:hover': {
      backgroundColor: theme.palette.pink
    },
  },
}));

export default useStyles;
