import React from 'react';
import * as routes from 'Shared/constants/routes';
import { Route, Redirect } from 'react-router-dom';

import PrivateRoute from 'Common/components/Authorization/PrivateRoute';
import Administration from 'Business/plant/pages/AdministrationPage';
import SelectDictionary from 'Business/plant/pages/SelectDictionary';
import SelectSetting from 'Business/plant/pages/SelectSetting';
import DefectsDictionaryItem from 'Business/plant/pages/DefectsDictionaryItem';
import DefectsDictionaryList from 'Business/plant/pages/DefectsDictionaryList';
import ParallelOperations from 'Business/plant/pages/ParallelOperations';
import SharePointSettingsList from 'Business/plant/pages/SharePointSettingsList';
import PresenceControlList from 'Business/plant/pages/PresenceControlList';
import PresenceControlItem from 'Business/plant/pages/PresenceControlItem';
import PauseReasonsDictionaryList from 'Business/plant/pages/PauseReasonsDictionaryList';
import OperationKeysItem from 'Business/plant/pages/OperationKeysItem';
import OperationKeysList from 'Business/plant/pages/OperationKeysList';
import PauseReasonsDictionaryItem from 'Business/plant/pages/PauseReasonsDictionaryItem';
import SharePointSettingsItem from 'Business/plant/pages/SharePointSettingsItem';
import WorkplacesList from 'Business/plant/pages/WorkplacesList';
import WorkPlacesItem from 'Business/plant/pages/WorkPlacesItem';

import {
  VOLUMES_VIEW,
  DICTIONARIES_VIEW,
  DEFECTS_DICTIONARY_ITEM_EDIT,
  DEFECTS_DICTIONARY_LIST_VIEW,
  SETTINGS_VIEW,
  PARALLEL_OPERATIONS_VIEW,
  PRESENCE_CONTROL_VIEW,
  PRESENCE_CONTROL_EDIT,
  PAUSE_REASONS_DICTIONARY_LIST_VIEW,
  PAUSE_REASON_DICTIONARY_CREATE,
  PAUSE_REASON_DICTIONARY_EDIT,
  OPERATION_KEYS_LIST_VIEW,
  OPERATION_KEY_CREATE,
  OPERATION_KEY_EDIT,
  WORKPLACES_LIST_VIEW,
  WORKPLACE_EDIT,
  WORKPLACE_CREATE,
  SHAREPOINT_SETTINGS_LIST_VIEW,
  SHAREPOINT_SETTING_VIEW,
  SHAREPOINT_SETTING_EDIT
} from 'Src/RBAC/businessPermissions';
import CommonRoutes from 'Src/routes/CommonRoutes';

const PLRoutes = () => (
  <CommonRoutes>
    <Route exact path={`${routes.HOME}`}>
      <Redirect to={routes.ADMINISTRATION} />
    </Route>
    <Route exact path={`${routes.HOME}`}>
      <Redirect to={routes.VOLUMES} />
    </Route>
    {/* Создание объема */}
    <PrivateRoute
      exact
      path={routes.VOLUMES}
      component={Administration}
      permission={VOLUMES_VIEW}
    />
    <PrivateRoute
      exact
      path={`${routes.DEFECTS_DICTIONARIES}/:id`}
      component={DefectsDictionaryItem}
      permission={DEFECTS_DICTIONARY_ITEM_EDIT}
    />
    <PrivateRoute
      path={routes.DEFECTS_DICTIONARIES}
      component={DefectsDictionaryList}
      permission={DEFECTS_DICTIONARY_LIST_VIEW}
    />
    <PrivateRoute
      exact
      path={`${routes.PAUSE_REASONS_DICTIONARIES}/create`}
      component={props => <PauseReasonsDictionaryItem {...props} mode="create" />}
      permission={PAUSE_REASON_DICTIONARY_CREATE}
    />

    <PrivateRoute
      exact
      path={`${routes.PAUSE_REASONS_DICTIONARIES}/:id`}
      component={props => <PauseReasonsDictionaryItem {...props} mode="edit" />}
      permission={PAUSE_REASON_DICTIONARY_EDIT}
    />

    <PrivateRoute
      path={routes.PAUSE_REASONS_DICTIONARIES}
      component={PauseReasonsDictionaryList}
      permission={PAUSE_REASONS_DICTIONARY_LIST_VIEW}
    />

    <PrivateRoute
      exact
      path={routes.CREATE_OPERATION_KEY}
      component={props => <OperationKeysItem {...props} mode="create" />}
      permission={OPERATION_KEY_CREATE}
    />

    <PrivateRoute
      exact
      path={routes.EDIT_OPERATION_KEY}
      component={props => <OperationKeysItem {...props} mode="edit" />}
      permission={OPERATION_KEY_EDIT}
    />

    <PrivateRoute
      path={routes.OPERATION_KEYS_SETTINGS}
      component={OperationKeysList}
      permission={OPERATION_KEYS_LIST_VIEW}
    />

    <PrivateRoute
      exact
      path={routes.CREATE_WORKPLACE}
      component={props => <WorkPlacesItem {...props} mode="create" />}
      permission={WORKPLACE_CREATE}
    />

    <PrivateRoute
      exact
      path={routes.EDIT_WORKPLACE}
      component={props => <WorkPlacesItem {...props} mode="edit" />}
      permission={WORKPLACE_EDIT}
    />

    <PrivateRoute
      path={routes.WORKPLACES_SETTINGS}
      component={WorkplacesList}
      permission={WORKPLACES_LIST_VIEW}
    />

    <PrivateRoute
      path={routes.DICTIONARIES}
      component={SelectDictionary}
      permission={DICTIONARIES_VIEW}
    />
    <PrivateRoute
      path={routes.PARALLEL_OPERATIONS}
      component={ParallelOperations}
      permission={PARALLEL_OPERATIONS_VIEW}
    />
    <PrivateRoute
      exact
      path={routes.VIEW_PRESENCE_CONTROL}
      component={() => <PresenceControlItem mode="view" />}
      permission={PRESENCE_CONTROL_VIEW}
    />
    <PrivateRoute
      exact
      path={routes.EDIT_PRESENCE_CONTROL}
      component={() => <PresenceControlItem mode="edit" />}
      permission={PRESENCE_CONTROL_EDIT}
    />
    <PrivateRoute
      exact
      path={routes.PRESENCE_CONTROL}
      component={PresenceControlList}
      permission={PRESENCE_CONTROL_VIEW}
    />

    <PrivateRoute
      exact
      path={routes.VIEW_SHAREPOINT_SETTING}
      component={props => <SharePointSettingsItem {...props} mode="view" />}
      permission={SHAREPOINT_SETTING_VIEW}
    />

    <PrivateRoute
      exact
      path={routes.EDIT_SHAREPOINT_SETTING}
      component={props => <SharePointSettingsItem {...props} mode="edit" />}
      permission={SHAREPOINT_SETTING_EDIT}
    />

    <PrivateRoute
      path={routes.SHAREPOINT_SETTINGS}
      component={SharePointSettingsList}
      permission={SHAREPOINT_SETTINGS_LIST_VIEW}
    />

    <PrivateRoute path={routes.SETTINGS} component={SelectSetting} permission={SETTINGS_VIEW} />
  </CommonRoutes>
);

export default PLRoutes;
