import {
  action,
  observable,
  runInAction,
  makeObservable,
} from 'mobx';
import {
  get, isEmpty, omit,
} from 'lodash';
import UserAgent from 'ApiAgents/Users/UserAgent';
import { onFileReceived } from 'Src/utils/file';
import { formatDate, formatToISOString } from 'Src/utils/datetime';
import { INITIAL_PAGE_NUMBER } from 'Shared/constants/paging';

const ITEMS_PER_PAGE = 30;

const usersAgent = new UserAgent();

export class UsersListStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable totalPages = null;

  @observable hasNextPage = null;

  @observable isLoadingByPage = false;

  @observable isLoading = false;

  @observable usersList = [];

  @observable isExporting = false;

  @observable isImporting = false;

  @observable allUsers = [];

  @observable currentPage = INITIAL_PAGE_NUMBER;

  @action discardUsers = () => {
    runInAction(() => {
      this.isLoading = false;
      this.allUsers = [];
      this.usersList = [];
    });
  }

   getUserStatus = (isActive, isBlocked) => {
     if (isActive && isBlocked) {
       return '';
     }
     if (isActive) {
       return 'ACTIVE';
     }
     if (isBlocked) {
       return 'BLOCKED';
     }
     return '';
   }

  @action loadUsersList = async (pageNumber, pageSize = ITEMS_PER_PAGE) => {
    const isInitialLoading = pageNumber === INITIAL_PAGE_NUMBER;
    if (isInitialLoading) {
      this.currentPage = pageNumber;
      this.usersList = [];
      this.isLoading = true;
    }

    const isPaginating = !!pageNumber;
    const filterState = get(this.rootStore, 'usersListFilterStore.filterState');
    const userStatus = this.getUserStatus(filterState.activeUsers, filterState.blockedUsers);
    const isFiltersSet = get(this.rootStore, 'usersListFilterStore.isFiltersSet');
    const searchWord = get(this.rootStore, 'usersListFilterStore.searchWord');

    const params = omit({
      ...filterState,
      ...(Boolean(userStatus) && { userStatus }),
    }, ['activeUsers', 'blockedUsers']);

    try {
      if (!(isFiltersSet || searchWord)) {
        runInAction(() => {
          this.isLoading = false;
          this.allUsers = [];
          this.usersList = [];
          this.currentPage = pageNumber;
          this.hasNextPage = false;
        });
        return;
      }
      const { items = [], hasNextPage } = (await usersAgent.fetchAll(params, isPaginating, pageNumber, pageSize)) || {};

      runInAction(() => {
        this.isLoading = false;
        this.allUsers = items;
        if (!isEmpty(items)) {
          this.usersList = [...this.usersList, ...items];
        }
        this.currentPage = pageNumber;
        this.hasNextPage = hasNextPage;
      });
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
        this.usersList = [];
        this.currentPage = INITIAL_PAGE_NUMBER;
        this.hasNextPage = false;
      });
      console.log('ERROR in USERS FETCHING: ', error);
    }

    runInAction(() => {
      this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', false);
    });
  };

  @action loadSearchUsersList = async (pageNumber, pageSize = ITEMS_PER_PAGE) => {
    const isInitialLoading = pageNumber === INITIAL_PAGE_NUMBER;
    if (isInitialLoading) {
      this.currentPage = pageNumber;
      this.usersList = [];
      this.isLoading = true;
    }

    const isPaginating = !!pageNumber;

    const searchWord = get(this.rootStore, 'usersListFilterStore.searchWord');
    try {
      const { items = [], hasNextPage } = (await usersAgent.searchUsers(searchWord, isPaginating, pageNumber, pageSize)) || {};

      runInAction(() => {
        this.isLoading = false;
        this.allUsers = items;
        if (!isEmpty(items)) {
          this.usersList = [...this.usersList, ...items];
        }
        this.currentPage = pageNumber;
        this.hasNextPage = hasNextPage;
      });
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
        this.usersList = [];
        this.currentPage = INITIAL_PAGE_NUMBER;
        this.hasNextPage = false;
      });
      console.log('ERROR in USERS FETCHING: ', error);
      throw new Error(error);
    }

    runInAction(() => {
      this.rootStore.setLoadingFlag(isPaginating, this, 'isLoadingByPage', 'isLoading', false);
    });
  };

  @action exportUsersList = async () => {
    this.isExporting = true;

    try {
      this.isExporting = true;
      const usersList = await usersAgent.exportUsersList();
      onFileReceived(usersList.data, `users-${formatDate(formatToISOString(new Date()))}`);

      runInAction(() => {
        this.isExporting = false;
      });
    } catch (error) {
      console.log('ERROR IN EXPORTING USERS LIST: ', error);
      runInAction(() => {
        this.isExporting = false;
      });
      throw new Error(error);
    }
  };

  @action importUsersList = async (event) => {
    this.isImporting = true;

    try {
      const [file] = get(event, 'target.files', []);
      const response = await usersAgent.importUsersList(file);
      const responseType = get(response, 'type');
      const success = responseType === 'application/json';
      if (success) {
        runInAction(() => {
          this.isImporting = false;
        });
        return { success };
      }
      onFileReceived(
        response,
        `imported-users-${formatDate(formatToISOString(new Date()))}`,
      );
      runInAction(() => {
        this.isImporting = false;
      });
      return { error: true };
    } catch (error) {
      console.log('ERROR IN IMPORTING USERS LIST: ', error);
      runInAction(() => {
        this.isImporting = false;
      });
      throw new Error(error);
    }
  };
}

export default UsersListStore;
