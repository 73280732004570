const VIEW = '/view';
const CREATE = '/create';
const EDIT = '/edit';
export const CONTROL = '/control';
const REQUEST = '/request';

export const HOME = '/';
export const TASKS = '/tasks';
export const STATUSES = '/statuses';
export const INSPECTIONS = '/inspections';
export const PROBLEMS = '/problems';
export const RESOURCES = '/resources';
export const MATERIALS = '/materials';
export const BRIGADES = '/brigades';
export const TRANSPORTS = '/transports';
export const MAP = '/map';
export const STATISTICS = '/statistics';
export const FAQ = '/faq';
export const TASKS_PERFORMANCE = '/tasks-performance';
export const MEASUREMENTS = '/measurements';
export const OCCUPATIONAL_SAFETY = '/occupational-safety';
export const DETAILS = '/details';
export const ADMINISTRATION = '/administration';
export const USERS = '/users';
export const PLANNER_TASKS = '/planner-tasks';
export const MEASUREMENT_REPORTS = '/measurement-reports';
export const DEFECTS = '/defects';
export const NO_ACCESS = '/no-access';
export const BLOCKED_USER = '/blocked-user';
export const NOT_FOUND = '/not-found';
export const DICTIONARIES = '/dictionaries';
export const SETTINGS = '/settings';
export const PARALLEL_OPERATIONS = `${SETTINGS}/parallel-operations`;
export const PRESENCE_CONTROL = `${SETTINGS}/presence-control`;
export const SHAREPOINT_SETTINGS = `${SETTINGS}/sharepoint-settings`;
export const VIEW_SHAREPOINT_SETTING = `${SHAREPOINT_SETTINGS}${VIEW}/:id`;
export const EDIT_SHAREPOINT_SETTING = `${SHAREPOINT_SETTINGS}${EDIT}/:id`;
export const VOLUMES = `${SETTINGS}/volumes`;
export const EDIT_PRESENCE_CONTROL = `${PRESENCE_CONTROL}${EDIT}/:id`;
export const VIEW_PRESENCE_CONTROL = `${PRESENCE_CONTROL}${VIEW}/:id`;
export const OPERATION_KEYS_SETTINGS = `${SETTINGS}/operation-keys`;
export const EDIT_OPERATION_KEY = `${OPERATION_KEYS_SETTINGS}/:id`;
export const CREATE_OPERATION_KEY = `${OPERATION_KEYS_SETTINGS}/create`;
export const WORKPLACES_SETTINGS = `${SETTINGS}/workplaces`;
export const EDIT_WORKPLACE = `${WORKPLACES_SETTINGS}/:id`;
export const CREATE_WORKPLACE = `${WORKPLACES_SETTINGS}/create`;
export const REPORTS = '/reports';
export const REPORT_INSPECTION_STATUSES = `${REPORTS}/inspection-statuses`;
export const REPORT_COMPLETED_INSPECTION = `${REPORTS}/completed-inspection`;
export const REPORT_DETAILED_INSPECTION = `${REPORTS}/detailed-inspection`;

export const DEFECTS_DICTIONARIES = `${DICTIONARIES}/defects`;
export const PAUSE_REASONS_DICTIONARIES = `${DICTIONARIES}/pause-reasons`;

export const CREATE_BRIGADE = `${BRIGADES}${CREATE}`;
export const EDIT_BRIGADE = `${BRIGADES}${EDIT}`;
export const BRIGADE_DETAILS = `${BRIGADES}${DETAILS}`;

export const VIEW_INSPECTION = `${INSPECTIONS}${VIEW}/:id`;
export const CREATE_INSPECTION = `${INSPECTIONS}/:action(${CREATE.slice(1)})`;
export const EDIT_INSPECTION = `${INSPECTIONS}/:id/:action(${EDIT.slice(1)})`;

export const CREATE_TASK = `${TASKS}${CREATE}`;
export const VIEW_TASK = `${TASKS}${VIEW}`;
export const VIEW_DEFECT = `${DEFECTS}${VIEW}`;
export const EDIT_TASK = `${TASKS}${EDIT}`;
export const CONTROL_TASK = `${TASKS}${CONTROL}`;

export const CREATE_USER = `${USERS}${CREATE}`;
export const VIEW_USER = `${USERS}${VIEW}`;
export const EDIT_USER = `${USERS}${EDIT}`;

export const REQUEST_REPORT = `${MEASUREMENT_REPORTS}${REQUEST}`;
export const CREATE_REPORT = `${MEASUREMENT_REPORTS}${CREATE}`;
export const EDIT_REPORT = `${MEASUREMENT_REPORTS}${EDIT}`;
export const VIEW_REPORT = `${MEASUREMENT_REPORTS}${VIEW}`;

export const INSPECTIONTASKS = '/inspection-tasks';
export const CREATE_INSPECTIONTASK = `${INSPECTIONTASKS}/:action(${CREATE.slice(1)})`;
export const VIEW_INSPECTIONTASK = `${INSPECTIONTASKS}/:id/`;
export const EDIT_INSPECTIONTASK = `${INSPECTIONTASKS}/:id/:action(${EDIT.slice(1)})`;

export const TASK_ROUTES = '/task-routes';
export const CREATE_TASK_ROUTE = `${TASK_ROUTES}/:action(${CREATE.slice(1)})`;
export const VIEW_TASK_ROUTE = `${TASK_ROUTES}/:id/`;
export const EDIT_TASK_ROUTE = `${TASK_ROUTES}/:id/:action(${EDIT.slice(1)})`;

export const CHECKPOINTS = '/checkpoints';
export const CHECKPOINTS_NEW = '/checkpoints-new';
export const CREATE_CHECKPOINT = `${CHECKPOINTS}/:action(${CREATE.slice(1)})`;
export const CREATE_CHECKPOINT_NEW = `${CHECKPOINTS_NEW}${CREATE}`;
export const VIEW_CHECKPOINT = `${CHECKPOINTS}/:id/`;
export const VIEW_CHECKPOINT_NEW = `${CHECKPOINTS_NEW}${VIEW}/:id/`;
export const EDIT_CHECKPOINT = `${CHECKPOINTS}/:id/:action(${EDIT.slice(1)})`;
export const EDIT_CHECKPOINT_NEW = `${CHECKPOINTS_NEW}${EDIT}/:id`;

export const TASKS_PERFORMANCE_STATISTICS = `${STATISTICS}${TASKS_PERFORMANCE}`;
export const MEASUREMENTS_STATISTICS = `${STATISTICS}${MEASUREMENTS}`;

export const CREATE_PLANNER_TASK = `${PLANNER_TASKS}${CREATE}`;
export const VIEW_PLANNER_TASK = `${PLANNER_TASKS}${VIEW}`;
export const EDIT_PLANNER_TASK = `${PLANNER_TASKS}${EDIT}`;
