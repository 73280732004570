import React from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useStyles from './styles';

const Layout = ({
  children, autoScroll, autoHeight, fixedHeight, rootStyles, style
}) => {
  const classes = useStyles();

  return (
    <Paper
      className={clsx(
        classes.default,
        autoScroll && classes.autoScroll,
        autoHeight && classes.autoHeight,
        fixedHeight && classes.fixedHeight,
        rootStyles,
      )}
      data-test="layout"
      style={{ ...style }}
    >
      {children}
    </Paper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  autoScroll: PropTypes.bool,
  autoHeight: PropTypes.bool,
  fixedHeight: PropTypes.bool,
  rootStyles: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]))
};

Layout.defaultProps = {
  autoScroll: false,
  autoHeight: false,
  fixedHeight: false,
  rootStyles: '',
  style: {}
};

export default Layout;
