import {
  action, computed, makeObservable, toJS, observable, runInAction
} from 'mobx';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { DEFECTS, MEASURING_POINTS } from 'Business/tpsInspections/config/operationTypeMapping'; // TODO
import { OPERATION, ACTION, TECHNICAL_PLACE } from 'Business/tpsInspections/config/sectionTypeMapping'; // TODO


const initialState = {
  historyPath: [],
  techObjectTree: [],
  selectedActions: [],
  selectedOperation: {
    type: null,
    actions: []
  },
  visibleSections: [],
  history: [],
  selectedState: new Map(),
  confirmedOperations: [],
};

export class CheckpointActionsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
    this.rootStore.prepareState(initialState, this);
    this.techObjectTree = rootStore.equipmentStore.techObjectsForOperationSelect;
  }

  historyPath;

  techObjectTree;

  selectedActions;

  selectedOperation;

  visibleSections;

  history;

  selectedState;

  confirmedOperations;

  @computed get sectionTypeToSelect() {
    if (this.visibleSections.length) {
      const { type } = this.visibleSections[this.visibleSections.length - 1];
      return (type === ACTION) ? OPERATION : type;
    }
    return TECHNICAL_PLACE;
  }

  @computed get isSelectedStateEmpty() {
    return this.selectedState.size === 0;
  }

  noContentForOverlay = () => {
    const { techObjectsForOperationSelect, isLoadedTechObjectsForOperationSelect } = this.rootStore.equipmentStore;
    return isLoadedTechObjectsForOperationSelect && techObjectsForOperationSelect.children.length === 0;
  };

  @action discardState() {
    this.rootStore.setInitialState(initialState, this);
  }

  @action addToHistory = (item) => {
    if (this.history.length > 0) {
      if (this.shouldToggleItemsInHistory(this.history, item, 'parentId')) {
        this.history.pop();
      }
    }
    this.history = [...this.history, item];
    this.historyPath = this.getHistoryPath();
  };

  handleListItemClick = (id, title, parentId, type, isRoot = false) => action(async () => {
    if (this.history.length > 0) {
      if (id === this.history[this.history.length - 1].id) {
        return;
      }
    }

    if (isRoot) {
      this.history = [];
    }

    this.addToHistory({
      title, id, parentId, type, isRoot
    });

    const children = this.rootStore.equipmentStore.flatTechObjects[id];

    if (this.visibleSections.length > 0) {
      //Handle same level item click
      const sectionIndex = this.visibleSections.findIndex(it => it.parentId === parentId);
      if (sectionIndex !== -1) {
        this.visibleSections = this.visibleSections.filter((it, index) => index < sectionIndex);
      }
    }

    if (this.visibleSections.length === 2) {
      this.visibleSections = [];
    }

    if (!children) {
      await this.prepareOperationAndActionSections(id);
      return;
    }

    this.visibleSections.push({
      id,
      parentId,
      title,
      type: children[0].type,
      items: children
    });
  });

  @action prepareOperationAndActionSections = async (techObjectId) => {
    await this.rootStore.equipmentStore.setOperations(techObjectId);
    if (!this.visibleSections.some(it => it.type === OPERATION)) {
      runInAction(() => {
        this.visibleSections = [...this.visibleSections, { type: OPERATION }, { type: ACTION }];
        this.selectedActions = [];
      });
    }
  };

  @action prepareTechObjectEditingState = async (selectedTechObjectId) => {
    this.visibleSections = [];
    const { history = [], techObject: { id: techObjectId, title: techObjectTitle } } = this.confirmedOperations.find(
      ({ techObject: { id } }) => id === selectedTechObjectId
    );

    this.history = history;
    this.historyPath = this.getHistoryPath();

    if (history.length < 3) {
      const parentId = this.rootStore.equipmentStore.getTechObjectParentId(selectedTechObjectId);
      const children = this.rootStore.equipmentStore.flatTechObjects[parentId];

      this.visibleSections.push({
        id: techObjectId,
        parentId,
        title: techObjectTitle,
        type: children[0].type,
        items: children
      });
    }
    await this.prepareOperationAndActionSections(techObjectId);
  };

  @action prepareTechObjectAddingState = () => {
    this.visibleSections = [];
    this.history = [];
  };

  shouldToggleItemsInHistory = (arr, item, key) => item[key] === arr[arr.length - 1][key];

  isCheckedAction = (id) => {
    const selectedElement = this.selectedState.get(this.historyPath);
    if (selectedElement && selectedElement.operations.has(this.selectedOperation.type)) {
      return selectedElement.operations.get(this.selectedOperation.type).some(it => it.id === id);
    }
    return false;
  };

  getHistoryPath = () => this.history.map(it => it.id).toString();

  @action selectActions = (operationType) => {
    if (this.visibleSections.length >= 2) {
      this.visibleSections = this.visibleSections.filter(it => [OPERATION, ACTION].includes(it.type));
    }
    this.selectedActions = this.rootStore.equipmentStore.operations.reduce(
      (acc, item) => (item.type === operationType ? ([...acc, ...item.actions]) : acc),
      []
    );
    this.selectedOperation = {
      type: operationType,
      actions: []
    };
  };

  @action setSelectedState = (id, title) => {
    const selectedElement = this.selectedState.get(this.historyPath);

    if (!selectedElement) {
      this.selectedState.set(
        this.historyPath,
        {
          techObject: this.history[this.history.length - 1],
          history: [...this.history],
          operations: observable.map({ [this.selectedOperation.type]: [{ id, title }] })
        }
      );
    } else {
      const actions = selectedElement.operations.get(this.selectedOperation.type);
      if (!actions) {
        selectedElement.operations.set(this.selectedOperation.type, [{ id, title }]);
        return;
      }
      const currentIndex = actions.findIndex(it => it.id === id);
      if (currentIndex !== -1) {
        actions.splice(currentIndex, 1);

        if (actions.length === 0) {
          selectedElement.operations.delete(this.selectedOperation.type);
        }
      } else {
        actions.push({ id, title });
      }

      if (this.noSelectedActions(selectedElement.operations)) {
        this.selectedState.delete(this.historyPath);
      }
    }
  };

  @action setConfirmedOperationsFromFetchedCheckpoint = (technicalObjects) => {
    this.confirmedOperations = technicalObjects
      .map(({
        technicalObjectId, technicalObjectTitle, checkpointDefects, checkpointMeasuringPoints
      }) => ({
        techObject: { id: technicalObjectId, title: technicalObjectTitle },
        operations: {
          ...(checkpointDefects.length > 0
            && {
              [DEFECTS]: checkpointDefects
                .map(({ defectId, defectTitle }) => ({ id: defectId, title: defectTitle }))
            }),
          ...(checkpointMeasuringPoints.length > 0
            && {
              [MEASURING_POINTS]: checkpointMeasuringPoints
                .map(({ measuringPointId, measuringPointTitle }) => ({ id: measuringPointId, title: measuringPointTitle }))
            }),
        }
      }));
  };

  @action addHistoryToConfirmedOperations = () => {
    const { technicalObjectParents } = this.rootStore.checkpointStore;
    if (isEmpty(technicalObjectParents)) return;

    this.confirmedOperations = this.confirmedOperations.map((operation) => {
      const { techObject: { id: techObjectId } } = operation;
      const history = technicalObjectParents[techObjectId];

      return { ...operation, history };
    });
  };

  @action setSelectedStateFromConfirmedOperations = () => {
    const { technicalObjectParents } = this.rootStore.checkpointStore;
    this.selectedState = new Map();
    this.confirmedOperations.forEach(({ techObject, operations, history: confirmedOperationHistory }) => {
      const history = technicalObjectParents[techObject.id] || confirmedOperationHistory || [];
      const historyKey = history.map(({ id }) => id).toString();

      this.selectedState.set(
        historyKey,
        {
          history,
          techObject,
          operations: observable.map(cloneDeep(operations))
        }
      );
    });
  };

  handleToggleAction = (id, title) => action(() => {
    this.setSelectedState(id, title);
  });

  handleTechObjectActionClick = techObjectId => action((e) => {
    e.stopPropagation();
    this.removeTechObjectHistoryFromSelected(techObjectId);
  });

  @action removeTechObjectHistoryFromSelected = (techObjectId) => {
    this.selectedState.forEach((value, key) => {
      if (key.split(',').includes(techObjectId.toString())) {
        this.selectedState.delete(key);
      }
    });
  };

  @action removeTechObjectFromConfirmed = (techObjectId) => {
    this.confirmedOperations = this.confirmedOperations.filter(({ techObject: { id } }) => id !== techObjectId);
    this.removeTechObjectHistoryFromSelected(techObjectId);
  };

  handleOperationActionClick = type => action((e) => {
    e.stopPropagation();
    const selectedElement = this.selectedState.get(this.historyPath);
    if (selectedElement) {
      selectedElement.operations.delete(type);

      if (this.noSelectedActions(selectedElement.operations)) {
        this.selectedState.delete(this.historyPath);
      }
    }
  });

  handleHistoryClick = (id, parentId, title, isRoot) => action(() => {
    if (this.history[this.history.length - 1].id === id) return;
    this.selectedActions = [];
    this.visibleSections = [];
    let children;
    const itemIndex = this.history.findIndex(it => it.id === id);
    this.history = this.history.filter((it, index) => index <= itemIndex);

    if (this.history.length < 3 && !isRoot) {
      this.history.forEach(({ id: itId, parentId: itParentId, title: itTitle }) => {
        const itChildren = this.rootStore.equipmentStore.flatTechObjects[itId];
        this.visibleSections.push({
          id: itId,
          parentId: itParentId,
          title: itTitle,
          type: itChildren[0].type,
          items: itChildren
        });
      });
    } else {
      children = this.rootStore.equipmentStore.flatTechObjects[id];

      if (!children) {
        this.visibleSections = [...this.visibleSections, { type: OPERATION }, { type: ACTION }];
        return;
      }

      this.visibleSections.push({
        id,
        parentId,
        title,
        type: children ? children[0].type : null,
        items: children || []
      });
    }
  });

  noSelectedActions = operations => Array.from(operations.values())
    .every(value => (value === null || value.length === 0));

  isCurrentlySelected = id => this.history.some(it => it.id === id);

  isTechObjectInSelectedState = id => Array.from(this.selectedState.keys()).some(it => it.includes(id));

  isOperationInSelectedState = (type) => {
    const selectedElement = this.selectedState.get(this.getHistoryPath());

    if (selectedElement) {
      return selectedElement.operations.has(type);
    }

    return false;
  };

  @action setConfirmedOperations = () => {
    this.confirmedOperations = Array.from(this.selectedState.values())
      .map(({ techObject, operations, history }) => ({
        techObject,
        operations: toJS(Object.fromEntries(operations)),
        history
      }));
  }
}

export default CheckpointActionsStore;
