import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {
  CloseOutlined, ExpandMore, ReportProblemOutlined, SettingsInputAntennaOutlined, Pause
} from '@material-ui/icons';
import clsx from 'clsx';
import useStores from 'Store/useStores';
import { ReactComponent as IconCheckpoints } from 'Assets/icon-checkpoints.svg';
import TechObjectDetails from 'Business/tpsInspections/components/TechObjectDetails';
import CheckpointNotScanned from 'Business/tpsInspections/components/CheckpointNotScannedBlock';
import {
  TPS_ALTERNATIVE_SCRIPT_FOR_SCANNING_CHECKPOINT,
  TPS_CHECKPOINT_NFC_SCAN_FAIL_REASON,
  TPS_CHECKPOINT_PAUSE_REASON,
  TPS_CHECKPOINT_REJECT_REASON,
  OPERATION_STATUS
} from 'Shared/constants/dictionaries';
import { TODO, REJECTED } from 'Shared/constants/operationStatuses';
import useStyles from './styles';


const CheckpointCard = ({ checkpoint, onExpand, isExpanded }) => {
  const {
    dictionaryStore: {
      loadedDictionaries,
    },
  } = useStores();
  const { t } = useTranslation();
  const checkpointStatuses = loadedDictionaries[OPERATION_STATUS];
  const scanFailureReasons = loadedDictionaries[TPS_CHECKPOINT_NFC_SCAN_FAIL_REASON];
  const pauseReasons = loadedDictionaries[TPS_CHECKPOINT_PAUSE_REASON];
  const rejectReasons = loadedDictionaries[TPS_CHECKPOINT_REJECT_REASON];
  const alternativeScripts = loadedDictionaries[TPS_ALTERNATIVE_SCRIPT_FOR_SCANNING_CHECKPOINT];

  const {
    id,
    fullName,
    order,
    checkpointStatus,
    scanFailureReason,
    scanFailureReasonComment,
    pauseReason,
    pauseReasonComment,
    rejectionReason,
    rejectionReasonComment,
    tpsAlternativeScriptForScanningCheckpoint,
    tpsAlternativeScriptForScanningCheckpointComment,
    technicalObjects
  } = checkpoint;
  const classes = useStyles({ status: checkpointStatus });
  const isCheckpointDisabled = [TODO, REJECTED].includes(checkpointStatus);

  const hasOperations = operationKey => technicalObjects.some(obj => obj[operationKey].length > 0);

  return (
    <div className={classes.checkpointCardWrapper}>
      <div className={classes.checkpointCard}>
        <Tooltip title={isExpanded ? t('CLOSE') : t('OPEN')} classes={{ tooltip: classes.tooltip }}>
          <IconButton
            aria-label="expand"
            onClick={onExpand(id)}
            className={clsx(classes.expand, {
              [classes.expandOpen]: isExpanded,
            })}
          >
            <ExpandMore />
          </IconButton>
        </Tooltip>

        <Grid container justify="space-between">
          <Grid item>
            <span className={classes.checkpointOrder}>{`№ ${order}`}</span>
          </Grid>
          <Grid item className={clsx(classes.checkpointStatus, classes.checkpointStatusMargin)}>
            <Tooltip title={t('PLANNED_DEFECTS_PRESENCE')} classes={{ tooltip: classes.tooltip }}>
              <ReportProblemOutlined className={clsx(classes.checkpointStatusIcon, {
                [classes.positive]: hasOperations('checkpointDefects') && !isCheckpointDisabled,
              })}
              />
            </Tooltip>

            <Tooltip title={t('PLANNED_MEASUREMENTS_PRESENCE')} classes={{ tooltip: classes.tooltip }}>
              <SettingsInputAntennaOutlined className={clsx(classes.checkpointStatusIcon, {
                [classes.positive]: hasOperations('checkpointMeasuringPoints') && !isCheckpointDisabled,
              })}
              />
            </Tooltip>
            {checkpointStatus !== 'TODO'
            && <span className={classes.checkpointStatusColor}>{checkpointStatuses[checkpointStatus]}</span>
            }
          </Grid>
          <Grid item xs={12}>
            <span className={classes.checkpointTitle}>{fullName}</span>

            {(scanFailureReason
              || scanFailureReasonComment
              || tpsAlternativeScriptForScanningCheckpoint
              || tpsAlternativeScriptForScanningCheckpointComment
            )
            && (
              <CheckpointNotScanned title={t('FAILED_CHECKPOINT_SCANNING')} Icon={IconCheckpoints}>
                { scanFailureReasons[scanFailureReason]
                || scanFailureReasonComment
                || alternativeScripts[tpsAlternativeScriptForScanningCheckpoint]
                || tpsAlternativeScriptForScanningCheckpointComment}
              </CheckpointNotScanned>
            )
            }

            {(rejectionReason || rejectionReasonComment)
            && (
              <CheckpointNotScanned title={t('CHECKPOINT_REJECTED')} Icon={CloseOutlined}>
                {rejectReasons[rejectionReason] || rejectionReasonComment}
              </CheckpointNotScanned>
            )
            }

            {(pauseReason || pauseReasonComment)
            && (
              <CheckpointNotScanned title={t('CHECKPOINT_ON_PAUSE')} Icon={Pause} iconClass={classes.alert}>
                {pauseReasons[pauseReason] || pauseReasonComment}
              </CheckpointNotScanned>
            )
            }
          </Grid>
        </Grid>
      </div>
      <Collapse
        in={isExpanded}
        timeout={0}
        unmountOnExit
      >
        {technicalObjects.map(techObject => (
          <TechObjectDetails techObject={techObject} isCheckpointDisabled={isCheckpointDisabled} key={techObject.technicalObjectId} />
        ))}
      </Collapse>
    </div>
  );
};

CheckpointCard.propTypes = {
  onExpand: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  checkpoint: PropTypes.shape({
    id: PropTypes.number.isRequired,
    fullName: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    checkpointStatus: PropTypes.string,
    scanFailureReason: PropTypes.string,
    scanFailureReasonComment: PropTypes.string,
    pauseReason: PropTypes.string,
    pauseReasonComment: PropTypes.string,
    rejectionReason: PropTypes.string,
    rejectionReasonComment: PropTypes.string,
    tpsAlternativeScriptForScanningCheckpoint: PropTypes.string,
    tpsAlternativeScriptForScanningCheckpointComment: PropTypes.string,
    technicalObjects: PropTypes.array // eslint-disable-line
  }).isRequired
};

export default observer(CheckpointCard);
