import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  Typography
} from '@material-ui/core/';

import HeaderButton from 'Common/components/Forms/Button/HeaderButton/HeaderButton';
import Layout from 'Common/widgets/Layout/Paper/Layout';
import Header from 'Common/widgets/Layout/Header/Header';

import useStyles from './styles';

export const PanelTitle = ({
  children,
  ...restProps
}) => {
  const classes = useStyles();
  return (
    <Typography variant="h3" className={classes.title} {...restProps}>
      {children}
    </Typography>
  );
};

PanelTitle.propTypes = {
  children: PropTypes.node
};

PanelTitle.defaultProps = {
  children: ''
};

export const PanelSubTitle = ({
  children,
  ...restProps
}) => {
  const classes = useStyles();
  return (
    <Typography variant="h4" className={classes.subTitle} {...restProps}>
      {children}
    </Typography>
  );
};

PanelSubTitle.propTypes = {
  children: PropTypes.node
};

PanelSubTitle.defaultProps = {
  children: ''
};


export const PanelBox = ({
  children,
  boxStyles,
  ...restProps
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.boxWrapper} {...restProps}>
      <Box className={classes.box} style={boxStyles}>
        {children}
      </Box>
    </Box>
  );
};

PanelBox.propTypes = {
  children: PropTypes.node,
  boxStyles: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ])
  ),
};

PanelBox.defaultProps = {
  children: null,
  boxStyles: {}
};

const Panel = ({
  mode,
  layoutStyles,
  viewItem,
  editItem,
  addItem,
  saveItem,
  goBack,
  deleteItem,
  labelGoToView,
  labelGoToList,
  labelView,
  labelCreate,
  labelEdit,
  children,
  disableCancel,
  disableSave,
  disableDelete,
  disableEdit,
  disableAdd,
  withActions,
  actions,
}) => {
  let title = labelView;
  if (mode === 'create') { title = labelCreate; }
  if (mode === 'edit') { title = labelEdit; }

  return (
    <Layout
      style={mode === 'onlyView' ? {
        minHeight: '100vh'
      } : layoutStyles}
    >
      <Header
        sticky
        style={mode === 'onlyView' ? {
          top: 0
        } : {}}
        title={title}
        handleGoBack={mode === 'edit' ? viewItem : goBack}
        titleGoBack={mode === 'edit' ? labelGoToView : labelGoToList}
        isActiveGoBack={mode === 'view' || mode === 'onlyView'}
      >
        {withActions
          && (
            <Grid container wrap="nowrap">
              {mode === 'edit' && (
                <Grid item>
                  <HeaderButton type="close" handleClick={viewItem} disabled={disableCancel} />
                  <HeaderButton type="save" handleClick={saveItem} disabled={disableSave} />
                </Grid>
              )}
              {mode === 'create' && (
                <Grid item>
                  <HeaderButton type="close" handleClick={goBack} disabled={disableCancel} />
                  <HeaderButton type="save" handleClick={saveItem} disabled={disableSave} />
                </Grid>
              )}
              {mode === 'view' && (
                <Grid item>
                  {actions.includes('delete') && (<HeaderButton type="delete" handleClick={deleteItem} disabled={disableDelete} />)}
                  {actions.includes('edit') && (<HeaderButton type="edit" handleClick={editItem} disabled={disableEdit} />)}
                  {actions.includes('add') && (<HeaderButton type="add" handleClick={addItem} disabled={disableAdd} />)}
                </Grid>
              )}
            </Grid>
          )}
      </Header>

      {children}

    </Layout>
  );
};

Panel.propTypes = {
  children: PropTypes.node,
  layoutStyles: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ])
  ),
  mode: PropTypes.string,
  viewItem: PropTypes.func,
  editItem: PropTypes.func,
  addItem: PropTypes.func,
  saveItem: PropTypes.func,
  goBack: PropTypes.func,
  deleteItem: PropTypes.func,
  labelGoToView: PropTypes.string,
  labelGoToList: PropTypes.string,
  labelView: PropTypes.string,
  labelCreate: PropTypes.string,
  labelEdit: PropTypes.string,
  disableAdd: PropTypes.bool,
  disableSave: PropTypes.bool,
  disableCancel: PropTypes.bool,
  disableEdit: PropTypes.bool,
  disableDelete: PropTypes.bool,
  withActions: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.string.isRequired
  ),
};

Panel.defaultProps = {
  children: null,
  disableSave: false,
  disableCancel: false,
  disableEdit: false,
  disableAdd: false,
  disableDelete: false,
  withActions: true,
  actions: [],
  mode: '',
  viewItem: () => {},
  editItem: () => {},
  addItem: () => {},
  saveItem: () => {},
  goBack: () => {},
  deleteItem: () => {},
  labelGoToView: '',
  labelGoToList: '',
  labelView: '',
  labelCreate: '',
  labelEdit: '',
  layoutStyles: {},
};

export default Panel;
