import React from 'react';
import PropTypes from 'prop-types';
import { HIGH, LOW } from 'Shared/constants/priority';
import {
  SvgIcon,
} from '@material-ui/core/';
import { Error, SwapCalls } from '@material-ui/icons';

import useStyles from './styles';

const InspectionTypeIcon = ({ taskPriority }) => {
  const classes = useStyles();
  return (
    <div className={classes.iconWrapper}>
      <SvgIcon
        component={SwapCalls}
      />
      {taskPriority === HIGH && (
        <>
          <div className={classes.substrate} />
          <SvgIcon
            viewBox="0 0 24 24"
            className={classes.warningIcon}
            component={Error}
          />
        </>
      )}
    </div>
  );
};

InspectionTypeIcon.defaultProps = {
  taskPriority: LOW,
};

InspectionTypeIcon.propTypes = {
  taskPriority: PropTypes.string,
};

export default InspectionTypeIcon;
