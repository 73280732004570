import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  taskType: {
    '& .MuiFormLabel-root': {
      display: 'none',
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'column !important',
    },
  },
}));

export default useStyles;
