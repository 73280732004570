import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { get, isEmpty } from 'lodash';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import useStores from 'Store/useStores';
import * as routes from 'Shared/constants/routes';

import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import Panel, { PanelBox } from 'Common/widgets/Layout/Panel/Panel';
import AddUserHelper from 'Common/pages/User/components/AddUserHelper';
import UserGeneralBlock from 'Common/pages/User/components/UserGeneralBlock';
import UserHeader from 'Common/pages/User/components/UserHeader';
import PlantsBlock from 'Business/plant/components/PlantsBlock';
import { PL } from 'Shared/constants/business';

import useStyles from './styles';

const User = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { mode } = props;
  const history = useHistory();
  const params = useParams();
  const { id: userId } = params;

  const {
    adminUserStore: {
      userData = {},
      getUserById,
      isLoaded,
      loadRolesDictionary,
      onAddPlant,
      onRemovePlantByIndex,
      onChangeStorageCode,
      onChangePlantCode,
      discardEditorState,
      reset,
      save,
      isUpdating,
      setMode,
      loadAllRootOrgUnits,
    },
    drawerStore: { isDrawerOpened },
    notificationStore: { enqueueSnackbar },
    validationStore: {
      validateRequiredFields, isValidFields, hasErrors, removeField
    },
    userStore: {
      businessType,
    },
  } = useStores();

  const { fullName, isUserInSystem } = userData;

  const showPlantsBlock = businessType === PL;

  const plants = get(userData, 'plants', []);

  const classes = useStyles({ isDrawerOpened, t });

  let panelActions = ['edit'];

  let shouldLoadUserById = false;
  let isReadonlyByMode = false;
  let showAddUserHelper = false;
  let requiredValidationMessage;
  let successSaveMessage;

  switch (mode) {
    case 'create':
      shouldLoadUserById = true;
      isReadonlyByMode = false;
      requiredValidationMessage = t('CANT_CREATE');
      successSaveMessage = t('USER_SUCCESSFULLY_CREATED');
      break;
    case 'view':
      shouldLoadUserById = true;
      isReadonlyByMode = true;
      if (!isUserInSystem) {
        showAddUserHelper = true;
        panelActions = ['add'];
      }
      break;
    case 'edit':
      shouldLoadUserById = true;
      requiredValidationMessage = t('CANT_UPDATE');
      successSaveMessage = t('CHANGES_SUCCESSFULLY_APPLIED');
      break;
    default:
      shouldLoadUserById = false;
      isReadonlyByMode = false;
  }


  useEffect(() => {
    setMode(mode);
  }, [mode]);

  const goBack = () => {
    reset();
    const { state } = location;
    const { fromPath, ...rest } = state || {};
    history.push({
      pathname: fromPath || routes.USERS,
      state: rest,
    });
  };

  const viewItem = () => {
    discardEditorState();
    history.push({
      pathname: `${routes.VIEW_USER}/${userId}`,
    });
  };

  const saveItem = async () => {
    validateRequiredFields();
    if (!isValidFields()) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: requiredValidationMessage,
                },
              ],
            },
            {
              rowContent: [
                {
                  type: 'text',
                  text: hasErrors() ? t('FORM_HAS_ERROR') : t('FILL_ALL_REQUIRED_FIELDS'),
                },
              ],
            },
          ],
        },
        variant: 'error',
      });
      return;
    }
    const { res } = await save();

    if (res) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [
            {
              rowContent: [
                {
                  type: 'text',
                  text: successSaveMessage,
                },
              ],
            },
          ],
        },
        variant: 'success',
      });
      viewItem();
      return;
    }

    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('REQUEST_DEFAULT_ERROR'),
              },
            ],
          },
        ],
      },
      variant: 'error',
    });
  };

  const showDefaultErrorNotification = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [
          {
            rowContent: [
              {
                type: 'text',
                text: t('REQUEST_DEFAULT_ERROR'),
              },
            ],
          },
        ],
      },
      variant: 'error',
    });
  };

  useEffect(() => {
    reset();

    // if id is not valid
    if (shouldLoadUserById && !userId) {
      goBack();
    }
    if (shouldLoadUserById && userId) {
      getUserById(userId).catch(() => showDefaultErrorNotification());
    }
  }, [userId, shouldLoadUserById]);

  useEffect(() => {
    loadRolesDictionary().catch(() => showDefaultErrorNotification());
    loadAllRootOrgUnits().catch(() => showDefaultErrorNotification());
  }, []);

  if (!isLoaded && mode !== 'create') {
    return <ProgressBar />;
  }

  const handleEdit = () => {
    history.push({
      pathname: `${routes.EDIT_USER}/${userId}`,
    });
  };

  const handleAdd = () => {
    history.push({
      pathname: `${routes.CREATE_USER}/${userId}`,
    });
  };

  return (
    (isLoaded || mode === 'create')
    && !isEmpty(userData) && (
      <>
        {isUpdating && <ProgressBar />}
        <Panel
          mode={mode}
          viewItem={viewItem}
          editItem={handleEdit}
          addItem={handleAdd}
          saveItem={saveItem}
          goBack={goBack}
          labelGoToView={t('GO_BACK')}
          labelGoToList={t('GO_BACK')}
          labelView={fullName}
          labelCreate={fullName}
          labelEdit={fullName}
          actions={panelActions}
          withActions
        >
          <UserHeader user={userData} showAddUserHelper={showAddUserHelper} />
          {showAddUserHelper ? (
            <AddUserHelper />
          ) : (
            <>
              <PanelBox
                className={classes.panelBox}
                boxStyles={{ padding: 0, maxWidth: 'inherit' }}
              >
                <UserGeneralBlock mode={mode} />
              </PanelBox>
              {showPlantsBlock && (
                <PanelBox
                  className={classes.panelBox}
                  boxStyles={{ padding: 0, maxWidth: 'inherit' }}
                >
                  <PlantsBlock
                    isReadonly={isReadonlyByMode}
                    plants={plants}
                    onAddPlant={onAddPlant}
                    onRemovePlantByIndex={onRemovePlantByIndex}
                    onChangeStorageCode={onChangeStorageCode}
                    onChangePlantCode={onChangePlantCode}
                    removeField={removeField}
                  />
                </PanelBox>
              )}
            </>
          )}
        </Panel>
      </>
    )
  );
};

User.propTypes = {
  mode: PropTypes.string.isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      positive: PropTypes.string,
      black30: PropTypes.string,
      negative: PropTypes.string,
    }),
  }),
};

User.defaultProps = {
  theme: {},
};

export default observer(User);
