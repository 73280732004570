import PresenceControlAgent from 'ApiAgents/Settings/PresenceControlAgent';
import {
  action, observable, runInAction, makeObservable
} from 'mobx';
import { get, omit, isArray } from 'lodash';


const presenceControlAgent = new PresenceControlAgent();

export class PresenceControlStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable list = [];

  @observable formData = {};

  @observable isLoading = false;

  @observable isListLoaded = false;

  @observable isItemLoading = false;

  @observable isSaving = false;

  @observable isUpdating = false;


  @action loadList = async () => {
    this.isLoading = true;
    this.isListLoaded = false;
    try {
      const data = await presenceControlAgent.loadList();
      let list = get(data, 'items');
      if (isArray(list)) {
        list = list.map(item => ({ ...item, isUpdating: false }));
      }
      runInAction(() => {
        this.list = list;
        this.isLoading = false;
        this.isListLoaded = true;
      });
    } catch (error) {
      console.log('ERROR LOAD PARALLEL OPERATIONS: ', error);
      runInAction(() => {
        this.list = [];
        this.isLoading = false;
        this.isListLoaded = true;
      });
      throw new Error(error);
    }
  }

  @action loadById = async (id) => {
    this.isItemLoading = true;
    try {
      const data = await presenceControlAgent.loadById(id);

      runInAction(() => {
        this.formData = data;
        this.isItemLoading = false;
      });
    } catch (error) {
      console.log('ERROR LOAD PARALLEL OPERATIONS: ', error);
      runInAction(() => {
        this.isItemLoading = false;
      });
      throw new Error(error);
    }
  }

  @action update = async () => {
    this.isUpdating = true;
    try {
      const res = await presenceControlAgent.update(omit(this.formData, 'isUpdating'));

      runInAction(() => {
        this.isUpdating = false;
        this.formData = {};
      });
      return { res };
    } catch (error) {
      console.log('ERROR LOAD PARALLEL OPERATIONS: ', error);
      runInAction(() => {
        this.isUpdating = false;
        this.formData = {};
      });
      return { error };
    }
  }

  @action onAlternativeFieldUpdate = async (id, value) => {
    const fieldIndex = this.list.findIndex(i => i.id === id);
    if (fieldIndex < 0) return;
    const newList = [...this.list];
    try {
      newList[fieldIndex] = { ...this.list[fieldIndex], isUpdating: true };
      runInAction(() => {
        this.list = newList;
      });
      const {
        id: fieldId, name, distance, mapLink
      } = this.list[fieldIndex];
      const result = await presenceControlAgent.update({
        id: fieldId, name, distance, mapLink, isAlternativeEnabled: value
      });
      const updatedIsAlternativeEnabled = get(result, 'isAlternativeEnabled', false);
      newList[fieldIndex] = { ...this.list[fieldIndex], isUpdating: false, isAlternativeEnabled: updatedIsAlternativeEnabled };
      runInAction(() => {
        this.list = newList;
      });
      return { error: false };
    } catch (error) {
      console.log('ERROR ON ALTERNATIVE FIELD UPDATE: ', error);
      newList[fieldIndex] = { ...this.list[fieldIndex], isUpdating: false };
      runInAction(() => {
        this.list = newList;
      });
      return { error };
    }
  }

  @action updateFormData = (field, value) => {
    this.formData[field] = value;
  };

  @action resetFormData = () => {
    this.formData = {};
  };
}

export default PresenceControlStore;
