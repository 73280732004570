import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  papaerWrapper: {
    width: 376,
    padding: theme.spacing(0, 3, 3, 3),
    boxSizing: 'border-box',
  },
  subtitle: {
    color: theme.palette.black,
    fontSize: 20,
    lineHeight: '48px',
    fontWeight: 'bold',
    padding: theme.spacing(1, 0, 2),
  },
  lastEditContainer: {
    fontSize: 16,
    padding: theme.spacing(0, 2),
  },
  labelText: {
    color: theme.palette.black30,
    fontSize: 12,
  },
  timeContainer: {
    display: 'flex',
    marginBottom: theme.spacing(1)
  },
  time: {
    marginRight: theme.spacing(2),
  },
  parameterWrap: {
    margin: '10px 0'
  },
}));

export default useStyles;
