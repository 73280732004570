import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import useStores from 'Store/useStores';
import { isEmpty, assign, get } from 'lodash';
import { isDateOverdue } from 'Src/utils/datetime';
import Loader from 'Common/components/Progress/components/Circular/ProgressBar';
import { Box, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import OperationView from 'Common/widgets/OperationView/OperationView';
import actionButtonTypes from 'Common/components/Forms/Button/configs/actionButtonTypes';
import ActionButton from 'Common/components/Forms/Button/components/Action/Action';
import withModalOpenHandling from 'HOC/withModalOpenHandling';
import { taskStatusAliases } from 'Src/business/tpsRepairs/config/taskStatusAliases';
import {
  FOREMAN, MASTER, MASTER_RU, HEAD_MASTER, HEAD_MASTER_RU, IDENTITY_TYPE_BRIGADE
} from 'Shared/constants/roles';
import useAssigneeSelect from 'Hooks/useAssigneSelect';
import CancelModal from 'Src/business/tpsRepairs/components/CancelModal/CancelModal';
import TaskExecutionResultBlock from 'Business/tpsRepairs/containers/TaskExecutionResultBlock';
import TaskAttributesBlock from 'Business/tpsRepairs/containers/TaskAttributesBlock';
import { canAssignBy } from 'Business/tpsRepairs/utils/CanAssign';
import OperationsLayout from 'Business/tpsRepairs/components/OperationsLayout';

import useStyles from './styles';

const View = observer((props) => {
  const {
    task,
    handleClose,
    isOpened,
    handleClickOpen,
    materials,
    dictionaries,
    assignTask,
    rejectTask,
    reasons
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  if (isEmpty(task)) return <Loader />;

  const {
    taskId,
    taskStatus: status,
    plannedStartDate,
    currentAssignee,
  } = task;

  const {
    userStore: {
      role,
      auth: {
        id: userId
      }
    },
    notificationStore: { enqueueSnackbar },
  } = useStores();

  const currentAssigneePositionName = get(currentAssignee, 'positionName');
  const currentAssigneeIdentityType = get(currentAssignee, 'identityType'); // eslint-disable-line

  const { assignee, handleAssigneeChange } = useAssigneeSelect();
  const [taskPlannedStartDate, setTaskPlannedStartDate] = useState(!isEmpty(plannedStartDate) ? plannedStartDate : '');
  const taskStatus = status && status.toLowerCase();
  const isAssignedStatus = taskStatus === taskStatusAliases(it => it).ASSIGNED.name;
  const isCurrentUser = !isEmpty(currentAssignee) && currentAssignee.identityId === userId;
  const isForBrigade = currentAssigneeIdentityType === IDENTITY_TYPE_BRIGADE;
  const showRejectButton = isCurrentUser && [MASTER, HEAD_MASTER].includes(role) && isAssignedStatus;

  const isEditableDate = isAssignedStatus && (
    isCurrentUser
    || (isEmpty(task.currentAssignee) && role === FOREMAN)
  );

  const isEditableCurrentAssignee = isAssignedStatus && (
    isCurrentUser
    || (isEmpty(task.currentAssignee) && role === FOREMAN)
    || (!isDateOverdue(plannedStartDate) && (
      (role === FOREMAN && currentAssigneePositionName === HEAD_MASTER_RU)
    || (role === HEAD_MASTER && currentAssigneePositionName === MASTER_RU)
    || (role === MASTER && isForBrigade)))
  );

  const showActions = {
    master: isAssignedStatus && ((isCurrentUser && [MASTER, HEAD_MASTER].includes(role)) || (!isDateOverdue(plannedStartDate) && ((role === HEAD_MASTER && currentAssigneePositionName === MASTER) || (role === MASTER && isForBrigade)))),
    foreman: isAssignedStatus && role === FOREMAN,
  };

  const goToBack = () => {
    history.push(`/tasks/${taskStatus}`);
  };

  const handleAssign = async () => {
    const params = {
      taskId,
      assigneeId: assignee.assigneeId,
    };

    if (taskStatus === taskStatusAliases(it => it).ASSIGNED.name && role === FOREMAN) {
      params.plannedStartDate = taskPlannedStartDate;
    }
    const { id } = await assignTask(params);
    if (id === taskId) {
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('TASK_SUCCESSFULLY_ASSIGNED')
            }]
          }],
        },
        variant: 'success',
      });
      goToBack();
      return;
    }
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('REQUEST_DEFAULT_ERROR')
          }]
        }],
      },
      variant: 'error',
    });
  };

  const cancelTask = async (reason, comment) => {
    if (reason) {
      const rejectionData = {
        taskId,
        rejectionReason: reason,
      };
      if (comment) assign(rejectionData, { rejectReasonComment: comment });
      const { id } = await rejectTask(rejectionData);
      if (id === taskId) {
        enqueueSnackbar({
          messageTemplate: {
            rows: [{
              rowContent: [{
                type: 'text',
                text: t('TASK_SUCCESSFULLY_REJECTED')
              }]
            }],
          },
          variant: 'success',
        });
        goToBack();
        return;
      }
      enqueueSnackbar({
        messageTemplate: {
          rows: [{
            rowContent: [{
              type: 'text',
              text: t('REQUEST_DEFAULT_ERROR')
            }]
          }],
        },
        variant: 'error',
      });
    }
  };

  const canAssign = () => canAssignBy({
    role,
    userId,
    oldPlannedStartDate: plannedStartDate,
    plannedStartDate: taskPlannedStartDate,
    currentAssignee: assignee,
    status: taskStatus,
  });

  useEffect(() => {
    if (!isEmpty(currentAssignee)) {
      handleAssigneeChange(currentAssignee.identityId, currentAssignee.identityName);
    }
  }, []);

  return (
    <Box css={{ display: 'flex', flexDirection: 'column', marginBottom: -24 }}>
      <Box className={`${classes.body} footer-${Object.keys(showActions).some(key => showActions[key]) ? 'on' : 'off'}`}>
        <Typography className={classes.header} variant="h5" gutterBottom>
          {t('EXECUTION_RESULT')}
        </Typography>
        <TaskExecutionResultBlock
          dictionaries={dictionaries}
          reasons={reasons}
          task={task}
        />
        <Divider className={classes.divider} />
        <Typography className={classes.header} variant="h5" gutterBottom>
          {t('TASK_ATTRIBUTES')}
        </Typography>
        <TaskAttributesBlock
          isEditableDate={isEditableDate}
          isEditableCurrentAssignee={isEditableCurrentAssignee}
          dictionaries={dictionaries}
          materials={materials}
          task={task}
          assignee={assignee}
          handleAssigneeChange={handleAssigneeChange}
          taskPlannedStartDate={taskPlannedStartDate}
          setTaskPlannedStartDate={setTaskPlannedStartDate}
        />
        <Divider className={classes.divider} />
        <div className={classes.commonDetails}>
          <OperationsLayout
            blockTitle={t('OPERATIONS')}
            operations={task.operations}
            renderOperation={(operation) => {
              const materialId = operation.equipmentId > 0 ? operation.equipmentId : operation.technicalPlaceId;
              const subTitle = materials[materialId] ? materials[materialId].title : '';

              return (
                <OperationView
                  key={operation.operationId}
                  title={operation.title}
                  subTitle={subTitle}
                  status={operation.status}
                  counters={{
                    top: {
                      left: `${t('PLANNED_TIME')}: ${operation.planDuration} ${t('MIN')}`,
                      right: operation.factDuration ? `${t('FACT_TIME')}: ${operation.factDuration} ${t('MIN')}` : '',
                    },
                    bottom: {
                      left: `${t('PLANNED_NUMBER_OF_PEOPLE')}: ${operation.workersCount} ${t('PEOPLE_CUT')}`,
                      right: operation.factWorkersCount ? `${t('FACT_NUMBER_OF_PEOPLE')}: ${operation.factWorkersCount} ${t('PEOPLE_CUT')}` : '',
                    }
                  }}
                />
              );
            }}
            renderMaterialValues={(operation, materialValue) => {
              const factQuantity = operation.materialValueConsumptions.reduce((acc, consumptionMaterial) => ((consumptionMaterial.materialValueId === materialValue.materialValueId) ? consumptionMaterial : acc), false);
              return (
                <OperationView
                  key={materialValue.sapCode}
                  title={materialValue.description}
                  subTitle={materialValue.sapCode}
                  counters={{
                    top: {
                      left: `${t('PLANNED_QUANTITY')}: ${materialValue.quantity} ${materialValue.unit}`,
                      right: factQuantity ? `${t('FACT_QUANTITY_SHORT')}: ${factQuantity.quantity} ${materialValue.unit}` : '',
                    }
                  }}
                />
              );
            }}
          />
        </div>
      </Box>

      {/* MASTER, HEAD_MASTER can appoint the assigned task */}
      {showActions.master && (
        <Box className={classes.actions}>
          {showRejectButton && (
            <ActionButton
              type={actionButtonTypes.CANCEL}
              text={t('REJECT')}
              handleClick={handleClickOpen}
            />
          )}

          <ActionButton
            disabled={!canAssign()}
            type={actionButtonTypes.CONFIRM}
            text={t('ASSIGN')}
            handleClick={handleAssign}
          />
        </Box>
      )}

      {/* FOREMAN can assign the new task */}
      {showActions.foreman && (
        <Box className={classes.actions}>
          <ActionButton
            disabled={!canAssign()}
            type={actionButtonTypes.CONFIRM}
            text={t('ASSIGN')}
            handleClick={handleAssign}
          />
        </Box>
      )}

      {isOpened && (
        <CancelModal
          reasonss={reasons}
          classes={classes}
          isOpened={isOpened}
          onClose={handleClose}
          onSave={cancelTask}
        />
      )}

    </Box>
  );
});

View.propTypes = {
  task: PropTypes.shape({
    title: PropTypes.string,
    taskId: PropTypes.number,
    sapCode: PropTypes.string,
    taskType: PropTypes.string,
    taskStatus: PropTypes.string,
    plannedStartDate: PropTypes.string,
    currentAssignee: PropTypes.shape({
      identityId: PropTypes.string,
      identityName: PropTypes.string,
    }),
    taskRejectionReason: PropTypes.string,
    rejectReasonComment: PropTypes.string,
    planDuration: PropTypes.number,
    factDuration: PropTypes.number,
    factStartDate: PropTypes.string,
    factFinishDate: PropTypes.string,
    rootTechnicalObjectIds: PropTypes.arrayOf(PropTypes.number),
    expectedStartDate: PropTypes.string,
    expectedFinishDate: PropTypes.string,
  }).isRequired,
  materials: PropTypes.objectOf(PropTypes.shape({
    title: PropTypes.string,
  })),
  dictionaries: PropTypes.shape({
    TaskStatus: PropTypes.objectOf(PropTypes.string),
    TaskType: PropTypes.objectOf(PropTypes.string),
  }),
  handleClose: PropTypes.func.isRequired,
  assignTask: PropTypes.func.isRequired,
  handleClickOpen: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
};

View.defaultProps = {
  materials: {},
  dictionaries: {},
};

export default withModalOpenHandling(View);
