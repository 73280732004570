import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  Grid,
  IconButton,
  Tooltip,
  Switch,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteOutline } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import CheckboxInput from 'Common/components/Forms/Input/Checkbox';
import buildUserFullName from 'Src/utils/buildUserFullName';

const useStyles = makeStyles(theme => ({
  listItem: {
    boxShadow: `0 1px 3px 0 ${theme.palette.midGray}`,
    border: `solid 1px ${theme.palette.midGray}`,
    marginBottom: 8,
    '& .MuiListItem-container': {
      display: 'none',
    },
    '&:marker': {
      display: 'none',
    },
  },
  removeButton: {
    padding: 2,
    '& .MuiSvgIcon-root': {
      color: theme.palette.negative,
    },
    '&:hover': {
      backgroundColor: theme.palette.pink,
    }
  },
  rightPadding: {
    paddingRight: '5em'
  },
  itemIcon: {
    minWidth: 0
  },
  switchThumb: {
    color: theme.palette.disabled
  },
  brigadier: {
    marginRight: 10,
  },
  disabled: {
    color: theme.palette.black30
  },
  active: {
    color: theme.palette.black
  },
}));

const EmployeeListItem = ({
  employee, secondaryTitle, handleToggle, checked, noActions, noSecondaryActions,
  toggleBrigadier, handleRemoveFromBrigade, isBrigadier, renderText,
}) => {
  const {
    firstName,
    lastName,
    surName,
    positionName,
    id,
  } = employee || {};
  const { t } = useTranslation();
  const classes = useStyles();

  const buildFullName = () => buildUserFullName(firstName, lastName, surName);

  const renderCheckBoxes = () => {
    if (noActions) return;

    return (
      <ListItemIcon className={classes.itemIcon}>
        <CheckboxInput
          name="add"
          onChange={() => handleToggle(employee)}
          checked={checked}
          data-test="add"
        />
      </ListItemIcon>
    );
  };

  const renderDeleteButton = () => (
    <Grid item>
      <Tooltip title={t('REMOVE_FROM_LIST')}>
        <IconButton className={classes.removeButton} onClick={() => handleRemoveFromBrigade(employee)} data-test="remove">
          <DeleteOutline />
        </IconButton>
      </Tooltip>
    </Grid>
  );

  const renderSecondaryActions = () => {
    if (noSecondaryActions) return;
    return (
      <ListItemSecondaryAction>
        <Grid container wrap="nowrap" justify="center" spacing={1}>
          <Grid item>
            <span className={clsx(isBrigadier ? classes.active : classes.disabled, classes.brigadier)} data-test="secondaryTitle">{secondaryTitle || t('RESPONSIBLE')}</span>
            {!!toggleBrigadier && (
              <Tooltip title={!isBrigadier
                ? t('ASSIGN_RESPONSIBLE')
                : t('UNASSIGN_RESPONSIBLE')}
              >
                <Switch
                  classes={{ switchBase: classes.switchThumb }}
                  size="small"
                  checked={isBrigadier}
                  onChange={e => toggleBrigadier(e, isBrigadier)}
                  color="primary"
                  value={id}
                  data-test={isBrigadier ? 'repeal-brigadier' : 'make-brigadier'}
                />
              </Tooltip>
            )
            }
          </Grid>
          {!!handleRemoveFromBrigade && renderDeleteButton()}
        </Grid>
      </ListItemSecondaryAction>
    );
  };

  return (
    <ListItem
      className={classes.listItem}
      data-test={id}
    >
      {renderCheckBoxes()}
      <ListItemText
        className={classes.rightPadding}
        primary={renderText ? renderText(employee) : buildFullName()}
        secondary={positionName || ''}
        data-test="employeeListItem"
      />
      {renderSecondaryActions()}
    </ListItem>
  );
};

EmployeeListItem.propTypes = {
  renderText: PropTypes.func,
  handleToggle: PropTypes.func,
  noActions: PropTypes.bool,
  noSecondaryActions: PropTypes.bool,
  handleRemoveFromBrigade: PropTypes.func,
  toggleBrigadier: PropTypes.func,
  isBrigadier: PropTypes.bool,
  secondaryTitle: PropTypes.string,
  checked: PropTypes.bool,
  employee: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    surName: PropTypes.string,
    fullName: PropTypes.string,
    positionName: PropTypes.string
  }).isRequired,
};

EmployeeListItem.defaultProps = {
  renderText: null,
  secondaryTitle: undefined,
  toggleBrigadier: null,
  handleToggle: null,
  handleRemoveFromBrigade: null,
  isBrigadier: false,
  noActions: false,
  noSecondaryActions: false,
  checked: false,
};

export default EmployeeListItem;
