import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import TextInput from 'Common/components/Forms/Input/Text';
import { Search } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  wrap: {
    marginBottom: 5,
  },
  searchInput: () => {
    const oneSideSpace = theme.spacing(2);

    return {
      margin: `10px ${oneSideSpace}px 0`,
      width: `calc(100% - ${oneSideSpace * 2}px)`,
    };
  },
  helpText: {
    fontSize: 12,
    color: theme.palette.black30,
    marginLeft: theme.spacing(4),
  },
}));

const UnitSearch = ({
  onChange, label, bottomText, value
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrap}>
      <TextInput
        className={classes.searchInput}
        value={value}
        label={label}
        name="searchQuery"
        onChange={onChange}
        InputEndAdornment={(<Search />)}
        data-test="searchQuery"
      />
      <span className={classes.helpText}>{bottomText}</span>
    </div>
  );
};

UnitSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  bottomText: PropTypes.string.isRequired,
};

export default UnitSearch;
