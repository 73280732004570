import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  formControlRadio: {
    marginTop: ({ marginTop }) => (marginTop || theme.spacing(1)),
    marginBottom: ({ marginBottom }) => (marginBottom || theme.spacing(3)),
  },
  formLabelRadio: {
    fontSize: 16,
    fontWeight: 'bold',
    margin: '2px 0 20px 2px',
    color: theme.palette.black,
    '&.Mui-focused': {
      color: theme.palette.black,
    }
  },
  labelRadio: {
    padding: '2px 8px',
    borderRadius: 2,
    '&:hover': {
      background: theme.palette.warmWhite,
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 16,
    }
  },
  radio: {
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
    }
  },
}));

export default useStyles;
