import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  customOperationWrapper: {
    backgroundColor: theme.palette.white,
    padding: 16,
    marginBottom: 8,
    '&:hover': {
      '& $headerButtons': {
        display: 'block',
      },
    },
  },
  operationTypeHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: theme.palette.white,
    marginBottom: 16,
    alignSelf: 'baseline',
    '&:hover': {
      '& $headerButtons': {
        display: 'block',
      },
    },
  },
  technicalObjectInfoTitle: {
    fontWeight: 'bold'
  },
  headerButtons: {
    display: 'none',
    minWidth: 72,
    height: 16,
    overflow: 'visible',
  },
  deleteButton: {
    color: theme.palette.negative,
  },
  editButton: {
    color: theme.palette.primaryLight,
    marginLeft: 8,
  },
  actionButtonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  clearIcon: {
    color: theme.palette.primaryLight,
  },
  inputClearButton: {
    alignSelf: 'baseline'
  },
  customActionReadOnly: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    hyphens: 'auto'
  },
}));

export default useStyles;
