import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import {
  DateRangeOutlined, EventAvailableOutlined
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import Header from 'Common/widgets/Layout/Header/Header';
import {
  REPORT_INSPECTION_STATUSES, REPORT_COMPLETED_INSPECTION, REPORT_DETAILED_INSPECTION
} from 'Shared/constants/routes';
import { ReactComponent as LineChart } from 'Assets/stacked_line_chart.svg';

import useStyles from './styles';

const SelectReport = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const goToPage = (link) => {
    history.push({
      pathname: link,
    });
  };

  return (
    <PaperLayout>
      <Header title={t('REPORTS')} />
      <div className={classes.block}>
        <div className={classes.row}>
          <ButtonBase
            className={classes.item}
            onClick={() => goToPage(REPORT_INSPECTION_STATUSES)}
            data-test="reportInspectionStatusesButton"
          >
            <div className={classes.iconWrapper}>
              <DateRangeOutlined />
            </div>
            <div className={classes.itemText}>{t('INSPECTION_STATUSES')}</div>
          </ButtonBase>
          <ButtonBase
            className={classes.item}
            onClick={() => goToPage(REPORT_COMPLETED_INSPECTION)}
            data-test="reportCompletedInspectionButton"
          >
            <div className={classes.iconWrapper}>
              <EventAvailableOutlined />
            </div>
            <div className={classes.itemText}>{t('COMPLETED_INSPECTION')}</div>
          </ButtonBase>
        </div>
        <div className={classes.row}>
          <ButtonBase
            className={classes.item}
            onClick={() => goToPage(REPORT_DETAILED_INSPECTION)}
            data-test="reportDetailedInspectionButton"
          >
            <div className={classes.iconWrapper}>
              <LineChart />
            </div>
            <div className={classes.itemText}>{t('DETAILED_INSPECTION_REPORT')}</div>
          </ButtonBase>
        </div>
      </div>
    </PaperLayout>
  );
};

export default SelectReport;
