import { FOREMAN, MASTER, HEAD_MASTER } from 'Shared/constants/roles';
import { makeStatusDto } from 'Services/dto/makeTaskStatusDto';
import taskStatusSchema from 'Services/validation/schemas/TaskStatusSchema';

const dtoMaker = makeStatusDto(taskStatusSchema);

export const taskStatusAliases = t => (
  {
    NEW: dtoMaker({
      title: t('NEW'),
      tab: 'NEW',
      name: 'new',
      access: [FOREMAN],
      query: ['ASSIGNED']
    }),
    ASSIGNED: dtoMaker({
      title: t('ASSIGNED'),
      tab: 'ASSIGNED',
      name: 'assigned',
      query: ['ASSIGNED'],
      access: [FOREMAN, MASTER, HEAD_MASTER],
    }),
    IN_PROGRESS: dtoMaker({
      title: t('IN_PROGRESS'),
      name: 'in_progress',
      query: ['IN_PROGRESS'],
      access: [FOREMAN, MASTER, HEAD_MASTER],
    }),
    COMPLETED: dtoMaker({
      title: t('COMPLETED'),
      name: 'completed',
      query: ['COMPLETED'],
      access: [FOREMAN, MASTER, HEAD_MASTER],
    }),
    CANCELED: dtoMaker({
      title: t('REJECTED'),
      name: 'canceled',
      query: ['CANCELED'],
      access: [FOREMAN, MASTER, HEAD_MASTER],
    }),
    ALL: dtoMaker({
      title: t('ALL'),
      name: 'all',
      query: [],
      access: [FOREMAN, MASTER, HEAD_MASTER],
      queryByRole: {
        [MASTER]: ['ASSIGNED', 'IN_PROGRESS', 'COMPLETED', 'CANCELED'],
        [HEAD_MASTER]: ['ASSIGNED', 'IN_PROGRESS', 'COMPLETED', 'CANCELED'],
        [FOREMAN]: ['ASSIGNED', 'IN_PROGRESS', 'COMPLETED', 'CANCELED'],
      }
    }),
    ARCHIVE: dtoMaker({
      title: t('ARCHIVE'),
      name: 'archive',
      access: [FOREMAN, MASTER, HEAD_MASTER],
      query: [
        'COMPLETED',
        'CANCELED'
      ]
    })
  }
);

export default taskStatusAliases;
