import Agent from 'ApiAgents/Agent';
import qs from 'qs';
import getPaginationQueryObject from 'Src/utils/getPaginationQueryObject';

export default class TaskAgent extends Agent {
  loadList = (
    query = '',
    shouldPaginate,
    pageNumber,
    pageSize
  ) => {
    const pageQuery = getPaginationQueryObject(shouldPaginate, pageNumber, pageSize);

    return this.request({
      url: '/task/pl/view',
      method: 'GET',
      params: { ...query, ...pageQuery },
      paramsSerializer: params => qs.stringify(params)
    }, 'tasks');
  };

  filterById = query => (this.request({
    url: '/task/pl/view',
    method: 'GET',
    params: query,
  }, 'tasks'));

  updatePlannedDate = body => (
    this.request({
      url: '/task/pl/manage/update/plannedDate',
      method: 'PUT',
      data: body
    }, 'tasks'));

  assignTask = data => (
    this.request({
      url: '/task/pl/manage/update/add/assignee',
      method: 'PUT',
      data: { ...data }
    }, 'tasks'));

  getStatusList = () => (this.request({
    url: '/task/statuses/view',
    method: 'GET',
  }, 'tasks'));

  saveTask = (body = {}) => (
    this.request({
      url: '/task/pl/manage/create',
      method: 'POST',
      data: body,
    },
    'tasks')
  );

  getReasonList = () => (this.request({
    url: '/task/reject/reasons/view',
    method: 'GET',
  }, 'tasks'));

  confirmTask = data => (
    this.request({
      url: '/task/pl/manage/update/confirm',
      method: 'PUT',
      data: { ...data }
    }, 'tasks'));

  rejectTask = data => (
    this.request({
      url: '/task/pl/manage/update/reject',
      method: 'PUT',
      data: { ...data }
    }, 'tasks'));

  rescheduleTask = data => (
    this.request({
      url: '/task/pl/manage/update/reschedule',
      method: 'PUT',
      data: { ...data }
    }, 'tasks'));

  getHistory = query => (this.request({
    url: '/history/task/full',
    method: 'GET',
    params: query,
  }, 'tasks'));

  getDictionaryByKey = query => (this.request({
    url: '/dictionary/getByKey',
    method: 'GET',
    params: query,
  }, 'tasks'));

  disableTasksByIds = taskIds => (
    this.request({
      url: '/task/pl/manage/disable/byIds',
      method: 'PUT',
      data: {
        ids: taskIds
      },
    },
    'tasks'));
}
