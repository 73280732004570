import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import useStores from 'Store/useStores';
import { isEmpty } from 'lodash';
import { SvgIcon, Tooltip } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import { ReportProblemOutlined } from '@material-ui/icons';
import OptionRenderer from 'Common/components/Forms/OptionRenderer';
import LinearProgress from 'Common/components/Progress/components/Linear';
import { taskStatuses } from 'Shared/constants/taskStatuses';
import { ReactComponent as StatisticsReportIcon } from 'Src/assets/qr_code_scanner.svg';
import { formatTime } from 'Src/utils/datetime';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const ExecutionResultsBlock = (props) => {
  const { t } = useTranslation();
  const translatedTaskStatuses = taskStatuses(t);
  const { theme } = props;
  const {
    coalTaskStore: {
      taskData: {
        factDuration,
        planDuration,
        operations = [],
        taskStatus,
        factStartDateTime,
        factFinishDateTime,
        isRequiredQrCode,
        coalQrScanFailReason,
      } = {},
      scanFailReasonDictionary,
      taskDefects = [],
    },
  } = useStores();

  const startTime = formatTime(factStartDateTime);
  const finishTime = formatTime(factFinishDateTime);
  const hasDefects = Boolean(taskDefects.length);
  const classes = useStyles({ hasDefects });

  const getProgressConfig = () => {
    if (planDuration === 0 && factDuration === 0) {
      return {
        planProgressColor: theme.palette.black30,
        planValue: 0,
        factProgressColor: theme.palette.black30,
        factValue: 0,
      };
    }
    const doneInTime = factDuration <= planDuration;
    const basis = doneInTime ? planDuration : factDuration;
    const planProgressColor = theme.palette.positive;
    const planValue = planDuration / basis * 100;
    const factProgressColor = doneInTime ? theme.palette.positive : theme.palette.negative;
    const factValue = factDuration / basis * 100;
    return {
      planProgressColor, planValue, factProgressColor, factValue
    };
  };

  const coalQrScanFail = Boolean(coalQrScanFailReason);

  const getTaskStatusByOperations = () => {
    const {
      COMPLETED, PARTIALLY_DONE, NOT_DONE
    } = translatedTaskStatuses;
    let taskStatusByOperations = COMPLETED;
    if (isEmpty(operations)) return taskStatusByOperations;

    const operationsByStatuses = {};
    operations.forEach(({ status }) => {
      if (!operationsByStatuses[status]) {
        operationsByStatuses[status] = 1;
      } else {
        operationsByStatuses[status] += 1;
      }
    });

    if (operationsByStatuses.DONE === operations.length) taskStatusByOperations = COMPLETED;
    else if (operationsByStatuses.DONE && operationsByStatuses.DONE < operations.length) taskStatusByOperations = PARTIALLY_DONE;
    else if (operationsByStatuses.REJECTED === operations.length) taskStatusByOperations = NOT_DONE;
    return taskStatusByOperations;
  };

  const {
    planProgressColor, planValue, factProgressColor, factValue
  } = getProgressConfig();

  const getScanFailReason = (enumValue) => {
    const reason = scanFailReasonDictionary.find(item => item.enumValue === enumValue) || {};
    const { value } = reason;
    if (value) {
      return value;
    }
  };

  return (
    <div className={classes.executionResultsBlock}>
      <div className={classes.blockHeader}>
        <div className={classes.blockHeaderLabel}>{t('EXECUTION_RESULT')}</div>
      </div>
      <div className={classes.twoColumns}>
        <div className={classes.column}>
          <OptionRenderer
            value={getTaskStatusByOperations()}
            title={t('STATE')}
            wrapperClass={classes.optionRenderer}
          />
          <OptionRenderer
            value={translatedTaskStatuses[taskStatus] ? translatedTaskStatuses[taskStatus] : translatedTaskStatuses.UNKNOWN}
            title={t('STATUS')}
            wrapperClass={classes.optionRenderer}
          />
          {!coalQrScanFail && isRequiredQrCode && (
            <div className={classes.qrReadingStateBlock}>
              <SvgIcon htmlColor={theme.palette.positive} className={classes.qrIcon} component={StatisticsReportIcon} />
              <span className={classes.qrReadingStateLabel}>{t('SUCCESSFUL_QR_CODE_SCANNING')}</span>
            </div>
          )}
          <div className={classes.defectsBlock}>
            <ReportProblemOutlined className={classes.defectsIcon} />
            <span className={classes.defectsLabel}>
              {hasDefects ? t('TASK_WITH_DEFECTS') : t('TASK_WITHOUT_DEFECTS')}
            </span>
          </div>
        </div>
        <div className={classes.column}>
          {!coalQrScanFail && (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                <div className={classes.planedTime}>{t('PLANNED_LEAD_TIME')}</div>
                <div className={classes.planedTime}>{`${planDuration} ${t('MIN')}`}</div>
              </div>
              <LinearProgress
                value={planValue}
                backgroundColor="#f3f3f3"
                progressColor={planProgressColor}
                wrapperClass={classes.planedProgress}
              />
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                <div className={classes.planedTime}>{t('ACTUAL_LEAD_TIME')}</div>
                <div className={classes.planedTime}>{`${factDuration} ${t('MIN')}`}</div>
              </div>
              <LinearProgress
                value={factValue}
                backgroundColor="#f3f3f3"
                progressColor={factProgressColor}
                wrapperClass={classes.actualProgress}
              />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <OptionRenderer value={startTime} title={t('FACT_START_TIME')} wrapperClass={classes.optionRenderer} />
                <OptionRenderer
                  value={finishTime}
                  title={t('FACT_END_TIME')}
                  wrapperClass={classes.optionRenderer}
                  titlePosition="right"
                />
              </div>
            </>
          )}
          {coalQrScanFail && (
            <div className={classes.qrReadingStateBlock}>
              <Tooltip title={t('UNSUCCESSFUL_QR_CODE_SCANNING')}>
                <SvgIcon
                  htmlColor={theme.palette.negative}
                  className={classes.qrIcon}
                  component={StatisticsReportIcon}
                />
              </Tooltip>
              <span className={classes.qrReadingStateLabel}>{getScanFailReason(coalQrScanFailReason)}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ExecutionResultsBlock.propTypes = {
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      positive: PropTypes.string,
      black30: PropTypes.string,
      negative: PropTypes.string,
    }),
  }),
};

ExecutionResultsBlock.defaultProps = {
  theme: {},
};

export default withTheme(observer(ExecutionResultsBlock));
