import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { get, assign } from 'lodash';
import clsx from 'clsx';
import { TextField } from '@material-ui/core';
import OptionRenderer from 'Common/components/Forms/OptionRenderer';
import withValidation from 'Src/HOC/withValidation';

import useStyles from './styles';

const Time = forwardRef((props, ref) => {
  const {
    helperText,
    value,
    name,
    label,
    placeholder,
    className,
    inputProps,
    isReadonly,
    onChange,
    error,
    required,
    maxWidth,
    marginBottom,
    marginTop,
    disabled,
    requiredMessage,
    style,
    autoFocus,
    'data-test': dataTest,
    onBlur,
  } = props;

  const selfRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      selfRef.current.focus();
    },
    blur: () => {
      selfRef.current.blur();
    }
  }));


  const { t } = useTranslation();
  const classes = useStyles({
    value,
    maxWidth,
    marginBottom,
    marginTop,
    error,
    disabled,
    helperText,
    requiredMessage,
    t
  });

  if (isReadonly) {
    return (
      <OptionRenderer
        style={style}
        title={label}
        value={value || t('INPUT_EMPTY_VALUE')}
      />
    );
  }

  const restInputProps = { ...props };

  if (helperText) assign(restInputProps, { helperText });

  return (
    <TextField
      autoFocus={autoFocus}
      placeholder={placeholder}
      autoComplete="off"
      type="time"
      className={className}
      classes={{ root: clsx(classes.textField, required && classes.required) }}
      value={value || ''}
      id={name}
      name={name}
      label={label}
      variant="outlined"
      margin="dense"
      InputProps={{
        inputRef: selfRef,
        inputProps: {
          ...inputProps,
          ...dataTest && { 'data-test': dataTest },
        },
        readOnly: Boolean(isReadonly),
        disabled: Boolean(isReadonly),
      }}
      fullWidth
      error={error}
      onChange={(event) => {
        const val = get(event, 'target.value', '');
        const inputName = get(event, 'target.name', '');
        onChange(val, inputName);
      }}
      {...onBlur && { onBlur }}
      {...style && { style }}
      {...helperText && { helperText }}
    />
  );
});

Time.defaultProps = {
  className: undefined,
  autoFocus: false,
  helperText: '',
  value: '',
  label: '',
  placeholder: '',
  inputProps: {},
  isReadonly: false,
  error: false,
  required: false,
  InputEndAdornment: null,
  maxWidth: null,
  marginBottom: undefined,
  marginTop: undefined,
  disabled: false,
  requiredMessage: '',
  style: {},
  multiline: false,
  rows: undefined,
  rowsMax: undefined,
  'data-test': undefined,
  onBlur: undefined,
  onClickEndAdornment: undefined,
};

Time.propTypes = {
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  helperText: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  inputProps: PropTypes.shape({ 'data-test': PropTypes.string }),
  isReadonly: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  InputEndAdornment: PropTypes.node,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number,
  disabled: PropTypes.bool,
  requiredMessage: PropTypes.string,
  style: PropTypes.shape({
    marginTop: PropTypes.number,
  }),
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  'data-test': PropTypes.string,
  onBlur: PropTypes.func,
  onClickEndAdornment: PropTypes.func,
};

export default withValidation(Time);
