import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import {
  Switch,
  Route,
  withRouter,
  Redirect,
} from 'react-router-dom';
import useStores from 'Store/useStores';
import { formatDate } from 'Src/utils/datetime';
import PaperLayout from 'Common/widgets/Layout/Paper/Layout';
import Header from 'Common/widgets/Layout/Header/Header';
import DataTable from 'Common/components/DataTable/DataTable';
import ProgressBar from 'Common/components/Progress/components/Circular/ProgressBar';
import { materialValuesAgreementsAliases } from 'Src/business/tpsRepairs/config/materialValuesAgreementsAliases';
import { materialValuesAgreementsConfig } from 'Business/tpsRepairs/config/materialValuesAgreementsConfig';
import ListTabs from 'Common/widgets/Tabs/ListTabs';
import useCurrentListStatus from 'Src/hooks/useCurrentListStatus';
import * as routes from 'Src/shared/constants/routes';
import { INITIAL_PAGE_NUMBER } from 'Shared/constants/paging';


const ConsumptionsControlList = observer(({ match, history }) => {
  const {
    materialValuesControlStore: {
      isTasksListLoading,
      isTaskListLoaded,
      tasks,
      materials,
      loadTaskList,
      totalPages,
      isLoadingByPage,
      hasNextPage,
      resetTaskListStorePart,
    },
    userStore: {
      role,
    },
    notificationStore: { enqueueSnackbar },
  } = useStores();

  const { t } = useTranslation();
  const translatedMaterialValuesAgreementsConfig = materialValuesAgreementsConfig(t);
  const translatedMaterialValuesAgreementsAliases = materialValuesAgreementsAliases(t);

  const statusAliases = Object.keys(translatedMaterialValuesAgreementsAliases).map(alias => ({
    key: alias,
    ...translatedMaterialValuesAgreementsAliases[alias]
  }));

  const currentStatus = useCurrentListStatus('control');

  const checkAlias = currentAlias => Object.keys(translatedMaterialValuesAgreementsAliases).some(key => translatedMaterialValuesAgreementsAliases[key].name === currentAlias) && currentAlias;

  const transform = data => data.map((item) => {
    const {
      rootTechnicalObjectIds = [],
      assignments = [],
      expectedStartDate,
      expectedFinishDate,
    } = item;


    return {
      ...item,
      rootTechnicalObjectIds: rootTechnicalObjectIds.map(id => (materials[id] ? materials[id].title : '')).join(', ') || t('INPUT_EMPTY_VALUE'),
      assignments: assignments.reduce((acc, assignment) => assignment.identityName, ''),
      expectedDate: `${formatDate(expectedStartDate)} - ${formatDate(expectedFinishDate)}`,
      role,
    };
  });

  const getStatusQuery = () => {
    if (!currentStatus) return [''];

    if (currentStatus === 'all') {
      const { queryByRole } = translatedMaterialValuesAgreementsAliases[currentStatus.toUpperCase()] || {};
      return queryByRole[role];
    }

    const { query } = translatedMaterialValuesAgreementsAliases[currentStatus.toUpperCase()] || {};
    return query;
  };

  const defaultLoadListError = () => {
    enqueueSnackbar({
      messageTemplate: {
        rows: [{
          rowContent: [{
            type: 'text',
            text: t('REQUEST_LOAD_LIST_ERROR')
          }]
        }],
      },
      variant: 'error',
    });
  };

  const loadPaginatedTasks = (pageNumber, perPage) => {
    loadTaskList(getStatusQuery(), pageNumber, perPage).catch(() => defaultLoadListError());
  };

  useEffect(() => {
    if (!currentStatus) return;
    loadTaskList(getStatusQuery(), INITIAL_PAGE_NUMBER).catch(() => defaultLoadListError());
  }, [currentStatus]);

  useEffect(() => () => resetTaskListStorePart(), []);

  const onRowClickHandler = (event, row) => {
    history.push({
      pathname: `${routes.CONTROL}/${currentStatus}/${row.id}`
    });
  };

  return (
    <PaperLayout autoHeight>
      <Header
        title={t('CONTROL')}
      />
      {(isLoadingByPage || isTasksListLoading) && <ProgressBar />}
      <ListTabs
        matchUrl={match.url}
        statusConfig={statusAliases}
        shouldCheckRole
        role={role}
        value={checkAlias(currentStatus) || translatedMaterialValuesAgreementsAliases.NEW.name}
      />
      <Switch>
        {statusAliases.map(({ key, name }) => (
          <Route
            key={key}
            path={`${match.path}/${name}`}
            render={props => (isTaskListLoaded && (
              <DataTable
                isDataLoaded={isTaskListLoaded}
                {...props}
                titleSingle={t('TASK_ACCUSATIVE')}
                columns={translatedMaterialValuesAgreementsConfig[key].columns}
                data={transform(tasks)}
                onRowClick={onRowClickHandler}
                count={totalPages}
                hasNextPage={hasNextPage}
                onPageChange={loadPaginatedTasks}
                onPerPageChange={loadPaginatedTasks}
                actions={translatedMaterialValuesAgreementsConfig[key].actions}
                withPageTabs
                dataTestRowSuffixEntityName="id"
              />
            )
            )}
          />
        ))}
        <Redirect to={`/control/${translatedMaterialValuesAgreementsAliases.NEW.name}`} />
      </Switch>
    </PaperLayout>
  );
});

ConsumptionsControlList.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.shape({
      tabIndex: PropTypes.number
    })
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(ConsumptionsControlList);
