import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  container: {
    border: `solid 1px ${theme.palette.black20}`,
    borderStyle: 'dashed',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'flex-start',
    color: theme.palette.black30,
    padding: 20,
    marginBottom: 8,
  },
  iconLeft: {
    marginRight: 10
  },
  iconRight: {
    marginLeft: 10
  }
}));

const ActionPrompt = ({
  text, dataTestAttr, Icon, iconPosition
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container} data-test={dataTestAttr}>
      {Icon && iconPosition === 'start'
        && (
          <Icon className={classes.iconLeft} />
        )}
      {text}
      {Icon && iconPosition === 'end'
      && (
        <Icon className={classes.iconRight} />
      )}
    </div>
  );
};

ActionPrompt.propTypes = {
  text: PropTypes.string.isRequired,
  dataTestAttr: PropTypes.string.isRequired,
  Icon: PropTypes.elementType,
  iconPosition: PropTypes.oneOf(['start', 'end']),
};

ActionPrompt.defaultProps = {
  Icon: null,
  iconPosition: 'start'
};

export default ActionPrompt;
