export const TECHNICAL_PLACE = 'TECHNICAL_PLACE';
export const EQUIPMENT = 'EQUIPMENT';
export const OPERATION = 'OPERATION';
export const ACTION = 'ACTION';
export const POWER_UNIT = 'POWER_UNIT';
export const TAGS = 'TAGS';
export const CUSTOM_ACTION = 'CUSTOM_ACTION';

export const sectionTypeShortTitles = {
  [TECHNICAL_PLACE]: 'TECHNICAL_PLACE_SHORT',
  [EQUIPMENT]: 'EQUIPMENT_ENTITY_SHORT',
};

export default {
  [TECHNICAL_PLACE]: 'TECHNICAL_PLACE',
  [EQUIPMENT]: 'EQUIPMENT',
  [OPERATION]: 'OPERATIONS',
  [ACTION]: 'ACTIONS_BY_TAG',
  [POWER_UNIT]: 'POWER_UNIT',
  [TAGS]: 'TAGS',
};
