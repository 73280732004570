import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ userCardHeight }) => ({
  root: {
    height: ({ maxHeight }) => (maxHeight),
    resize: ({ maxHeight }) => (maxHeight ? 'none' : 'inherit'),
    overflowY: 'scroll',
    padding: '24px 20px 45px',
    width: '100%',
  },
  circularProgressWrapper: {
    width: '100%',
    height: userCardHeight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
