import {
  action, runInAction, makeObservable, observable
} from 'mobx';
import { get } from 'lodash';
import { onFileReceived } from 'Src/utils/file';
import UnitsAgent from 'ApiAgents/Units/UnitsAgent';
import { formatToISOString, formatDate } from 'Src/utils/datetime';
import {
  MANAGER, SHIFT_SUPERVISOR_KTC
} from 'Shared/constants/roles';
import { FILE_DATE_FORMAT } from 'Shared/constants/datetime';

const unitsAgent = new UnitsAgent();

export class InspectionReportsStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this);
  }

  @observable rootOrgUnits = [];

  @observable isRootOrgUnitsLoading = false;

  @observable isReportLoading = false;

  @observable startDate;

  @observable finishDate;

  @observable rootOrgUnitCode;

  @action getRootOrgUnits = async () => {
    try {
      this.isRootOrgUnitsLoading = true;
      const rootOrgUnits = await unitsAgent.fetchAllRootOrgUnits();
      runInAction(() => {
        this.rootOrgUnits = rootOrgUnits;
        this.isRootOrgUnitsLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.rootOrgUnits = [];
        this.isRootOrgUnitsLoading = false;
      });
    }
  };

  @action downloadReport = async (reportType, reportName) => {
    try {
      this.isReportLoading = true;
      const { startDate, finishDate, rootOrgUnitCode } = this;
      const role = get(this.rootStore, 'userStore.role');
      const usersRootOrgUnitCode = get(this.rootStore, 'userStore.auth.rootOrgUnit.code');
      const report = await unitsAgent.getReportByType({
        startDate: formatToISOString(startDate),
        finishDate: formatToISOString(finishDate),
        ...rootOrgUnitCode && role === MANAGER && { orgUnitCode: rootOrgUnitCode },
        ...role === SHIFT_SUPERVISOR_KTC && { orgUnitCode: usersRootOrgUnitCode },
        type: reportType
      });

      onFileReceived(report.data, `${reportName} ${formatDate(startDate, FILE_DATE_FORMAT)} - ${formatDate(finishDate, FILE_DATE_FORMAT)}`);

      runInAction(() => {
        this.isReportLoading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.isReportLoading = false;
      });
      throw new Error(error);
    }
  }

  @action updateField = (field, value) => {
    this[field] = value;
  }

  @action discardState = () => {
    this.rootOrgUnits = [];
    this.startDate = '';
    this.finishDate = '';
    this.rootOrgUnitCode = '';
    this.isReportLoading = false;
    this.isRootOrgUnitsLoading = false;
  }
}

export default InspectionReportsStore;
